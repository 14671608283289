import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, Dialog, DialogContent, DialogTitle, Divider, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { EventType, FormLabelText } from "@next/modules/profile/redux/types";
import SwitchSubscription from "@next/modules/profile/components/switch-subscription/switch-subscription";
import { selectSubscriptionList } from "@next/modules/profile/redux/selectors";

export const NotificationsDialog = ({ open, handleClose }) => {
  const { t } = useTranslation("notificationsDialog");
  const subscriptionsList = useSelector(selectSubscriptionList);

  const filterSubscriptionsList = subscriptionsList?.filter(
    (subscription) => subscription.event_type === EventType.CREATE_RFQ
  );
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className="c-notifications-dialog"
    >
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={11}>
            <Box textAlign="left" variant="h6" className="c-notifications-dialog--box">
              {t("notificationPreferences")}
            </Box>
          </Grid>
          <Grid item xs={1} className="c-notifications-dialog--closeIcon">
            <CloseIcon onClick={handleClose} />
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider className="c-notifications-dialog--divider" />
      <DialogContent className="c-notifications-dialog--content">
        <SwitchSubscription
          title={FormLabelText.PREFERENCES_NOTIFICATIONS_LABEL}
          subscriptions={filterSubscriptionsList}
        />
      </DialogContent>
    </Dialog>
  );
};
