import { connect } from "react-redux";
import { TagForm } from "./tag-form.component";
import { workspaceActions } from "../../../services/workspace/workspace.actions";

const mapDispatchToProps = {
  createPartTags: workspaceActions.createPartTags,
  getAllTagsCreatedByCurrentCompany: workspaceActions.getAllTagsCreatedByCurrentCompany,
  getDefaultTagsList: workspaceActions.getDefaultTagsList,
};

const mapStateToProps = (state) => {
  return {
    token: state.profile.token,
    tagsCreatedByCurrentCompany: state.workspace.tagsCreatedByCurrentCompany,
    tagsCreatedByCurrentCompanyLoading: state.workspace.tagsCreatedByCurrentCompanyLoading,
    tags: state.workspace.tags,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TagForm);
