import { put, takeLatest } from "redux-saga/effects";
import { safeCall } from "@next/redux/safeCall";
import { analyticsService } from "../services/analytics-services";
import { analyticsActions } from "./slices";
import { FetchOrdersReportInput, FetchRequestsReportInput, SupplierReport } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";

function* handleFetchRequestsReportRequest({ payload }: PayloadAction<FetchRequestsReportInput>) {
  const { response, error } = yield safeCall(analyticsService.fetchRequestReport, payload);

  if (error) {
    yield put(
      analyticsActions.fetchRequestsReportFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(analyticsActions.fetchRequestsReportSuccess(response.data));
  }
}

function* handleFetchOrdersReportRequest({ payload }: PayloadAction<FetchOrdersReportInput>) {
  const { response, error } = yield safeCall(analyticsService.fetchOrdersReport, payload);

  if (error) {
    yield put(analyticsActions.fetchOrdersReportFailure(error));
  } else {
    yield put(analyticsActions.fetchOrdersReportSuccess(response.data));
  }
}

function* handleFetchSuppliersReportRequest() {
  const { response, error } = yield safeCall(analyticsService.fetchSupplierReport);

  return yield put(analyticsActions.fetchSupplierReportSuccess(mockSupplierReportData));

  if (error) {
    yield put(analyticsActions.fetchSupplierReportFailure(error));
  } else {
    yield put(analyticsActions.fetchSupplierReportSuccess(response.data));
  }
}

export function* analyticsSaga() {
  yield takeLatest(analyticsActions.fetchRequestsReportRequest, handleFetchRequestsReportRequest);

  yield takeLatest(analyticsActions.fetchOrdersReportRequest, handleFetchOrdersReportRequest);

  yield takeLatest(analyticsActions.fetchSupplierReportRequest, handleFetchSuppliersReportRequest);
}

// const mockOrdersReportData: OrdersReport = {
//   supplier_targeted_for_orders: 22,
//   rfqs_count: 1390,
//   total_value: 36300000,
//   average_time_to_acknowledge: 2.1,
//   on_time_deliveries_percentage: 75,
//   late_items: 401,
//   charts: [
//     {
//       id: "rfq_volume",
//       x_axis: ["Jan", "Feb", "Mar", "Apr", "May", "June", "Jul", "Aug", "Sept"],
//       y_axes: [
//         {
//           id: "items_sent_in_order",
//           values: [20, 20, 50, 55, 30, 75, 280, 150, 230],
//         },
//         {
//           id: "total_value",
//           values: [
//             4100000, 5100000, 6100000, 7100000, 8100000, 9100000, 10100000,
//             11100000, 11800000,
//           ],
//         },
//         {
//           id: "cumulative_value",
//           values: [
//             20100000, 25100000, 30100000, 35100000, 40100000, 41100000,
//             42100000, 45100000, 49100000,
//           ],
//         },
//       ],
//     },
//     {
//       id: "on_time_shipment",
//       x_axis: ["Jan", "Feb", "Mar", "Apr", "May", "June", "Jul", "Aug", "Sept"],
//       y_axes: [
//         {
//           id: "+5_days_early",
//           values: [40, 110, 220, 90, 200, 120, 40, 20, 240],
//         },
//         {
//           id: "on_time",
//           values: [100, 190, 50, 30, 20, 30, 150, 270, 50],
//         },
//         {
//           id: "late",
//           values: [50, 10, 40, 45, 20, 100, 90, 20, 10],
//         },
//       ],
//     },
//   ],
//   tables: [
//     {
//       id: "supplier_details",
//       rows: [
//         {
//           id: "1_xxx",
//           supplier_name: "Alumax Usinage CNC",
//           items_sent_in_order: 2,
//           orders_marked_as_acknowledged_percentage: 50,
//           avg_time_to_acknowledge: 2,
//           orders_marked_as_shipped_percentage: 100,
//           on_time_delivery_percentage: 80,
//           total_awarded_value: 1000,
//         },
//       ],
//     },
//   ],
// };

const mockSupplierReportData: SupplierReport = {
  active_suppliers: 100,
  total_spent: 36300000,
  charts: [
    {
      id: "spending_per_supplier",
      x_axis: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "N", "M"],
      y_axes: [
        {
          id: "total_value",
          values: [
            9000000, 7500000, 6500000, 5500000, 4000000, 2500000, 2400000, 2350000, 2350000,
            2340000, 2330000, 1000000, 300000, 100000,
          ],
        },
        {
          id: "cumulative_value",
          values: [
            10500000, 8000000, 7500000, 5500000, 4000000, 2500000, 2400000, 2550000, 2350000,
            2340000, 2330000, 1000000, 300000, 100000,
          ],
        },
      ],
    },
  ],
};
