import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";

// Material ui imports
import { Box, Button, Grid } from "@mui/material";
import Link from "@mui/material/Link";

import BuyerNdaUploadModal from "../buyer-nda-upload-modal/buyer-nda-upload-modal.component";

import StepperView from "../utils/stepper/stepper-view.component";
import { downloadFile } from "../../helpers/download";

export const SupplierNDA = ({ activeRfqDetails }) => {
  const { t } = useTranslation("supplierNda");

  const rfqStepTitles = [
    {
      title: t("ndaSigning"),
      subTitle: "",
    },
    {
      title: t("buyerValidation"),
      subTitle: "",
    },
  ];
  const [isNDAUploadModalOpen, setIsNDAUploadModalOpen] = useState(false);
  const NDAStepperIndex = useSelector((state) => state.marketplace.NDAStepperIndex);

  const getRfqStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <NdaSignature
            t={t}
            setIsNDAUploadModalOpen={setIsNDAUploadModalOpen}
            activeRfqDetails={activeRfqDetails}
          />
        );
      case 1:
        return <BuyerValidation t={t} />;
    }
  };

  return (
    <section className="c-supplier-nda" align="center">
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={6}>
          {NDAStepperIndex === 0 && <p>{t("rfqProtectedByNda")}</p>}
          <StepperView
            stepTitles={rfqStepTitles}
            stepContent={getRfqStepContent}
            activeStep={NDAStepperIndex}
          />
        </Grid>
      </Grid>

      {/* NDA modal */}
      {isNDAUploadModalOpen && (
        <BuyerNdaUploadModal
          isModalOpen={isNDAUploadModalOpen}
          isSeller={true}
          setIsNDAUploadModalOpen={setIsNDAUploadModalOpen}
        />
      )}
    </section>
  );
};

export const NdaSignature = ({ t, setIsNDAUploadModalOpen, activeRfqDetails }) => {
  const tracking = useTracking();
  const ndaSignedByBuyer = useSelector((state) => state.marketplace.activeRfqDetails.nda);
  const profile = useSelector((state) => state.profile);

  const handleDownloadClick = (e) => {
    e.stopPropagation();
    downloadFile(e);
    tracking.trackEvent({
      category: "download-nda",
      company: profile.company.name,
      username: profile.user.username,
      rfqId: activeRfqDetails.pk,
      rfqName: activeRfqDetails.rfq_name,
      date: new Date(),
    });
  };

  return (
    <div className="c-nda-signature">
      <Box m={3}>
        <Link
          download-link={ndaSignedByBuyer && ndaSignedByBuyer.nda_url}
          download
          onClick={(e) => handleDownloadClick(e)}
          className="c-nda-link"
        >
          {t("downloadNda")}
        </Link>
      </Box>
      <Box className="c-nda-signature-upload--btn" mt={6}>
        <Button variant="contained" color="primary" onClick={() => setIsNDAUploadModalOpen(true)}>
          {t("uploadSignedNda")}
        </Button>
      </Box>
    </div>
  );
};

export const BuyerValidation = ({ t }) => {
  return <div>{t("buyerValidationHelperText")}</div>;
};
