import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { Box, styled } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { CustomTypography } from "@next/components/custom-typography";
import { formatDate } from "helpers/formatDate";

const StyledBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
});

const StyledTypography = styled(CustomTypography)(({ theme }) => ({
  color: `${theme.palette.text.secondary}`,
  letterSpacing: "0.4px",
}));

const StyledSpan = styled("span")(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "12px",
  letterSpacing: "0.4px",
}));

type EditableDateTemplateCellProps = {
  checkField: string;
  sinceOptions: string[];
  byOptions: string[];
  isTableDisabled: boolean;
} & GridRenderCellParams;

export const EditableDateTemplateCell: React.FC<EditableDateTemplateCellProps> = ({
  checkField,
  sinceOptions,
  byOptions,
  isTableDisabled,
  ...params
}) => {
  const valueFromSelectionInRow = params.row[checkField];
  let placeholderText = null;

  if (sinceOptions.includes(valueFromSelectionInRow)) {
    placeholderText = "Since: ";
  } else if (byOptions.includes(valueFromSelectionInRow)) {
    placeholderText = "By: ";
  }
  const formattedDate = formatDate(params?.value, "MM/DD/YYYY");

  return (
    <StyledBox>
      <StyledTypography tooltipTitle={null} variant="caption">
        {placeholderText}
        <StyledSpan>{formattedDate}</StyledSpan>
      </StyledTypography>
      {!isTableDisabled && placeholderText ? <CalendarTodayIcon /> : null}
    </StyledBox>
  );
};
