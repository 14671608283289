import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "../redux/modalsSlices";
import { SharedModalTypes } from "./types";
import Modal from "@next/components/custom-modal";
import { useTranslation } from "react-i18next";
import { Rfq } from "@next/modules/project/redux";
import { SharedExtendRfq } from "./components/extend-rfq/shared-extend-rfq";

export const SharedExtendRfqModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(SharedModalTypes.EXTEND_RFQ_MODAL));
  const data = modal?.data as {
    activeRfq: Partial<Rfq>;
    refetchData: boolean;
  };

  if (!modal || !data) return null;

  return (
    <Modal
      handleModalClose={() => dispatch(modalsActions.closeModal(SharedModalTypes.EXTEND_RFQ_MODAL))}
      modalState={{
        modalHeader: t("project:table:row:extendRfq"),
        modalBody: <SharedExtendRfq rfq={data?.activeRfq} refetchData={data?.refetchData} />,
      }}
      isModalOpen={true}
    />
  );
};
