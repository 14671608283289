import React from "react";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";
import { getQuotationLineItemsTableColumns } from "./quotation-line-items-table.columns";
import { useGridApiRef } from "@mui/x-data-grid-pro-v5";
import { FormikErrors } from "formik";
import { FormValues } from "../upload-quotation.form.types";
import { useTranslation } from "react-i18next";

type Props = {
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormValues>>;
  parts: any;
  currency: string;
};

export const QuotationLineItemTable: React.FC<Props> = ({ setFieldValue, parts, currency }) => {
  const apiRef = useGridApiRef();
  const { t } = useTranslation();
  const columns = getQuotationLineItemsTableColumns({
    setFieldValue,
    parts,
    currency,
    t,
  });

  return (
    <DataGridProV5
      apiRef={apiRef}
      rows={parts || []}
      columns={columns || []}
      onCellClick={(_params, e) => {
        e.stopPropagation();
      }}
      rowHeight={40}
      headerHeight={44}
      showColumnRightBorder
      showCellRightBorder
      getRowId={(row) => row.pk}
      disableSelectionOnClick
      hideFooter
      sx={{
        "&.MuiDataGrid-root": {
          border: "none",
        },
      }}
    />
  );
};
