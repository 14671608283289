import { Box } from "@mui/material";
import { CompanyPublicProfile } from "@next/modules/profile/redux";
import React from "react";
import CompanyProfileDocCard from "../company-profile-doc-card";

interface Props {
  companyProfile?: CompanyPublicProfile;
}

const CompanyProfileFullDocs: React.FC<Props> = ({ companyProfile }) => {
  if (!companyProfile?.documents?.length) return null;

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(auto-fill, minmax(min(260px, 100%), 1fr))"
      gap="12px"
    >
      {companyProfile.documents.map((item) => (
        <CompanyProfileDocCard
          key={item.pk}
          file={{
            ...item,
            file_name: `${item.file_name}.${item.file_extension}`,
          }}
        />
      ))}
    </Box>
  );
};

export default CompanyProfileFullDocs;
