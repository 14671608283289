import { GridColumns } from "@mui/x-data-grid-pro";
import { getLoadingRows } from "helpers/data-view.helper";
import { useEffect, useState } from "react";

export type GridStateData = {
  rows: Partial<any>[];
  columns: GridColumns;
};

export type Props = {
  loading: boolean;
  columns: GridColumns;
  rows: Partial<any>[];
};

export const useGridData = ({ loading, columns, rows }: Props) => {
  const [gridData, setGridData] = useState<GridStateData>({
    rows: [],
    columns: [],
  });

  useEffect(() => {
    if (loading) {
      const loadingGridData = getLoadingRows(columns) as GridStateData;

      setGridData(loadingGridData);
    } else {
      setGridData({ rows: rows, columns: columns });
    }
  }, [rows, loading, columns]);

  return {
    gridData,
    setGridData,
  };
};
