import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ValidationManagementSearchAndFilter } from "../validation-management-search-and-filter/validation-management-search-and-filter";
import { ValidationManagementTable } from "../validation-management-table/validation-management-table";
import {
  PaginationSizeForFormTable,
  ValidationManagementStatusEnum,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { SelectChangeEvent } from "@mui/material";
import * as S from "./validation-management-body.styled";

export const ValidationManagementBody = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState<string>("");

  useEffect(() => {
    dispatch(
      vendorManagementActions.fetchQuestionnairesRequest({
        search: searchValue,
        status: statusValue as keyof typeof ValidationManagementStatusEnum | "",
        page: page,
        pageSize: PaginationSizeForFormTable,
      })
    );
  }, [page, statusValue, searchValue]);

  const clearSearch = () => {
    setPage(1);
    setSearchValue("");
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setPage(1);
    setStatusValue(value);
  };

  return (
    <S.StyledRootBox>
      <S.StyledInnerBox>
        <S.StyledTypography variant="body1">Questionnaires</S.StyledTypography>
      </S.StyledInnerBox>

      <S.StyledContentBox>
        <ValidationManagementSearchAndFilter
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          clearSearch={clearSearch}
          statusValue={statusValue}
          handleStatusChange={handleStatusChange}
        />

        <ValidationManagementTable
          page={page}
          setPage={setPage}
          pageSize={PaginationSizeForFormTable}
        />
      </S.StyledContentBox>
    </S.StyledRootBox>
  );
};
