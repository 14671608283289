import React from "react";
import { Grid, Step, StepLabel, Stepper } from "@mui/material";

const StepperView = ({ stepTitles, stepContent, activeStep = 0, gridXs = 8 }) => {
  return (
    <section className="c-stepper">
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className="c-stepper--container"
      >
        <Grid item xs={gridXs}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {stepTitles.map((step) => (
              <Step key={step.title}>
                <StepLabel>{step.title}</StepLabel>
                <p className="c-stepper-view--helper-text">{step.subTitle}</p>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          {stepContent(activeStep)}
        </Grid>
      </Grid>
    </section>
  );
};

export default StepperView;
