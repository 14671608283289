import { connect } from "react-redux";

// Action Import
import { modalsActions } from "../../services/modals/modals.actions";

import AppDrawer from "./app-drawer.component";
import { ProjectModalTypes } from "@next/modules/project/modals/types";
import { Modal } from "@next/redux/modalsTypes";

const mapDispatchToProps = {
  handleOpenModal: modalsActions.showModal,
};

const mapStateToProps = (state: any) => {
  return {
    token: state.profile.token,
    modals: state.modals,
    isDrawerOpen: [
      state.modals.isInboxOpen,
      state.modalsNext.modals?.some(
        (item: Modal) => item.key === ProjectModalTypes.RFQ_DETAILS_MODAL
      ),
    ].some((item: boolean) => !!item),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppDrawer);
