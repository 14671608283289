import { CustomRoute } from "@next/routes/config/types";
import React from "react";
import AppLayout from "./AppLayout";

import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    bgRoot: {
      backgroundColor: theme.palette.grey[50],
      padding: theme.spacing(3),
      margin: theme.spacing(-6, -8),
      minHeight: "calc(100vh - 50px)",
      maxWidth: "100vw",
      boxSizing: "content-box",
    },
    root: {
      maxWidth: "min(100%, 892px)",
      margin: "auto",
    },
  })
);

const ProfileLayout: CustomRoute["layout"] = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <AppLayout {...props}>
      <div className={classes.bgRoot}>
        <div className={classes.root}>{children}</div>
      </div>
    </AppLayout>
  );
};

export default ProfileLayout;
