import React from "react";
import { GridSelectionModel, GridToolbarContainer } from "@mui/x-data-grid-pro-v5";
import { Box, Button, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SearchTextField } from "@next/components/search-text-field";
import ExportMenu from "../export-menu";
import { createStyles, makeStyles } from "@mui/styles";
import { ErpConnectionStatus } from "@next/components/erp-connection-status";
import { ErpConfigurationFor } from "@next/components/erp-connection-status/erp-connection-status";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1, 0.5, 1),
      justifyContent: "space-between",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    leftContainer: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
  })
);

type Props = {
  clearSearch: () => void;
  setSearchValue: (searchValue: string) => void;
  searchValue: string;
  selectionModel: GridSelectionModel;
  onClickCreateNewProject: () => void;
  setSelectionModel: (selectionModel: GridSelectionModel) => void;
};

export const ProjectsTableToolbar: React.FC<Props> = ({
  clearSearch,
  setSearchValue,
  searchValue,
  selectionModel,
  onClickCreateNewProject,
  setSelectionModel,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const resetSelectedProject = () => {
    setSelectionModel([]);
  };

  return (
    <GridToolbarContainer color="inherit" className={classes.root}>
      <Box className={classes.leftContainer}>
        <Button variant="contained" color="primary" onClick={onClickCreateNewProject}>
          {t("project:button:newProject")}
        </Button>
        <ErpConnectionStatus erpConfigurationFor={ErpConfigurationFor.PROJECT_SYNCHRONIZATION} />
        <Box ml="15px">
          <ExportMenu
            projectCodes={selectionModel as string[]}
            resetSelectedProject={resetSelectedProject}
          />
        </Box>
      </Box>

      <Box>
        <SearchTextField
          clearSearch={clearSearch}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
        />
      </Box>
    </GridToolbarContainer>
  );
};
