export enum QuoteStatus {
  WAITING = "Waiting",
  CANCELED = "Canceled",
  ACTIVE = "Active",
  CONTRACT = "Contract",
  ON_HOLD = "ON_HOLD",
  IN_PROGRESS = "InProgress",
}

export enum TabName {
  PARTS = "parts",
  ORDERS = "orders",
  ARCHIVE = "archive",
  IN_PROGRESS_STATUS_WAITING = "InProgress&status=Waiting",
  INFORMATION = "information",
  COMPANY_INFORMATION = "companyInformation",
  QUESTION_AND_ANSWER = "questionAndAnswer",
  NDA = "Nda",
  IN_PROGRESS = "inProgress",
  MEMBERS = "members",
  CAPABILITIES = "capabilities",
  MY_PARTS = "myParts",
  VISIBILITY_DETECTION = "visibilityDetection",
  OFFER = "offer",
  WAITING = "waiting",
  ACTIVE_RFQS = "activeRfqs",
  PRIVATE_RFQS = "privateRfqs",
  INTEND_TO_BID_RFQS = "intentToBidRfqs",
  HIDE = "hide",
  ON_HOLD = "onHold",
  NO_QUOTE = "noQuote",
}

export enum CompanyType {
  DO = "DO",
  FAB = "FAB",
}
