import React, { useCallback, useEffect } from "react";
import { Box, CircularProgress, Link, Theme, Typography } from "@mui/material";
import { useQuery } from "@next/hooks/useQuery";
import { useParams } from "react-router-dom";
import InfoCard from "./info-card";
import { useDispatch, useSelector } from "react-redux";
import { PublicItbItnbRequestInput, marketplaceActions } from "../../redux";
import {
  selectSavePublicItbItnbError,
  selectSavePublicItbItnbLoading,
} from "../../redux/selectors";
import { Trans, useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#F5F5F5",
      textAlign: "center",
    },
    inner: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      maxWidth: 668,
      minHeight: "100vh",
      padding: theme.spacing(3),
      margin: "auto",
    },
    pageText: {
      color: theme.palette.text.secondary,
    },
  })
);

export const PublicItbItnb = () => {
  const classes = useStyles();
  const [query] = useQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token } = useParams<{ token?: string }>();
  const { rfqDisplayName, publicQaLink, type, reason, companyName, rfqCreatorName } = query;
  const error = !!useSelector(selectSavePublicItbItnbError);
  const isItb = type === "itb";

  const loading =
    useSelector(selectSavePublicItbItnbLoading) ||
    !["itb", "itnb"].includes(type as string) ||
    !token;

  const makeRequest = useCallback(() => {
    dispatch(
      marketplaceActions.savePublicItbItnbRequest({
        token,
        type,
        reason,
      } as PublicItbItnbRequestInput)
    );
  }, [token, type, reason]);

  useEffect(() => {
    makeRequest();
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.inner}>
        <Typography className={classes.pageText} variant="body2">
          {rfqDisplayName || ""}
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <InfoCard
            variant="error"
            title={t("publicItbItnb:error:title")}
            bodyParas={[
              <Trans
                i18nKey="publicItbItnb:error:message1"
                components={[<Link href="#" onClick={makeRequest} />]}
              />,
              <Trans
                i18nKey="publicItbItnb:error:message2"
                values={{ rfqCreatorName }}
                components={[<Link href={(publicQaLink || "") as string} />]}
              />,
            ]}
          />
        ) : (
          <InfoCard
            variant="success"
            title={t("publicItbItnb:title")}
            status={isItb ? "success" : "error"}
            statusText={isItb ? t("publicItbItnb:interestedToQuote") : t("publicItbItnb:noQuote")}
            bodyParas={isItb ? [t("publicItbItnb:itbSuccessMessage")] : undefined}
            footerNote={`${rfqCreatorName}\n${companyName}`}
          />
        )}
        <Typography className={classes.pageText} variant="body2">
          {t("publicItbItnb:axyaIntro")}
        </Typography>
      </Box>
    </Box>
  );
};
