import React, { ComponentType } from "react";
import { FieldProps } from "formik";
import { AttachFile } from "@next/components/attach-file";
import { Button } from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useTranslation } from "react-i18next";
import { AttachFileProps } from "../attach-file/attach-file";
import { FileData } from "../attach-file/attach-file-list.types";

const defaultBtnProps: Partial<React.ComponentProps<typeof Button>> = {
  startIcon: <AttachmentIcon />,
  variant: "contained",
  size: "medium",
};

const FormikAttachFile: React.FC<
  FieldProps &
    AttachFileProps & {
      customAttachButton?: ComponentType;
      skipDeleteConfirmationModal?: boolean;
    }
> = ({
  form: { setFieldValue },
  field: { value, name },
  customAttachButton,
  skipDeleteConfirmationModal,
  ...restProps
}) => {
  const { t } = useTranslation();

  const onChangeFiles = (files: (File | FileData)[]) => {
    if (typeof restProps.onChangeFiles === "function") {
      restProps.onChangeFiles(files);
    } else setFieldValue(name, files);
  };

  return (
    <AttachFile
      {...restProps}
      btnProps={{
        ...defaultBtnProps,
        ...restProps.btnProps,
      }}
      btnText={restProps.btnText || t("workspaceNext:rfqDrawer:attachFiles")}
      files={value}
      onChangeFiles={onChangeFiles}
      customAttachButton={customAttachButton}
      skipDeleteConfirmationModal={skipDeleteConfirmationModal}
    />
  );
};

export default FormikAttachFile;
