import { connect } from "react-redux";
import { SupplierContracts } from "./supplier-contracts.component";
import { marketplaceActions } from "../../../services/marketplace";
import { quotationActions } from "../../../services/quotation";

const mapDispatchToProps = {
  fetchCompanyContractList: marketplaceActions.fetchCompanyContractList,
  setIsEditQuotationMode: quotationActions.setIsEditQuotationMode,
};

const mapStateToProps = (state) => {
  return {
    token: state.profile.token,

    contractListOfCompany: state.marketplace.contractListOfCompany,
    isLoading: state.marketplace.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierContracts);
