import { Box, Button, styled } from "@mui/material";

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  height: "712px",
  overflow: "hidden",
  marginBottom: "10px",
});

export const StyledContent = styled(Box)({
  overflow: "hidden",
  marginBottom: "78px",
});

export const StyledRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  marginBottom: "24px",
  overflow: "hidden",
});

export const StyledFooterBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  backgroundColor: "white",
  zIndex: 1000,
  borderTop: `1px solid ${theme.palette.divider}`,
  height: "88px",
}));

export const StyledButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  height: "100%",
  padding: "0 24px",
}));

export const StyledButton = styled(Button)({
  height: "40px",
  borderRadius: "8px",
  marginLeft: "auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const StyledHeaderBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});
