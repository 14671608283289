import React from "react";

import { Order, OrderState } from "@next/modules/orders/redux";
import { AppTheme } from "layout/AppTheme";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import LocalShipping from "@mui/icons-material/LocalShipping";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import ErrorIcon from "@mui/icons-material/Error";
import FilterListIcon from "@mui/icons-material/FilterList";
import CreateIcon from "@mui/icons-material/Create";
import { alpha } from "@mui/material";

const baseStyle: React.CSSProperties = {
  cursor: "unset",
  textOverflow: "ellipsis",
  overflow: "hidden",
};

export const getOrderStateTagStyle = (stateSlug?: OrderState): React.CSSProperties | undefined => {
  switch (stateSlug) {
    case OrderState.WAITING_FOR_ACKNOWLEDGMENT:
    case OrderState.ON_TRACK:
      return {
        ...baseStyle,
        color: AppTheme.palette.primary.dark,
        backgroundColor: alpha(AppTheme.palette.primary.main, 0.1),
      };
    case OrderState.LATE:
      return {
        ...baseStyle,
        color: AppTheme.palette.error.dark,
        backgroundColor: alpha(AppTheme.palette.error.main, 0.1),
      };
    case OrderState.UPDATED:
    case OrderState.AT_RISK:
    case OrderState.WAITING_FOR_SHIPPING_CONFIRMATION:
      return {
        ...baseStyle,
        color: AppTheme.palette.warning.dark,
        backgroundColor: alpha(AppTheme.palette.warning.main, 0.1),
      };
    case OrderState.SHIPPED:
      return {
        ...baseStyle,
        color: AppTheme.palette.success.dark,
        backgroundColor: alpha(AppTheme.palette.success.light, 0.1),
      };
    case OrderState.UNPUBLISHED:
    default:
      return undefined;
  }
};

export const getOrderStateTagIcon = (stateSlug?: OrderState): React.ReactElement | undefined => {
  switch (stateSlug) {
    case OrderState.WAITING_FOR_ACKNOWLEDGMENT:
    case OrderState.ON_TRACK:
      return <PlayCircleFilledIcon style={{ color: AppTheme.palette.primary.main }} />;
    case OrderState.LATE:
      return <WatchLaterIcon style={{ color: AppTheme.palette.error.main }} />;
    case OrderState.WAITING_FOR_SHIPPING_CONFIRMATION:
      return undefined;
    case OrderState.UNPUBLISHED:
      return <CreateIcon />;
    case OrderState.AT_RISK:
      return <ErrorIcon style={{ color: AppTheme.palette.warning.main }} />;
    case OrderState.UPDATED:
      return <RotateRightIcon style={{ color: AppTheme.palette.warning.main }} />;
    case OrderState.SHIPPED:
      return <LocalShipping style={{ color: AppTheme.palette.success.main }} />;
    case OrderState.ALL:
      return <FilterListIcon />;
    default:
      return undefined;
  }
};

export const getActiveStepForStepper = (order?: Order): number => {
  if (!order || !order?.is_sent) return -1;

  const { step } = order;

  if (step === "sent") {
    return 1;
  }

  if (step === "acknowledged") {
    return 2;
  }

  if (step === "shipped") {
    return 3;
  }

  return -1;
};
