import * as yup from "yup";

export type AddOnHoldNoteFormState = {
  on_hold_note: string;
};

export const addOnHoldNoteFormState: AddOnHoldNoteFormState = {
  on_hold_note: "",
};

export const addOnHoldNoteFormValidationSchema = yup.object({
  on_hold_note: yup.string(),
});
