import { Box } from "@mui/material";
import { AppTheme } from "layout/AppTheme";
import React, { PropsWithChildren } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const VisibilityBadge: React.FC<PropsWithChildren<{ seenDate?: string }>> = ({
  seenDate,
}) => {
  const seen = !!seenDate;

  return (
    <Box
      style={{
        position: "absolute",
        height: "20px",
        width: "20px",
        backgroundColor: seen ? AppTheme.palette.primary.main : AppTheme.palette.grey[300],
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: seen ? "white" : "black",
        border: "1px solid white",
      }}
    >
      {seen ? (
        <VisibilityIcon style={{ width: "14px", height: "14px" }} />
      ) : (
        <VisibilityOffIcon style={{ width: "14px", height: "14px" }} />
      )}
    </Box>
  );
};
