import React from "react";
import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";

import Moment from "react-moment";

import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { useTranslation } from "react-i18next";
import noMessagesImg from "../../../assets/img/noMessagesImg.svg";
import { useDispatch } from "react-redux";
import { inboxActions } from "../../../services/inbox/inbox.actions";

import { ContentLoader } from "../content-loader/content-loader.component";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function InboxMessages({
  token,
  messages,
  setActiveQuotationThread,
  handleOpenModal,
  user,
  isMessagesLoading,
  getQuotationThreadDetails,
}) {
  const tracking = useTracking();
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const company_pk = useSelector((state) => state.profile.user.company);
  const messagesPage = useSelector((state) => state.inbox.messagesPage);
  const selectedMessagesFilterRfq = useSelector((state) => state.inbox.messagesFilterRfqPk);
  const messagesHasNextPage = useSelector((state) => state.inbox.messagesHasNextPage);
  const activeQuotationThread = useSelector((state) => state.inbox.activeQuotationThread);

  const getSelectedQuotation = (data) => {
    // Set the current quotation thread as active.
    setActiveQuotationThread(token, data);
    // Send a signal to the chat dialog to open.
    // It will autoload the quotation thread messages based
    // on the currently active quotation thread
    handleOpenModal(true, "isChatOpen");
    dispatch(inboxActions.clearAttachments());
    dispatch(inboxActions.setMinimizeChatWindow());
    // tracking open chat window
    tracking.trackEvent({
      scout_category: "manufacturer_messenger",
      scout_feature_name: "open_chat_window",
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["name"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date(),
    });
  };

  const onClickLoadMore = (event) => {
    event.preventDefault();
    dispatch(inboxActions.getInboxMessages(messagesPage + 1, selectedMessagesFilterRfq));
  };

  return (
    <div className="c-quotation-thread">
      {messages && messages.length ? (
        <>
          {messages.map((message) => {
            if (!message) {
              return <></>;
            }
            let quotationThreadActiveCls = "";
            if (activeQuotationThread && activeQuotationThread.pk === message.pk) {
              quotationThreadActiveCls = "active";
            }
            return (
              <Card
                className={`${classes.root} c-quotation-thread--card ${quotationThreadActiveCls}`}
              >
                <CardActionArea
                  onClick={() => {
                    handleOpenModal(true, "isChatOpen");
                    setActiveQuotationThread(null, null);
                    getQuotationThreadDetails(token, message.quotation?.pk).then(
                      getSelectedQuotation
                    );
                  }}
                >
                  <CardContent>
                    <Grid container direction="row" alignItems="center">
                      <div className="c-quotation-thread--status-container">
                        <Avatar src={message.instigator && message.instigator.picture}></Avatar>
                        <FiberManualRecordIcon
                          className="c-quotation-thread--status"
                          style={{
                            color:
                              message.instigator && message.instigator.is_online
                                ? "#4caf50"
                                : "#C7C7C7",
                          }}
                        />
                      </div>
                      <Grid
                        className="c-quotation-thread--info"
                        item
                        direction="column"
                        style={{ flexBasis: "80%" }}
                      >
                        <Grid
                          item
                          direction="row"
                          style={{ display: "flex" }}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography variant="h6" component="h6">
                            {message.instigator && message.instigator.name} · $
                            {message.quotation && message.quotation.total_price ? (
                              message.quotation.total_price
                            ) : (
                              <>
                                <span className="blurred-content"></span>
                              </>
                            )}
                          </Typography>
                        </Grid>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {message.rfq && message.rfq.display_name} · {message.status}
                        </Typography>
                      </Grid>
                      <Badge
                        color="primary"
                        className="c-quotation-thread--count"
                        badgeContent={message.unread_messages_count}
                      ></Badge>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className="c-quotation-thread--last-message"
                    >
                      {message.last_message && (
                        <Grid direction="row" style={{ display: "flex", width: "100%" }}>
                          <Grid item xs={10}>
                            <Typography
                              noWrap
                              variant="body2"
                              color="textSecondary"
                              component="p"
                              style={{
                                fontSize: "12px",
                                maxWidth: "97%",
                                textOverflow: "elipsis",
                              }}
                            >
                              {message.last_message.company_pk === company_pk
                                ? t("inbox:you")
                                : message.last_message.company}
                              {": "}

                              {message.last_message.event_type === "quotation_drop"
                                ? t("inbox:quotationDrop")
                                : ""}
                              {message.last_message.event_type === "contracted_rfq"
                                ? t("inbox:contractedMessage")
                                : ""}
                              {message.last_message.event_type === "not_contracted_rfq"
                                ? t("inbox:nonContractedMessage")
                                : ""}
                              {message.last_message.event_type === "canceled_rfq"
                                ? t("inbox:canceledRFQMessage")
                                : ""}
                              {message.last_message.event_type === "text_message"
                                ? message.last_message.content
                                : ""}
                              {message.last_message.event_type === "rate_supplier"
                                ? t("rating:rateSupplier")
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              variant="body3"
                              color="textSecondary"
                              component="p"
                              style={{
                                fontSize: "10px",
                                textAlign: "right",
                              }}
                            >
                              <Moment locale={profile.user.language} fromNow ago>
                                {message.last_message.created_at}
                              </Moment>
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            );
          })}
          {messagesHasNextPage ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="60px">
              {isMessagesLoading ? (
                <CircularProgress size={35} />
              ) : (
                <Button onClick={onClickLoadMore} fullWidth style={{ height: 60 }}>
                  {t("inbox:loadMore")}
                </Button>
              )}
            </Box>
          ) : null}
        </>
      ) : isMessagesLoading ? (
        <ContentLoader count={8} />
      ) : (
        <section className="c-quotation-thread--no-messages">
          <img src={noMessagesImg} />
          <div className="no-messages-div">
            <Typography variant="h6" gutterBottom>
              {t("inbox:noMessagesTitle")}
            </Typography>
            <Typography variant="body2">
              {user.type === "DO"
                ? t("inbox:buyerNoMessagesSubTitle")
                : t("inbox:supplierNoMessagesSubTitle")}
            </Typography>
          </div>
        </section>
      )}
    </div>
  );
}
