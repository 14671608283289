import { connect } from "react-redux";
import Table from "./project-part-info-table.component";
import { modalsActions } from "../../services/modals/modals.actions";
import { workspaceActions } from "../../services/workspace";
import { projectActions } from "@next/modules/project/redux";

const mapDispatchToProps = {
  handleOpenModal: modalsActions.showModal,
  setSelectedPartRows: projectActions.setSelectedPartRows,
  fetchCompanyPartList: workspaceActions.fetchCompanyParts,
  getPartById: workspaceActions.getPartById,
  clonePart: workspaceActions.clonePart,
  patchPart: workspaceActions.editPart,
  createPart: workspaceActions.createPart,
};

const mapStateToProps = (state: any) => {
  return {
    token: state.profile.token,
    project: state.project,
    partList: state.workspace.companyPartList,
    isLoading: state.marketplace.isLoading,
    isWorkspaceLoading: state.workspace.isWorkspaceLoading,
    isWorkspaceInitialLoading: state.workspace.isWorkspaceInitialLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
