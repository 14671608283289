import React from "react";
// MaterialUI Specific Imports
import { Box, Button, Grid, Typography } from "@mui/material";
import { GenericTextField } from "../../generic-text-field/generic-text-field.component";

export const RequestDemoForm = (props) => {
  const {
    handleSubmit,
    values: { email, company },
    t,
  } = props;

  return (
    <Grid className="c-request-demo-form">
      <Box my={4}>
        <h5>{t("requestDemo:demoTitle")}</h5>
        <h6>{t("requestDemo:demoSubTitle")}</h6>
      </Box>
      <form className="c-request-demo-form--container" onSubmit={handleSubmit}>
        <Grid container xs={4}>
          <GenericTextField
            value={email}
            name="email"
            label={t("requestDemo:demoFormEmailField")}
            variant="outlined"
          />
        </Grid>
        <Grid container xs={4}>
          <Box ml="16px">
            <GenericTextField
              value={company}
              name="company"
              label={t("requestDemo:demoFormCompanyField")}
              variant="outlined"
            />
          </Box>
        </Grid>
        <Grid container justifyContent="center" xs={4}>
          <Button variant="contained" type="submit" className="c-submit-button">
            <Typography>{t("requestDemo:demoFormSubmit")}</Typography>
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};
