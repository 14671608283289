import { Box, Divider } from "@mui/material";
import React from "react";
import ChangePasswordForm from "./change-password-form";
import TwoFactorAuthSettings from "./two-factor-auth-settings";

export const AuthenticationSettings = () => {
  return (
    <div>
      <TwoFactorAuthSettings />

      <Box mt="16px" mb="16px">
        <Divider />
      </Box>

      <ChangePasswordForm />
    </div>
  );
};
