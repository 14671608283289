import { Grid, Paper, Theme } from "@mui/material";
import React, { useCallback, useState } from "react";
import PublicRfqCreationSuccess from "./public-rfq-creation-success";
import PublicRfqCreationDescription from "./public-rfq-creation-description";
import PublicRfqCreationForm from "./public-rfq-creation-form";
import { PublicRFQFormValuesType } from "./public-rfq-creation-form/public-rfq-creation-form.types";
import { useDispatch } from "react-redux";
import { DiscordChannel, workspaceNextActions } from "../../redux";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    left: {
      paddingTop: theme.spacing(12.5),
      paddingLeft: theme.spacing(6.875),
      "& > *": {
        marginTop: "16px",
      },
      "& ul": {
        paddingLeft: "10px",
        paddingRight: theme.spacing(6.5),
      },
    },
    right: {
      padding: "56px",
      paddingTop: "12px",
      paddingBottom: "24px",
      [theme.breakpoints.down("md")]: {
        paddingTop: theme.spacing(5),
      },
    },
    paper: {
      padding: "24px",
    },
    body: {
      position: "relative",
      color: "white",
    },
  })
);

const PublicRfqCreationOLD = () => {
  const [submittedData, setSubmittedData] = useState<PublicRFQFormValuesType | undefined>();
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmit = useCallback((values: PublicRFQFormValuesType) => {
    setSubmittedData(values);

    dispatch(
      workspaceNextActions.createDiscordNotifyRequest({
        title: "AMQ_PUBLIC_RFQ_CREATED",
        channel_id: DiscordChannel.BUYER_ACTIVITY,
        ...values,
      })
    );
  }, []);

  const onClickSendAnotherOne = () => {
    setSubmittedData(undefined);
  };

  return (
    <Grid container className={classes.body}>
      <Grid item xs={12} md={5} className={classes.left}>
        <PublicRfqCreationDescription />
      </Grid>

      <Grid item xs={12} md={7} className={classes.right}>
        <Paper elevation={0} className={classes.paper}>
          {submittedData ? (
            <PublicRfqCreationSuccess
              name={submittedData?.name}
              onClickSendAnotherOne={onClickSendAnotherOne}
            />
          ) : (
            <PublicRfqCreationForm onSubmit={onSubmit} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PublicRfqCreationOLD;
