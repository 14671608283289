export enum BuyerRFQSTableField {
  RFQ_DISPLAY_NAME = "rfq_display_name",
  PARTS = "parts_count",
  PROCESSING_TIME = "num_processing_days",
  DEADLINE = "deadline",
  AWARDED_DATE = "winner_date",
  OWNER = "created_by",
  USERS = "collaborators",
  CREATED_ON = "created_at",
  VIEWS = "views_count",
  QUOTATION_IN_PROGRESS = "intent_to_bid_count",
  QUOTES = "quotations_count",
  SUPPLIERS = "targeted_suppliers",
  INTERNAL_NOTE = "notes",
  AWARDED_SUPPLIER = "winner",
  RESPONSES = "responses",
  PARTS_STATUS = "parts_status",
  STATE_AND_STATUS = "stateAndStatus",
  CREATE_ORDER = "createOrder",
  SUPPLIER_SELECTION_EXPORT = "supplier_selection_export",
  MARK_AS_COMPLETE = "mark_as_complete",
  COST_AND_LEAD_TIME = "lead_time",
  RATING = "rating",
  PURCHASE_ORDER = "purchase_order",
  CANCELLATION_DATE = "cancellation_date",
}

export enum BuyerRFQsTableSortField {
  RFQ_NAME = "rfq_name",
  RFQ_NUMBER = "rfq_number",
  PARTS_COUNT = "parts_count",
  OWNER_FIRST_NAME = "created_by__first_name",
  OWNER_LAST_NAME = "created_by__last_name",
  USERS = "collaborators",
  CREATED_AT = "created_at",
  DEADLINE = "deadline",
  INTERNAL_NOTE = "notes",
}
