import React from "react";
import * as S from "./form-field-error-typography.styled";

type Props = {
  text: string;
};

export const FormFieldErrorTypography: React.FC<Props> = ({ text }) => {
  return <S.StyledTypography variant="caption">{text}</S.StyledTypography>;
};
