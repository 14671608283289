// General Imports
import React from "react";

// MaterialUI Specific Imports

import { Grid, Typography } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export function ValidationCompanyAccepted({ t }) {
  return (
    <div className="c-validation-company-accepted">
      <Grid item>
        <Grid container>
          <Grid item className="check-circle-icon">
            <CheckCircleIcon />
          </Grid>
          <Grid item className="typography-container">
            <Typography variant="body2">{t("validated")}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
