import { GridColumns, GridRowData } from "@mui/x-data-grid-pro";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { CustomFileObject } from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload.types";
import { TenderData } from "@next/modules/marketplace/redux/types";
import { GenericUser } from "@next/modules/profile/redux";
import { PartUpload, ProjectCompanyPart, Rfq } from "@next/modules/project/redux";
import { GenericQueryParamsType, Modify } from "@next/utils/typeUtils";
import { InvitedSupplier } from "../components/invite-supplier/invite-supplier.types";
import {
  RFQFormValuesType,
  RFQSupplierType,
} from "../components/rfq-creation-form/rfq-creation.form.types";
import { GroupedCreateMultiContractData } from "@next/modules/project/components/multi-contract/create-multi-contract-table.types";
import { Config, QCResponse } from "../components/quote-comparison/types";
import { CancelTokenSource } from "axios";
import { DistributionForSupplierContacts } from "@next/modals/shared-supplier-contact-lists-modal";

export type WorkspaceNextState = {
  searchSuppliersLoading: boolean;
  searchSuppliersError: Error | undefined;
  searchSuppliers: ASLSupplierSearch;

  addASLLoading: boolean;
  addASLError?: Error | undefined;

  qaTopicsLoading: boolean;
  qaTopicsError?: Error | undefined;
  qaTopics: TopicType[];

  qaTopicLoading: boolean;
  qaTopicError?: Error | undefined;
  qaTopic?: TopicType;
  qaTopicLastToken?: string | undefined;

  internalChatTopicLoading: boolean;
  internalChatTopicError?: Error | undefined;
  internalChatTopic?: TopicType;

  createQaTopicLoading: boolean;
  createQaTopicError?: Error | undefined;

  answerQaTopicLoading: boolean;
  answerQaTopicError?: Error | undefined;

  contactUsLoading: boolean;
  contactUsError?: Error | undefined;
  customQuotationExportLoading: boolean;
  customQuotationExportError?: Error | undefined;
  inviteSupplierLoading: boolean;
  inviteSupplierError?: Error | undefined;
  isEditRfqDescription?: boolean;
  showQuotationVariance: boolean;

  ndaListLoading: boolean;
  ndaListError?: Error;
  ndaList: NDA[];

  updateNDALoading: boolean;
  updateNDAError?: Error | undefined;

  rfqForm: {
    fetchPartsLoading: boolean;
    fetchPartsError?: Error;
    partsList: ProjectCompanyPart[];
    partsListNext: string | null;

    fetchFavoriteSuppliersLoading: boolean;
    fetchFavoriteSuppliersError?: Error;
    favoriteSuppliersList: RFQSupplierType[];
    favoriteSuppliersListNext: string | null;
    favoriteSuppliersListCount: number;

    additionalFiles: Partial<FileData>[] | null;
  };
  favoriteSuppliers: FavoriteSupplier[];
  favoriteSuppliersLoading: boolean;
  favoriteSuppliersError?: Error | undefined;
  favoriteSuppliersCount: number;

  addFavoriteSupplierLoading: boolean;
  addFavoriteSupplierError?: Error | undefined;

  removeFavoriteSupplierLoading: boolean;
  removeFavoriteSupplierError?: Error | undefined;

  updateFavoriteSupplierLoading: boolean;
  updateFavoriteSupplierError?: Error | undefined;

  favoriteSupplierGroups: FavoriteSupplierGroup[];
  favoriteSupplierGroupsLoading: boolean;
  favoriteSupplierGroupsError?: Error | undefined;

  createFavoriteSupplierGroupLoading: boolean;
  createFavoriteSupplierGroupError?: Error | undefined;

  draftRFQs: DraftRFQ[];
  draftRFQsLoading: boolean;
  draftRFQsError?: Error | undefined;

  saveDraftRFQLoading: boolean;
  saveDraftRFQError?: Error | undefined;

  deleteDraftRFQLoading: boolean;
  deleteDraftRFQError?: Error | undefined;

  loadDraftRFQLoading: number;
  loadDraftRFQError?: Error | undefined;

  updateDraftRFQCollaboratorsLoading: boolean;
  updateDraftRFQCollaboratorsError?: Error | undefined;

  activeDraftRFQ: null | DraftRFQ;
  draftRFQsCount: number;

  createDiscoryNotifyLoading: boolean;
  createDiscoryNotifyError?: Error | undefined;

  contracts: any[];
  contractsLoading: boolean;
  contractsError?: Error | undefined;
  contractsCount: number;
  contractsLastQuery?: string | undefined;

  tenders: TenderData[];
  tendersLoading: boolean;
  tendersError: Error | undefined;

  myParts: any[];
  myPartsLoading: boolean;
  myPartsError?: Error | undefined;
  myPartsCount: number;
  myPartsLastQuery?: string | undefined;

  exportMyPartsLoading: boolean;
  exportMyPartsError?: Error | undefined;

  importMyPartsLoading: boolean;
  importMyPartsError?: Error | undefined;
  importMyPartsSuccess?: boolean;

  deletePartLoading: boolean;
  deletePartError?: Error | undefined;

  clonePartLoading: boolean;
  clonePartError?: Error | undefined;

  updatePartLoading: boolean;
  updatePartError?: Error | undefined;

  createPartLoading: boolean;
  createPartError?: Error | undefined;

  uploadMyPartsAttachments?: PartUpload[];
  uploadMyPartsAttachmentsLoading: boolean;
  uploadMyPartsAttachmentsError?: Error | undefined;

  contractMarkAsCompleteLoading: Record<number, boolean>;
  contractMarkAsCompleteError?: Error | undefined;

  exportBRPFilesLoading: Record<string, boolean>;
  exportBRPFilesError?: Error | undefined;

  qaMarAsReadLoading: number;
  qaMarkAsReadError?: Error | undefined;

  supplierContactsList: GenericUser[];
  fetchSupplierContactsListLoading: boolean;
  fetchSupplierContactsListError?: Error | undefined;

  inviteSupplierContactLoading: boolean;
  inviteSupplierContactError?: Error | undefined;

  updateSupplierContactsListLoading: boolean;
  updateSupplierContactsListError?: Error | undefined;

  axyaStatsSupplierLoading: boolean;
  axyaStatsSupplierError: Error | undefined;
  axyaStatsSupplier?: AxyaRFQStats | undefined;

  portalDataLoading: boolean;
  portalDataError: Error | undefined;
  portalData?: PortalData | undefined;

  updateRFQCollaboratorsLoading: boolean;
  updateRFQCollaboratorsError?: Error | undefined;

  updateRFQIsPublicLoading: boolean;
  updateRFQIsPublicError?: Error | undefined;

  updateRFQFollowupDateLoading: boolean;
  updateRFQFollowupDateError?: Error | undefined;

  updateRFQTargetedProfilesLoading: boolean;
  updateRFQTargetedProfilesError: Error | undefined;

  updatePortalQuoteNoQuoteLoading: boolean;
  updatePortalQuoteNoQuoteError: Error | undefined;

  uploadPortalQuoteFilesLoading: boolean;
  uploadPortalQuoteFilesError: Error | undefined;
  uploadPortalQuoteFiles?: PartUpload[];

  createPortalQuoteLoading: boolean;
  createPortalQuoteError: Error | undefined;

  updatePortalQuoteLoading: boolean;
  updatePortalQuoteError: Error | undefined;

  deletePortalQuoteLoading: boolean;
  deletePortalQuoteError: Error | undefined;

  exportQuotesComparisonSheetLoading: boolean;
  exportQuotesComparisonSheetError: Error | undefined;

  sendRevisionLoading: boolean;
  sendRevisionWithNoteLoading: boolean;
  revisionSendInProgress: boolean;
  sendRevisionError: Error | undefined;

  showRevisionStatusBar: boolean;

  globalSearchResults: GlobalSearchResult[];
  globalSearchLoading: boolean;
  globalSearchError?: Error | undefined;
  globalSearchNotFound: boolean;

  supplierQuotesLoading: boolean;
  supplierQuotesError?: Error | undefined;
  supplierQuotes: any[] | undefined;
  supplierQuotesCounts: SupplierQuotesCounts;

  marketplaceCounts: MarketplaceCounts;
  marketplaceCountsLoading: boolean;
  marketplaceCountsError?: Error | undefined;
  uploadRfqAttachmentsLoading: boolean;
  uploadRfqAttachmentsError?: Error | undefined;

  presignedS3UrlLoading: boolean;
  presignedS3UrlError: Error | undefined;
  presignedS3Url: PresignedS3Url | undefined;

  uploadS3FileLoading: boolean;
  uploadS3FileError: Error | undefined;
  fileUploadProgress: FileUploadProgress[];
  fileUploading: boolean;
  fileUploadSuccess: undefined;
  fileUploadError: Error | undefined;

  uploadSinglePartFileLoading: boolean;
  uploadSinglePartFileError?: Error | undefined;

  uploadMultiplePartFilesLoading: boolean;
  uploadMultiplePartFilesError: Error | undefined;

  addAddendaLoading: boolean;
  addAddendaError?: Error | undefined;

  quoteComparisonLoading: boolean;
  quoteComparisonError?: Error | undefined;
  quoteComparison: QCResponse | undefined;
  quoteComparisonLastQueryRfqId: number | undefined;

  updateQuoteComparisonConfigLoading: boolean;
  updateQuoteComparisonConfigError?: Error | undefined;

  proceedQuoteComparisonSelectionLoading: boolean;
  proceedQuoteComparisonSelectionError?: Error | undefined;
  sendNewVerificationEmailLoading: boolean;
  sendNewVerificationEmailError?: Error | undefined;

  checkEmailAlreadyExistsLoading: boolean;
  checkEmailAlreadyExistsError?: Error | undefined;

  createEasyRfqLoading: boolean;
  createEasyRfqError?: Error | undefined;

  syncERPQuotationLoading: boolean;
  syncERPQuotationError?: Error | undefined;

  rfqQuestionsStats: RFQQuestionsStats[];
  rfqQuestionsStatsLoading: boolean;
  rfqQuestionsStatsError?: Error | undefined;

  qaThreadDetails: TopicType;
  qaThreadDetailsLoading: boolean;
  qaThreadDetailsError?: Error | undefined;

  uploadQuotationAsBuyerLoading: boolean;
  uploadQuotationAsBuyerError?: Error | undefined;

  uploadQuotationFilesAsBuyerLoading: boolean;
  uploadQuotationFilesAsBuyerError?: Error | undefined;

  internalNotesMutateLoading: boolean;
  internalNotesMutateError: Error | undefined;
  updateInternalNoteDescLoading: boolean;
  updateInternalNoteDescError: Error | undefined;
  internalNotes: NoteType[];

  getInternalNotesLoading: boolean;
  getInternalNotesError: Error | undefined;

  assignInternalNoteLoading: boolean;
  assignInternalNoteError: Error | undefined;
  assignedInternalNote: any[];
  sendAirbusConfirmationLoading: boolean;
  sendAirbusConfirmationError?: Error | undefined;
  fetchOTTRequestLoading: boolean;
  fetchOTTRequestError: Error | undefined;
  OneTimeToken: string;
  getPortalUrlLoading: boolean;
  getPortalUrlError: Error | undefined;
  portalUrl: string;
};

export enum BuyerContractsTabType {
  ACTIVE = "active",
  ARCHIVED = "archived",
}

export type TopicType = {
  pk: number;
  token: string;
  subject: string;
  messages?: TopicMessageType[] | null;
  rfq: Partial<Rfq>;
  author: TopicAuthor;
  company: TopicMessageCompany;
  created_at: string | null;
  answered_at: string | null;
  is_private?: boolean;
  unseen_messages_count?: number;
  is_profile_complete?: boolean;
  current_user?: TopicAuthor;
};

export type TopicMessageType = {
  pk: number;
  qa: number;
  body: string;
  company: TopicMessageCompany;
  created_by: TopicAuthor;
  created_at: string;
  files?: FileData[];
  filesError?: Error | null;
  event_type: TopicMessageEventType;
  event_message: string;
  data?: any;
  footer_message?: string;
  footer_type?: string;
};

export enum TopicMessageEventType {
  REGULAR = "regular",
  PO_SENT = "po_sent",
  PO_REOPEN = "po_reopen",
  PO_ACKNOWLEDGED = "po_acknowledged",
  PO_DELIVERY_DATE_UPDATED = "po_delivery_date_updated",
  PO_SHIPPING_BATCH_CONFIRMED = "po_shipping_batch_confirmed",
  PO_REVISION = "po_revision",
  PO_MARKED_AS_COMPLETED = "po_marked_as_completed",
  PO_MARKED_AS_COMPLETED_IN_BULK = "po_marked_as_completed_in_bulk",
  PO_ON_TRACK = "po_on_track",
  PO_CANCELLED = "po_cancelled",
  ADDENDUM_ADDED = "addendum_added",
  PO_REVIEWED_CHANGES = "po_reviewed_changes",
  PO_PROPOSED_CHANGES = "po_proposed_changes",
  PO_ACKNOWLEDGEMENT_FAILURE = "auto_po_acknowledgment_failure",
  RFQ_BUYER_CREATED_QUOTE = "rfq_buyer_created_quote",
  RFQ_BUYER_MODIFIED_QUOTE = "rfq_buyer_modified_quote",
}
export enum TopicMessageFooterType {
  OUTLOOK_SYNC = "outlook_sync",
}

export enum FileContentType {
  PART_UPLOAD = "PART_UPLOAD",
  RFQ_ATTACHMENT = "RFQ_ATTACHMENT",
  ADDENDUM_ATTACHMENT = "ADDENDUM_ATTACHMENT",
}

export type TopicMessageCompany = {
  id: number;
  uuid: string;
  name: string;
  picture: string | null;
};

export type TopicAuthor = {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  full_name: string;
  company: number;
  user_type?: string;
  picture?: string | null;
  email?: string;
};

export type FetchTopicsInput = {
  rfqId: string;
};

export type FetchTopicInput = {
  token: string;
  isNotFirstFetch?: boolean;
};

export type FetchInternalChatTopicInput = {
  rfqId: number;
};

export type FetchPortalDataInput = {
  rfqToken: string;
};

export type UpdateQuoteNoQuoteStatusInput = {
  rfqToken: string;
  type: "itnb" | "itb" | "no_selection";
  reason?: string;
  description?: string;
  onSuccess?: () => void;
};

export type UploadPortalQuoteFilesInput = {
  rfqToken: string;
  files: File[];
};

export type UploadPortalQuoteFileInput = {
  rfqToken: string;
  file: File;
};

export type DeletePortalQuoteFileInput = {
  filePk: number;
};

export type CreatePortalQuoteInput = {
  rfqToken: string;
  additional_details?: string;
  files: CustomFileObject[];
  isRFI?: boolean;
  isSimpleRFQ?: boolean;
  total_cost?: number;
  currency?: string;
  delay_unit?: string;
  delay?: number;
};

export type UpdatePortalQuoteInput = {
  rfqToken: string;
  additional_details: string;
  files: CustomFileObject[];
  quotation_id: number;
  isRFI?: boolean;
  isSimpleRFQ?: boolean;
  total_cost?: number;
  currency?: string;
  delay_unit?: string;
  delay?: number;
};

export type CreateTopicInput = {
  rfqId: string;
  subject?: string;
  body?: string;
  is_private?: boolean;
};

export type AnswerTopicInput = {
  topicToken: string;
  answer: string;
  firstName?: string;
  lastName?: string;
  attachments?: File[];
  showToastOnMessage?: boolean;
};

export type PostInternalChatMessageInput = {
  rfqId: number;
  body: string;
  attachments?: File[];
};

export type QnATopicBody = {
  answer: string;
  profile_update?: {
    first_name: string;
    last_name: string;
  };
};

export type ContactUsInput = {
  subject?: string;
  msg: string;
  company_id?: string;
};

export type CustomQuotationExportInput = {
  quotationId: number;
  fileName: string;
};

export type InviteSupplierInput = {
  suppliers: InvitedSupplier[];
};

export type GridDataState = {
  rows: GridRowData[];
  columns: GridColumns;
};

export type NDA = {
  pk: number;
  company_name: string;
  created_at: string;
  is_approved?: boolean;
};

export type UpdateNDAInput = {
  pk: number;
};

export type FetchPartsInput = {
  query?: GenericQueryParamsType & {
    search?: string;
    project?: string;
    rfq?: string;
  };
  url?: string;
};

export type FetchRFQFavoriteSuppliersInput = {
  query?: GenericQueryParamsType & { name?: string; group?: number };
  url?: string;
  distributionForSupplierContacts?: DistributionForSupplierContacts;
};

export type FavoriteSupplier = {
  pk: number;
  name: string;
  phone_number: string;
  picture: string;
  groups: FavoriteSupplierGroup[];
  target_profiles?: GenericUser[];
  rfqPk?: number;
};

export type CreateFavoriteSupplierGroupInput = {
  groupName: string;
  assignSupplierPk?: number;
  onSuccess?: (newGroup: FavoriteSupplierGroup) => void;
};

export type FavoriteSupplierGroup = {
  pk: number;
  name: string;
};

export type FetchFavoriteSupplierInput = {
  query?: string;
};

export type AddFavoriteSupplierInput = {
  pk: number;
};

export type FetchDraftRFQsInput = {
  query?: GenericQueryParamsType & { project?: string };
};

export type LoadDraftRFQInput = {
  id: number;
  onSuccess?: () => void;
};

export type UpdateDraftRFQCollaboratorsInput = {
  collaborators: { email: string; note: string | null }[];
  draftId: number;
  onSuccess?: () => void;
};

export type CompanyUser = {
  id: number;
  username: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  company: number;
};

export type DraftRFQData = Modify<
  Partial<RFQFormValuesType>,
  {
    supporter?: string | null;
    net_ds?: string[];
    rfq_nda_pk?: number;
    all_parts_selected?: boolean;
    project?: string;
  }
>;

export type DraftRFQ = {
  id: number;
  user: CompanyUser;
  data: DraftRFQData;
  attachments?: Partial<FileData>[] | File[];
  nda?: Partial<FileData> | File;
  created_at: string;
  updated_at?: string;
  collaborators?: GenericUser[];
};

export enum DiscordChannel {
  PING_PONG = "pingpong",
  BUYER_ACTIVITY = "buyer_activity",
}

export type CreateDiscordNofityInput = {
  title: string;
  channel_id: DiscordChannel;
  onSuccess?: () => void;
};

export type FetchContractsInput = {
  query: string;
};

export type FetchMyPartsInput = {
  query: string;
};

export type UploadBulkPartFileInput = {
  pk: number;
  addedAttachments: File[];
};

export type UploadMyPartsAttachmentsInput = {
  files: File[];
};

export type ExportBRPFilesInput = {
  rfqId: string;
  rfqDisplayName: string;
  quoteId: number;
};

export type ContractMarkAsCompleteInput = {
  pk: number;
  isCompleted: boolean;
  onSuccess?: () => void;
};

export type QaMarkAsReadInput = {
  qaId: number;
  onSuccess?: () => void;
};

export type FetchSupplierContactsList = {
  supplierId: number;
  distributionForSupplierContacts?: DistributionForSupplierContacts;
};

export type InviteSupplierContactInput = {
  email: string;
  first_name: string;
  last_name: string;
  supplierId: number;
  onSuccess?: (user: GenericUser) => void;
};

export type UpdateSupplierContactsListInput = {
  target_profiles: GenericUser[];
  supplierId: number;
  onSuccess?: () => void;
};

export type UpdateRFQTargetedProfilesInput = {
  target_profiles: number[];
  supplierId: number;
  rfqId: number;
  onSuccess?: () => void;
};

export interface RFQItnbReason {
  slug: string;
  title: string;
}

export interface PortalData {
  company: Company;
  created_by: CreatedBy;
  rfq_display_name: string;
  created_at: Date;
  deadline: Date;
  pk: number;
  parts: Part[];
  bundle_file_url: string;
  quotation_template_url: string;
  bundle_file: BundleFile;
  quotation_template: BundleFile;
  quotation: Quotation;
  has_itb: boolean;
  has_itnb: boolean;
  rfq_itnb_reasons?: RFQItnbReason[];
  request_type: "rfq" | "rfi" | "simple_rfq";
  addenda?: Addenda[];
  additional_details?: string;
  additional_details_rich_text?: string;
  download_urls?: {
    attachments: BundleFile[];
    parts: BundleFile[];
  };
}

export interface BundleFile {
  pk: number;
  file_name: null | string;
  file_extension: null | string;
  file_url: null | string;
  file_size: number | null;
  date?: string;
}

export interface Company {
  id: number;
  uuid: string;
  name: string;
  picture: string;
}

export interface CreatedBy {
  id: number;
  username: string;
  email: string;
  first_name: null;
  last_name: null;
  full_name: string;
  picture: string;
  company: number;
}

export interface Part {
  pk: number;
  name: string;
  quantity: number;
}

export interface Quotation {
  pk: number;
  created_at: Date;
  files: BundleFile[];
  additional_details: null;
  currency?: string;
  is_revision_requested: boolean;
  lead_time: string;
  total_price: number;
}
export type UpdateRFQCollaboratorsInput = {
  collaborators: { email: string; note: string | null }[];
  rfqId: number;
  poId?: number;
  onSuccess?: () => void;
  refetchData?: boolean;
};

export type UpdateRFQIsPublicInput = {
  pk: number;
  isPublic: boolean;
  onSuccess?: () => void;
};

export type AxyaRFQStats = {
  rfqs_count: number;
  parts_count: number;
  total_gmv: number;
};

export type UpdateRFQFollowupDateInput = {
  followupDate: string | null;
  pk: number;
  onSuccess?: () => void;
};

export type ExportQuotesComparisonSheetInput = {
  rfqDisplayName: string;
  rfqPk: number;
};

export type SendRevisionNoteInput = {
  rfqId: number;
  companies?: number[];
  note: string;
  onSuccess?: () => void;
  parts?: any;
  revisionWithoutNote?: boolean;
  partsIDs?: number[];
};
export type requestDataTypes = {
  pk: number;
  parts: any;
  parts_to_remove?: number[];
};
export type payloadPartData = {
  quantity: number;
  pk: number;
  name: string;
  additional_details: string;
  alloy: number;
  processes: number[];
  files?: number[] | undefined;
  extra_fields?: object;
};
export type processesTypes = {
  id: number;
  label?: string;
  name: string;
  type?: string;
};

export type GlobalSearchInput = {
  term: string;
};
export type GlobalSearchResult = {
  id: number;
  name: string;
  result_type: "RFQ" | "CONTRACT" | "PURCHASE_ORDER";
};

export type FetchSupplierQuotesInput = {
  query: string;
};

export type SupplierQuotesCounts = {
  archived_count: number;
  count: number;
  on_hold_count: number;
  ongoing_count: number;
  waiting_count: number;
};

export type MarketplaceCounts = {
  tenders_total: number;
  tenders_unseen: number;
  public_total: number;
  public_unseen: number;
  private_total: number;
  private_unseen: number;
};

export type UploadRfqAttachmentsInput = {
  files: File[];
  rfqPk?: number | null;
  onSuccess?: (attachments: FileData[]) => void;
};

export type FetchPresignedS3Input = {
  file_name: string;
  file_size: number;
  file_type?: string;
  content_type: FileContentType;
  instance_id: number | null | undefined;
};

export type FinishMultipartS3UploadInput = {
  file_path: string;
  upload_id: string;
  parts: { etag: string; part_number: number }[];
};

export type SinglePresignedS3Url = {
  url: string;
  part_number: number;
};

export type PresignedS3Url = {
  upload_id: string;
  file_path: string;
  presigned_urls: SinglePresignedS3Url[];
};

export type FileChunkEntry = {
  name: string;
  url: string;
  fileChunk: Blob;
  partNumber: number;
};

export type HandleS3MultipartUploadInput = {
  file: File;
  instanceId: number | undefined | null;
  contentType: FileContentType;
  onSuccess: (url: string) => any;
};

export type FileChunkUploadProgress = {
  fileName: string;
  partNumber: number;
  uploaded: number;
  total?: number;
  pqueue?: any;
  source: CancelTokenSource;
};

export type FileChunkUploadSuccess = {
  fileName: string;
  emitter: any;
};

export type FileUploadProgress = {
  fileName: string;
  fileType?: string;
  uploaded: number;
  total: number;
  chunksUploaded: number[];
  percent: number;
  pqueue: any;
  sources: CancelTokenSource[];
};

export type DeleteFileUploadProgress = {
  filesName: string;
};

export type UploadS3FileInput = {
  url: string;
  file: File;
};

export type uploadSinglePartFileInput = {
  file: File;
  onSuccess?: (response: PartUpload) => void;
  onError?: (error: Error) => void;
};

export type uploadMultiplePartFilesInput = {
  files: File[];
  partPk: number;
  isRFQPart?: boolean;
  isOrderPart?: boolean;
  isRFQEditMode?: boolean;
};

export type AddAddendaInput = {
  rfq: number;
  companies: number[];
  note?: string;
  files?: FileData[];
  filesUrl?: string[];
  onSuccess?: () => void;
};

export type UploadAddendaAttachmentsInput = {
  rfq: number;
  files?: File[];
  onSuccess?: (fileUrls: string[]) => void;
};

export type RFQActivity = {
  name: string;
  viewed_at: string;
};

export type Addenda = {
  pk: number;
  rfq: number;
  note?: string;
  created_at?: string;
  files?: FileData[];
  views?: RFQActivity[];
  downloads?: RFQActivity[];
};

export type FetchQuoteComparisonInput = {
  rfq_id?: number;
  onSuccess?: (response: QCResponse | undefined) => void;
};

export type UpdateQuoteComparisonConfigInput = {
  config_id?: number;
  updated_data: Partial<Config["selected"]>;
};

export type ProceedQuoteComparisonSelectionInput = {
  rfq_pk?: number;
  contractData: {
    supplierId?: GroupedCreateMultiContractData["supplier_id"];
    parts: number[];
  }[];
};

export type SendNewVerificationEmailInput = {
  email: string;
  onSuccess?: () => void;
};

export enum StepsAfterCheckEmailAlreadyExists {
  REGISTER = "register",
  LOGIN = "login",
  VERIFICATION = "verification",
}

export type CheckEmailAlreadyExistsInput = {
  email: string;
  onSuccess?: () => void;
  fromPortal?: boolean;
};

export type CreateEasyRfqInput = {
  additional_details: string;
  additional_details_rich_text: string;
  deadline: string;
  rfq_name: string;
  attachments: Partial<FileData>[];
  fromDashboard?: boolean;
};

export type SyncERPQuotationInput = {
  quotation_id: number;
};

export type RFQQuestionsStats = {
  id: number;
  company: Company;
  unseen_messages_count: number;
};

export type FetchRFQQuestionsStatsInput = {
  rfqId: string;
};

export type FetchQAThreadDetailsInput = {
  qaId: string;
};

export type QuotationAsBuyer = {
  parts: {
    part_id: number;
    unit_price: number;
  }[];
  supplier_id: number;
  currency: string;
  delay: number;
  delay_unit: string;
  files: number[];
  fixed_charge: number;
  total_price: number;
};

export type UploadQuotationAsBuyerInput = {
  data: QuotationAsBuyer;
  quotation_id?: number;
  onSuccess?: (response: any) => void;
};

export type UploadQuotationFilesAsBuyerInput = {
  files: File[];
  onSuccess?: (response: any) => void;
};

export type InternalNotePayload = {
  notes?: NoteType[];
  deleted_notes?: number[];
  orderPk?: number;
};

export type NoteType = {
  color: string;
  id?: any;
  module: string;
  note?: string;
  internal_note_description?: string;
  pk?: number | string;
  title: string | number;
  isModified?: boolean;
  isNewlyAdded?: boolean;
  deleted_notes?: number[];
  total_usage?: number;
};
export type SelectInternalNote = {
  pk: number;
  notes: Object;
  isDetailPage?: boolean;
  onSuccess?: () => void;
  moduleType?: string;
};
export type ApprovedSupplierInput = {
  user: {
    email: string;
    first_name: string;
    last_name: string;
  };
  company: {
    name: string;
    quickbooks_vendor_id?: string;
  };
  currency?: string;
  onSuccess?: () => void;
};

export type SearchedCompany = {
  pk: number;
  name: string;
  picture: string;
  is_buyer_approved?: boolean;
  profiles: {
    email: string;
    first_name: string;
    last_name: string;
    picture: string;
  }[];
};

export type SearchedUser = {
  first_name: string;
  last_name: string;
  language: string;
  email: string;
};

export type ASLSupplierSearch =
  | {
      type: "user_found" | "non_company_user";
      results: SearchedCompany[];
      user: SearchedUser;
    }
  | {
      type: "user_buyer";
      results?: never;
      user?: never;
    }
  | {
      type: "companies" | "no_user" | "";
      results: SearchedCompany[];
      user?: never;
    };

export type SendAirbusConfirmationInput = {
  token: string;
  onSuccess?: () => void;
};

export type CreatePortalUrl = {
  rfq_id: number;
  onSuccess?: () => void;
};
