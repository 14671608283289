import { Box } from "@mui/material";
import React from "react";
import { CustomCircularProgress } from "../custom-circular-progress";

interface Props {}

const PageLoader: React.FC<Props> = () => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CustomCircularProgress />
    </Box>
  );
};

export default PageLoader;
