import { Box, Divider, Theme, Typography, alpha, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";

import { TopicType } from "@next/modules/workspace/redux/types";
import TopicAccordionWrapper from "./topic-accordion-wrapper";
import TopicTitle from "./topic-title";
import TopicMessage from "./topic-message";
import TopicMessageForm from "./topic-message-form";
import { useDispatch, useSelector } from "react-redux";
import { AnswerTopicInput, workspaceNextActions } from "@next/modules/workspace/redux";
import { useIsStaffUser } from "@next/modules/profile/hooks/useIsStaffUser";
import { createStyles, makeStyles } from "@mui/styles";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import { TopicMessageFooter } from "./topic-message/topic-message-footer";
import { useDropzone } from "react-dropzone";
import folderIcon from "@next/assets/folderIcon.png";
import { useTranslation } from "react-i18next";

type StyleProps = {
  requiresName?: boolean;
  verticalBorders?: boolean;
  isDragActive?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      position: "relative",
      width: "100%",
      height: "100%",
      display: "flex",
      borderLeft: ({ verticalBorders }) => (verticalBorders ? "1px solid #e0e0e0" : "none"),
      borderRight: ({ verticalBorders }) => (verticalBorders ? "1px solid #e0e0e0" : "none"),
      flexDirection: "column",
      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
      "& .MuiAccordion-root.Mui-expanded": {
        height: "100%",
        overflowY: "hidden",
      },
    },
    dropFileContainer: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 1,
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
      marginTop: "0px",
      borderWidth: 2,
      borderRadius: "16px",
      borderColor: theme.palette.primary.main,
      borderStyle: "dashed",
      backgroundColor: alpha(theme.palette.primary.main, 0.3),
      color: theme.palette.text.secondary,
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    innerContent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      width: "100%",
      height: "100%",
    },
    customIcon: {
      width: "40px",
      height: "40px",
      marginRight: "24px",
    },
    fullWidth: { width: "100%" },
    footer: {
      width: "100%",
      height: ({ requiresName }) => (requiresName ? "140px" : "unset"),
    },
    footerContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      gap: "16px",
      opacity: ({ isDragActive }) => (isDragActive ? 0.5 : 1),
    },
    dividerContainer: {
      paddingBottom: "16px",
    },
    divider: {
      width: "100%",
    },
    messages: {
      overflow: "auto",
      flex: 1,
      width: "100%",
      opacity: ({ isDragActive }) => (isDragActive ? 0.5 : 1),
    },
    disabled: { pointerEvents: "none", opacity: 0.3 },
    syncFooterStyle: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      margin: "10px 0px 10px 30px",
    },
  })
);

type Props = {
  topic: TopicType;
  initiallyOpen?: boolean;
  publicToken?: string;
  replyDisabled?: boolean;
  showToastOnMessage?: boolean;
  TitleComponent?: React.ReactNode;
  showExpandIcon?: boolean;
  disableAccordion?: boolean;
  isPortal?: boolean;
  verticalBorders?: boolean;
  customComponentInFooter?: React.ReactNode;
  rfqIdForInternalChat?: number;
};

export const Topic: React.FC<Props & StyleProps> = ({
  topic,
  initiallyOpen,
  publicToken,
  replyDisabled,
  showToastOnMessage = true,
  TitleComponent,
  showExpandIcon,
  disableAccordion,
  verticalBorders,
  customComponentInFooter,
  rfqIdForInternalChat,
}) => {
  const dispatch = useDispatch();
  const messagesContainerRef = useRef<null | any>(null);
  const isStaffUser = useIsStaffUser();
  const rfqDetails = useSelector(getRfqDetails);
  const theme = useTheme();
  const { t } = useTranslation();
  const onlyForMobile = useMediaQuery(theme.breakpoints.down(937));
  const [droppedFiles, setDroppedFiles] = React.useState<File[]>([]);

  // first time scroll to bottom after some delay
  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 1000);
  }, []);

  // scroll to bottom on new message
  useEffect(() => scrollToBottom(), [topic?.messages?.length]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scroll({
        top: messagesContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const onSubmitNewMessage = (values: Partial<AnswerTopicInput>) => {
    if (values.answer) {
      if (rfqIdForInternalChat) {
        dispatch(
          workspaceNextActions.postInternalChatMessageRequest({
            body: values.answer,
            rfqId: rfqIdForInternalChat,
            attachments: values.attachments,
          })
        );
      } else {
        dispatch(
          workspaceNextActions.answerQATopicRequest({
            topicToken: publicToken || topic?.token,
            answer: values.answer,
            firstName: values.firstName,
            lastName: values.lastName,
            attachments: values.attachments,
            showToastOnMessage,
          })
        );
      }
    }
  };

  const handleOnChangeExpanded = useCallback(
    (expanded) => {
      if (expanded && topic?.unseen_messages_count && !publicToken && !isStaffUser) {
        dispatch(
          workspaceNextActions.qaMarkAsReadRequest({
            qaId: topic.pk,
            onSuccess: () => {
              if (rfqIdForInternalChat) return;

              dispatch(
                workspaceNextActions.fetchRFQQuestionsStatsRequest({
                  rfqId: rfqDetails?.pk.toString(),
                })
              );
            },
          })
        );
      }
    },
    [topic?.unseen_messages_count, publicToken, isStaffUser]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => setDroppedFiles(acceptedFiles),
    noClick: true,
  });

  const classes = useStyles({
    requiresName: topic?.is_profile_complete === false,
    verticalBorders: verticalBorders,
    isDragActive,
  });

  return (
    <div {...getRootProps({ className: classes.root })}>
      <Box
        className={classes.dropFileContainer}
        style={{
          display: isDragActive ? "block" : "none",
        }}
      >
        <div className={classes.innerContent}>
          <img src={folderIcon} alt="customStartIcon" className={classes.customIcon} />
          <Typography variant="body1">{t("workspaceNext:QA:dragAndDropFiles")}</Typography>
        </div>
      </Box>
      <input {...getInputProps()} />
      <TopicAccordionWrapper
        showExpandIcon={showExpandIcon}
        expandedKey={topic?.pk?.toString()}
        initiallyOpen={initiallyOpen}
        title={
          <div className={classes.fullWidth}>
            {TitleComponent !== undefined ? TitleComponent : <TopicTitle topic={topic} />}
          </div>
        }
        onChangeExpanded={handleOnChangeExpanded}
        disableAccordion={disableAccordion}
      >
        <div className={classes.messages} ref={messagesContainerRef}>
          {topic?.messages?.map((message, index) => {
            return (
              <React.Fragment key={index}>
                {index > 0 ? (
                  <div className={classes.dividerContainer}>
                    <Divider className={classes.divider} />
                  </div>
                ) : null}

                <Box pt={index === 0 ? 2 : 0} mt={2}>
                  <TopicMessage message={message} key={index} index={index} />
                </Box>
                {message?.footer_type && message?.footer_message && (
                  <Box className={classes.syncFooterStyle}>
                    <TopicMessageFooter
                      footerMessage={message.footer_message}
                      footerType={message.footer_type}
                    />
                  </Box>
                )}
              </React.Fragment>
            );
          })}
        </div>

        <div className={classes.fullWidth}>
          <Divider
            sx={{
              marginRight: onlyForMobile ? -20 : 0,
              marginLeft: onlyForMobile ? -20 : 0,
            }}
          />
        </div>

        {publicToken || topic?.token !== null ? (
          <div className={classes.footer}>
            <Box
              className={`${classes.fullWidth} ${
                replyDisabled ? classes.disabled : ""
              } ${customComponentInFooter ? classes.footerContainer : ""}`}
              p={2}
            >
              <TopicMessageForm
                requiresName={topic?.is_profile_complete === false}
                onSubmit={onSubmitNewMessage}
                topic={topic}
                droppedFiles={droppedFiles}
              />

              {customComponentInFooter}
            </Box>
          </div>
        ) : null}
      </TopicAccordionWrapper>
    </div>
  );
};
