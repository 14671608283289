import { GenericObject } from "@next/modules/profile/redux";
import {
  CompanyCapabilityType,
  NormalizedCapabilitiesType,
} from "@next/modules/company-capabilities/redux";
import { Capability } from "./company-capabilities.types";

const getInitialCapabilitiesState = (capabilities: Capability[]) => {
  const initialCapabilitiesState: Capability | GenericObject = {};

  capabilities?.length > 0 &&
    capabilities.forEach((capabilities: Capability) => {
      initialCapabilitiesState[capabilities.name] = capabilities;
    });

  return initialCapabilitiesState;
};

function getNormalizedCapabilitiesTypes(arr: CompanyCapabilityType[]): NormalizedCapabilitiesType {
  const normalizedArr = Array.isArray(arr) ? arr : [arr];
  return normalizedArr.reduce<NormalizedCapabilitiesType>((acc, item) => {
    // Extract the parent key
    const parentKey = item.parent;

    // If the parent key is null, add the item to the accumulator
    if (parentKey === null) {
      acc[item.slug] = [item];
    } else {
      // If the parent key doesn't exist in the accumulator, create a new array for it
      if (!acc[parentKey]) {
        acc[parentKey] = [];
      }

      // Remove the parent property from the item
      const { parent, ...rest } = item;

      // Add the item to the appropriate parent key
      acc[parentKey].push(rest);
    }

    return acc;
  }, {} as NormalizedCapabilitiesType);
}

export type InitialCapabilities = {
  [key: string]: { name: string; value: number }[];
};

function getInitialCapabilities(capabilities?: Capability[]): InitialCapabilities {
  if (!capabilities) {
    return {};
  }
  return capabilities.reduce((acc, capability) => {
    const { pk, name, type } = capability;
    const key = `${type.parent}-${type.pk}`;

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push({ name, value: pk });

    return acc;
  }, {} as InitialCapabilities);
}

type CapabilityPair = { name: string; value: number };
function getPKsFromFormikValues(values: InitialCapabilities) {
  const allValues = Object.values(values);
  const allPairs: CapabilityPair[] = ([] as CapabilityPair[]).concat(
    ...(allValues as CapabilityPair[][])
  );
  return allPairs.map((pair) => pair.value);
}

export {
  getInitialCapabilitiesState,
  getNormalizedCapabilitiesTypes,
  getInitialCapabilities,
  getPKsFromFormikValues,
};
