import React from "react";
import AccordionWrapper, {
  AccordionWrapperProps,
} from "@next/components/accordion-wrapper/accordion-wrapper";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    "&.Mui-expanded": { marginTop: 0 },
    border: `1px solid ${theme.palette.divider}`,
    "&.MuiAccordion-rounded": { borderRadius: "16px" },
    overflowX: "hidden",
  },
  accordionDetailsRoot: {
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    border: "none",
    paddingLeft: theme.spacing(0),
    paddingTop: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  accordionSummaryContent: {
    "&.Mui-expanded": { marginTop: "12px" },
  },
}));

const TopicAccordionWrapper: React.FC<AccordionWrapperProps> = (props) => {
  const classes = useStyles();

  return (
    <AccordionWrapper
      {...props}
      accordionClasses={{
        root: `${classes.accordionRoot} ${props.accordionClasses?.root}`,
      }}
      accordionDetailsClasses={{
        root: `${classes.accordionDetailsRoot} ${props.accordionDetailsClasses?.root}`,
      }}
      accordionSummaryClasses={{ content: classes.accordionSummaryContent }}
    >
      {props.children}
    </AccordionWrapper>
  );
};

export default TopicAccordionWrapper;
