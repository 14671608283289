import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import i18n from "assets/configi18n/i18n";
import { isDate, parseISO } from "date-fns";
import { styled } from "@material-ui/core";

const MUIDatePickerBase: React.FC<
  KeyboardDatePickerProps & { disableIgnoreTimezone?: boolean }
> = ({ value, onChange, disableIgnoreTimezone, ...restProps }) => {
  const language = i18n.language;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        size="small"
        color="secondary"
        style={{ width: "100%" }}
        inputVariant="outlined"
        format={language === "en" ? "yyyy/MM/dd" : "dd/MM/yyyy"}
        InputAdornmentProps={{ position: "end" }}
        disablePast
        autoOk
        okLabel=""
        cancelLabel=""
        value={parseISO(value as any)}
        onChange={(date) => {
          onChange(disableIgnoreTimezone ? date : getDateIgnoreTimezone(date));
        }}
        {...restProps}
      />
    </MuiPickersUtilsProvider>
  );
};

const StyledMUIDatePicker = styled(MUIDatePickerBase)(() => ({
  "& .MuiOutlinedInput-adornedEnd": {
    paddingRight: 0,
  },
}));

export default StyledMUIDatePicker;

/**
 * Adjust the the date to UTC 00:00 time regardless input by date picker keyboard or picked by mouse
 *
 * @param {Date|null} date
 * @returns Date|null
 */
const getDateIgnoreTimezone = (date: any) => {
  let ignoredTimezoneDate = date;
  try {
    ignoredTimezoneDate =
      date && date.getUTCHours() !== 0
        ? ((theDate) => new Date(theDate.getTime() - theDate.getTimezoneOffset() * 60 * 1000))(
            new Date(date)
          )
        : date;
  } catch (error) {
    console.error("Timezone error");
    ignoredTimezoneDate = date;
  }

  if (!isDate(ignoredTimezoneDate)) {
    ignoredTimezoneDate = date;
  }

  return ignoredTimezoneDate;
};
