import React from "react";
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { withTranslation } from "react-i18next";
import WorkSpaceTour from "../../tour-guide/workspace-tour/workspace-tour.component";
import { modalsActions } from "../../../services/modals/modals.actions";
import { connect } from "react-redux";

const EmptyWorkspaceModal = (props) => {
  const { t } = useTranslation("workspaceTour");
  const tracking = useTracking();
  const { handleOpenModal, profile, isAppTourOpened } = props;

  const handleOpenTour = () => {
    handleOpenModal(true, "isWorkSpaceTourOpen");
    // tracking open workspace tour click
    tracking.trackEvent({
      scout_category: "tour_guide",
      scout_feature_name: "open_workspace_tour",
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["name"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date(),
    });
  };

  return (
    <Grid item xs={12} className="new-parts">
      {props.children}
      <Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
          className="c-empty-workspace--grid"
        >
          <h3>{t("welcomeText")}</h3>
          <p>{t("welcomeDescription")}</p>
          <WorkSpaceTour />
          <button
            className="c-empty-workspace--tour-btn c-tour-btn"
            onClick={() => handleOpenTour()}
          >
            {t("workspaceTour:tourButton")}
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  handleOpenModal: modalsActions.showModal,
};
const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmptyWorkspaceModal));
