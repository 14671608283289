// General Import
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// Material UI Import
import { Grid, Typography } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { fieldToTextField } from "formik-material-ui";

// Component Import
import { GenericAutoCompleteTextField } from "../../generic-autocomplete-text-field/generic-autocomplete-text-field.component";

const useStyles = makeStyles(() => ({
  iconOutlined: {
    "& svg": {
      "& path": {
        d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')",
      },
    },
  },
}));

export const TagOptions = (props) => {
  const { label, textFieldProps, onTagChange } = props;
  const { t } = useTranslation("workspace");
  const { tags } = useSelector((state) => state.partConfiguration);

  const { ...field } = fieldToTextField(props);
  const { name } = field;

  const classes = useStyles();
  const {
    form: { setFieldValue },
  } = props;
  return (
    <Autocomplete
      id={name}
      renderOption={(props, option) => (
        <li {...props}>
          <Grid container direction="column" className="c-tag-options-container">
            <Grid>
              <Typography variant="subtitle2">{option.name}</Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" className="c-tag-options-description">
                {option.description}
              </Typography>
            </Grid>
          </Grid>
        </li>
      )}
      options={tags}
      getOptionLabel={(slug) => tags.find((tag) => tag.slug === slug)?.name || ""}
      {...props}
      {...field}
      onChange={(_, v) => {
        if (!v) {
          setFieldValue(name, null);
        } else {
          setFieldValue(name, v.slug);
        }
        if (typeof onTagChange === "function") onTagChange(v || null);
      }}
      style={{ width: "100%" }}
      classes={{
        clearIndicatorDirty: classes.clearIndicator,
      }}
      renderInput={(params) => (
        <GenericAutoCompleteTextField {...params} {...textFieldProps} name={name} label={label} />
      )}
    />
  );
};
