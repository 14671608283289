import React, { useEffect, useState } from "react";
import { useTracking } from "react-tracking";

import AutocompleteOptions from "../../autocomplete-options/autocomplete-options.component";
// Material ui component and icons
import { Grid } from "@mui/material";

import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { inboxActions } from "services/inbox";

export const FilterQuotationByRfq = ({ RFQList, token, t, ...rest }) => {
  const tracking = useTracking();
  const dispatch = useDispatch();
  const [formattedRfqs, setFormattedRfqs] = useState([]);
  const selectedMessagesFilterRfq = useSelector((state) => state.inbox.selectedMessagesFilterRfq);

  // formatting rfq response to reuse autocomplete component
  useEffect(() => {
    if (
      RFQList &&
      Object.keys(RFQList).length &&
      RFQList["InProgress&status=Waiting&status=Contract"] &&
      RFQList["InProgress&status=Waiting&status=Contract"].length
    ) {
      const rfqs = RFQList["InProgress&status=Waiting&status=Contract"]
        .map((rfq) => {
          rfq["type"] = rfq.status;
          rfq["name"] = rfq.rfq_display_name;
          return rfq;
        })
        .sort((a, b) => -b.type.localeCompare(a.type));
      setFormattedRfqs(rfqs);
    }
  }, [RFQList]);

  // filter quotation threads
  const handleSelectedRfq = (rfq) => {
    // fetch messages with page = 1, pagination will work in the inbox modal
    dispatch(inboxActions.getInboxMessages(1, rfq));
  };

  const filterQuotationsFocusHandle = () => {
    tracking.trackEvent({
      scout_category: "manufacturer_messenger",
      scout_feature_name: "filter_quotation_thread_by_rfqs",
      scout_company: rest["company"]["name"],
      scout_username: rest["user"]["username"],
      scout_user_type: rest["user"]["type"],
      scout_date: new Date(),
    });
  };

  return (
    <Formik initialValues={{ filter: [] }} enableReinitialize={true}>
      {() => {
        return (
          <Form className="c-filter-quotations">
            <Grid item xs={12}>
              <Field
                name="filter"
                label={`${t("filter")} ${
                  selectedMessagesFilterRfq
                    ? selectedMessagesFilterRfq?.rfq_display_name
                    : `: ${t("all")}`
                }`}
                component={AutocompleteOptions}
                onFocus={() => filterQuotationsFocusHandle()}
                staticsData={formattedRfqs}
                handleSelectedRfq={handleSelectedRfq}
              />
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
