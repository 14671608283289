import i18next from "i18next";
import * as yup from "yup";
import { PublicRFQFormValuesType } from "./public-rfq-landing-step-form.types";

export const getPublicRfqFormInitialValues = (): PublicRFQFormValuesType => ({
  name: "",
  email: "",
  additional_details: "",
  attachments: [],
});

export const getPublicRfqFormValidationSchema = (t: i18next.TFunction) =>
  yup.object().shape({
    name: yup.string().required(t("common:error:thisFieldIsRequired")),
    email: yup.string().email().required(t("common:error:thisFieldIsRequired")),
    additional_details: yup.string(),
    attachments: yup.string().required(t("workspaceNext:publicRfq:form:thisFileIsRequired")),
  });
