import { Box, Typography, styled } from "@mui/material";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

export const RootBox = styled(Box)`
  width: 100%;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 48px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: white;
`;

export const StyledTypography = styled(Typography)`
  margin-left: 12px;
`;

export const StyledDiv = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  margin-top: 48px;
  margin-bottom: 24px;
`;

export const StyledDataGridPro = styled(DataGridProV5)({
  marginLeft: "12px",
  width: "100%",
  height: "700px",
  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": { display: "none" },
});
