import React from "react";

import { Formik } from "formik";
import { ChangePasswordFormBase } from "./change-password-form.base";
import { ChangePasswordFormValues } from "./change-password-form.types";
import { validationSchema } from "./change-password-form.definitions";
import { profileNextActions } from "@next/modules/profile/redux";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";

const ChangePasswordForm: React.FC = () => {
  const dispatch = useDispatch();
  const initialValues: ChangePasswordFormValues = {
    old_password: "",
    new_password1: "",
    new_password2: "",
  };

  const handleSubmit = (values: ChangePasswordFormValues) => {
    dispatch(profileNextActions.updateUserPasswordRequest(values));
  };

  return (
    <>
      <Typography variant="h6">Change your password</Typography>

      <Box mt="16px">
        <Formik
          render={(props) => <ChangePasswordFormBase {...props} />}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        />
      </Box>
    </>
  );
};

export default ChangePasswordForm;
