import * as yup from "yup";

import { t } from "assets/configi18n/i18n";
import { Supplier } from "../suppliers-table/suppliers-table-v5.types";

const validationSchema = yup.object().shape({
  suppliers: yup.array().required(t("userOnboarding:thisFieldIsRequired")),
  body: yup.string().required(t("userOnboarding:thisFieldIsRequired")),
});

type TargetedSupplierIdsParams = {
  targetedSuppliersName: string[];
  targetedSuppliers: Supplier[];
};

const getTargetedSupplierIds = ({
  targetedSuppliers,
  targetedSuppliersName,
}: TargetedSupplierIdsParams) => {
  const targetedSupplierNameSet = new Set(targetedSuppliersName);
  const targetedSupplierIds: number[] = targetedSuppliers
    .filter((supplier: Supplier) => targetedSupplierNameSet.has(supplier.name))
    .map((supplier: Supplier) => supplier.id);

  return targetedSupplierIds;
};

export { validationSchema, getTargetedSupplierIds };
