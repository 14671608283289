import React from "react";
import { Button, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import * as S from "./error-screen.styled";

type Props = {
  message: string;
};

export const ErrorScreen: React.FC<Props> = ({ message }) => {
  return (
    <S.StyledBox>
      <Typography variant="h5">{message}</Typography>
      <Button onClick={() => window.location.reload()}>
        {<RefreshIcon fontSize="large" color="primary" />}
      </Button>
    </S.StyledBox>
  );
};
