import { Avatar, Box, Theme, Typography } from "@mui/material";
import { getInitials } from "@next/utils/stringUtils";
import React, { useCallback, useMemo, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";

type StyleProps = {
  horizontal?: boolean;
  height?: number;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: ({ horizontal }) =>
      horizontal
        ? {
            display: "flex",
            alignItems: "center",
          }
        : {},
    avatar: {
      height: ({ height }) => height || 92,
      maxWidth: 500,
      width: "fit-content",
      borderRadius: 8,

      "& img": {
        width: "unset",
      },
    },
    border: {
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    initials: {
      minWidth: ({ height }) => height || 112,
      textAlign: "center",
    },
    companyName: {
      marginTop: ({ horizontal }) => (horizontal ? 0 : theme.spacing(2)),
      marginLeft: ({ horizontal }) => (horizontal ? theme.spacing(1.5) : 0),
    },
  })
);

type Props = {
  avatarSrc?: string;
  companyName?: string;
  showName?: boolean;
  horizontal?: boolean;
  height?: number;
  bordered?: boolean;
};

const CompanyAvatar: React.FC<Props> = ({
  avatarSrc,
  companyName = "N/A",
  showName = true,
  horizontal = false,
  height,
  bordered = true,
}) => {
  const classes = useStyles({ horizontal, height });
  const initials = useMemo(() => getInitials(companyName), [companyName]);
  const [loaded, setLoaded] = useState(false);

  const onLoaded = useCallback(() => setLoaded(true), []);

  return (
    <Box className={classes.root}>
      <Avatar
        imgProps={{
          onLoad: onLoaded,
          onError: onLoaded,
        }}
        variant="rounded"
        className={`${classes.avatar} ${loaded && bordered ? classes.border : ""}`}
        src={avatarSrc}
      >
        <div className={classes.initials}>{initials}</div>
      </Avatar>
      {showName ? (
        <Typography variant={height ? "body2" : "h5"} className={classes.companyName}>
          {companyName}
        </Typography>
      ) : null}
    </Box>
  );
};

export default CompanyAvatar;
