import { Box, Button, Chip, Typography } from "@mui/material";
import LinkButton from "@next/components/link-button/link-button";
import { GenericTextInput } from "components/utils/input/text";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { initialValues, validationSchema } from "./two-factor-manage-form.definitions";
import LockIcon from "@mui/icons-material/Lock";
import { AppTheme } from "layout/AppTheme";
import { useDispatch, useSelector } from "react-redux";
import { TwoFAActionType, TwoFAAuthType, profileNextActions } from "@next/modules/profile/redux";
import {
  selectUpdate2FAAuthLoading,
  selectUpdate2FAAuthResult,
} from "@next/modules/profile/redux/selectors";
import CustomButton from "@next/components/custom-button/custom-button";
import { useTranslation } from "react-i18next";

type Props = {
  onClose: () => void;
  actionType?: TwoFAActionType;
};

export const TwoFactorManageForm: React.FC<Props> = ({ onClose, actionType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const update2FAAuthLoading = useSelector(selectUpdate2FAAuthLoading);
  const update2FAAuthResult = useSelector(selectUpdate2FAAuthResult);
  const backupCodes = update2FAAuthResult?.backup_codes;
  const isBackupCodesAvailable = backupCodes?.length > 0;

  const [isResendDisabled, setIsResendDisabled] = useState(false);

  const onSubmit = async (values: any) => {
    if (values?.code) {
      dispatch(
        profileNextActions.update2FAAuthRequest({
          actionType,
          isConfirm: true,
          authType: TwoFAAuthType.EMAIL,
          code: values.code,
        })
      );
    }
  };

  const onClickResend = () => {
    setIsResendDisabled(true);

    dispatch(
      profileNextActions.update2FAAuthRequest({
        actionType,
        authType: TwoFAAuthType.EMAIL,
      })
    );

    setTimeout(function () {
      setIsResendDisabled(false);
    }, 5000);
  };

  return isBackupCodesAvailable ? (
    <div>
      <Box display="flex" flexDirection={"column"}>
        <Box display={"flex"} alignItems="center">
          <Box ml="8px">
            <Chip
              icon={<LockIcon style={{ color: "white" }} />}
              style={{
                backgroundColor: AppTheme.palette.success.dark,
                color: "white",
              }}
              label={t("profile:userProfile:2FAisNowActivated")}
            />
          </Box>
        </Box>

        <Box mt="16px">
          <Typography variant="body1">
            <b>{t("profile:userProfile:recoveryCodes")}</b>
            <br />
            <br />
            <ul>{backupCodes?.map((code: string) => <li>{code}</li>)}</ul>
            <br />
            {t("profile:userProfile:saveYourRecoveryCode")}
          </Typography>
        </Box>
      </Box>
      <br />
      <br />
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" color="primary" size="large" onClick={onClose}>
          {t("common:close")}
        </Button>
      </Box>
    </div>
  ) : (
    <div>
      <Typography variant="body1">
        {t(
          actionType === TwoFAActionType.ACTIVATE
            ? "profile:userProfile:enterTheAuthenticationCode"
            : "profile:userProfile:bySecurityMeasure"
        )}
      </Typography>

      <Box mt="16px">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <GenericTextInput name="code" label="" placeholder="Code" />

            <Box mt="16px">
              <CustomButton
                loading={update2FAAuthLoading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
              >
                {t(
                  actionType === TwoFAActionType.ACTIVATE
                    ? "profile:userProfile:activate"
                    : "profile:userProfile:deActivate"
                )}
              </CustomButton>
            </Box>
          </Form>
        </Formik>
      </Box>

      <Box mt={"16px"} display="flex" justifyContent={"center"}>
        <Typography variant="body1">{t("profile:userProfile:dontSeeTheEmail")}</Typography>

        <Box ml="8px">
          <LinkButton onClick={onClickResend} disabled={isResendDisabled}>
            <Typography variant="body1">
              {t(
                isResendDisabled
                  ? "profile:userProfile:emailSent"
                  : "profile:userProfile:sendANewOne"
              )}
            </Typography>
          </LinkButton>
        </Box>
      </Box>
    </div>
  );
};
