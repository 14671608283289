import { Box, Typography } from "@mui/material";
import CustomButton from "@next/components/custom-button/custom-button";
import { TwoFAActionType, TwoFAAuthType, profileNextActions } from "@next/modules/profile/redux";
import {
  selectFetchUserActive2FAsResult,
  selectUpdate2FAAuthLoading,
} from "@next/modules/profile/redux/selectors";
import { AppTheme } from "layout/AppTheme";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const TwoFactorAuthSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const update2FAAuthLoading = useSelector(selectUpdate2FAAuthLoading);
  const userActive2FAsResult = useSelector(selectFetchUserActive2FAsResult);
  const is2FAActivated = userActive2FAsResult?.find(
    (item: any) => item.name === TwoFAAuthType.EMAIL
  );

  const onClickButton = () => {
    dispatch(
      profileNextActions.update2FAAuthRequest({
        actionType: is2FAActivated ? TwoFAActionType.DEACTIVATE : TwoFAActionType.ACTIVATE,
        authType: TwoFAAuthType.EMAIL,
      })
    );
  };

  return (
    <div>
      <Typography variant="h6">
        <Box display="flex" alignItems="center">
          {t(
            is2FAActivated
              ? "profile:userProfile:twoFactorAuthentication"
              : "profile:userProfile:twoFactorAuthenticationDisabled"
          )}
        </Box>
      </Typography>

      <Box mt="16px">
        <Typography color="textSecondary" variant="body1">
          {t("profile:userProfile:weWillAskForAVerificationCode")}
        </Typography>
      </Box>

      <Box mt="16px">
        <CustomButton
          loading={update2FAAuthLoading}
          variant={is2FAActivated ? "outlined" : "contained"}
          color={is2FAActivated ? "secondary" : "primary"}
          style={
            is2FAActivated
              ? {
                  borderColor: AppTheme.palette.error.main,
                  color: AppTheme.palette.error.main,
                }
              : undefined
          }
          size="large"
          onClick={onClickButton}
        >
          {t(
            is2FAActivated
              ? "profile:userProfile:deActivate2FA"
              : "profile:userProfile:activateTwoFactorAuthentication"
          )}
        </CustomButton>
      </Box>
    </div>
  );
};

export default TwoFactorAuthSettings;
