import { Box, CircularProgress, Theme, Typography } from "@mui/material";
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    bottom: {
      color: theme.palette.grey[200],
    },
    top: {
      animationDuration: "550ms",
      position: "absolute",
      left: 0,
    },
    circle: {
      strokeLinecap: "round",
    },
    textCenter: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

type Props = {
  value: number;
};

export const BulkPartImportLoaderCircularProgress: React.FC<Props> = ({ value }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size="88px"
        thickness={1.2}
        value={100}
      />

      <CircularProgress
        size="88px"
        thickness={1.2}
        className={classes.top}
        variant="indeterminate"
        disableShrink
        value={10}
        classes={{
          circle: classes.circle,
        }}
      />

      <Box className={classes.textCenter}>
        <Typography variant="h5" component="div">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};
