/*
 *   Validation Page
 *   Documentation Reference: https://grad4.atlassian.net/wiki/spaces/FDS/pages/243171343/Validation+page
 *   This component is used whenever we want to re-assure the user that something was in fact done
 *   for instance sending a RFQ to the marketplace or sending a confirmation email.
 *   This component is doing the routing to one (or optionally two) different part of the application.
 */

// General IMport
import React from "react";
import ValidationPage from "../ValidationPage";
import { frontendUrl } from "../../urls";

// Assets
import accessDeniedImg from "../../assets/img/access-denied.png";

function NotAuthorizedPage(props) {
  return (
    <ValidationPage
      title={props.t("error401Title")}
      body={props.t("error401Body")}
      primaryText={props.t("SIDE_BAR:logout")}
      primaryUrl={frontendUrl.login}
      image={accessDeniedImg}
    />
  );
}

export default NotAuthorizedPage;
