import { Theme } from "@mui/material";
import { AppTheme } from "layout/AppTheme";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [`& input[type="number"]`]: {
        "-moz-appearance": "textfield",
      },
      [`& input[type="number"]::-webkit-outer-spin-button`]: {
        "-webkit-appearance": "none",
        margin: 0,
      },
      [`& input[type="number"]::-webkit-inner-spin-button`]: {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
    loaderContainer: {
      display: "grid",
      placeItems: "center",
      position: "absolute",
      top: "73px",
      left: 0,
      right: 0,
      bottom: 0,
    },
    header: {
      position: "sticky",
      top: 0,
      backgroundColor: theme.palette.common.white,
      zIndex: 10,
    },
    rfqDeleteIcon: {
      color: theme.palette.common.black,
    },
    rfqIcon: {
      color: AppTheme.palette.primary.main,
      marginRight: theme.spacing(1.5),
    },
    form: {
      padding: `${theme.spacing(6)} ${theme.spacing(6.5)} 0 ${theme.spacing(6.5)}`,
      height: "100vh",
    },
    columnHeading: {
      fontWeight: 700,
      color: theme.palette.common.black,
      margin: theme.spacing(1.25, 0),
    },
    partCloseIcon: {
      fontSize: 18,
    },
    partRow: {
      marginBottom: theme.spacing(2),
      display: "flex",
      alignItems: "center",
    },
    pointer: {
      cursor: "pointer",
    },
    sectionDivider: {
      margin: theme.spacing(6, -6.5),
    },
    row: {
      display: "flex",
      gap: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    closeIconBtn: {
      padding: theme.spacing(0.875),
    },
    infoText: {
      color: theme.palette.action.active,
    },
    rfqAttachmentBtn: {
      height: 40,
    },
    infoIcon: {
      marginLeft: theme.spacing(1.25),
      color: theme.palette.text.disabled,
      marginTop: theme.spacing(-0.25),
    },
    footer: {
      position: "sticky",
      bottom: 0,
      backgroundColor: theme.palette.common.white,
      display: "flex",
      flexDirection: "column",
      zIndex: 10,
    },
    preLine: {
      whiteSpace: "pre-line",
    },
    groupsContainer: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      marginTop: theme.spacing(3),
    },
    syncIcon: {
      color: theme.palette.text.secondary,
    },
    deleteBtn: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },
    lastListItem: {
      marginBottom: 0,
    },
    listItem: {
      marginBottom: theme.spacing(1.5),
    },
  })
);
