import { connect } from "react-redux";
import { QuotationDialog } from "./quotation-dialog.component";
import { marketplaceActions } from "../../services/marketplace";
import { modalsActions } from "../../services/modals/modals.actions";
import { inboxActions } from "../../services/inbox";

const mapDispatchToProps = {
  fetchCompanyQuotations: marketplaceActions.fetchCompanyQuotations,
  getBuyerStatsByRfqId: marketplaceActions.getBuyerStatsByRfqId,
  getQuotationThreadDetails: inboxActions.getQuotationThreadDetails,
  setActiveQuotationThread: inboxActions.setActiveQuotationThread,
  handleOpenModal: modalsActions.showModal,
  handleCloseModal: modalsActions.hideModal,
  clearActiveRfqDetails: marketplaceActions.clearActiveRfqDetails,
  setRfqHasNDA: marketplaceActions.setRfqHasNDA,
  setSellerNdaAttachments: marketplaceActions.setSellerNdaAttachments,
  setRfqDetailsLoading: marketplaceActions.setRfqDetailsLoading,
  getActiveRfqDetails: marketplaceActions.getActiveRfqDetails,
  setNDAStepperIndex: marketplaceActions.setNDAStepperIndex,
};

const mapStateToProps = (state) => ({
  token: state.profile.token,
  isLoading: state.marketplace.isLoading,
  isRfqDetailsLoading: state.marketplace.isRfqDetailsLoading,
  quoteListOfCompany: state.marketplace.quoteListOfCompany,
  activeRfqDetailsError: state.marketplace.activeRfqDetailsError,
  activeRfqDetails: state.marketplace.activeRfqDetails,
  hasNDA: state.marketplace.rfqHasNda,
  buyerStatsLoading: state.marketplace.buyerStatsLoading,
});

export const QuotationDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotationDialog);
