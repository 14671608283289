import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { Link } from "react-router-dom";
import { CustomTypography } from "../custom-typography";

type Props = {
  to: string;
  label: string;
} & GridRenderCellParams;

const LinkCell: React.FC<Props> = ({ to, label }) => {
  return (
    <Link
      to={to}
      style={{
        lineHeight: "initial",
        textDecoration: "underline",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
      target="_blank"
    >
      <CustomTypography variant="caption">{label}</CustomTypography>
    </Link>
  );
};

export default LinkCell;
