import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { formatDate } from "helpers/formatDate";
import { CustomTypography } from "../custom-typography";

const DateCell: React.FC<GridRenderCellParams> = ({ value }) => {
  return <CustomTypography>{`${formatDate(value, "MMM D, YYYY")}`}</CustomTypography>;
};

export default DateCell;
