/**
 * actions.js:
 *
 * this file is configuration action, we use this to  dispatching an action.
 * means sending out a signal to the store to change the state.
 *
 */

// Services Exported
import { partConfigurationApi, partConfigurationConstants } from "./index";

/**
 *
 * Backend Query to fetch a statics data
 * @returns {Array} - list of objects representing processes, materials, alloys, treatments
 */

function fetchPartConfigurationData() {
  return (dispatch) => {
    dispatch(request());
    partConfigurationApi.fetchPartConfigurationData().then(
      (partConfigurationData) => {
        dispatch(success(partConfigurationData));
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };

  function request() {
    return { type: partConfigurationConstants.PART_CONFIGURATION_DATA_REQUEST };
  }
  function success(partConfigurationData) {
    return {
      type: partConfigurationConstants.PART_CONFIGURATION_DATA_SUCCESS,
      partConfigurationData,
    };
  }
  function failure(error) {
    return {
      type: partConfigurationConstants.PART_CONFIGURATION_DATA_FAILURE,
      error,
    };
  }
}

export const setSeletedFilters = (data) => (dispatch) => {
  return dispatch({
    type: partConfigurationConstants.PART_CONFIGURATION_SELECTED_FILTERS,
    selectedFilters: data,
  });
};

const setERPTypeLastSyncDate = (last_synchronization_date) => {
  return {
    type: partConfigurationConstants.SET_ERP_TYPE_LAST_SYNC_DATE,
    last_synchronization_date,
  };
};

export const partConfigurationActions = {
  fetchPartConfigurationData,
  setERPTypeLastSyncDate,
};
