import { ACTION_STATUS } from "@next/constants";
import { workspaceConstants } from "./index";

const initialState = {
  newPart: null,
  rfq: null,
  isLoading: false,
  companyPartNextPage: null,
  companyPartNextPageLoading: false,
  companyPartList: null,
  RFQListOfCompany: [],
  buyerContracts: [],
  isBuyerContractsLoading: false,
  partDetails: {},
  isPartDetailsLoading: false,
  isPurchaseOrderLoading: false,
  isRFQDetailsLoading: false,
  isRFQPartsLoading: false,
  rfqDetails: null,
  rfqParts: [],
  isRFQPartsModified: false,
  isContractDetailsLoading: false,
  contractDetails: null,
  selectedTab: 0,
  rfqStepper: {
    generalInformation: {
      attachments: [],
    },
    recipients: {
      suppliersList: [],
      supplierEmailsList: [],
    },
  },
  rfqActiveStep: 0,
  selectedParts: [],
  cadFiles: [],
  tagsCreatedByCurrentCompany: [],
  tagsCreatedByCurrentCompanyLoading: false,
  ndaAttachments: [],
  tags: [],
  supplierSignedNdas: [],
  selectedTags: [],
  isWorkspaceLoading: false,
  rfqCreationLoading: false,
  ndaList: [],
  congratsInfo: {},
  isNdaApprovalLoading: false,
  isCancellationOrExtensionLoading: false,
  isWorkspaceInitialLoading: false,
  isUploadAttachmentsLoading: false,
  isRfqUpdatePartialLoading: false,
  isRfiShortlistLoading: false,
  rfqUpdatePartialStatus: ACTION_STATUS.IDLE,
  resetPartsSelectionCounter: 0,
  isBookmarkQuotationLoading: false,
};

export function workspace(state = initialState, action) {
  switch (action.type) {
    /** CREATE_PART_REQUEST*/
    case workspaceConstants.CREATE_PART_REQUEST:
      return { ...state, isLoading: true };
    case workspaceConstants.CREATE_PART_SUCCESS:
      return {
        ...state,
        companyPartList: [action.newPart, ...(state.companyPartList || [])],
        isLoading: false,
      };
    case workspaceConstants.CREATE_PART_FAILURE:
      return { ...state, isLoading: false };

    /** DELETE_PART_SUCCESS*/
    case workspaceConstants.DELETE_PART_SUCCESS:
      return {
        ...state,
        companyPartList: state.companyPartList.filter((obj) => obj.pk !== action.deletedPartPk),
      };

    /**FETCH_COMPANY_PARTS_REQUEST */
    case workspaceConstants.FETCH_COMPANY_PARTS_REQUEST:
      if (action.isInitialLoad) return { ...state, isWorkspaceInitialLoading: true };
      else return { ...state, isWorkspaceLoading: true };
    case workspaceConstants.FETCH_COMPANY_PARTS_SUCCESS:
      return {
        ...state,
        companyPartList: Array.isArray(action.payload) ? action.payload : action.payload.results,
        companyPartNextPage: action.payload?.next || null,
        isWorkspaceLoading: false,
        isWorkspaceInitialLoading: false,
      };
    case workspaceConstants.FETCH_COMPANY_PARTS_FAILURE:
      return {
        ...state,
        isWorkspaceLoading: false,
        isWorkspaceInitialLoading: false,
      };

    /**FETCH_COMPANY_PARTS_NEXT_REQUEST */
    case workspaceConstants.FETCH_COMPANY_PARTS_NEXT_REQUEST:
      return { ...state, companyPartNextPageLoading: true };
    case workspaceConstants.FETCH_COMPANY_PARTS_NEXT_SUCCESS:
      return {
        ...state,
        companyPartList: [...(state.companyPartList || []), ...action.payload.results],
        companyPartNextPage: action.payload?.next || null,
        companyPartNextPageLoading: false,
      };
    case workspaceConstants.FETCH_COMPANY_PARTS_NEXT_FAILURE:
      return {
        ...state,
        companyPartNextPageLoading: false,
      };

    /**FETCH_BUYER_CONTRACTS_REQUEST */
    case workspaceConstants.FETCH_BUYER_CONTRACTS_REQUEST:
      return { ...state, isBuyerContractsLoading: true };
    case workspaceConstants.FETCH_BUYER_CONTRACTS_SUCCESS:
      return {
        ...state,
        buyerContracts: action.buyerContracts,
        isBuyerContractsLoading: false,
      };
    case workspaceConstants.FETCH_BUYER_CONTRACTS_FAILURE:
      return { ...state, isLoading: false };
    /**Workspace CAD Files */
    case workspaceConstants.FETCH_CAD_FILES_REQUEST:
      return { ...state, isLoading: true };
    case workspaceConstants.FETCH_CAD_FILES_SUCCESS:
      return {
        ...state,
        cadFiles: action.cadFiles,
        isLoading: false,
      };
    case workspaceConstants.FETCH_CAD_FILES_FAILURE:
      return { ...state, isLoading: false };

    /**Delete CAD File */
    case workspaceConstants.DELETE_CAD_FILE_REQUEST:
      return { ...state, isLoading: true };
    case workspaceConstants.DELETE_CAD_FILE_SUCCESS:
      return {
        ...state,
        cadFiles: state.cadFiles.filter((obj) => obj.pk !== action.filePk),
        isLoading: false,
      };
    case workspaceConstants.DELETE_CAD_FILE_FAILURE:
      return { ...state, isLoading: false };
    /**RFQ_REQUEST */
    case workspaceConstants.RFQ_REQUEST:
      return { ...state, rfqCreationLoading: true };
    case workspaceConstants.RFQ_SUCCESS:
      return { ...state, rfq: action.rfq, rfqCreationLoading: false };
    case workspaceConstants.RFQ_FAILURE:
      return { rfqCreationLoading: false };
    /**FETCH_ACTIVE_RFQ_DETAILS_REQUEST */
    case workspaceConstants.FETCH_RFQ_DETAILS_REQUEST:
      return { ...state, isRFQDetailsLoading: true };

    case workspaceConstants.FETCH_RFQ_DETAILS_SUCCESS:
      return {
        ...state,
        isRFQDetailsLoading: false,
        rfqDetails: action.rfqDetails,
      };

    case workspaceConstants.SET_RFQ_PARTS_FOR_EDIT:
      return {
        ...state,
        rfqParts: state.rfqDetails.parts.map((part) => ({
          ...part,
          isEditMode: true,
        })),
        removeRfqParts: [],
        sendRevisionNoteLoading: false,
        sendWithoutRevisionNoteLoading: false,
        isRFQPartsModified: false,
      };

    case workspaceConstants.UPDATE_RFQ_PARTS_FOR_EDIT:
      const updatedParts = state.rfqParts.map((part) => {
        if (part.pk === action.rfqPart?.pk) {
          return action.rfqPart;
        } else {
          return part;
        }
      });
      return {
        ...state,
        rfqParts: updatedParts,
        isRFQPartsModified: true,
      };

    case workspaceConstants.DELETE_RFQ_PARTS_FOR_EDIT:
      return {
        ...state,
        rfqParts: state.rfqParts.filter((part) => part.pk !== action.rfqPart.pk),
        ...state.removeRfqParts.push(action.rfqPart.pk),
        isRFQPartsModified: true,
      };

    case workspaceConstants.ADD_RFQ_PART_FOR_EDIT:
      return {
        ...state,
        rfqParts: [action.rfqPart, ...state.rfqParts],
        isRFQPartsModified: true,
      };

    case workspaceConstants.RFQ_PARTS_ATTACHEMENT:
      return {
        ...state,
        isRFQPartsModified: true,
        rfqParts: state.rfqParts.map((part) =>
          part.pk === action.rfqPartAttachement.partPk
            ? {
                ...part,
                part_uploads_list: [
                  ...action.rfqPartAttachement.attachements,
                  ...part.part_uploads_list,
                ],
              }
            : part
        ),
      };

    case workspaceConstants.EDIT_RFQ_PARTS_ATTACHEMENT:
      return {
        ...state,
        isRFQPartsModified: true,
        rfqParts: state.rfqParts.map((part) =>
          part.pk === action.partId
            ? {
                ...part,
                part_uploads_list: action.attachements,
              }
            : part
        ),
      };

    case workspaceConstants.FETCH_RFQ_DETAILS_FAILURE:
      return { ...state, isRFQDetailsLoading: false };
    /**FETCH_RFQ_REMAINING_PARTS_REQUEST */
    case workspaceConstants.FETCH_RFQ_PARTS_REQUEST:
      return { ...state, isRFQPartsLoading: true };

    case workspaceConstants.FETCH_RFQ_PARTS_SUCCESS:
      return {
        ...state,
        isRFQPartsLoading: false,
        rfqDetails: {
          ...state.rfqDetails,
          parts: action.parts,
        },
      };

    case workspaceConstants.FETCH_RFQ_PARTS_FAILURE:
      return { ...state, isRFQPartsLoading: false };
    /**FETCH_CONTRACTS_DETAILS_REQUEST */
    case workspaceConstants.FETCH_CONTRACT_DETAILS_REQUEST:
      return { ...state, isContractDetailsLoading: true };

    case workspaceConstants.FETCH_CONTRACT_DETAILS_SUCCESS:
      return {
        ...state,
        isContractDetailsLoading: false,
        contractDetails: action.contractDetails,
      };

    case workspaceConstants.FETCH_CONTRACT_DETAILS_FAILURE:
      return { ...state, isContractDetailsLoading: false };
    /**FETCH_COMPANY_RFQLIST_REQUEST */
    case workspaceConstants.FETCH_COMPANY_RFQLIST_REQUEST:
      if (action.isInitialLoad) return { ...state, isRFQsInitialLoading: true };
      else return { ...state, isLoading: true };
    case workspaceConstants.FETCH_COMPANY_RFQLIST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        RFQListOfCompany: {
          ...state.RFQListOfCompany,
          [action.RFQListOfCompany.status]: action.RFQListOfCompany.data,
        },
      });

    case workspaceConstants.CLEAR_RFQ_TOUR_MOCK_IN_RFQLISTOFCOMPANY:
      const mockRFQListOfCompanyItem = state.RFQListOfCompany[action.status].filter(
        (quote) => quote.pk !== 123456789
      );
      return {
        ...state,
        RFQListOfCompany: {
          ...state.RFQListOfCompany,
          [action.status]: mockRFQListOfCompanyItem,
        },
      };
    case workspaceConstants.FETCH_COMPANY_RFQLIST_FAILURE:
      return { ...state, isLoading: false };

    /**SELECT_WINNER_REQUEST */
    case workspaceConstants.SELECT_WINNER_REQUEST:
      return { ...state, isLoading: true };
    case workspaceConstants.SELECT_WINNER_SUCCESS:
      return {
        ...state,
        rfqDetails: {
          ...(state.rfqDetails || {}),
          ...(action.updatedRFQ || {}),
        },
        isLoading: false,
      };

    case workspaceConstants.SELECT_WINNER_FAILURE:
      return { ...state, isLoading: false };

    /**PATCH_PURCHASE_ORDER_REQUEST */
    case workspaceConstants.PATCH_PURCHASE_ORDER_REQUEST:
      return { ...state, isPurchaseOrderLoading: true };
    case workspaceConstants.PATCH_PURCHASE_ORDER_SUCCESS:
      let rfqKeys = "";
      // find the contract to patch  in the contracts list
      const contractToPatch = state.buyerContracts.find(
        (contract) => contract.pk === action.contractPo.pk
      );

      // get index of contract to patch
      const poIndex = state.buyerContracts.indexOf(contractToPatch);

      //update the contracts list
      if (state.buyerContracts && state.buyerContracts.length > 0) {
        state.buyerContracts[poIndex]["po_file"] = action.contractPo.poFile;
      }
      if (action.contractPo.projectCode) {
        rfqKeys = `InProgress&status=Waiting&status=Contract${action.contractPo.projectCode}`;
      } else {
        rfqKeys = "InProgress&status=Waiting&status=Contract";
      }
      // Workaround when attaching the po from the project listing page
      if (state.RFQListOfCompany[rfqKeys]) {
        const rfqs = state.RFQListOfCompany[rfqKeys];
        const contractedRfq = rfqs.find((contract) => contract.pk === action.contractPo.pk);
        const poIndex = rfqs.indexOf(contractedRfq);
        rfqs[poIndex]["po_file"] = action.contractPo.poFile;
      }
      return {
        ...state,
        isPurchaseOrderLoading: false,
      };
    case workspaceConstants.PATCH_PURCHASE_ORDER_FAILURE:
      return { ...state, isPurchaseOrderLoading: false };

    /**EDIT_PART_REQUEST */
    case workspaceConstants.EDIT_PART_REQUEST:
      return { ...state, isLoading: true };
    case workspaceConstants.EDIT_PART_SUCCESS:
      // update rfqDetails with edited part information
      if (action.isRfqPartEdit && state.rfqDetails) {
        const updatedParts = state.rfqDetails.parts.map((part) => {
          if (part.pk === action.newPart.pk) {
            return action.newPart;
          } else {
            return part;
          }
        });
        return {
          ...state,
          rfqDetails: { ...state.rfqDetails, parts: [...updatedParts] },
          isLoading: false,
        };
      } else {
        const partsList = state.companyPartList || [];
        const editedPart = partsList.find((item) => item.pk === action.newPart.pk);
        const parts = [...partsList];
        const index = partsList.indexOf(editedPart);
        parts[index] = Object.assign({}, editedPart, action.newPart);
        return {
          ...state,
          companyPartList: parts,
          isLoading: false,
        };
      }
    case workspaceConstants.EDIT_PART_FAILURE:
      return { ...state, isLoading: false };
    /**EDIT_PART_REQUEST */
    case workspaceConstants.GET_PART_BY_ID_REQUEST:
      return { ...state, isPartDetailsLoading: true };
    case workspaceConstants.GET_PART_BY_ID_SUCCESS:
      return {
        ...state,
        partDetails: action.partDetails,
        isPartDetailsLoading: false,
      };
    case workspaceConstants.GET_PART_BY_ID_FAILURE:
      return { ...state, isPartDetailsLoading: false };

    // CLONE_PART_REQUEST
    case workspaceConstants.CLONE_PART_REQUEST:
      return { ...state, isLoading: true };
    case workspaceConstants.CLONE_PART_SUCCESS:
      const companyPartList = [action.clonedPart, ...(state.companyPartList || [])];
      return {
        ...state,
        companyPartList: companyPartList,
        isLoading: false,
      };
    case workspaceConstants.CLONE_PART_FAILURE:
      return { ...state, isLoading: false };

    case workspaceConstants.SET_SELECTED_TAB_IN_QUOTATIONS:
      return {
        ...state,
        selectedTab: action.selectedTab,
      };

    // RFQ cancellation and extension
    case workspaceConstants.RFQ_CANCELLATION_AND_EXTENSION_REQUEST:
      return { ...state, isCancellationOrExtensionLoading: true };
    case workspaceConstants.RFQ_CANCELLATION_AND_EXTENSION_SUCCESS:
      const newStatus = action.data.status;

      const ongoingRfqKey = "InProgress&status=Waiting";
      let ongoingRfqList = state.RFQListOfCompany[ongoingRfqKey];

      const archivedRfqKey = "archive";
      const archivedRfqList = state.RFQListOfCompany[archivedRfqKey];

      // Cancel RFQ
      if (newStatus === "Canceled") {
        const cancelledRfq = ongoingRfqList.find((item) => item.pk === action.data.pk);

        // delete it from the ongoing list
        ongoingRfqList = ongoingRfqList.filter((item) => item.pk !== cancelledRfq.pk);

        // add to archive list
        archivedRfqList.unshift({
          ...cancelledRfq,
          ...action.data,
          status: "Canceled",
        });
      }

      // Extend RFQ
      else if (newStatus === "Active") {
        // update rfq in the ongoing list
        ongoingRfqList = ongoingRfqList.map((item) => {
          if (item.pk === action.data.pk) {
            return {
              ...item,
              ...action.data,
            };
          }

          return item;
        });
      }

      return {
        ...state,
        RFQListOfCompany: {
          [ongoingRfqKey]: ongoingRfqList,
          [archivedRfqKey]: archivedRfqList,
        },
        isCancellationOrExtensionLoading: false,
      };

    case workspaceConstants.RFQ_CANCELLATION_AND_EXTENSION_FAILURE:
      return { ...state, isCancellationOrExtensionLoading: false };

    //rfq stepper set rfq general information
    case workspaceConstants.SET_RFQ_GENERAL_INFORMATION:
      return Object.assign({}, state, {
        rfqStepper: { ...state.rfqStepper, generalInformation: action.data },
      });

    //rfq stepper set rfq general information
    case workspaceConstants.SET_RECIPIENTS_LIST:
      return Object.assign({}, state, {
        rfqStepper: {
          ...state.rfqStepper,
          recipients: {
            ...state.rfqStepper.recipients,
            supplierEmailsList: action.data.suppliersEmailList,
            suppliersList: action.data.suppliersList,
          },
        },
      });

    //set active step in RFQ stepper
    case workspaceConstants.SET_RFQ_ACTIVE_STEP:
      return Object.assign({}, state, { rfqActiveStep: action.activeStep });

    //set selected parts
    case workspaceConstants.SET_SELECTED_PARTS:
      return {
        ...state,
        selectedParts: [...action.parts],
      };

    // storing NDA attachments info to send NDA id to the rfq creation endpoint
    case workspaceConstants.SET_BUYER_NDA_ATTACHMENTS:
      return {
        ...state,
        ndaAttachments: [...action.attachments],
      };
    /**CREATE_PART_TAGS_REQUEST */
    case workspaceConstants.CREATE_PART_TAGS_REQUEST:
      return { ...state, isLoading: true };
    case workspaceConstants.CREATE_PART_TAGS_SUCCESS:
      // find the part to add the tags
      const thePart = state.companyPartList.find((part) => part.pk === action.partTags.pk);

      // get index of contract to patch
      const partTagsIndex = state.companyPartList.indexOf(thePart);

      //update the company part list
      state.companyPartList[partTagsIndex]["tags"] = action.partTags.tags;

      return {
        ...state,
        isLoading: false,
      };
    case workspaceConstants.CREATE_PART_TAGS_FAILURE:
      return { ...state, isLoading: false };

    /**GET_All_TAGS_CREATED_BY_CURRENT_COMPANY */
    case workspaceConstants.GET_All_TAGS_CREATED_BY_CURRENT_COMPANY_REQUEST:
      return { ...state, tagsCreatedByCurrentCompanyLoading: true };
    case workspaceConstants.GET_All_TAGS_CREATED_BY_CURRENT_COMPANY_SUCCESS:
      return {
        ...state,
        tagsCreatedByCurrentCompany: [...action.tagsCreatedByCurrentCompany],
        tagsCreatedByCurrentCompanyLoading: false,
      };
    case workspaceConstants.GET_All_TAGS_CREATED_BY_CURRENT_COMPANY_FAILURE:
      return { ...state, tagsCreatedByCurrentCompanyLoading: false };

    // get tags list created by company
    case workspaceConstants.GET_TAGS_LIST_REQUEST:
      return { ...state, isLoading: true };
    case workspaceConstants.GET_TAGS_LIST_SUCCESS:
      return {
        ...state,
        tags: [...action.tags],
        isLoading: false,
      };
    case workspaceConstants.GET_TAGS_LIST_FAILURE:
      return { ...state, tags: [], isLoading: false };

    // get supplier signed NDA list
    case workspaceConstants.GET_SIGNED_NDA_LIST_REQUEST:
      return { ...state, isLoading: true };
    case workspaceConstants.GET_SIGNED_NDA_LIST_SUCCESS:
      return {
        ...state,
        supplierSignedNdas: [...action.NDAList],
        isLoading: false,
      };
    case workspaceConstants.GET_SIGNED_NDA_LIST_FAILURE:
      return { ...state, supplierSignedNdas: [], isLoading: false };

    case workspaceConstants.SET_SELECTED_TAGS:
      return { ...state, selectedTags: [...action.tags] };

    // Open buyer quotation tabs
    case workspaceConstants.SET_BUYER_QUOTATIONS_TABS:
      return {
        ...state,
        isQuotationsTabs: action.isQuotationsTabs,
      };
    // get supplier signed NDA list
    case workspaceConstants.GET_ALL_SIGNED_NDAS_REQUEST:
      return { ...state, isLoading: true };
    case workspaceConstants.GET_ALL_SIGNED_NDAS_SUCCESS:
      return {
        ...state,
        ndaList: [...action.NDAList],
        isLoading: false,
      };
    case workspaceConstants.GET_ALL_SIGNED_NDAS_FAILURE:
      return { ...state, ndaList: [], isLoading: false };
    case workspaceConstants.TOGGLE_NDA_APPROVE_REQUEST:
      return { ...state, isNdaApprovalLoading: true };
    // update inApproved in the NDA list after toggle switch API call
    case workspaceConstants.TOGGLE_NDA_APPROVE_SUCCESS:
      const updatedNdaList = state.ndaList.map((nda) => {
        if (nda.pk === action.pk) {
          nda.is_approved = action.isApproved;
        }
        return nda;
      });
      return {
        ...state,
        isNdaApprovalLoading: false,
        ndaList: [...updatedNdaList],
      };
    case workspaceConstants.TOGGLE_NDA_APPROVE_FAILURE:
      return { ...state, isNdaApprovalLoading: false };

    case workspaceConstants.SET_CONGRATULATION_DETAILS:
      return {
        ...state,
        congratsInfo: { ...action.congratsInfo },
      };

    case workspaceConstants.UPLOAD_RFQ_ATTACHMENT_REQUEST:
      return { ...state, isUploadAttachmentsLoading: true };
    case workspaceConstants.UPLOAD_RFQ_ATTACHMENT_SUCCESS:
      return {
        ...state,
        isUploadAttachmentsLoading: false,
      };
    case workspaceConstants.UPLOAD_RFQ_ATTACHMENT_FAILURE:
      return { ...state, isUploadAttachmentsLoading: false };

    case workspaceConstants.DECREMENT_RFQ_NEW_QA_MSG_COUNT:
      return {
        ...state,
        rfqDetails: {
          ...state.rfqDetails,
          unseen_questions_count:
            (state.rfqDetails?.unseen_questions_count || action.count) - action.count,
        },
        contractDetails: {
          ...state.contractDetails,
          unseen_questions_count:
            (state.contractDetails?.unseen_questions_count || action.count) - action.count,
        },
      };

    case workspaceConstants.RFQ_UPDATE_PARTIAL_REQUEST:
      return {
        ...state,
        isRfqUpdatePartialLoading: true,
        rfqUpdatePartialStatus: ACTION_STATUS.LOADING,
      };
    case workspaceConstants.RFQ_UPDATE_PARTIAL_SUCCESS:
      let newRfqDetail = {};
      if (state.rfqDetails.pk === action.updatedRfq.pk) {
        newRfqDetail = {
          ...state.rfqDetails,
          ...action.updatedRfq,
        };
      } else {
        newRfqDetail = { ...state.rfqDetails };
      }
      return {
        ...state,
        rfqDetails: newRfqDetail,
        isRfqUpdatePartialLoading: false,
        rfqUpdatePartialStatus: ACTION_STATUS.IDLE,
      };
    case workspaceConstants.RFQ_UPDATE_PARTIAL_FAILURE:
      return {
        ...state,
        isRfqUpdatePartialLoading: false,
        rfqUpdatePartialStatus: ACTION_STATUS.FAILED,
      };

    case workspaceConstants.RFI_SHORTLIST_REQUEST:
      return {
        ...state,
        isRfiShortlistLoading: true,
      };
    case workspaceConstants.RFI_SHORTLIST_SUCCESS:
      return {
        ...state,
        rfqDetails: {
          ...state.rfqDetails,
          ...action.updatedRfq.rfq,
        },
        isRfiShortlistLoading: false,
      };
    case workspaceConstants.RFI_SHORTLIST_FAILURE:
      return {
        ...state,
        isRfiShortlistLoading: false,
      };

    case workspaceConstants.RESET_PARTS_SELECTION:
      return {
        ...state,
        resetPartsSelectionCounter: (state.resetPartsSelectionCounter || 0) + 1,
      };
    case workspaceConstants.SET_PART_DETAILS:
      return {
        ...state,
        partDetails: action.partDetails,
      };
    case workspaceConstants.SET_RFQ_DETAILS:
      return {
        ...state,
        rfqDetails: action.rfqDetails,
      };
    case workspaceConstants.UPDATE_RFQ_DETAILS:
      return {
        ...state,
        rfqDetails: {
          ...(state.rfqDetails || {}),
          ...(action.rfqDetails || {}),
        },
      };

    case workspaceConstants.UPDATE_RFQ_TARGETED_PROFILES_SUCCESS:
      const ix = state.rfqDetails.targeted_suppliers.findIndex(
        (item) => item.id === action.supplierId
      );
      return {
        ...state,
        rfqDetails: {
          ...state.rfqDetails,
          targeted_suppliers: state.rfqDetails.targeted_suppliers.map((item, index) =>
            index === ix
              ? {
                  ...state.rfqDetails.targeted_suppliers[ix],
                  target_profiles: action.targetProfiles,
                }
              : item
          ),
        },
      };

    default:
      return { ...state, congratsInfo: {} };

    /** EDIT_BOOKMARK_REQUEST*/
    case workspaceConstants.BOOKMARK_QUOTATION_REQUEST:
      return { ...state, isBookmarkQuotationLoading: true };

    case workspaceConstants.BOOKMARK_QUOTATION_SUCCESS:
      const updatedRfqDetails = action.updatedRfq.rfq;
      const { quotations } = updatedRfqDetails;

      const bookmarkedQuotes = quotations.filter((quote) => quote.is_bookmarked);
      const nonBookmarkedQuotes = quotations.filter((quote) => !quote.is_bookmarked);
      const updatedQuotations = [...bookmarkedQuotes, ...nonBookmarkedQuotes];

      return {
        ...state,
        rfqDetails: {
          ...state.rfqDetails,
          quotations: updatedQuotations,
        },
        isBookmarkQuotationLoading: false,
      };

    case workspaceConstants.BOOKMARK_QUOTATION_FAILURE:
      return { ...state, isBookmarkQuotationLoading: false };
  }
}
