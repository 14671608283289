import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { PublicRfqCreationFormBase } from "./public-rfq-creation-form.base";
import {
  getPublicRfqFormInitialValues,
  getPublicRfqFormValidationSchema,
} from "./public-rfq-creation-form.definitions";
import { PublicRFQFormValuesType } from "./public-rfq-creation-form.types";

type Props = {
  onSubmit: (values: PublicRFQFormValuesType) => void;
};

const PublicRfqCreationForm: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      render={(props) => <PublicRfqCreationFormBase {...props} />}
      initialValues={getPublicRfqFormInitialValues(t)}
      onSubmit={onSubmit}
      validationSchema={getPublicRfqFormValidationSchema(t)}
      enableReinitialize
    />
  );
};

export default PublicRfqCreationForm;
