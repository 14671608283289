import { Box, Divider, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProjectPartTableToolbar } from "./project-part-table.toolbar";
import { useTranslation } from "react-i18next";
import { PartTableDataProps, usePartTableData } from "./project-part-table.hooks";
import { v4 as uuid } from "uuid";
import {
  selectProjectBulkDeletePartLoading,
  selectProjectCompanyPartsCount,
  selectProjectCompanyPartsFiltered,
  selectProjectCompanyPartsLoading,
  selectProjectCompanyPartsSelectAll,
  selectProjectStats,
  selectProjectTemplates,
  selectProjectTemplatesLoading,
  selectSelectedPart,
  selectSelectedPartRows,
} from "@next/modules/project/redux/selectors";
import { projectActions } from "@next/modules/project/redux/slices";
import { ProjectCompanyPart, ProjectTemplate } from "@next/modules/project/redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "@next/modules/project/modals/types";
import { ProjectsPartField } from "./types";
import { PAGINATION_PAGE_SIZE } from "@next/constants";
import {
  GridCellEditCommitParams,
  GridCellParams,
  GridFilterModel,
  GridLinkOperator,
  GridRenderCellParams,
  GridRowModel,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid-pro-v5";
import { addOrRemove } from "@next/utils/arrayUtils";
import { getProjectPartTableCheckboxColumn } from "./project-part-table.columns";
import { debounce } from "lodash";
import { ProjectBulkPartImportLoaderModal } from "../../modals/project-bulk-part-import-loader-modal";
import { NoRowsOverlayBase } from "@next/components/no-rows-overlay-with-api/no-rows-overlay.base";
import { ProjectPartTableEditMenu, ProjectPartTableMenuItemType } from "./project-part-table.menu";
import TemplateSelector from "./template-selector";
import { createStyles, makeStyles } from "@mui/styles";
import { UpperSectionFromRfqCreation } from "./upper-section-from-rfq-creation";
import { RfqDataToPartsModal } from "@next/modules/workspace/modals/rfq-add-filesandparts-modal/rfq-add-filesandparts-modal";
import { selectRfqFormAdditionalFiles } from "@next/modules/workspace/redux/selectors";
import DownloadableFileItem from "@next/components/downloadable-file-item/downloadable-file-item";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";
import RFQPartsStatusBar from "components/buyer-rfqs/buyer-rfqs-information/rfq-parts-status-bar";
import { workspaceActions } from "services/workspace";
import { RFQPartsRevisionFormValues } from "@next/modules/workspace/modals/rfq-parts-revision-modal/rfq-parts-revision-form.types";
import {
  getRemovedPartsIds,
  getRfqDetails,
  getRfqPartsForEdit,
} from "services/workspace/workspace.selectors";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { ProjectIsEditRFQCancelModal } from "@next/modules/project/modals/project-isEdit-rfq-cancel-modal";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import useLocalStorageGridState from "@next/hooks/useLocalStorageGridState-v5";
import { BUYER_PARTS_GRID_COLUMN_STATE } from "@next/constants/data-grid-state-constants";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import { ProjectFileDeleteConfirmationModal } from "../../modals/project-file-delete-confirmation-modal";
import snackbarUtils from "@next/utils/snackbarUtils";

const INITIAL_PAGE = 1;

const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      "& .MuiDataGrid-columnHeader": {
        animation: "fadein 1.5s",
      },
      "& .MuiDataGrid-main": {
        // borderTop: "solid 1px rgba(224, 224, 224, 1)",
        overflow: "unset",
      },
      "& .MuiDataGrid-columnHeaderTitleContainerContent": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    gridDefault: {
      "& .MuiDataGrid-columnHeaderTitleContainerContent": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    actionButtonStyle: {
      padding: "10px 16px",
    },
  })
);

type Props = {
  rfqData?: RfqDataToPartsModal;
  isRFQEditMode?: boolean;
  hidePriceColumn?: boolean;
};

export const ProjectPartTable: React.FC<Props> = ({ rfqData, isRFQEditMode, hidePriceColumn }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { apiRef, restoreState } = useLocalStorageGridState(BUYER_PARTS_GRID_COLUMN_STATE);
  const hasViewerOrRestrictedViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | Element>(null);
  const [searchValue, setSearchValueState] = useState<string | undefined>();
  const projectTemplates = useSelector(selectProjectTemplates);
  const projectTemplatesLoading = useSelector(selectProjectTemplatesLoading);

  const selectedPart = useSelector(selectSelectedPart);
  const projectStats = useSelector(selectProjectStats);
  const selectedPartsSelectionModel = useSelector(selectSelectedPartRows);
  const projectCompanyPartsCount = useSelector(selectProjectCompanyPartsCount);
  const projectCompanyPartsFiltered = useSelector(selectProjectCompanyPartsFiltered);
  const rfqDetails = useSelector(getRfqDetails);
  const rfqParts = useSelector(getRfqPartsForEdit);
  const deletedPartsIDs = useSelector(getRemovedPartsIds);
  const [columns, setColumns] = useState([]);

  const selectedPartRows = useSelector(selectSelectedPartRows);
  const projectCompanyPartsLoading = useSelector(selectProjectCompanyPartsLoading);
  const projectCompanyPartsSelectAll = useSelector(selectProjectCompanyPartsSelectAll);

  const projectBulkDeletePartLoading = useSelector(selectProjectBulkDeletePartLoading);

  const rfqAdditionalFiles = useSelector(selectRfqFormAdditionalFiles);

  const [projectPartTableDataParams, setProjectPartTableDataParams] = useState<PartTableDataProps>({
    currentPage: INITIAL_PAGE,
    debouncedSearchValue: "",
    filterModel: { items: [], linkOperator: GridLinkOperator.And },
  });

  const [showProjectPartSelectedOptions, setShowProjectPartSelectedOptions] = useState(false);

  const { gridData, setGridData } = usePartTableData({
    ...projectPartTableDataParams,
    fromRfq: !!rfqData,
    isRFQEditMode,
    isEditable: true,
    hidePriceColumn,
  });

  const resetSelections = () => {
    dispatch(projectActions.setSelectedPartRows([]));
    dispatch(projectActions.setProjectCompanyPartsSelectAll(false));
  };

  const handleTemplateChange = useCallback(
    (template: ProjectTemplate) => {
      if (projectStats?.code) {
        dispatch(projectActions.updateProjectTemplateInState(template));
        dispatch(
          projectActions.updateProjectTemplateRequest({
            projectCode: projectStats.code,
            template: template.id,
          })
        );
      }
    },
    [projectStats?.code]
  );

  const empty = !projectCompanyPartsLoading && projectStats?.total_parts_count === 0;
  const showTemplateSelector = empty && projectTemplates?.length > 0;

  const fetchProjectTemplates = useCallback(
    debounce(
      () => {
        dispatch(projectActions.fetchProjectTemplatesRequest());
      },
      1000,
      { leading: false, trailing: true }
    ),
    []
  );

  useEffect(() => {
    if (empty) fetchProjectTemplates();
  }, [empty]);

  useEffect(() => {
    resetSelections();

    return () => {
      resetSelections();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedPartsSelectionModel || projectCompanyPartsSelectAll) {
      setShowProjectPartSelectedOptions(
        selectedPartsSelectionModel.length > 0 || projectCompanyPartsSelectAll
      );
    }

    if (
      selectedPartsSelectionModel.length > 0 &&
      projectCompanyPartsCount > 0 &&
      selectedPartsSelectionModel.length === projectCompanyPartsCount &&
      !projectCompanyPartsFiltered
    ) {
      dispatch(projectActions.setProjectCompanyPartsSelectAll(true));
    }
  }, [selectedPartsSelectionModel, projectCompanyPartsCount, projectCompanyPartsSelectAll]);

  useEffect(() => {
    if (rfqData?.onPartsCountChange) {
      rfqData.onPartsCountChange({
        partsCount: projectCompanyPartsCount || 0,
        filesCount: (projectStats?.attached_files_count || 0) + (rfqAdditionalFiles?.length || 0),
      });
    }
  }, [projectCompanyPartsCount, rfqAdditionalFiles, projectStats?.attached_files_count]);

  const isCellEditable = (params: GridCellParams) =>
    !(params.colDef.editable && params.field === "quantity" && params.row.winner);

  const onCellEditCommit = useCallback(
    (params: GridCellEditCommitParams & { api?: any }) => {
      let field = params.field;
      let value = params.value;

      if (field === "processes") {
        if (value instanceof Object || !value) {
          return;
        }

        value = [value];
      }

      const row = apiRef.current.getRow(params.id);

      if (apiRef.current) {
        if (row) {
          // Here, we perform operations to save the extraFields in the appropriate format for the API.
          const field_parts = field.split(".");
          if (field_parts.length > 1) {
            field = "extra_fields";
            const extraFields = row[field];
            const oldFieldValue = extraFields?.[field_parts[1]];
            if (oldFieldValue === params.value) {
              return;
            }
            value = isRFQEditMode
              ? {
                  ...extraFields,
                  [field_parts[1]]: params.value,
                }
              : {
                  [field_parts[1]]: params.value,
                };
          } else {
            const oldFieldValue = row.field;
            if (params.value === oldFieldValue) {
              return;
            }
          }
        }
      }

      const updates = {
        [field]: value,
      };

      if (isRFQEditMode) {
        if (row[params.field] === params.value) {
          // field value was not updated. No need to dispatch any action
          return;
        }
        dispatch(workspaceActions.updateRFQPartForEdit({ ...row, [field]: value }));
      } else {
        dispatch(
          projectActions.updateProjectPartRequest({
            pk: params.id as number,
            part: updates,
            skipSuccessToast: true,
          })
        );
      }
    },
    [projectStats?.pk, apiRef.current]
  );

  const onClickAddPart = () => {
    const newRow = {
      name: "New Part",
      pk: `${uuid()}`,
    };
    setGridData({
      ...gridData,
      rows: [newRow, ...gridData.rows],
    });
    dispatch(
      projectActions.createProjectPartRequest({
        part: {
          name: newRow.name,
          project: projectStats?.pk,
        },
        skipSuccessToast: true,
      })
    );
  };

  const onAddPart = (part: ProjectCompanyPart) => {
    setGridData({
      ...gridData,
      rows: [part, ...gridData.rows],
    });
  };

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent<Element, MouseEvent>>
  ) => {
    dispatch(projectActions.setSelectedPart(params.row as ProjectCompanyPart));
    switch (params.field) {
      case ProjectsPartField.DISPLAY_NAME:
        if (params.row.rfq) {
          dispatch(
            modalsActions.showModal({
              key: ProjectModalTypes.RFQ_DETAILS_MODAL,
              data: params.row.rfq,
            })
          );
        }

        break;
      case ProjectsPartField.EDIT_BUTTON:
        if (isRFQEditMode) {
          //in case there is only one single part during rfq edit
          if (gridData.rows.length == 1) {
            snackbarUtils.error(`${t("project:deleteItemWarning")}`);
          } else {
            dispatch(workspaceActions.deleteRFQPartForEdit(params.row));
          }
        } else {
          setMenuAnchorEl(event?.currentTarget);
        }
        break;
    }
  };

  const onImportFile = (file: any) => {
    if (file) {
      dispatch(projectActions.resetBulkPartImportReport());

      dispatch(
        projectActions.bulkInsertProjectPartRequest({
          file,
        })
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchValueWithDebounce = useCallback(
    debounce(
      (value) =>
        setProjectPartTableDataParams((prev) => ({
          ...prev,
          currentPage: INITIAL_PAGE,
          debouncedSearchValue: value,
        })),
      1000
    ),
    []
  );

  const setSearchValue = (value: string) => {
    setSearchValueState(value);
    setSearchValueWithDebounce(value);

    if (selectedPartRows.length > 0) {
      resetSelections();
      snackbarUtils.toast(`${t("project:selectionCleared")}`);
    }
  };

  const clearSearch = () => {
    setSearchValueState("");
    setProjectPartTableDataParams((prev) => ({
      ...prev,
      currentPage: INITIAL_PAGE,
      debouncedSearchValue: "",
    }));
  };

  const onPageChange = (newPage: number) => {
    setProjectPartTableDataParams((prev) => ({
      ...prev,
      currentPage: newPage + 1,
    }));
  };

  const checkboxColumn = useMemo(
    () => {
      const gridRowsFiltered = gridData.rows?.filter((row) => !row?.rfq);
      const selectedPartRowsFiltered = selectedPartRows?.filter((row) => !row?.rfq);

      const onCheckboxChange =
        ({ row, selectAll = false }: { row?: GridRenderCellParams["row"]; selectAll?: boolean }) =>
        () => {
          if (projectCompanyPartsSelectAll && !selectAll) {
            dispatch(projectActions.setProjectCompanyPartsSelectAll(selectAll));
            dispatch(
              projectActions.setSelectedPartRows(
                selectedPartRowsFiltered?.length === PAGINATION_PAGE_SIZE && row
                  ? addOrRemove(selectedPartRowsFiltered, row)
                  : []
              )
            );
          } else {
            dispatch(
              projectActions.setSelectedPartRows(
                selectAll
                  ? selectedPartRowsFiltered?.length === PAGINATION_PAGE_SIZE ||
                    selectedPartRowsFiltered?.length > 0
                    ? []
                    : gridRowsFiltered
                  : row
                    ? addOrRemove(selectedPartRowsFiltered, row)
                    : []
              )
            );
          }
        };

      return getProjectPartTableCheckboxColumn(
        onCheckboxChange,
        selectedPartRowsFiltered,
        projectCompanyPartsLoading || projectBulkDeletePartLoading,
        projectCompanyPartsSelectAll,
        gridRowsFiltered?.length === 0
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedPartRows,
      projectCompanyPartsLoading,
      projectBulkDeletePartLoading,
      gridData.rows,
      projectCompanyPartsSelectAll,
      projectPartTableDataParams?.filterModel,
    ]
  );

  const onFilterChange = (filterModel: GridFilterModel) => {
    dispatch(projectActions.setProjectCompanyPartsFiltered(filterModel.items.length > 0));

    resetSelections();

    setProjectPartTableDataParams((prev) => ({
      ...prev,
      filterModel,
    }));

    if (filterModel.items.length === 0) {
      apiRef.current.hideFilterPanel();
    }
  };

  const onClickMenuItem = (menuItem: ProjectPartTableMenuItemType) => {
    switch (menuItem) {
      case ProjectPartTableMenuItemType.CLONE:
        dispatch(
          projectActions.cloneProjectPartRequest({
            partPk: Number(selectedPart?.pk),
          })
        );
        setMenuAnchorEl(null);
        break;
      case ProjectPartTableMenuItemType.DELETE:
        confirmDialog(
          t("common:areYouSureWantToDelete"),
          t("project:deletePartWarningText", { count: 1 }),
          () => {
            dispatch(projectActions.deleteProjectPartRequest(selectedPart?.pk));
          },
          t("common:delete"),
          "delete"
        );
        setMenuAnchorEl(null);
        break;
    }
  };

  const selectedPartCount = projectCompanyPartsSelectAll
    ? projectCompanyPartsCount
    : selectedPartsSelectionModel?.length;

  const handleSubmitWithoutRevisionNote = (values?: RFQPartsRevisionFormValues) => {
    dispatch(
      workspaceNextActions.sendRevisionRequest({
        rfqId: rfqDetails.pk,
        note: values?.note,
        parts: rfqParts,
        revisionWithoutNote: true,
        partsIDs: deletedPartsIDs,
        onSuccess: () => {
          dispatch(modalsActions.closeModal(WorkspaceModalTypes.RFQ_PARTS_REVISION_MODAL));
          dispatch(modalsActions.closeModal(WorkspaceModalTypes.RFQ_ADD_FILES_AND_PARTS_MODAL));
          window.location.reload();
        },
      })
    );
  };

  useEffect(() => {
    if (isRFQEditMode) {
      setColumns(restoreState(gridData?.columns || []));
    } else if (gridData?.columns) {
      setColumns(restoreState([...checkboxColumn, ...gridData?.columns]));
    }
  }, [gridData?.columns, checkboxColumn, isRFQEditMode, apiRef]);

  return (
    <Box className="c-project-part-table c-parts-table-column-borders">
      <Box className="c-project-part-table__container">
        {!!rfqData && (
          <UpperSectionFromRfqCreation
            onClickAddRow={onClickAddPart}
            onAddFile={onImportFile}
            rfqData={rfqData}
            onAddPart={onAddPart}
            isProjectEmpty={empty}
            isRFQEditMode={isRFQEditMode}
          />
        )}

        <DataGridProV5
          className={showTemplateSelector ? classes.grid : classes.gridDefault}
          apiRef={apiRef}
          sx={
            isRFQEditMode
              ? {
                  overflowY: "auto",
                  minHeight: "80%",
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                  },
                  "& .MuiDataGrid-main": {
                    height: "270px",
                    overflowY: "auto",
                  },
                  ".MuiDataGrid-cell:first-of-type": {
                    borderLeft: "0px solid !important",
                  },
                  ".MuiDataGrid-columnHeader:first-of-type": {
                    borderLeft: "0px solid !important",
                  },
                  ".MuiDataGrid-cell:last-child": {
                    borderRight: "0px solid !important",
                  },
                  ".MuiDataGrid-columnHeader:last-child": {
                    borderRight: "0px solid !important",
                  },
                  " .MuiDataGrid-columnSeparator--sideRight": {
                    opacity: "0 !important",
                  },
                }
              : {
                  borderTop: "none",
                }
          }
          hideFooterRowCount
          autoHeight={!showTemplateSelector}
          disableSelectionOnClick
          rowHeight={32}
          headerHeight={39}
          pagination={isRFQEditMode ? false : !showTemplateSelector}
          disableColumnResize={isRFQEditMode ? true : false}
          autoPageSize
          pageSize={PAGINATION_PAGE_SIZE}
          onCellClick={onCellClick}
          rows={gridData?.rows || []}
          columns={columns}
          isCellEditable={isCellEditable}
          isRowSelectable={(params: GridRowParams) => !params.row.rfq}
          getRowId={(row: GridRowModel) => row.pk}
          disableColumnReorder={hasViewerOrRestrictedViewerRole}
          onCellEditCommit={onCellEditCommit}
          components={{
            NoRowsOverlay: () =>
              showTemplateSelector && !rfqData ? (
                <TemplateSelector
                  value={projectStats?.template as ProjectTemplate}
                  onChange={handleTemplateChange}
                  options={projectTemplates}
                  disabled={!empty && projectTemplatesLoading}
                  asProjectOverlay
                />
              ) : (
                <NoRowsOverlayBase />
              ),
            Toolbar: () =>
              !rfqData && (
                <ProjectPartTableToolbar
                  onClickAddPart={onClickAddPart}
                  onImport={onImportFile}
                  showProjectPartSelectedOptions={showProjectPartSelectedOptions}
                  selectedPartCount={selectedPartCount}
                  searchTextFieldProps={{
                    clearSearch,
                    setSearchValue,
                    searchValue,
                    disabled: projectCompanyPartsLoading || projectBulkDeletePartLoading,
                  }}
                />
              ),
          }}
          onPageChange={onPageChange}
          paginationMode="server"
          rowCount={projectCompanyPartsCount}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          localeText={{
            toolbarColumns: t("common:columns"),
            toolbarFilters: t("common:filters"),
          }}
        />

        {!!rfqData && rfqAdditionalFiles && rfqAdditionalFiles.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              paddingBottom: "20px",
            }}
          >
            <Typography variant="body2" color={"textSecondary"}>
              {t("workspaceNext:rfqDrawer:partsAndFilesModal:additionalFiles")}
            </Typography>
            {rfqAdditionalFiles.map((file) => (
              <DownloadableFileItem
                file={file}
                showSize
                onDelete={(file) => rfqData.onAttachmentsChange([], file.pk)}
              />
            ))}
          </Box>
        )}

        <ProjectPartTableEditMenu
          anchorEl={menuAnchorEl}
          setAnchorEl={setMenuAnchorEl}
          onClickMenuItem={onClickMenuItem}
          disabledClone={!selectedPart?.rfq}
          disabledDelete={!!selectedPart?.rfq}
        />
        {isRFQEditMode && (
          <>
            <Divider />
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ maxWidth: "50%" }} variant="body2">
                {t("project:sendRevisionText")}
              </Typography>
              <RFQPartsStatusBar onDismiss={handleSubmitWithoutRevisionNote} />
            </Box>
          </>
        )}
      </Box>

      {/* MODALS */}
      <ProjectBulkPartImportLoaderModal />
      <ProjectIsEditRFQCancelModal />
      <ProjectFileDeleteConfirmationModal />
    </Box>
  );
};
