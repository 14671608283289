export const userOnboardingConstants = {
  // user onboarding
  SET_ACTIVE_STEP: "SET_ACTIVE_STEP",
  SET_COMPANY_INFORMATION: "SET_COMPANY_INFORMATION",
  SET_COMPANY_REFERENCES: " SET_COMPANY_REFERENCES",

  // user onboarding
  SET_USER_INFORMATION: "SET_USER_INFORMATION",
  SET_COMPANY_TYPE: "SET_COMPANY_TYPE",

  SET_REMOVE_UPLOADED_FILES_SUCCESS: "SET_REMOVE_UPLOADED_FILES_SUCCESS",
  SET_UPLOADED_FILES_SUCCESS: "SET_UPLOADED_FILES_SUCCESS",

  SET_IS_CHOOSE_COMPANY_TYPE: "SET_IS_CHOOSE_COMPANY_TYPE",
  IS_UPLOAD_DONE: "IS_UPLOAD_DONE",

  REGISTER_USER_REQUEST: "REGISTER_SUPPLIER_REQUEST",
  REGISTER_USER_SUCCESS: "REGISTER_SUPPLIER_SUCCESS",
  REGISTER_USER_FAILURE: "REGISTER_SUPPLIER_FAILURE",

  RESET_TO_INITIAL_STATE: "RESET_TO_INITIAL_STATE",
};
