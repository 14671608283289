import { Box, Divider, Theme, Typography } from "@mui/material";
import CustomButton from "@next/components/custom-button";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUpdateOrderLoading } from "../../redux/selectors";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      position: "sticky",
      bottom: 0,
      backgroundColor: theme.palette.common.white,
      display: "flex",
      flexDirection: "column",
      zIndex: 10,
    },
    button: {
      height: 40,
    },
  })
);

interface Props {
  onSubmit?: () => void;
  isSubmitting?: boolean;
}

const SendOrderDrawerFooter: React.FC<Props> = (props) => {
  const { onSubmit, isSubmitting } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const updateOrderLoading = useSelector(selectUpdateOrderLoading);

  return (
    <div className={classes.footer}>
      <Divider light />
      <Box m={2} display="flex" justifyContent="flex-end" alignItems="center">
        <Box display="flex" alignItems="center">
          <CustomButton
            onClick={onSubmit}
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            loading={isSubmitting && updateOrderLoading}
          >
            <Typography variant="body2">{t("workspaceNext:rfqDrawer:send")}</Typography>
          </CustomButton>
        </Box>
      </Box>
    </div>
  );
};

export default SendOrderDrawerFooter;
