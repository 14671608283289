import { Avatar, Tooltip } from "@mui/material";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { selectCompanyProfile } from "@next/modules/profile/redux/selectors";
import { getInitials } from "@next/utils/stringUtils";
import { history } from "helpers/history";
import { AppTheme } from "layout/AppTheme";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCompany } from "services/profile/profile.selectors";
import { frontendUrl } from "urls";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  avatar: {
    "& > img": {
      width: "auto",
      backgroundColor: AppTheme.palette.background.default,
    },
    borderRadius: "8px",
    cursor: "pointer",
    width: "fit-content",
  },
}));

export const AppBarNextCompanyLogo = () => {
  const company = useSelector(getCompany);
  const companyProfile = useSelector(selectCompanyProfile);
  const location = useLocation();
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);

  const onLoaded = useCallback(() => setLoaded(true), []);

  const onClickProfileLink = () => {
    history.push(hasViewerRole ? "/" : frontendUrl.companyProfile);
  };

  return (
    <Tooltip title={companyProfile?.name || company?.name}>
      <Avatar
        imgProps={{ onLoad: onLoaded }}
        className={classes.avatar}
        variant="rounded"
        src={companyProfile?.picture || company?.picture}
        style={{
          border: `1px solid ${
            location.pathname === frontendUrl.companyProfile
              ? AppTheme.palette.primary.main
              : AppTheme.palette.divider
          }`,
          minWidth: loaded ? undefined : 40,
        }}
        onClick={onClickProfileLink}
      >
        {companyProfile?.name || company?.name
          ? getInitials(companyProfile?.name || company?.name)
          : null}
      </Avatar>
    </Tooltip>
  );
};
