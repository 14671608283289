import _ from "lodash";

export function groupAndMergeBySupplierId(data: any) {
  let groupedData: any = [];

  groupedData = _.chain(data)
    .groupBy("id")
    .map((group, id) => ({
      supplier_id: parseInt(id, 10),
      parts: _.map(group, "partId"),
    }))
    .value();

  return groupedData;
}
