import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "@next/components/custom-modal";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "./types";
import { CreateOrderForm } from "../components/create-order-form/create-order-form";
import { isEmpty } from "lodash";

export const CreateOrderModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(OrdersModalTypes.CREATE_ORDER_MODAL));
  const data = modal?.data as {
    rfqPk?: number;
    rfqName?: string;
  };
  const isManualOrder = isEmpty(data?.rfqPk);

  if (!modal) return null;

  const onClose = () => {
    dispatch(modalsActions.closeModal(OrdersModalTypes.CREATE_ORDER_MODAL));
  };

  return (
    <Modal
      handleModalClose={onClose}
      modalState={{
        modalHeader: t(
          isManualOrder ? "orders:createManualOrder:title" : "orders:sendOrderDrawer:title"
        ),
        modalBody: (
          <CreateOrderForm
            rfqPk={data?.rfqPk}
            rfqName={data?.rfqName}
            onClose={onClose}
            isManualOrder={isManualOrder}
          />
        ),
      }}
      isModalOpen={true}
    />
  );
};
