export const OTHER_REASON_SLUG = "other";

export function getITNBReasonLabel({
  slug,
  title,
  description,
}: {
  slug?: string;
  title?: string;
  description?: string;
}) {
  if (slug === OTHER_REASON_SLUG) return description ? ` · ${description}` : "";
  return slug && title ? ` · ${title}` : "";
}
