import { connect } from "react-redux";

// Action Import
import { modalsActions } from "../../services/modals/modals.actions";
import { inboxActions } from "../../services/inbox/inbox.actions";

import {
  emitMessage,
  listenToNewMessages,
  onReceiveRFQRoomMsg,
} from "../../services/socket/socket.actions";

import { Inbox } from "./chat-dialog.component";

const mapDispatchToProps = {
  handleOpenModal: modalsActions.showModal,
  getQuotationMessages: inboxActions.getQuotationMessages,
  publishMessage: inboxActions.publishMessage,
  sendQuotationMessage: inboxActions.sendQuotationMessage,
  setChatWindowState: inboxActions.setMinimizeChatWindow,
  setActiveQuotationThread: inboxActions.setActiveQuotationThread,
  emitMessage,
  listenToNewMessages,
  onReceiveRFQRoomMsg,
};

const mapStateToProps = (state) => {
  return {
    token: state.profile.token,
    company: state.profile.company,
    user: state.profile.user,
    modalState: state.modals ? state.modals.isChatOpen : false,
    quotationMessages: state.inbox.quotationMessages,
    isLoading: state.inbox.isLoading,
    messages: state.inbox.messages,
    activeQuotationThread: state.inbox.activeQuotationThread,
    isInboxOpen: state.modals ? state.modals.isInboxOpen : false,
    chatWindowState: state.inbox.chatWindowState,
    isQuotationMessagesLoading: state.inbox.isQuotationMessagesLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
