import React from "react";
import { useSelector } from "react-redux";
import { getCompany } from "services/profile/profile.selectors";
import { checkIsSellerCompany } from "@next/utils/checkCompanyUtils";
import { CompanyTypeNext } from "@next/redux/types";
import { SellerDashboard } from "./seller-dashboard/seller-dashboard";
import { useCompanyPlan } from "@next/hooks/useIsPremiumCompany";
import { FreemiumBuyerDashboard } from "./freemium-buyer-dashboard/freemium-buyer-dashboard";
import { BuyerDashboard } from "./buyer-dashboard";

export const Dashboard = () => {
  const company = useSelector(getCompany);
  const isSeller = checkIsSellerCompany(company?.type as CompanyTypeNext);
  const isFreemiumBuyer = useCompanyPlan("freemium");

  const renderDashboard = () => {
    if (isSeller) {
      return <SellerDashboard />;
    } else {
      if (isFreemiumBuyer) {
        return <FreemiumBuyerDashboard />;
      }

      return <BuyerDashboard />;
    }
  };

  return renderDashboard();
};
