import React, { useCallback, useEffect, useMemo, useState } from "react";
import MUIRichTextEditor from "mui-rte";
import { Box, Theme } from "@mui/material";
import { EditorState, RawDraftContentState, convertToRaw } from "draft-js";
import { defaultControls, getRawDraftStateAsString, truncate } from "./rich-text-editor-utils";
import LinkButton from "@next/components/link-button";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

type RichTextEditorProps = React.ComponentProps<typeof MUIRichTextEditor> & {
  onChange?: (state: RawDraftContentState) => void;
  defaultValue?: any;
  value?: any;
  maxCharsToShow?: number;
  minHeight?: string | number;
  maxHeight?: string | number;
};

type StyleProps = {
  minHeight: number | string;
  maxHeight: number | string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 0,
      padding: theme.spacing(0.5),
      outline: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: theme.shape.borderRadius,
      "&:hover": {
        outline: `1px solid ${theme.palette.text.primary}`,
      },
      "&:focus-within": {
        outline: `2px solid ${theme.palette.text.primary}`,
      },
    },
    editorContainer: {
      minHeight: ({ minHeight }) => minHeight,
      marginTop: 0,
      padding: theme.spacing(1, 1.75),
      maxHeight: ({ maxHeight }) => maxHeight,
      overflowY: "auto",
      whiteSpace: "pre-wrap",
    },
    editor: {
      minHeight: ({ minHeight }) => minHeight,
    },
    toolbar: {
      "& button.MuiIconButton-colorPrimary": {
        color: theme.palette.text.primary,
      },
    },
  })
);

const useReadOnlyStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 0,
      fontSize: "inherit",
    },
    editorContainer: {
      marginTop: 0,
    },
  })
);

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  minHeight = 48,
  maxHeight = 384,
  ...props
}) => {
  const classes = useStyles({ minHeight, maxHeight });
  const readOnlyClasses = useReadOnlyStyles();
  const { t } = useTranslation();

  const [value, setValue] = useState(getRawDraftStateAsString(props?.value || ""));
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    setValue(getRawDraftStateAsString(props?.value || ""));
  }, [props?.value]);

  //only set default value (if present) once at mount
  useEffect(() => {
    if (!props?.value) {
      setValue(getRawDraftStateAsString(props?.defaultValue || ""));
    }
  }, []);

  const onChange = useCallback(
    (state: EditorState) => {
      typeof props?.onChange === "function" &&
        props.onChange(convertToRaw(state.getCurrentContent()));
    },
    [props?.onChange]
  );

  const handleClick = useCallback(() => {
    setCollapsed((val) => !val);
  }, []);

  const truncationEnabled = props.maxCharsToShow && props.readOnly;
  const [truncated, isTruncated] = useMemo(() => {
    return truncationEnabled ? truncate(value, props.maxCharsToShow as number) : [];
  }, [truncationEnabled, value, props.maxCharsToShow]);

  return (
    <span>
      <MUIRichTextEditor
        classes={props?.readOnly ? readOnlyClasses : classes}
        controls={defaultControls}
        toolbar={!props?.readOnly}
        {...props}
        onChange={onChange}
        defaultValue={truncationEnabled ? (collapsed ? truncated : value) : value}
      />
      {truncationEnabled && isTruncated && (
        <Box mt={2}>
          <LinkButton onClick={handleClick}>
            {collapsed ? t("rte:readMore") : t("rte:readLess")}
          </LinkButton>
        </Box>
      )}
    </span>
  );
};

export default RichTextEditor;
