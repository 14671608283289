import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { BuyerRfqsInformationDetail } from "./buyer-rfqs-information-detail";
import { BuyerRfqsInformationHeader } from "./buyer-rfqs-information-header";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import { BuyerRfqsInformationPartList } from "./buyer-rfqs-information-part-list";
import { useCompanyPlan } from "@next/hooks/useIsPremiumCompany";

export const BuyerRfqsInformation = () => {
  const rfqDetails = useSelector(getRfqDetails);
  const isFreemiumBuyer = useCompanyPlan("freemium");

  return (
    <Grid container direction="column" spacing={6}>
      <Grid item xs={12} width="100%">
        <BuyerRfqsInformationHeader />
      </Grid>

      <Grid item xs={12}>
        <BuyerRfqsInformationDetail />
      </Grid>

      {rfqDetails.request_type === "rfq" && !isFreemiumBuyer && (
        <Grid item xs={12}>
          <BuyerRfqsInformationPartList />
        </Grid>
      )}
    </Grid>
  );
};
