import React, { useEffect } from "react";
import { Avatar, Box, Checkbox, FormControl, FormControlLabel } from "@mui/material";
import AirbusLogo from "../../../../../assets/airbus-logo-light.png";
import { useDispatch, useSelector } from "react-redux";
import {
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { useParams } from "react-router-dom";
import {
  selectIdentificationData,
  selectIsLoadingIdentificationData,
} from "@next/modules/vendor-management/redux/selectors";
import { modalsActions } from "@next/redux/modalsSlices";
import * as S from "./thank-you-modal-body.styled";

const titleText = "Thank you for completing this questionnaire";
const bodyText = "Airbus Canada team will contact you in the coming days.";

export const ThankYouModalBody: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const identificationData = useSelector(selectIdentificationData);
  const isLoadingIdentificationData = useSelector(selectIsLoadingIdentificationData);
  const is_form_validated = identificationData?.is_form_validated;

  useEffect(() => {
    dispatch(vendorManagementActions.fetchIdentificationDataRequest(companyId));
  }, []);

  const handleValidateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(vendorManagementActions.setIsFormValidated(event.target.checked));
    if (event.target.checked) {
      dispatch(
        vendorManagementActions.updateIdentificationDataRequest({
          companyId: companyId,
          data: {
            is_form_validated: event.target.checked,
          },
          onSuccess: closeModal,
        })
      );
    }
  };

  const closeModal = () => {
    dispatch(modalsActions.closeModal(VendorManagementModalTypes.THANK_YOU_MODAL));
  };

  return (
    <S.StyledBox>
      <S.StyledCircleBox>
        <S.StyledCheckIcon />
      </S.StyledCircleBox>

      <S.StyledTitleTypography variant="h5">{titleText}</S.StyledTitleTypography>

      <S.BodyTypography variant="body1">{bodyText}</S.BodyTypography>

      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              disabled={isLoadingIdentificationData}
              checked={is_form_validated || false}
              onChange={handleValidateChange}
            />
          }
          label="I validate that all the information declared is under my own responsability"
        />
      </FormControl>

      <Box>
        <Avatar sx={{ width: "100%", height: "22px" }} src={AirbusLogo} variant="square" />
      </Box>
    </S.StyledBox>
  );
};
