import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import HelpIcon from "@mui/icons-material/Help";
import { useTranslation } from "react-i18next";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { useDispatch } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { ContactUsModal } from "@next/modules/workspace/modals/contact-us-modal";

export const ContactSupportItem = () => {
  const { t } = useTranslation();
  const axyaTracking = useAxyaTracking();
  const dispatch = useDispatch();

  const onClickOpenContactSupportModal = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.CONTACT_US_MODAL,
        data: {
          modalTitle: t("contactUs:contactSupport"),
          hasSubjectAndMsg: true,
          hasFormTitle: true,
          formTitle: t("contactUs:contactSupportFormTitle"),
          label: t("contactUs:contactSupportTextInputLabel"),
        },
      })
    );
    axyaTracking({
      scout_category: "contact-us",
      scout_feature_name: "open-contact-support-modal-from-app-bar",
    });
  };

  return (
    <>
      <Tooltip title={t("appTour:contactSupport")}>
        <IconButton
          data-tut="reactour__contactSupport"
          onClick={onClickOpenContactSupportModal}
          size="large"
        >
          <HelpIcon style={{ color: "white" }} />
        </IconButton>
      </Tooltip>

      <ContactUsModal />
    </>
  );
};
