import track from "react-tracking";
import AppRouter from "@next/routes/AppRouter";

import { sendTrackingData } from "services/tracking/tracking.actions";

declare global {
  interface Window {
    dataLayer: any;
  }
}

const App = track(
  // app-level tracking data
  { app: "axya-app" },
  {
    // custom dispatch to console.log in addition to pushing to dataLayer[]
    dispatch: (data: any) => {
      import.meta.env.VITE_ENV === "development" && console.log(data);
      (window.dataLayer = window.dataLayer || []).push(data);
      sendTrackingData(data);
    },
  }
)(AppRouter);

export default App;
