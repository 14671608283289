import { axiosApi } from "@next/api";
import { backendUrl } from "urls";
import {
  AddOrUpdateProcessControlStandard,
  AddSpecToScopeRequest,
  AddSpecToSubtierSupplier,
  CreateNewMessageThreadParams,
  CreateNewMessageWithinThreadParams,
  DeleteProcessControlStandard,
  DeleteSpecOfSubtierSupplier,
  FetchDesignAndBuiltDrawings,
  FetchFormDataRequest,
  FetchMessageThreadsOfStepParams,
  FetchMessagesOfThreadParam,
  FetchNumberOfLinesApprovedParams,
  FetchQuestionnairesRequestInput,
  FetchSpecsOfBufferStockInput,
  FetchSpecsOfLeadTimeProductionInput,
  FetchSpecsOfStandardParams,
  FetchSpecsOfSubtierSupplier,
  FetchStepsRequest,
  PaginationAndFilterForScopeSpecs,
  PaginationAndFilterForSpecification,
  RemoveSpecFromScopeRequest,
  SaveAdditionalComment,
  SaveSpecOfScopeRequest,
  SendSupportMessageInput,
  UpdateApprovalFormFile,
  UpdateApprovalStatusOfSpecParams,
  UpdateApprovalStatusOfStepParams,
  UpdateDesignAndBuildDrawing,
  UpdateFormDataRequest,
  UpdateIdentificationDataBody,
  UpdateLeadTimeImplFormData,
  UpdateSpecOfBufferStockStrategy,
  UpdateSpecOfLeadTimeForProduction,
  UpdateSpecOfSubtierSupplier,
  UpdateStepRequest,
  UpdateSubtierFormData,
  UpdateSubtierOnboardingTableRow,
  UpdateSubtiersOnboardingFormData,
  UpdateThreadStatusParams,
} from "../redux";
import { generateSearchAndFilterSpecQuery } from "../utils/generateSearchAndFilterSpecQuery";

const fetchFormData = (params: FetchFormDataRequest) => {
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementUrls[params.formName]}/${params.companyId}/`
  );
};

const updateFormData = (params: UpdateFormDataRequest) => {
  const payload = params.data;
  return axiosApi.put(
    `${backendUrl.airbusVendorManagementUrls[params.formName]}/${params.companyId}/`,
    payload
  );
};

const fetchSteps = (params: FetchStepsRequest) => {
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/step-completion-status/company/${params.companyId}/questionnaire-type/${params.questionnaireType}/`
  );
};

const updateStep = (payload: UpdateStepRequest) => {
  return axiosApi.put(
    `${backendUrl.airbusVendorManagementBaseUrl}/step-completion-status/${payload.stepId}/`,
    payload.data
  );
};

const fetchProcessControlStandards = (payload: FetchSpecsOfStandardParams) => {
  const query = generateSearchAndFilterSpecQuery({
    entityId: payload.entityId,
  });
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/standard/${payload.companyId}/?${query}`
  );
};

const fetchAllSpecifications = (payload: PaginationAndFilterForSpecification) => {
  const query = generateSearchAndFilterSpecQuery(payload);
  return axiosApi.get(`${backendUrl.airbusVendorManagementBaseUrl}/specifications/?${query}`);
};

const deleteProcessControlStandard = (payload: DeleteProcessControlStandard) => {
  const body = {
    standard_id: payload.standardId,
  };
  return axiosApi.delete(
    `${backendUrl.airbusVendorManagementBaseUrl}/standard/${payload.companyId}/`,
    { data: body }
  );
};

const addProcessControlStandard = (payload: AddOrUpdateProcessControlStandard) => {
  return axiosApi.post(
    `${backendUrl.airbusVendorManagementBaseUrl}/standard/${payload.companyId}/`,
    payload.standard
  );
};

const updateProcessControlStandard = (payload: AddOrUpdateProcessControlStandard) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementBaseUrl}/standard/${payload.companyId}/`,
    payload.standard
  );
};

const updateApprovalFormFile = (payload: UpdateApprovalFormFile) => {
  const formData = new FormData();
  formData.append("file", payload.file);
  formData.append("field_name", payload.field_name);
  formData.append("field_title", payload.field_title);
  formData.append("companyId", payload.companyId);
  return axiosApi.post(
    `${backendUrl.airbusVendorManagementBaseUrl}/approvals/upload_approval_file/`,
    formData
  );
};

const deleteApprovalFormFile = (fileId: string) => {
  return axiosApi.delete(
    `${backendUrl.airbusVendorManagementBaseUrl}/approvals/${fileId}/remove-file/`
  );
};

const fetchAclpClassifications = () => {
  return axiosApi.get(`${backendUrl.airbusVendorManagementBaseUrl}/aclp-classifications/`);
};

const fetchSupplierCategories = () => {
  return axiosApi.get(`${backendUrl.airbusVendorManagementBaseUrl}/supplier-categories/`);
};

const fetchSpecsOfScope = (params: PaginationAndFilterForScopeSpecs) => {
  const {
    pagination,
    companyId,
    search,
    specType,
    isInPomDistributor,
    filter_completed,
    entityId,
  } = params;
  const query = generateSearchAndFilterSpecQuery({
    page: pagination.page,
    pageSize: pagination.pageSize,
    search: search,
    specType: specType,
    isPomDistributor: isInPomDistributor,
    stepId: params.stepId,
    filter_completed: filter_completed,
    entityId: entityId,
  });
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/scopes/${companyId}/specifications/?${query}`
  );
};

const removeSpecFromScope = (params: RemoveSpecFromScopeRequest) => {
  return axiosApi.delete(
    `${backendUrl.airbusVendorManagementBaseUrl}/scopes/${params.companyId}/specifications/${params.specification_id}`
  );
};

const saveAdditionalCommentFieldOfForm = (params: SaveAdditionalComment) => {
  const body = {
    additional_comment: params.additionalComment,
  };
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementUrls[params.stepName]}/${params.companyId}/`,
    body
  );
};

const addSpecToScope = (params: AddSpecToScopeRequest) => {
  const body = {
    specification_id: params.row.specification_id,
  };
  return axiosApi.post(
    `${backendUrl.airbusVendorManagementBaseUrl}/scopes/${params.companyId}/specifications/`,
    body
  );
};

const saveSpecOfScope = (params: SaveSpecOfScopeRequest) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementBaseUrl}/scopes/${params.companyId}/specifications/${params.specificationId}`,
    params.spec
  );
};

const updateLeadTimeImplFormData = (params: UpdateLeadTimeImplFormData) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementUrls.leadTimeForImplementationForm}/${params.companyId}/`,
    params.formData
  );
};

const updateSubtiersFormData = (params: UpdateSubtierFormData) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementUrls.subtiersForm}/${params.companyId}/`,
    params.formData
  );
};

const fetchSubtierCompaniesRequest = (search: string) => {
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/subtiers/companies/search/?query=${search}`
  );
};

const fetchSpecsOfSubtierSupplier = (params: FetchSpecsOfSubtierSupplier) => {
  const query = generateSearchAndFilterSpecQuery({ entityId: params.entityId });
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/subtiers/${params.companyId}/supplier/${params.supplierId}/specifications/?${query}`
  );
};

const updateSpecOfSubtierSupplier = (params: UpdateSpecOfSubtierSupplier) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementBaseUrl}/subtiers/${params.companyId}/supplier/${params.supplierId}/specifications/${params.specificationId}/`,
    params.spec
  );
};

const deleteSpecOfSubtierSupplier = (params: DeleteSpecOfSubtierSupplier) => {
  return axiosApi.delete(
    `${backendUrl.airbusVendorManagementBaseUrl}/subtiers/${params.companyId}/supplier/${params.supplierId}/specifications/${params.specificationId}/`
  );
};

const addSpecToSubtierSupplier = (params: AddSpecToSubtierSupplier) => {
  return axiosApi.post(
    `${backendUrl.airbusVendorManagementBaseUrl}/subtiers/${params.companyId}/supplier/${params.supplierId}/specifications/`,
    params.spec
  );
};

const fetchSubtierOnboardingTableData = (companyId: string) => {
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/subtiers/${companyId}/suppliers-access/`
  );
};

const updateSubtiersOnboardingFormData = (params: UpdateSubtiersOnboardingFormData) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementUrls.subtiersOnboardingForm}/${params.companyId}/`,
    params.formData
  );
};

const updateSubtierOnboardingTableRow = (params: UpdateSubtierOnboardingTableRow) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementBaseUrl}/subtiers/${params.companyId}/supplier/${params.supplierId}/access/`,
    params.data
  );
};

const fetchDesignAndBuiltDrawings = (params: FetchDesignAndBuiltDrawings) => {
  const { pagination, filter_completed, entityId } = params;
  const query = generateSearchAndFilterSpecQuery({
    page: pagination.page,
    pageSize: pagination.pageSize,
    filter_completed: filter_completed,
    entityId: entityId,
  });
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/design-and-built/${params.companyId}/drawings/?${query}`
  );
};

const updateDesignAndBuiltDrawing = (params: UpdateDesignAndBuildDrawing) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementBaseUrl}/design-and-built/${params.companyId}/drawings/${params.drawingId}/`,
    params.data
  );
};

const fetchSpecsOfLeadTimeForProduction = (params: FetchSpecsOfLeadTimeProductionInput) => {
  const { page, pageSize, filter_completed, entityId } = params;
  const query = generateSearchAndFilterSpecQuery({
    page: page,
    pageSize: pageSize,
    filter_completed: filter_completed,
    entityId: entityId,
  });
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/lead-time-production/${params.companyId}/specifications/?${query}`
  );
};

const updateSpecOfLeadTimeForProduction = (params: UpdateSpecOfLeadTimeForProduction) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementBaseUrl}/lead-time-production/${params.companyId}/specifications/${params.specificationId}/`,
    params.data
  );
};

const fetchSpecsOfBufferStockStrategy = (params: FetchSpecsOfBufferStockInput) => {
  const { pagination, filter_completed } = params;
  const query = generateSearchAndFilterSpecQuery({
    page: pagination.page,
    pageSize: pagination.pageSize,
    filter_completed: filter_completed,
    entityId: params.entityId,
  });
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/buffer-stock/${params.companyId}/specifications/?${query}`
  );
};

const updateSpecOfBufferStockStrategy = (params: UpdateSpecOfBufferStockStrategy) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementBaseUrl}/buffer-stock/${params.companyId}/specifications/${params.specificationId}/`,
    params.data
  );
};

const fetchSuppliersBySearchText = (searchText: string) => {
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/supplier-search/?name=${searchText}`
  );
};

const fetchSuppliersByCompanyId = (company_id: string) => {
  return axiosApi.get(`${backendUrl.airbusVendorManagementBaseUrl}/supplier-detail/${company_id}/`);
};

const sendSupportMessage = (params: SendSupportMessageInput) => {
  const formData = new FormData();
  formData.append("msg", params.data.msg);
  formData.append("subject", params.data.subject);
  formData.append("supplier_email", params.data.email);
  if (params.data.attachments && params.data.attachments?.length > 0) {
    params.data.attachments.forEach((attachment) => formData.append("attachments", attachment));
  }
  return axiosApi.post(
    `${backendUrl.airbusVendorManagementBaseUrl}/contact-us/${params.companyId}/`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const fetchIdentificationData = (companyId: string) => {
  return axiosApi.get(`${backendUrl.airbusVendorManagementUrls.identificationForm}/${companyId}/`);
};

const updateIdentificationData = (params: UpdateIdentificationDataBody) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementUrls.identificationForm}/${params.companyId}/`,
    params.data
  );
};

// for validation management
const fetchQuestionnairesRequest = (params: FetchQuestionnairesRequestInput) => {
  const query = generateSearchAndFilterSpecQuery({
    page: params.page,
    pageSize: params.pageSize,
    supplier: params.search,
    status: params.status,
  });

  return axiosApi.get(`${backendUrl.airbusVendorManagementBaseUrl}/dashboard/?${query}`);
};

const fetchMessageThreadsOfStepRequest = (params: FetchMessageThreadsOfStepParams) => {
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/reviews/${params.companyId}/${params.stepId}/`
  );
};

const createNewMessageThreadRequest = (params: CreateNewMessageThreadParams) => {
  const { body } = params;
  return axiosApi.post(
    `${backendUrl.airbusVendorManagementBaseUrl}/reviews/${params.companyId}/${params.stepId}/`,
    {
      ...body,
    }
  );
};

const createNewMessageWithinThreadRequest = (params: CreateNewMessageWithinThreadParams) => {
  return axiosApi.post(
    `${backendUrl.airbusVendorManagementBaseUrl}/reviews/${params.messageThreadId}/messages/`,
    {
      body: params.body,
    }
  );
};

const fetchMessagesOfThreadRequest = (params: FetchMessagesOfThreadParam) => {
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/reviews/${params.messageThreadId}/messages/`
  );
};

const updateMessageThreadRequest = (params: UpdateThreadStatusParams) => {
  return axiosApi.patch(
    `${backendUrl.airbusVendorManagementBaseUrl}/reviews/${params.messageThreadId}/`,
    {
      is_resolved: params.isResolved,
    }
  );
};

const updateApprovalStatusOfStepRequest = (params: UpdateApprovalStatusOfStepParams) => {
  return axiosApi.post(
    `${backendUrl.airbusVendorManagementBaseUrl}/step-approval/${params.companyId}/${params.stepId}/`,
    {
      status: params.status,
    }
  );
};

const updateApprovalStatusOfSpecRequest = (params: UpdateApprovalStatusOfSpecParams) => {
  return axiosApi.post(
    `${backendUrl.airbusVendorManagementBaseUrl}/step-approval/${params.companyId}/${params.stepId}/`,
    {
      ...params.body,
    }
  );
};

const fetchNumberOfUnapprovedLinesOfStep = (params: FetchNumberOfLinesApprovedParams) => {
  return axiosApi.get(
    `${backendUrl.airbusVendorManagementBaseUrl}/step-approval-count/${params.companyId}/${params.stepId}/`
  );
};

export const vendorManagementService = {
  fetchFormData,
  updateFormData,
  fetchSteps,
  updateStep,
  fetchProcessControlStandards,
  fetchAllSpecifications,
  deleteProcessControlStandard,
  addProcessControlStandard,
  updateProcessControlStandard,
  updateApprovalFormFile,
  fetchAclpClassifications,
  fetchSupplierCategories,
  deleteApprovalFormFile,
  fetchSpecsOfScope,
  removeSpecFromScope,
  saveAdditionalCommentFieldOfForm,
  addSpecToScope,
  saveSpecOfScope,
  updateLeadTimeImplFormData,
  fetchSubtierCompaniesRequest,
  fetchSpecsOfSubtierSupplier,
  updateSubtiersFormData,
  updateSpecOfSubtierSupplier,
  deleteSpecOfSubtierSupplier,
  addSpecToSubtierSupplier,
  fetchSubtierOnboardingTableData,
  updateSubtiersOnboardingFormData,
  updateSubtierOnboardingTableRow,
  fetchDesignAndBuiltDrawings,
  updateDesignAndBuiltDrawing,
  fetchSpecsOfLeadTimeForProduction,
  updateSpecOfLeadTimeForProduction,
  fetchSpecsOfBufferStockStrategy,
  updateSpecOfBufferStockStrategy,
  fetchSuppliersBySearchText,
  fetchSuppliersByCompanyId,
  sendSupportMessage,
  fetchIdentificationData,
  updateIdentificationData,
  // for validation management
  fetchQuestionnairesRequest,
  fetchMessageThreadsOfStepRequest,
  createNewMessageThreadRequest,
  createNewMessageWithinThreadRequest,
  fetchMessagesOfThreadRequest,
  updateMessageThreadRequest,
  updateApprovalStatusOfStepRequest,
  updateApprovalStatusOfSpecRequest,
  fetchNumberOfUnapprovedLinesOfStep,
};
