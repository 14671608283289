import moment from "moment";
import * as yup from "yup";
import { OrderLineItem, OrderLineItemChanges } from "../../redux";

export type ReviewOrderFormState = {
  update_note: string;
  confirmation_file?: File | null;
  date?: string;
};

export const getReviewOrderFormState = ({
  defaultDate,
}: {
  defaultDate?: string;
}): ReviewOrderFormState => {
  return {
    update_note: "",
    date: defaultDate ? defaultDate : moment().format("YYYY-MM-DD"),
    confirmation_file: null,
  };
};

export const getReviewOrderFormValidationSchema = () => {
  return yup.object({
    note: yup.string(),
    date: yup.string(),
  });
};

export const convertLineItemsToLineItemsChanges = (
  lineItems: OrderLineItem[]
): OrderLineItemChanges[] => {
  return lineItems.map((item) => {
    const previousLineItem = Object.entries(item?.previous_line_item || {})
      .filter(([key, value]) => value !== undefined)
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    return {
      id: item.id,
      name: item.name,
      previous_line_item: previousLineItem,
      updated_delivery_date: item?.updated_delivery_date,
      quantity: item?.quantity,
      quotation_price: item?.quotation_price,
    };
  });
};
