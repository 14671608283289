import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  CustomQuotationExportInput,
  CustomQuotationImportData,
  CustomQuotationImportInput,
} from ".";
import {
  IntentToNoQuoteData,
  IntentToNoQuoteInput,
  MarketplaceState,
  PublicItbItnbRequestInput,
} from "./types";

export const initialState: MarketplaceState = {
  customQuotationExportLoading: false,
  customQuotationExportError: undefined,

  customQuotationImportLoading: false,
  customQuotationImportError: undefined,
  customQuotationImportedData: [],
  intentToNoQuoteLoading: false,
  intentToNoQuoteError: undefined,
  intentToNoQuoteData: {},
  isIntentToNoQuote: false,

  publicItbItnbLoading: false,
  publicItbItnbError: undefined,
};

const marketplaceSlice = createSlice({
  name: "marketplaceNext",
  initialState,
  reducers: {
    customQuotationExportRequest: (state, _action: PayloadAction<CustomQuotationExportInput>) => {
      state.customQuotationExportLoading = true;
      state.customQuotationExportError = undefined;
    },
    customQuotationExportFailure: (state, action: PayloadAction<Error>) => {
      state.customQuotationExportLoading = false;
      state.customQuotationExportError = action.payload;
    },
    customQuotationExportSuccess: (state) => {
      state.customQuotationExportLoading = false;
      state.customQuotationExportError = undefined;
    },
    customQuotationImportRequest: (state, _action: PayloadAction<CustomQuotationImportInput>) => {
      state.customQuotationImportLoading = true;
      state.customQuotationImportError = undefined;
    },
    customQuotationImportFailure: (state, action: PayloadAction<Error>) => {
      state.customQuotationImportLoading = false;
      state.customQuotationImportError = action.payload;
    },
    customQuotationImportSuccess: (state, action: PayloadAction<CustomQuotationImportData>) => {
      state.customQuotationImportLoading = false;
      state.customQuotationImportError = undefined;
      state.customQuotationImportedData = action.payload;
    },
    clearCustomQuotationImportedData: (state) => {
      state.customQuotationImportLoading = false;
      state.customQuotationImportError = undefined;
      state.customQuotationImportedData = [];
    },
    intentToNoQuoteFailure: (state, action: PayloadAction<Error>) => {
      state.intentToNoQuoteLoading = false;
      state.intentToNoQuoteError = action.payload;
    },
    intentToNoQuoteSuccess: (
      state,
      action: PayloadAction<{
        data: IntentToNoQuoteData;
        input: IntentToNoQuoteInput;
      }>
    ) => {
      state.intentToNoQuoteLoading = false;
      state.intentToNoQuoteError = undefined;
      state.intentToNoQuoteData = action.payload.data;
    },
    intentToNoQuoteRequest: (state, _action: PayloadAction<IntentToNoQuoteInput>) => {
      state.intentToNoQuoteLoading = false;
      state.intentToNoQuoteError = undefined;
    },
    savePublicItbItnbRequest: (state, _action: PayloadAction<PublicItbItnbRequestInput>) => {
      state.publicItbItnbLoading = true;
      state.publicItbItnbError = undefined;
    },
    savePublicItbItnbSuccess: (state) => {
      state.publicItbItnbLoading = false;
      state.publicItbItnbError = undefined;
    },
    savePublicItbItnbFailure: (state, action: PayloadAction<Error>) => {
      state.publicItbItnbLoading = false;
      state.publicItbItnbError = action.payload;
    },
    setIsIntentToNoQuote: (state, action: PayloadAction<boolean>) => {
      state.isIntentToNoQuote = action.payload;
    },
  },
  //emptying customQuotationImportedData in default case was emptying quotation data on socket events
  // extraReducers: (builder) => {
  //   builder.addDefaultCase((state) => {
  //     state.customQuotationImportedData = [];
  //   });
  // },
});

export const marketplaceActions = marketplaceSlice.actions;

export default marketplaceSlice.reducer;
