import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { Typography } from "@mui/material";
import { ProgressCircle } from "@next/components/progress-circle";
import { AppTheme } from "layout/AppTheme";
import { NameCell } from "../../report-table-cells/name-cell";
import { TextCell } from "../../report-table-cells/text-cell";
import { HeaderCell } from "../../report-table-cells/header-cell";
import i18next from "i18next";
import { CurrencyCell } from "../../report-table-cells/currency-cell";

export const getRequestsReportTableColumns = (t: i18next.TFunction) => {
  const ScoreCell: React.FC<GridRenderCellParams> = ({ value }) => {
    return (
      <>
        <ProgressCircle
          value={value}
          colors={[{ value: value, color: `${AppTheme.palette.primary.main}` }]}
          style={{ width: "24px", height: "24px" }}
        />
        <Typography variant="body2" style={{ marginLeft: "8px" }}>
          {value + "%"}
        </Typography>
      </>
    );
  };

  const ItemTextCell: React.FC<GridRenderCellParams> = (params) => {
    if (!params?.row?.is_asl) {
      return <Typography variant="body2">{"--"}</Typography>;
    }
    return (
      <Typography variant="body2">
        {`${params.value} ${t("analytics:global:item", {
          count: params.value,
        })}`}
      </Typography>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "supplier_name",
      headerName: "Name",
      renderHeader: () => <HeaderCell text={t("analytics:global:name")} />,
      flex: 0.5,
      sortable: true,
      renderCell: NameCell,
    },
    {
      field: "parts_sent_count",
      headerName: "Items sent in request",
      renderHeader: () => (
        <HeaderCell text={t("analytics:requestsReport:table:columns:itemsSent")} />
      ),
      flex: 0.5,
      sortable: true,
      renderCell: ItemTextCell,
      valueGetter: (params) => {
        if (!params.row.is_asl) {
          return 0;
        }
        return params.value;
      },
    },
    {
      field: "response_rate_percentage",
      headerName: "Response rate",
      renderHeader: () => (
        <HeaderCell
          text={t("analytics:requestsReport:table:columns:responseRate:title")}
          tooltipTitle={t("analytics:requestsReport:table:columns:responseRate:tooltipTitle")}
        />
      ),
      flex: 0.5,
      sortable: true,
      renderCell: (params) => {
        if (!params?.row?.is_asl) {
          return "--";
        }

        return <ScoreCell {...params} />;
      },
    },
    {
      field: "conversion_rate_percentage",
      headerName: "Conversion rate",
      renderHeader: () => (
        <HeaderCell
          text={t("analytics:requestsReport:table:columns:conversationRate:title")}
          tooltipTitle={t("analytics:requestsReport:table:columns:conversationRate:tooltipTitle")}
        />
      ),
      flex: 0.5,
      sortable: true,
      renderCell: ScoreCell,
    },
    {
      field: "total_quoted_value",
      headerName: "Total quoted",
      renderHeader: () => (
        <HeaderCell text={t("analytics:requestsReport:table:columns:totalQuote")} />
      ),
      flex: 0.5,
      sortable: true,
      renderCell: (params) => (
        <Typography variant="body2">
          <CurrencyCell value={params.value} currency="$" />
        </Typography>
      ),
    },
    {
      field: "total_awarded_value",
      headerName: "Total awarded",
      renderHeader: () => (
        <HeaderCell text={t("analytics:requestsReport:table:columns:totalAward")} />
      ),
      flex: 0.5,
      sortable: true,
      renderCell: (params) => (
        <Typography variant="body2">
          <CurrencyCell value={params.value} currency="$" />
        </Typography>
      ),
    },
    {
      field: "average_time_to_quote_days",
      headerName: "Avg. time to quote (working days)",
      renderHeader: () => <HeaderCell text={t("analytics:requestsReport:table:columns:avgTime")} />,
      flex: 0.5,
      sortable: true,
      renderCell: TextCell,
      valueGetter: (params) =>
        `${params.value} ${t("analytics:global:day", { count: params.value })}`,
    },
  ];

  return columns;
};
