import React from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro-v5";
import { getRowIdToPk } from "@next/utils/dataGridUtils-v5";
import { CompanyProfileViews } from "../../redux";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

type Props = {
  gridData: {
    rows: CompanyProfileViews[];
    columns: GridColDef[];
  };
  count: number;
  setCurrentPage: (page: number) => void;
  loading: boolean;
};

export const SellerDashboardUserViewsTable: React.FC<Props> = ({
  gridData,
  count,
  setCurrentPage,
  loading,
}) => {
  return (
    <Box width={"100%"}>
      <DataGridProV5
        autoHeight
        autoPageSize
        pagination
        paginationMode="server"
        rows={gridData?.rows || []}
        columns={gridData?.columns || []}
        disableSelectionOnClick
        disableColumnResize
        headerHeight={0}
        rowHeight={60}
        pageSize={7}
        rowCount={count}
        onPageChange={(newPage) => setCurrentPage(newPage + 1)}
        loading={loading}
        getRowId={getRowIdToPk}
        style={{ border: "none" }}
      />
    </Box>
  );
};
