import * as yup from "yup";
import i18n from "assets/configi18n/i18n";
import { oneLetterOneNumber } from "helpers/formRegex";

const t = i18n.t.bind(i18n);

export type TResetPasswordFormState = {
  new_pass1: string;
};
export type TResetPasswordFormProps = {
  params: {
    uid: string;
    token: string;
  };
};

export const resetPasswordFormState: TResetPasswordFormState = {
  new_pass1: "",
};

export const resetPasswordValidationSchema = yup.object().shape({
  new_pass1: yup
    .string()
    .required(t("auth:error:errorPasswordMissing"))
    .min(8, t("auth:error:errorPasswordLength"))
    .matches(oneLetterOneNumber, {
      message: t("auth:error:errorPasswordCase"),
      excludeEmptyString: true,
    }),
});
