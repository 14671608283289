import React from "react";
import { Config, QCPartData } from "./types";
import { Box } from "@mui/material";
import DataGridProV5 from "@next/components/data-grid-pro-v5/data-grid-pro-v5";

type Props = {
  gridData: {
    config?: Config | any;
    rows: QCPartData[];
    columns: any;
  };
};
const QuoteComparisonTableView: React.FC<Props> = ({ gridData }) => {
  return (
    <Box
      sx={{
        width: "100%",
        "& .MuiDataGrid-root": {
          border: "unset",
          "& .MuiDataGrid-columnHeaderTitle": {
            color: "#0000008A",
            fontWeight: "400",
          },
          "& .MuiDataGrid-columnSeparator": {
            color: "white",
          },
        },
      }}
    >
      <DataGridProV5
        autoHeight
        sx={{
          "& .MuiDataGrid-cell": {
            padding: "0 8px",
          },
        }}
        disableColumnSelector
        isRowSelectable={() => false}
        columns={gridData?.columns}
        rows={gridData?.rows}
        disableColumnResize
        disableColumnMenu
        pagination={false}
        hideFooterRowCount
        showCellRightBorder
        rowHeight={44}
      />
    </Box>
  );
};
export default QuoteComparisonTableView;
