import React from "react";

import { Grid } from "@mui/material";
import { ProjectsTable } from "../components/projects/projects-table";

export const Projects: React.FC = () => {
  return (
    <Grid container xs={12} className="c-projects">
      <Grid item xs={12}>
        <ProjectsTable />
      </Grid>
    </Grid>
  );
};
