import React, { ChangeEvent, useState } from "react";
import { getInitialCapabilitiesState } from "../company-capabilities/company-capabilities.utils";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import InlineFilePickerButton from "@next/components/inline-file-picker-button/inline-file-picker-button";
import { companyCapabilitiesService } from "@next/modules/company-capabilities/services/companyCapabilitiesService";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { companyCapabilitiesActions } from "@next/modules/company-capabilities/redux";
import { EnhancedCertificaionList } from "../company-capabilities/company-capabilities.types";

interface CompanyCertificationsFormProps {
  certificationList: EnhancedCertificaionList[];
  setFieldValue: (field: string, value: any) => void;
  values: {
    certificationsPks: number[];
  };
}

export const CompanyCertificationsForm: React.FC<CompanyCertificationsFormProps> = ({
  certificationList,
  setFieldValue,
  values,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialCertificationsState = getInitialCapabilitiesState(certificationList as any);
  const [checkboxState, setCheckboxState] = useState(initialCertificationsState);

  const handleChangeChecked = (event: ChangeEvent<HTMLInputElement>) => {
    const eventTargetName = event.target.name;
    const eventTargetChecked = event.target.checked;

    const newCertificationState = { ...checkboxState };
    newCertificationState[eventTargetName].has_capability = eventTargetChecked;
    setCheckboxState(newCertificationState);

    dispatch(
      companyCapabilitiesActions.toggleCompanyCapability(newCertificationState[eventTargetName].pk)
    );

    if (!eventTargetChecked) {
      const removedPk = values.certificationsPks.filter(
        (pk: any) => pk !== newCertificationState[eventTargetName]["pk"]
      );
      setFieldValue("certificationsPks", removedPk);
    } else {
      const addedPk = [...values.certificationsPks, newCertificationState[eventTargetName]["pk"]];
      setFieldValue("certificationsPks", addedPk);
    }
  };
  return (
    <FormGroup>
      {certificationList.map((certification: any) => {
        return (
          <FormControlLabel
            key={certification.pk}
            control={
              <Checkbox
                checked={checkboxState?.[certification?.name]?.has_capability}
                onChange={handleChangeChecked}
                name={certification.name}
                className="checkbox-color"
              />
            }
            label={
              <Box>
                {certification.name}
                {checkboxState?.[certification?.name]?.has_capability ? (
                  <InlineFilePickerButton
                    onFileAdded={(file) =>
                      companyCapabilitiesService.saveFile({
                        capabilityId: certification.pk,
                        file,
                      })
                    }
                    onFileRemoved={() =>
                      companyCapabilitiesService.deleteFile({
                        capabilityId: certification.pk,
                      })
                    }
                    accept="application/pdf"
                    file={certification.file}
                    pickerButtonText={t("companyCapabilities:attachPDF")}
                  />
                ) : null}
              </Box>
            }
          />
        );
      })}
    </FormGroup>
  );
};
