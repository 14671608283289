/* eslint-disable react-hooks/exhaustive-deps */
import { useGridApiRef } from "@mui/x-data-grid-pro-v5";
import { useSelector } from "react-redux";
import { selectOrderByIsPortal } from "@next/modules/orders/redux/selectors";
import { PO_DRAFT_PART_GRID_STATE } from "@next/constants/data-grid-state-constants";
import useLocalStorageGridState from "@next/hooks/useLocalStorageGridState-v5";
import { getOrderDetailDraftLineItemsTableColumns } from "./order-detail-draft-line-items-table.columns";

export const ORDER_DETAIL_DRAFT_LINE_ITEMS_TABLE_STATE_KEY =
  "ORDER_DETAIL_DRAFT_LINE_ITEMS_TABLE_STATE";

export const useOrderDetailDraftLineItemsTableData = () => {
  const apiRef = useGridApiRef();
  const orderDetail = useSelector(selectOrderByIsPortal(false));
  const orderDetailLineItems = orderDetail?.line_items || [];
  const { restoreState } = useLocalStorageGridState(PO_DRAFT_PART_GRID_STATE);

  const orderDetailLineItemsTableColumns = restoreState(
    getOrderDetailDraftLineItemsTableColumns(orderDetail?.pk)
  );

  return {
    gridData: {
      rows: orderDetailLineItems,
      columns: orderDetailLineItemsTableColumns,
    },
    apiRef,
    orderDetail,
  };
};
