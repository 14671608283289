import React, { useEffect } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import { batch, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "../types";
import { ProjectPartTable } from "@next/modules/project/components/part/project-part-table";
import { projectActions } from "@next/modules/project/redux";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import {
  selectProjectCompanyPartsCount,
  selectProjectStats,
} from "@next/modules/project/redux/selectors";
import { PartsAndFilesCount } from "../../components/rfq-creation-form/rfq-creation.form.types";
import { selectActiveDraftRFQ, selectRfqFormAdditionalFiles } from "../../redux/selectors";
import { AutoSavedStatus } from "@next/components/auto-saved-status/auto-saved-status";
import { getIsRFQPartModified, getRfqDetails } from "services/workspace/workspace.selectors";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    dialogPaper: {
      width: "100%",
      borderRadius: "24px",
      maxWidth: "100%",
      height: "100%",
    },
    dialogTitle: {
      padding: theme.spacing(3),
      margin: 0,
      display: "flex",
      alignItems: "center",
    },
    heading: {
      display: "flex",
      alignItems: "baseline",
      gap: theme.spacing(1.5),
    },
    dialogContent: {
      padding: theme.spacing(3),
    },
    headerRightContainer: {
      display: "flex",
      alignItems: "center",
      position: "absolute",
      right: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
);

export type RfqDataToPartsModal = {
  projectCode: string;
  isRFQEditMode?: boolean;
  onAttachmentsChange: (files: FileData[], filePkToDelete?: number) => void;
  onPartsCountChange: (partsAndFilesCount: PartsAndFilesCount) => void;
};

type Props = {
  dialogProps?: Partial<DialogProps>;
  dividers?: boolean;

  modalHeader?: string | React.ReactNode;
  modalBanner?: React.ReactNode;
  className?: string;
  bannerClassName?: string;
  dialogContentClassName?: string;
  dialogTitleClassName?: string;
  isRFQEditMode?: boolean;
};

const RfqAddFilesAndPartsModal: React.FC<Props> = ({
  dialogProps,
  dividers = true,
  isRFQEditMode,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const projectCompanyPartsCount = useSelector(selectProjectCompanyPartsCount);
  const projectStats = useSelector(selectProjectStats);
  const rfqAdditionalFiles = useSelector(selectRfqFormAdditionalFiles);
  const activeDraft = useSelector(selectActiveDraftRFQ);
  const activeRfq = useSelector(getRfqDetails);
  const isRFQPartModified = useSelector(getIsRFQPartModified);
  const modal = useSelector(getModal(WorkspaceModalTypes.RFQ_ADD_FILES_AND_PARTS_MODAL));

  const data = modal?.data as RfqDataToPartsModal;

  useEffect(() => {
    if (data?.projectCode)
      dispatch(
        projectActions.fetchProjectStatsRequest({
          projectCode: data.projectCode,
          rfqId: activeRfq?.pk,
        })
      );
  }, [data?.projectCode]);

  const onClose = () => {
    if (isRFQPartModified && isRFQEditMode) {
      dispatch(
        modalsActions.showModal({
          key: WorkspaceModalTypes.CANCEL_EDIT_RFQ_MODAL,
        })
      );
      return;
    }
    batch(() => {
      dispatch(modalsActions.closeModal(WorkspaceModalTypes.RFQ_ADD_FILES_AND_PARTS_MODAL));
    });
  };

  const rfqData: RfqDataToPartsModal = {
    projectCode: data?.projectCode,
    onAttachmentsChange: data?.onAttachmentsChange,
    onPartsCountChange: data?.onPartsCountChange,
  };

  return (
    <Dialog
      maxWidth="md"
      onClose={onClose}
      open={!!modal}
      classes={{ paper: classes.dialogPaper }}
      {...dialogProps}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box className={classes.heading}>
          {isRFQEditMode ? (
            <Typography fontSize="20px" fontWeight="500">
              {t("project:navbar:editPart")}
            </Typography>
          ) : (
            <>
              <Typography variant="h6">
                {t("workspaceNext:rfqDrawer:partsAndFilesModal:title")}
              </Typography>
              <Typography variant="body2" color={"textSecondary"}>
                {t("project:navbar:partWithCount", {
                  count: projectCompanyPartsCount || 0,
                }) +
                  ", " +
                  t("project:table:row:filesWithCount", {
                    count:
                      (projectStats?.attached_files_count || 0) + (rfqAdditionalFiles?.length || 0),
                  })}
              </Typography>
            </>
          )}
        </Box>

        <Box className={classes.headerRightContainer}>
          {activeDraft?.updated_at ? (
            <AutoSavedStatus updated_at={activeDraft?.updated_at} />
          ) : null}

          {onClose ? (
            <IconButton onClick={onClose}>
              <CloseIcon color="secondary" />
            </IconButton>
          ) : null}
        </Box>
      </DialogTitle>

      <DialogContent dividers={dividers} className={classes.dialogContent}>
        <ProjectPartTable rfqData={rfqData} hidePriceColumn={true} isRFQEditMode={isRFQEditMode} />
      </DialogContent>
    </Dialog>
  );
};

export default RfqAddFilesAndPartsModal;
