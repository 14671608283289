import React from "react";
import { Grid, Select, Theme } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { createStyles, makeStyles } from "@mui/styles";

type Props = {
  colorOptions: Object;
  selectedColor: string;
  setSelectedColor: (selectedColor: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textFieldOptionColor: {
      width: "24px",
      height: "24px",
      borderRadius: "100%",
    },
    optionColorContainer: {
      padding: "24px",
    },
    optionColorStyle: {
      width: "24px",
      height: "24px",
      borderRadius: "100%",
      cursor: "pointer",
    },
  })
);

export const ColorPickerDropDown: React.FC<Props> = ({
  selectedColor,
  setSelectedColor,
  colorOptions,
}) => {
  const classes = useStyles();
  const handleColorSelect = (color: string) => {
    setSelectedColor(color);
  };

  return (
    <Select
      color="primary"
      size="small"
      sx={{
        width: "80px",
        height: "40px",
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
      disableUnderline
      IconComponent={ExpandMore}
      value={selectedColor}
      renderValue={() => (
        <div
          className={classes.textFieldOptionColor}
          style={{ backgroundColor: selectedColor }}
        ></div>
      )}
    >
      <Grid container spacing={1} className={classes.optionColorContainer}>
        {Object.entries(colorOptions).map(([colorName, colorValue]) => (
          <Grid item key={colorName}>
            <div
              onClick={() => handleColorSelect(colorValue)}
              className={classes.optionColorStyle}
              style={{
                backgroundColor: colorValue,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Select>
  );
};
