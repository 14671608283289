import React from "react";
import { Dialog } from "@mui/material";
import { useSelector } from "react-redux";
import { ProjectModalTypes } from "./types";
import { getModal } from "@next/redux/modalsSlices";
import { BulkPartImportLoader } from "../components/bulk-part-import-loader/bulk-part-import-loader";

export const ProjectBulkPartImportLoaderModal = () => {
  const modal = useSelector(getModal(ProjectModalTypes.BULK_PART_IMPORT_LOADER_MODAL));

  if (!modal) return null;

  return (
    <Dialog open={!!modal} closeAfterTransition maxWidth="md">
      <BulkPartImportLoader />
    </Dialog>
  );
};
