import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Field, Form, FormikProps } from "formik";
import { ContactUsFormProps, ContactUsFormValues } from "./contact-us.types";
import { Box, Divider, Grid, Link, Theme, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

import { GenericTextInput } from "components/utils/input/text";
import { selectContactUsLoading } from "../../redux/selectors";
import { createStyles, makeStyles } from "@mui/styles";
import CustomButton from "@next/components/custom-button/custom-button";
import { isSendSupportMessageInProgress } from "@next/modules/vendor-management/redux/selectors";
import { FormikAttachFile } from "@next/components/formik-attach-file";
import { FileData } from "@next/components/attach-file/attach-file-list.types";

type StyledProps = {
  isAirbusSupportModal?: boolean;
};

const useStyles = makeStyles<Theme, StyledProps>((theme: Theme) =>
  createStyles({
    link: {
      "&:not(.hot-link)": {
        color: "#1473E6",
      },
      fontWeight: "bold",
    },
    formTitle: {
      padding: 11,
      backgroundColor: ({ isAirbusSupportModal }) => (isAirbusSupportModal ? "#fff" : "#f1f1f1"),
      color: ({ isAirbusSupportModal }) => (isAirbusSupportModal ? "#0000008A" : "unset"),
      borderRadius: 5,
      fontSize: 16,
      fontWeight: 400,
      marginBottom: 24,
    },
    buttonContainer: {
      position: "relative",
    },
    circularProgress: {
      position: "absolute",
      top: "30%",
      right: "8%",
    },
  })
);
const ContactUsFormBase: React.FC<FormikProps<ContactUsFormValues> & ContactUsFormProps> = ({
  values,
  setFieldValue,
  handleSubmit,
  formTitle,
  hasFormTitle,
  hasSubjectAndMsg,
  label,
  footerText,
  footerLinkText,
  iconName,
  isAirbusSupportModal,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({
    isAirbusSupportModal,
  });
  const contactUsLoading = useSelector(selectContactUsLoading);
  const airbusSupportMessageLoading = useSelector(isSendSupportMessageInProgress);

  const onAttachementChange = (files: (File | FileData)[], value, setFieldValue) => {
    let temp_file: File[] = [];
    const files_name: string[] = [];

    // Convert file data to files
    files.forEach((item: File | FileData) =>
      item instanceof File
        ? temp_file.push(item)
        : files_name.push(`${item.file_name}.${item.file_extension}`)
    );
    temp_file = temp_file.concat(
      value.attachments.filter((item: File) => files_name.indexOf(item.name) !== -1)
    );

    setFieldValue("attachments", temp_file);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {hasFormTitle && <Box className={classes.formTitle}>{formTitle}</Box>}
          {hasSubjectAndMsg && (
            <GenericTextInput
              disabled={isAirbusSupportModal}
              name="subject"
              label={isAirbusSupportModal ? null : t("contactUs:subject")}
            />
          )}
        </Grid>
        {isAirbusSupportModal && (
          <Grid item xs={12}>
            <GenericTextInput name="email" label={"Email address"} />
          </Grid>
        )}

        <Grid item xs={12}>
          <GenericTextInput rows={"5"} name="msg" label={label} />
        </Grid>
        {isAirbusSupportModal && (
          <Grid item xs={12}>
            <Field
              name="attachments"
              disableAcceptSpecificTypes
              component={FormikAttachFile}
              onChangeFiles={(files: (File | FileData)[]) =>
                onAttachementChange(files, values, setFieldValue)
              }
            />
          </Grid>
        )}
        <Grid item container justifyContent="flex-end" className={classes.buttonContainer}>
          <CustomButton
            color="primary"
            variant="contained"
            type="submit"
            disabled={contactUsLoading || airbusSupportMessageLoading}
            loading={contactUsLoading || airbusSupportMessageLoading}
          >
            {t("common:send")}
          </CustomButton>
        </Grid>
        {hasSubjectAndMsg && (
          <Grid item xs={12}>
            <Divider />
            <Box display="flex" alignItems="center" mt={3}>
              <Box mr={1}>{iconName == "EmailIcon" ? <EmailIcon /> : <PhoneIcon />}</Box>
              <Typography variant="subtitle1">
                {footerText ? footerText : t("contactUs:footerText")}
                <Link
                  underline={isAirbusSupportModal ? "none" : "always"}
                  href={isAirbusSupportModal ? `mailto:${footerLinkText}` : "tel:+1 581-333-3743"}
                  className={classes.link}
                  variant="subtitle1"
                >
                  {footerLinkText ? ` ${footerLinkText}` : `  +1 581-333-3743`}
                </Link>
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Form>
  );
};

export default ContactUsFormBase;
