// General Imports
import { connect } from "react-redux";
import { LogoutView } from "./logout.component";
// Actions
import { profileActions } from "../../services/profile/profile.actions";

const mapDispatchToProps = {
  logout: profileActions.logout,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.profile.isLoggedIn,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutView);
