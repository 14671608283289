import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { frontendUrl } from "urls";
import { useDispatch } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "@next/modules/project/modals/types";
import { Rfq } from "@next/modules/project/redux";

interface Props {
  rfq: Rfq;
  fromPM?: boolean;
}

const RFQSuccessSnackbar: React.FC<Props> = (props) => {
  const { rfq, fromPM } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(
      modalsActions.showModal({
        key: ProjectModalTypes.RFQ_DETAILS_MODAL,
        data: rfq,
      })
    );
  };

  return (
    <Button
      variant="outlined"
      color="inherit"
      href={!fromPM ? `${frontendUrl.bundleView}/${rfq.pk}` : undefined}
      onClick={fromPM ? onClick : undefined}
    >
      {t("workspaceNext:rfqDrawer:openRFQ")}
    </Button>
  );
};

export default RFQSuccessSnackbar;
