import { Box, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectProjectStats,
  selectProjectStatsLoading,
} from "@next/modules/project/redux/selectors";
import { ProjectType } from "@next/modules/project/redux";
import i18n from "assets/configi18n/i18n";

const ProjectStatsBase: React.FC = () => {
  const { t } = useTranslation();

  const projectStats = useSelector(selectProjectStats);
  const projectStatsLoading = useSelector(selectProjectStatsLoading);

  const [progress, setProgress] = useState<
    Pick<ProjectType, "parts_in_rfq_count" | "parts_contracted_count">
  >({
    parts_in_rfq_count: 0,
    parts_contracted_count: 0,
  });

  useEffect(() => {
    if (!projectStatsLoading && projectStats) {
      setProgress({
        parts_in_rfq_count:
          (progress.parts_in_rfq_count < 100 &&
            (projectStats.parts_in_rfq_count / projectStats.total_parts_count) * 100) ||
          0,
        parts_contracted_count:
          (progress.parts_contracted_count < 100 &&
            (projectStats.parts_contracted_count / projectStats.total_parts_count) * 100) ||
          0,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectStatsLoading, projectStats, i18n.language]);

  if (projectStatsLoading) {
    return <Skeleton variant="text" width={300} height={40} />;
  }

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body2">
        {`${t("project:navbar:partWithCount", {
          count: projectStats?.total_parts_count || 0,
        })} `}
      </Typography>

      <Box m="0px 8px">{`|`}</Box>

      <Typography variant="body2">
        {`${t("project:navbar:filesWithCount", {
          count: projectStats?.attached_files_count || 0,
        })} `}
      </Typography>

      <Box m="0px 8px">{`|`}</Box>

      <Typography variant="body2">
        {`${projectStats?.parts_in_rfq_count || 0}/${
          projectStats?.total_parts_count || 0
        } ${t("project:navbar:partInRfq")} `}
      </Typography>

      <Box m="0px 8px">{`|`}</Box>
    </Box>
  );
};

export const ProjectStats = React.memo(ProjectStatsBase);
