import { Box, Divider, Typography } from "@mui/material";
import React, { useContext } from "react";
import { QCSupplierList } from "./quote-comparison-award-drawer-content-supplier-list";
import { quoteComparisonContext } from "../../components/quote-comparison/quote-comparison.context";
import { useTranslation } from "react-i18next";

export const QuoteComparisonAwardDrawerContent = () => {
  const { t } = useTranslation();
  const {
    gridData,
    selectedData: { selectedSuppliersParts },
  } = useContext(quoteComparisonContext);
  const partsCounts = gridData?.rows?.length;
  const remainingParts = partsCounts - selectedSuppliersParts?.length || 0;

  return (
    <Box p="24px" height={"100%"}>
      <Typography>{t("workspaceNext:quoteComparison:awardSelectDrawerDescription")}</Typography>

      <Divider style={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "24px" }} />

      <QCSupplierList />

      {remainingParts > 0 ? (
        <>
          <Box height="72px" display="flex" alignItems="center">
            <Typography variant="body1">
              {t("workspaceNext:quoteComparison:remainingPart", {
                count: remainingParts,
              })}
            </Typography>
          </Box>

          <Divider style={{ marginLeft: "-24px", marginRight: "-24px" }} />
        </>
      ) : null}
      {/* 
      <Box
        height="56px"
        display="flex"
        justifyContent="space-between"
        pt="18px"
      >
        <Box>Total</Box> <QuoteComparisonCurrencyField value={totalPricesSum} />
      </Box>

      <Divider style={{ marginLeft: "-24px", marginRight: "-24px" }} /> */}
    </Box>
  );
};
