import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";

import { Box, Button, Grid, TextField } from "@mui/material";
import { Send as SendIcon } from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ClearIcon from "@mui/icons-material/Clear";
import Skeleton from "@mui/material/Skeleton";

import { inboxActions } from "../../../../services/inbox/inbox.actions";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

export default ({ user, sendMessage, t }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("");
  const dispatch = useDispatch();
  const tracking = useTracking();

  const profile = useSelector((state) => state.profile);

  const fileUploads = useSelector((state) => state.inbox.fileAttachments);
  const isFileAttachmentsLoading = useSelector((state) => state.inbox.isFileAttachmentsLoading);

  const setResponse = React.useCallback(() => {
    if (value || fileUploads.length > 0) {
      const filePks = [];
      fileUploads.forEach((file) => {
        filePks.push(file.pk);
      });
      sendMessage({
        content: value,
        files: filePks,
      });
      setValue("");
    }
  }, [value, fileUploads]);

  const handleFileUpload = (e) => {
    dispatch(inboxActions.handleInboxFileUpload(e.target.files[0], t));
    /**
     * Feature tracking
     */
    tracking.trackEvent({
      scout_category: "manufacturer_messenger",
      scout_feature_name: "messenger_file_upload",
      scout_company: profile.company.name,
      scout_username: profile.user.username,
      scout_user_type: profile.user.type,
      scout_date: new Date(),
    });
  };

  const handleFileDelete = (e, pk) => {
    dispatch(inboxActions.deleteFileUpload(pk));
    /**
     * Feature tracking
     */
    tracking.trackEvent({
      scout_category: "manufacturer_messenger",
      scout_feature_name: "messenger_remove_file_uploaded",
      scout_company: profile.company.name,
      scout_username: profile.user.username,
      scout_user_type: profile.user.type,
      scout_date: new Date(),
    });
  };

  const MyLoader = () => (
    <Grid container item display="flex">
      <Skeleton variant="text" className="c-file-loader-section" />
      <Skeleton variant="circular" width={20} height={20} />
    </Grid>
  );

  return (
    <Box className="c-text-field-input">
      {isFileAttachmentsLoading ? (
        <MyLoader />
      ) : (
        <div>
          {fileUploads.map((file) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className="c-text-input--uploaded-file-section"
              >
                {file.name}
                {file.extension}
                <ClearIcon onClick={(e) => handleFileDelete(e, file.pk)} />
              </Box>
            );
          })}
        </div>
      )}
      <Box className="c-text-field-input--message">
        <TextField
          placeholder={"Aa"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          autoFocus
          multiline
          fullWidth
          disableUnderline={true}
          InputProps={{ classes }}
          maxRows={4}
        />
      </Box>
      <Box
        fontSize={14}
        className="c-text-field-input--btn"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button variant="contained" component="label" className="c-text-input--upload">
          <AttachFileIcon />
          <input type="file" hidden onChange={(e) => handleFileUpload(e)} />
        </Button>
        <Button
          type="button"
          fullWidth
          onClick={setResponse}
          disabled={fileUploads.length === 0 && value.length === 0}
          variant="contained"
          size="large"
          color="primary"
          startIcon={<SendIcon className="c-text-field-input--send-icon" />}
        >
          {t("send")}
        </Button>
      </Box>
    </Box>
  );
};
