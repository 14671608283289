import React from "react";

import { GridColDef, GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import { t } from "assets/configi18n/i18n";
import { OrdersTableField } from "./orders-table.types";
import { OrderStateTag } from "../order-state-tag";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { getInitials } from "@next/utils/stringUtils";
import { CustomTypography } from "@next/components/custom-typography";
import { frontendUrl } from "urls";
import { DUMMY_ORDER_STATUS } from "@next/constants";
import { ProgressCircle } from "@next/components/progress-circle";
import { AppTheme } from "layout/AppTheme";
import CollaboratorsList from "components/workspace/buyer-rfqs-view/collaborators-list";
import LinkCell from "@next/components/table-cells/link-cell-v5";
import DateCell from "@next/components/table-cells/date-cell-v5";
import IconButtonCell from "@next/components/table-cells/icon-button-cell-v5";
import TextCell from "@next/components/table-cells/text-cell-v5";
import { InternalNoteCell } from "@next/components/table-cells/internal-note-cell";
import snackbarUtils from "@next/utils/snackbarUtils";

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: false,
  sortable: true,
  hideSortIcons: false,
};

const DisplayNameCell: React.FC<
  GridRenderCellParams & {
    lastSelectedRow: string | number | undefined;
  }
> = ({ value, id, lastSelectedRow }) => {
  const showLastOpened = lastSelectedRow === id;

  return (
    <Box display="flex" alignItems="center" width="100%">
      <CustomTypography
        variant="caption"
        tooltipTitle={(value || "") as string}
        maxWidth={showLastOpened ? "calc(100% - 76px)" : "100%"}
      >
        {value}
      </CustomTypography>

      {showLastOpened ? (
        <Box ml="10px">
          <Typography variant="caption" color="textSecondary">
            {t("project:table:row:lastOpened")}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

const SupplierCell: React.FC<GridRenderCellParams<any>> = (params) => {
  return (
    <Box display="flex" alignItems="center">
      {params?.row?.supplier?.name ? (
        <Avatar src={params?.row?.supplier?.picture} style={{ width: "32px", height: "32px" }}>
          {getInitials(params?.row?.supplier?.name)}
        </Avatar>
      ) : null}

      <Box ml="8px">
        <CustomTypography variant="caption">{params?.row?.supplier?.name}</CustomTypography>
      </Box>
    </Box>
  );
};

const ShipmentCell: React.FC<GridRenderCellParams> = (params) => {
  return (
    <Tooltip
      title={
        t("orders:partShipped", {
          countStatus: `${params.row.shipped_line_items_count}/${params.row.line_items_count}`,
          count: params.row.shipped_line_items_count,
        }) || ""
      }
    >
      <Box display="flex" alignItems="center" lineHeight={0}>
        <ProgressCircle
          value={Math.round(
            (params.row.shipped_line_items_count / params.row.line_items_count) * 100
          )}
          colors={[
            {
              value: Math.round(
                (params.row.shipped_line_items_count / params.row.line_items_count) * 100
              ),
              color: AppTheme.palette.success.main,
            },
          ]}
        />

        <Box ml={1} mt="2px">
          <Typography variant="caption">{`${params.row.shipped_line_items_count}/${params.row.line_items_count}`}</Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};

const ShippingNoteCell: React.FC<GridRenderCellParams> = (params) => {
  if (!params?.row?.shipping_note) return null;
  return (
    <Tooltip
      title={
        <Box display="flex" alignItems="center">
          <FileCopyIcon style={{ fontSize: "12px", margin: "2px" }} />
          <Typography variant="inherit">{t("orders:table:tooltip:copyClipboard")}</Typography>
        </Box>
      }
    >
      <Typography
        variant="caption"
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigator.clipboard.writeText(params?.row?.shipping_note);
          snackbarUtils.toast(t("orders:shippingNoteCopied"));
        }}
      >
        {params?.row?.shipping_note}
      </Typography>
    </Tooltip>
  );
};

export const getOrdersTableColumns = (
  lastSelectedRow?: string | number | undefined,
  showEditButton?: boolean,
  moduleType?: string
) => {
  const _columns: GridColumns = [
    {
      ...defaultColumnConfig,
      field: OrdersTableField.NAME,
      headerName: t("orders:table:header:name"),
      type: "string",
      renderCell: (params) => <DisplayNameCell {...params} lastSelectedRow={lastSelectedRow} />,
    },
    {
      ...defaultColumnConfig,
      field: OrdersTableField.REQUEST,
      width: 160,
      type: "string",
      headerName: t("orders:table:header:request"),
      renderCell: (params) =>
        params.row?.rfq?.status === DUMMY_ORDER_STATUS ? null : (
          <LinkCell
            {...params}
            to={`${frontendUrl.bundleView}/${params.row?.rfq?.pk}`}
            label={params.row?.rfq?.display_name}
          />
        ),
    },
    {
      ...defaultColumnConfig,
      field: OrdersTableField.USERS,
      width: 110,
      type: "string",
      headerName: t("orders:table:header:users"),
      renderCell: (params) => (
        <CollaboratorsList
          data={{
            collaborators: params.row.collaborators,
            createdBy: params.row.created_by,
            rfqPk: params.row.rfq.pk,
            poId: params.row.pk,
          }}
          size="small"
        />
      ),
    },
    {
      ...defaultColumnConfig,
      field: OrdersTableField.ITEMS,
      type: "number",
      width: 60,
      headerName: t("orders:table:header:items"),
      renderCell: TextCell,
      headerAlign: "left",
      align: "left",
    },
    {
      ...defaultColumnConfig,
      field: OrdersTableField.SUPPLIER,
      type: "string",
      width: 140,
      headerName: t("orders:table:header:supplier"),
      renderCell: SupplierCell,
    },
    {
      ...defaultColumnConfig,
      field: OrdersTableField.SENT_ON,
      type: "date",
      width: 120,
      headerName: t("orders:table:header:sentOn"),
      renderCell: DateCell,
    },
    {
      ...defaultColumnConfig,
      field: OrdersTableField.REQUIRED_RECEPTION,
      type: "date",
      width: 140,
      headerName: t("orders:table:header:requiredReception"),
      renderCell: DateCell,
    },
    {
      ...defaultColumnConfig,
      disableColumnMenu: true,
      sortable: false,
      hideSortIcons: true,
      field: OrdersTableField.STATE,
      width: 400,
      headerName: t("orders:table:header:status"),
      renderCell: (params) => <OrderStateTag state={params?.row?.state} />,
    },
    {
      ...defaultColumnConfig,
      disableColumnMenu: true,
      sortable: false,
      hideSortIcons: true,
      field: OrdersTableField.SHIPMENT,
      width: 120,
      headerName: t("orders:table:header:shipment"),
      renderCell: ShipmentCell,
    },
    {
      ...defaultColumnConfig,
      disableColumnMenu: true,
      sortable: false,
      hideSortIcons: true,
      field: OrdersTableField.NOTE,
      minWidth: 270,
      headerName: t("project:table:row:internalNotes:header"),
      renderCell: (params: any) =>
        InternalNoteCell({
          notes: params?.row?.notes,
          internal_note_description: params?.row?.internal_note_description,
          paramID: params?.id,
          moduleType,
        }),
    },
    {
      ...defaultColumnConfig,
      disableColumnMenu: true,
      sortable: false,
      hideSortIcons: true,
      field: OrdersTableField.SHIPPING_NOTE,
      width: 140,
      headerName: t("orders:table:header:shippingNote"),
      renderCell: ShippingNoteCell,
    },
  ];
  showEditButton &&
    _columns.push({
      ...defaultColumnConfig,
      disableColumnMenu: true,
      sortable: false,
      hideSortIcons: true,
      field: OrdersTableField.EDIT_BUTTON,
      headerName: " ",
      width: 80,
      align: "right",
      renderCell: IconButtonCell,
      disableReorder: true,
    });

  return _columns;
};
