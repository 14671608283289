import React from "react";
import { Box, DialogTitle, Divider, Drawer, IconButton } from "@mui/material";
import { batch, useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { ProfileModalTypes } from "./types";
import { BuyerProfileSendMessage } from "@next/modules/profile/components/buyer-profile-send-message/buyer-profile-send-message";
import { useTranslation } from "react-i18next";
import { profileNextActions } from "@next/modules/profile/redux";
import CloseIcon from "@mui/icons-material/Close";

export const BuyerProfileSendMessageDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(ProfileModalTypes.BUYER_PROFILE_SEND_MESSAGE_DRAWER));
  const data = modal?.data as {
    buyerProfileId: number;
    buyerName: string;
    buyerPicture: string;
  };

  if (!data || !modal) return null;

  const onClose = () => {
    batch(() => {
      dispatch(modalsActions.closeModal(ProfileModalTypes.BUYER_PROFILE_SEND_MESSAGE_DRAWER));
    });
  };

  const onSubmit = (values: any) => {
    dispatch(
      profileNextActions.sendBuyerProfileMessageRequest({
        buyerProfileId: data.buyerProfileId,
        files: values.files?.map((item: any) => item?.file),
        message: values.message as any,
      })
    );
  };

  return (
    <Drawer
      PaperProps={{ style: { width: "100%", height: "100%" } }}
      anchor="bottom"
      open={!!modal}
      onClose={onClose}
    >
      <DialogTitle style={{ height: "72px", paddingTop: "12px" }}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            {t("marketPlace:profile:sendInvitationTo", {
              buyerName: data?.buyerName,
            })}{" "}
          </Box>
          <Box pl="5px">
            <IconButton onClick={onClose} size="large">
              <CloseIcon style={{ color: "black" }} />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />

      {!!modal ? (
        <BuyerProfileSendMessage
          buyerData={data}
          submitHandler={onSubmit}
          suggestionTitle={t("profile:buyerProfile:suggestion")}
          suggenstionText={t("profile:buyerProfile:suggestionMessage")}
          messageInputLabel={t("profile:buyerProfile:sendMessageInputLabel")}
        />
      ) : null}
    </Drawer>
  );
};
