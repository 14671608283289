import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "@next/components/custom-modal";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "./types";
import { Order } from "../redux";
import RevisePoForm from "../components/revise-po-form/revise-po-form";

export const RevisePoModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(OrdersModalTypes.REVISE_PO_MODAL));
  const data = modal?.data as {
    order?: Order;
  };

  if (!modal || !data?.order?.pk) return null;

  const onClose = () => {
    dispatch(modalsActions.closeModal(OrdersModalTypes.REVISE_PO_MODAL));
  };

  return (
    <Modal
      handleModalClose={onClose}
      modalState={{
        modalHeader: t("orders:revisePoModal:title"),
        modalBody: <RevisePoForm order={data.order} />,
      }}
      isModalOpen={true}
    />
  );
};
