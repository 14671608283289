import { axiosApi } from "@next/api";
import { backendUrl } from "urls";
import {
  AddCompanyProfileViewInput,
  FetchBuyerProfileInput,
  FetchBuyerProfilePartInput,
  FetchBuyerProfilesInput,
  FetchCompanyProfileViewsInput,
} from "../redux";

const fetchCompanyProfileAnalytics = () => {
  return axiosApi.get(`${backendUrl.companyProfileAnalytics}`);
};

const fetchCompanyProfileViews = ({ query }: FetchCompanyProfileViewsInput) => {
  return axiosApi.get(`${backendUrl.companyProfileViews}?${query}`);
};

const addCompanyProfileView = ({ pk }: AddCompanyProfileViewInput) => {
  return axiosApi.post(`${backendUrl.companyProfileViews}/`, {
    company: pk,
  });
};

const fetchBuyerProfiles = ({ query }: FetchBuyerProfilesInput) => {
  return axiosApi.get(`${backendUrl.buyerDiscovery}?${query}`);
};

const fetchBuyerProfile = ({ id }: FetchBuyerProfileInput) => {
  return axiosApi.get(`${backendUrl.buyerDiscovery}/${id}`);
};

const fetchBuyerProfilePart = ({ partId }: FetchBuyerProfilePartInput) => {
  return axiosApi.get(`${backendUrl.buyerDiscovery}/parts/${partId}`);
};

const fetchSupplierOnboardingStatus = () => {
  return axiosApi.get(`${backendUrl.supplierOnboardingStatus}`);
};

export const dashboardService = {
  fetchCompanyProfileAnalytics,
  fetchCompanyProfileViews,
  addCompanyProfileView,
  fetchBuyerProfiles,
  fetchBuyerProfile,
  fetchBuyerProfilePart,
  fetchSupplierOnboardingStatus,
};
