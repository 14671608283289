import { Box, IconButton, InputAdornment, TextField, Theme, alpha } from "@mui/material";
import { SharedModalTypes } from "@next/modals/types";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { modalsActions } from "@next/redux/modalsSlices";
import React, { useCallback } from "react";
import { batch, useDispatch } from "react-redux";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";
import { Typography } from "@material-ui/core";
import { useKeyboardShortcut } from "@next/hooks/useKeyboardShortcut";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    search: {
      width: 370,
      padding: 2,
      marginRight: 8,
      overflow: "hidden",
      borderRadius: 8,
      backgroundColor: alpha(theme.palette.appbar.light, 0.9),
      "& input": {
        color: theme.palette.common.white,
      },
      "& fieldset": {
        border: "none",
      },
      "& ::placeholder": {
        color: theme.palette.common.white,
        opacity: 1,
      },
      "& ::-ms-input-placeholder": {
        color: theme.palette.common.white,
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "black",
        },
      },
    },
    tapToOpen: {
      color: alpha(theme.palette.common.white, 0.5),
    },
    icon: {
      color: theme.palette.common.white,
    },
  })
);

type Props = {
  iconOnly?: boolean;
  setSidePanelDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SearchActionItem: React.FC<Props> = ({ iconOnly, setSidePanelDrawer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSearch = useCallback(() => {
    batch(() => {
      if (setSidePanelDrawer) setSidePanelDrawer(false);
      dispatch(workspaceNextActions.clearGlobalSearch());
      dispatch(modalsActions.showModal({ key: SharedModalTypes.GLOBAL_SEARCH }));
    });
  }, []);

  useKeyboardShortcut({ key: "/", ctrl: true, onKeyPressed: () => onSearch() });

  return (
    <Box onClick={onSearch} data-tut="reactour__search" data-pid="btnGlobalSearch">
      {iconOnly ? (
        <IconButton size="large">
          <SearchOutlinedIcon style={{ color: "white" }} />
        </IconButton>
      ) : (
        <TextField
          className={classes.search}
          variant="outlined"
          size="small"
          placeholder={t("globalSearch:inputText")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon className={classes.icon} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="body1" className={classes.tapToOpen}>
                  {t("globalSearch:tapToOpen")}
                </Typography>
              </InputAdornment>
            ),
            readOnly: true,
          }}
        />
      )}
    </Box>
  );
};
