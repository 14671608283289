/*
 *   Online documentation: https://grad4.atlassian.net/wiki/spaces/FDS/pages/311459841/Login
 *   Online prototype: https://xd.adobe.com/view/0947e4ee-3356-4422-641b-f12cde3a4b03-7f76/
 *   In this view we can see the split panel for the resend verification email
 *   and reset the password when we forget.
 */

// General Imports
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

// Links
import { frontendUrl } from "../../urls";

// Image Assets
import logo from "../../assets/img/axya-logo.svg";

// Form Validation Import
import { Formik } from "formik";

// MaterialUI specific Import
import { Grid } from "@mui/material";

import { AuthActionsMenu } from "../auth-actions-menu/auth-actions-menu.component";
import { ForgotPasswordFormBase } from "./forgot-password-form-base";
import { ResendEmailFormBase } from "./resend-email-form-base";
import {
  TRecoverPasswordFormState,
  recoverPasswordFormState,
  recoverPasswordValidationSchema,
} from "./recover-password-definitions";
import { profileActions } from "../../services/profile/profile.actions";
/*
 *   RecoveryView:
 *   Composing view for the Issues page. Here we are making a big card
 *   with two panels for forgetting a password and for resend a verification email
 *   This view finish with an action menu like in the login view
 */

export const RecoverPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmitRecoverPassword = (values: TRecoverPasswordFormState) => {
    dispatch(profileActions.recover(values.email));
  };
  const handleSubmitResendEmail = (values: TRecoverPasswordFormState) => {
    dispatch(profileActions.resendVerification(values.email));
  };

  return (
    <div className={"auth"}>
      <Grid
        container
        className={"content content--large"}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <img src={logo} alt={"Axya Logo"} className={"logo"} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Formik
              render={(props) => <ForgotPasswordFormBase {...props} />}
              initialValues={recoverPasswordFormState}
              onSubmit={handleSubmitRecoverPassword}
              validationSchema={recoverPasswordValidationSchema}
            />
          </Grid>

          <Grid item xs={6}>
            <Formik
              render={(props) => <ResendEmailFormBase {...props} />}
              initialValues={recoverPasswordFormState}
              onSubmit={handleSubmitResendEmail}
              validationSchema={recoverPasswordValidationSchema}
            />
          </Grid>
        </Grid>

        <AuthActionsMenu
          minorLinkUrl={frontendUrl.login}
          minorLinkText={t("auth:actionsMenu:comeBackToLogin")}
          majorText={t("auth:actionsMenu:stillNoAccount")}
          majorLinkUrl={frontendUrl.register}
          majorLinkText={t("auth:actionsMenu:join")}
        />
      </Grid>
    </div>
  );
};

export default RecoverPasswordForm;
