export const quotationConstants = {
  FETCH_QUOTATION_DETAILS_REQUEST: "FETCH_QUOTATION_DETAILS_REQUEST",
  FETCH_QUOTATION_DETAILS_SUCCESS: "FETCH_QUOTATION_DETAILS_SUCCESS",
  FETCH_QUOTATION_DETAILS_FAILURE: "FETCH_QUOTATION_DETAILS_FAILURE",

  SET_IS_EDIT_QUOTATION_MODE: "SET_IS_EDIT_QUOTATION_MODE",

  EDIT_QUOTATION_REQUEST: "EDIT_QUOTATION_REQUEST",
  EDIT_QUOTATION_SUCCESS: "EDIT_QUOTATION_SUCCESS",
  EDIT_QUOTATION_FAILURE: "EDIT_QUOTATION_FAILURE",

  SET_LEAD_TIME_OPTIONS_SUCCESS: "SET_LEAD_TIME_OPTIONS_SUCCESS",
};
