import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { Chip, styled } from "@mui/material";
import {
  ValidationManagementStatus,
  ValidationManagementStatusType,
} from "@next/modules/vendor-management/redux";

const statusStyles: Record<string, { backgroundColor: string; color: string }> = {
  warning: {
    backgroundColor: "rgba(255, 169, 44, 0.1)",
    color: "rgba(179, 118, 31, 1)",
  },
  primary: {
    backgroundColor: "rgba(38, 130, 253, 0.1)",
    color: "rgba(38, 130, 253, 1)",
  },
  success: {
    backgroundColor: "rgba(76, 175, 80, 0.1)",
    color: "rgba(56, 142, 60, 1)",
  },
  default: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    color: "rgba(0, 0, 0, 0.87)",
  },
};

type ChipStyleProps = {
  status: string;
};

const StyledChip = styled(Chip)<ChipStyleProps>(({ status }) => ({
  backgroundColor: statusStyles[status]?.backgroundColor || "rgba(0, 0, 0, 0.1)",
  color: statusStyles[status]?.color || "rgba(0, 0, 0, 0.87)",
}));

export const StatusCell: React.FC<GridRenderCellParams<any>> = (params) => {
  return (
    <StyledChip
      label={ValidationManagementStatus[params.value]}
      status={ValidationManagementStatusType[params.value]}
    />
  );
};
