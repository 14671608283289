import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { MarketplaceModalTypes } from "./types";
import IntentToBidModal from "components/intent-to-bid-modal/intent-to-bid-modal.component";

export const MarketplaceIntentToBidModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(MarketplaceModalTypes.MARKETPLACE_INTENT_TO_BID_MODAL));
  const modalParams = modal?.data as {
    isRecommended: boolean;
    tabIndex: number;
    rfqDetails: any;
  };

  if (!modal || !modalParams) return null;

  return (
    <IntentToBidModal
      isModalOpen={true}
      handleClose={() =>
        dispatch(modalsActions.closeModal(MarketplaceModalTypes.MARKETPLACE_INTENT_TO_BID_MODAL))
      }
      {...modalParams}
    />
  );
};
