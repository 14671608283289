import { put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { safeCall } from "@next/redux/safeCall";
import { companyCapabilitiesService } from "../services/companyCapabilitiesService";
import { companyCapabilitiesActions } from "./slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { FetchCompanyCapabilitiesTypesInput, PatchCompanyCapabilitiesInput } from "./types";
import { getCompany } from "services/profile/profile.selectors";
import { Company } from "@next/modules/profile/redux";
import { selectCompanyCapabilities } from "./selectors";
import { t } from "assets/configi18n/i18n";
import snackbarUtils from "@next/utils/snackbarUtils";

function* handleFetchCompanyCapabilitiesTypes({
  payload,
}: PayloadAction<FetchCompanyCapabilitiesTypesInput>) {
  const { response, error } = yield safeCall(
    companyCapabilitiesService.getCapabilitiesTypes,
    payload
  );

  if (error) {
    yield put(companyCapabilitiesActions.fetchCompanyCapabilitesTypesFailure(error));
  } else {
    yield put(
      companyCapabilitiesActions.fetchCompanyCapabilitesTypesSuccess({
        data: response.data,
        type: payload.type,
      })
    );
  }
}

function* handlePatchCompanyCapabilities({
  payload,
}: PayloadAction<PatchCompanyCapabilitiesInput>) {
  const company: Company = yield select(getCompany);
  const companyCapabilitiesPK: number[] = yield select(selectCompanyCapabilities);
  const capabilitiesPk = [...companyCapabilitiesPK, ...payload.capabilities].filter(
    (value, index, self) => self.indexOf(value) === index
  );
  const { response, error } = yield safeCall(companyCapabilitiesService.patchCompanyCapabilities, {
    companyId: company.id,
    capabilities: capabilitiesPk,
    capabilities_comment: payload.capabilities_comment,
  });

  if (error) {
    yield put(companyCapabilitiesActions.patchCompanyCapabilitiesFailure(error));
  } else {
    yield snackbarUtils.toast(t("common:saved"));
    yield put(companyCapabilitiesActions.patchCompanyCapabilitiesSuccess(response.data));
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  }
}

export function* companyCapabilitesSaga() {
  yield takeEvery(
    companyCapabilitiesActions.fetchCompanyCapabilitesTypesRequest,
    handleFetchCompanyCapabilitiesTypes
  );

  yield takeLatest(
    companyCapabilitiesActions.patchCompanyCapabilitiesRequest,
    handlePatchCompanyCapabilities
  );
}
