export enum OrderStatus {
  CANCELED = "Canceled",
  CONTRACTED = "contracted",
  RECEIVED = "received",
  IN_RFQ = "inRfq",
  COMPLETE = "complete",
  IDLE = "idle",
  PO_SENT = "po-sent",
}

export enum OrderField {
  RFQ_DISPLAY_NAME = "rfq_display_name",
  ORDER_STATUS = "order-status",
  PARTS = "parts",
  TARGETED_SUPPLIERS = "targetedSuppliers",
  WINNING_SUPPLIER = "winningSupplier",
  WINNER = "winner",
  LEAD_TIME = "leadTime",
  EXPECTED_DELIVERY_DATE = "expected-delivery-date",
  RATING = "rating",
  UNIT_PRICE = "Unit Price",
  QUOTATIONS_COUNT = "quotations_count",
  DEADLİNE = "deadline",
  RATING_SCORE = "rating_score",
  EDIT_BUTTON = "editButton",
  CREATE_ORDER = "createOrder",
  PURCHASE_ORDER = "purchase_order",
  COST_AND_LEAD_TIME = "lead_time",
}
