import { Box, IconButton, Step, StepLabel, Stepper, styled } from "@mui/material";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    height: 0,
    width: 0,
    border: 0,
  },
}));

export const StepperContainer = styled(Box)({
  position: "relative",
  display: "inline-flex",
  alignItems: "center",
  overflow: "visible",
  width: "auto",
});

export const StyledStepper = styled(Stepper)({
  display: "inline-flex",
  flexDirection: "row",
  overflowX: "auto",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  width: "auto",
  gap: "12px",
});

export const StyledRightIconButton = styled(IconButton)({
  position: "absolute",
  right: "-17px",
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "#fff",
  zIndex: 2,
  width: "16px",
  height: "16px",
});

export const StyledLeftIconButton = styled(IconButton)({
  position: "absolute",
  left: "-17px",
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "#fff",
  zIndex: 2,
  width: "16px",
  height: "16px",
});

export const StyledStep = styled(Step)({
  padding: 0,
  margin: 0,
  flexShrink: 0,
  "& .MuiStep-root": {
    padding: 0,
  },
});

export const StyledStepLabel = styled(StepLabel)({
  "& .MuiStepLabel-iconContainer": {
    padding: 0,
  },
  "& .MuiStepLabel-root": {
    padding: 0,
  },
});
