import React from "react";

import { Button, Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useOnClickDownloadAll } from "./download-all-as-zip.utils";
import { useTranslation } from "react-i18next";

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 270,
    fontSize: 12,
    marginTop: 5,
  },
}))(Tooltip);

export const DownloadAllAsZip = ({ rfqFilesInfos }) => {
  const { t } = useTranslation();
  const onClickDownloadAll = useOnClickDownloadAll(rfqFilesInfos);

  // Download all part files on one click
  const handleDownloadAllClick = (e) => {
    e.stopPropagation();
    onClickDownloadAll();
  };

  return (
    <HtmlTooltip title={t("workspace:toolTipDownloadZIP")}>
      <Button
        disabled={!rfqFilesInfos?.download_all_url}
        variant="contained"
        color="secondary"
        onClick={(e) => handleDownloadAllClick(e)}
      >
        {t("workspace:downloadZIP")}
      </Button>
    </HtmlTooltip>
  );
};
