export enum AxyaSuppliers {
  ENTIRE_NETWORK = "entireNetwork",
  FAVORITE_SUPPLIERS = "favorite-suppliers",
}

type StatusInfos = {
  status: string;
  itb_date: null;
};

export type Option = {
  id: number;
  name: string;
  picture: string;
  is_online?: boolean;
  last_seen?: string;
  uuid?: string;
  status_info?: StatusInfos;
};

export interface SelectAllOptionProps {
  options: Option[];
  placeholder: string;
  hideEntireNetworkOption?: boolean;
  isSmall?: boolean;
  loading?: boolean;
  showStatus?: boolean;
}

export type SelectedOptionsParams = {
  targetValues: string[];
  options: Option[];
};
