/**
 * use this function to have specific characteristics of parts
 * @param {Array} listOfPart
 */
export const getCharacteristicsOfPart = (quotation) => {
  //List of part in the quotation
  const listOfPart = quotation ? quotation?.parts : [];

  //rfq primary key in the quotation
  const rfqPk = quotation ? quotation?.rfq_info?.pk : "";

  // rfq name in the quotation
  const rfqName = quotation ? quotation?.rfq_info?.name : "";

  return listOfPart.map((part) => {
    // get the part price
    let partPrice = part.price * part.quantity;

    // get the part unit price
    let partUnitPrice = Number.parseFloat(partPrice / part.quantity).toFixed(2);

    let data = {
      ...part,
      partPk: part.pk,
      name: part.name,
      quantity: part.quantity,
      price: partPrice,
      unitPrice: partUnitPrice,
      process: part.process,
      color: part.color,
      material: part.material,
      alloy: part.alloy,
      treatment: part.treatment,
      part_uploads_list: part.part_uploads_list,
      rfqPk: rfqPk,
      rfqName: rfqName,
    };
    return data;
  });
};
