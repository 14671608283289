import React from "react";

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { t } from "assets/configi18n/i18n";
import { SupplierLineItemsTableField } from "./supplier-line-items-table.types";
import { Box } from "@mui/material";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { CustomTypography } from "@next/components/custom-typography";
import { OrderDetailPartStatusTag } from "../order-detail/order-detail-part-status-tag/order-detail-part-status-tag";
import TextCell from "@next/components/table-cells/text-cell-v5";
import BuyerCell from "@next/components/table-cells/buyer-cell-v5";

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
};

const PONameCell: React.FC<GridRenderCellParams> = (params) => {
  return (
    <a
      href={params.row.purchase_order?.url}
      target="_blank"
      style={{ width: "100%" }}
      rel="noreferrer"
      data-pid={"supplierLineItems:poPortalViewClick"}
    >
      <Box display="flex" justifyContent="space-between">
        <CustomTypography variant="caption" maxWidth={"85%"}>
          {params.row.purchase_order?.name}
        </CustomTypography>

        <ArrowForwardRoundedIcon style={{ fontSize: "16px" }} />
      </Box>
    </a>
  );
};

const StatusCell: React.FC<GridRenderCellParams> = (params) => {
  return (
    <OrderDetailPartStatusTag
      orderShipmentStatus={params.row.status}
      shipmentConfirmationDate={params.row.shipment_confirmation_date}
    />
  );
};

export const getSupplierLineItemsTableColumns = () => {
  const _columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: SupplierLineItemsTableField.NAME,
      flex: 1,
      headerName: t("orders:supplierLineItemTable:header:partNumber"),
      renderCell: (params) => <TextCell {...params} value={params.row.name} />,
    },
    {
      ...defaultColumnConfig,
      field: SupplierLineItemsTableField.QUANTITY,
      flex: 0.5,
      headerName: t("orders:supplierLineItemTable:header:quantity"),
      renderCell: TextCell,
    },
    {
      ...defaultColumnConfig,
      field: SupplierLineItemsTableField.RFQ_NAME,
      flex: 1,
      headerName: t("orders:supplierLineItemTable:header:request"),
      renderCell: (params) => (
        <TextCell
          {...params}
          value={params.row.rfq?.display_name ? params.row.rfq.display_name : params.row.rfq.name}
        />
      ),
    },
    {
      ...defaultColumnConfig,
      field: SupplierLineItemsTableField.PO_NAME,
      flex: 1,
      headerName: t("orders:supplierLineItemTable:header:po"),
      renderCell: PONameCell,
    },
    {
      ...defaultColumnConfig,
      field: SupplierLineItemsTableField.BUYER,
      flex: 1,
      headerName: t("orders:supplierLineItemTable:header:buyer"),
      renderCell: (params) => (
        <BuyerCell
          {...params}
          value={params.row.buyer?.name}
          row={{ company_logo: params.row.buyer?.picture, ...params.row }}
        />
      ),
    },
    {
      ...defaultColumnConfig,
      field: SupplierLineItemsTableField.PROMISED_DELIVERY_DATE,
      flex: 1,
      headerName: t("orders:supplierLineItemTable:header:deliveryDate"),
      renderCell: TextCell,
    },
    {
      ...defaultColumnConfig,
      field: SupplierLineItemsTableField.STATUS,
      flex: 1,
      headerName: t("orders:supplierLineItemTable:header:status"),
      renderCell: StatusCell,
    },
  ];

  return _columns;
};
