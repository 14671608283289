import { Grid } from "@mui/material";
import React from "react";

type TabPanelProps = {
  value: number | string;
  index: number | string;
  innerProps?: React.ComponentProps<typeof Grid>;
  destroyOnHide?: boolean;
};

const TabPanel: React.FC<TabPanelProps & React.ComponentProps<typeof Grid>> = ({
  children,
  value,
  index,
  innerProps,
  destroyOnHide,
  ...rest
}) => {
  return (
    <Grid
      container
      hidden={value !== index}
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={destroyOnHide && value !== index ? { display: "none" } : undefined}
      {...rest}
    >
      {value === index && (
        <Grid item xs={12} {...innerProps}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default TabPanel;
