import React, { useCallback, useRef } from "react";

import { Box, Chip, FormHelperText, Tooltip, Typography } from "@mui/material";

import { GenericTextInput } from "components/utils/input/text";
import { Field, Form, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getUserLanguage } from "services/profile/profile.selectors";
import { RichTextEditor } from "@next/components/rich-text-editor";
import { RawDraftContentState } from "draft-js";
import { getPlainAndRichTextAsString } from "@next/components/rich-text-editor/rich-text-editor-utils";
import { useStyles } from "./rfq-creation-form-lite.styles";
import { useFormikScrollToError } from "@next/hooks/useFormilkScrollToError";

import { workspaceNextActions } from "../../redux";
import { selectUploadRfqAttachmentsLoading } from "../../redux/selectors";

import { FormikAttachFile } from "@next/components/formik-attach-file";
import LockIcon from "@mui/icons-material/Lock";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { MUIDatePicker } from "@next/components/mui-date-picker";
import { formatDate } from "helpers/formatDate";
import { RFQFormLiteValuesType } from "./rfq-creation-form-lite.types";
import RFQCreationFormLiteFooter from "./rfq-creation-form-lite-footer";
import HelpIcon from "@mui/icons-material/Help";
import { AppTheme } from "layout/AppTheme";

const RFQCreationFormLiteBase: React.FC<FormikProps<RFQFormLiteValuesType>> = (props) => {
  const { values, setFieldValue, touched, errors, submitForm } = props;
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const classes = useStyles();
  const language = useSelector(getUserLanguage);
  const additionalDetailsRichText = useRef<string | RawDraftContentState>(
    values.additional_details_rich_text || values.additional_details
  );
  const attachmentsLoading = useSelector(selectUploadRfqAttachmentsLoading);

  useFormikScrollToError();

  const onAttachmentsChange = (files: (File | FileData)[]) => {
    const toUpload = files.filter((file) => file instanceof File);

    dispatch(
      workspaceNextActions.uploadRfqAttachmentsRequest({
        files: toUpload as File[],
        rfqPk: null,
        onSuccess: (attachments) =>
          setFieldValue("attachments", [
            ...files.filter((file) => !(file instanceof File)),
            ...attachments,
          ]),
      })
    );
  };

  const onSubmit = useCallback(() => {
    const { richText, plainText } = getPlainAndRichTextAsString(
      additionalDetailsRichText.current || ""
    );
    setFieldValue("additional_details_rich_text", richText);
    setFieldValue("additional_details", plainText);
    submitForm();
  }, []);

  const onDescriptionChange = useCallback(
    (state) => {
      additionalDetailsRichText.current = state;
    },
    [values]
  );

  return (
    <>
      <Form className={classes.form}>
        <Box display="flex" alignItems="center" mb="24px">
          <Box>
            <Typography color="textSecondary">{t("workspaceNext:rfqDrawerLite:to")}</Typography>
          </Box>

          <Box ml="16px">
            <Tooltip title={t("workspaceNext:rfqDrawerLite:axyaDiscoveryNetworkTooltip")}>
              <Chip
                label={t("workspaceNext:rfqDrawerLite:axyaDiscoveryNetwork")}
                variant="outlined"
                deleteIcon={<HelpIcon style={{ color: AppTheme.palette.primary.main }} />}
                onDelete={() => {}}
              />
            </Tooltip>
          </Box>
        </Box>

        <Box className={classes.row}>
          <Typography color="textSecondary">
            {t("workspaceNext:rfqDrawerLite:craftYourRequest")}
          </Typography>
        </Box>

        <Box className={classes.row}>
          <Box flex={1}>
            <GenericTextInput
              name="rfq_name"
              label={t("workspaceNext:rfqDrawer:placeholder:addTitle")}
              value={values.rfq_name}
              size="small"
            />
          </Box>
          <Box flex={1}>
            <MUIDatePicker
              size="small"
              color="secondary"
              style={{ width: "100%" }}
              name="deadline"
              label={t("workspaceNext:rfqDrawer:placeholder:addEndDate")}
              value={values.deadline}
              inputVariant="outlined"
              format={language === "en" ? "yyyy/MM/dd" : "dd/MM/yyyy"}
              InputAdornmentProps={{ position: "end" }}
              onChange={(value) => {
                setFieldValue("deadline", formatDate(value, "YYYY-MM-DD") || "");
              }}
              error={touched.deadline && !!errors.deadline}
              helperText={touched.deadline && !!errors.deadline ? errors.deadline : ""}
              disablePast
              autoOk
              okLabel=""
              cancelLabel=""
            />
          </Box>
        </Box>

        <RichTextEditor
          minHeight={120}
          label={t("workspaceNext:rfqDrawerLite:placeholder:addDescription")}
          value={values.additional_details_rich_text || values.additional_details}
          onChange={onDescriptionChange}
        />

        <Box mt={3}>
          <Field
            name="attachments"
            component={FormikAttachFile}
            error={touched.attachments && !!errors.attachments}
            btnProps={{
              className: `${classes.rfqAttachmentBtn}`,
              loading: attachmentsLoading,
              variant: "outlined",
            }}
            btnText={t("workspaceNext:rfqDrawer:attachFiles")}
            captionText={t("workspaceNext:rfqDrawerLite:attachFilesCaption")}
            captionIcon={<LockIcon />}
            disableAcceptSpecificTypes={true}
            onChangeFiles={onAttachmentsChange}
          />
          {touched.attachments && !!errors.attachments && (
            <FormHelperText id="rfi-attachments-error" error={true}>
              {t("workspaceNext:rfqDrawer:rfiAttachmentsRequiredErrorMsg")}
            </FormHelperText>
          )}
        </Box>
      </Form>

      <RFQCreationFormLiteFooter onSubmit={onSubmit} />
    </>
  );
};

export default RFQCreationFormLiteBase;
