import { styled } from "@mui/material";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

export const StyledDataGridPro = styled(DataGridProV5)(({ theme }) => ({
  width: "1776px",
  height: "100%",
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: `${theme.palette.background.default} !important`,
  },
  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    "& .MuiCheckbox-root": {
      display: "none",
    },
  },
  "& .MuiDataGrid-row": {
    borderTop: "none",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "& .MuiDataGrid-cell": {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  "& .MuiDataGrid-row.disabled-row": {
    opacity: 1,
    pointerEvents: "none",
    "& .MuiDataGrid-cell[data-field='removeRowButton']": {
      pointerEvents: "auto",
    },
    "& .MuiDataGrid-cell[data-field='action']": {
      pointerEvents: "auto",
    },
  },
}));
