import { LinearProgress, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      background: "rgba(232, 232, 232, 0.4)",
      position: "absolute",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      width: "100%",
      zIndex: theme.zIndex.drawer + 1,
    },
  })
);

const TableLoadingOverlay = () => {
  const classes = useStyles();

  return (
    <>
      <LinearProgress color="primary" style={{ height: 1 }} />
      {/* <Box className={classes.backdrop}>
        <CircularProgress />
      </Box> */}
    </>
  );
};

export default TableLoadingOverlay;
