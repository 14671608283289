import React, { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import CustomGridToolbarExport from "@next/components/custom-grid-toolbar-export/custom-grid-toolbar-export";
import { CustomGridToolbarImport } from "@next/components/custom-grid-toolbar-import";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";

type Props = {
  onImport: (fileResults?: any) => void;
  onExport: () => void;
  exportDisabled?: boolean;
};

const GridToolbarColumnsButtonCustom = (
  props: Partial<ComponentProps<typeof GridToolbarColumnsButton>>
) => {
  return (
    <GridToolbarColumnsButton
      placeholder=""
      onPointerEnterCapture={() => {}}
      onPointerLeaveCapture={() => {}}
      {...props}
    />
  );
};

const GridToolbarDensitySelectorCustom = (
  props: Partial<ComponentProps<typeof GridToolbarDensitySelector>>
) => {
  return (
    <GridToolbarDensitySelector
      placeholder=""
      onPointerEnterCapture={() => {}}
      onPointerLeaveCapture={() => {}}
      {...props}
    />
  );
};

export const QuotationExtraFieldsTableToolbar: React.FC<Props> = ({
  onImport,
  onExport,
  exportDisabled,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const isMarketplace = location?.pathname?.includes("/marketplace");
  const importNotAvailable = location?.pathname?.includes("/view/");

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButtonCustom color="inherit" />
      <GridToolbarDensitySelectorCustom color="inherit" />
      <CustomGridToolbarExport
        onExport={onExport}
        exportDisabled={exportDisabled}
        buttonTitle={
          isMarketplace
            ? t("marketPlace:table:toolbar:exportTemplate")
            : t("marketPlace:table:toolbar:exportQuotation")
        }
      />
      {!importNotAvailable ? (
        <CustomGridToolbarImport
          importButtonTitle={t("marketPlace:table:toolbar:importQuotation")}
          onImport={onImport}
        />
      ) : null}
    </GridToolbarContainer>
  );
};
