import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import {
  selectProjectCreateLoading,
  selectProjectUpdateLoading,
} from "@next/modules/project/redux/selectors";
import { GenericTextInput } from "components/utils/input/text";
import { Form, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ProjectCreateRenameFormState } from "./project-create-rename-form.definitions";
import { ProjectCreateRenameFormVariant } from "./project-create-rename-form";

export const ProjectCreateRenameFormBase: React.FC<
  FormikProps<ProjectCreateRenameFormState> & {
    onClickCancel: () => void;
    variant: ProjectCreateRenameFormVariant;
  }
> = ({ handleSubmit, values, onClickCancel, variant }) => {
  const { t } = useTranslation();
  const projectUpdateLoading = useSelector(selectProjectUpdateLoading);
  const projectCreateLoading = useSelector(selectProjectCreateLoading);
  const loading = projectUpdateLoading || projectCreateLoading;

  return (
    <Form onSubmit={handleSubmit}>
      <Box mt="12px">
        <GenericTextInput
          name="display_name"
          label={variant === ProjectCreateRenameFormVariant.CREATE ? t("project:projectName") : ""}
          value={values.display_name}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" mt="24px">
        <Box mr="16px" display="flex" alignItems="center">
          {loading ? <CircularProgress size={16} /> : null}
        </Box>

        <Box mr="10px">
          <Button variant="outlined" onClick={onClickCancel} disabled={loading}>
            {t("common:cancel")}
          </Button>
        </Box>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={values.display_name === "" || loading}
        >
          {t("common:save")}
        </Button>
      </Box>
    </Form>
  );
};
