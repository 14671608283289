import React, { ChangeEvent } from "react";
import { t } from "assets/configi18n/i18n";

import { Box, Divider, FormControlLabel, Icon, Switch, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import LanguageIcon from "@mui/icons-material/Language";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1.5, 2),
    },
    formControl: {
      margin: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    infoText: {
      color: theme.palette.action.active,
    },
    labelIconContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(0.5),
      width: "32px",
      height: "32px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "6px",
      color: theme.palette.background.default,
    },
    labelContainer: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
  })
);

interface RFQPublicSwitchProps {
  isEnabled: boolean;
  onToggle: (enabled: boolean) => void;
  disabled?: boolean;
}

const RFQPublicSwitch: React.FC<RFQPublicSwitchProps> = ({
  isEnabled,
  onToggle,
  disabled = false,
}) => {
  const classes = useStyles();

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    onToggle(event.target.checked);
  };

  return (
    <>
      <Divider />
      <Box className={classes.root}>
        <Typography className={classes.infoText} variant="caption">
          {t("rfq:isPublicSwitch:title")}
        </Typography>
        <Box mt={1}>
          <FormControlLabel
            className={classes.formControl}
            labelPlacement="start"
            control={
              <Switch
                defaultChecked
                checked={isEnabled}
                onChange={handleSwitchChange}
                color="primary"
                disabled={disabled}
              />
            }
            label={
              <Box className={classes.labelContainer}>
                <Icon className={classes.labelIconContainer}>
                  <LanguageIcon color="inherit" />
                </Icon>
                <Typography>{t("rfq:isPublicSwitch:label")}</Typography>
              </Box>
            }
          />
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default RFQPublicSwitch;
