import {
  GridCellParams,
  GridRenderCellParams,
  MuiEvent,
  useGridApiRef,
} from "@mui/x-data-grid-pro-v5";
import { PAGINATION_PAGE_SIZE } from "@next/constants";
import { getRowIdToPk } from "@next/utils/dataGridUtils-v5";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectMyPartsCount, selectMyPartsLoading } from "../../redux/selectors";
import { useMyPartsTableData } from "./my-parts-table.hooks";
import { MyPartsTableField } from "./my-parts-table.types";

import { useTranslation } from "react-i18next";
import { MyPartsTableToolbar } from "./my-parts-table.toolbar";
import { Box } from "@mui/material";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "../../modals/types";
import { MyPartsTableEditMenu, MyPartsTableMenuItemType } from "./my-parts-table.menu";
import { debounce } from "lodash";
import { projectActions } from "@next/modules/project/redux";
import { selectSelectedPartRows } from "@next/modules/project/redux/selectors";
import { addOrRemove } from "@next/utils/arrayUtils";
import { getMyPartCheckboxColumn } from "./my-parts-table.columns";
import { workspaceNextActions } from "../../redux";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import { makeStyles } from "@mui/styles";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
      display: "none",
    },
  },
}));

type Props = {
  // This flags disable all interactions with the grid e.g editing rows, displaying ctx menu, etc..
  isReadOnly?: boolean;
};

const MyPartsTable: React.FC<Props> = ({ isReadOnly = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gridApiRef = useGridApiRef();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | Element>(null);
  const [menuActiveData, setMenuActiveData] = useState<any>();

  const myPartsLoading = useSelector(selectMyPartsLoading);
  const myPartsCount = useSelector(selectMyPartsCount);

  const selectedPartRows = useSelector(selectSelectedPartRows);

  const [searchValue, setSearchValueState] = useState<string | undefined>();

  const [myPartsTableDataParams, setMyPartsTableDataParams] = useState<{
    currentPage: number;
    debouncedSearchValue: string;
    isReadOnly: boolean;
  }>({ currentPage: 1, debouncedSearchValue: "", isReadOnly });
  const { gridData } = useMyPartsTableData(myPartsTableDataParams);
  const [pageSize, setPageSize] = React.useState(PAGINATION_PAGE_SIZE);

  useEffect(() => {
    dispatch(projectActions.setSelectedPartRows([]));
  }, []);

  const openEditPartModal = (menuActiveData: any) => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.PART_MODAL,
        data: menuActiveData,
      })
    );
  };

  const resetMenuAnchorEl = () => {
    setMenuAnchorEl(null);
  };

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent<Element, MouseEvent>>
  ) => {
    if (isReadOnly) return;

    setMenuActiveData(params.row);

    switch (params?.field) {
      case MyPartsTableField.EDIT:
        openEditPartModal(params.row);
        break;
      case MyPartsTableField.ATTACHMENTS:
        openEditPartModal(params.row);
        break;
      case MyPartsTableField.CONTEXT_MENU:
        setMenuAnchorEl(event.currentTarget);
        break;
      default:
        break;
    }
  };

  const onClickMenuItem = (menuItem: MyPartsTableMenuItemType) => {
    switch (menuItem) {
      case MyPartsTableMenuItemType.EDIT:
        openEditPartModal(menuActiveData);
        break;
      case MyPartsTableMenuItemType.CLONE:
        dispatch(workspaceNextActions.clonePartRequest(menuActiveData?.pk));
        resetMenuAnchorEl();
        break;
      case MyPartsTableMenuItemType.DELETE:
        confirmDialog(
          t("workspace:confirmation"),
          t("workspaceNext:myParts:modals:areYouSureDeleteThisPart"),
          () => {
            dispatch(workspaceNextActions.deletePartRequest(menuActiveData?.pk));
            resetMenuAnchorEl();
          },
          t("common:delete")
        );
        break;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchValueWithDebounce = useCallback(
    debounce(
      (value) =>
        setMyPartsTableDataParams({
          currentPage: 1,
          debouncedSearchValue: value,
          isReadOnly,
        }),
      1000
    ),
    []
  );

  const setSearchValue = (value: string) => {
    setSearchValueState(value);
    setSearchValueWithDebounce(value);
  };

  const clearSearch = () => {
    setSearchValueState("");
    setMyPartsTableDataParams({
      currentPage: 1,
      debouncedSearchValue: "",
      isReadOnly,
    });
  };

  const onPageChange = (newPage: number) => {
    setMyPartsTableDataParams((prev) => ({
      ...prev,
      currentPage: newPage + 1,
    }));
  };

  const checkboxColumn = useMemo(
    () => {
      const onCheckboxChange =
        ({ row, selectAll }: { row?: GridRenderCellParams["row"]; selectAll?: boolean }) =>
        () => {
          dispatch(
            projectActions.setSelectedPartRows(
              selectAll
                ? selectedPartRows.length === 30 || selectedPartRows.length > 0
                  ? []
                  : gridData.rows
                : addOrRemove(selectedPartRows, row)
            )
          );
        };

      return getMyPartCheckboxColumn(onCheckboxChange, selectedPartRows, myPartsLoading);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPartRows, myPartsLoading, gridData.rows]
  );

  return (
    <Box mt="32px">
      <MyPartsTableToolbar
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        clearSearch={clearSearch}
        selectedPartsLength={selectedPartRows.length}
      />

      <DataGridProV5
        autoHeight
        pagination
        paginationMode="server"
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        apiRef={gridApiRef}
        rowCount={myPartsCount}
        onPageChange={onPageChange}
        getRowId={getRowIdToPk}
        rowHeight={32}
        headerHeight={39}
        onCellClick={onCellClick}
        getRowClassName={() => "c-cursor-pointer"}
        className={classes.root}
        rows={gridData?.rows || []}
        columns={[...(!isReadOnly ? checkboxColumn : []), ...gridData?.columns] || []}
        hideFooterSelectedRowCount
      />

      {!isReadOnly && (
        <MyPartsTableEditMenu
          anchorEl={menuAnchorEl}
          setAnchorEl={setMenuAnchorEl}
          onClickMenuItem={onClickMenuItem}
          disabledClone={!menuActiveData?.rfq}
        />
      )}
    </Box>
  );
};

export default MyPartsTable;
