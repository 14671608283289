import { styled } from "@mui/material";

export const StyledBox = styled("div")({
  position: "fixed",
  left: "0",
  top: "0",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});
