import React from "react";
import ValidationPage from "components/ValidationPage";
import errorImg from "assets/img/oops-not-found.png";

import { useTranslation } from "react-i18next";

// Silly way to access children props

const SomethingWentWrong: React.FC = () => {
  const { t }: any = useTranslation("ERRORS");
  return (
    <div className="app-root">
      <ValidationPage
        title={t("oopsSomethingWentWrongTitle")}
        body={t("oopsSomethingWentWrongBody")}
        primaryText={t("reloadApp")}
        handleSubmitPrimaryUrl={() => {
          window.location.href = `${window.location.origin}/auth/logout`;
        }}
        image={errorImg}
      ></ValidationPage>
    </div>
  );
};
class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    console.error(error);

    if (error?.toString()?.includes("TypeError: Cannot create property")) {
      // If there is any crash error related type (Ex: encryption library error), purge persistor.
      // persistor.purge();
    }

    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any) {
    // You can also log the error to an error reporting service
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return <SomethingWentWrong {...this.props}></SomethingWentWrong>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
