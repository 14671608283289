import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import CollaboratorsList from "components/workspace/buyer-rfqs-view/collaborators-list";

const UsersCell: React.FC<GridRenderCellParams> = (params) => {
  return (
    <CollaboratorsList
      data={{
        collaborators: params.row.collaborators,
        rfqPk: params.row.pk,
        createdBy: params.row.created_by,
      }}
      size="small"
    />
  );
};

export default UsersCell;
