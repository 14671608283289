import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "../redux/modalsSlices";
import { SharedModalTypes } from "./types";
import { useTranslation } from "react-i18next";
import Modal from "@next/components/custom-modal";
import { SharedAddOnHoldNoteForm } from "./components/add-on-hold-note/add-on-hold-note.form";

export const SharedAddOnHoldNoteModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(SharedModalTypes.ADD_ON_HOLD_NOTE_MODAL));
  const data = modal?.data as {
    rfqPk: number;
    isDetailPage?: boolean;
  };

  if (!modal || !data) return null;

  const onClose = () => {
    dispatch(modalsActions.closeModal(SharedModalTypes.ADD_ON_HOLD_NOTE_MODAL));
  };

  return (
    <Modal
      handleModalClose={onClose}
      modalState={{
        modalHeader: t("project:table:row:addANote"),
        modalBody: (
          <SharedAddOnHoldNoteForm rfqPk={data?.rfqPk} isDetailPage={data?.isDetailPage} />
        ),
      }}
      isModalOpen={true}
    />
  );
};
