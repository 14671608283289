import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PhoneIcon from "@mui/icons-material/Phone";

const UnsubscribedMessageBox: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap="12px">
      <Typography variant="h6">{t("auth:unsubscribed:title")}</Typography>
      <Typography variant="body2">{t("auth:unsubscribed:message")}</Typography>

      <a href="tel:+1 438 600-8933">
        <Chip
          icon={<PhoneIcon />}
          label="+1 438-600-8933"
          onClick={console.log}
          variant="outlined"
        />
      </a>
    </Box>
  );
};

export default UnsubscribedMessageBox;
