import React from "react";

import {
  DataGridProProps,
  GridCellParams,
  GridRowIdGetter,
  MuiEvent,
} from "@mui/x-data-grid-pro-v5";
import { GridDataState } from "./suppliers-table-v5.types";
import { makeStyles } from "@mui/styles";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

const useStyles = makeStyles(() => ({
  root: {
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: 0,
    },
    "& .MuiDataGrid-columnHeaders": {
      display: ({ hideHeaders }: any) => (hideHeaders ? "none" : "inherit"),
    },
    "& .MuiDataGrid-window": {
      position: ({ hideHeaders }: any) => (hideHeaders ? "inherit" : "relative"),
    },
  },
  columnHeader: {
    background: "none",
    "& .MuiDataGrid-columnSeparator": {
      display: ({ hideHeaders }: any) => (hideHeaders ? "none" : "unset"),
    },
  },
}));

type Props = {
  gridData: GridDataState;
  hideHeaders?: Boolean;
  getRowId?: GridRowIdGetter;
  loading?: boolean;
  onCellClick?: (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent<Element, MouseEvent>>,
    details?: any
  ) => void;
  showContactsList?: boolean;
} & Partial<DataGridProProps>;

export const SuppliersTable: React.FC<Props> = ({
  gridData,
  hideHeaders = false,
  getRowId,
  loading,
  onCellClick,
  showContactsList = false,
  ...props
}) => {
  const classes = useStyles({ hideHeaders });

  return (
    <div>
      <DataGridProV5
        autoHeight
        autoPageSize
        hideFooter
        getRowId={getRowId}
        disableSelectionOnClick
        headerHeight={hideHeaders ? 0 : 40}
        classes={classes}
        rowHeight={61}
        loading={loading}
        onCellClick={onCellClick}
        {...props}
        rows={gridData?.rows || []}
        columns={gridData?.columns || []}
      />
    </div>
  );
};
