import React from "react";
import { IconButton, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

interface ReviewChangeButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>, revert?: boolean) => void;
  tooltipTitle?: string;
  icon?: React.ReactElement;
  status?: "approved" | "rejected" | null;
  buttonType?: "approve" | "reject";
  readOnly?: boolean;
}

type StyleProps = {
  status?: ReviewChangeButtonProps["status"];
  readOnly?: ReviewChangeButtonProps["readOnly"];
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  const getChipBgColor = ({ status }: StyleProps) => {
    return status === "approved"
      ? theme.palette.success.dark
      : status === "rejected"
        ? theme.palette.error.main
        : theme.palette.grey[300];
  };

  return createStyles({
    iconButton: {
      cursor: ({ readOnly }) => (readOnly ? "default" : "pointer"),
      border: ({ status }) =>
        status !== null ? "none" : `1px solid ${theme.palette.action.disabled}`,
      width: ({ readOnly }) => (readOnly ? 20 : 32),
      height: ({ readOnly }) => (readOnly ? 20 : 32),
      padding: 5,
      backgroundColor: ({ status }) =>
        status !== null ? getChipBgColor({ status }) : "transparent",
      "& .MuiSvgIcon-root": {
        fontSize: ({ readOnly }) => (readOnly ? 16 : 20),
        color: (props: StyleProps) =>
          props.status !== null ? theme.palette.background.default : theme.palette.text.secondary,
      },
      "&:hover": {
        backgroundColor: ({ status }) =>
          status !== null ? getChipBgColor({ status }) : "transparent",
      },
    },
  });
});

export const ReviewChangeButton: React.FC<ReviewChangeButtonProps> = ({
  icon,
  onClick,
  status,
  buttonType,
  readOnly,
}) => {
  const classes = useStyles({ status, readOnly });

  const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    let revert: boolean;
    if (buttonType) {
      if (status === "approved" && buttonType === "approve") {
        revert = true;
      } else if (status === "rejected" && buttonType === "reject") {
        revert = true;
      }
    }
    if (onClick) onClick(event, revert);
    return true;
  };

  return (
    <IconButton
      onClick={handleIconClick}
      className={classes.iconButton}
      size={readOnly ? "small" : "large"}
      disableRipple={readOnly}
    >
      {icon}
    </IconButton>
  );
};
