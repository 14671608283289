// General imports
import React from "react";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import Moment from "react-moment";
// Material ui imports
import { Box } from "@mui/material";
import Rating from "@mui/material/Rating";
import PlaceIcon from "@mui/icons-material/Place";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LanguageIcon from "@mui/icons-material/Language";
import { AppTheme } from "layout/AppTheme";
import i18n from "assets/configi18n/i18n";

export const SellerRating = ({
  sellerData: {
    company_created_at,
    company_website,
    company_address: { city, country, province },
  },
  ratingScores,
}) => {
  const { t } = useTranslation();
  const language = useSelector((state) => state.profile.user.language);
  return (
    <div className="c-seller-rating">
      <Box
        className={
          !!ratingScores ? "c-seller-rating--rating-container" : "c-seller-rating--no-rating"
        }
      >
        {!!ratingScores && Object.keys(ratingScores).length ? (
          ratingScores.map((rating) => (
            <Box display="flex" alignItems="center" key={rating["pk"]} mt={2}>
              {
                <Box ml={1}>
                  <Rating
                    color={AppTheme.palette.primary.main}
                    name="read-only"
                    precision={0.1}
                    value={rating["avg_score"] ? rating["avg_score"] : 0}
                    readOnly
                  />
                </Box>
              }

              <Box fontSize={16} letterSpacing={0.5} color="#212121" ml={1.5}>
                {rating["description"]}
              </Box>
            </Box>
          ))
        ) : (
          <Box display="flex" align="center" justifyContent="center">
            <Box fontSize={16} letterSpacing={0.5} color="#00000099">
              {t("sellerInfosPopover:noRatingsMsg")}
            </Box>
          </Box>
        )}
      </Box>
      <Box className="c-seller-rating--address-container">
        <Box display="flex" alignItems="center" alignContent="center">
          <Box>
            <PlaceIcon className="c-seller-rating--icon place-icon-width" />
          </Box>
          <Box
            ml={1.5}
            fontSize={16}
            letterSpacing={0.01}
            color="#000000DE"
          >{`${city}, ${province}, ${country}`}</Box>
        </Box>
        <Box display="flex" alignItems="center" alignContent="center" mt={2}>
          <Box>
            <AccountBoxIcon className="c-seller-rating--icon account-box-icon-width" />
          </Box>
          <Box ml={1.5} fontSize={16} letterSpacing={0.01} color="#000000DE">
            {`${t("workspace:memberSince")}  `}
            <Moment locale={i18n.language} fromNow ago>
              {company_created_at}
            </Moment>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" alignContent="center" mt={2}>
          <Box>
            <LanguageIcon className="c-seller-rating--icon language-icon-width" />
          </Box>
          <Box ml={1.5} fontSize={16} letterSpacing={0.01} color="#000000DE">
            <a
              href={company_website.includes("http") ? company_website : `//${company_website}`}
              target="_blank"
              className="c-seller-rating--website-link"
              rel="noreferrer"
            >
              {company_website}
            </a>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
