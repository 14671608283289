import { Formik } from "formik";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ordersActions } from "../../redux";
import { selectOrder } from "../../redux/selectors";
import { SendOrderFormBase } from "./send-order-form.base";
import {
  SendOrderFormState,
  getSendOrderFormInitialValues,
  sendOrderFormValidationSchema,
} from "./send-order-form.definitions";
import { partConfigurationActions } from "services/part-configuration";
import { useTranslation } from "react-i18next";
import snackbarUtils from "@next/utils/snackbarUtils";
import { filteredUsersAccordingToDistribution } from "@next/modules/workspace/components/supplier-contacts-list/supplier-contacts-list.utils";
import { DistributionForSupplierContacts } from "@next/modals/shared-supplier-contact-lists-modal";

export const SendOrderForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orderDetail = useSelector(selectOrder);
  const isSupplierExistInOrder = orderDetail?.supplier?.id;

  const updateOrder = (values: SendOrderFormState, isSent: boolean) => {
    if (!orderDetail?.pk) return;

    if (!isSupplierExistInOrder && !values?.supplier?.pk) {
      return snackbarUtils.error(t("orders:sendOrderDrawer:pleaseSelectSupplier"));
    }

    dispatch(
      ordersActions.updateOrderRequest({
        pk: orderDetail?.pk,
        name: values.name,
        delivery_date: moment(values.delivery_date).format("YYYY-MM-DD"),
        note: values.note,
        is_sent: isSent,
        onSuccess: () => {
          dispatch(partConfigurationActions.fetchPartConfigurationData());
        },
        qa_token: orderDetail?.qa_token,
        created_by: values?.created_by,
        supplier: values?.supplier?.pk,
        target_profiles: filteredUsersAccordingToDistribution(
          values?.supplier?.target_profiles,
          DistributionForSupplierContacts.ORDER
        ).map((profile) => profile.id),
      })
    );
  };

  const onSubmit = (values: SendOrderFormState) => {
    updateOrder(values, true);
  };

  const saveOrder = useCallback(
    debounce(
      (values: SendOrderFormState) => {
        updateOrder(values, false);
      },
      1000,
      {
        leading: false,
        trailing: true,
      }
    ),
    [orderDetail]
  );

  return (
    <Formik
      initialValues={getSendOrderFormInitialValues(orderDetail)}
      validationSchema={sendOrderFormValidationSchema}
      onSubmit={onSubmit}
      validateOnMount
      render={(props) => <SendOrderFormBase {...props} saveOrder={saveOrder} />}
    />
  );
};
