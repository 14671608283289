import React from "react";

//helper function use it to redirect to another page

import { AppBar, Box, Dialog, DialogContent, Grid, IconButton, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import TagForm from "./tag-form/tag-form.container";

export const TagDialog = ({ open, onclickCloseTagDialog, partPk, partTags, t }) => {
  return (
    <Dialog open={open} onClose={onclickCloseTagDialog} className={"c-tag-dialog"} maxWidth="sm">
      <div className="c-tag-dialog-container">
        <AppBar elevation={0} className="c-tag-dialog--app-bar" position="sticky">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="c-tag-dialog--grid-container"
          >
            <Grid item xs={2}>
              <Typography align="left" variant="h6">
                {t("tag")}
              </Typography>
            </Grid>

            <Grid item xs={1}>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="close"
                onClick={onclickCloseTagDialog}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </AppBar>
        <DialogContent className="c-tag-dialog--content">
          <Box
            fontSize={16}
            fontWeight="fontWeightRegular"
            className="c-tag-dialog--content--max-tags"
          >
            {t("youCanAddUpToFiveTagsPerPart")}
          </Box>
          <TagForm
            t={t}
            partPk={partPk}
            partTags={partTags}
            onclickCloseTagDialog={onclickCloseTagDialog}
          />
        </DialogContent>
      </div>
    </Dialog>
  );
};
