import React, { useCallback } from "react";
import { Typography } from "@material-ui/core";
import { Box, Button, Theme, alpha, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/styles";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import { ordersActions } from "@next/modules/orders/redux";
import {
  selectAddOrderPartsLoading,
  selectLineItemsTableSelectionModel,
} from "@next/modules/orders/redux/selectors";
import { formatDate } from "helpers/formatDate";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { OrderDetailEditLineItemChangeDate } from "./order-detail-edit-line-items-change-date/order-detail-edit-line-items-change-date";
import { PO_DRAFT_PART_GRID_STATE } from "@next/constants/data-grid-state-constants";
import useLocalStorageGridState from "@next/hooks/useLocalStorageGridState-v5";
import { useOrderDetailDraftLineItemsTableData } from "./order-detail-draft-line-items-table.hooks";
import { GridCellEditCommitParams } from "@mui/x-data-grid-pro-v5";
import { AttachFileList } from "@next/components/attach-file/attach-file-list";
import { ProjectFileDeleteConfirmationModal } from "@next/modules/project/modals/project-file-delete-confirmation-modal";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { DraftOrderLineItemsField } from "./order-detail-draft-line-items-table.types";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    table: {
      "& .highlightedCell": {
        backgroundColor: alpha(theme.palette.warning.main, 0.1),
      },

      "& .highlightedBlueCell": {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      },
    },
  })
);

export const OrderDetailDraftLineItemsTable = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lineItemsTableSelectionModel = useSelector(selectLineItemsTableSelectionModel);
  const addNewLineItemLoading = useSelector(selectAddOrderPartsLoading);
  const { gridData, orderDetail } = useOrderDetailDraftLineItemsTableData();
  const theme = useTheme();
  const onlyForMobile = useMediaQuery(theme.breakpoints.down(937));

  const { apiRef } = useLocalStorageGridState(PO_DRAFT_PART_GRID_STATE);

  const dispatch = useDispatch();

  const handleDeleteItems = () => {
    confirmDialog(
      t("orders:deleteDraftPartsTitle"),
      t("orders:deleteDraftConfirm"),
      () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        lineItemsTableSelectionModel?.forEach((id) => {
          dispatch(
            ordersActions.deleteShipmentPartRequest({
              order_pk: orderDetail?.pk as number,
              line_item_id: id as number,
            })
          );
        });
      },
      t("common:delete"),
      "delete",
      t("common:back")
    );
  };

  const onClickAddNewItem = () => {
    const newRow = {
      name: "New Part",
      quantity: 1,
      description: "",
      delivery_date: formatDate(new Date(), "YYYY-MM-DD"),
    };
    dispatch(
      ordersActions.addOrderPartsRequest({
        order_pk: orderDetail.pk,
        parts: [newRow],
      })
    );
  };

  const handleEditDate = (date: Date) => {
    dispatch(
      ordersActions.updateOrderDeliveryDatesRequest({
        orderPk: orderDetail?.pk,
        delivery_dates: lineItemsTableSelectionModel?.map((id) => {
          return {
            id: id,
            delivery_date: formatDate(date, "YYYY-MM-DD"),
          };
        }),
      })
    );
  };

  const onCellEditCommit = useCallback(
    (params: GridCellEditCommitParams & { api?: any }) => {
      const row = apiRef.current.getRow(params.id);

      if (apiRef.current) {
        if (row) {
          const oldFieldValue = row.field;
          if (params.value === oldFieldValue) {
            return;
          }
        }
      }

      let field = params.field;
      if (field === DraftOrderLineItemsField.PRICE) {
        field = "updated_price";
      }

      dispatch(
        ordersActions.updateDraftOrderLineItemsRequest({
          orderPk: orderDetail.pk,
          line_items: [
            {
              id: Number(params.id),
              [field]: params.value,
            },
          ],
        })
      );
    },
    [apiRef.current]
  );

  const handleAdditionalAttachmentDelete = (fileName: string) => {
    dispatch(
      ordersActions.updateOrderRequest({
        pk: orderDetail?.pk,
        additional_files: orderDetail?.additional_files.filter(
          (attachment: FileData) => attachment.file_name !== fileName
        ),
      })
    );
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mt="20px">
        <Typography variant="h6">{t("orders:orderDetail:lineItems")}</Typography>

        <Box ml="12px" mt="4px">
          <Typography variant="body2" color="textSecondary">
            {t("orders:orderDetail:selectedItems", {
              count: lineItemsTableSelectionModel?.length,
            })}
          </Typography>
        </Box>

        <Box ml="12px">
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            color="primary"
            onClick={onClickAddNewItem}
            disabled={addNewLineItemLoading}
          >
            {t("orders:addOrderParts:title")}
          </Button>
        </Box>

        <Box ml="12px">
          <Button
            variant="outlined"
            color="error"
            onClick={handleDeleteItems}
            disabled={!lineItemsTableSelectionModel?.length}
          >
            {t("orders:orderDetail:deleteRows", {
              count: lineItemsTableSelectionModel?.length,
            })}
          </Button>
        </Box>
        <Box ml="12px">
          <OrderDetailEditLineItemChangeDate
            disabled={!lineItemsTableSelectionModel?.length}
            label={t("orders:orderDetail:editReceptionDate")}
            onSelectedDateChange={handleEditDate}
          />
        </Box>
      </Box>

      <Box mt="20px" height={onlyForMobile ? "600px" : "510px"} className={classes.table}>
        <DataGridProV5
          apiRef={apiRef}
          rows={gridData?.rows || []}
          columns={gridData?.columns || []}
          onCellEditCommit={onCellEditCommit}
          rowHeight={40}
          pagination
          pageSize={10}
          paginationMode="client"
          hideFooterSelectedRowCount
          disableSelectionOnClick
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            dispatch(ordersActions.setLineItemsTableSelectionModel(newSelectionModel));
          }}
          selectionModel={lineItemsTableSelectionModel}
        />
      </Box>

      <Box mb="20px">
        {orderDetail?.additional_files?.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              maxWidth: "max-content",
            }}
          >
            <AttachFileList
              title="Files"
              files={orderDetail?.additional_files}
              onClickDelete={handleAdditionalAttachmentDelete}
            />
          </Box>
        )}
      </Box>

      <ProjectFileDeleteConfirmationModal />
    </Box>
  );
};
