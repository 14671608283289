/**
 * This is the global component of the workspace
 * It can be found at url /workspace. in the header Workspace we have two button
 * first button "NOUVELLE PIÉCE": this button open modal who contain input to upload file.
 * After upload file you can be redirect to the AddPart form to create part.
 * After create part the part will be display in the workspace.
 *
 * second button "DEMANDE DE DEVIS" will be open after clicking  on existing part in the workspace. We use
 * this button to send part to request for quotation.
 *
 */

// general imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import querystring from "query-string";
// material ui imports
import { Box, Button, Checkbox, Chip, CircularProgress, Grid, Typography } from "@mui/material";

// components imports
import EmptyWorkspaceModal from "./empty-workspace-parts.component";

import Part from "../../../components/workspace/part/part.container";

// image imports
import cadImage from "../../../assets/img/default-part-img.png";
import NoResultFound from "../../../assets/img/No-result-found.svg";

// components import
import PartDetailsDialog from "../../part-dialog/part-details-dialog.component";
import { WorkspaceContentLoader } from "../workspace-content-loader/workspace-content-loader";
import { WorkspaceHeader } from "../workspace-header/workspace-header.component";

// helpers import
import { frontendUrl } from "../../../urls";
import { history } from "../../../helpers/history";

// actions import
import { workspaceActions } from "../../../services/workspace";
import { modalsActions as modalsActionsNext } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { workspaceSelectors } from "services/workspace/workspace.selectors";

// the main workspace component
export const WorkspaceParts = (props) => {
  const {
    token,
    fetchCompanyPartList,
    fetchCompanyPartsNextPage,
    handleOpenModal,
    handleCloseModal,
    partList,
    partListHasNext,
    modalState,
    setSelectedParts,
    selectedTags,
    partNextPageLoading,
    isWorkspaceLoading,
    isWorkspaceInitialLoading,
  } = props;
  const { t } = useTranslation("workspace");
  const location = useLocation();
  const [isPartDetailsDialogOpen, setIsPartDetailsDialogOpen] = useState(false);
  const [partToView, setPartToView] = useState(null);
  const [checked, setChecked] = useState([]);
  const dispatch = useDispatch();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [allPartPks, setAllPartPks] = useState([]);
  const sortedPartsByNewlyCreated = partList && partList.sort((a, b) => (b.pk > a.pk ? 1 : -1));
  const resetPartsSelectionCounter = useSelector(workspaceSelectors.getResetPartsSelectionCounter);

  //FIXME: this is a workaround to reset selected parts. Ideally checked parts state should be moved to redux
  useEffect(() => {
    if (resetPartsSelectionCounter) {
      setChecked([]);
      setSelectAllChecked(false);
    }
  }, [resetPartsSelectionCounter]);

  useEffect(() => {
    const partPks = [];
    sortedPartsByNewlyCreated &&
      sortedPartsByNewlyCreated.map((part) => {
        partPks.push(part.pk);
      });
    // set all part pks in "partPks" variable when partList changes (when part duplicated, ..)
    setAllPartPks(partPks);
  }, [partList]);

  //clear part selection on workspace filtering
  useEffect(() => {
    setSelectAllChecked(false);
    setChecked([]);
  }, [selectedTags]);

  const isRfqOptionValid = checked.length > 0; // Variable to check if the option to create an RFQ is Valid.
  const onClickCheckBox = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);

    // set select all parts checkbox to true when all the parts individually checked
    if (allPartPks.length === newChecked.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  };

  const handleSelectAllParts = (value) => {
    if (value) {
      setChecked(allPartPks);
    } else {
      setChecked([]);
    }
    setSelectAllChecked(!selectAllChecked);
  };

  const handleClickOpen = () => {
    //temporary array to hold the selected parts
    const tmp = [];

    //For each part in the partlist
    partList.forEach((part) => {
      //If the pk of the part is included in the part selection list of the user
      if (checked.includes(part.pk)) {
        //add the part to the temporary array
        tmp.push(part);
      }
    });

    //update the state of selectedParts with the recently update information of the temporaryArray
    setSelectedParts(tmp);
    dispatch(
      modalsActionsNext.showModal({
        key: WorkspaceModalTypes.RFQ_CREATION_DRAWER,
        data: {
          form: { parts: tmp || [] },
          selectAllParts: true,
        },
      })
    );
  };

  // set select all checkbox false when part is duplicated
  const handleClonePart = () => {
    setSelectAllChecked(false);
  };

  // remove tags on clear click
  const handleRemoveTags = (tag) => {
    const selectedTagNames = [];
    const selectedTagsAfterRemoval = selectedTags.filter((selectedTag) => {
      if (selectedTag.name !== tag.name) {
        selectedTagNames.push(selectedTag.name);
        return true;
      }
    });
    dispatch(workspaceActions.setSelectedTags(selectedTagsAfterRemoval));
    const queryParams = querystring.stringify({ tag: selectedTagNames });
    fetchCompanyPartList(token, queryParams.toString());
    history.push(`${location.pathname}?${queryParams}`);
  };

  const handleEmptyPartsFilterView = () => {
    const queryParams = location.search.replace("?", "");
    const selectedTagsObject = querystring.parse(queryParams);
    const selectedTagsArray = Array.isArray(selectedTagsObject.tag)
      ? selectedTagsObject.tag
      : Object.keys(selectedTagsObject).length
        ? [selectedTagsObject.tag]
        : [];

    if (selectedTagsArray.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleLoadMore = () => {
    fetchCompanyPartsNextPage(token);
  };

  const isEmptyWorkspace =
    (partList === null || partList === undefined || partList.length === 0) &&
    !handleEmptyPartsFilterView();
  //To achieve "ComponentDidMount" behavior,we can pass an empty array to the second argument of useEffect()
  //will fetch the company's part list on first render
  useEffect(() => {
    if (!location.search) {
      // arguments - token, params: tag filter query params, isInitialLoad
      fetchCompanyPartList(token, "", true);
    }
    handleOpenModal(false, "isAddPartModalOpen");
  }, []);

  const handleRemoveFilter = () => {
    history.push(frontendUrl.myParts);
    fetchCompanyPartList(token, "", true);
    dispatch(workspaceActions.setSelectedTags([]));
  };

  const selectedChips = () => {
    return (
      <Box display="inline-flex" className="selected-tags-chips">
        {selectedTags &&
          selectedTags.map((selectedTag) => {
            return (
              <Chip
                className="selected-tags"
                label={selectedTag.name}
                onDelete={() => handleRemoveTags(selectedTag)}
              />
            );
          })}
      </Box>
    );
  };

  return (
    <div data-test="headerWorkspace" style={{ width: "100%" }}>
      {isWorkspaceInitialLoading ? (
        <Grid container spacing={3}>
          <WorkspaceContentLoader showHeader={true} />
        </Grid>
      ) : isEmptyWorkspace && !isWorkspaceLoading ? (
        <EmptyWorkspaceModal>
          <WorkspaceHeader
            // handleOpenModal method accepts two arguments, (isModalOpen, modalID)
            handleOpenModal={() => handleOpenModal(true, "isAddPartModalOpen")}
            openModal={modalState}
            handleCloseModal={() => handleCloseModal("isAddPartModalOpen")}
            handleClickOpen={handleClickOpen}
            isValid={isRfqOptionValid}
            checked={checked}
            t={t}
          />
        </EmptyWorkspaceModal>
      ) : (
        <Grid item xs={12} className="new-parts">
          <WorkspaceHeader
            // handleOpenModal method accepts two arguments, (isModalOpen, modalID)
            handleOpenModal={() => handleOpenModal(true, "isAddPartModalOpen")}
            openModal={modalState}
            handleCloseModal={() => handleCloseModal("isAddPartModalOpen")}
            handleClickOpen={handleClickOpen}
            isValid={isRfqOptionValid}
            checked={checked}
            t={t}
          />

          {sortedPartsByNewlyCreated &&
          sortedPartsByNewlyCreated.length === 0 &&
          handleEmptyPartsFilterView() ? (
            <div>
              {selectedChips()}
              <Grid container direction="column" justifyContent="center">
                <Grid item xs={12} justifyContent="center">
                  <Grid container justifyContent="center">
                    <img src={NoResultFound} alt="" className="no-part-img" />
                  </Grid>
                </Grid>

                <Box className="no-part-text">
                  <Grid item xs={12} justifyContent="center" className="no-part-text--space">
                    <Typography>{t("noCorrespondingPart")}</Typography>
                  </Grid>
                </Box>
                <Box className="no-part-btn">
                  <Grid item xs={12}>
                    <Button onClick={() => handleRemoveFilter()}>{t("removeTheTags")}</Button>
                  </Grid>
                </Box>
              </Grid>
            </div>
          ) : (
            <>
              <Box display="flex" alignItems="center" flexWrap="wrap">
                {/* select all parts checkbox */}
                <Checkbox
                  checked={selectAllChecked}
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                  className="c-part--checkbox"
                  onChange={(event) => handleSelectAllParts(event.target.checked)}
                />
                <Typography variant="body1" display="inline">
                  {t("selectAll")}{" "}
                  {sortedPartsByNewlyCreated && `(${sortedPartsByNewlyCreated.length})`}
                </Typography>
                {/* list of selected tags */}
                {selectedChips()}
              </Box>
              {isWorkspaceLoading ? (
                <Grid container spacing={3}>
                  <WorkspaceContentLoader />
                </Grid>
              ) : (
                <>
                  <Grid container spacing={3} className="c-workspace-parts--card-container">
                    {sortedPartsByNewlyCreated &&
                      sortedPartsByNewlyCreated.map((part) => {
                        let file_preview = part.preview;
                        if (file_preview) {
                          file_preview = part.preview.file_preview_url;
                        }

                        return (
                          <Grid key={part.pk} item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Part
                              part={part}
                              cadImage={file_preview}
                              fallbackImage={cadImage}
                              checked={checked.indexOf(part.pk) !== -1}
                              onClick={() => {
                                setPartToView(part);
                                setIsPartDetailsDialogOpen(true);
                              }}
                              onClickCheckBox={onClickCheckBox(part.pk)}
                              t={t}
                              handleClonePart={handleClonePart}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                  {partListHasNext ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="60px">
                      {partNextPageLoading ? (
                        <CircularProgress size={35} />
                      ) : (
                        <Button onClick={handleLoadMore} fullWidth style={{ height: 60 }}>
                          {t("workspace:loadMore")}
                        </Button>
                      )}
                    </Box>
                  ) : null}
                </>
              )}
            </>
          )}
        </Grid>
      )}
      <PartDetailsDialog
        {...props}
        part={partToView}
        isOpen={isPartDetailsDialogOpen}
        onClose={() => setIsPartDetailsDialogOpen(false)}
      />
    </div>
  );
};
