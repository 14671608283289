import { Box, Chip, Typography, styled } from "@mui/material";

export const StyledTitle = styled(Typography)({
  fontFamily: "Space Grotesk",
  fontSize: "24px",
  lineHeight: "24px",
  letterSpacing: "0.18px",
});

type Props = {
  isCompleted: boolean;
  isInReview: boolean;
  isApproved: boolean;
};

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) =>
    prop !== "isCompleted" && prop !== "isApproved" && prop !== "isInReview",
})<Props>(({ theme, isApproved, isInReview, isCompleted }) => {
  const backgroundColor = isApproved
    ? "rgba(18, 214, 125, 0.1)"
    : isInReview
      ? "rgba(255, 169, 44, 0.1)"
      : isCompleted
        ? "rgba(38, 130, 253, 0.1)"
        : theme.palette.divider;

  const labelColor = isApproved
    ? theme.palette.success.dark
    : isInReview
      ? theme.palette.warning.dark
      : isCompleted
        ? theme.palette.primary.main
        : "rgba(0, 0, 0, 0.87)";

  return {
    marginLeft: "12px",
    backgroundColor: backgroundColor,
    height: "24px",
    "& .MuiChip-label": {
      color: labelColor,
      fontSize: "12px",
    },
  };
});

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});
