import { Theme, Typography } from "@mui/material";
import React from "react";
import { BulkFileImportSummaryItemType } from "./bulk-file-import-summary-item";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PartsAutoCompleteDropdown from "@next/modules/workspace/components/rfq-creation-form/parts-autocomplete-dropdown";
import { Form, Formik } from "formik";
import { FormikAutoSaver } from "@next/components/formik-auto-saver/formik-auto-saver";
import { useDispatch } from "react-redux";
import { PartUpload, projectActions } from "@next/modules/project/redux";
import { createStyles, makeStyles } from "@mui/styles";
import { BulkFileImportFor } from "@next/modals/shared-bulk-files-import-modal";
import { OrderPartsAutocompleteDropdown } from "@next/modules/orders/components/order-parts-autocomplete-dropdown/order-parts-autocomplete-dropdown";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      paddingLeft: "8px",
      paddingRight: "8px",
      marginTop: "12px",
    },
    left: {
      width: "50%",
      display: "flex",
      justifyContent: "space-between",
    },
    right: {
      width: "50%",
    },
    arrow: {
      paddingRight: "20px",
      display: "flex",
      alignItems: "center",
    },
    arrowIcon: {
      width: "16px",
      height: "16px",
      color: theme.palette.text.secondary,
    },
  })
);

type Props = {
  type: BulkFileImportSummaryItemType;
  file: any;
  error?: string;
  bulkFileImportFor?: BulkFileImportFor;
};

export const PartsDropdownForm = ({
  onSubmit,
  bulkFileImportFor,
}: {
  onSubmit: (values: any) => void;
  bulkFileImportFor: BulkFileImportFor;
}) => (
  <Formik initialValues={{ parts: [] }} onSubmit={onSubmit}>
    <Form>
      {bulkFileImportFor === BulkFileImportFor.PROJECT ? (
        <PartsAutoCompleteDropdown
          hideCreatePartButton={true}
          fromPM={true}
          multiple={false}
          disablePlaceholder={true}
        />
      ) : bulkFileImportFor === BulkFileImportFor.ORDER ? (
        <OrderPartsAutocompleteDropdown />
      ) : null}
      <FormikAutoSaver />
    </Form>
  </Formik>
);

export const BulkFileImportSummaryItemFileStatus: React.FC<Props> = ({
  type,
  file,
  error,
  bulkFileImportFor,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const filePk = file?.file_pk;

  const onSubmit = (values: any) => {
    const part = values?.parts;
    if (!part || part.length === 0) return;
    const currentFilesPk = part?.part_uploads_list?.map((file: PartUpload) => file?.pk) || [];

    if (filePk) {
      dispatch(
        projectActions.updateProjectPartRequest({
          pk: part?.pk as number,
          part: {
            files: [...currentFilesPk, filePk],
          },
        })
      );
    }
  };

  const renderFormik = () => (
    <Formik initialValues={{ parts: [] }} onSubmit={onSubmit}>
      <PartsDropdownForm onSubmit={onSubmit} bulkFileImportFor={bulkFileImportFor} />
    </Formik>
  );

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <Typography variant="body2">{file?.file_name}</Typography>
        {type === BulkFileImportSummaryItemType.WARNING && (
          <div className={classes.arrow}>
            <ArrowForwardIcon className={classes.arrowIcon} />
          </div>
        )}
      </div>
      <div className={classes.right}>
        {type === BulkFileImportSummaryItemType.WARNING && renderFormik()}
        {error && <Typography>{error}</Typography>}
      </div>
    </div>
  );
};
