import React from "react";
import { CircularAvatarsList } from "@next/components/circular-avatars-list";
import { Box, CardContent, Typography } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";

import { formatDate } from "helpers/formatDate";

export const BuyerStatsCreatedByInfos = ({ rfqDetails, language, t }) => {
  return (
    <div>
      <CardContent className="c-buyer-stats-created-by-infos" pt={10}>
        <Box ml={-0.5} mt={-1} mb={1}>
          <Typography color="textSecondary" variant="body1">
            {t("createdBy")}
          </Typography>
        </Box>
        {rfqDetails?.created_by_details?.full_name && (
          <Box
            display="flex"
            alignContent="center"
            justifyContent="flex-start"
            className="c-buyer-stats-created-by-infos--box"
            alignItems="center"
            p={0.5}
          >
            <Box pr={0.5}>
              <CircularAvatarsList
                images={[
                  {
                    name: rfqDetails?.created_by_details?.full_name,
                    tooltip: rfqDetails?.created_by_details?.full_name,
                    url: rfqDetails?.created_by_details?.picture,
                  },
                ]}
                overlapping
                combinedTooltip
                size="medium"
              />
            </Box>
            {rfqDetails.created_by_details.full_name}
          </Box>
        )}

        {rfqDetails?.created_by_details?.email && (
          <Box
            display="flex"
            alignContent="center"
            justifyContent="flex-start"
            className="c-buyer-stats-created-by-infos--box"
            paddingRight={40}
            p={0.5}
          >
            <Box pr={0.5}>
              {" "}
              <MailOutlineIcon className="c-buyer-stats-created-by-infos--icon" />
            </Box>
            {rfqDetails.created_by_details.email}
          </Box>
        )}

        {rfqDetails?.created_at && (
          <Box
            display="flex"
            alignContent="center"
            justifyContent="flex-start"
            className="c-buyer-stats-created-by-infos--box"
            p={0.5}
          >
            <Box pr={0.5}>
              <EventAvailableOutlinedIcon className="c-buyer-stats-created-by-infos--icon" />
            </Box>
            <Box pr={0.5}>
              <Typography color="textSecondary" variant="body2">
                {t("createdOn")}
              </Typography>
            </Box>
            {formatDate(rfqDetails.created_at, "DD-MMM-YYYY")}
          </Box>
        )}

        {rfqDetails?.deadline && (
          <Box
            display="flex"
            alignContent="center"
            justifyContent="flex-start"
            className="c-buyer-stats-created-by-infos--box"
            p={0.5}
          >
            <Box pr={0.5}>
              <FlagOutlinedIcon className="c-buyer-stats-created-by-infos--icon" />
            </Box>
            <Box pr={0.5}>
              <Typography color="textSecondary" variant="body2">
                {t("endsOn")}
              </Typography>
            </Box>
            {formatDate(rfqDetails.deadline, "DD-MMM-YYYY")}
          </Box>
        )}
      </CardContent>
    </div>
  );
};
