import { Box, Typography } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { ReviewOrderFormBase } from "./review-order-form.base";
import {
  ReviewOrderFormState,
  getReviewOrderFormState,
  getReviewOrderFormValidationSchema,
} from "./review-order-form.definitions";

type Props = {
  onClose: () => void;
  description?: string;
  buttonText: string;
  onSubmit: (values: ReviewOrderFormState) => void;
  loading?: boolean;
  showDateSelection?: boolean;
  previewLineItemChanges?: boolean;
  defaultDate?: string;
  isPortal?: boolean;
  notePlaceholder?: string;
  dateInputLabel?: string;
  asModal?: boolean;
};

export const ReviewOrderForm: React.FC<Props> = ({
  description,
  onClose,
  buttonText,
  onSubmit,
  loading,
  showDateSelection,
  previewLineItemChanges,
  defaultDate,
  isPortal,
  notePlaceholder,
  dateInputLabel,
  asModal,
}) => {
  return (
    <Box>
      {description ? (
        <Typography variant="body1" px={"24px"} pt={"24px"}>
          {description}
        </Typography>
      ) : null}

      <Formik
        initialValues={getReviewOrderFormState({ defaultDate })}
        validationSchema={getReviewOrderFormValidationSchema()}
        onSubmit={onSubmit}
        validateOnMount
        render={(props) => (
          <ReviewOrderFormBase
            onClose={onClose}
            buttonText={buttonText}
            loading={loading}
            showDateSelection={showDateSelection}
            previewLineItemChanges={previewLineItemChanges}
            isPortal={isPortal}
            notePlaceholder={notePlaceholder}
            dateInputLabel={dateInputLabel}
            asModal={asModal}
            {...props}
          />
        )}
      />
    </Box>
  );
};
