import { Box, Chip, CircularProgress, Theme } from "@mui/material";
import React, { ReactNode, useCallback, useState } from "react";
import { RFQSupplierType } from "./rfq-creation.form.types";
import { workspaceNextService } from "../../services/workspace-services";
import { FavoriteSupplierGroup } from "../../redux";
import { openProfileInNewTab } from "@next/utils/browserUtils";
import GenericAvatar from "@next/components/generic-avatar";
import { CustomTypography } from "@next/components/custom-typography";
import { createStyles, makeStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

type StyleProps = {
  rootWidth?: string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    optionContainer: {
      display: "flex",
      alignItems: "center",
      paddingRight: theme.spacing(1),
      width: ({ rootWidth }) => (rootWidth ? rootWidth : "100%"),
      justifyContent: "space-between",
    },
    initials: {
      minWidth: 40,
      textAlign: "center",
    },
    disabledText: {
      color: theme.palette.text.disabled,
      marginLeft: theme.spacing(0.5),
    },
    groupChip: {
      cursor: "pointer",
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    supplierGroupChip: {
      marginRight: theme.spacing(0.5),
    },
    profileLink: {
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  })
);

interface GroupChipProps {
  group: FavoriteSupplierGroup;
  onSuppliersFetched?: (suppliers: RFQSupplierType[]) => void;
}

export const GroupChip: React.FC<GroupChipProps> = ({ group, onSuppliersFetched }) => {
  const classes = useStyles({});
  const [loading, setLoading] = useState(false);

  const onClick = useCallback(() => {
    setLoading(true);
    workspaceNextService
      .fetchFavoriteSuppliersList({ query: { group: group.pk } })
      .then((res) => {
        if (typeof onSuppliersFetched === "function") onSuppliersFetched(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [onSuppliersFetched, group.pk]);

  return (
    <Chip
      key={group.pk}
      onClick={onClick}
      className={classes.groupChip}
      label={group.name}
      onDelete={loading ? () => null : undefined}
      deleteIcon={loading ? <CircularProgress size={20} /> : undefined}
    />
  );
};

interface SupplierListItemProps {
  name: string;
  uuid?: string;
  groups?: FavoriteSupplierGroup[];
  picture?: string | null;
  loading?: boolean;
  openProfileOnClick?: boolean;
  tooltipTitle?: ReactNode;
  maxWidth?: number;
  avatarStyle?: React.CSSProperties;
  selected?: boolean;
  enableCheckbox?: boolean;
  rootWidth?: string;
}

const SupplierListItem: React.FC<SupplierListItemProps> = ({
  name,
  uuid,
  picture,
  groups,
  loading,
  openProfileOnClick,
  tooltipTitle = null,
  maxWidth,
  avatarStyle,
  selected,
  enableCheckbox,
  rootWidth,
}) => {
  const classes = useStyles({ rootWidth });
  const nameClickable = name && uuid && openProfileOnClick;

  const onClickName = useCallback(() => {
    openProfileInNewTab(name, uuid as string);
  }, [name, uuid]);

  return (
    <Box className={classes.optionContainer}>
      <Box flex={1} display="flex" alignItems="center">
        <GenericAvatar
          url={picture || ""}
          name={name}
          size={"medium"}
          avatarProps={{ style: avatarStyle }}
        />

        <Box ml={1}>
          <CustomTypography
            tooltipTitle={tooltipTitle}
            onClick={nameClickable ? onClickName : undefined}
            className={nameClickable ? classes.profileLink : undefined}
            variant="body2"
            maxWidth={maxWidth}
          >
            {name}
          </CustomTypography>
        </Box>

        {Array.isArray(groups) ? (
          <Box flex={1} ml={1} mr={-2}>
            {groups.map((item, ix) => (
              <Chip
                key={item.pk}
                label={item.name}
                variant="outlined"
                size="small"
                className={ix === groups.length - 1 ? undefined : classes.supplierGroupChip}
                style={{ cursor: "inherit" }}
              />
            ))}
          </Box>
        ) : null}
      </Box>
      {enableCheckbox ? (
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon />}
          checkedIcon={<CheckBoxIcon />}
          checked={selected}
        />
      ) : null}
      {loading ? <CircularProgress size={20} /> : null}
    </Box>
  );
};

export default SupplierListItem;
