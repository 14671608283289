import React from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { AppTheme } from "layout/AppTheme";
import { LegendList } from "../../analytic-graph-card/analytic-graph-card-legend-list/analytic-graph-card-legend-list";
import { formatNumber } from "helpers/formatNumber";
import { OrdersReportChart } from "@next/modules/analytics/redux";

const colors = [
  AppTheme.palette.secondary.main,
  AppTheme.palette.primary.main,
  AppTheme.palette.primary.main,
];

export const OrdersReportCumulativeColumnChart: React.FC<OrdersReportChart> = ({
  id,
  x_axis,
  y_axes,
}) => {
  const { t } = useTranslation();
  const legendTitles = [
    t("analytics:ordersReport:cards:rfqsCount"),
    t("analytics:global:totalValue"),
    t("analytics:global:cumulativeValue"),
  ];

  const data = {
    series: [
      {
        name: legendTitles[0],
        type: "column",
        data: y_axes[1].values,
      },
      {
        name: legendTitles[1],
        type: "column",
        data: y_axes[0].values,
      },
      {
        name: legendTitles[2],
        type: "line",
        data: y_axes[2].values,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line" as "line",
        stacked: false,
        toolbar: {
          autoSelected: "zoom" as "zoom",
        },
        zoom: {
          enabled: true,
          type: "x" as "x",
          autoScaleYaxis: true,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
          borderRadius: 2,
          borderRadiusWhenStacked: "all" as "all",
          borderRadiusApplication: "around" as "around",
        },
      },
      stroke: {
        width: [1, 1, 1, 2],
        colors: ["", "", AppTheme.palette.primary.main],
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: x_axis,
        tickPlacement: "on",
      },
      yaxis: [
        {
          seriesName: legendTitles[0],
          tickAmount: 6,
          opposite: false,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            formatter: (value: number) => formatNumber(value),
          },
          title: {
            text: legendTitles[0],
          },
          tooltip: {
            enabled: true,
          },
        },
        {
          seriesName: legendTitles[1],
          tickAmount: 6,
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            formatter: (value: number) => formatNumber(value),
          },
          title: {
            text: legendTitles[1],
          },
          tooltip: {
            enabled: true,
          },
        },
        {
          seriesName: legendTitles[2],
          tickAmount: 6,
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            formatter: (value: number) => formatNumber(value),
          },
          title: {
            text: legendTitles[2],
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      tooltip: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      colors: [colors[0], colors[1], colors[2]],
      fill: {
        opacity: 1,
      },
    },
  };

  return (
    <>
      <LegendList legendTitles={legendTitles} colors={colors} />
      <div style={{ marginLeft: "-12px" }}>
        <Chart options={data.options} series={data.series} type="line" height={"370"} />
      </div>
    </>
  );
};
