import React from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, FormikProps } from "formik";
import { Box, Typography } from "@mui/material";
import { GenericTextInput } from "components/utils/input/text";

import { SelectSuppliers } from "@next/components/select-suppliers/select-suppliers";
import { RFQAddAddendaFormValues } from "./rfq-add-addenda-form.types";
import CustomButton from "@next/components/custom-button/custom-button";
import { FormikAttachFile } from "@next/components/formik-attach-file";
import { selectAddAddendaLoading, selectFileUploading } from "../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { workspaceNextActions } from "../../redux";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import { createStyles, makeStyles } from "@mui/styles";
import { Supplier } from "../../components/suppliers-table/suppliers-table-v5.types";

const useStyles = makeStyles((theme) =>
  createStyles({
    footer: {
      margin: theme.spacing(-3),
      marginTop: 0,
      padding: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.divider}`,
      display: "flex",
      justifyContent: "flex-end",
      position: "sticky",
      bottom: theme.spacing(-3),
      backgroundColor: theme.palette.common.white,
    },
    note: {
      "& .MuiFormHelperText-root.Mui-error": {
        marginLeft: 0,
      },
    },
  })
);

const RFQAddAddendaFormBase: React.FC<
  FormikProps<RFQAddAddendaFormValues> & { suppliers: Supplier[] }
> = ({ handleSubmit, suppliers, values, setFieldValue }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const loading = useSelector(selectAddAddendaLoading);
  const rfqDetails = useSelector(getRfqDetails);
  const fileUploadingStatus = useSelector(selectFileUploading);
  const onAttachmentsChange = (files: File[]) => {
    const toUpload = files.filter((file) => file instanceof File);
    setFieldValue("files", files);

    dispatch(
      workspaceNextActions.uploadAddendaAttachmentsRequeset({
        files: toUpload as File[],
        rfq: rfqDetails.pk,
        onSuccess: (uploadedFiles) =>
          setFieldValue("files", [
            ...files.filter((file) => !(file instanceof File)),
            ...uploadedFiles,
          ]),
      })
    );
  };

  const onAttachementDelete = (filesName: string) => {
    dispatch(
      workspaceNextActions.removeFileUploadProgress({
        filesName,
      })
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Box mb={3}>
        <Typography variant="body1">{t("rfqAddenda:modal:description")}</Typography>
      </Box>

      <Box mb={3}>
        <Field
          name="suppliers"
          hideEntireNetworkOption={true}
          component={SelectSuppliers}
          options={suppliers}
          placeholder={t("rfqAddenda:modal:selectSuppliers")}
          showStatus={true}
        />
      </Box>

      <Box mb={3} className={classes.note}>
        <GenericTextInput
          multiline={true}
          name="note"
          placeholder={t("rfqAddenda:modal:describeWhatHasChanged")}
          rows="7"
        />
      </Box>
      <Box mb={3} className={classes.note}>
        <Field
          name="files"
          disableAcceptSpecificTypes
          component={FormikAttachFile}
          captionText={t("workspace:optional")}
          onChangeFiles={onAttachmentsChange}
          onDeleteFiles={onAttachementDelete}
        />
      </Box>
      <Box className={classes.footer}>
        <CustomButton
          loading={loading}
          type="submit"
          color="primary"
          variant="contained"
          disabled={
            !values.suppliers.length ||
            (!values.files.length && !values.note) ||
            (values.files.length && !fileUploadingStatus)
          }
        >
          {t("rfqAddenda:modal:send")}
        </CustomButton>
      </Box>
    </Form>
  );
};

export default RFQAddAddendaFormBase;
