import * as Yup from "yup";
import { t } from "assets/configi18n/i18n";

export const registerFormValidations = Yup.object({
  type: Yup.string().required(t("common:error:thisFieldIsRequired")),
  name: Yup.string().required(t("common:error:thisFieldIsRequired")),
  address: Yup.object({
    street: Yup.string().required(t("common:error:thisFieldIsRequired")),
    postal_code: Yup.string().required(t("common:error:thisFieldIsRequired")),
    city: Yup.string(),
    province: Yup.string(),
    country: Yup.string(),
  }),
  phone_number: Yup.string()
    .min(17, "common:error:enterValidPhone")
    .required(t("common:error:thisFieldIsRequired")),
});

export const registerFormInitialValues = {
  type: "",
  name: "",
  address: {
    street: "",
    postal_code: "",
    city: "",
    province: "",
    country: "",
  },
  phone_number: "",
};
