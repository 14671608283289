import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import { SideDrawerMessageThread } from "./side-drawer-message-thread/side-drawer-message-thread";
import { SideDrawerResolveBox } from "./side-drawer-resolve-box/side-drawer-resolve-box";
import { SideDrawerThreadCard } from "../side-drawer-threadful-review/side-drawer-thread-card/side-drawer-thread-card";
import {
  selectCurrentMessageThread,
  selectIsAllStepsApprovalNull,
  selectIsAllStepsCompleted,
  selectIsFormDisplayForValidationManagement,
  selectIsFormStepInReview,
  selectIsShowReviewBoxForThreadfulReview,
  selectMessageThreadsOfStep,
  selectMessagesOfThread,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  MessageThread,
  Step,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { getUser } from "services/profile/profile.selectors";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./side-drawer-review-box.styled";

type Props = {
  isThreadful: boolean;
};

export const SideDrawerReviewBox: React.FC<Props> = ({ isThreadful }) => {
  const dispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();
  const user = useSelector(getUser);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const activeStep: Step = steps[activeFormStepIndex];
  const messageThreads = useSelector(selectMessageThreadsOfStep);
  const messagesOfThread = useSelector(selectMessagesOfThread);
  const currentMessageThread = useSelector(selectCurrentMessageThread);
  const isShowReviewBoxForThreadfulReview = useSelector(selectIsShowReviewBoxForThreadfulReview);
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );
  const isActiveStepInReview = useSelector(selectIsFormStepInReview);
  const isAllStepsCompleted = useSelector(selectIsAllStepsCompleted);
  const isAllStepsApprovalNull = useSelector(selectIsAllStepsApprovalNull);
  const isSendMessageDisabled =
    (isFormDisplayForValidationManagement && user?.user_type === "viewer") ||
    (!isActiveStepInReview && !isFormDisplayForValidationManagement) ||
    (!isAllStepsCompleted && isAllStepsApprovalNull) ||
    (currentMessageThread?.is_resolved && !isFormDisplayForValidationManagement) ||
    currentMessageThread?.line_deleted;
  const [message, setMessage] = useState<string>("");

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  const handleClickSendMessageBtn = () => {
    if (message.trim() === "") return;

    if (isThreadful) {
      if (currentMessageThread && currentMessageThread?.id !== null) {
        sendMessage(currentMessageThread);
      } else {
        const body = {
          title: currentMessageThread?.title,
          supplier_title: currentMessageThread?.supplier_title,
          entity_id: currentMessageThread?.entity_id,
          entity_name: currentMessageThread?.entity_name,
        };
        dispatch(
          vendorManagementActions.createNewMessageThreadRequest({
            companyId: companyId,
            stepId: activeStep.step,
            body: body,
            onSuccess: sendMessage,
          })
        );
      }
    } else {
      if (messageThreads && messageThreads?.length > 0) {
        sendMessage(messageThreads[0]);
      } else {
        dispatch(
          vendorManagementActions.createNewMessageThreadRequest({
            companyId: companyId,
            stepId: activeStep.step,
            body: {},
            onSuccess: sendMessage,
          })
        );
      }
    }
  };

  const sendMessage = (messageThread: MessageThread) => {
    if (!currentMessageThread || currentMessageThread?.id === null) {
      dispatch(vendorManagementActions.setCurrentMessageThread(messageThread));
    }
    dispatch(
      vendorManagementActions.createNewMessageWithinThreadRequest({
        messageThreadId: messageThread.id,
        body: message,
        onSuccess: onSuccessForSendMessage,
      })
    );
  };

  const onSuccessForSendMessage = (messageThreadId: number) => {
    setMessage("");
    dispatch(vendorManagementActions.triggerFetchingMessagesOfThread());

    const messageThread: MessageThread = messageThreads?.find(
      (thread) => thread.id === messageThreadId
    );
    if (messageThread?.is_resolved) {
      dispatch(
        vendorManagementActions.updateMessageThreadRequest({
          stepId: activeStep.step,
          messageThreadId: messageThreadId,
          isResolved: false,
        })
      );
    }

    if (isThreadful) {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfSpecRequest({
          companyId: companyId,
          stepId: activeStep.step,
          body: {
            status: "changes_requested",
            entity_id: currentMessageThread.entity_id,
            entity_name: currentMessageThread.entity_name,
          },
          onSuccess: updateStatusesOfStepAndTriggerFetchingSpecs,
        })
      );
    } else {
      updateApprovalAndCompletionStatusesOfStep();
    }
  };

  const updateStatusesOfStepAndTriggerFetchingSpecs = () => {
    updateApprovalAndCompletionStatusesOfStep();
    dispatch(vendorManagementActions.triggerForFetchingSpecsOfStep());
  };

  const updateApprovalAndCompletionStatusesOfStep = () => {
    if (
      activeStep.approval_status === "approved" ||
      activeStep.approval_status === "changes_done" ||
      activeStep.approval_status === null
    ) {
      updateApprovalOfStep();
    }

    if (activeStep.is_completed) {
      updateCompletionOfStep();
    }
  };

  const updateApprovalOfStep = () => {
    dispatch(
      vendorManagementActions.updateApprovalStatusOfStepRequest({
        companyId: companyId,
        stepId: activeStep.step,
        status: "changes_requested",
      })
    );
  };

  const updateCompletionOfStep = () => {
    const partialStep = {
      is_completed: false,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: activeStep.id,
        data: partialStep,
      })
    );
  };

  const NoCommentBox = () => (
    <S.NoCommmentBox>
      <Typography variant="body2">
        {"No comment yet, write a message to start a review."}
      </Typography>
    </S.NoCommmentBox>
  );

  return (
    <S.StyledContentBox>
      <S.StyledInnerBox>
        <Box>
          {isShowReviewBoxForThreadfulReview ? (
            <SideDrawerThreadCard
              id={currentMessageThread?.id}
              is_resolved={currentMessageThread?.is_resolved}
              title={currentMessageThread?.title}
              entity_id={currentMessageThread?.entity_id}
              entity_name={currentMessageThread?.entity_name}
              line_deleted={currentMessageThread?.line_deleted}
              supplier_title={currentMessageThread?.supplier_title}
            />
          ) : null}

          {messageThreads?.length > 0 && messagesOfThread?.length > 0 ? (
            <>
              {<SideDrawerResolveBox isThreadful={isThreadful} />}

              <S.StyledThreadBox>
                <SideDrawerMessageThread />
              </S.StyledThreadBox>
            </>
          ) : (
            <NoCommentBox />
          )}
        </Box>

        <S.StyledInputBox>
          <S.StyledTextField
            placeholder={"Write your message..."}
            label={null}
            minRows={1}
            multiline
            value={message}
            onChange={handleMessageChange}
            disabled={isSendMessageDisabled}
            onKeyDown={onKeyDown}
          />

          <IconButton disabled={isSendMessageDisabled} onClick={handleClickSendMessageBtn}>
            <S.StyledSendIcon />
          </IconButton>
        </S.StyledInputBox>
      </S.StyledInnerBox>
    </S.StyledContentBox>
  );
};
