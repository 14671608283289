import { FileData } from "@next/components/attach-file/attach-file-list.types";

export enum CompanyTypeNext {
  DO = "DO",
  FAB = "FAB",
}

export type BomFilesUploadReportStatusData = {
  file_pk?: number;
  file_name: string;
  message?: string;
};

export enum UploadReportStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

export type BomFilesUploadReport = {
  uuid: string;
  status: UploadReportStatus;
  success_report: any;
  warning_report: BomFilesUploadReportStatusData[];
  error_report: BomFilesUploadReportStatusData[];
  additional_attachments?: FileData[];
  total_files_count: number;
  files_processed_count: number;
  files_success_count: number;
  parts_success_count: number;
  files_warning_count: number;
  files_error_count: number;
};
