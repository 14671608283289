import { DataGridProProps, DataGridPro as DataGridProV4Base } from "@mui/x-data-grid-pro";
import React, { useEffect } from "react";
import { initializeDataGridProV4License } from "./data-grid-pro-v4.utils";

const DataGridProV4: React.FC<DataGridProProps> = (props) => {
  useEffect(() => {
    initializeDataGridProV4License();
  }, []);

  return <DataGridProV4Base {...props} />;
};

export default DataGridProV4;
