import * as yup from "yup";

export type CreateOrderFormState = {
  orderName: string;
};

export const createOrderFormState: CreateOrderFormState = {
  orderName: "",
};

export const createOrderFormValidationSchema = yup.object({
  orderName: yup.string(),
});
