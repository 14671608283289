import { Box, Theme, Typography } from "@mui/material";
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { OrderDetailPartStatusTag } from "../order-detail-part-status-tag/order-detail-part-status-tag";
import { OrderLineItemChanges, OrderShipmentStatus } from "@next/modules/orders/redux";
import rightArrow from "assets/img/arrow-cool-down.svg";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    rootContainer: {
      display: "flex",
      flexDirection: "column",
      padding: "24px",
      gap: "16px",
    },
    contentBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  })
);

export const PreviewLineItemStatusChange: React.FC<{
  lineItemChanges: OrderLineItemChanges[];
}> = ({ lineItemChanges }) => {
  const classes = useStyles();

  return (
    <Box className={classes.rootContainer}>
      {lineItemChanges?.map((item) => (
        <Box key={item.name} className={classes.contentBox}>
          <Typography variant="caption">{item.name}</Typography>

          <Typography variant="caption" display={"flex"} alignItems={"center"} gap={"8px"}>
            <OrderDetailPartStatusTag
              orderShipmentStatus={
                (item?.previous_line_item?.status as OrderShipmentStatus) ||
                (item?.status as OrderShipmentStatus)
              }
              size={"small"}
            />
            <img src={rightArrow} style={{ width: "16px", height: "16px" }} />
            <OrderDetailPartStatusTag orderShipmentStatus={"shipped"} size={"small"} />
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
