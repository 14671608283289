import { axiosApi } from "@next/api";
import { handleResponse } from "helpers/responseHandling";
import { backendUrl } from "../../urls";
import { ISendRatingInfo } from "./rating.model";

class RatingInfo {
  static getRatingInfo = async (token: string) => {
    try {
      const url = `${backendUrl.ratingInfo}${token}/`;
      const res = await axiosApi.get(url).then(handleResponse);
      return res;
    } catch (err) {
      throw new Error(`Something went wrong: ${err || ""}`);
    }
  };

  static sendUserRating = async (token: string, rating: ISendRatingInfo) => {
    const url = `${backendUrl.sendRating}${token}/`;

    return await axiosApi
      .post(url, rating)
      .then(async (response: any) => {
        if (response.status === 201) {
          return "Request submitted sucessfully";
        }
        // FIXME: refactor error to CustomError
        throw new Error("Something went wrong");
      })
      .catch((error) => {
        throw error;
      });
  };
}

export default RatingInfo;
