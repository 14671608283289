import React from "react";
import "@ag-grid-community/styles/ag-grid.css";
import "./ag-theme-axya.css";
import { Box, CircularProgress, Grid } from "@mui/material";
import { defaultColumnDef } from "./quote-comparison.columns";
import { makeCellSelectedById } from "./quote-comparison.utils";
import { QuoteComparisonAwardDrawer } from "../../modals/quote-comparison-award-drawer/quote-comparison-award-drawer";
import { QuoteComparisonCustomizeViewModal } from "../../modals/quote-comparison-customize-view-modal/quote-comparison-customize-view-modal";
import { useQuoteComparisonData } from "./quote-comparison.hooks";
import { QuoteComparisonContextProvider } from "./quote-comparison.context";
import { useDispatch } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "../../modals/types";
import { AgGridReact } from "@ag-grid-community/react";
import { Divider } from "@material-ui/core";
import QuoteComparisonHeaderContainer from "./quote-comparison-header-container";
import QuoteComparisonTableView from "./quote-comparison-table-view";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";

export const QuoteComparison: React.FC<{ isAwardedRfq?: boolean }> = ({ isAwardedRfq }) => {
  const dispatch = useDispatch();

  const quoteComparisonData = useQuoteComparisonData({ isAwardedRfq });
  const {
    columnView,
    gridData,
    setGridData,
    loading,
    autoSelectLowestPrice,
    selectedData,
    switchQuoteComparisonView,
  } = quoteComparisonData;
  const { config } = gridData;

  const onClickAwardSelection: any = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.QUOTE_COMPARISON_AWARD_DRAWER,
      })
    );
  };

  const onClickCustomizeView = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.QUOTE_COMPARISON_CUSTOMIZE_VIEW_MODAL,
      })
    );
  };

  const onCellClicked = (params: any) => {
    if (!params?.value?.id || isAwardedRfq) {
      return null;
    }

    const newRowsWithSelected = makeCellSelectedById(
      gridData?.rows,
      params?.data?.id,
      params?.value?.id
    );

    setGridData({
      ...gridData,
      rows: newRowsWithSelected,
    });
  };

  if (loading) {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item>
          <Box mt="48px">
            <CircularProgress />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <QuoteComparisonContextProvider
      value={{
        ...quoteComparisonData,
        onClickAwardSelection,
        onClickCustomizeView,
        autoSelectLowestPrice,
        isAwardedRfq,
      }}
    >
      <Box ml="-54px" mr="-32px">
        <QuoteComparisonHeaderContainer
          selectedData={selectedData}
          config={config}
          autoSelectLowestPrice={autoSelectLowestPrice}
          onClickCustomizeView={onClickCustomizeView}
          onClickAwardSelection={onClickAwardSelection}
          isAwardedRfq={isAwardedRfq}
          columnView={columnView}
          setColumnView={switchQuoteComparisonView}
        />

        <Divider />
        {columnView ? (
          <Box height="calc(100vh - 110px)" className="ag-theme-axya">
            <AgGridReact
              rowData={gridData?.rows}
              columnDefs={gridData?.columns as any}
              defaultColDef={defaultColumnDef}
              headerHeight={212}
              onCellClicked={onCellClicked}
              rowHeight={32}
              suppressCellFocus
              suppressScrollOnNewData
              suppressDragLeaveHidesColumns
              enableCellTextSelection
              modules={[ClientSideRowModelModule] as any}
            />

            <QuoteComparisonCustomizeViewModal />
          </Box>
        ) : (
          <QuoteComparisonTableView gridData={gridData} />
        )}
      </Box>
      <QuoteComparisonAwardDrawer />
    </QuoteComparisonContextProvider>
  );
};
