import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  Typography,
} from "@mui/material";
import ReleaseDropDown from "../components/release-dropdown";
import { useAnnouncementsData, useSelectedAnnouncementData } from "../pages/announcement-hooks";
import { CustomCircularProgress } from "@next/components/custom-circular-progress";
import { createStyles, makeStyles } from "@mui/styles";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  slug: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      minHeight: "80vh",
      maxHeight: "80vh",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

const ReleaseModal: React.FC<ModalProps> = ({ open = true, onClose, slug }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { announcements, announcementsLoading } = useAnnouncementsData();
  const [selectedOption, setSelectedOption] = React.useState(slug);
  const { selectedAnnouncement, selectedAnnouncementLoading } =
    useSelectedAnnouncementData(selectedOption);

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value as string);
  };

  useEffect(() => {
    if (slug) setSelectedOption(slug);
  }, [slug]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="md"
      aria-labelledby="release-dialog-title"
      classes={{ paper: classes.dialogPaper }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle id="release-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">{t("common:whatsNew")}</Typography>
          <ReleaseDropDown
            selectedValue={selectedOption}
            handleChange={handleOptionChange}
            isLoading={announcementsLoading}
            options={announcements}
          />
        </Box>
      </DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        {selectedAnnouncementLoading ? (
          <Box display="flex" width="100%" height="100%" flexGrow={1} alignItems="center">
            <CustomCircularProgress />
          </Box>
        ) : (
          selectedAnnouncement && (
            <DialogContentText>
              <div
                dangerouslySetInnerHTML={{
                  __html: selectedAnnouncement.content,
                }}
              />
            </DialogContentText>
          )
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("common:gotIt")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReleaseModal;
