import { axiosApi } from "@next/api";
import { backendUrl } from "urls";
import { FetchCompanyCapabilitiesTypesInput } from "../redux";

const saveFile = ({ capabilityId, file }: { capabilityId: number; file: File }) => {
  const formData = new FormData();
  formData.append("file", file);

  return axiosApi.post(`${backendUrl.companyCapabilities}${capabilityId}`, formData);
};

const deleteFile = ({ capabilityId }: { capabilityId: number }) => {
  return axiosApi.delete(`${backendUrl.companyCapabilities}${capabilityId}`);
};

const getCapabilitiesTypes = ({ type }: FetchCompanyCapabilitiesTypesInput) => {
  const query = type ? `?type=${type}` : "";
  return axiosApi.get(`${backendUrl.companyCapabilitiesTypes}${query}`);
};

const patchCompanyCapabilities = ({
  companyId,
  ...data
}: {
  companyId: number;
  capabilities: number[];
  capabilities_comment: string;
}) => {
  let formData = new FormData();
  data.capabilities && formData.append("capabilities", JSON.stringify(data.capabilities));
  data.capabilities_comment && formData.append("capabilities_comment", data.capabilities_comment);

  return axiosApi.patch<Partial<any>>(`${backendUrl.company}/${companyId}`, formData);
};

export const companyCapabilitiesService = {
  saveFile,
  deleteFile,
  getCapabilitiesTypes,
  patchCompanyCapabilities,
};
