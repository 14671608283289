export const createErrorMessage = (
  error: any,
  fallbackMsg: string = "Unexpected Error"
): string | undefined => {
  let errorMsg: string | undefined = "Unexpected Error";

  if (error?.response?.data) return createErrorMessage(error?.response?.data, fallbackMsg);

  try {
    if (typeof error === "string") {
      //string
      try {
        // Handling 401 errors in axios api
        if (error?.includes("Given token not valid")) {
          return undefined;
        }

        const parsed = JSON.parse(error);
        errorMsg = createErrorMessage(parsed);
      } catch (catchError) {
        errorMsg = error;
      }
    } else if (Array.isArray(error)) {
      //array
      errorMsg = createErrorMessage(error[0]);
    } else if (error !== null && typeof error === "object") {
      //object
      const value = Object.values(error)[0];
      errorMsg = createErrorMessage(value);
    } else {
      const responseData = error?.response?.data;

      if (!!error.isAxiosError && !error.response) {
        errorMsg = "Network Error";
      } else if (responseData?.error?.code) {
        errorMsg = responseData?.error.message;
      } else if (responseData?.error?.length) {
        errorMsg = responseData?.error;
      } else if (responseData?.message) {
        errorMsg = responseData?.message?.join();
      } else if (typeof responseData === "string") {
        errorMsg = responseData;
      } else {
        errorMsg = error?.response?.statusText;
      }
    }
  } catch (catchError) {
    errorMsg = error?.message || fallbackMsg;
  }

  if (typeof errorMsg !== "string") {
    errorMsg = fallbackMsg;
  }

  return errorMsg;
};
