import React, { useCallback, useMemo } from "react";

import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  GridColDef,
  GridColumns,
  GridEditCellProps,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import {
  CreateMultiContractState,
  CreateMultiContractTableField,
  SupplierKeyValue,
} from "./create-multi-contract-table.types";
import { getSummaryItemTitleIcons } from "./create-multi-contract-summary-item.utils";
import { t } from "assets/configi18n/i18n";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 1,
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
};

export const PartNameCell: React.FC<GridRenderCellParams> = (params: GridValueGetterParams) => {
  return (
    <Box display={"flex"} justifyContent="space-between" alignItems={"center"} width="100%">
      <Typography variant="body2">{params.row?.part?.name}</Typography>
      <ArrowForwardIcon style={{ width: 12, height: 12 }} />
    </Box>
  );
};

const SupplierSelectPlaceholder = () => {
  return (
    <Typography variant="body2" color="textSecondary">
      {t("project:multiContract:selectAnOption")}
    </Typography>
  );
};

const SupplierSelectCell: React.FC<GridEditCellProps> = ({
  id,
  field,
  api,
  value = "",
  suppliers,
  getValue,
  filterSuppliers,
}) => {
  const onChange = useCallback(
    (event: SelectChangeEvent<any>) => {
      if (api) {
        api.setEditCellValue(
          {
            id,
            field,
            value: event.target.value,
          },
          event
        );

        api.commitCellChange({ id, field });

        api.setCellMode(id, field, "view");
      }
    },
    [id, field, api]
  );

  const filteredSuppliers = useMemo(() => {
    if (!filterSuppliers) return suppliers;
    const part = getValue(id, "part");
    return suppliers?.filter(
      (item: SupplierKeyValue) =>
        item.value < 0 || (item.parts?.find((p) => p.part_id === part.pk)?.quantity || 0) > 0
    );
  }, [suppliers, getValue, id, filterSuppliers]);

  return (
    <Select
      value={value}
      fullWidth
      displayEmpty
      disableUnderline
      onChange={onChange}
      renderValue={value === "" ? () => <SupplierSelectPlaceholder /> : undefined}
    >
      {filteredSuppliers?.map((supplier: SupplierKeyValue, index: number) => {
        return (
          <MenuItem
            value={supplier?.value}
            divider={suppliers[index + 1]?.value === CreateMultiContractState.CANCEL}
            style={{ height: "40px" }}
          >
            <Box display="flex" alignItems="center">
              <ListItemIcon style={{ minWidth: "32px" }}>
                {getSummaryItemTitleIcons(supplier)}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ variant: "body2" }}
                primary={supplier?.label}
              />
            </Box>
          </MenuItem>
        );
      })}
    </Select>
  );
};
export const getCreateMultiContractTableColumns = (
  suppliers: SupplierKeyValue[] = [],
  filterSuppliers?: boolean
) => {
  const _columns: GridColumns = [
    {
      ...defaultColumnConfig,
      field: CreateMultiContractTableField.PART,
      headerName: t("project:multiContract:part"),
      renderCell: PartNameCell,
    },
    {
      ...defaultColumnConfig,
      field: CreateMultiContractTableField.SUPPLIER_ID,
      headerName: t("project:multiContract:selection"),
      renderCell: (params: GridRenderCellParams) => (
        <SupplierSelectCell {...params} suppliers={suppliers} filterSuppliers={filterSuppliers} />
      ),
    },
  ];

  return _columns;
};
