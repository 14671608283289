/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectOrder, selectOrderLoading } from "../../redux/selectors";
import { ordersActions } from "../../redux";
import { useParams } from "react-router-dom";

export const useOrderDetailData = () => {
  const dispatch = useDispatch();
  const params = useParams<{ pk: string }>();

  const orderDetail = useSelector(selectOrder);
  const orderDetailLoading = useSelector(selectOrderLoading);

  useEffect(() => {
    if (params.pk) {
      dispatch(ordersActions.fetchOrderRequest({ pk: Number(params.pk) }));
    }
  }, [params.pk]);

  return {
    orderDetail,
    orderDetailLoading,
  };
};
