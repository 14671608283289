import React from "react";
import { Box, styled } from "@mui/material";
import { ValidationManagementAppBar } from "../components/validation-management/validation-management-app-bar/validation-management-app-bar";
import { ValidationManagementBody } from "../components/validation-management/validation-management-body/validation-management-body";

const StyledBox = styled(Box)({
  width: "100% !important",
});

export const ValidationManagement = () => {
  return (
    <StyledBox>
      <StyledBox>
        <ValidationManagementAppBar />

        <ValidationManagementBody />
      </StyledBox>
    </StyledBox>
  );
};
