import { requestDemoApi } from "./request-demo.api";
import { requestDemoConstants } from "./request-demo.constants";
import { genericErrorHandler } from "@next/utils/apiUtils";

const requestDemo = (email, company) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    dispatch(request());
    requestDemoApi.requestDemo(email, company).then(
      (data) => {
        dispatch(success(data));
        resolve(data);
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
        reject(error);
      }
    );
  });

  function request() {
    return { type: requestDemoConstants.BOOK_DEMO_REQUEST };
  }
  function success(data) {
    return { type: requestDemoConstants.BOOK_DEMO_SUCCESS, ...data };
  }
  function failure(error) {
    return { type: requestDemoConstants.BOOK_DEMO_FAILURE, error };
  }
};

export const requestDemoActions = {
  requestDemo,
};
