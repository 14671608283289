import React from "react";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { SearchTextField } from "../../search-text-field/search-text-field";
import { ValidationManagementStatusEnum } from "@next/modules/vendor-management/redux";
import * as S from "./validation-management-search-and-filter.styled";

type Props = {
  searchValue: string | null;
  setSearchValue: (value: string | null) => void;
  clearSearch: () => void;
  statusValue: string | null;
  handleStatusChange: (event: SelectChangeEvent<string>) => void;
};

export const ValidationManagementSearchAndFilter: React.FC<Props> = ({
  searchValue,
  setSearchValue,
  clearSearch,
  statusValue,
  handleStatusChange,
}) => {
  const statusOptions = Object.keys(ValidationManagementStatusEnum) as Array<
    keyof typeof ValidationManagementStatusEnum
  >;

  return (
    <S.StyledBox>
      <SearchTextField
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        clearSearch={clearSearch}
        placeholder="Search supplier..."
        width="240px"
      />

      <FormControl>
        <Select
          id="status-select"
          value={statusValue}
          multiple={false}
          displayEmpty
          onChange={handleStatusChange}
          placeholder="All statuses"
          sx={{ width: "320px", height: "40px" }}
          renderValue={(selected) => {
            if (!selected || selected === "") {
              return <span style={{ color: "#aaa" }}>All statuses</span>;
            } else {
              return ValidationManagementStatusEnum[selected];
            }
          }}
        >
          <MenuItem key={"All"} value={""}>
            All statuses
          </MenuItem>

          {statusOptions.map((key) => (
            <MenuItem key={key} value={key}>
              {ValidationManagementStatusEnum[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </S.StyledBox>
  );
};
