import * as yup from "yup";
import { t } from "assets/configi18n/i18n";

export type TwoFactorManageFormValues = {
  code: string;
};

export const initialValues: TwoFactorManageFormValues = {
  code: "",
};

export const validationSchema = yup.object().shape({
  code: yup.string().required(t("common:required")),
});
