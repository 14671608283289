import { Box, Container, Divider, Theme, Typography } from "@mui/material";
import { GridOverlay } from "@mui/x-data-grid-pro-v5";
import React from "react";
import { getRowIdToPk } from "@next/utils/dataGridUtils-v5";
import { useSupplierInvitationsTableData } from "./supplier-invitations-table.hooks";
import InboxIcon from "@mui/icons-material/Inbox";
import { useTranslation } from "react-i18next";
import { ConnectionRequestsModal } from "@next/modules/workspace/modals/connection-requests-modal";
import { GroupingAfterConnectionModal } from "@next/modules/workspace/modals/grouping-after-connection-modal";
import { createStyles, makeStyles } from "@mui/styles";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dataGrid: {
      "& .MuiDataGrid-columnsContainer": {
        display: "none",
      },
    },
    noRowContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2),
      marginTop: "60px",
    },
    inboxContainer: {
      padding: theme.spacing(2),
      borderRadius: 8,
      border: `1px solid ${theme.palette.divider}`,
    },
  })
);

export const SupplierInvitationsTable = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { gridData, count, setCurrentPage, loading, pageSize } = useSupplierInvitationsTableData({
    isBuyerPerspective: true,
  });

  return (
    <Container maxWidth="md">
      <Box>
        <Box mb={3}>
          <Typography variant="h6">{t("workspaceNext:invitationsTab:tableTitle")}</Typography>
        </Box>
        <Box width={"100%"}>
          <DataGridProV5
            className={classes.dataGrid}
            autoHeight
            autoPageSize
            pagination={gridData?.rows.length > 0}
            paginationMode="server"
            rows={gridData?.rows || []}
            columns={gridData?.columns || []}
            disableSelectionOnClick
            disableColumnResize
            headerHeight={0}
            rowHeight={88}
            pageSize={pageSize}
            rowCount={count}
            onPageChange={(newPage) => setCurrentPage(newPage + 1)}
            loading={loading}
            getRowId={getRowIdToPk}
            components={{
              NoRowsOverlay: () => {
                return (
                  <GridOverlay>
                    <Box className={classes.noRowContainer}>
                      <Box className={classes.inboxContainer}>
                        <InboxIcon />
                      </Box>
                      <Typography variant="h6">
                        {t("workspaceNext:invitationsTab:noInvitationsFound")}
                      </Typography>
                    </Box>
                  </GridOverlay>
                );
              },
            }}
          />
          <Divider light />
        </Box>
      </Box>
      <ConnectionRequestsModal />
      <GroupingAfterConnectionModal />
    </Container>
  );
};
