import React from "react";
import { useTranslation } from "react-i18next";

import { Form, FormikProps } from "formik";
import { Button, Grid } from "@mui/material";
import { GenericTextInput } from "components/utils/input/text";
import { ChangePasswordFormValues } from "./change-password-form.types";
import { useSelector } from "react-redux";
import { selectUpdateUserPasswordLoading } from "@next/modules/profile/redux/selectors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export const ChangePasswordFormBase: React.FC<FormikProps<ChangePasswordFormValues>> = ({
  values,
  handleSubmit,
  dirty,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const updateUserPasswordLoading = useSelector(selectUpdateUserPasswordLoading);

  return (
    <Form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GenericTextInput
            name="old_password"
            label={t("profile:inputs:currentPassword")}
            value={values.old_password}
            type="password"
            autoComplete="current-password"
          />
        </Grid>

        <Grid item xs={12}>
          <GenericTextInput
            name="new_password1"
            label={t("profile:inputs:newPassword")}
            value={values.new_password1}
            type="password"
            autoComplete="new-password"
          />
        </Grid>

        <Grid item xs={12}>
          <GenericTextInput
            name="new_password2"
            label={t("profile:inputs:confirmNewPassword")}
            value={values.new_password2}
            type="password"
            autoComplete="new-password"
          />
        </Grid>

        <Grid item xs={12} className={classes.flexEnd}>
          <Button variant="outlined" type="submit" disabled={updateUserPasswordLoading || !dirty}>
            {t("common:save")}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};
