import React from "react";

// MaterialUI specific Import
import { Button, Grid } from "@mui/material";

import { SimpleTextField } from "components/utils/text-field/text-field.component";

// Library Components
import { FormHeader } from "components/utils/form-header/form-header";
import { FormikProps } from "formik";
import { TRecoverPasswordFormState } from "./recover-password-definitions";
import { useTranslation } from "react-i18next";

export const ResendEmailFormBase: React.FC<FormikProps<TRecoverPasswordFormState>> = ({
  values,
  errors,
  touched,
  handleSubmit,
  handleChange,
}) => {
  const { t } = useTranslation();
  // Deconstruct the values from formik
  const { email } = values;

  return (
    <form onSubmit={handleSubmit} className="c-recover-password-form">
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid container item xs={9} className="c-recover-password-form--header">
          <FormHeader
            title={t("auth:resendEmailForm:emailNotReceivedTitle")}
            body={t("auth:resendEmailForm:emailNotReceivedBody")}
          />
        </Grid>

        <Grid container item xs={9} className="c-recover-password-form--field">
          <SimpleTextField
            name="email"
            value={email}
            label={t("auth:login:email")}
            placeholder={t("auth:login:email")}
            helperText={touched.email ? errors.email : ""}
            error={touched.email && Boolean(errors.email)}
            onChange={handleChange}
          />
        </Grid>

        <Grid container item xs={9} className="c-recover-password-form--button">
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSubmit()}
            fullWidth={true}
          >
            {t("auth:actionsMenu:emailNotReceivedButton")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
