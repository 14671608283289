import React from "react";
import { FormControl, FormControlLabel, Switch } from "@mui/material";
import * as S from "./table-lines-box.styled";

type Props = {
  completedCount: number;
  totalCount: number;
  hideCompletedTableLines: boolean;
  setHideCompletedTableLines: React.Dispatch<React.SetStateAction<boolean>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const TableLinesBox: React.FC<Props> = ({
  completedCount,
  totalCount,
  hideCompletedTableLines,
  setHideCompletedTableLines,
  setPage,
}) => {
  const handleSwitchChange = () => {
    setPage(1);
    setHideCompletedTableLines((prev) => !prev);
  };

  return (
    <S.StyledBox>
      <S.StyledTypography variant="body1" tooltipTitle={null}>
        Lines completed: {completedCount}/{totalCount}
      </S.StyledTypography>

      <FormControl>
        <FormControlLabel
          checked={hideCompletedTableLines}
          onChange={handleSwitchChange}
          control={<Switch color="primary" />}
          label="Hide completed lines"
          labelPlacement="end"
        />
      </FormControl>
    </S.StyledBox>
  );
};
