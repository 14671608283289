import React from "react";

import { Checkbox, Link, Tooltip } from "@mui/material";
import { t } from "assets/configi18n/i18n";
import { Autocomplete } from "@mui/material";
import CurrencyField from "components/common/fields/CurrencyField";
import { getIdsByData } from "@next/utils/dataGridUtils-v5";
import {
  addTypeAttribute,
  checkIsColumnHidden,
  isNewRowId,
  normalizeMaterialData,
} from "./project.part.table.utils";
import { store } from "store";
import { getSelectedLanguage } from "@next/utils/browserUtils";
import { ProjectsPartField } from "./types";
import {
  GridApi,
  GridCellParams,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro-v5";
import { PartExtraField, ProjectCompanyPart } from "../../redux";
import { CustomTypography } from "@next/components/custom-typography";
import IconButtonCell from "@next/components/table-cells/icon-button-cell-v5";
import ClearIcon from "@mui/icons-material/Clear";
import { FileCell } from "@next/components/table-cells/file-cell-v5";
import { frontendUrl } from "urls";

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: false,
  sortable: false,
  hideSortIcons: true,
  filterable: true,
  resizable: true,
};

const NameCell: React.FC<GridCellParams & { api: GridApi }> = ({ api, value, id }) => {
  if (isNewRowId(id) && api) {
    api.setCellMode(id, "name", "edit");
  }

  return <CustomTypography variant="caption">{value}</CustomTypography>;
};

export const QuantityCell: React.FC<GridCellParams> = ({ value }) => {
  return <CustomTypography variant="caption">{value}</CustomTypography>;
};

const ProcessesCell: React.FC<GridCellParams> = (params, isRFQEditMode) => {
  const { row } = params;
  let displayValue = row?.processes?.length > 0 ? row?.processes[0]?.name : "n/a";
  if (isRFQEditMode && row?.processes?.length) {
    const state = store.getState();
    const processes = state.partConfiguration.processes;
    const processedData = addTypeAttribute(processes);
    const selectedProcess = processedData.find((process) => process.id === row?.processes[0]);
    if (selectedProcess) {
      displayValue = selectedProcess.name;
    }
  }

  return <CustomTypography variant="caption">{displayValue}</CustomTypography>;
};

const ProcessesEditCell: React.FC<GridRenderEditCellParams> = (params) => {
  const state = store.getState();
  const processes = state.partConfiguration.processes;
  const processedData = addTypeAttribute(processes);

  return <AutoCompleteEditCell {...params} data={processedData} />;
};

const AlloyCell: React.FC<GridCellParams> = (params, isRFQEditMode) => {
  let displayValue = params.row.alloy ? params.row.alloy : "n/a";
  if (isRFQEditMode) {
    const state = store.getState();
    const materials = state.partConfiguration.materials;
    const materialData = normalizeMaterialData(materials);

    const selectedMaterial = materialData.find((material) => material.id === params.row.alloy);
    if (selectedMaterial) {
      displayValue = selectedMaterial.name;
    }
  }

  return <CustomTypography variant="caption">{displayValue}</CustomTypography>;
};

const AlloyEditCell: React.FC<GridRenderEditCellParams> = (params) => {
  const state = store.getState();
  const materials = state.partConfiguration.materials;
  const materialData = normalizeMaterialData(materials);

  return <AutoCompleteEditCell {...params} data={materialData} />;
};

const AdditionalDetailsCell: React.FC<GridCellParams> = ({ value }) => {
  return (
    <Tooltip title={value as string}>
      <CustomTypography className="c-ellipsis" variant="caption">
        {value}
      </CustomTypography>
    </Tooltip>
  );
};

const RFQDisplayNameCell: React.FC<GridRenderCellParams> = ({ value }) => {
  if (!value) {
    return null;
  }
  return (
    <CustomTypography>
      <Link href="#">{value}</Link>
    </CustomTypography>
  );
};

export const UnitPriceCell: React.FC<GridCellParams> = (params) => {
  return params.value ? (
    <CustomTypography variant="caption">
      <CurrencyField value={params.value} />
    </CustomTypography>
  ) : (
    <CustomTypography variant="caption" className="not-available">
      n/a
    </CustomTypography>
  );
};

export const getColumns = (
  partExtraFields: PartExtraField[] = [],
  pmPartsHiddenColumns: string[],
  readOnly?: boolean,
  fromRfq?: boolean,
  isRFQEditMode?: boolean,
  editable?: boolean,
  hidePriceColumn?: boolean
) => {
  const _columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: ProjectsPartField.NAME,
      hide: checkIsColumnHidden(pmPartsHiddenColumns, ProjectsPartField.NAME),
      headerName: t("project:table:header:partNumber"),
      minWidth: 200,
      editable: editable,
      renderCell: NameCell,
      type: "string",
      flex: 1,
    },
    {
      ...defaultColumnConfig,
      field: ProjectsPartField.FILES,
      hide: checkIsColumnHidden(pmPartsHiddenColumns, ProjectsPartField.FILES),
      minWidth: 150,
      headerName: t("project:table:header:files"),
      renderCell: (params) => (
        <FileCell
          {...params}
          readOnly={readOnly}
          isRFQEditMode={isRFQEditMode}
          isRFQPart={
            fromRfq &&
            !isRFQEditMode &&
            window.location.pathname.endsWith(frontendUrl.buyerQuotes) === false &&
            !window.location.pathname.includes(frontendUrl.projects)
          }
        />
      ),
      disableExport: true,
      editable: editable,
      filterable: false,
    },
    {
      ...defaultColumnConfig,
      field: ProjectsPartField.QUANTITY,
      hide: checkIsColumnHidden(pmPartsHiddenColumns, ProjectsPartField.QUANTITY),
      align: "right",
      headerName: t("project:table:header:quantity"),
      minWidth: 100,
      editable: editable,
      renderCell: QuantityCell,
      flex: 1,
      filterable: false,
    },
    {
      ...defaultColumnConfig,
      field: ProjectsPartField.ADDITIONAL_DETAILS,
      hide: checkIsColumnHidden(pmPartsHiddenColumns, ProjectsPartField.ADDITIONAL_DETAILS),
      minWidth: 150,
      headerName: t("project:table:header:description"),
      editable: editable,
      renderCell: AdditionalDetailsCell,
      flex: 1,
      filterable: false,
    },
    {
      ...defaultColumnConfig,
      field: ProjectsPartField.ALLOY,
      hide: checkIsColumnHidden(pmPartsHiddenColumns, ProjectsPartField.ALLOY),
      headerName: t("project:table:header:material"),
      editable: editable,
      minWidth: 150,
      renderCell: (params) => AlloyCell(params, isRFQEditMode),
      renderEditCell: AlloyEditCell,
      disableExport: true,
      flex: 1,
      filterable: false,
    },
    {
      ...defaultColumnConfig,
      field: ProjectsPartField.PROCESSES,
      hide: checkIsColumnHidden(pmPartsHiddenColumns, ProjectsPartField.PROCESSES),
      headerName: t("project:table:header:process"),
      editable: editable,
      minWidth: 150,
      renderCell: (params) => ProcessesCell(params, isRFQEditMode),
      renderEditCell: ProcessesEditCell,
      disableExport: true,
      flex: 1,
      filterable: false,
    },
  ];

  if (partExtraFields?.length > 0) {
    partExtraFields.forEach((field) => {
      const selectedLanguage = getSelectedLanguage();

      _columns.push({
        ...defaultColumnConfig,
        disableExport: true,
        editable: editable,
        minWidth: field.width ?? 150,
        flex: 1,
        field: `extra_fields.${field.slug}`,
        hide: checkIsColumnHidden(pmPartsHiddenColumns, field.slug as any),
        headerName: field.display_name[selectedLanguage as keyof typeof field.display_name],
        type: field.type,
        valueOptions: field.valueOptions,
        valueGetter: (_params: GridValueGetterParams) => {
          const field_parts = _params.field.split(".");
          return _params.row.extra_fields?.[field_parts[1]];
        },
        renderCell: (params) => {
          return <CustomTypography>{params?.value}</CustomTypography>;
        },
      });
    });
  }

  _columns.push({
    ...defaultColumnConfig,
    field: ProjectsPartField.DISPLAY_NAME,
    hide: fromRfq || checkIsColumnHidden(pmPartsHiddenColumns, ProjectsPartField.DISPLAY_NAME),
    minWidth: 200,
    headerName: t("project:table:header:rfq"),
    renderCell: (params) => {
      if (!params.row?.rfq?.display_name) {
        return null;
      }

      return (
        <RFQDisplayNameCell value={params.row.rfq.display_name} {...params.row.rfq.display_name} />
      );
    },
    disableExport: true,
    flex: 1,
    filterable: false,
  });
  if (isRFQEditMode) {
    _columns.push({
      ...defaultColumnConfig,
      field: ProjectsPartField.EDIT_BUTTON,
      headerName: " ",
      width: 40,
      align: "center",
      renderCell: (params) => <IconButtonCell Icon={ClearIcon} {...params} />,
      editable: editable,
      disableExport: true,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
    });
  } else {
    _columns.push({
      ...defaultColumnConfig,
      field: ProjectsPartField.EDIT_BUTTON,
      hide: checkIsColumnHidden(pmPartsHiddenColumns, ProjectsPartField.EDIT_BUTTON),
      headerName: " ",
      width: 40,
      align: "center",
      renderCell: IconButtonCell,
      disableExport: true,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
    });
  }
  if (!hidePriceColumn) {
    _columns.push({
      ...defaultColumnConfig,
      field: ProjectsPartField.PRICE,
      hide: checkIsColumnHidden(pmPartsHiddenColumns, ProjectsPartField.PRICE),
      align: "right",
      headerAlign: "right",
      minWidth: 150,
      headerName: t("project:table:header:priceUnit"),
      renderCell: UnitPriceCell,
      disableExport: true,
      editable: editable,
      flex: 1,
      filterable: false,
    });
  }
  return _columns;
};

type AutoCompleteEditCellProps = (GridCellParams | GridRenderEditCellParams) & {
  data: any;
  api: GridApi;
};

const AutoCompleteEditCell: React.FC<AutoCompleteEditCellProps> = ({ api, id, field, data }) => {
  const handleChange = (event: any, newValue: any) => {
    if (!newValue) {
      return;
    }
    event.target.value = newValue.id;
    api.setEditCellValue({ id, field, value: Number(event.target.value) }, event);
    // Check if the event is not from the keyboard
    // https://github.com/facebook/react/issues/7407
    if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
      api.commitCellChange({ id, field });
      api.setCellMode(id, field, "view");
    }
  };

  const handleRef = (element: any) => {
    if (element) {
      element.querySelector(`input`).focus();
    }
  };
  return (
    <Autocomplete
      ref={handleRef}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      isOptionEqualToValue={(item, current) => item.name === current.name}
      onChange={handleChange}
      groupBy={(option) => option.type}
      options={data}
      getOptionLabel={(option: any) => option.name || ""}
      style={{ width: 300 }}
      renderInput={(params) => (
        <div ref={params.InputProps.ref} style={{ height: "100%" }}>
          <input style={{ width: "100%", height: "100%" }} type="text" {...params.inputProps} />
        </div>
      )}
    />
  );
};

export const getProjectPartTableCheckboxColumn = (
  onCheckboxChange: (params: {
    row?: GridRenderCellParams["row"];
    selectAll?: boolean;
  }) => () => void,
  selectedPartRows: ProjectCompanyPart[],
  loading?: boolean,
  projectCompanyPartsSelectAll?: boolean,
  disableSelectAll?: boolean
) => {
  const selectedPartRowsIds = getIdsByData(selectedPartRows, "pk");

  const checkboxColumn: GridColumns = [
    {
      field: ProjectsPartField.SELECTED_CHECKBOX,
      headerName: " ",
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      sortable: false,
      hideSortIcons: true,
      filterable: false,
      width: 40,
      renderHeader: () => {
        return (
          <div>
            <Checkbox
              onChange={onCheckboxChange({
                selectAll: selectedPartRows.length > 0 ? false : !projectCompanyPartsSelectAll,
              })}
              checked={projectCompanyPartsSelectAll}
              color="primary"
              indeterminate={projectCompanyPartsSelectAll ? false : selectedPartRows.length > 0}
              disabled={loading || disableSelectAll}
            />
          </div>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Checkbox
            onChange={onCheckboxChange({ row: params?.row, selectAll: false })}
            checked={projectCompanyPartsSelectAll || selectedPartRowsIds.includes(params?.row?.pk)}
            color="primary"
            disabled={loading || params?.row?.rfq}
          />
        );
      },
    },
  ];

  return checkboxColumn;
};
