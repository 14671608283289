import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import {
  A2psThreadLabel,
  MessageThread,
  PosEndUsersFormKeyMapping,
  Step,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectIsShowReviewBoxForThreadfulReview,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./side-drawer-thread-card.styled";

type Props = {
  onClick?: () => void;
} & MessageThread;

export const SideDrawerThreadCard: React.FC<Props> = ({
  id,
  title,
  is_resolved,
  entity_name,
  line_deleted,
  supplier_title,
  onClick,
}) => {
  const dispatch = useDispatch();
  const isShowReviewBoxForThreadfulReview = useSelector(selectIsShowReviewBoxForThreadfulReview);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const activeStep: Step = steps[activeFormStepIndex];
  const threadLabel =
    activeStep?.step_name === PosEndUsersFormKeyMapping.A2psProcessControl
      ? A2psThreadLabel[entity_name]
      : null;

  const onClickLeftIcon = () => {
    dispatch(vendorManagementActions.setCurrentMessageThread(null));
    dispatch(vendorManagementActions.setIsShowReviewBoxForThreadfulReview(false));
    dispatch(vendorManagementActions.setIsFetchSpecByClickedThread(false));
    dispatch(vendorManagementActions.setNameOfTableToFetchSpecByClickedThread(null));
  };

  const onClickCard = () => {
    if (!isShowReviewBoxForThreadfulReview) {
      onClick();
    } else {
      return;
    }
  };

  return (
    <S.StyledCardBox
      onClick={onClickCard}
      isShowReviewBoxForThreadfulReview={isShowReviewBoxForThreadfulReview}
    >
      <S.StyledInnerBox>
        {isShowReviewBoxForThreadfulReview ? (
          <IconButton sx={{ width: "24px", height: "24px" }} onClick={onClickLeftIcon}>
            <S.StyledLeftIcon />
          </IconButton>
        ) : null}

        {is_resolved ? <S.StyledCheckIcon /> : <S.StyledCircle />}

        <S.StyledTypography variant="body1" tooltipTitle={null}>
          {title}
        </S.StyledTypography>

        {threadLabel ? (
          <S.StyledLabelTypography variant="body1" tooltipTitle={null}>
            {threadLabel}
          </S.StyledLabelTypography>
        ) : (
          <S.StyledLabelTypography variant="body1" tooltipTitle={null}>
            {supplier_title}
          </S.StyledLabelTypography>
        )}
      </S.StyledInnerBox>

      <S.StyledInnerBox>
        {line_deleted ? <S.StyledChip label={"Line removed"} /> : null}

        {!isShowReviewBoxForThreadfulReview ? <S.StyledRightIcon /> : null}
      </S.StyledInnerBox>
    </S.StyledCardBox>
  );
};
