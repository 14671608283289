import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NewsfeedPost, NewsfeedState, fetchNewsfeedInput } from "./types";

export const initialState: NewsfeedState = {
  newsfeedLoading: false,
  newsfeedError: undefined,
  newsfeed: [],
  newsfeedCount: 0,
  newsfeedPage: 0,
  newsfeedIsNextPageAvailable: false,
};

const newsfeedSlice = createSlice({
  name: "newsfeedNext",
  initialState,
  reducers: {
    fetchNewsfeedRequest: (
      state,
      action: PayloadAction<fetchNewsfeedInput & { reset?: boolean }>
    ) => {
      state.newsfeedLoading = true;
      state.newsfeedError = undefined;
      if (action.payload.reset) {
        state.newsfeed = initialState.newsfeed;
        state.newsfeedCount = initialState.newsfeedCount;
        state.newsfeedPage = initialState.newsfeedPage;
        state.newsfeedIsNextPageAvailable = initialState.newsfeedIsNextPageAvailable;
      }
    },
    fetchNewsfeedError: (state, action: PayloadAction<Error>) => {
      state.newsfeedLoading = false;
      state.newsfeedError = action.payload;
    },
    fetchNewsfeedSuccess: (
      state,
      action: PayloadAction<{
        results: NewsfeedPost[];
        count: number;
        page: number;
        next: string;
      }>
    ) => {
      state.newsfeedLoading = false;
      state.newsfeedError = undefined;
      state.newsfeed = state.newsfeed!.concat(action.payload.results);
      state.newsfeedCount = action.payload.count;
      state.newsfeedPage = action.payload.page;
      state.newsfeedIsNextPageAvailable = !!action.payload.next;
    },
  },
});

export const newsfeedActions = newsfeedSlice.actions;

export default newsfeedSlice.reducer;
