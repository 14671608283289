import React, { useCallback } from "react";

import { batch, useDispatch } from "react-redux";
import { Formik } from "formik";

import { workspaceActions } from "services/workspace";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { getPlainAndRichTextAsString } from "@next/components/rich-text-editor/rich-text-editor-utils";
import { RfqDescriptionFormBase, RfqDescriptionFormValue } from "./rfq-description-form.base";

interface RfqDescriptionProps {
  pk: number;
  additionalDetailsRichText?: string;
}

const RfqDescription: React.FC<RfqDescriptionProps> = ({ pk, additionalDetailsRichText }) => {
  const dispatch = useDispatch();

  const initialValues: RfqDescriptionFormValue = {
    additionalDetails: additionalDetailsRichText,
  };

  const handleRfqUpdatePartial = useCallback(
    (values) => {
      const { richText, plainText } = getPlainAndRichTextAsString(values.additionalDetails);

      if (richText && plainText) {
        batch(() => {
          dispatch(
            workspaceActions.rfqUpdatePartial(pk, {
              additional_details_rich_text: richText,
              additional_details: plainText,
            })
          );
          dispatch(workspaceNextActions.setEditRfqDescription(false));
        });
      }
    },
    [dispatch, pk]
  );

  return (
    <>
      <Formik
        render={(props) => (
          <RfqDescriptionFormBase {...props} defaultValue={additionalDetailsRichText as string} />
        )}
        initialValues={initialValues}
        onSubmit={handleRfqUpdatePartial}
      />
    </>
  );
};

export default RfqDescription;
