export enum ProjectsField {
  CODE = "code",
  DISPLAY_NAME = "display_name",
  COMPANY = "company",
  TOTAL_PARTS_COUNT = "total_parts_count",
  PARTS_IN_RFQ_COUNT = "parts_in_rfq_count",
  PARTS_CONTRACTED_COUNT = "parts_contracted_count",
  TOTAL_PRICE = "total_price",
  FIXED_FEES = "fixed_fees",
  PARTS_CANCELLED_COUNT = "parts_cancelled_count",
  EDIT_BUTTON = "editButton",
}
