import { Avatar, Box, Card, CardActionArea, CardContent, Theme } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import React from "react";
import { BuyerProfile } from "../../redux";
import { getInitials } from "@next/utils/stringUtils";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    card: {
      width: "100%",
      height: "100%",
      boxShadow: "none",
      border: `1px solid ${theme.palette.divider}`,
    },
    actionArea: { height: "100%" },
    cardContent: {
      height: "100%",
      padding: "24px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    name: {
      fontWeight: 700,
    },
    logoAvatar: {
      width: "88px",
      height: "88px",
      borderRadius: "16px",
    },
  })
);

export const SellerDashboardActiveBuyerCard: React.FC<{
  buyerProfile?: BuyerProfile;
  onClickOpenProfile?: () => void;
}> = ({ buyerProfile, onClickOpenProfile }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea onClick={onClickOpenProfile}>
        <CardContent className={classes.cardContent}>
          <Avatar variant="square" className={classes.logoAvatar} src={buyerProfile?.picture}>
            {getInitials(buyerProfile?.name as string)}
          </Avatar>

          <Box mt="16px" display="flex" alignItems="center" justifyContent="center" maxWidth="100%">
            <CustomTypography variant="body1" className={classes.name}>
              {buyerProfile?.name}
            </CustomTypography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
