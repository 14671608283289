import * as yup from "yup";
import { t } from "assets/configi18n/i18n";

export type TCancelRfqFormState = {
  rfqCancellationReason: {
    pk?: number;
    reason?: string;
    reason_short?: string;
    hint_message?: string | null;
  };
  cancellationNote: string;
};

export const cancelRfqFormState: TCancelRfqFormState = {
  rfqCancellationReason: {},
  cancellationNote: "",
};

export const cancelRfqFormValidationSchema = yup.object({
  cancellationNote: yup.string().when("rfqCancellationReason", {
    is: (rfqCancellationReason) => {
      return rfqCancellationReason.reason !== t("quotation:noQuote");
    },
    then: yup.string().required(t("project:rfqDrawer:rfqForm:errorMessage:thisFieldIsRequired")),
  }),
});
