import { Box, Button, Grid, Typography } from "@mui/material";
import { Form, FormikProps } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PublicRFQFormValuesType } from "./public-rfq-landing-step-form.types";
import MultipleDNDFileUpload from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload";
import { CustomFileObject } from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload.types";
import { GenericTextInput } from "components/utils/input/text";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: "800px",
      width: "100%",
    },
    headline: {
      fontFamily: "Space Grotesk",
    },
  })
);

enum EPublicRfqLandingFormStep {
  STEP1,
  STEP2,
}

export const PublicRfqLandingStepFormBase: React.FC<FormikProps<PublicRFQFormValuesType>> = ({
  handleSubmit,
  errors,
  touched,
  values,
  setFieldValue,
  submitForm,
  isValid,
}) => {
  const [formStep, setFormStep] = useState<EPublicRfqLandingFormStep>(
    EPublicRfqLandingFormStep.STEP1
  );
  const classes = useStyles();
  const { t } = useTranslation();

  const renderFormStep = () => {
    switch (formStep) {
      case EPublicRfqLandingFormStep.STEP1:
        return (
          <>
            <Typography variant="h6" className={classes.headline}>
              {t("workspaceNext:publicRfqLanding:rightStep1Title")}
            </Typography>

            <Box mt="48px">
              <MultipleDNDFileUpload
                filesState={values.attachments as any}
                setFilesState={(files: CustomFileObject[]) => {
                  setFieldValue("attachments", files);
                }}
                disableDndMargin
                dndBackgroundColor="white"
              />

              <Box mt="24px">
                <Box style={{ backgroundColor: "white" }}>
                  <GenericTextInput
                    name="additional_details"
                    multiline
                    rows="8"
                    placeholder={t("workspaceNext:publicRfqLanding:notePlaceholder")}
                  />
                </Box>
              </Box>
            </Box>
          </>
        );
      case EPublicRfqLandingFormStep.STEP2:
        return (
          <>
            <Typography variant="h6" className={classes.headline}>
              {t("workspaceNext:publicRfqLanding:rightStep2Title")}
            </Typography>

            <Box mt="48px">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box style={{ backgroundColor: "white" }}>
                    <GenericTextInput
                      name="name"
                      label={t("workspaceNext:publicRfqLanding:yourName")}
                      size="small"
                      error={touched.name && !!errors.name}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box style={{ backgroundColor: "white" }}>
                    <GenericTextInput
                      name="email"
                      label={t("workspaceNext:publicRfqLanding:yourEmail")}
                      size="small"
                      type="email"
                      error={touched.email && !!errors.email}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  const onClickNext = () => {
    if (formStep === EPublicRfqLandingFormStep.STEP2) {
      submitForm();
    } else {
      setFormStep(EPublicRfqLandingFormStep.STEP2);
    }
  };

  const onClickBack = () => {
    setFormStep(EPublicRfqLandingFormStep.STEP1);
  };

  return (
    <Form onSubmit={handleSubmit} className={classes.root}>
      {renderFormStep()}

      <Box mt="24px" display="flex" justifyContent="center">
        {formStep !== EPublicRfqLandingFormStep.STEP1 ? (
          <Box mr="12px" style={{ backgroundColor: "white" }}>
            <Button variant="outlined" onClick={onClickBack}>
              {t("common:back")}
            </Button>
          </Box>
        ) : null}

        <Button
          variant="contained"
          color="primary"
          disabled={
            (formStep === EPublicRfqLandingFormStep.STEP1 && values.attachments.length === 0) ||
            (formStep === EPublicRfqLandingFormStep.STEP2 && !isValid)
          }
          onClick={onClickNext}
        >
          {t(
            formStep === EPublicRfqLandingFormStep.STEP2
              ? "workspaceNext:publicRfqLanding:sendAndGetQuotes"
              : "common:next"
          )}
        </Button>
      </Box>
    </Form>
  );
};
