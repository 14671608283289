import { Box, Link } from "@mui/material";
import React from "react";
import { AppBarItemType, PageLabelWithIcon } from "../../app-bar-next-dropdown";

type Props = {
  appBarItem: AppBarItemType;
};

export const ExternalLinkActionItem: React.FC<Props> = ({ appBarItem }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ cursor: "pointer" }}
      padding="12px"
      width={"100%"}
    >
      <Link
        href={appBarItem.path}
        target={"_blank"}
        style={{ textDecoration: "none", color: "white", width: "100%" }}
      >
        <PageLabelWithIcon appBarItem={appBarItem} />
      </Link>
    </Box>
  );
};
