import React from "react";
import { useSelector } from "react-redux";
import { Divider } from "@mui/material";
import { selectMessagesOfThread } from "@next/modules/vendor-management/redux/selectors";
import { SideDrawerMessageCard } from "./side-drawer-message-card/side-drawer-message-card";
import { formatDateAndTime } from "helpers/formatDate";

export const SideDrawerMessageThread = () => {
  const messagesOfThread = useSelector(selectMessagesOfThread);

  return (
    <>
      {messagesOfThread?.length > 0
        ? messagesOfThread.map((message, i) => (
            <React.Fragment key={message.id}>
              <SideDrawerMessageCard
                id={message.id}
                body={message.body}
                author={message.author}
                createdAt={formatDateAndTime(message.created_at, "MMM D, YYYY", "h:mm a")}
              />

              {i !== messagesOfThread.length - 1 ? <Divider /> : null}
            </React.Fragment>
          ))
        : null}
    </>
  );
};
