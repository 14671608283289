import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
} from "@mui/material";
import CustomButton from "@next/components/custom-button/custom-button";
import { useTranslation } from "react-i18next";
import { CustomTypography } from "@next/components/custom-typography";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { BuyerRfqsViewMenu } from "components/workspace/buyer-rfqs-view/buyer-rfqs-view-menu";
import CollaboratorsList from "components/workspace/buyer-rfqs-view/collaborators-list";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { modalsActions } from "services/modals/modals.actions";
import { getProfile } from "services/profile/profile.selectors";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import { projectActions } from "@next/modules/project/redux";
import { selectUpdateRFQLoading } from "@next/modules/project/redux/selectors";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import { NAVIGATION_FOR } from "@next/hooks/useDataForNavigation";
import { DataNavigationWithArrows } from "@next/components/data-navigation-with-arrows/data-navigation-with-arrows";

export const BuyerRfqsInformationHeader = () => {
  const rfqDetails = useSelector(getRfqDetails);
  const profile = useSelector(getProfile);
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);
  const tracking = useTracking();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [editedName, setEditedName] = useState(rfqDetails?.rfq_name || "");
  const updateRFQLoading = useSelector(selectUpdateRFQLoading);
  useEffect(() => setEditedName(rfqDetails?.rfq_name || ""), [rfqDetails]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSaveChanges = () => {
    dispatch(
      projectActions.updateRFQRequest({
        pk: rfqDetails?.pk,
        rfq_name: editedName,
        refetchData: rfqDetails?.refetchData,
        onSuccess: () => {
          handleCloseModal();
        },
      })
    );
  };

  const handleEditClick = () => {
    handleOpenModal();
  };

  const handleOpenTour = () => {
    dispatch(modalsActions.showModal(true, "isRfqViewTourOpen"));

    // tracking open rfq view tour click
    tracking.trackEvent({
      scout_category: "tour_guide",
      scout_feature_name: "open_rfq_view_tour",
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["name"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date(),
    });
  };

  return (
    <Box>
      <DataNavigationWithArrows navigationFor={NAVIGATION_FOR.RFQ} countBeforeArrows={true} />

      <Box display="flex" alignItems="center">
        <CustomTypography variant="h6">{rfqDetails.rfq_display_name}</CustomTypography>
        <Box>
          {!hasViewerRole && (
            <IconButton aria-label="edit" onClick={handleEditClick} size="small">
              <EditIcon />
            </IconButton>
          )}
        </Box>

        {rfqDetails?.tag && (
          <Box textAlign="left" fontWeight="fontWeightRegular" fontSize="h4.fontSize" ml="8px">
            {rfqDetails?.tag && Object.keys(rfqDetails.tag).length && (
              <Chip label={rfqDetails.tag.name} variant="outlined" />
            )}
          </Box>
        )}

        <Box ml={1}>
          <CollaboratorsList
            data={{
              collaborators: rfqDetails.collaborators,
              rfqPk: rfqDetails.pk,
              createdBy: rfqDetails.created_by,
            }}
            editMode={!hasViewerRole}
            size="small"
          />
        </Box>

        {hasViewerRole ? null : (
          <Box ml={1}>
            <BuyerRfqsViewMenu rfqDetails={rfqDetails} handleOpenTour={handleOpenTour} />
          </Box>
        )}
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="edit-rfq-name-dialog"
          maxWidth="sm"
          fullWidth
        >
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="rfq-name"
              label="Display Name"
              type="text"
              fullWidth
              value={editedName}
              inputProps={{ maxLength: 128 }}
              onChange={(e) => setEditedName(e.target.value)}
            />
          </DialogContent>
          <DialogActions style={{ padding: "4px 24px 20px" }}>
            <CustomButton
              onClick={handleSaveChanges}
              color="primary"
              variant="outlined"
              startIcon={<DoneIcon />}
              disabled={!editedName.trim()}
              loading={updateRFQLoading}
            >
              {t("common:save")}
            </CustomButton>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};
