import { Chip, IconButton, Typography } from "@mui/material";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { CustomFileObject } from "./multiple-dnd-file-upload.types";
import { createStyles, makeStyles } from "@mui/styles";

type Props = {
  files: CustomFileObject[];
  onClickDeleteFile?: (file: CustomFileObject) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    fileItemRoot: {
      display: "flex",
      alignItems: "center",
      position: "relative",
      marginTop: "10px",
    },
    fileName: {
      paddingLeft: "10px",
      width: "80%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    fileItemDeleteButton: {
      position: "absolute",
      right: "5px",
      cursor: "pointer",
    },
  })
);

export const MultipleDNDFileUploadList: React.FC<Props> = ({ files, onClickDeleteFile }) => {
  const classes = useStyles();

  return (
    <div>
      {files.map((customFileObject: CustomFileObject) => (
        <div className={classes.fileItemRoot}>
          <Chip
            label={customFileObject?.file?.name?.split(".")?.pop()?.toUpperCase()}
            size="small"
          />
          <Typography className={classes.fileName}>{customFileObject?.file?.name}</Typography>

          {onClickDeleteFile && (
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => onClickDeleteFile(customFileObject)}
              className={classes.fileItemDeleteButton}
              size="large"
            >
              <ClearIcon />
            </IconButton>
          )}
        </div>
      ))}
    </div>
  );
};
