import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { CustomTypography } from "@next/components/custom-typography";
import React from "react";

export const TextCell: React.FC<GridRenderCellParams<any>> = (params) => {
  return (
    <CustomTypography variant="body2" tooltipTitle={params?.value?.toString() || ""}>
      {params?.value?.toString() || ""}
    </CustomTypography>
  );
};
