import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import Modal from "@next/components/custom-modal";
import { SharedModalTypes } from "./types";
import { SupplierContactsListProps } from "@next/modules/workspace/components/supplier-contacts-list/supplier-contacts-list";
import { SupplierContactsForm } from "@next/modules/workspace/modals/supplier-contacts-list-form";

export enum DistributionForSupplierContacts {
  ALL = "ALL",
  RFQ = "RFQ",
  ORDER = "ORDER",
}

export const SharedSupplierContactsListModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(SharedModalTypes.SUPPLIER_CONTACTS_LIST_MODAL));
  const data = modal?.data as SupplierContactsListProps;

  if (!modal || !data) return null;

  return (
    <Modal
      dialogProps={{ fullWidth: true }}
      customWidth="md"
      handleModalClose={() =>
        dispatch(modalsActions.closeModal(SharedModalTypes.SUPPLIER_CONTACTS_LIST_MODAL))
      }
      modalState={{
        modalHeader: t("workspaceNext:supplierContactsList:manageModal:title"),
        modalBody: <SupplierContactsForm {...data} />,
      }}
      isModalOpen={true}
    />
  );
};
