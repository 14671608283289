import React from "react";
import { batch, useDispatch } from "react-redux";
import { Formik } from "formik";
import { modalsActions } from "@next/redux/modalsSlices";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { WorkspaceModalTypes } from "../../modals/types";
import AddASLFormBase from "./add-asl.form.base";
import { ASLInvitedSupplier } from "./add-asl.types";
import { createSupplierSchema } from "./add-asl.definitions";
import { useTranslation } from "react-i18next";

const AddASLForm = ({ onSubmit }: { onSubmit?: (suppliers: ASLInvitedSupplier) => void }) => {
  const dispatch = useDispatch();
  const axyaTracking = useAxyaTracking();
  const { t } = useTranslation();

  const initialValues: ASLInvitedSupplier = {
    email: "",
    firstName: "",
    lastName: "",
    language: "",
    companyName: "",
    vendorId: "",
    currency: "",
  };

  const handleInviteSupplier = async (formData: ASLInvitedSupplier) => {
    if (typeof onSubmit === "function") {
      onSubmit(formData);
    }

    batch(() => {
      if (typeof onSubmit !== "function")
        dispatch(modalsActions.closeModal(WorkspaceModalTypes.INVITE_SUPPLIER_MODAL));
    });
    axyaTracking({
      app: "axya-app",
      scout_category: "invite-supplier",
      scout_feature_name: "send-invitation",
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleInviteSupplier}
        validationSchema={createSupplierSchema(t)}
      >
        {(props) => <AddASLFormBase {...props} />}
      </Formik>
    </>
  );
};

export default AddASLForm;
