import { Part } from "@next/modules/project/redux";

// RF-TODO: convert workspace store to type safety store
export const getCompanyPartList = (state: any) => state.workspace.companyPartList;
export const getIsWorkspaceLoading = (state: any) => state.workspace.isWorkspaceLoading;
export const getIsWorkspaceInitialLoading = (state: any) =>
  state.workspace.isWorkspaceInitialLoading;
export const getIsCancellationOrExtensionLoading = (state: any) =>
  state.workspace.isCancellationOrExtensionLoading;
export const getRFQListOfCompany = (state: any) => state.workspace.RFQListOfCompany;
export const getIsLoading = (state: any) => state.workspace.isLoading;

export const getContractDetails = (state: any) => state.workspace.contractDetails;

export const getIsUploadAttachmentsLoading = (state: any) =>
  state.workspace.isUploadAttachmentsLoading;
export const fetchPublicRequestLoading = (state: any) => state.workspace.fetchPublicRequestLoading;
export const getRfqDetails = (state: any) => state.workspace.rfqDetails;

export const getRfqPartsForEdit = (state: any) => state.workspace.rfqParts;
export const getRemovedPartsIds = (state: any) => state.workspace.removeRfqParts;
export const getIsRFQPartModified = (state: any) => state.workspace.isRFQPartsModified;
export const selectRFQPartForEdit = (partPk: number) => (state: any) =>
  getRfqPartsForEdit(state)?.find?.((item: Part) => item.pk === partPk);

export const selectRFQPart = (partPk: number) => (state: any) =>
  getRfqDetails(state)?.parts?.find?.((item: Part) => item.pk === partPk);
export const selectRfqUpdatePartialLoading = (state: any) =>
  state.workspace.isRfqUpdatePartialLoading;
export const selectRfiShortlistLoading = (state: any) => state.workspace.isRfiShortlistLoading;
export const selectRfqUpdatePartialStatus = (state: any) => state.workspace.rfqUpdatePartialStatus;

export const getResetPartsSelectionCounter = (state: any) =>
  state.workspace.resetPartsSelectionCounter;

export const selectRfqPartsLoading = (state: any) => state.workspace.isRFQPartsLoading;

export const selectRFQDetailsLoading = (state: any) => state.workspace.isRFQDetailsLoading;

export const workspaceSelectors = {
  getCompanyPartList,
  getIsWorkspaceLoading,
  getIsWorkspaceInitialLoading,
  getIsCancellationOrExtensionLoading,
  getRFQListOfCompany,
  getIsLoading,
  getContractDetails,
  getIsUploadAttachmentsLoading,
  getRfqDetails,
  selectRfqUpdatePartialLoading,
  selectRfqUpdatePartialStatus,
  getResetPartsSelectionCounter,
  selectRFQDetailsLoading,
};
