import React from "react";
import {
  GridApi,
  GridCellParams,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro-v5";

import { ordersActions } from "@next/modules/orders/redux";
import { Box, Tooltip } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import { t } from "assets/configi18n/i18n";
import { MUIDatePicker } from "@next/components/mui-date-picker";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { formatDate } from "helpers/formatDate";
import { store } from "store";
import { DraftOrderLineItemsField } from "./order-detail-draft-line-items-table.types";
import { isNewRowId } from "@next/modules/project/components/part/project.part.table.utils";
import { FileCell } from "@next/components/table-cells/file-cell-v5";

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: true,
  hideSortIcons: false,
  editable: false,
};

const NameCell: React.FC<GridCellParams & { api: GridApi }> = ({ api, value, id }) => {
  if (isNewRowId(id) && api) {
    api.setCellMode(id, "name", "edit");
  }

  return <CustomTypography variant="caption">{value}</CustomTypography>;
};

const EditDeliveryDateCell: React.FC<
  GridRenderCellParams & {
    onChange: (value: any) => void;
  }
> = ({ value, onChange }) => {
  return (
    <Box width={"100%"}>
      <CustomTypography tooltipTitle={null} sx={{ display: "flex" }}>
        <MUIDatePicker
          value={value as any}
          onChange={(newValue) => {
            const formattedNewValue = formatDate(newValue, "YYYY-MM-DD");
            if (formattedNewValue === value) {
              return;
            }

            onChange(newValue);
          }}
          InputProps={{
            disableUnderline: true,
            margin: "none",
            style: { fontSize: 12, marginTop: "2px" },
          }}
          inputVariant="standard"
          keyboardIcon={<CalendarTodayOutlinedIcon fontSize="small" />}
          KeyboardButtonProps={{ color: "inherit", size: "small" }}
          disablePast={false}
          variant="inline"
        />
      </CustomTypography>
    </Box>
  );
};

export const QuantityOrPriceCell: React.FC<GridCellParams> = ({ value }) => {
  return <CustomTypography variant="caption">{value}</CustomTypography>;
};

const AdditionalDetailsCell: React.FC<GridCellParams> = ({ value }) => {
  return (
    <Tooltip title={value as string}>
      <CustomTypography className="c-ellipsis" variant="caption">
        {value}
      </CustomTypography>
    </Tooltip>
  );
};

const handleChangeDate = (id: number, value: any, orderPk: number) => {
  store.dispatch(
    ordersActions.updateOrderDeliveryDatesRequest({
      orderPk,
      delivery_dates: [{ id, delivery_date: formatDate(value, "YYYY-MM-DD") }],
    })
  );
};

export const getOrderDetailDraftLineItemsTableColumns = (orderPk): GridColumns => {
  const _columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: DraftOrderLineItemsField.NAME,
      headerName: t("orders:orderDetail:table:partNumber"),
      minWidth: 200,
      editable: true,
      renderCell: NameCell,
      type: "string",
      flex: 1,
    },
    {
      ...defaultColumnConfig,
      field: DraftOrderLineItemsField.FILES,
      minWidth: 200,
      headerName: t("orders:orderDetail:table:files"),
      renderCell: (params) => (
        <FileCell
          {...params}
          readOnly={false}
          isRFQEditMode={false}
          isOrderPart={true}
          part={params.row.part}
          files={params.row.part?.part_uploads_list}
        />
      ),
      disableExport: true,
      filterable: false,
    },
    {
      ...defaultColumnConfig,
      field: DraftOrderLineItemsField.QUANTITY,
      align: "right",
      headerName: t("orders:orderDetail:table:quantity"),
      minWidth: 100,
      editable: true,
      renderCell: QuantityOrPriceCell,
      flex: 1,
      filterable: false,
    },
    {
      ...defaultColumnConfig,
      field: DraftOrderLineItemsField.PRICE,
      align: "right",
      headerName: t("orders:orderDetail:table:unitPrice"),
      minWidth: 100,
      editable: true,
      renderCell: QuantityOrPriceCell,
      flex: 1,
      filterable: false,
    },
    {
      ...defaultColumnConfig,
      field: DraftOrderLineItemsField.DESCRIPTION,
      minWidth: 150,
      headerName: t("orders:orderDetail:table:description"),
      editable: true,
      renderCell: AdditionalDetailsCell,
      flex: 1,
      filterable: false,
    },
    {
      ...defaultColumnConfig,
      field: DraftOrderLineItemsField.DELIVERY_DATE,
      minWidth: 150,
      headerName: t("orders:orderDetail:table:requiredReceptionDate"),
      editable: true,
      renderCell: (params) => (
        <EditDeliveryDateCell
          onChange={(value) => {
            handleChangeDate(params.row.id, value, orderPk);
          }}
          {...params}
        />
      ),
      flex: 1,
      filterable: false,
    },
  ];

  return _columns;
};
