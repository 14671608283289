import { GridColumns, GridRowModel } from "@mui/x-data-grid-pro-v5";

export enum SuppliersField {
  ID = "id",
  NAME = "name",
  IS_ONLINE = "is_online",
  PICTURE = "picture",
  PHONE = "phone_number",
  CONTACTS_LIST = "target_profiles",
  GROUP = "groups",
  RFQS_SENT = "rfqsSent",
  QUOTES_RECEIVED = "quotesReceived",
  CONTRACTS_WON = "contractsWon",
  VENDOR_ID = "vendor_code",
  EDIT_BUTTON = "editButton",
}

export type GridDataState = {
  rows: GridRowModel[];
  columns: GridColumns;
};

export enum SupplierStatusInfo {
  ITB = "ITB",
  ITNB = "ITNB",
  QUOTED = "QUOTED",
  WAITING = "WAITING",
}

export type Supplier = {
  id: number;
  name: string;
  is_online: boolean;
  picture: string;
};
