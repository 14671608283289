import React from "react";
import { GridRowParams } from "@mui/x-data-grid-pro-v5";
import { useSelector } from "react-redux";
import { getValidationManagementTableColumns } from "./validation-management-table.columns";
import { frontendUrl } from "urls";
import {
  QuestionnaireValidationUrlMap,
  ValidationManagementTableRow,
} from "@next/modules/vendor-management/redux";
import {
  selectQuestionnairesData,
  selectQuestionnairesDataLoading,
} from "@next/modules/vendor-management/redux/selectors";
import * as S from "./validation-management-table.styled";

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
};

export const ValidationManagementTable: React.FC<Props> = ({ page, setPage, pageSize }) => {
  const columns = getValidationManagementTableColumns();
  const isLoading = useSelector(selectQuestionnairesDataLoading);
  const tableData = useSelector(selectQuestionnairesData);
  const rows = tableData?.results;
  const count = tableData?.count;

  const handleRowClick = (params: GridRowParams) => {
    const row = params.row as ValidationManagementTableRow;
    if (row.status !== "not_started") {
      window.open(
        `${frontendUrl[QuestionnaireValidationUrlMap[row.questionnaire_name]]}/${row.company_id}`,
        "_blank"
      );
    }
  };

  return (
    <S.StyledDataGridPro
      rows={rows || []}
      columns={columns}
      headerHeight={48}
      rowHeight={56}
      autoHeight
      onRowClick={handleRowClick}
      getRowId={(row) => row.questionnaire_url}
      pagination
      paginationMode="server"
      page={page - 1}
      pageSize={pageSize}
      rowCount={count ? count : 0}
      rowsPerPageOptions={[pageSize]}
      onPageChange={(newPage) => setPage(newPage + 1)}
      loading={isLoading}
      disableSelectionOnClick
    />
  );
};
