import React from "react";
import { Card, CardContent, Grid, SvgIconProps, Theme, Typography } from "@mui/material";
import CustomButton from "@next/components/custom-button/custom-button";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      color: "white",
      padding: "8px",
      background: "linear-gradient(45deg, #071930 48.75%, #2453B8 100%)",
      height: "100%",
    },
    cardContent: {
      paddingBottom: "16px !important",
      height: "100%",
    },
    button: {
      marginTop: "10px",
      backgroundColor: "white",
    },
  })
);

type Props = {
  title: string;
  Icon?: React.ComponentType<SvgIconProps>;
  btnText?: string;
  onClick?: () => void;
  btnTestId?: string;
};

export const ActionCard: React.FC<Props> = ({ title, Icon, btnText, onClick, btnTestId }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          style={{ height: "100%" }}
        >
          <Grid item style={{ maxWidth: "85%", height: "100%" }}>
            <Grid
              container
              direction="column"
              alignItems="flex-start"
              justifyContent="space-between"
              style={{ height: "100%" }}
            >
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <CustomButton
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={onClick}
                  data-pid={btnTestId}
                >
                  {btnText}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            {Icon && (
              <Grid item>
                <Icon />
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
