import React from "react";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
} from "@mui/material";

import { Announcement } from "../redux/types";
import { createStyles, makeStyles } from "@mui/styles";

interface Props {
  isLoading: boolean;
  selectedValue: string;
  handleChange: (event: SelectChangeEvent<string>) => void;
  options: Announcement[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

const ReleaseDropDown: React.FC<Props> = ({ isLoading, selectedValue, handleChange, options }) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <Select
        labelId="select-release-announcement-label"
        id="select-release-announcement"
        value={selectedValue}
        onChange={handleChange}
        renderValue={() => {
          return isLoading ? (
            <CircularProgress size={20} />
          ) : (
            options.find((option) => option.slug === selectedValue)?.title || ""
          );
        }}
      >
        {options.map((ann) => (
          <MenuItem key={ann.slug} value={ann.slug}>
            {ann.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ReleaseDropDown;
