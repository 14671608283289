import CurrencyField from "components/common/fields/CurrencyField";
import React, { useContext } from "react";
import { quoteComparisonContext } from "./quote-comparison.context";
import { Box } from "@mui/material";

export const QuoteComparisonCurrencyField = ({
  value,
  hideCurrency,
  currency,
}: {
  value: number;
  hideCurrency?: boolean;
  currency?: string;
}) => {
  const {
    gridData: { config },
  } = useContext(quoteComparisonContext);

  if (isNaN(value) || value === null || value === undefined)
    return <Box visibility="hidden">{"0"}</Box>;

  return (
    <CurrencyField
      value={value}
      currency={currency || config?.selected?.currency}
      hideCurrency={hideCurrency}
    />
  );
};
