import React from "react";
import PropTypes from "prop-types";

import { CardMedia } from "@mui/material";

import cadImage from "../../../assets/img/default-part-img.png";

PartThumbnail.propTypes = {
  /**
   * Part info object
   */
  part: PropTypes.object.isRequired,
};

function PartThumbnail({ part, part_previews, nda = false, onClick = () => {} }) {
  let preview;
  if (part_previews) {
    preview = part_previews[0];
  } else if (part && part.preview) {
    preview = part.preview;
  } else if (part && part.part_uploads_list) {
    preview = part.part_uploads_list.find((u) => u.file_preview_url !== null);
  }

  if (preview) {
    preview = preview.file_preview_url || preview;
  } else {
    preview = cadImage;
  }

  return (
    <CardMedia
      onClick={onClick}
      component="img"
      src={preview}
      title="cad image"
      className={`part-thumbnail ${nda ? "nda-form-disable" : ""}`}
    />
  );
}

export default PartThumbnail;
