import { Typography, styled } from "@mui/material";
import { colorOptions } from "@next/utils/constantUtils";

export const StyledTypography = styled(Typography)({
  color: "rgba(0, 0, 0, 0.87)",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  fontFamily: "Roboto",
});

export const StyledSpanTag = styled("span")`
  color: ${colorOptions.royalBlue};
  font-weight: bold;
`;
