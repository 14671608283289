import { Box, styled } from "@mui/material";

export const StyledFormFieldBox = styled(Box)({
  width: "48%",
  display: "flex",
});

export const StyledInnerBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  width: "100%",
});

export const StyledTableWrapper = styled(Box)(() => ({
  margin: "0",
  overflowX: "auto",
}));
