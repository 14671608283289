export enum ContractsTableField {
  RFQ_DISPLAY_NAME = "rfq_display_name",
  OWNER = "created_by",
  AWARDED_ON = "winner_date",
  PARTS = "parts_count",
  BUYER = "company_name",
  COST_AND_LEAD_TIME = "lead_time",
  SUPPLIER_SELECTION_EXPORT = "supplier_selection_export",
  PURCHASE_ORDER = "quotations_count",
  RATING = "stateAndStatus",
  MARK_AS_COMPLETE = "mark_as_complete",
}

export enum ContractsTableSortField {
  BUYER_NAME = "company_name__name",
}
