import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { RFQStatus, projectActions } from "@next/modules/project/redux";
import { modalsActions as _modalActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";
import { useDispatch } from "react-redux";
import { Divider, IconButton, ListItemText, Menu, MenuItem, Theme } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RfqViewTour from "components/tour-guide/rfq-view-tour/rfq-view-tour.component";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { createStyles, makeStyles } from "@mui/styles";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { useOnClickDownloadAll } from "components/utils/download-all-as-zip/download-all-as-zip.utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemIcon: {
      minWidth: "32px",
    },
    icon: {
      width: "20px",
      height: "20px",
      color: theme.palette.text.primary,
    },
  })
);

type Props = {
  rfqDetails: any;
  handleOpenTour: () => void;
};

export const BuyerRfqsViewMenu: React.FC<Props> = ({ rfqDetails, handleOpenTour }) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const axyaTracking = useAxyaTracking();
  const _onClickDownloadAll = useOnClickDownloadAll(rfqDetails);

  const onOpen = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onClickAddAddenda = () => {
    dispatch(
      _modalActions.showModal({
        key: WorkspaceModalTypes.RFQ_ADD_ADDENDA_MODAL,
        data: {
          index: rfqDetails.addenda?.length || 0,
        },
      })
    );
  };

  const handleSetEditRfqDescription = () => {
    onClose();
    dispatch(workspaceNextActions.setEditRfqDescription(true));
  };

  const onClickExtendRfq = useCallback(() => {
    dispatch(
      _modalActions.showModal({
        key: SharedModalTypes.EXTEND_RFQ_MODAL,
        data: {
          activeRfq: rfqDetails,
          refetchData: false,
        },
      })
    );
  }, [rfqDetails]);

  const onClickAddCollaborator = useCallback(() => {
    dispatch(
      _modalActions.showModal({
        key: SharedModalTypes.ADD_COLLABORATOR_MODAL,
        data: {
          collaborators: rfqDetails.collaborators,
          rfqPk: rfqDetails.pk,
          createdBy: rfqDetails.created_by,
          refetchData: false,
        },
      })
    );
  }, [rfqDetails]);

  const onClickOpenAddSuppliersModal = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    dispatch(
      _modalActions.showModal({
        key: WorkspaceModalTypes.ADD_SUPPLIERS_MODAL,
      })
    );
  };

  const onClickOpenContactSuppliersModal = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    dispatch(
      _modalActions.showModal({
        key: WorkspaceModalTypes.CONTACT_SUPPLIERS_MODAL,
      })
    );
  };

  const onClickPutOnHoldRfq = useCallback(() => {
    if (!rfqDetails?.pk) return;

    if (rfqDetails?.status === RFQStatus.ON_HOLD) {
      dispatch(
        projectActions.updateRFQRequest({
          pk: rfqDetails?.pk,
          status: RFQStatus.ACTIVE,
          refetchData: true,
          isDetailPage: true,
          onSuccess: () => {
            axyaTracking({
              scout_category: "usage_metric",
              scout_rfq_pk: rfqDetails.pk,
              scout_action: "revert-rfq-on-hold",
            });
          },
        })
      );
    } else {
      dispatch(
        _modalActions.showModal({
          key: SharedModalTypes.ADD_ON_HOLD_NOTE_MODAL,
          data: {
            rfqPk: rfqDetails?.pk,
            isDetailPage: true,
          },
        })
      );
    }
  }, [rfqDetails.pk, rfqDetails?.status]);

  const onClickCancelRfq = useCallback(() => {
    dispatch(
      _modalActions.showModal({
        key: SharedModalTypes.CANCEL_RFQ_MODAL,
        data: {
          activeRfqPk: rfqDetails.pk,
          refetchData: false,
        },
      })
    );
  }, [rfqDetails.pk]);

  const onClickDownloadFiles = useCallback(() => {
    _onClickDownloadAll();
    onClose();
  }, [_onClickDownloadAll]);

  const disabled = [RFQStatus.CANCELED, RFQStatus.CONTRACT].includes(
    rfqDetails.status as RFQStatus
  );

  const isSelectedRfqOnHold = rfqDetails.status === RFQStatus.ON_HOLD;

  return (
    <>
      <IconButton onClick={onOpen} size="large">
        <MoreHorizIcon />
      </IconButton>

      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        <MenuItem onClick={onClickDownloadFiles} disabled={!rfqDetails.download_all_url}>
          <ListItemText primary={t("project:rfqOptionMenu:downlaodFiles")} />
        </MenuItem>

        <MenuItem onClick={onClickAddAddenda} disabled={disabled}>
          <ListItemText primary={t("project:rfqOptionMenu:addAddenda")} />
        </MenuItem>

        <MenuItem
          onClick={handleSetEditRfqDescription}
          disabled={rfqDetails.additional_details ? false : true}
        >
          <ListItemText primary={t("project:rfqOptionMenu:editRequestDesc")} />
        </MenuItem>

        <MenuItem onClick={onClickExtendRfq}>
          <ListItemText primary={t("project:rfqOptionMenu:extendDeadline")} />
        </MenuItem>

        <MenuItem onClick={onClickAddCollaborator} disabled={disabled}>
          <ListItemText primary={t("project:rfqOptionMenu:shareWithCollaborators")} />
        </MenuItem>

        <Divider />

        <MenuItem onClick={onClickOpenAddSuppliersModal}>
          <ListItemText primary={t("project:rfqOptionMenu:inviteSupplier")} />
        </MenuItem>

        <MenuItem onClick={onClickOpenContactSuppliersModal}>
          <ListItemText primary={t("project:rfqOptionMenu:sendMsgToSuppliers")} />
        </MenuItem>

        <Divider />

        <MenuItem onClick={onClickPutOnHoldRfq} disabled={disabled}>
          <ListItemText
            primary={
              isSelectedRfqOnHold
                ? t("project:table:row:reactive")
                : t("project:rfqOptionMenu:putOnHold")
            }
          />
        </MenuItem>

        <MenuItem onClick={onClickCancelRfq} disabled={disabled}>
          <ListItemText primary={t("project:rfqOptionMenu:cancelRFQ")} />
        </MenuItem>
      </Menu>

      <RfqViewTour />
    </>
  );
};
