import { Avatar, Box, Divider, Grid, List, ListItem, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import QuotationPriceAndLeadTime from "@next/modules/workspace/components/quotation-price-and-lead-time";
import { checkIsShowCommissionFeeCompany } from "@next/utils/checkCompanyUtils";
import CurrencyField from "components/common/fields/CurrencyField";
import { getFeatureOfPart } from "components/workspace/buyer-rfqs-view/offers-view/offers-details/offers-details.component";
import { QuoteTotalPrice } from "components/workspace/buyer-rfqs-view/quote-total-price";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCompany } from "services/profile/profile.selectors";

interface Props {
  quotation: Record<string, any>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quotationContainer: {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(1),
    },
    companyContainer: {
      padding: theme.spacing(2),
    },
    avatar: {
      width: 60,
      height: 60,
      marginRight: theme.spacing(1.5),
    },
    lightText: {
      color: theme.palette.action.active,
    },
    invoiceGrid: {
      marginBottom: -theme.spacing(1.5),
    },
    list: {
      paddingTop: 0,
      paddingBottom: 0,

      "& li": {
        marginBottom: 0,
      },
    },
    listSec: {
      "& li": {
        paddingLeft: 0,
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    alignRight: {
      textAlign: "right",
    },
  })
);

export const SingleQuotationViewer: React.FC<Props> = (props) => {
  const { quotation } = props;
  const company = quotation.company_info;
  const classes = useStyles();
  const { t } = useTranslation();

  const myCompany = useSelector(getCompany);

  const featuresOfParts = getFeatureOfPart(quotation.parts, quotation.fixed_charge);

  const subTotal = useMemo(
    () =>
      featuresOfParts.reduce((acc, part) => {
        return acc + parseFloat(part.price || "0");
      }, 0),
    []
  );

  const isShowCommissionFeeCompany = checkIsShowCommissionFeeCompany(myCompany.id);

  const quoteTotalPrice = parseFloat(quotation?.total_price || 0);

  return (
    <Grid className={classes.quotationContainer} container direction="column" spacing={0}>
      <Grid className={classes.companyContainer} container alignItems="center" direction="row">
        <Grid item>
          <Avatar alt="supplier image" src={company.company_picture} className={classes.avatar} />
        </Grid>
        <Grid item>
          <Typography variant="body2">{company.company_name}</Typography>
          <Typography className={classes.lightText} variant="caption">
            <Box>{company.company_phone_number}</Box>
            <Box>
              {company.company_address?.city}, {company.company_address?.province}{" "}
              {company.company_address?.postal_code}, {company.company_address?.country}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={true}>
          <QuotationPriceAndLeadTime quotation={quotation} showCurrencyRate />
        </Grid>
      </Grid>

      <Grid item className={classes.invoiceGrid}>
        <List className={classes.list}>
          <Divider />
          <ListItem divider>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6}>
                <Typography className={classes.lightText} variant="caption">
                  {t("workspace:partOfferView")}
                </Typography>
              </Grid>

              <Grid className={classes.alignRight} item xs={2}>
                <Typography className={classes.lightText} variant="caption">
                  {t("workspace:quantity")}
                </Typography>
              </Grid>

              <Grid className={classes.alignRight} item xs={2}>
                <Typography className={classes.lightText} variant="caption">
                  {t("workspace:unitPrice")}
                </Typography>
              </Grid>

              <Grid className={classes.alignRight} item xs={2}>
                <Typography className={classes.lightText} variant="caption">
                  {t("workspace:total")}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>

          {featuresOfParts.map((part) => {
            return (
              <ListItem divider>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={6}>
                    <Typography variant="caption">{part?.name}</Typography>
                  </Grid>

                  <Grid className={classes.alignRight} item xs={2}>
                    <Typography variant="caption">{part?.quantity}</Typography>
                  </Grid>

                  <Grid className={classes.alignRight} item xs={2}>
                    <Typography variant="caption">
                      {part?.unitPrice ? (
                        <CurrencyField
                          value={part.unitPrice}
                          currency={quotation.currency}
                          showSymbol
                        />
                      ) : (
                        <>
                          <span className="blurred-content"></span>$
                        </>
                      )}
                    </Typography>
                  </Grid>

                  <Grid className={classes.alignRight} item xs={2}>
                    <Typography variant="caption">
                      {part?.price ? (
                        <CurrencyField
                          value={part?.price}
                          currency={quotation.currency}
                          showSymbol
                        />
                      ) : (
                        <>
                          <span className="blurred-content"></span>$
                        </>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            );
          })}
        </List>
      </Grid>

      <Grid container direction="row" justifyContent="flex-end">
        <Grid item xs={7}>
          <List className={`${classes.list} ${classes.listSec}`}>
            <ListItem divider>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography className={classes.lightText} variant="caption">
                    {t("workspace:subTotal")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">
                    {subTotal ? (
                      <CurrencyField value={subTotal} currency={quotation.currency} showSymbol />
                    ) : (
                      <>
                        <span className="blurred-content"></span>$
                      </>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>

            <ListItem divider>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography className={classes.lightText} variant="caption">
                    {t("inbox:fixedFees")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">
                    {quotation?.fixed_charge ? (
                      <CurrencyField
                        value={quotation.fixed_charge}
                        currency={quotation.currency}
                        showSymbol
                      />
                    ) : (
                      <>
                        <span className="blurred-content"></span>$
                      </>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>

            {isShowCommissionFeeCompany && quotation?.commission_fee && (
              <ListItem divider>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography
                      className={`${classes.lightText} c-offer-details__commissionFees`}
                      variant="caption"
                    >
                      {t("pay:paymentTerms:commissionFees")}
                      {quotation?.commission_fee?.percentage ? (
                        <span className="s-span">
                          {`(${quotation?.commission_fee?.percentage}%)`}
                        </span>
                      ) : (
                        <span className="blurred-content"></span>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      {quotation?.commission_fee && quotation?.commission_fee?.amount ? (
                        <CurrencyField
                          value={quotation?.commission_fee?.amount}
                          currency={quotation.currency}
                          showSymbol
                        />
                      ) : (
                        <>
                          <span className="blurred-content"></span>$
                        </>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            )}

            <ListItem divider>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography className={classes.lightText} variant="caption">
                    {t("pay:paymentTerms:paymentTermsLabel")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">{quotation?.net_d["name"]}</Typography>
                </Grid>
              </Grid>
            </ListItem>

            <Box mb={1.5}>
              <ListItem>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography variant="subtitle1" className={classes.bold}>
                      {t("workspace:total")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" className={classes.bold}>
                      <QuoteTotalPrice
                        price={
                          isShowCommissionFeeCompany && quotation?.commission_fee
                            ? quotation?.commission_fee?.final_total_price
                            : quoteTotalPrice
                        }
                        currency={quotation?.currency}
                        currencyConversion={quotation?.currency_conversion}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </Box>
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};
