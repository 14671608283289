import { Button, styled } from "@mui/material";

export const StyledAttachmentButton = styled(Button)(({ theme }) => ({
  height: "40px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  border: "1px solid rgba(0, 0, 0, 0.25)",
  backgroundColor: `${theme.palette.background.paper}`,
  color: `${theme.palette.text.primary}`,
  width: "auto",
}));
