import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import {
  MessageThread,
  Step,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectCurrentMessageThread,
  selectIsFormDisplayForValidationManagement,
  selectMessageThreadsOfStep,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./side-drawer-resolve-box.styled";

type Props = {
  isThreadful: boolean;
};

export const SideDrawerResolveBox: React.FC<Props> = ({ isThreadful }) => {
  const dispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();
  const visibleSteps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(visibleSteps);
  const activeFormStep: Step = visibleSteps[activeFormStepIndex];
  const messageThread = useSelector(selectCurrentMessageThread);
  const messageThreads = useSelector(selectMessageThreadsOfStep);
  const currentMessageThread: MessageThread = isThreadful ? messageThread : messageThreads![0];
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );

  const handleClickMarkAsResolvedBtn = () => {
    dispatch(
      vendorManagementActions.updateMessageThreadRequest({
        stepId: activeFormStep.step,
        messageThreadId: currentMessageThread.id,
        isResolved: true,
        onSuccess: onSuccessForUpdateMessageThread,
      })
    );
  };

  const onSuccessForUpdateMessageThread = () => {
    if (isThreadful) {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfSpecRequest({
          companyId: companyId,
          stepId: activeFormStep.step,
          body: {
            status: "changes_done",
            entity_id: currentMessageThread.entity_id,
            entity_name: currentMessageThread.entity_name,
          },
          onSuccess: onSuccessApprovalOfSpec,
        })
      );
    } else if (
      !isThreadful &&
      activeFormStep?.is_completed &&
      activeFormStep?.unresolved_threads_count === 1
    ) {
      updateApprovalStatusOfStep();
    }
  };

  const updateApprovalStatusOfStep = () => {
    dispatch(
      vendorManagementActions.updateApprovalStatusOfStepRequest({
        companyId: companyId,
        stepId: activeFormStep.step,
        status: "changes_done",
      })
    );
  };

  const onSuccessApprovalOfSpec = () => {
    dispatch(vendorManagementActions.triggerForFetchingSpecsOfStep());

    if (activeFormStep?.is_completed && activeFormStep?.unresolved_threads_count === 1) {
      updateApprovalStatusOfStep();
    }
  };

  if (currentMessageThread?.line_deleted) {
    return null;
  }

  return currentMessageThread?.is_resolved ? (
    <S.StyledBtnBox>
      <S.StyledButton
        startIcon={<S.StyledCheckIcon />}
        variant="contained"
        color="secondary"
        onClick={null}
        disableElevation
        sx={{ cursor: "default" }}
      >
        <S.StyledTypography variant="body2" tooltipTitle={null}>
          Resolved
        </S.StyledTypography>
      </S.StyledButton>
    </S.StyledBtnBox>
  ) : (
    <S.StyledBtnBox>
      <Tooltip
        title={isFormDisplayForValidationManagement ? "Only the supplier can mark as resolved" : ""}
      >
        <span style={{ width: "100%" }}>
          <S.StyledButton
            startIcon={<S.StyledCheckIcon />}
            variant="contained"
            color="primary"
            onClick={handleClickMarkAsResolvedBtn}
            disableElevation
            disabled={isFormDisplayForValidationManagement}
          >
            <S.StyledTypography variant="body2" tooltipTitle={null}>
              Mark as resolved
            </S.StyledTypography>
          </S.StyledButton>
        </span>
      </Tooltip>
    </S.StyledBtnBox>
  );
};
