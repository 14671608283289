import React from "react";
import { TextField } from "@mui/material";

import { withStyles } from "@mui/styles";
import { fieldToTextField } from "formik-material-ui";

export const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "grey",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "grey",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: "grey",
      },
      "&.Mui-focused fieldset": {
        borderColor: "grey",
      },
    },
  },
})(TextField);

export const CssTextFieldBlackBorder = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        border: "2px solid black",
        borderRadius: "4px",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(TextField);

export const FormikGenericTextFieldGreyBorder = ({ textFieldProps, ...props }) => {
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;
  return <CssTextField name={name} {...textFieldProps} helperText={helperText} error={error} />;
};
export const FormikGenericTextFieldBlackBorder = ({ textFieldProps, ...props }) => {
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;
  return (
    <CssTextFieldBlackBorder
      name={name}
      {...textFieldProps}
      helperText={helperText}
      error={error}
    />
  );
};
