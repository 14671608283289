import React, { useCallback } from "react";
import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { t } from "assets/configi18n/i18n";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "@next/modules/project/modals/types";
import { GridCellParams } from "@mui/x-data-grid-pro-v5";
import AddIcon from "@mui/icons-material/Add";
import { AppThemeV5 } from "layout/AppTheme";
import { batch, useDispatch } from "react-redux";
import { workspaceActions } from "services/workspace";
import { ProjectCompanyPart, projectActions } from "@next/modules/project/redux";
import { SharedModalTypes } from "@next/modals/types";

export const FileCell: React.FC<
  GridCellParams & {
    readOnly?: boolean;
    isRFQEditMode?: boolean;
    isRFQPart?: boolean;
    isOrderPart?: boolean;
    part?: ProjectCompanyPart;
    files?: any;
  }
> = (params) => {
  const dispatch = useDispatch();
  const files: any = params.files || params.row.part_uploads_list || [];
  const part: ProjectCompanyPart = params.part || params.row;

  const onClick = useCallback(
    (preSelectedFileId?) => {
      batch(() => {
        dispatch(projectActions.setSelectedPart(part));
        dispatch(
          modalsActions.showModal({
            key: SharedModalTypes.PART_FILE_MODAL,
            data: {
              part: part,
              readOnly: params.readOnly,
              isRFQEditMode: params?.isRFQEditMode,
              isRFQPart: params?.isRFQPart,
              isOrderPart: params?.isOrderPart,
              preSelectedFileId,
            },
          })
        );
      });
    },
    [part, params.readOnly, params?.isRFQEditMode, params?.isRFQPart, params?.isOrderPart]
  );

  const handleDeleteDuringEdit = (filePk: number) => {
    const updatedPartAttachement = files.filter((part) => part.pk !== filePk);
    dispatch(workspaceActions.editRFQPartsAttachement(part.pk, updatedPartAttachement));
  };

  const handleDelete = (file: any) => () => {
    batch(() => {
      dispatch(projectActions.setSelectedPart(part));
      dispatch(
        modalsActions.showModal({
          key: ProjectModalTypes.FILE_DELETE_CONFIRMATION_MODAL,
          data: {
            deleteFilePk: file?.pk,
            isRFQEditMode: params?.isRFQEditMode,
            isOrderPart: params?.isOrderPart,
            customUpdateHandler: handleDeleteDuringEdit,
          },
        })
      );
    });
  };

  return (
    <>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: AppThemeV5.palette.text.primary,
              "& .MuiTooltip-arrow": {
                color: AppThemeV5.palette.text.primary,
              },
            },
          },
        }}
        title={
          files.length === 0 ? null : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              {files.map((file) => {
                return (
                  <Typography variant="body2" key={file.pk}>
                    {file.file_name + "." + file.file_extension}
                  </Typography>
                );
              })}
            </Box>
          )
        }
        placement="bottom"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            width: "100%",
          }}
          onClick={onClick}
        >
          <Chip label={files.length} variant="outlined" size="small" />
          {files.length === 0 && !params.readOnly ? (
            <Chip
              icon={<AddIcon />}
              label={t("project:table:row:addFiles")}
              variant="outlined"
              size="small"
              onClick={onClick}
            />
          ) : (
            <>
              {files.map((file) => {
                return (
                  <Chip
                    size="small"
                    label={file.file_name + "." + file.file_extension}
                    onClick={(e) => {
                      onClick(file.pk);
                      e.stopPropagation();
                    }}
                    onDelete={params.readOnly ? undefined : handleDelete(file)}
                    sx={{
                      backgroundColor: AppThemeV5.palette.text.primary,
                      color: AppThemeV5.palette.common.white,
                      "&:hover": {
                        backgroundColor: AppThemeV5.palette.text.primary,
                        color: AppThemeV5.palette.common.white,
                      },

                      ".MuiChip-deleteIcon": {
                        color: AppThemeV5.palette.common.white,
                        "&:hover": {
                          color: AppThemeV5.palette.common.white,
                        },
                      },
                    }}
                  />
                );
              })}
              {!params.readOnly && (
                <IconButton
                  size="small"
                  onClick={onClick}
                  sx={{
                    border: `1px solid ${AppThemeV5.palette.divider}`,
                    padding: "4px",
                  }}
                >
                  <AddIcon sx={{ width: "16px", height: "16px" }} />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </Tooltip>
    </>
  );
};
