import React from "react";
import { Graph } from "./graph/graph.component";

// Material ui imports
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import EqualizerIcon from "@mui/icons-material/Equalizer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const StatsReport = ({ t }) => {
  const [expanded, setExpanded] = React.useState("panel1");

  // expand panel after click on the icon
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      elevation={0}
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      className="c-stats-report"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className="c-stats-report--panel-header"
      >
        <EqualizerIcon />
        <span>{t("report")}</span>
      </AccordionSummary>
      <AccordionDetails>
        <Graph t={t} />
      </AccordionDetails>
    </Accordion>
  );
};
