import React from "react";
import { withTranslation } from "react-i18next";
// Routes
import { frontendUrl } from "urls";
// Components
import NotFoundPage from "components/not-found-page/not-found-page.component";
import NotAuthorizedPage from "components/not-authorized-page/not-authorized-page.component";
// Types
import { CustomRoute } from "./types";
// Dummy layout
const ErrorsLayout = (props: any) => {
  return props.children;
};

const ERRORS_ROUTES: CustomRoute[] = [
  {
    // /404
    layout: ErrorsLayout,
    path: frontendUrl.notFound,
    key: "404",
    exact: true,
    component: withTranslation("ERRORS")((props) => {
      return <NotFoundPage {...props} />;
    }),
  },
  {
    // /notAuthorized
    layout: ErrorsLayout,
    path: frontendUrl.notAuthorized,
    key: "accessDenied",
    exact: true,
    component: withTranslation("ERRORS")((props) => {
      return <NotAuthorizedPage {...props} />;
    }),
  },
];

export default ERRORS_ROUTES;
