import React from "react";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import { MUIDatePicker } from "@next/components/mui-date-picker";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { formatDate } from "helpers/formatDate";
import { calculateDateRange } from "./analytic-filters.utils";
import { selectAnalyticFilters } from "../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { analyticsActions } from "../../redux";
import { AppTheme } from "layout/AppTheme";
import { useTranslation } from "react-i18next";

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  height: "64px",
  justifyContent: "space-around",
  alignItems: "center",
  borderBottom: `1px solid ${AppTheme.palette.divider}`,
  paddingBottom: "8px",
});

const StyledMenuItem = styled(MenuItem)({
  height: "44px",
});

export const AnalyticFiltersTimeframeDropdown = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const analyticFilters = useSelector(selectAnalyticFilters);

  const handleTimeFrameChange = (event: SelectChangeEvent) => {
    const value = event.target.value;

    const dateObj = value === "custom" ? analyticFilters?.timeframe : calculateDateRange(value);

    dispatch(
      analyticsActions.setAnalyticFilters({
        ...analyticFilters,
        timeframe: {
          ...dateObj,
          key: value,
        },
      })
    );
  };

  const handleTimeFrameDatepickersChange = (type: "start" | "end") => (date: Date | null) => {
    dispatch(
      analyticsActions.setAnalyticFilters({
        ...analyticFilters,
        timeframe: {
          ...analyticFilters.timeframe,
          key: "custom",
          [type]: formatDate(date, "YYYY-MM-DD"),
        },
      })
    );
  };

  const renderSelectValue = () => {
    if (analyticFilters.timeframe.key === "allTime") {
      return t("analytics:timeframe:allTime");
    } else {
      if (analyticFilters.timeframe.start && analyticFilters.timeframe.end) {
        return `${t("analytics:timeframe:to", {
          start: formatDate(analyticFilters.timeframe.start, "YYYY-MM-DD"),
          end: formatDate(analyticFilters.timeframe.end, "YYYY-MM-DD"),
        })}`;
      } else {
        return t("analytics:timeframe:custom");
      }
    }
  };

  return (
    <FormControl fullWidth style={{ marginLeft: "12px", width: "400px" }}>
      <InputLabel>{t("analytics:timeframe:label")}</InputLabel>

      <Select
        value={analyticFilters.timeframe.key}
        renderValue={renderSelectValue}
        label={t("analytics:timeframe:label")}
        onChange={handleTimeFrameChange}
        size="small"
      >
        <StyledBox>
          <MUIDatePicker
            onChange={handleTimeFrameDatepickersChange("start")}
            label={t("analytics:timeframe:start")}
            value={analyticFilters?.timeframe?.start}
            variant="inline"
            style={{ width: "170px" }}
            size="small"
            minDateMessage=""
            invalidDateMessage=""
            disablePast={false}
            disabled={analyticFilters.timeframe.key === "allTime"}
            InputLabelProps={{ shrink: true }}
          />
          <ArrowForwardIcon />
          <MUIDatePicker
            onChange={handleTimeFrameDatepickersChange("end")}
            label={t("analytics:timeframe:end")}
            value={analyticFilters?.timeframe?.end}
            variant="inline"
            style={{ width: "170px" }}
            size="small"
            minDateMessage=""
            invalidDateMessage=""
            disablePast={false}
            minDate={analyticFilters.timeframe.start}
            disabled={analyticFilters.timeframe.key === "allTime"}
            InputLabelProps={{ shrink: true }}
          />
        </StyledBox>

        <StyledMenuItem value={"custom"}>{t("analytics:timeframe:custom")}</StyledMenuItem>

        <StyledMenuItem value={"lastWeek"}>{t("analytics:timeframe:last7days")}</StyledMenuItem>

        <StyledMenuItem value={"lastMonth"}>{t("analytics:timeframe:last30days")}</StyledMenuItem>

        <StyledMenuItem value={"lastYear"}>{t("analytics:timeframe:last12months")}</StyledMenuItem>

        <StyledMenuItem value={"yearToDate"}>{t("analytics:timeframe:yearToDate")}</StyledMenuItem>

        <Divider />

        <StyledMenuItem value={"allTime"}>{t("analytics:timeframe:allTime")}</StyledMenuItem>
      </Select>
    </FormControl>
  );
};
