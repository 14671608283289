import React from "react";
import { Button, Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useOnClickDownloadAllQuotations } from "./download-all-quotations-as-zip.util";
import DownloadIcon from "@mui/icons-material/GetApp";
import { useTranslation } from "react-i18next";

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 300,
    fontSize: 12,
    marginTop: 5,
    textAlign: "center",
  },
}))(Tooltip);
interface Props {
  quotations: any;
  disabled?: boolean;
}
export const DownloadQuotationsAsZip: React.FC<Props> = ({ quotations, disabled }) => {
  const { t } = useTranslation();

  const quotationFilesWithCompany = quotations.map((quotation: any) => ({
    company_name: quotation.company_info.company_name,
    quotation_uploads_list: quotation.quotation_uploads_list,
  }));
  const onClickDownloadAllQuotations = useOnClickDownloadAllQuotations(quotationFilesWithCompany);

  const handleDownloadAllQuotationsClick = (e: any) => {
    e.stopPropagation();
    onClickDownloadAllQuotations();
  };

  return (
    <HtmlTooltip
      title={
        disabled
          ? t("rfq:quotations:toolTipExportQuotationsPDFDisabled")
          : t("rfq:quotations:toolTipDownloadQuotationsZIP")
      }
    >
      <span>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDownloadAllQuotationsClick}
          startIcon={<DownloadIcon />}
          fullWidth
          size="large"
          data-pid="btnQuotationsDownloadAllFiles"
          disabled={disabled}
        >
          {t("rfq:quotations:downloadAllFiles")}
        </Button>
      </span>
    </HtmlTooltip>
  );
};
