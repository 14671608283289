import { axiosApi } from "@next/api";
import { backendUrl } from "urls";
import { AnalyticFilters, OrdersReport, RequestsReport, SupplierReport } from "../redux";
import { generateReportQuery } from "../utils/generateReportQuery";

const fetchRequestReport = (filters: AnalyticFilters) => {
  const query = generateReportQuery("requests", filters);

  return axiosApi.get<RequestsReport>(`${backendUrl.analyticsRfqs}/${query}`);
};

const fetchOrdersReport = (filters: AnalyticFilters) => {
  const query = generateReportQuery("requests", filters);

  return axiosApi.get<OrdersReport>(`${backendUrl.analyticsOrders}/${query}`);
};

const fetchSupplierReport = () => {
  return axiosApi.get<SupplierReport>(`${backendUrl.analyticsRfqs}`);
};

export const analyticsService = {
  fetchRequestReport,
  fetchOrdersReport,
  fetchSupplierReport,
};
