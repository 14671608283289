import * as yup from "yup";
import { t } from "assets/configi18n/i18n";
import { RFQPartsRevisionFormValues } from "./rfq-parts-revision-form.types";

export const getInitialValues = (): RFQPartsRevisionFormValues => {
  return {
    suppliers: [],
    note: "",
  };
};

export const validationSchema = yup.object().shape({
  suppliers: yup.array().required(t("userOnboarding:thisFieldIsRequired")),
  note: yup.string().required(t("userOnboarding:thisFieldIsRequired")),
});
