import React from "react";

import { useSelector } from "react-redux";

import { Card } from "@mui/material";

import { BuyerStatsCreatedByInfos } from "./buyer-stats-created-by-infos/buyer-stats-created-by-infos.component";
import { BuyerStatsCompanyInfos } from "./buyer-stats-company-infos/buyer-stats-company-infos.component";
import { useTranslation } from "react-i18next";

const BuyerStats = () => {
  const { t } = useTranslation("workspace");
  const language = useSelector((state) => state.profile.user.language);
  const buyerStats = useSelector((state) => state.marketplace.buyerStats);
  const rfqDetails = useSelector((state) => state.marketplace.activeRfqDetails);

  return (
    <Card elevation={0} className="c-buyer-stats">
      <BuyerStatsCompanyInfos buyerStats={buyerStats} language={language} t={t} />
      <BuyerStatsCreatedByInfos rfqDetails={rfqDetails} language={language} t={t} />
    </Card>
  );
};

export default BuyerStats;
