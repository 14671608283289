import React from "react";
import { Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import MyPartsTable from "../../components/my-parts-table";
import { PartModal } from "../../modals/part-modal";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    header: {
      display: "flex",
      flexDirection: "column",
    },
    titleContainer: { display: "flex", alignItems: "center" },
    content: { marginTop: theme.spacing(2) },
  })
);

export const MyParts = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <Typography variant="h5">{t("workspaceNext:myParts:title")}</Typography>
        </div>
      </div>

      <div className={classes.content}>
        <MyPartsTable isReadOnly={true} />
      </div>

      <PartModal />
    </div>
  );
};
