import React, { useCallback, useMemo } from "react";
import { FieldArray, useField } from "formik";
import { getUserDisplayName } from "@next/modules/profile/helpers/profile-helpers";
import { Box, Typography } from "@mui/material";
import { Skeleton } from "@mui/material";
import PaginatedAutoComplete from "@next/components/paginated-autocomplete";
import { PaginatedAutoCompleteProps } from "@next/components/paginated-autocomplete/paginated-autocomplete";
import { GenericUser } from "@next/modules/profile/redux";
import GenericAvatar from "@next/components/generic-avatar/generic-avatar";
import CustomListItem from "./custom-list-item";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { CustomTypography } from "@next/components/custom-typography";
import { createFilterOptions } from "@mui/material/useAutocomplete";

export const UserListItem = ({ option }: { option: Option }) => {
  const displayName = getUserDisplayName(option);

  return (
    <Box display="flex" alignItems="center" justifyContent={"space-between"} width={"100%"}>
      <Box display="flex" alignItems="center" gap="8px">
        <GenericAvatar url={option.picture || ""} name={displayName || option.email} size="large" />
        <Box>
          <Typography variant="body2">{displayName}</Typography>
          <Typography variant="body2" color="textSecondary">
            {option.email}
          </Typography>
        </Box>
      </Box>
      {option.tag ? (
        <Box textAlign={"right"} width={250}>
          <CustomTypography variant="caption">{option.tag}</CustomTypography>
        </Box>
      ) : null}
    </Box>
  );
};

export const UserListItemLoading = () => {
  return (
    <CustomListItem>
      <Box display="flex" alignItems="center">
        <Skeleton width={32} height={32} variant="circular" />

        <Box ml={1} flex="1">
          <Skeleton width="100%" height={20} variant="rectangular" />
        </Box>
      </Box>
    </CustomListItem>
  );
};

type Option = {
  disabled?: boolean;
  tag?: string;
} & GenericUser;

type Props = {
  fieldName: string;
  placeholder: string;
  options: Option[];
  valueKey: keyof GenericUser | undefined;
  allowInvite?: boolean;
  onInvite?: (value: string) => void;
  showSelected?: boolean;
} & Partial<PaginatedAutoCompleteProps>;

const UsersPaginatedAutoComplete: React.FC<Props> = ({
  fieldName,
  placeholder,
  options,
  valueKey,
  allowInvite,
  onInvite,
  multiple = true,
  loading,
  showSelected = true,
  ...props
}) => {
  const [field, _, fieldHelpers] = useField(fieldName);
  const { t } = useTranslation();

  const value = useMemo(() => {
    if (valueKey) {
      return field.value
        ? Array.isArray(field.value)
          ? field.value
              .map((prop) => options.find((user) => user[valueKey] === prop))
              .filter((item) => !!item)
          : options.find((user) => user[valueKey] === field.value)
        : undefined;
    } else {
      return field.value;
    }
  }, [options, field.value]);
  const createdFilterOptions = useMemo(
    () =>
      createFilterOptions({
        stringify: (option: GenericUser) => getUserDisplayName(option) + " " + option.email,
        trim: true,
      }),
    []
  );

  const filterOptions = useCallback(
    (options: any, state: any) => {
      const filtered = createdFilterOptions(options, state);

      if (
        allowInvite &&
        state.inputValue?.length > 0 &&
        !filtered.find((item) => item.email === state.inputValue)
      ) {
        filtered.push({
          __invite: true,
        });
      }

      return filtered;
    },
    [createFilterOptions, allowInvite]
  );

  const renderOption = useCallback(
    (user, { inputValue }) => {
      const showInvite = allowInvite && user.__invite;

      return showInvite ? (
        <Box
          onClick={(e) => {
            e.stopPropagation();
            if (typeof onInvite === "function") onInvite(inputValue);
          }}
          display="flex"
          alignItems="center"
          width={"100%"}
        >
          <Box mr={2}>
            <AddIcon color="primary" />
          </Box>
          <Typography variant="body1" color="primary">
            {`${t("common:invite")} "${inputValue}"`}
          </Typography>
        </Box>
      ) : (
        <UserListItem option={user} />
      );
    },
    [allowInvite]
  );

  const getInputFieldValue = useCallback((value: GenericUser) => {
    const displayName = getUserDisplayName(value);
    return `${displayName ? displayName + " - " : ""}${value.email}`;
  }, []);

  const onSelectionChange = useCallback(
    (user) => {
      if (valueKey) {
        fieldHelpers.setValue(
          Array.isArray(user) ? user.map((item) => item?.[valueKey] || "") : user?.[valueKey] || ""
        );
      } else {
        fieldHelpers.setValue(user);
      }
    },
    [valueKey]
  );

  const getOptionSelected = useCallback((option, value) => option.email === value.email, []);

  return (
    <Box>
      <PaginatedAutoComplete
        renderOption={renderOption}
        getOptionSelected={getOptionSelected}
        disableClearable={multiple}
        disableCloseOnSelect={multiple}
        getInputFieldValue={multiple ? undefined : getInputFieldValue}
        filterOptions={filterOptions}
        multiple={multiple}
        loading={loading}
        getOptionDisabled={(option: Option) => !!option.disabled}
        onSelectionChange={onSelectionChange}
        {...props}
        name={field.name}
        value={value}
        options={options}
        placeholder={placeholder}
      />

      {multiple && showSelected ? (
        <Box mt={2} pr={1} display={"flex"} flexDirection={"column"} gap={"16px"}>
          {loading ? (
            Array(field.value?.length || 0)
              .fill(true)
              .map((_, ix) => <UserListItemLoading key={ix} />)
          ) : (
            <FieldArray
              name={field.name}
              render={(arrayHelpers) =>
                (value as GenericUser[]).map((user, index) => {
                  const disabled = !!user.disabled;

                  return (
                    <CustomListItem
                      key={user.id || user.email}
                      onRemove={disabled ? undefined : () => arrayHelpers.remove(index)}
                    >
                      <UserListItem option={user} />
                    </CustomListItem>
                  );
                })
              }
            />
          )}
        </Box>
      ) : null}
    </Box>
  );
};

export default UsersPaginatedAutoComplete;
