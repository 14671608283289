import { Box, Button, Card, CardActionArea, CardMedia, Theme, Typography } from "@mui/material";
import React from "react";
import { IconCircle } from "@next/components/icon-circle/icon-circle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { makeStyles } from "@mui/styles";

type StyleProps = {
  bgImage?: string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => {
  return {
    root: {
      width: "100%",
      maxWidth: "736px",
      height: "400px",
      borderRadius: "16px",
      "&:hover $media": {
        transform: "scale(1.2)",
      },
    },
    media: {
      height: "100%",
      width: "100%",
      backgroundSize: "cover",
      transition: "transform 0.3s ease-in-out",
    },
    actionArea: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    contentWrapper: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 1,
      color: theme.palette.common.white,
      display: "flex",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "24px",
    },
    icon: {
      border: "none",
    },
  };
});

type Props = {
  Icon?: React.ReactNode;
  title: string;
  subTitle: string;
  onClick: () => void;
  bgImage: string;
  buttonText: string;
};

export const FreemiumDashboardCard: React.FC<Props> = ({
  Icon,
  title,
  subTitle,
  onClick,
  bgImage,
  buttonText,
}) => {
  const classes = useStyles({ bgImage });

  return (
    <Card className={classes.root}>
      <CardActionArea className={classes.actionArea} onClick={onClick}>
        <CardMedia className={classes.media} image={bgImage} />

        <Box className={classes.overlay} />

        <Box className={classes.contentWrapper}>
          <Box className={classes.content}>
            <IconCircle className={classes.icon} revert>
              {Icon}
            </IconCircle>

            <Box>
              <Box>
                <Typography variant="h4">{title}</Typography>
              </Box>

              <Box mt="16px">
                <Typography variant="body1">{subTitle}</Typography>
              </Box>

              <Box mt="16px">
                <Button variant="contained" color="secondary" startIcon={<ArrowForwardIcon />}>
                  {buttonText}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
};
