import React from "react";
import SwitchSubscription from "../../../../switch-subscription";
import { EventType, FormLabelText } from "@next/modules/profile/redux";
import { useSelector } from "react-redux";
import { selectSubscriptionList } from "@next/modules/profile/redux/selectors";
import { Box } from "@mui/material";
import SwitchSubscriptionRfqRecap from "./switch-subscription-rfq-recap";

const NotificationsSwitches: React.FC = () => {
  const subscriptionsList = useSelector(selectSubscriptionList);

  const filterSubscriptionsList = subscriptionsList?.filter(
    (subscription) =>
      subscription.event_type !== EventType.CREATE_RFQ &&
      subscription.event_type !== EventType.RFQS_RECAP
  );
  const rfqRecapSubscription = subscriptionsList?.find(
    (item) => item.event_type === EventType.RFQS_RECAP
  );

  return (
    <div>
      {rfqRecapSubscription ? (
        <Box mb="32px">
          <SwitchSubscriptionRfqRecap rfqRecapSubscription={rfqRecapSubscription} />
        </Box>
      ) : null}

      {filterSubscriptionsList?.length ? (
        <SwitchSubscription
          title={FormLabelText.SETTINGS_NOTIFICATIONS_LABEL}
          subscriptions={filterSubscriptionsList}
        />
      ) : null}
    </div>
  );
};

export default NotificationsSwitches;
