import {
  Box,
  Chip,
  CircularProgress,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import React from "react";
import { BulkFileImportSummary } from "./bulk-file-import-summary";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import LocalCafeOutlinedIcon from "@mui/icons-material/LocalCafeOutlined";
import { selectUploadBomFilesLoading } from "@next/modules/project/redux/selectors";
import { BulkFileImportFor } from "@next/modals/shared-bulk-files-import-modal";
import { selectUploadOrderBulkFilesLoading } from "@next/modules/orders/redux/selectors";

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      width: "900px",
      height: "596px",
    },
    loading: {
      width: "600px",
      height: "480px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    loadingTitle: {
      marginTop: "24px",
    },
    loadingSubtitle: {
      marginTop: "12px",
    },
  })
);

type Props = {
  onCloseModal: () => void;
  filesCount: number;
  onAttachmentsChange?: (files: FileData[], filePkToDelete?: number) => void;
  bulkFileImportFor: BulkFileImportFor;
};

export const BulkFileImport: React.FC<Props> = ({
  onCloseModal,
  filesCount,
  onAttachmentsChange,
  bulkFileImportFor,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const loadingSelector =
    bulkFileImportFor === BulkFileImportFor.PROJECT
      ? selectUploadBomFilesLoading
      : selectUploadOrderBulkFilesLoading;

  const uploadBomFilesLoading = useSelector(loadingSelector);

  return uploadBomFilesLoading ? (
    <div className={classes.loading}>
      <CircularProgress />

      <Typography className={classes.loadingTitle} variant="h6">
        {t("project:bulkFileImport:loadingTitle", { count: filesCount })}
      </Typography>
      <Box className={classes.loadingSubtitle}>
        <Chip
          icon={<LocalCafeOutlinedIcon className={classes.cafeIcon} />}
          label={
            <Typography variant="caption">
              {t("project:bulkPartImportLoader:loadingSubtitle")}
            </Typography>
          }
        />
      </Box>
    </div>
  ) : (
    <Box className={classes.root}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{t("project:bulkFileImport:summary:title")}</Box>

          <Box>
            <IconButton onClick={onCloseModal} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <BulkFileImportSummary
        onClickCloseReport={onCloseModal}
        onAttachmentsChange={onAttachmentsChange}
        bulkFileImportFor={bulkFileImportFor}
      />
    </Box>
  );
};
