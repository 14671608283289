import { GridColDef } from "@mui/x-data-grid-pro-v5";

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.3,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
};

export const getAddSpecListModalTableColumns = () => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "specType",
      headerName: "Spec type",
    },
    {
      ...defaultColumnConfig,
      field: "name",
      headerName: "Specification",
    },
    {
      ...defaultColumnConfig,
      field: "dash_ref",
      headerName: "Dash",
      renderCell: (params) => {
        const value = params.value;
        return value === "nan" ? "n/a" : value;
      },
    },
    {
      ...defaultColumnConfig,
      field: "revision",
      headerName: "Revision",
    },
    {
      ...defaultColumnConfig,
      field: "controlled_process",
      headerName: "Controlled process",
    },
    {
      ...defaultColumnConfig,
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];

  return columns;
};
