import { SubtierSupplier, SubtierSupplierResponse } from "../redux";

export const convertToSubtierSupplier = (
  subtierSupplierResponses: SubtierSupplierResponse[]
): SubtierSupplier[] => {
  return subtierSupplierResponses.map((supplier) => ({
    id: supplier.company_id,
    title: supplier.title,
  }));
};
