import { Button, Typography, styled } from "@mui/material";
import React from "react";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { AMQHowItWorksModal } from "@next/modules/workspace/modals/amq-how-it-works-modal";

const WhiteButton = styled(Button)({
  borderColor: "#fff",
  color: "#fff",
});

const PublicRfqCreationDescription = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClickHowDoesItWork = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.AMQ_HOW_IT_WORKS_MODAL,
      })
    );
  };

  return (
    <>
      <Typography variant="h4">{t("workspaceNext:publicRfqOLD:text:title")}</Typography>

      <Typography variant="h5">{t("workspaceNext:publicRfqOLD:text:subTitle")}</Typography>

      <Typography variant="body1">
        <ul>
          <li>{t("workspaceNext:publicRfqOLD:text:description1")}</li>
          <li>{t("workspaceNext:publicRfqOLD:text:description2")}</li>
          <li>{t("workspaceNext:publicRfqOLD:text:description3")}</li>
        </ul>
      </Typography>

      <WhiteButton
        startIcon={<PlayArrowRoundedIcon />}
        variant={"outlined"}
        onClick={onClickHowDoesItWork}
      >
        {t("workspaceNext:publicRfqOLD:text:howDoesItWork")}
      </WhiteButton>

      <AMQHowItWorksModal />
    </>
  );
};

export default PublicRfqCreationDescription;
