import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { SubtierReadinessFormTable } from "../../../table/subtier-readiness-form-table/subtier-readiness-form-table";
import { FormFieldErrorTypography } from "../../../form-field-error-typography/form-field-error-typography";
import {
  FieldMandatoryText,
  SubtierSupplier,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectCurrentMessageThread,
  selectIsFetchSpecByClickedThread,
  selectIsFormDisplayForValidationManagement,
  selectNameOfTableToFetchSpecByClickedThread,
  selectSpecsOfSubtierSupplier,
  selectTriggerForFetchingSpecsOfStep,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./subtier-card.styled";

type Props = {
  subtierSupplier: SubtierSupplier;
  handleClickAddSpecsBtn: (subtierSupplier: SubtierSupplier) => void;
  isShowTableErrors: boolean;
  isTableDisabled: boolean;
};

const text =
  "Thanks to declare if any qualified specifications are missing from the list above, " +
  "click 'Add Spec' and search through the complete list to include them.(CP / Not controlled process / NDT, Test ...).";

export const SubtierCard: React.FC<Props> = ({
  subtierSupplier,
  handleClickAddSpecsBtn,
  isShowTableErrors,
  isTableDisabled,
}) => {
  const dispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const activeFormStep = steps[activeFormStepIndex];
  const rows = useSelector(selectSpecsOfSubtierSupplier(subtierSupplier.id));
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );
  const triggerStateForFetchingSpecs = useSelector(selectTriggerForFetchingSpecsOfStep);
  const currentMessageThread = useSelector(selectCurrentMessageThread);
  const isFetchSpecByClickedThread = useSelector(selectIsFetchSpecByClickedThread);
  const nameOfTableToFetchSpecByClickedThread = useSelector(
    selectNameOfTableToFetchSpecByClickedThread
  );

  useEffect(() => {
    fetchSpecs();

    dispatch(
      vendorManagementActions.fetchNumberOfUnapprovedLinesOfStepRequest({
        companyId: companyId,
        stepId: activeFormStep.step,
      })
    );
  }, [triggerStateForFetchingSpecs, isFetchSpecByClickedThread]);

  const fetchSpecs = () => {
    if (
      isFetchSpecByClickedThread &&
      nameOfTableToFetchSpecByClickedThread === subtierSupplier.title
    ) {
      dispatch(
        vendorManagementActions.fetchSpecsOfSubtierSupplierRequest({
          companyId: companyId,
          supplierId: subtierSupplier.id,
          entityId: currentMessageThread?.entity_id,
        })
      );
    } else {
      dispatch(
        vendorManagementActions.fetchSpecsOfSubtierSupplierRequest({
          companyId: companyId,
          supplierId: subtierSupplier.id,
        })
      );
    }
  };

  return (
    <S.StyledBox>
      <S.StyledTitle tooltipTitle={null}>{subtierSupplier.title}</S.StyledTitle>

      <S.StyledTableWrapper>
        <SubtierReadinessFormTable
          subtierSupplierId={subtierSupplier.id}
          isTableDisabled={isTableDisabled}
          subtierTitle={subtierSupplier.title}
        />
      </S.StyledTableWrapper>

      {!isFormDisplayForValidationManagement ? (
        <>
          <S.StyledButtonBox>
            <Box>
              <S.StyledButton
                startIcon={<AddIcon fontSize="large" />}
                variant="contained"
                disableElevation
                onClick={() => handleClickAddSpecsBtn(subtierSupplier)}
                disabled={
                  isTableDisabled ||
                  (isFetchSpecByClickedThread &&
                    nameOfTableToFetchSpecByClickedThread === subtierSupplier.title)
                }
                fullWidth={false}
              >
                <S.StyledButtonTypography variant="body2">
                  {`Add ${subtierSupplier.title} specs`}
                </S.StyledButtonTypography>
              </S.StyledButton>
            </Box>

            <S.StyledTypography variant="body2">{text}</S.StyledTypography>
          </S.StyledButtonBox>

          {isShowTableErrors && rows.length === 0 ? (
            <FormFieldErrorTypography text={FieldMandatoryText} />
          ) : null}
        </>
      ) : null}
    </S.StyledBox>
  );
};
