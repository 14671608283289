import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppTheme } from "layout/AppTheme";
import { Box, Link, Tooltip, Typography } from "@mui/material";
import StarsRoundedIcon from "@mui/icons-material/StarsRounded";
import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro-v5";
import { getCompany } from "services/profile/profile.selectors";
import { t } from "assets/configi18n/i18n";
import { AvatarWithStatus } from "@next/components/avatar-with-status";
import { openProfileInNewTab } from "@next/utils/browserUtils";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { SelectSuppliersGroupAutocomplete } from "../select-suppliers-group-autocomplete/select-suppliers-group-autocomplete";
import { FavoriteSupplier, FavoriteSupplierGroup, workspaceNextActions } from "../../redux";
import { grey } from "@mui/material/colors";
import InfoIcon from "@mui/icons-material/Info";
import IconButtonCell from "@next/components/table-cells/icon-button-cell-v5";
import SupplierContactsList from "../supplier-contacts-list/supplier-contacts-list";
import { GenericUser } from "@next/modules/profile/redux";
import { selectUpdateSupplierContactsListLoading } from "../../redux/selectors";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";
import TextCell from "@next/components/table-cells/text-cell-v5";
import { SuppliersField } from "../suppliers-table/suppliers-table-v5.types";
import { DistributionForSupplierContacts } from "@next/modals/shared-supplier-contact-lists-modal";

const NameCell: React.FC<GridRenderCellParams> = ({ value, row }) => {
  const axyaTracking = useAxyaTracking();
  const company = useSelector(getCompany);

  const isASL = row?.is_asl;
  const isPermium = row?.is_premium;

  const onClickOpenSupplierProfile = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    openProfileInNewTab(row?.name, row?.uuid);
    axyaTracking({
      app: "axya-app",
      scout_category: "axya-supplier-profile",
      scout_feature_name: "supplier-profile-redirection-from-my-supplier-list",
    });
  };

  const tooltipTitle = isASL
    ? t("workspaceNext:supplierTable:row:approvedASLCompanyTooltip", {
        buyer: company?.name,
      })
    : t("workspaceNext:supplierTable:row:nonASLCompanyTooltip");

  const badgeTitle = isASL
    ? t("workspaceNext:supplierTable:row:approvedASLCompany")
    : t("workspaceNext:supplierTable:row:nonASLCompany");

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <AvatarWithStatus isOnline={row?.is_online} avatarProps={{ src: row?.picture }} />
      <Box paddingLeft="8px" display="flex" flexDirection="column">
        <Box mb={0.4}>
          <Typography variant="subtitle1">
            <Link
              onClick={onClickOpenSupplierProfile}
              component="button"
              color="secondary"
              style={{ textUnderlineOffset: "2px" }}
            >
              {value}
            </Link>
            {isPermium && (
              <StarsRoundedIcon
                style={{
                  fontSize: "20px",
                  marginLeft: "4px",
                  color: AppTheme.palette.success.main,
                }}
              />
            )}
          </Typography>
        </Box>

        <Tooltip title={tooltipTitle}>
          <Box
            width="fit-content"
            padding="1px 5px"
            borderRadius="20px"
            color={isASL ? "white" : "black"}
            bgcolor={isASL ? AppTheme.palette.primary.main : "white"}
            border={isASL ? 0 : 1}
            borderColor={AppTheme.palette.grey[500]}
          >
            <Typography variant="subtitle2" style={{ fontSize: "10px" }}>
              {badgeTitle}
            </Typography>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

const SupplierContactsListCell: React.FC<GridRenderCellParams> = ({ value, row }) => {
  const dispatch = useDispatch();
  const handleUpdateSupplierContactsList = (target_profiles: GenericUser[]) => {
    const payload = target_profiles.map((user: GenericUser) => ({
      id: user.id,
      is_po_distribution: user.is_po_distribution,
      is_rfq_distribution: user.is_rfq_distribution,
    }));

    dispatch(
      workspaceNextActions.updateSupplierContactsListRequest({
        supplierId: row?.pk as number,
        target_profiles: payload,
        onSuccess: () => {
          dispatch(modalsActions.closeModal(SharedModalTypes.SUPPLIER_CONTACTS_LIST_MODAL));
        },
      })
    );
  };
  return (
    <SupplierContactsList
      supplier={row as FavoriteSupplier}
      users={value as GenericUser[]}
      editMode
      includeEmailInTooltip
      onValidate={handleUpdateSupplierContactsList}
      loadingSelector={selectUpdateSupplierContactsListLoading}
      distributionForSupplierContacts={DistributionForSupplierContacts.ALL}
    />
  );
};

export const SupplierGroupHeaderCell: React.FC<GridColumnHeaderParams> = (
  params: GridColumnHeaderParams
) => {
  return (
    <Box display={"flex"} alignItems="center">
      <Typography variant="body2" style={{ fontWeight: 700 }}>
        {params?.colDef?.headerName}
      </Typography>
      <Tooltip title={t("workspaceNext:supplierTable:header:groupTooltip") || ""}>
        <InfoIcon
          style={{
            marginLeft: 5,
            width: 15,
            height: 15,
            color: grey[500],
          }}
        />
      </Tooltip>
    </Box>
  );
};

const SupplierGroupCell: React.FC<GridRenderCellParams> = ({ value, row }) => {
  return (
    <Box width="100%">
      <SelectSuppliersGroupAutocomplete
        favoriteSupplierPk={row?.pk}
        initialValue={value as FavoriteSupplierGroup[]}
      />
    </Box>
  );
};

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
};

export const getMySuppliersColumns = () => {
  const _columns: GridColumns = [
    {
      ...defaultColumnConfig,
      field: SuppliersField.NAME,
      headerName: t("workspaceNext:supplierTable:header:company"),
      renderCell: NameCell,
      flex: 0.5,
    },
    {
      ...defaultColumnConfig,
      field: SuppliersField.VENDOR_ID,
      headerName: t("workspaceNext:supplierTable:header:vendorID"),
      flex: 0.5,
      renderCell: TextCell,
    },
    // {
    //   ...defaultColumnConfig,
    //   field: SuppliersField.PHONE,
    //   headerName: t("workspaceNext:supplierTable:header:phone"),
    //   width: 200,
    // },
    {
      ...defaultColumnConfig,
      field: SuppliersField.CONTACTS_LIST,
      headerName: t("workspaceNext:supplierTable:header:distributionList"),
      renderCell: SupplierContactsListCell,
      flex: 0.5,
    },
    {
      ...defaultColumnConfig,
      field: SuppliersField.GROUP,
      headerName: t("workspaceNext:supplierTable:header:group"),
      renderCell: SupplierGroupCell,
      renderHeader: SupplierGroupHeaderCell,
      flex: 1,
    },
    // {
    //   ...defaultColumnConfig,
    //   field: SuppliersField.RFQS_SENT,
    //   headerName: t("workspaceNext:supplierTable:header:rfqsSent"),
    //   flex: 1,
    //   align: "right",
    //   headerAlign: "right",
    // },
    // {
    //   ...defaultColumnConfig,
    //   field: SuppliersField.QUOTES_RECEIVED,
    //   headerName: t("workspaceNext:supplierTable:header:quotesReceived"),
    //   flex: 1,
    //   align: "right",
    //   headerAlign: "right",
    // },
    // {
    //   ...defaultColumnConfig,
    //   field: SuppliersField.CONTRACTS_WON,
    //   headerName: t("workspaceNext:supplierTable:header:contractsWon"),
    //   flex: 0.9,
    //   align: "right",
    //   headerAlign: "right",
    // },
    {
      ...defaultColumnConfig,
      field: SuppliersField.EDIT_BUTTON,
      headerName: " ",
      width: 40,
      align: "center",
      renderCell: IconButtonCell,
    },
  ];

  return _columns;
};
