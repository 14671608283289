import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { ProjectModalTypes } from "./types";
import { useTranslation } from "react-i18next";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import {
  ProjectCreateRenameForm,
  ProjectCreateRenameFormVariant,
} from "../components/projects/project-create-rename-form/project-create-rename-form";
import Modal from "@next/components/custom-modal";

export type ProjectCreateRenameModalProps = {
  projectCode: string;
  projectName: string;
  variant: ProjectCreateRenameFormVariant;
};

export const ProjectCreateRenameModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(ProjectModalTypes.CREATE_RENAME_MODAL));
  const data = modal?.data as ProjectCreateRenameModalProps;

  if (!modal || !data) return null;

  return (
    <Modal
      handleModalClose={() =>
        dispatch(modalsActions.closeModal(ProjectModalTypes.CREATE_RENAME_MODAL))
      }
      modalState={{
        modalHeader:
          data?.variant === ProjectCreateRenameFormVariant.CREATE
            ? t("project:table:modals:createANewProject")
            : t("project:table:modals:renameProject"),
        modalBody: (
          <ProjectCreateRenameForm
            projectCode={data?.projectCode}
            projectName={data?.projectName}
            variant={data?.variant}
          />
        ),
      }}
      isModalOpen={true}
    />
  );
};
