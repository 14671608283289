import React from "react";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BackButton = (props) => {
  return (
    <Button
      onClick={props.onClick}
      className="c-back-button"
      size="small"
      startIcon={<ArrowBackIcon />}
    >
      {props.children}
    </Button>
  );
};

export default BackButton;
