import React from "react";
import Modal from "@next/components/custom-modal";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "./types";
import { useTranslation } from "react-i18next";
import { OrderDetailMarkAsShippedForm } from "../components/order-detail/order-detail-mark-as-shipped-form/order-detail-mark-as-shipped-form";
import { GridSelectionModel } from "@mui/x-data-grid-pro";

export const OrderMarkAsShippedModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useSelector(getModal(OrdersModalTypes.ORDER_MARK_AS_SHIPPED_MODAL));
  const data = modal?.data as {
    partsSelectionModel?: GridSelectionModel;
    isPortal?: boolean;
  };

  if (!modal || !data?.partsSelectionModel) return null;

  const onClose = () => {
    dispatch(modalsActions.closeModal(OrdersModalTypes.ORDER_MARK_AS_SHIPPED_MODAL));
  };

  return (
    <Modal
      handleModalClose={onClose}
      modalState={{
        modalHeader: t("orders:orderDetail:confirmShippingModal:header"),
        modalBody: (
          <OrderDetailMarkAsShippedForm
            onClose={onClose}
            partsSelectionModel={data?.partsSelectionModel}
            isPortal={data?.isPortal}
          />
        ),
      }}
      isModalOpen={true}
      customWidth="520px"
      disableContentPadding
    />
  );
};
