import { Box, Button, Typography, styled } from "@mui/material";
import AirbusBG from "../../../../assets/airbus-bg.jpeg";
import { CustomTypography } from "@next/components/custom-typography";

export const ImageBox = styled(Box)({
  backgroundImage: `url(${AirbusBG})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "100vh",
  width: "100%",
  backgroundPosition: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const StyledFormBox = styled(Box)(({ theme }) => ({
  width: "918px",
  height: "496px",
  display: "flex",
  flexDirection: "column",
  gap: "48px",
  padding: "48px",
  borderRadius: "16px",
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: "#ffffff",
  [theme.breakpoints.down("lg")]: {
    width: "750px",
    gap: "36px",
    padding: "36px",
    paddingTop: "48px",
  },
  [theme.breakpoints.down("md")]: {
    width: "618px",
    paddingTop: "36px",
    paddingBottom: "24px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "418px",
    height: "448px",
    gap: "16px",
    padding: "16px",
    paddingTop: "24px",
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  letterSpacing: "0.5px",
  width: "100%",
  "& li": {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));

export const StyledButton = styled(Button)({
  width: "92px",
  height: "40px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  gap: "8px",
  marginLeft: "auto",
  overflowY: "auto",
});

export const StyledButtonContainer = styled("div")({
  width: "100%",
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderRadius: "8px",
  gap: "8px",
  marginLeft: "auto",
  overflowY: "hidden",
});

export const StyledWatchWebinarButton = styled(Button)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  gap: "8px",
  marginLeft: "auto",
  overflowY: "auto",
  margin: "0 !important",
});

export const ButtonText = styled(CustomTypography)({
  marginLeft: "-8px",
});
