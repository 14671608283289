import React from "react";
import { Avatar, AvatarProps, Badge, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export type StyleProps = {
  isOnline?: number;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: ({ isOnline }) => (isOnline ? theme.palette.success.main : "lightgrey"),
      color: ({ isOnline }) => (isOnline ? theme.palette.success.main : "lightgrey"),
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: ({ isOnline }) => (isOnline ? "$ripple 1.2s infinite ease-in-out" : ""),
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  })
);

type Props = {
  avatarProps?: AvatarProps;
};

export const AvatarWithStatus: React.FC<Props & StyleProps> = ({ avatarProps, isOnline }) => {
  const classes = useStyles({ isOnline });

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      variant="dot"
      classes={classes}
    >
      <Avatar alt="Remy Sharp" {...avatarProps} />
    </Badge>
  );
};

export default AvatarWithStatus;
