import React from "react";
import {
  Box,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  IconButton,
  SvgIconProps,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getModal } from "@next/redux/modalsSlices";
import { BuyerProfileSendMessage } from "../components/buyer-profile-send-message/buyer-profile-send-message";
import CloseIcon from "@mui/icons-material/Close";
import { ProfileModalTypes } from "@next/modules/profile/modals/types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  curvedBox: {
    borderBottomLeftRadius: "24px",
    borderBottomRightRadius: "24px",
    background: "linear-gradient(45deg, #071930 48.75%, #2453B8 100%)",
  },
  curevedBoxContent: {
    color: "white",
    margin: "24px auto 24px auto",
    width: "890px",
  },
  cards: {
    marginTop: theme.spacing(1.5),
    display: "flex",
    justifyContent: "center",
    maxWidth: "1900px",
    padding: "0 auto",
  },
}));

type Props = {
  onSubmit: (values: any) => void;
  onClose: () => void;
  dialogTitle: string;
  descriptionTitle: string;
  descriptionText: string;
  descriptionIcon: React.ComponentType<SvgIconProps>;
  messageInputLabel: string;
  sendButtonText: string;
};

export const DiscoveryModal: React.FC = () => {
  const classes = useStyles();
  const modal = useSelector(getModal(ProfileModalTypes.USER_PROFILE_SEND_DISCOVERY_DRAWER));

  const data = modal?.data as Props;
  if (!modal || !data) return <Drawer></Drawer>;

  const RenderedDescriptionIcon = data.descriptionIcon;

  return (
    <Drawer
      PaperProps={{
        style: { width: "100%", height: "100%", transitionDuration: "500ms" },
      }}
      anchor="bottom"
      open={!!modal}
      onClose={data.onClose}
    >
      <DialogTitle style={{ height: "72px", paddingTop: "12px" }}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{data.dialogTitle}</Box>
          <Box pl="5px">
            <IconButton onClick={data.onClose} size="large">
              <CloseIcon style={{ color: "black" }} />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />
      <Box className={classes.curvedBox}>
        <Box className={classes.curevedBoxContent}>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {data.descriptionTitle}
              </Typography>
            </Grid>
            <Grid item>
              <RenderedDescriptionIcon />
            </Grid>
          </Grid>
          <Typography variant="subtitle2">{data.descriptionText}</Typography>
        </Box>
      </Box>
      {!!modal ? (
        <BuyerProfileSendMessage
          messageInputLabel={data.messageInputLabel}
          submitHandler={data.onSubmit}
          buttonText={data.sendButtonText}
        />
      ) : null}
    </Drawer>
  );
};
