import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useGridData } from "@next/hooks/useGridData-v5";
import { getCompanyMembersColumns } from "./company-members-table.columns";
import {
  selectCompanyProfile,
  selectCompanyProfileLoading,
} from "@next/modules/profile/redux/selectors";

export const useCompanyMembersTableData = () => {
  const companyProfile = useSelector(selectCompanyProfile);
  const companyProfileLoading = useSelector(selectCompanyProfileLoading);
  const companyMembersColumns = useMemo(
    () => getCompanyMembersColumns(companyProfile?.type),
    [companyProfileLoading]
  );
  const users = useMemo(
    () =>
      companyProfile?.users.map((user, i) => ({
        id: i,
        ...user,
      })),
    [companyProfile?.users]
  );
  const { gridData, setGridData } = useGridData({
    rows: users || [],
    columns: companyMembersColumns,
    loading: false,
  });

  return {
    gridData,
    setGridData,
  };
};
