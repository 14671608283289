import React, { useEffect } from "react";
import PublicRfqCreation from "../../components/public-rfq-creation";
import i18n from "assets/configi18n/i18n";
import { useQuery } from "@next/hooks/useQuery";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
    },
  })
);

export const PublicRfqAmq = () => {
  const classes = useStyles();
  const [query] = useQuery();
  const queryLanguage = query?.lang || "fr";

  useEffect(() => {
    i18n.changeLanguage(queryLanguage as string);
  }, [queryLanguage]);

  return (
    <div className={classes.root}>
      <PublicRfqCreation />
    </div>
  );
};
