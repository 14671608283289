import { socketConstants } from "./socket.constants";

export function connect() {
  return {
    type: "socket",
    types: [
      socketConstants.CONNECT_REQUEST,
      socketConstants.CONNECT_SUCCESS,
      socketConstants.CONNECT_FAILURE,
    ],
    promise: (socket) => socket.connect(),
  };
}

export function listenToNewMessages(token, email, room) {
  return {
    type: "socket",
    types: [
      socketConstants.JOIN_REQUEST,
      socketConstants.JOIN_SUCCESS,
      socketConstants.JOIN_FAILURE,
    ],
    promise: (socket) => {
      socket.emit("request-join", { token, email, room });
      // Make to rejoin the room if the user has disconnected.
      return socket.on("connect", () => {
        socket.emit("request-join", { token, email, room });
      });
    },
  };
}

/**
 * Listen for push updates from websockets.
 * An update could be anything like
 * @param {*} token
 * @param {*} email
 */
export function listenForUpdates(token, id) {
  return {
    type: "socket",
    types: [
      socketConstants.LISTEN_FOR_UPDATES_REQUEST,
      socketConstants.LISTEN_FOR_UPDATES_SUCCESS,
      socketConstants.LISTEN_FOR_UPDATES_FAILURE,
    ],
    promise: (socket) => {
      socket.emit("get-updates", { token, id });
      // Make to rejoin the room if the user has disconnected.
      return socket.on("connect", () => {
        socket.emit("get-updates", { token, id });
      });
    },
  };
}

export function onReceiveUpdates(callback) {
  return {
    type: "socket",
    types: [
      socketConstants.RECEIVE_UPDATES_REQUEST,
      socketConstants.RECEIVE_UPDATES_SUCCESS,
      socketConstants.RECEIVE_UPDATES_FAILURE,
    ],
    promise: (socket) => socket.on("updates", callback),
  };
}

export function emitMessage(token, message) {
  return {
    type: "socket",
    types: [
      socketConstants.EXCHANGE_REQUEST,
      socketConstants.EXCHANGE_SUCCESS,
      socketConstants.EXCHANGE_FAILURE,
    ],
    promise: (socket) => socket.exchange(token, message),
  };
}

export function onReceiveRFQRoomMsg(callback) {
  return {
    type: "socket",
    types: [
      socketConstants.RECEIVE_REQUEST,
      socketConstants.RECEIVE_SUCCESS,
      socketConstants.RECEIVE_FAILURE,
    ],
    promise: (socket) => {
      socket.socket.removeAllListeners("message");
      return socket.on("message", callback);
    },
  };
}
