import { axiosApi } from "@next/api";
import { NewsfeedPost } from "../redux/types";
import { backendUrl } from "urls";
import { generatePaginationAndSearchQuery } from "@next/utils/paginationUtils";
import { fetchNewsfeedInput } from "../redux/types";

const fetchNewsfeed = ({ page }: fetchNewsfeedInput) => {
  return axiosApi.get<NewsfeedPost[]>(
    `${backendUrl.newsfeed}/?${generatePaginationAndSearchQuery({
      currentPage: page,
      pageSize: 5,
    })}/`
  );
};

export const newsfeedService = {
  fetchNewsfeed,
};
