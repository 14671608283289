import { QCPartData, QCPartSupplier } from "./types";

export const makeCellSelectedById = (
  partsData: QCPartData[] = [],
  partId: number,
  supplierId: number
) => {
  return partsData.map((partData) => {
    return {
      ...partData,
      suppliers:
        partData.id === partId
          ? partData.suppliers.map((supplier) => ({
              ...supplier,
              selected: supplier?.selected ? false : supplier.id === supplierId,
            }))
          : partData.suppliers,
    };
  });
};

export const markLowestPriceSuppliers = (partsData: QCPartData[] = []) => {
  return partsData.map((part) => ({
    ...part,
    suppliers: part.suppliers.map((supplier) => ({
      ...supplier,
      selected: supplier.is_lowest_price,
    })),
  }));
};

export const calculateSupplierTotalPriceSum = (
  selectedSuppliersParts: QCPartSupplier[] = [],
  supplierId?: number
) => {
  return selectedSuppliersParts.reduce((sum, supplier) => {
    return (
      sum +
      (supplier.id === supplierId
        ? supplier.quotation_fields?.converted_price?.total_price || 0
        : 0)
    );
  }, 0);
};

export const markSelectAllBySupplierId = (partsData: QCPartData[] = [], supplierId: number) => {
  return partsData.map((part) => ({
    ...part,
    suppliers: part.suppliers.map((supplier) => ({
      ...supplier,
      selected:
        supplier?.quotation_fields?.converted_price?.total_price && supplierId === supplier?.id,
    })),
  }));
};
