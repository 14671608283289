import { TextField, styled } from "@mui/material";

interface StyledTextFieldProps {
  width?: string;
}

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "width",
})<StyledTextFieldProps>(({ theme, width }) => ({
  "& .MuiInput-underline:before": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  color: theme.palette.text.secondary,
  "& .MuiInputBase-input": {
    width: width || "180px",
  },
}));
