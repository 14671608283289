import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "./types";

import ContactUsForm from "../components/contact-us/contact-us-form";
import { ContactUsFormProps } from "../components/contact-us/contact-us.types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
}));

export const ContactUsModal: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const modal = useSelector(getModal(WorkspaceModalTypes.CONTACT_US_MODAL));

  const data = modal?.data as ContactUsFormProps;
  if (!modal || !data) return null;

  const onClickCloseModal = () => {
    dispatch(modalsActions.closeModal(WorkspaceModalTypes.CONTACT_US_MODAL));
  };

  return (
    <>
      <Dialog
        open={!!modal}
        onClose={onClickCloseModal}
        closeAfterTransition
        fullWidth={true}
        className={classes.root}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography variant="h6">{data.modalTitle}</Typography>
            </Box>
            <Box>
              <IconButton onClick={onClickCloseModal} size="large">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <ContactUsForm
            hasSubjectAndMsg={data.hasSubjectAndMsg}
            formTitle={data.formTitle}
            hasFormTitle={data.hasFormTitle}
            helpSubject={data.helpSubject}
            label={data.label}
            footerText={data.footerText}
            footerLinkText={data.footerLinkText}
            iconName={data.iconName}
            isAirbusSupportModal={data.isAirbusSupportModal}
            supplierDetails={data.supplierDetails}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
