import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OrdersReportTable as OrdersReportTableType } from "@next/modules/analytics/redux";
import { getOrdersReportTableColumns } from "./orders-report-table.columns";
import * as S from "./orders-report-table.styled";

export const OrdersReportTable: React.FC<OrdersReportTableType> = ({ id, rows }) => {
  const { t } = useTranslation();
  const columns = getOrdersReportTableColumns(t);

  return (
    <S.RootBox>
      <Box width="95%" pb="24px">
        <S.StyledDiv>
          <S.StyledTypography variant="h6">
            {t("analytics:ordersReport:table:title")}
          </S.StyledTypography>
        </S.StyledDiv>

        <S.StyledDataGridPro
          rows={rows}
          columns={columns}
          disableSelectionOnClick={true}
          disableColumnResize
          headerHeight={44}
          rowHeight={60}
          hideFooter
        />
      </Box>
    </S.RootBox>
  );
};
