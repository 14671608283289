// URL

import { backendUrl } from "../../urls";
// Helper
import { handleResponse } from "../../helpers/responseHandling";

import { store } from "store";
import { PAGINATION_PAGE_SIZE } from "@next/constants";
import { axiosApi } from "@next/api";

// Services Exported
export const inboxApi = {
  getQuotationMessages,
  getQuotationMessageDetails,
  sendQuotationMessage,
  logUserActivity,
  getInboxMessages,
  inboxFileUpload,
  deleteFileUpload,
  getQuotationThreadDetails,
};

function getQuotationThreadDetails(token, quotationId) {
  return axiosApi.get(`${backendUrl.inbox}${quotationId}`).then(handleResponse);
}

function getQuotationMessages(token, quotationId) {
  return axiosApi.get(`${backendUrl.inbox}${quotationId}/messages/`).then(handleResponse);
}

function getQuotationMessageDetails(token, quotationId, messageId) {
  return axiosApi
    .get(`${backendUrl.inbox}${quotationId}/messages/${messageId}`)
    .then(handleResponse);
}

function sendQuotationMessage(token, quotationId, data) {
  return axiosApi.post(`${backendUrl.inbox}${quotationId}/messages/`, data).then(handleResponse);
}

/**
 * Logs the user activity whenever he opens a Quotation Thread
 *
 * @param {String} token The token to authenticate the user
 * @param {Integer} quotationId The quotation id
 */
function logUserActivity(token, quotationId) {
  return axiosApi.post(`${backendUrl.inbox}${quotationId}/activities/`).then(handleResponse);
}

function getInboxMessages() {
  const state = store.getState();
  const messagesPage = state.inbox.messagesPage;
  const selectedMessagesFilterRfq = state.inbox.selectedMessagesFilterRfq;

  let url = `${backendUrl.inbox}?page=${messagesPage}&pageSize=${PAGINATION_PAGE_SIZE}`;

  if (selectedMessagesFilterRfq) {
    url = `${backendUrl.inbox}grouped/?rfq_id=${selectedMessagesFilterRfq?.pk}&page=${messagesPage}&pageSize=${PAGINATION_PAGE_SIZE}`;
  }

  return axiosApi.get(url).then(handleResponse);
}

//upload file inbox
function inboxFileUpload(token, file) {
  const formData = new FormData();
  formData.append("file", file);

  return axiosApi.post(backendUrl.inboxFileUpload, formData).then(handleResponse);
}

function deleteFileUpload(token, pk) {
  return axiosApi.delete(`${backendUrl.inboxFileUpload}${pk}`).then(handleResponse);
}
