// General Imports
import React, { useEffect } from "react";

// Links
import { frontendUrl } from "../../urls";

import { Redirect } from "react-router-dom";

import { CircularProgress, Grid } from "@mui/material";

import "./logout.scss";
import useLocalStorage from "hooks/useLocalStorage";
import { LOCAL_STOTAGE_RECENT_SEARCHES_KEY } from "@next/modals/components/global-search/global-search";

export function LogoutView(props) {
  const { refresh, user } = props.profile;
  const [_recentSearches, setRecentSearches] = useLocalStorage(
    LOCAL_STOTAGE_RECENT_SEARCHES_KEY,
    []
  );

  useEffect(() => {
    if (user && refresh) {
      props.logout(refresh);
    }
  }, [user, refresh]);

  useEffect(() => {
    setRecentSearches([]);
  }, []);

  return props.isLoggedIn ? (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      className="logout"
    >
      <Grid item>
        <CircularProgress size={80} thickness={4} />
      </Grid>
    </Grid>
  ) : (
    <Redirect to={frontendUrl.login} />
  );
}
