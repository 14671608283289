import { GridFilterItem, GridSortModel } from "@mui/x-data-grid-pro-v5";
import { OrdersTableField, OrdersTableSortField } from "./orders-table.types";
export const getSortQueryString = (sortModel: GridSortModel) => {
  if (!sortModel.length) return "";
  let queryString = "ordering=";
  sortModel.forEach((item, index) => {
    const directionStr = item.sort === "desc" ? "-" : "";
    if (index > 0) {
      queryString += ",";
    }
    switch (item.field) {
      case OrdersTableField.REQUEST:
        queryString += `${directionStr}${OrdersTableSortField.RFQ_NUMBER},${directionStr}${OrdersTableSortField.RFQ_NAME}`;
        break;
      case OrdersTableField.NAME:
        queryString += `${directionStr}${OrdersTableField.NAME}`;
        break;
      case OrdersTableField.ITEMS:
        queryString += `${directionStr}${OrdersTableField.ITEMS}`;
        break;
      case OrdersTableField.USERS:
        queryString += `${directionStr}${OrdersTableSortField.USERS}`;
        break;
      case OrdersTableField.REQUIRED_RECEPTION:
        queryString += `${directionStr}${OrdersTableField.REQUIRED_RECEPTION}`;
        break;
      case OrdersTableField.SENT_ON:
        queryString += `${directionStr}${OrdersTableField.SENT_ON}`;
        break;
      case OrdersTableField.SUPPLIER:
        queryString += `${directionStr}${OrdersTableSortField.SUPPLIER_NAME}`;
        break;
    }
  });
  return `${queryString}&`;
};

export const generateFilterQuery = (filterValues?: GridFilterItem[]) => {
  if (!filterValues || filterValues.length < 1) {
    return "";
  }

  let query = "";
  filterValues.forEach((filterValue) => {
    if (["", null, undefined].includes(filterValue.value)) return;
    // remove extra_fields. from columnField
    let columnField = filterValue.columnField?.replace("extra_fields.", "");
    if (columnField === OrdersTableField.REQUEST) columnField = "rfq";
    else if (columnField === OrdersTableField.USERS) columnField = OrdersTableSortField.USERS;

    switch (filterValue.operatorValue) {
      case "contains":
        query += `&${columnField}__icontains=${filterValue.value}`;
        break;
      case "equals":
        query += `&${columnField}__iexact=${filterValue.value}`;
        break;
      case "is":
        query += `&${columnField}=${filterValue.value}`;
        break;
      case "onOrAfter":
      case ">=":
        query += `&${columnField}__gte=${filterValue.value}`;
        break;
      case "onOrBefore":
      case "<=":
        query += `&${columnField}__lte=${filterValue.value}`;
        break;
      case "=":
        query += `&${columnField}=${filterValue.value}`;
        break;
      case "<":
        query += `&${columnField}__lt=${filterValue.value}`;
        break;
      case ">":
        query += `&${columnField}__gt=${filterValue.value}`;
        break;
      case "!=":
        query += `&${columnField}__neq=${filterValue.value}`;
        break;
    }
  });

  return query;
};
