import { Box, Divider, Theme } from "@mui/material";
import { GenericTextInput } from "components/utils/input/text";
import { Form, FormikProps } from "formik";
import React from "react";
import { MUIDatePicker } from "@next/components/mui-date-picker";
import CustomButton from "@next/components/custom-button/custom-button";
import {
  ReviewOrderFormState,
  convertLineItemsToLineItemsChanges,
} from "./review-order-form.definitions";
import { PreviewLineItemChanges } from "../preview-line-item-changes/preview-line-item-changes";
import { useTranslation } from "react-i18next";
import InlineFilePickerButton from "@next/components/inline-file-picker-button";
import { createStyles, makeStyles } from "@mui/styles";
import { selectNotSavedChangesInLineItems } from "../../redux/selectors";
import { useSelector } from "react-redux";

type StyleProps = {
  asModal?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      padding: ({ asModal }) => (asModal ? theme.spacing(3) : 0),
      gap: "24px",
    },
    attachmentButton: {
      width: "max-content",
      height: 40,
    },
  })
);

export const ReviewOrderFormBase: React.FC<
  FormikProps<ReviewOrderFormState> & {
    onClose: () => void;
    previewLineItemChanges?: boolean;
    buttonText?: string;
    loading?: boolean;
    showDateSelection?: boolean;
    notePlaceholder?: string;
    defaultDate?: string;
    isPortal?: boolean;
    dateInputLabel?: string;
    asModal?: boolean;
  }
> = ({
  handleSubmit,
  values,
  previewLineItemChanges,
  touched,
  errors,
  setFieldValue,
  buttonText,
  loading,
  showDateSelection,
  notePlaceholder,
  isPortal,
  dateInputLabel,
  onClose,
  asModal,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ asModal });
  const notSavedChangesInLineItems = useSelector(selectNotSavedChangesInLineItems(isPortal));
  const lineItemsChanges = convertLineItemsToLineItemsChanges(notSavedChangesInLineItems);
  return (
    <Form onSubmit={handleSubmit}>
      <Box className={classes.contentContainer}>
        {showDateSelection ? (
          <MUIDatePicker
            name="date"
            label={dateInputLabel}
            value={values?.date}
            onChange={(value) => {
              setFieldValue("date", value || "");
            }}
            error={touched["date"] && !!errors["date"]}
            helperText={touched["date"] && !!errors["date"] ? errors["date"] : ""}
            variant="inline"
          />
        ) : null}

        {previewLineItemChanges && lineItemsChanges?.length > 0 ? (
          <>
            <PreviewLineItemChanges lineItemsChanges={lineItemsChanges} padding="0px" />
          </>
        ) : null}

        {isPortal ? (
          <GenericTextInput
            name="update_note"
            label={notePlaceholder}
            value={values.update_note}
            autoComplete="off"
            multiline={true}
            size={!asModal ? "small" : "medium"}
            rows={asModal ? "2" : "1"}
          />
        ) : null}

        {isPortal ? (
          <InlineFilePickerButton
            file={values.confirmation_file || undefined}
            onFileAdded={(file) => setFieldValue("confirmation_file", file)}
            onFileRemoved={() => setFieldValue("confirmation_file", null)}
            btnProps={{
              variant: "outlined",
              size: "large",
              disabled: lineItemsChanges?.length === 0 && !asModal,
            }}
            rootClassName={classes.attachmentButton}
            pickerButtonText={t("orders:orderDetail:acknowledgeOrderModal:attachmentButton")}
          />
        ) : null}
      </Box>

      {asModal && <Divider />}

      <Box
        display="flex"
        justifyContent={asModal ? "flex-end" : "space-between"}
        px={asModal ? 2 : 0}
        py={3}
      >
        <CustomButton
          variant="outlined"
          color="error"
          onClick={onClose}
          sx={{ marginRight: "16px" }}
        >
          {t("common:cancel")}
        </CustomButton>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          loading={loading}
          disabled={lineItemsChanges?.length === 0 && !asModal}
        >
          {buttonText}
        </CustomButton>
      </Box>
    </Form>
  );
};
