import { User } from "@next/modules/profile/redux";
import { t } from "assets/configi18n/i18n";

export const getAccessLevelTitle = (userType: User["user_type"]) => {
  switch (userType) {
    case "admin":
      return t("profile:inviteCompanyUserModal:adminAccess");

    case "normal":
      return t("profile:inviteCompanyUserModal:buyerAccess");

    case "viewer":
      return t("profile:inviteCompanyUserModal:viewerAccess");

    case "restricted_viewer":
      return t("profile:inviteCompanyUserModal:engineerAccess");
  }
};

export const getAccessLevelHelperText = (userType: User["user_type"]) => {
  switch (userType) {
    case "admin":
      return t("profile:inviteCompanyUserModal:adminAccessHelpText");
    case "normal":
      return t("profile:inviteCompanyUserModal:buyerAccessHelpText");
    case "viewer":
      return t("profile:inviteCompanyUserModal:viewerAccessHelpText");
    case "restricted_viewer":
      return t("profile:inviteCompanyUserModal:engineerAccessHelpText");
  }
};
