import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AutocompleteRenderOptionState, Box, Checkbox } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { TextFieldProps, fieldToTextField } from "formik-material-ui";
import { GenericAutoCompleteTextField } from "components/generic-autocomplete-text-field/generic-autocomplete-text-field.component";
import { usePaymentTerms } from "./payment-terms.hooks";
import { getUser } from "services/profile/profile.selectors";
import { NetDsData } from "services/part-configuration/part-configuration.model";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const PaymentTerms: React.FC<TextFieldProps & any> = (props) => {
  const { t } = useTranslation();

  const { paymentTerms } = usePaymentTerms();

  const user = useSelector(getUser);

  const {
    textFieldProps,
    form: { setFieldValue },
    showHelperText = true,
  } = props;

  const { name, label, helperText, error, ...field } = fieldToTextField(props);

  return (
    <>
      <Autocomplete
        multiple={user.type === "DO" ? true : false}
        id="checkboxes-tags-demo"
        options={paymentTerms || []}
        disableCloseOnSelect
        getOptionLabel={(option: NetDsData) => option?.name || ""}
        renderOption={(
          props: React.HTMLAttributes<HTMLLIElement>,
          option: any,
          state: AutocompleteRenderOptionState
        ) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={state?.selected}
              color="primary"
            />
            {option.name}
          </li>
        )}
        {...props}
        {...field}
        onChange={(_: any, newValue: NetDsData) => {
          setFieldValue(name, newValue ?? null);
        }}
        renderTags={(tagValue) => (
          <Box pl={1}>
            {tagValue.map((option: unknown) => (option as NetDsData).name).join(", ")}
          </Box>
        )}
        renderInput={(params) => (
          <GenericAutoCompleteTextField
            {...params}
            {...textFieldProps}
            name={name}
            label={label}
            error={error}
            helperText={helperText}
          />
        )}
      />
      {user.type === "DO" && showHelperText && (
        <Box fontSize={12} pt={0.5} pl={2} pr={2} color="#757575">
          {t("pay:paymentTerms:paymentTermsHelperText")}
        </Box>
      )}
    </>
  );
};
