import React from "react";
import { Tooltip, styled } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

type TooltipProp = {
  tooltipTitle: string;
};

export const StyledIcon = styled(InfoIcon)`
  border-radius: 50%;
  color: white;
  background-color: ${({ theme }) => theme.palette.text.secondary};
`;

export const ModifiedTooltip = ({ tooltipTitle }: TooltipProp) => {
  return tooltipTitle ? (
    <Tooltip
      title={tooltipTitle}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -10],
              },
            },
          ],
        },
      }}
    >
      <StyledIcon fontSize="small" />
    </Tooltip>
  ) : null;
};
