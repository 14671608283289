import { Box, Chip, Theme, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import LocalCafeOutlinedIcon from "@mui/icons-material/LocalCafeOutlined";
import { BulkPartImportLoaderCircularProgress } from "./bulk-part-import-loader-circular-progress";
import { selectBulkPartImportReport } from "../../redux/selectors";
import { useSelector } from "react-redux";
import LeavePageBlocker from "@next/components/leave-page-blocker";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    loading: {
      width: "600px",
      height: "480px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    loadingTitle: {
      marginTop: "24px",
    },
    loadingSubtitle: {
      marginTop: "24px",
    },
    cafeIcon: {
      width: "17px",
      height: "17px",
      color: "black",
      marginLeft: "8px",
    },
  })
);

type Props = {};

export const BulkPartImportLoader: React.FC<Props> = ({}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const bulkPartImportReport = useSelector(selectBulkPartImportReport);

  const { totalCount, count, percentage } = useMemo(() => {
    const totalCount = bulkPartImportReport?.total_parts_count || 0;
    const count = bulkPartImportReport?.parts_processed_count || 0;

    return {
      totalCount,
      count,
      percentage: totalCount > 0 ? (count / totalCount) * 100 : 0,
    };
  }, [bulkPartImportReport]);

  return (
    <div className={classes.loading}>
      <LeavePageBlocker />

      <BulkPartImportLoaderCircularProgress value={percentage} />

      <Typography className={classes.loadingTitle} variant="h6">
        {t("project:bulkPartImportLoader:loadingTitle")}
      </Typography>

      <Typography className={classes.loadingTitle} variant="body1">
        {totalCount > 0 ? (
          <>
            {t("project:bulkPartImportLoader:partsImported", {
              count,
              totalCount,
            })}
          </>
        ) : (
          t("project:bulkPartImportLoader:calculating")
        )}
      </Typography>

      <Box className={classes.loadingSubtitle}>
        <Chip
          icon={<LocalCafeOutlinedIcon className={classes.cafeIcon} />}
          label={
            <Typography variant="caption">
              {t("project:bulkPartImportLoader:loadingSubtitle")}
            </Typography>
          }
        />
      </Box>
    </div>
  );
};
