import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import { useSuppliersTableData } from "./suppliers-section-hooks";
import { t } from "assets/configi18n/i18n";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "../../modals/types";
import SendIcon from "@mui/icons-material/Send";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { AddSuppliersModal } from "../../modals/add-suppliers-modal";
import RFQFollowupButton from "../rfq-followup-button";
import { selectUpdateRFQFollowupDateLoading } from "../../redux/selectors";
import { workspaceNextActions } from "../../redux";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { RequestType } from "../rfq-creation-form/rfq-creation.form.definitions";
import RFQPublicSwitch from "../rfq-creation-form/rfq-public-switch";
import { SuppliersTable } from "../suppliers-table/suppliers-table-v5";
import { Supplier } from "../suppliers-table/suppliers-table-v5.types";

type Props = {
  targetedSuppliers: Supplier[];
  marketplaceSuppliers: Supplier[];
  followupDate?: string;
  onPublicSwitchToggle: (is_enabled: boolean) => void;
  isPublicSwitchEnabled: boolean;
  requestType: RequestType;
  pk: number;
};

export const SuppliersSection: React.FC<Props> = ({
  targetedSuppliers,
  marketplaceSuppliers,
  followupDate,
  pk,
  isPublicSwitchEnabled,
  onPublicSwitchToggle,
  requestType,
}) => {
  const hasViewerRole = useHasCompanyRole(["viewer"]);
  const hasEngineeringRole = useHasCompanyRole(["restricted_viewer"]);
  const { gridData: targetedSuppliersData } = useSuppliersTableData(
    targetedSuppliers,
    hasEngineeringRole ? false : true
  );
  const { gridData: marketplaceSuppliersData } = useSuppliersTableData(marketplaceSuppliers, false);
  const followupDateLoading = useSelector(selectUpdateRFQFollowupDateLoading);
  const dispatch = useDispatch();
  const axyaTracking = useAxyaTracking();
  const emptySuppliers = !targetedSuppliers?.length && !marketplaceSuppliers?.length;

  const onClickOpenContactSuppliersModal = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.CONTACT_SUPPLIERS_MODAL,
      })
    );
  };

  const onClickOpenAddSuppliersModal = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.ADD_SUPPLIERS_MODAL,
      })
    );
  };

  const handleFollowupDateChange = (date: string | null) => {
    dispatch(
      workspaceNextActions.updateRFQFollowupDateRequest({
        pk,
        followupDate: date,
        onSuccess: () => {
          if (date)
            axyaTracking({
              scout_category: "usage_metric",
              scout_rfq_pk: pk,
              scout_action: "followup_date_set",
              data: { from: "rfq_details_page", followup_date: date },
            });
        },
      })
    );
  };

  return (
    <>
      {!emptySuppliers && (
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={0.5}>
            <Typography variant="h6">{t("rfq:suppliers")}</Typography>
            <Box ml={1} display="flex" gap="8px" flexWrap="wrap" alignItems="center">
              {hasEngineeringRole || hasViewerRole ? null : (
                <Button
                  size="medium"
                  variant="outlined"
                  onClick={onClickOpenAddSuppliersModal}
                  startIcon={<PersonAddIcon />}
                >
                  {t("rfq:addSuppliers")}
                </Button>
              )}
              <Button
                size="medium"
                variant="outlined"
                onClick={onClickOpenContactSuppliersModal}
                startIcon={<SendIcon />}
              >
                {t("rfq:contactSuppliers")}
              </Button>
              <RFQFollowupButton
                value={followupDate || null}
                onChange={handleFollowupDateChange}
                loading={followupDateLoading}
              />
            </Box>
          </Box>

          {targetedSuppliers?.length ? (
            <Box mt={2}>
              <SuppliersTable gridData={targetedSuppliersData} hideHeaders showContactsList />
            </Box>
          ) : null}

          <AddSuppliersModal />
        </Box>
      )}
      {requestType === RequestType.RFQ && (
        <>
          {hasEngineeringRole || hasViewerRole ? null : (
            <Box mt={4}>
              <RFQPublicSwitch
                isEnabled={isPublicSwitchEnabled}
                onToggle={onPublicSwitchToggle}
                disabled={isPublicSwitchEnabled}
              />
            </Box>
          )}
          {marketplaceSuppliers?.length ? (
            <SuppliersTable gridData={marketplaceSuppliersData} hideHeaders />
          ) : null}
        </>
      )}
    </>
  );
};
