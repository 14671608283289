import React, { useCallback } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Skeleton } from "@mui/material";

interface Location {
  lat: number;
  lng: number;
}

interface Props {
  location: Location;
  width?: string | number;
  height?: string | number;
}

function addLocationMarker(map: any, location: Location) {
  return new google.maps.Marker({
    position: location,
    map,
    zIndex: 100,
    draggable: false,
    animation: google.maps.Animation.DROP,
  });
}

const LocationMap: React.FC<Props & React.ComponentProps<typeof GoogleMap>> = ({
  location,
  width = "100%",
  height = "400px",
  zoom = 12,
  ...props
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDU5x7bbLjOPDZ5jnYvPaTzdzzkIwAoLQw",
  });

  const onLoad = useCallback(
    function callback(map) {
      const bounds = new google.maps.LatLngBounds(location);
      addLocationMarker(map, location);
      map.fitBounds(bounds);
      const listener = google.maps.event.addListener(map, "idle", function () {
        map.setZoom(zoom);
        google.maps.event.removeListener(listener);
      });
    },
    [location, zoom]
  );

  if (!isLoaded) return <Skeleton variant="rectangular" width={width} height={height} />;

  return (
    <GoogleMap
      key="google-map-key"
      mapContainerStyle={{ ...(props.mapContainerStyle || {}), width, height }}
      zoom={zoom}
      {...props}
      onLoad={onLoad}
      options={{
        mapId: "fbebe637cacf4e7d",
        mapTypeControl: false,
        zoomControl: true,
        scaleControl: true,
        streetViewControl: false,
        fullscreenControl: false,
        ...(props.options || {}),
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
          ...(props.options?.zoomControlOptions || {}),
        },
      }}
    />
  );
};

export default LocationMap;
