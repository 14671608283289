import { GenericObject } from "@next/modules/profile/redux";

export type MarketplaceState = {
  customQuotationExportLoading: boolean;
  customQuotationExportError?: Error | undefined;
  customQuotationImportLoading: boolean;
  customQuotationImportError?: Error | undefined;
  customQuotationImportedData: any;
  intentToNoQuoteLoading: boolean;
  intentToNoQuoteError?: Error | undefined;
  intentToNoQuoteData: IntentToNoQuoteData | GenericObject;
  isIntentToNoQuote: boolean;

  publicItbItnbLoading: boolean;
  publicItbItnbError?: Error | undefined;
};

export type CustomQuotationExportInput = {
  rfqId: number;
  fileName: string;
};

export type CustomQuotationImportInput = {
  rfqId: number;
  file: File;
};

export type IntentToNoQuoteInput = {
  rfqId: number;
  noQuoteReason?: string;
  reasonDescription?: string;
  onSuccess?: () => void;
};

export type IntentToNoQuoteData = {
  pk: number;
  rfq: number;
  reason: string;
  supplier: number;
};

export type CustomQuotationImportData = {
  pk: number;
  name: string;
  quotation_extra_fields: any;
};

export type PublicItbItnbRequestInput = {
  token: string;
  type: "itb" | "itnb";
  reason?: string;
  description?: string;
};

export type TenderData = {
  id: number;
  name: string;
  buyer: string;
  description: string | null;
  full_description: string | null;
  country: string;
  area: string;
  start_date: string;
  end_date: string;
  categories: string[];
  url: string;
  amount: number | null;
  amount_currency: number | null;
};

export enum RequestType {
  PREMIUM = "premium",
  DIRECT = "direct",
  TENDERS = "tenders",
}

export enum RequestStatus {
  ALL = "all",
  INTERESTED = "interested",
  NOT_INTERESTED = "not_interested",
}
