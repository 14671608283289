import { companyRatingsConstants } from "./index";

const initialState = {
  companyRatingsScores: {},
  isCompanyRatingsScoreLoading: false,
  isRequestRatingLoading: false,
};

export function companyRatings(state = initialState, action) {
  switch (action.type) {
    /**GET_COMPANY_RATINGS_SCORES_REQUEST */
    case companyRatingsConstants.GET_COMPANY_RATINGS_SCORES_REQUEST:
      return {
        ...state,
        isCompanyRatingsScoreLoading: true,
      };
    case companyRatingsConstants.GET_COMPANY_RATINGS_SCORES_SUCCESS:
      const companyRatingScoresById = {
        ...state.companyRatingsScores,
        [action.companyId]: action.companyRatingsScores,
      };

      return {
        ...state,
        companyRatingsScores: companyRatingScoresById,
        isCompanyRatingsScoreLoading: false,
      };
    case companyRatingsConstants.GET_COMPANY_RATINGS_SCORES_FAILURE:
      return { ...state, isCompanyRatingsScoreLoading: false };

    case companyRatingsConstants.CLEAR_COMPANY_RATINGS_SCORES:
      return {
        ...state,
        companyRatingsScores: {},
      };

    case companyRatingsConstants.SEND_REQUEST_RATINGS_REQUEST:
      return {
        ...state,
        isRequestRatingLoading: true,
      };
    case companyRatingsConstants.SEND_REQUEST_RATINGS_SUCCESS:
      return {
        ...state,
        isRequestRatingLoading: false,
      };
    case companyRatingsConstants.SEND_REQUEST_RATINGS_FAILURE:
      return { ...state, isRequestRatingLoading: false };

    default:
      return state;
  }
}
