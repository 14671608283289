import { GlobalSearchResult } from "@next/modules/workspace/redux/types";
import { t } from "assets/configi18n/i18n";
import { resultType } from "@next/modals/components/global-search/global-search.consts";
import { frontendUrl } from "urls";

export const getGlobalSearchTypeText = (result: GlobalSearchResult): string => {
  switch (result?.result_type) {
    case resultType.RFQ:
      return t("globalSearch:RFQ");
    case resultType.CONTRACT:
      return t("globalSearch:Contract");
    case resultType.PO:
      return t("globalSearch:PO");
    default:
      return "";
  }
};

export const getGlobalSearchEntryUrl = (result: GlobalSearchResult): string => {
  switch (result?.result_type) {
    case resultType.RFQ:
      return `${window.location.origin}${frontendUrl.bundleView}/${result.id}`;
    case resultType.CONTRACT:
      return `${window.location.origin}${frontendUrl.bundleView}/${result.id}`;
    case resultType.PO:
      return `${window.location.origin}${frontendUrl.orders}/${result.id}`;
    default:
      return "";
  }
};
