import { Box, Grid, Theme, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import KongsbergLogo from "@next/assets/kongsberg.png";
import TriparLogo from "@next/assets/tripar.png";
import EagleLogo from "@next/assets/eagle.png";
import BrouwerLogo from "@next/assets/brouwer.png";
import VentionLogo from "@next/assets/vention.png";
import Cert1Logo from "@next/assets/cert1.png";
import Cert2Logo from "@next/assets/cert2.png";
import Cert3Logo from "@next/assets/cert3.png";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    headline: {
      fontFamily: "Space Grotesk",
    },
  })
);

const PublicRfqLandingDescription = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h4" className={classes.headline}>
        {t("workspaceNext:publicRfqLanding:title")}
      </Typography>

      <Box mt="16px">
        <Typography variant="body1">
          <ol>
            <li>{t("workspaceNext:publicRfqLanding:step1")}</li>
            <li>{t("workspaceNext:publicRfqLanding:step2")}</li>
            <li>{t("workspaceNext:publicRfqLanding:step3")}</li>
            <li>{t("workspaceNext:publicRfqLanding:step4")}</li>
            <li>{t("workspaceNext:publicRfqLanding:step5")}</li>
          </ol>
        </Typography>
      </Box>

      <Box mt="36px">
        <Typography variant="body1" color="textSecondary">
          {t("workspaceNext:publicRfqLanding:subTitle")}
        </Typography>
      </Box>

      <Box mt="36px">
        <Typography variant="body1" color="textSecondary">
          {t("workspaceNext:publicRfqLanding:companiesUsingAxya")}
        </Typography>

        <Grid container spacing={3}>
          <Grid item>
            <img src={KongsbergLogo} alt="Konsberg" />
          </Grid>
          <Grid item>
            <img src={TriparLogo} alt="Tripar" />
          </Grid>
          <Grid item>
            <img src={EagleLogo} alt="Eagle" />
          </Grid>
          <Grid item>
            <img src={BrouwerLogo} alt="Brouwer" />
          </Grid>
          <Grid item>
            <img src={VentionLogo} alt="Vention" />
          </Grid>
        </Grid>
      </Box>

      <Box mt="36px">
        <Typography variant="body1" color="textSecondary">
          {t("workspaceNext:publicRfqLanding:ourNetworkIsCertified")}
        </Typography>

        <Box mt="16px">
          <Grid container spacing={3}>
            <Grid item>
              <img src={Cert1Logo} alt="Konsberg" />
            </Grid>
            <Grid item>
              <img src={Cert2Logo} alt="Tripar" />
            </Grid>
            <Grid item>
              <img src={Cert3Logo} alt="Eagle" />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default PublicRfqLandingDescription;
