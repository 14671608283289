import { phoneRegExp } from "helpers/formRegex";
import i18next from "i18next";
import moment from "moment";
import * as yup from "yup";
import { PublicRFQFormValuesType } from "./public-rfq-creation-form.types";

export const getPublicRfqFormInitialValues = (t: i18next.TFunction): PublicRFQFormValuesType => ({
  company_name: "",
  name: "",
  phone_number: "",
  email: "",
  rfq_name: "",
  deadline: moment().add(7, "days").format("YYYY-MM-DD"),
  additional_details: t("workspaceNext:publicRfqOLD:form:additionalDetails"),
  attachments: [],
  is_agreed: false,
});

export const getPublicRfqFormValidationSchema = (t: i18next.TFunction) =>
  yup.object().shape({
    company_name: yup.string().required(t("common:error:thisFieldIsRequired")),
    name: yup.string().required(t("common:error:thisFieldIsRequired")),
    phone_number: yup
      .string()
      .required(t("userCompany:thePhoneNumberIsRequired"))
      .matches(phoneRegExp, t("userCompany:pleaseEnterAValidPhoneNumber")),
    email: yup
      .string()
      .email(t("auth:error:errorEmailInvalid"))
      .required(t("common:error:thisFieldIsRequired")),
    rfq_name: yup.string().required(t("common:error:thisFieldIsRequired")),
    deadline: yup
      .string()
      .required(t("common:error:thisFieldIsRequired"))
      .test(
        "is-future-date",
        t("workspaceNext:rfqDrawer:error:pastDate"),
        (val) => moment(val).diff(moment(), "days") >= 0
      ),
    additional_details: yup.string().required(t("common:error:thisFieldIsRequired")),
    attachments: yup.string().required(t("workspaceNext:publicRfqOLD:form:thisFileIsRequired")),
    is_agreed: yup.bool().oneOf([true], t("common:error:thisFieldIsRequired")),
  });
