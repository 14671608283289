import React, { useEffect } from "react";

import { useGridApiContext } from "@mui/x-data-grid-pro-v5";
import { NoRowsOverlayBase } from "./no-rows-overlay.base";
import { GridApiPro } from "@mui/x-data-grid-pro-v5/models/gridApiPro";

type Props = {
  setApiRef?: (apiRef: React.MutableRefObject<GridApiPro> | undefined) => void;
};

/**
 * This component actually a trick to use apiRef more easily.
 * The least rendered component is NoRowsOverlay so I chose this and if it is defined
 * we will have both no rows definition and ref.
 */

const NoRowsOverlayWithApiRef: React.FC<Props> = ({ setApiRef }) => {
  const apiRef = useGridApiContext();

  useEffect(() => {
    if (apiRef && setApiRef) {
      setApiRef(apiRef);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef]);

  return <NoRowsOverlayBase />;
};

export default NoRowsOverlayWithApiRef;
