import { Chip } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import { OrderShipmentStatus } from "@next/modules/orders/redux";
import React from "react";
import {
  getOrderDetailShipmentPartsTableTagLabel,
  getOrderDetailShipmentPartsTableTagStyle,
} from "./order-detail-part-status-tag.utils";

type Props = {
  orderShipmentStatus?: OrderShipmentStatus;
  shipmentConfirmationDate?: string;
  size?: "small" | "medium";
};

export const OrderDetailPartStatusTag: React.FC<Props> = ({
  orderShipmentStatus,
  shipmentConfirmationDate,
  size = "medium",
}) => {
  return (
    <Chip
      label={
        <CustomTypography>
          {getOrderDetailShipmentPartsTableTagLabel(orderShipmentStatus, shipmentConfirmationDate)}
        </CustomTypography>
      }
      size={size}
      // icon={getOrderDetailShipmentPartsTableTagIcon(orderShipmentStatus)}
      style={getOrderDetailShipmentPartsTableTagStyle(orderShipmentStatus)}
    />
  );
};
