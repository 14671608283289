import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GridColDef } from "@mui/x-data-grid-pro";
import { ProjectCompanyPart } from "@next/modules/project/redux";
import { profileNextActions } from "../../redux";
import {
  selectCompanyProfile,
  selectCompanyProfileError,
  selectCompanyProfileLoading,
} from "../../redux/selectors";

export type GridStatePartData = {
  rows: Partial<ProjectCompanyPart>[];
  columns: GridColDef[];
};

export const useCompanyProfileEditData = (companyId: number) => {
  const dispatch = useDispatch();

  const companyProfile = useSelector(selectCompanyProfile);
  const companyProfileLoading = useSelector(selectCompanyProfileLoading);
  const companyProfileError = useSelector(selectCompanyProfileError);

  useEffect(() => {
    if (companyId) {
      dispatch(
        profileNextActions.fetchCompanyProfileRequest({
          companyId,
          isPublic: false,
          resetState: true,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return {
    companyProfile,
    companyProfileLoading,
    companyProfileError,
  };
};
