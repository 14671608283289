import { Box, Divider, IconButton, Theme, Typography } from "@mui/material";
import React, { useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { AppTheme } from "layout/AppTheme";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "../types";
import { selectOrder, selectUpdateOrderLoading } from "../../redux/selectors";
import { AutoSavedStatus } from "@next/components/auto-saved-status/auto-saved-status";
import { DashboardCardIcon } from "@next/modules/dashboard/components/buyer-dashboard-card/buyer-dashboard-card-icon";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      position: "sticky",
      top: 0,
      backgroundColor: theme.palette.common.white,
      zIndex: 10,
    },
    deleteIcon: {
      color: theme.palette.common.black,
    },
    icon: {
      color: AppTheme.palette.primary.main,
      marginRight: theme.spacing(1.5),
    },
  })
);

const SendOrderDrawerHeader: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const orderDetail = useSelector(selectOrder);
  const isUpdateOrderLoading = useSelector(selectUpdateOrderLoading);

  const onClose = useCallback(() => {
    dispatch(modalsActions.closeModal(OrdersModalTypes.SEND_ORDER_DRAWER));
  }, []);

  return (
    <div className={classes.header}>
      <Box display="flex" alignItems="center" justifyContent="space-between" px={2} py={1.5}>
        <Box display="flex" alignItems="center">
          <Box className={classes.icon}>
            <DashboardCardIcon iconColor={AppTheme.palette.warning.light}>
              <InsertDriveFileIcon style={{ color: AppTheme.palette.warning.main }} />
            </DashboardCardIcon>
          </Box>
          <Typography variant="h6">{t("orders:sendOrderDrawer:title")}</Typography>
        </Box>

        <Box display="flex" alignItems="center">
          {orderDetail?.updated_at ? (
            <AutoSavedStatus updated_at={orderDetail?.updated_at} loading={isUpdateOrderLoading} />
          ) : null}

          <IconButton onClick={onClose} size="large">
            <CloseIcon className={classes.deleteIcon} />
          </IconButton>
        </Box>
      </Box>
      <Divider light />
    </div>
  );
};

export default SendOrderDrawerHeader;
