import { useEffect } from "react";

interface UseKeyboardShortcutArgs {
  key: string;
  onKeyPressed: () => void;
  ctrl?: boolean;
}

export function useKeyboardShortcut({ key, onKeyPressed, ctrl = false }: UseKeyboardShortcutArgs) {
  useEffect(() => {
    function keyDownHandler(e: globalThis.KeyboardEvent) {
      const isCtrlPressed = ctrl ? e.ctrlKey : true;

      if (e.key === key && isCtrlPressed) {
        e.preventDefault();
        onKeyPressed();
      }
    }

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [key, onKeyPressed, ctrl]);
}
