import { AnalyticFilters } from "../redux";

export const generateReportQuery = (
  reportType: "requests" | "orders" | "suppliers",
  filters: AnalyticFilters
) => {
  const params = new URLSearchParams();

  if (filters.selectedSuppliers.length > 0) {
    const supplierIds = filters.selectedSuppliers.map((supplier) => supplier.pk).join(",");
    params.append("supplier_ids", supplierIds);
  }

  if (filters?.timeframe?.key !== "allTime") {
    if (filters.timeframe.start) {
      params.append("start", filters.timeframe.start);
    }

    if (filters.timeframe.end) {
      params.append("end", filters.timeframe.end);
    }
  } else {
    params.delete("start");
    params.delete("end");
  }

  switch (reportType) {
    case "requests":
      return `?${params}`;
    case "orders":
      return "";
    case "suppliers":
      return "";
  }
};
