// Helper functions
import querystring from "query-string";
import React from "react";

// workspaceConstants, workspaceApi for Redux
import { workspaceApi, workspaceConstants } from "./index";
import { modalsActions } from "../modals/modals.actions";
import { modalsActions as modalsActionsNext } from "@next/redux/modalsSlices";
import { t } from "assets/configi18n/i18n";
import { projectActions } from "@next/modules/project/redux";
import { batch } from "react-redux";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { workspaceNextService } from "@next/modules/workspace/services/workspace-services";
import { selectActiveDraftRFQ } from "@next/modules/workspace/redux/selectors";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { genericErrorHandler } from "@next/utils/apiUtils";
import RFQSuccessSnackbar from "@next/modules/workspace/components/rfq-creation-form/rfq-success-snackbar";
import snackbarUtils from "@next/utils/snackbarUtils";

export const workspaceActions = {
  createPart,
  editPart,
  deletePart,
  fetchCompanyParts,
  fetchCompanyPartsNextPage,
  requestForQuotation,
  fetchCompanyRFQList,
  selectWinner,
  getPartById,
  clonePart,
  fetchBuyerContracts,
  patchPurchaseOrder,
  fetchRFQDetails,
  fetchRFQParts,
  fetchContractDetails,
  setSelectedTabInQuotations,
  rfqCancellationAndExtension,
  setRfqGeneralInformation,
  setRfqActiveStep,
  setSelectedParts,
  fetchCADFiles,
  deleteCADFile,
  createPartTags,
  getAllTagsCreatedByCurrentCompany,
  setBuyerNdaAttachments,
  getTagsList,
  setSelectedTags,
  getDefaultTagsList,
  setBuyerQuotationsTabs,
  getAllSignedNdas,
  toggleNdaApprove,
  setRecipientsList,
  setRFQCompanyList,
  clearRFQListofCompanyMockdata,
  setCongratulationViewDetails,
  uploadRfqAttachments,
  decrementRFQNewQaMsgCount,
  rfqUpdatePartial,
  rfiShortlist,
  resetPartsSelection,
  setPartDetails,
  setRFQDetails,
  updateRFQDetails,
  updateRFQTargetProfiles,
  setRFQPartForEdit,
  updateRFQPartForEdit,
  addRFQPartforEdit,
  deleteRFQPartForEdit,
  setAttachementtoRFQPartForEdit,
  editBookmarkQuotation,
  editRFQPartsAttachement,
};

function createPart(token, newPart, onSuccess) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi.createPart(token, newPart).then(
      (newPart) => {
        if (typeof onSuccess === "function") onSuccess(newPart);
        dispatch(success(newPart));
        snackbarUtils.success(`${t("workspace:yourPartHasBeenSuccessfullyCreated")}`);
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: workspaceConstants.CREATE_PART_REQUEST };
  }
  function success(newPart) {
    return { type: workspaceConstants.CREATE_PART_SUCCESS, newPart };
  }
  function failure(error) {
    return { type: workspaceConstants.CREATE_PART_FAILURE, error };
  }
}

// edit part action
function editPart(token, data, part_id, t, isRfqPartEdit, onSuccess, disableSuccessMessage) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi.editPart(token, data, part_id).then(
      (newPart) => {
        if (!disableSuccessMessage) {
          snackbarUtils.success(`${t("workspace:yourPartHasBeenSuccessfullyUpdated")}`);
        }

        if (typeof onSuccess === "function") onSuccess(newPart);
        dispatch(success(newPart, isRfqPartEdit));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: workspaceConstants.EDIT_PART_REQUEST };
  }
  function success(newPart, isRfqPartEdit) {
    return {
      type: workspaceConstants.EDIT_PART_SUCCESS,
      newPart,
      isRfqPartEdit,
    };
  }
  function failure(error) {
    return { type: workspaceConstants.EDIT_PART_FAILURE, error };
  }
}
// edit part action
function getPartById(token, partId) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi.getPartById(token, partId).then(
      (partDetails) => {
        dispatch(success(partDetails));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: workspaceConstants.GET_PART_BY_ID_REQUEST };
  }
  function success(partDetails) {
    return { type: workspaceConstants.GET_PART_BY_ID_SUCCESS, partDetails };
  }
  function failure(error) {
    return { type: workspaceConstants.GET_PART_BY_ID_FAILURE, error };
  }
}

/**
 * Redux Action to dispatch a query for delete part in the workspace
 * @param {*} token Token for Authentication
 * @param {*} partPk part primary key
 */

function deletePart(token, partPk) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi.deletePart(token, partPk).then(
      () => {
        dispatch(success(partPk));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: workspaceConstants.DELETE_PART_REQUEST };
  }
  function success(deletedPartPk) {
    return { type: workspaceConstants.DELETE_PART_SUCCESS, deletedPartPk };
  }
  function failure(error) {
    return { type: workspaceConstants.DELETE_PART_FAILURE, error };
  }
}

/**
 * Redux Action to dispatch a query for the creation of an RFQ
 *
 * @param {*} rfq - RFQ object Data
 */
function requestForQuotation(
  rfqData,
  fromPM = false,
  selectAllParts = false,
  partSearch,
  projectCode,
  onSuccess
) {
  const { ...restRfq } = rfqData;
  return (dispatch, getState) => {
    dispatch(request());

    if (!restRfq.created_by) delete restRfq.created_by;
    workspaceApi.requestForQuotation(restRfq, fromPM, selectAllParts, partSearch, projectCode).then(
      (responseRfq) => {
        batch(() => {
          dispatch(success(responseRfq));
          dispatch(setSelectedParts([]));
          dispatch(resetPartsSelection());

          if (typeof onSuccess === "function") onSuccess(responseRfq?.pk);

          if (fromPM) {
            dispatch(projectActions.setSelectedPartRows([]));
            dispatch(projectActions.setProjectCompanyPartsSelectAll(false));
          }

          dispatch(modalsActionsNext.closeModal(WorkspaceModalTypes.RFQ_CREATION_DRAWER));
        });

        const state = getState();
        const activeDraftRFQ = selectActiveDraftRFQ(state);

        //delete active draft on rfq creation
        if (activeDraftRFQ.id)
          dispatch(workspaceNextActions.deleteDraftRFQRequest(activeDraftRFQ.id));

        if (responseRfq?.pk) {
          dispatch(projectActions.unshiftProjectCompanyOrder(responseRfq));
          snackbarUtils.custom(t("workspace:YouRequestHasBeenSent"), {
            ButtonComponent: <RFQSuccessSnackbar rfq={responseRfq} fromPM={fromPM} />,
          });
        }
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: workspaceConstants.RFQ_REQUEST };
  }
  function success(rfq) {
    return { type: workspaceConstants.RFQ_SUCCESS, rfq };
  }
  function failure(error) {
    return { type: workspaceConstants.RFQ_FAILURE, error };
  }
}

/**
 * Redux Action to Dispatch a query for a subset of RFQ's belonging to a certain company
 *
 * @param {*} token - Token For User Authentication
 * @param {*} company_id - The Company ID  of the company we want to fetch the RFQList
 */
function fetchCompanyRFQList(token, status, project, isNewuser = false) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi.fetchCompanyRFQList(token, status, project).then(
      (RFQListOfCompany) => {
        if (
          isNewuser &&
          RFQListOfCompany &&
          RFQListOfCompany.data &&
          RFQListOfCompany.data.length === 0 &&
          status === "InProgress&status=Waiting"
        ) {
          dispatch(setRFQCompanyList({}, true, isNewuser, false, status));
        } else {
          dispatch(setRFQCompanyList(RFQListOfCompany, false, isNewuser, false, status));
        }
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: workspaceConstants.FETCH_COMPANY_RFQLIST_REQUEST };
  }
  function failure(error) {
    return { type: workspaceConstants.FETCH_COMPANY_RFQLIST_FAILURE, error };
  }
}

function setRFQCompanyList(RFQListOfCompany, isMock, isNewuser, tourClicked = false, status) {
  const isRFQTourVisited = localStorage.getItem("isRFQTourVisited");
  const RFQListMock = {
    status: status,
    data: [
      {
        pk: 123456789,
        rfq_display_name: "RFQ Guidance",
        parts_count: 2,
        parts_quantity: 4,
        deadline: new Date(),
        quotations_count: 2,
        part_previews: [],
        status: "Ongoing",
        cancellation_reason: "cancellation_reason",
        intent_to_bid_count: "1",
        unseen_questions_count: "2",
        created_at: new Date(),
      },
    ],
  };
  const RFQListMockNewUserRecurring = {
    status: status,
    data: [],
  };
  if (
    !isMock &&
    RFQListOfCompany &&
    ["InProgress&status=Waiting"].some((x) => x === RFQListOfCompany.status) &&
    (isNewuser || tourClicked)
  ) {
    RFQListOfCompany.data.unshift(RFQListMock.data[0]);
  }

  return (dispatch) => {
    dispatch({
      type: workspaceConstants.FETCH_COMPANY_RFQLIST_SUCCESS,
      RFQListOfCompany:
        (isMock && isRFQTourVisited == null) ||
        (tourClicked && isNewuser) ||
        (tourClicked && status === "archive")
          ? RFQListMock
          : isNewuser && isRFQTourVisited == "true" && status === "InProgress&status=Waiting"
            ? RFQListMockNewUserRecurring
            : RFQListOfCompany,
    });
  };
}

function clearRFQListofCompanyMockdata(status) {
  return (dispatch) => {
    dispatch({
      type: workspaceConstants.CLEAR_RFQ_TOUR_MOCK_IN_RFQLISTOFCOMPANY,
      status: status,
    });
  };
}

function fetchRFQDetails(rfqId, excludeParts = false, setNote, note) {
  return async (dispatch) => {
    dispatch(request());
    try {
      let res;
      if (excludeParts) {
        //fetch rfq details and few parts in parallel to reduce initial loading time
        let [rfqDetails, parts] = await Promise.all([
          workspaceApi.fetchRFQDetails(rfqId, true),
          workspaceNextService.fetchPartsList({
            query: { rfq: rfqId, page: 1, pageSize: 10 },
          }),
        ]);
        rfqDetails.parts = parts.data.results;
        res = rfqDetails;

        //once initial batch is fetched, fetch all parts needed for different functions (ex: split rfq)
        if (parts.data.results.length < parts.data.count) {
          dispatch(fetchRFQParts(rfqId));
        }
      } else {
        res = await workspaceApi.fetchRFQDetails(rfqId, false);
      }
      dispatch(success(res));
      if (typeof note === "string") {
        setNote(note);
      }
    } catch (error) {
      dispatch(failure(error?.toString()));
      genericErrorHandler(error);
    }
  };

  function request() {
    return { type: workspaceConstants.FETCH_RFQ_DETAILS_REQUEST };
  }
  function success(rfqDetails) {
    return {
      type: workspaceConstants.FETCH_RFQ_DETAILS_SUCCESS,
      rfqDetails,
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.FETCH_RFQ_DETAILS_FAILURE,
      error,
    };
  }
}

function fetchRFQParts(rfqId) {
  return (dispatch) => {
    dispatch(request());
    workspaceNextService
      .fetchPartsList({
        query: { rfq: rfqId },
      })
      .then(
        (res) => {
          dispatch(success(res.data));
        },
        (error) => {
          dispatch(failure(error?.toString()));
          genericErrorHandler(error);
        }
      );
  };

  function request() {
    return { type: workspaceConstants.FETCH_RFQ_PARTS_REQUEST };
  }
  function success(parts) {
    return {
      type: workspaceConstants.FETCH_RFQ_PARTS_SUCCESS,
      parts,
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.FETCH_RFQ_PARTS_FAILURE,
      error,
    };
  }
}

/**
 * Redux Action to Dispatch a query for a subset of RFQ's belonging to a certain SUPPLIER company
 *
 * @param {*} token - Token For User Authentication
 * @param {*} winnerId - The Company ID  of the company we want to fetch the RFQList
 */
function fetchBuyerContracts(token) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi.fetchBuyerContracts(token).then(
      (buyerContracts) => {
        dispatch(success(buyerContracts));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: workspaceConstants.FETCH_BUYER_CONTRACTS_REQUEST };
  }
  function success(buyerContracts) {
    return {
      type: workspaceConstants.FETCH_BUYER_CONTRACTS_SUCCESS,
      buyerContracts,
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.FETCH_BUYER_CONTRACTS_FAILURE,
      error,
    };
  }
}

function fetchContractDetails(token, rfqId) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi.fetchContractDetails(token, rfqId).then(
      (contractDetails) => {
        dispatch(success(contractDetails));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: workspaceConstants.FETCH_CONTRACT_DETAILS_REQUEST };
  }
  function success(contractDetails) {
    return {
      type: workspaceConstants.FETCH_CONTRACT_DETAILS_SUCCESS,
      contractDetails,
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.FETCH_CONTRACT_DETAILS_FAILURE,
      error,
    };
  }
}

function fetchCADFiles(token) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi.fetchCADFiles(token).then(
      (files) => {
        dispatch(success(files));
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };

  function request() {
    return { type: workspaceConstants.FETCH_CAD_FILES_REQUEST };
  }
  function success(cadFiles) {
    return {
      type: workspaceConstants.FETCH_CAD_FILES_SUCCESS,
      cadFiles,
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.FETCH_CAD_FILES_FAILURE,
      error,
    };
  }
}

function deleteCADFile(token, filePk) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi.deleteCADFile(token, filePk).then(
      (files) => {
        dispatch(success(files));
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };

  function request() {
    return { type: workspaceConstants.DELETE_CAD_FILE_REQUEST };
  }
  function success(file) {
    return {
      type: workspaceConstants.DELETE_CAD_FILE_SUCCESS,
      filePk,
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.DELETE_CAD_FILE_FAILURE,
      error,
    };
  }
}

/**
 * Redux Action to Dispatch a query for a subset of RFQ's belonging to a certain company
 * @param {*} token - Token For User Authentication
 * @param {*} companyWinnerPk - The Company winner pk
 * @param {*} rfqId - The rfq ID  of the request for quotation
 */
function selectWinner(token, companyWinnerPk, rfqId) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi.selectWinner(token, companyWinnerPk, rfqId).then(
      (updatedRFQ) => {
        batch(() => {
          dispatch(success(updatedRFQ));

          // This for new implementation
          dispatch(projectActions.updateRFQSuccess(updatedRFQ));
        });
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: workspaceConstants.SELECT_WINNER_REQUEST };
  }
  function success(updatedRFQ) {
    return { type: workspaceConstants.SELECT_WINNER_SUCCESS, updatedRFQ };
  }
  function failure(error) {
    return { type: workspaceConstants.SELECT_WINNER_FAILURE, error };
  }
}

/**
 *
 * @param {*} token - Token For User Authentication
 * @param {*} contractPk - The contract pk
 * @param {*} poFilePk - The upload purchase order pk
 */
function patchPurchaseOrder(token, poFilePk, contractPk, projectCode = "") {
  return (dispatch) => {
    dispatch(request());
    workspaceApi.patchPurchaseOrder(token, poFilePk, contractPk).then(
      (contract) => {
        snackbarUtils.success(`${t("workspace:uploadedPurchaseOrder")}`);
        dispatch(success({ poFile: contract.po_file, pk: contractPk, projectCode }));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: workspaceConstants.PATCH_PURCHASE_ORDER_REQUEST };
  }
  function success(contractPo) {
    return {
      type: workspaceConstants.PATCH_PURCHASE_ORDER_SUCCESS,
      contractPo,
    };
  }
  function failure(error) {
    return { type: workspaceConstants.PATCH_PURCHASE_ORDER_FAILURE, error };
  }
}

/**
 * Redux action to Dispatch a backend Query for the fetching of parts belonging to a company
 *
 * @param {*} token - JWT Token For User Authentication
 * @param {*} company_id - The Company unique ID
 */
function fetchCompanyParts(token, params = "", isInitialLoad = false) {
  return (dispatch) => {
    dispatch(request(isInitialLoad));
    workspaceApi
      .fetchCompanyParts(
        token,
        querystring.stringify({
          ...querystring.parse(params),
          page: 1,
          pageSize: 12,
        })
      )
      .then(
        (payload) => {
          dispatch(success(payload));
        },
        (error) => {
          dispatch(failure(error?.toString()));
          snackbarUtils.error(JSON.stringify(error));
        }
      );
  };

  function request(isInitialLoad) {
    return {
      type: workspaceConstants.FETCH_COMPANY_PARTS_REQUEST,
      isInitialLoad,
    };
  }
  function success(payload) {
    return { type: workspaceConstants.FETCH_COMPANY_PARTS_SUCCESS, payload };
  }
  function failure(error) {
    return { type: workspaceConstants.FETCH_COMPANY_PARTS_FAILURE, error };
  }
}

/**
 * Redux action to Dispatch a backend Query for fetching the next page of parts belonging to a company
 *
 * @param {*} token - JWT Token For User Authentication
 */
function fetchCompanyPartsNextPage(token) {
  return (dispatch, getState) => {
    const { companyPartNextPage } = getState().workspace;
    dispatch(request());
    workspaceApi.fetchCompanyPartsNextPage(token, companyPartNextPage).then(
      (payload) => {
        dispatch(success(payload));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        snackbarUtils.error(JSON.stringify(error));
      }
    );
  };

  function request() {
    return {
      type: workspaceConstants.FETCH_COMPANY_PARTS_NEXT_REQUEST,
    };
  }
  function success(payload) {
    return {
      type: workspaceConstants.FETCH_COMPANY_PARTS_NEXT_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return { type: workspaceConstants.FETCH_COMPANY_PARTS_NEXT_FAILURE, error };
  }
}

// Clone part

function clonePart(token, partPk, t) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi.clonePart(token, partPk, t).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: workspaceConstants.CLONE_PART_REQUEST };
  }
  function success(clonedPart) {
    return { type: workspaceConstants.CLONE_PART_SUCCESS, clonedPart };
  }
  function failure(error) {
    return { type: workspaceConstants.CLONE_PART_FAILURE, error };
  }
}

function setSelectedTabInQuotations(tabValue) {
  return (dispatch) => {
    dispatch({
      type: workspaceConstants.SET_SELECTED_TAB_IN_QUOTATIONS,
      selectedTab: tabValue,
    });
  };
}

function rfqCancellationAndExtension(token, pk, requestParms, t) {
  const { hideModal } = modalsActions;
  return (dispatch) => {
    dispatch(request());
    workspaceApi.rfqCancellationAndExtension(token, pk, requestParms, t).then(
      (data) => {
        dispatch(success(data));
        dispatch(hideModal("isExtendAndCancelRfqModalOpen"));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: workspaceConstants.RFQ_CANCELLATION_AND_EXTENSION_REQUEST };
  }
  function success(data) {
    return {
      type: workspaceConstants.RFQ_CANCELLATION_AND_EXTENSION_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.RFQ_CANCELLATION_AND_EXTENSION_FAILURE,
      error,
    };
  }
}

// RFQ stepper

function setRfqActiveStep(activeStep) {
  return {
    type: workspaceConstants.SET_RFQ_ACTIVE_STEP,
    activeStep,
  };
}

function setRfqGeneralInformation(data) {
  return {
    type: workspaceConstants.SET_RFQ_GENERAL_INFORMATION,
    data,
  };
}

function setRecipientsList(data) {
  return {
    type: workspaceConstants.SET_RECIPIENTS_LIST,
    data,
  };
}
function setSelectedParts(parts) {
  return {
    type: workspaceConstants.SET_SELECTED_PARTS,
    parts,
  };
}

// create part Tags action

function createPartTags(token, partPk, tags, t) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi.createPartTags(token, partPk, tags, t).then(
      (tags) => {
        dispatch(success({ tags: tags, pk: partPk }));
        dispatch(workspaceActions.getTagsList());
        snackbarUtils.success(
          tags.length > 1
            ? t("tagsHasBeenSuccessfullyCreated")
            : tags.length === 1
              ? t("tagHasBeenSuccessfullyCreated")
              : t("noTagHasBeenCreated")
        );
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: workspaceConstants.CREATE_PART_TAGS_REQUEST };
  }
  function success(partTags) {
    return { type: workspaceConstants.CREATE_PART_TAGS_SUCCESS, partTags };
  }
  function failure(error) {
    return { type: workspaceConstants.CREATE_PART_TAGS_SUCCESS, error };
  }
}
// create part Tags action

function getAllTagsCreatedByCurrentCompany(token, companyPk) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi.getAllTagsCreatedByCurrentCompany(token, companyPk).then(
      (tagsCreatedByCurrentCompany) => {
        dispatch(success(tagsCreatedByCurrentCompany));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return {
      type: workspaceConstants.GET_All_TAGS_CREATED_BY_CURRENT_COMPANY_REQUEST,
    };
  }
  function success(tagsCreatedByCurrentCompany) {
    return {
      type: workspaceConstants.GET_All_TAGS_CREATED_BY_CURRENT_COMPANY_SUCCESS,
      tagsCreatedByCurrentCompany,
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.GET_All_TAGS_CREATED_BY_CURRENT_COMPANY_FAILURE,
      error,
    };
  }
}
// Buyer NDA attachements
function setBuyerNdaAttachments(attachments) {
  return (dispatch) => {
    dispatch({
      type: workspaceConstants.SET_BUYER_NDA_ATTACHMENTS,
      attachments,
    });
  };
}

// get list of tags created by company
function getTagsList(keyword) {
  return (dispatch, getstate) => {
    dispatch(request());
    workspaceApi.getTagsList(getstate().profile.token, keyword).then(
      (tags) => {
        dispatch(success(tags));
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };

  function request() {
    return { type: workspaceConstants.GET_TAGS_LIST_REQUEST };
  }
  function success(tags) {
    return { type: workspaceConstants.GET_TAGS_LIST_SUCCESS, tags };
  }
  function failure(error) {
    return { type: workspaceConstants.GET_TAGS_LIST_FAILURE, error };
  }
}

function setSelectedTags(tags) {
  return (dispatch) => {
    dispatch({
      type: workspaceConstants.SET_SELECTED_TAGS,
      tags,
    });
  };
}

// get last 5 created tags and update tagsCreatedByCurrentCompany state in the reducer
function getDefaultTagsList(tagsCreatedByCurrentCompany) {
  return (dispatch) => {
    dispatch({
      type: workspaceConstants.GET_All_TAGS_CREATED_BY_CURRENT_COMPANY_SUCCESS,
      tagsCreatedByCurrentCompany,
    });
  };
}

// Buyer quotations tabs
function setBuyerQuotationsTabs(isQuotationsTabs) {
  return (dispatch) => {
    dispatch({
      type: workspaceConstants.SET_BUYER_QUOTATIONS_TABS,
      isQuotationsTabs,
    });
  };
}

// get list of all supplier signed NDAs
function getAllSignedNdas() {
  return (dispatch, getState) => {
    dispatch(request());
    workspaceApi.getAllSignedNdas(getState().profile.token).then(
      (NDAList) => {
        dispatch(success(NDAList));
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };
}
function request() {
  return { type: workspaceConstants.GET_ALL_SIGNED_NDAS_REQUEST };
}
function success(NDAList) {
  return { type: workspaceConstants.GET_ALL_SIGNED_NDAS_SUCCESS, NDAList };
}
function failure(error) {
  return { type: workspaceConstants.GET_ALL_SIGNED_NDAS_FAILURE, error };
}

//get Nda approve
function toggleNdaApprove(token, pk) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi.toggleNdaApprove(token, pk).then(
      (isApproved) => {
        dispatch(success(isApproved, pk));
      },
      (error) => {
        dispatch(failure(error?.toString()));
      }
    );
  };
  function request() {
    return { type: workspaceConstants.TOGGLE_NDA_APPROVE_REQUEST };
  }
  function success(isApproved, pk) {
    return {
      type: workspaceConstants.TOGGLE_NDA_APPROVE_SUCCESS,
      isApproved,
      pk,
    };
  }
  function failure(error) {
    return { type: workspaceConstants.TOGGLE_NDA_APPROVE_FAILURE, error };
  }
}

function setCongratulationViewDetails(congratsInfo) {
  return (dispatch) => {
    dispatch({
      type: workspaceConstants.SET_CONGRATULATION_DETAILS,
      congratsInfo: congratsInfo,
    });
  };
}

function uploadRfqAttachments(rfqPk, files) {
  return (dispatch) => {
    dispatch(request());

    const uploads = [];
    for (let i = 0; i < files?.length; i++) {
      uploads.push(workspaceApi.uploadRfqAttachment(rfqPk, files[i]));
    }

    if (uploads.length > 0) {
      Promise.all(uploads)
        .then(function (response) {
          // Since the error handling api part is not yet correct, we call it successful if there is data.
          const successResults = response.filter((item) => item?.pk);
          const errorResults = response.filter((item) => !item?.pk);
          const errorMessage = `${errorResults?.length} attachments failed to upload`;

          if (errorResults?.length > 0) {
            // just warn user to see partial upload error
            snackbarUtils.warning(errorMessage);
          }

          if (successResults?.length > 0) {
            // if any success file dispatch as success
            return dispatch(success());
          }

          // if no success file dispatch as failure
          dispatch(failure(new Error(errorMessage)));
        })
        .catch(function (err) {
          dispatch(failure(new Error(err)));
        });
    }
  };

  function request() {
    return { type: workspaceConstants.UPLOAD_RFQ_ATTACHMENT_REQUEST };
  }
  function success() {
    return { type: workspaceConstants.UPLOAD_RFQ_ATTACHMENT_SUCCESS };
  }
  function failure(error) {
    return { type: workspaceConstants.UPLOAD_RFQ_ATTACHMENT_FAILURE, error };
  }
}

function decrementRFQNewQaMsgCount(count) {
  return { type: workspaceConstants.DECREMENT_RFQ_NEW_QA_MSG_COUNT, count };
}

function rfqUpdatePartial(rfqId, partialRfq) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi
      .rfqUpdatePartial(rfqId, partialRfq)
      .then((updatedRfq) => {
        dispatch(success(updatedRfq));
        snackbarUtils.success(t("rfq:success:partialUpdate"));
      })
      .catch((error) => {
        dispatch(failure());
        genericErrorHandler(error);
      });
  };

  function request() {
    return { type: workspaceConstants.RFQ_UPDATE_PARTIAL_REQUEST };
  }
  function success(updatedRfq) {
    return {
      type: workspaceConstants.RFQ_UPDATE_PARTIAL_SUCCESS,
      updatedRfq,
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.RFQ_UPDATE_PARTIAL_FAILURE,
      error,
    };
  }
}

function rfiShortlist(rfqId, winnerIDs, winnerNote, losersNote) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi
      .rfiShortlist(rfqId, {
        company_ids: winnerIDs,
        winner_note: winnerNote,
        loser_note: losersNote,
      })
      .then((updatedRfq) => {
        dispatch(success(updatedRfq));
        snackbarUtils.success(t("rfq:quotations:shortlist:requestCompleted"));
      })
      .catch((error) => {
        dispatch(failure());
        genericErrorHandler(error);
      });
  };

  function request() {
    return { type: workspaceConstants.RFI_SHORTLIST_REQUEST };
  }
  function success(updatedRfq) {
    return {
      type: workspaceConstants.RFI_SHORTLIST_SUCCESS,
      updatedRfq,
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.RFI_SHORTLIST_FAILURE,
      error,
    };
  }
}

function resetPartsSelection() {
  return { type: workspaceConstants.RESET_PARTS_SELECTION };
}

function setPartDetails(partDetails) {
  return { type: workspaceConstants.SET_PART_DETAILS, partDetails };
}

function setRFQDetails(rfqDetails) {
  return { type: workspaceConstants.SET_RFQ_DETAILS, rfqDetails };
}

function updateRFQDetails(rfqDetails) {
  return { type: workspaceConstants.UPDATE_RFQ_DETAILS, rfqDetails };
}

function updateRFQTargetProfiles(supplierId, targetProfiles) {
  return {
    type: workspaceConstants.UPDATE_RFQ_TARGETED_PROFILES_SUCCESS,
    supplierId,
    targetProfiles,
  };
}

function setRFQPartForEdit() {
  return { type: workspaceConstants.SET_RFQ_PARTS_FOR_EDIT };
}

function updateRFQPartForEdit(rfqPart) {
  return { type: workspaceConstants.UPDATE_RFQ_PARTS_FOR_EDIT, rfqPart };
}

function deleteRFQPartForEdit(rfqPart) {
  return { type: workspaceConstants.DELETE_RFQ_PARTS_FOR_EDIT, rfqPart };
}

function addRFQPartforEdit(rfqPart) {
  return { type: workspaceConstants.ADD_RFQ_PART_FOR_EDIT, rfqPart };
}

function setAttachementtoRFQPartForEdit(rfqPartAttachement) {
  return { type: workspaceConstants.RFQ_PARTS_ATTACHEMENT, rfqPartAttachement };
}

function editRFQPartsAttachement(partId, attachements) {
  return {
    type: workspaceConstants.EDIT_RFQ_PARTS_ATTACHEMENT,
    partId,
    attachements,
  };
}

function editBookmarkQuotation(token, quoteId, payload, t) {
  return (dispatch) => {
    dispatch(request());
    workspaceApi
      .editBookmarkQuotation(token, quoteId, payload)
      .then((updatedRfq) => {
        dispatch(success(updatedRfq));
        snackbarUtils.success(t("rfq:quotations:requestCompleted"));
      })
      .catch((error) => {
        console.trace(error);
        dispatch(failure());
        genericErrorHandler(error);
      });
  };

  function request() {
    return { type: workspaceConstants.BOOKMARK_QUOTATION_REQUEST };
  }
  function success(updatedRfq) {
    return { type: workspaceConstants.BOOKMARK_QUOTATION_SUCCESS, updatedRfq };
  }
  function failure(error) {
    return { type: workspaceConstants.BOOKMARK_QUOTATION_FAILURE, error };
  }
}
