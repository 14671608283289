import { backendUrl } from "../../urls";

// Helper
import { handleResponse } from "../../helpers/responseHandling";
import { axiosApi } from "@next/api";
export const workspaceApi = {
  createPart,
  uploadPart,
  uploadPurchaseOrder,
  editPart,
  deletePart,
  fetchCompanyParts,
  fetchCompanyPartsNextPage,
  requestForQuotation,
  fetchCompanyRFQList,
  fetchRFQDetails,
  selectWinner,
  getPartById,
  clonePart,
  fetchBuyerContracts,
  patchPurchaseOrder,
  fetchContractDetails,
  rfqCancellationAndExtension,
  fetchCADFiles,
  deleteCADFile,
  createPartTags,
  getAllTagsCreatedByCurrentCompany,
  buyerUploadNDA,
  getTagsList,
  // getSignedNdaList,
  buyerSignedNDAUpload,
  getAllSignedNdas,
  toggleNdaApprove,
  uploadRfqAttachment,
  rfqUpdatePartial,
  rfiShortlist,
  editBookmarkQuotation,
};

/**
 * Backend Query  to fetch a subset of Parts belonging to a company
 *
 * @param {String} token - JWT token for User Authentication
 * @param {Integer} companyId -  unique Company identifier
 * @returns {Array} - list of objects representing parts
 */
function fetchCompanyParts(token, params) {
  return axiosApi.get(`${backendUrl.parts}${params ? `?${params}` : ""}`).then(handleResponse);
}

/**
 * Backend Query to fetch the next subset of Parts belonging to a company
 *
 * @param {String} token - JWT token for User Authentication
 * @param {String} nextUrl - URL of the next page
 * @returns {Object} - response of the api containing parts for this page
 */
function fetchCompanyPartsNextPage(token, nextUrl) {
  return axiosApi.get(nextUrl).then(handleResponse);
}

/**
   * Backend Query for the Creation of a Request for Quotation in the backend
   *
   * @param {String} token - Token for User Authentication
   * @param {Object} rfqData - Data to be sent fir the RFQ
   * @returns {Object} - The Created RFQ Data
   *
   * object Data should resemble  the following ;
   *{
      "rfq_name": "RFQ NAME",
      "deadline":"1993-01-31",
      "additional_details": "Lorem Ipsum",
      "tag":"PROTOTYPE",
      "parts":[{
                  "name": "Part1",
                  "pk": "11",
                  "quantity": 2,
              }]
  *}
   */
function requestForQuotation(rfqData, fromPM, selectAllParts, partSearch, projectCode) {
  const query =
    fromPM && selectAllParts
      ? partSearch
        ? `${backendUrl.rfq}?selectAll=true&search=${partSearch}&project=${projectCode}`
        : `${backendUrl.rfq}?selectAll=true&project=${projectCode}`
      : backendUrl.rfq;

  return axiosApi.post(query, rfqData).then(handleResponse);
}

/**
 * Backend Query  select winner company
 *
 * @param {String} token - JWT token for User Authentication
 * @param {Integer} rfqId -  unique rfq identifier
 *
 */
function selectWinner(token, winnerCompanyPk, rfqPk) {
  return axiosApi.patch(backendUrl.rfq + `${rfqPk}`, winnerCompanyPk).then(handleResponse);
}

/**
 * Get contract details
 *
 * @param {String} token - JWT token for User Authentication
 * @param {Integer} rfqId -  unique rfq identifier
 *
 */
function fetchContractDetails(token, contractPk) {
  return axiosApi.get(`${backendUrl.contracts}/${contractPk}`).then(handleResponse);
}
/**
 * Get uploaded CAD files
 */
function fetchCADFiles(token) {
  return axiosApi.get(`${backendUrl.workspaceCadFiles}`).then(handleResponse);
}

function deleteCADFile(token, filePk) {
  return axiosApi.delete(`${backendUrl.workspaceCadFiles}/${filePk}`).then(handleResponse);
}

/**
 * Backend Query  select winner company
 *
 * @param {String} token - JWT token for User Authentication
 * @param {Integer} rfqId -  unique rfq identifier
 *
 */
function patchPurchaseOrder(token, poFilePk, contractPk) {
  return axiosApi.patch(`${backendUrl.contracts}/${contractPk}`, poFilePk).then(handleResponse);
}

/**
 * Backend Query for the fetching of a list of all the RFQ's created by a company
 *
 * @param {*} token - Token for User Authentication
 * @param {*} companyId -The unique identifer of the company we want to retrieve the RFQ's
 * @returns {Array} - Array of Objects representing RFQ's
 */
function fetchCompanyRFQList(token, status, project = "") {
  if (project) {
    project = `&${project}`;
  } else {
    project = "";
  }

  return axiosApi.get(`${backendUrl.quotes}${status}${project}`).then(handleResponse);
}

/**
 * Fetches an RFQ details
 * @param {string} token The auth token
 * @param {string} id The rfq id
 */
function fetchRFQDetails(id, excludeParts = false) {
  return axiosApi.get(`${backendUrl.rfq}${id}/?exclude_parts=${excludeParts}`).then(handleResponse);
}

/**
 * Backend Query for create fetch Company Quotations
 * @param {*} token - Token for User Authentication
 * @returns {Array} -   Array of Objects representing contracts
 */

function fetchBuyerContracts(token) {
  return axiosApi.get(backendUrl.contracts).then(handleResponse);
}
/**
 * Backend Query  to create part
 *
 * @param {String} token - JWT token for User Authentication
 * @param {Object} newPart -  part object
 * @returns {Object} -  objects representing part
 *
 */
function createPart(token, newPart) {
  return axiosApi.post(backendUrl.parts, newPart).then(handleResponse);
}

/**
 * Backend Query  to delete part
 * @param {String} token - JWT token for User Authentication
 * @param {integer} partPk -  part primary key
 */
function deletePart(token, partPk) {
  return axiosApi.delete(backendUrl.parts + `${partPk}`).then(handleResponse);
}

/**
 * Backend Query  to edit part
 *
 * @param {String} token - JWT token for User Authentication
 * @param {Object} editPart -   modified part
 * @returns {Object} - the objects representing the new modified part
 * @param {Integer} partId -  unique Company identifier
 *
 */
function editPart(token, editPart, partId) {
  return axiosApi.patch(backendUrl.parts + `${partId}/`, editPart).then(handleResponse);
}
/**
 *  Backend Query  to get part by id
 * @param {*} token
 * @param {*} partId
 */
function getPartById(token, partId) {
  return axiosApi.get(backendUrl.parts + `${partId}`).then(handleResponse);
}

/**
 * Uploads a part's file
 *
 * @param {String} token - JWT token for User Authentication
 * @param {File} file - The file to be uploaded
 */
function uploadPart(file) {
  const payload = {
    file: file,
  };

  return axiosApi.post(backendUrl.uploadPart, payload).then(handleResponse);
}

/**
 * Uploads a part's file
 *
 * @param {String} token - JWT token for User Authentication
 * @param {File} file - The file to be uploaded
 */
function uploadPurchaseOrder(token, file) {
  const formData = new FormData();
  formData.append("file", file);

  return axiosApi.post(backendUrl.uploadRfqPo, formData).then(handleResponse);
}

//upload NDA buyer
function buyerUploadNDA(token, file) {
  const formData = new FormData();
  formData.append("nda_file", file);

  return axiosApi.post(backendUrl.NDA, formData).then(handleResponse);
}

// Clone Part API
function clonePart(token, partPk, t) {
  return axiosApi
    .post(backendUrl.part + `${partPk}/clone`, {
      id: partPk,
    })
    .then(handleResponse);
}

function rfqCancellationAndExtension(token, rfqPk, requestParams, t) {
  return axiosApi.patch(`${backendUrl.rfq}${rfqPk}`, requestParams).then(handleResponse);
}
// create part tags

function createPartTags(token, partPk, tags, t) {
  return axiosApi
    .post(`${backendUrl.parts}${partPk}/tags/`, {
      tags: tags,
    })
    .then(handleResponse);
}

// Get all tags of a specific company
function getAllTagsCreatedByCurrentCompany(token, keyWord) {
  return axiosApi.get(`${backendUrl.tags}?q=${keyWord}`).then(handleResponse);
}

// API call to upload final signed NDA
function buyerSignedNDAUpload(token, file, pk) {
  const formData = new FormData();
  formData.append("nda_file", file);

  return axiosApi.patch(`${backendUrl.rfq}${pk}/signed-nda/`, formData).then(handleResponse);
}

// get list of tags created by buyer in the filter
function getTagsList(token, keyword = "") {
  return axiosApi.get(`${backendUrl.tags}${keyword ? `?q=${keyword}` : ""}`).then(handleResponse);
}

// API call to get list of all supplier signed NDAs
function getAllSignedNdas(token) {
  return axiosApi.get(`${backendUrl.NDA}`).then(handleResponse);
}

//API call to get Nda approve
function toggleNdaApprove(token, pk) {
  return axiosApi.patch(`${backendUrl.rfq}${pk}/signed-nda/`).then(handleResponse);
}

/**
 * Backend Query for bookmark Quotation
 * @param {*} token - Token for User Authentication
 * @param {string} quoteId - The ID of the quotation to be edited
 * @param {Object} payload - The payload containing the data to be updated
 * @returns {Promise<Object>} - A promise resolving to the updated quotation data
 */
function editBookmarkQuotation(token, quoteId, payload) {
  return axiosApi
    .patch(`${backendUrl.quotation}/${quoteId}/bookmark/`, payload)
    .then(handleResponse)
    .then((data) => data)
    .catch((error) => {
      console.error("Error updating bookmark status:", error);
      throw error;
    });
}

/**
 * Upload rfq's attachment
 *
 * @param {File} file - The file to be uploaded
 */
function uploadRfqAttachment(rfqPk, file) {
  const payload = { file: file };
  if (rfqPk) payload.rfq = rfqPk;

  return axiosApi.post(backendUrl.uploadRfqAttachment, payload).then(handleResponse);
}

//API call to partial update rfq
function rfqUpdatePartial(rfqId, partialRfq) {
  return axiosApi
    .patch(`${backendUrl.rfq}${rfqId}`, partialRfq)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

/**
 * API call to shortlist quotations for RFI
 *
 * @param {string} rfqId - rfqId for which to shortlist
 * @param {Number[]} winnerIDs - IDs of winner companies
 * @param {string} winnerNote - Note for winner companies
 * @param {string} loserNote - Note for loser companies
 */
function rfiShortlist(rfqId, payload) {
  return axiosApi
    .patch(`${backendUrl.rfi}${rfqId}/shortlist/`, payload)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
