import { Box, MenuItem, Typography, styled } from "@mui/material";

export const StyledBox = styled(Box)({
  width: "344px",
});

export const StyledMenuItem = styled(MenuItem)({
  height: "50px",
});

export const StyledTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));
