import React from "react";
import * as S from "./side-drawer-message-card.styled";

type Props = {
  id: number;
  body: string;
  author: string;
  createdAt: string;
};

export const SideDrawerMessageCard: React.FC<Props> = ({ id, body, author, createdAt }) => {
  return (
    <S.StyledCardBox>
      <S.StyledCardHeaderBox>
        <S.StyledAuthorTypography variant="body2" tooltipTitle={null}>
          {author}
        </S.StyledAuthorTypography>

        <S.StyledDateTypography variant="body2" tooltipTitle={null}>
          {createdAt}
        </S.StyledDateTypography>
      </S.StyledCardHeaderBox>

      <S.StyledBodyTypography variant="body2" tooltipTitle={null}>
        {body}
      </S.StyledBodyTypography>
    </S.StyledCardBox>
  );
};
