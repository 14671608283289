import { checkIsPublicPage, genericErrorHandler } from "@next/utils/apiUtils";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { profileActions } from "services/profile";
import { store } from "store";
import { backendUrl, frontendUrl } from "urls";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { history } from "helpers/history";
import { getSelectedLanguage } from "@next/utils/browserUtils";
import { t } from "assets/configi18n/i18n";

enum StatusCode {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  TooManyRequests = 429,
  InternalServerError = 500,
  TimeOut = 504,
}

const headers: Readonly<Record<string, string | boolean>> = {
  "Content-Type": "application/json",
};

// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const isPublicApiUsageNeed = checkIsPublicPage();
    const token = store.getState()?.profile?.token;

    // Sometimes we use api without jwt like public qa so if checkIsPublicPage true we don't pass JWT
    if (typeof token === "string" && token?.length > 0 && !isPublicApiUsageNeed) {
      config.headers.Authorization = generateAuthHeader(token);
    }
    return config;
  } catch (error) {
    throw new Error(error as any);
  }
};

const injectLanguage = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    config.headers["Accept-Language"] = getSelectedLanguage() || "en";

    return config;
  } catch (error) {
    throw new Error(error as any);
  }
};

const generateAuthHeader = (token: string) => {
  const auth_type = "JWT";

  return `${auth_type} ${token}`;
};

export class AxiosApi {
  private instance: AxiosInstance | null = null;
  private retryCount = 0;

  private get axiosApi(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const axiosInstance = axios.create({
      headers,
      timeoutErrorMessage: "timeout",
    });

    axiosInstance.interceptors.request.use(injectToken, (error) => Promise.reject(error));

    axiosInstance.interceptors.request.use(injectLanguage, (error) => Promise.reject(error));

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        return this.handleError(error);
      }
    );

    this.instance = axiosInstance;

    // Instantiate the interceptor
    createAuthRefreshInterceptor(axiosInstance, this.refreshAuthLogic);

    return axiosInstance;
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.axiosApi.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.axiosApi.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.axiosApi.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.axiosApi.put<T, R>(url, data, config);
  }

  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.axiosApi.patch<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.axiosApi.delete<T, R>(url, config);
  }

  // Function that will be called to refresh authorization
  refreshAuthLogic = async (failedRequest: any) => {
    const refreshToken = store.getState()?.profile?.refresh;

    return axios
      .post(backendUrl.refreshToken, { refresh: refreshToken })
      .then((tokenRefreshResponse: any) => {
        const newAccessToken = tokenRefreshResponse?.data?.access;
        const newRefreshToken = tokenRefreshResponse?.data?.refresh;

        if (!newAccessToken || !newRefreshToken) {
          genericErrorHandler(t("common:error:yourSessionHasBeenExpired"));
        }

        store.dispatch(
          profileActions.setTokens({
            access: newAccessToken,
            refresh: newRefreshToken,
          })
        );

        failedRequest.response.config.headers["Authorization"] = generateAuthHeader(newAccessToken);

        return Promise.resolve();
      })
      .catch(() => {
        // If any error when fetching refresh token we need to logout user
        this.logout();

        genericErrorHandler(t("common:error:yourSessionHasBeenExpired"));
      });
  };

  logout = () => {
    history.push(frontendUrl.logout);
  };

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private async handleError(error: any) {
    let status = error?.response?.status;
    let apiErrorPrefix = "API Error:";
    let errorMessage = undefined;

    if (error?.message === "Network Error") {
      status = StatusCode.TimeOut;
    }

    switch (status) {
      case StatusCode.BadRequest: {
        // Handle BadRequest
        errorMessage = `${apiErrorPrefix} BadRequest`;
        break;
      }
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        errorMessage = `${apiErrorPrefix} InternalServerError`;
        break;
      }
      case StatusCode.Forbidden: {
        // Handle Forbidden
        errorMessage = `${apiErrorPrefix} Forbidden`;
        break;
      }
      case StatusCode.NotFound: {
        // Handle NotFound
        errorMessage = `${apiErrorPrefix} NotFound`;
        break;
      }
      case StatusCode.Unauthorized: {
        errorMessage = `${apiErrorPrefix} Unauthorized`;
        break;
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        errorMessage = `${apiErrorPrefix} TooManyRequests`;
        break;
      }

      case StatusCode.TimeOut: {
        // Handle TooManyRequests
        errorMessage = `${apiErrorPrefix} TimeOut`;
        break;
      }
    }

    console.error(errorMessage);
    // if (errorMessage) {
    //   snackbarService.showSnackbar(errorMessage, "error", 6000);
    // }

    return Promise.reject(error);
  }
}

export const axiosApi = new AxiosApi();
