import { inboxConstants } from "./index";
import { isEmpty } from "lodash";

const initialState = {
  isLoading: false,
  newMessagesCount: 0,
  quotationMessages: [], // The quotation chat messages
  messages: [], // The inbox quotation threads
  messagesPage: 1,
  messagesHasNextPage: false,
  selectedMessagesFilterRfq: undefined,
  activeQuotationThread: null, // The selected quotation message from the inbox drawer
  fileAttachments: [],
  isFileAttachmentsLoading: false,
  chatWindowState: false,
  isMessagesLoading: false,
  isQuotationMessagesLoading: false,
};

export function inbox(state = initialState, action) {
  switch (action.type) {
    /**
     * Inbox Quotations Messages
     */
    case inboxConstants.FETCH_QUOTATION_MESSAGES_REQUEST:
      return { ...state, isQuotationMessagesLoading: true };

    case inboxConstants.FETCH_QUOTATION_MESSAGES_SUCCESS:
      return {
        ...state,
        isQuotationMessagesLoading: false,
        quotationMessages: action.quotationMessages,
      };

    case inboxConstants.FETCH_QUOTATION_MESSAGES_FAILURE:
      return { ...state, isQuotationMessagesLoading: false };

    /**
     * POST a new message
     */
    case inboxConstants.POST_QUOTATION_MESSAGE_SUCCESS:
      const quotationThread = state.messages.find((o) => o.pk === state.activeQuotationThread.pk);
      if (!quotationThread) {
        return state;
      }
      // Update the thread updated_at date with the last message created_at date.
      // This will help to sort quotation threads without reloading the store
      quotationThread.updated_at = action.message.created_at;

      quotationThread.last_message = {
        event_type: action.message.event_type,
        content: action.message.content,
        company_pk: action.message.company.pk,
        created_at: action.message.created_at,
      };
      state.messages.sort(
        (d1, d2) => new Date(d2.updated_at).getTime() - new Date(d1.updated_at).getTime()
      );

      return {
        ...state,
        messages: [...state.messages],
        quotationMessages: [...state.quotationMessages, action.message],
      };
    /**
     * Inbox Messages
     */
    case inboxConstants.FETCH_INBOX_MESSAGES_REQUEST:
      return {
        ...state,
        isMessagesLoading: true,
        messagesPage: action.messagesPage,
        selectedMessagesFilterRfq: action.selectedMessagesFilterRfq,
      };

    case inboxConstants.FETCH_INBOX_MESSAGES_SUCCESS:
      let newMessagesCount = 0;
      const messagesPage = state.messagesPage;
      const messages = action.messages.results;
      const messagesHasNextPage = !isEmpty(action.messages.next);

      messages.forEach((element) => {
        newMessagesCount += element.unread_messages_count;
      });

      return {
        ...state,
        isMessagesLoading: false,
        newMessagesCount: newMessagesCount,
        messagesHasNextPage,
        messages: messagesPage > 1 ? [...state.messages, ...messages] : messages,
      };

    case inboxConstants.FETCH_INBOX_MESSAGES_FAILURE:
      return { ...state, isMessagesLoading: false };

    /**
     * Active Quotation
     */
    case inboxConstants.SET_ACTIVE_QUOTATION:
      if (action.quotationThread) {
        const q = state.messages.find((o) => o.pk === action.quotationThread.pk);
        if (!q) {
          return {
            ...state,
            activeQuotationThread: action.quotationThread,
          };
        }
        // Reset the count
        state.newMessagesCount = state.newMessagesCount - q.unread_messages_count;
        q.unread_messages_count = 0;
      }

      window.document.title = `Axya`;
      return {
        ...state,
        messages: [...state.messages],
        newMessagesCount: state.newMessagesCount,
        activeQuotationThread: action.quotationThread,
      };

    /**
     * Real time updates
     */
    case inboxConstants.INBOX_MESSAGE_QUOTATION_DROP:
      action.message.unread_messages_count = 1;
      state.messages.push(action.message);
      state.messages.sort(
        (d1, d2) => new Date(d2.updated_at).getTime() - new Date(d1.updated_at).getTime()
      );
      return {
        ...state,
        messages: [...state.messages],
        newMessagesCount: (state.newMessagesCount += 1),
      };

    case inboxConstants.COMPANY_ONLINE_STATUS_UPDATE:
      state.messages.forEach((msg) => {
        if (!msg) {
          return;
        }
        if (msg.instigator && msg.instigator.pk == action.cid) {
          msg.instigator.is_online = action.status;
        }
      });
      return {
        ...state,
        messages: [...state.messages],
      };

    case inboxConstants.PUBLISH_ROOM_MESSAGE:
      return {
        ...state,
        quotationMessages: [...state.quotationMessages, action.message],
      };

    case inboxConstants.INBOX_QUOTATION_MESSAGES_COUNT_UPDATE:
      const pid = action.pid;
      let quotationMsg = state.messages.find((o) => o.pk === pid);

      if (!quotationMsg) {
        return state;
      }

      const receivedMsg = action.message;

      // If the quotation chat is already open when receiving the message
      // Dont update the global count, update only the quotation count..
      quotationMsg.unread_messages_count++;
      // Update the date so it moves the top
      quotationMsg.updated_at = new Date();

      quotationMsg.last_message = receivedMsg.last_message;

      quotationMsg.status = receivedMsg.status;

      state.newMessagesCount++;

      state.messages.sort(
        (d1, d2) => new Date(d2.updated_at).getTime() - new Date(d1.updated_at).getTime()
      );

      if (state.activeQuotationThread) {
        if (state.activeQuotationThread.pk === action.pid) {
          if (
            ["contracted_rfq", "not_contracted_rfq", "canceled_rfq"].includes(
              action.thread_message.event_type
            )
          )
            state.quotationMessages.push(action.thread_message);
        }
      }

      return {
        ...state,
        messages: [...state.messages],
        newMessagesCount: state.newMessagesCount,
        quotationMessages: [...state.quotationMessages],
      };

    case inboxConstants.HANDLE_INBOX_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        isFileAttachmentsLoading: true,
      };

    case inboxConstants.HANDLE_INBOX_FILE_UPLOAD_SUCCESS:
      // state.fileAttachments.push(action.file);
      return {
        ...state,
        isFileAttachmentsLoading: false,
        fileAttachments: [...state.fileAttachments, action.file],
      };

    case inboxConstants.HANDLE_INBOX_FILE_UPLOAD_FAILURE:
      return {
        ...state,
        isFileAttachmentsLoading: false,
      };

    case inboxConstants.DELETE_FILE_UPLOAD_SUCCESS:
      const files = state.fileAttachments.filter((attachment) => attachment.pk !== action.pk);
      return {
        ...state,
        fileAttachments: [...files],
      };

    // clear uploaded file attachments after send button click
    case inboxConstants.CLEAR_FILE_ATTACHMENTS:
      return {
        ...state,
        fileAttachments: [],
      };

    case inboxConstants.SET_MINIMIZE_CHAT_WINDOW:
      return {
        ...state,
        chatWindowState: action.isMinimized,
      };

    default:
      return state;
  }
}
