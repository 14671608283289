import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Box, DialogContent, Link } from "@mui/material";
import TextMessage from "./message/message.component";
import QuotationDropMessage from "./message/quotation.component";
import ContractWonRFQMessage from "./message/contractWon.component";
import ContractLostRFQMessage from "./message/contractLost.component";
import CanceledRFQMessage from "./message/rfqCancelled.component";
import RateSupplierMessage from "./message/rateSupplier.component";
import QuotationUpdateMessage from "./message/quotation-update-message.component";

import TextInput from "./text-input/text-input.component";
import { formatDate } from "helpers/formatDate";
import { ContentLoader } from "../content-loader/content-loader.component";
import { Trans } from "react-i18next";
import { frontendUrl } from "urls";
import { getCompany } from "services/profile/profile.selectors";
import { checkIsSellerCompany } from "@next/utils/checkCompanyUtils";

export default ({
  activeQuotationThread,
  listenToNewMessages,
  publishMessage,
  emitMessage,
  onReceiveRFQRoomMsg,
  sendQuotationMessage,
  getQuotationMessages,
  quotationMessages,
  token,
  user,
  isQuotationMessagesLoading,
  t,
}) => {
  // Abort if activeQuotationThread is null
  if (!activeQuotationThread) {
    return <></>;
  }
  const msgRef = React.useRef(null);

  const [messages, setMessages] = useState(quotationMessages);
  const [groupMessagesByDate, setGroupMessagesByDate] = useState([]);
  const language = useSelector((state) => state.profile.user.language);
  const company = useSelector(getCompany);
  const isSeller = checkIsSellerCompany(company?.type);
  /**
   * Handle scrolling
   */
  const scroll = React.useCallback(() => {
    if (msgRef && msgRef.current) {
      setTimeout(() => {
        if (!msgRef || !msgRef.current) {
          return;
        }
        msgRef.current.scrollTop = msgRef.current.scrollHeight;
      }, 100);
    }
  }, [msgRef]);

  const sendMessage = (message, filePks) => {
    if (!activeQuotationThread) {
      return;
    }
    const quotationPk = activeQuotationThread.quotation.pk;
    // Save the message via the API call
    sendQuotationMessage(token, quotationPk, message, filePks).then((data) => {
      emitMessage(token, data);
    });
  };
  /**
   * Join the current room
   */

  useEffect(() => {
    if (!activeQuotationThread) {
      return;
    }
    const quotationPk = activeQuotationThread.quotation.pk;
    getQuotationMessages(token, quotationPk);
    listenToNewMessages(token, user.email, quotationPk);
    onReceiveRFQRoomMsg((receivedMessage) => {
      // Updates the redux store with the received message
      publishMessage(receivedMessage);
      scroll();
    });

    scroll();
  }, [
    scroll,
    activeQuotationThread,
    getQuotationMessages,
    listenToNewMessages,
    onReceiveRFQRoomMsg,
  ]);

  useEffect(() => {
    setMessages(quotationMessages);
    scroll();
  }, [quotationMessages]);

  useEffect(() => {
    // grouping messages by create date
    if (messages && messages.length > 0) {
      const formattedCreatedDate = (item) => {
        if (!item) {
          return;
        }
        return formatDate(item.created_at);
      };
      const groupedMessages = _.groupBy(messages, formattedCreatedDate);
      const groupedMessagesArray = [];
      // construct groupedMessages in required format date and messages
      for (let groupedMessage in groupedMessages) {
        groupedMessagesArray.push({
          date: groupedMessage,
          messages: groupedMessages[groupedMessage],
        });
      }
      setGroupMessagesByDate(groupedMessagesArray);
    }
  }, [messages]);

  let lastMessage;
  let isLocked = false;

  return (
    <DialogContent className="c-discussion" fullscreen>
      <div className="c-discussion--content" ref={msgRef}>
        {isQuotationMessagesLoading ? (
          <ContentLoader count={4} isChatWindow={true} />
        ) : (
          <div>
            {groupMessagesByDate.map((groupedMessages) => {
              return (
                <>
                  <Box
                    pt={1}
                    display="flex"
                    justifyContent="center"
                    className="c-discussion--created-date"
                  >
                    <p>{groupedMessages.date}</p>
                  </Box>
                  {groupedMessages.messages.map((message) => {
                    if (!message) {
                      return <></>;
                    }
                    let isMergeDiscussion =
                      lastMessage &&
                      lastMessage.author &&
                      message &&
                      message.author &&
                      lastMessage.author.email === message.author.email;

                    if (isMergeDiscussion) {
                      // We only merge the messages of the same author
                      // within a 5 minutes timespan
                      const previousMsgCreatedDate = new Date(lastMessage.created_at).getTime();
                      const currentMsgCreatedDate = new Date(message.created_at).getTime();
                      if (currentMsgCreatedDate - previousMsgCreatedDate > 300 * 1000) {
                        isMergeDiscussion = false;
                      }
                    }
                    lastMessage = message;
                    let MessageCmp = TextMessage;
                    if (message && message.event_type === "quotation_drop") {
                      MessageCmp = QuotationDropMessage;
                    }
                    if (message && message.event_type === "contracted_rfq") {
                      MessageCmp = ContractWonRFQMessage;
                    }

                    if (message && message.event_type === "not_contracted_rfq") {
                      isLocked = true;
                      MessageCmp = ContractLostRFQMessage;
                    }

                    if (message && message.event_type === "canceled_rfq") {
                      isLocked = true;
                      MessageCmp = CanceledRFQMessage;
                    }

                    if (message && message.event_type === "rate_supplier" && user.type === "DO") {
                      MessageCmp = RateSupplierMessage;
                    }
                    if (
                      message &&
                      message.event_type === "quotation_update" &&
                      (user.type === "DO" || user.type === "FAB")
                    ) {
                      MessageCmp = QuotationUpdateMessage;
                    }
                    return (
                      <>
                        <MessageCmp
                          t={t}
                          key={message.pk}
                          id={message.pk}
                          message={message}
                          user={user}
                          quotationCreatedAt={
                            activeQuotationThread && activeQuotationThread.quotation.created_at
                          }
                          activeQuotationThread={activeQuotationThread}
                          language={language}
                          isHideAuthorInfo={isMergeDiscussion}
                        />

                        {lastMessage.author &&
                          message &&
                          message.author &&
                          lastMessage.author.email !== message.author.email && <Box m={1}></Box>}
                      </>
                    );
                  })}
                </>
              );
            })}
          </div>
        )}
      </div>

      {!activeQuotationThread.is_locked && !isLocked ? (
        <div className="c-discussion--text-input">
          <TextInput sendMessage={sendMessage} user={user} t={t} />
        </div>
      ) : (
        <Box className="c-discussion--closed">
          <p>
            <Trans
              i18nKey="workspace:messengerPhasedOutMsg"
              components={[
                <Link
                  href={
                    isSeller
                      ? `${frontendUrl.supplierQuoteView}/${activeQuotationThread.quotation?.pk}?tab=1`
                      : `${frontendUrl.bundleView}/${activeQuotationThread.rfq?.pk}?tab=1&fromSplitOrInbox=true`
                  }
                >
                  communication thread
                </Link>,
              ]}
            />
          </p>
        </Box>
      )}
    </DialogContent>
  );
};
