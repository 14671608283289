import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { NameCell } from "../../report-table-cells/name-cell";
import { TextCell } from "../../report-table-cells/text-cell";
import { HeaderCell } from "../../report-table-cells/header-cell";
import { formatNumber } from "helpers/formatNumber";
import { ProgressCircle } from "@next/components/progress-circle";
import { AppTheme } from "layout/AppTheme";
import { Typography } from "@mui/material";
import i18next from "i18next";

export const getOrdersReportTableColumns = (t: i18next.TFunction) => {
  const ScoreCell: React.FC<GridRenderCellParams> = ({ value }) => {
    return (
      <>
        <ProgressCircle
          value={value}
          colors={[{ value: value, color: `${AppTheme.palette.primary.main}` }]}
          style={{ width: "24px", height: "24px" }}
        />
        <Typography variant="body2" style={{ marginLeft: "8px" }}>
          {value + "%"}
        </Typography>
      </>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "supplier_name",
      headerName: "Name",
      renderHeader: () => <HeaderCell text={t("analytics:global:name")} />,
      width: 246,
      sortable: true,
      renderCell: NameCell,
    },
    {
      field: "items_count",
      headerName: "Items sent in order",
      renderHeader: () => <HeaderCell text={t("analytics:ordersReport:cards:rfqsCount")} />,
      flex: 0.5,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => (
        <TextCell {...params} value={`${params.value} ${t("analytics:global:items")}`} />
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: "avg_time_to_acknowledge",
      headerName: "Avg. time to acknowledge",
      renderHeader: () => <HeaderCell text={t("analytics:ordersReport:table:columns:avgTime")} />,
      flex: 0.5,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => (
        <TextCell
          {...params}
          value={`${params.value} ${t("analytics:global:day", {
            count: params.value,
          })}`}
        />
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: "shipped_percentage",
      headerName: "% of orders marked as shipped",
      renderHeader: () => (
        <HeaderCell
          text={t("analytics:ordersReport:table:columns:ordersShip:text")}
          tooltipTitle={t("analytics:ordersReport:table:columns:ordersShip:tooltipTitle")}
        />
      ),
      flex: 0.5,
      sortable: true,
      disableColumnMenu: true,
      renderCell: ScoreCell,
    },
    {
      field: "on_time_percentage",
      headerName: "On time delivery",
      renderHeader: () => <HeaderCell text={t("analytics:ordersReport:table:columns:delivery")} />,
      flex: 0.5,
      sortable: true,
      renderCell: ScoreCell,
      valueGetter: (params) => params.value,
    },
    {
      field: "total_value",
      headerName: "Total value (CAD)",
      renderHeader: () => <HeaderCell text={t("analytics:ordersReport:cards:totalValue")} />,
      flex: 0.5,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => (
        <TextCell {...params} value={`${formatNumber(params.value)} CAD`} />
      ),
      valueGetter: (params) => params.value,
    },
  ];

  return columns;
};
