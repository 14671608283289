import { Theme, Tooltip } from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      width: "fit-content",
      maxWidth: theme.spacing(87.5),
      minWidth: theme.spacing(62.5),
      textAlign: "justify",
      "&:hover": {
        width: "100%",
      },
    },
  })
);
const PaperProps = {
  width: "fit-content",
  maxWidth: 463,
};

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    width: "fit-content",
    maxWidth: theme.spacing(33.75),
    fontSize: 12,
    marginTop: theme.spacing(0.5),
  },
}))(Tooltip);

export { useStyles, PaperProps, CustomTooltip };
