import React, { useEffect, useState } from "react";
import { GridCellParams } from "@mui/x-data-grid-pro-v5";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  PartialStep,
  Questionnaire,
  QuestionnaireType,
  SubtiersFormData,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectAddSpecToScopeLoading,
  selectFormData,
  selectIsFetchSpecByClickedThread,
  selectIsFormDisplayForValidationManagement,
  selectIsFormStepCompleted,
  selectQuestionnaireName,
  selectRemoveSpecFromScopeLoading,
  selectSpecsOfScope,
  selectSpecsOfScopeLoading,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { getScopeFormTableColumns } from "./scope-form-table.columns";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./scope-form-table.styled";

type Props = {
  hideCompletedTableLines: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  isTableDisabled: boolean;
};

export const ScopeFormTable: React.FC<Props> = ({
  hideCompletedTableLines,
  page,
  setPage,
  pageSize,
  isTableDisabled,
}) => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const steps = useSelector(selectVisibleSteps);
  const isFormCompleted = useSelector(selectIsFormStepCompleted);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const step = steps[activeFormStepIndex];
  const specsOfScopeData = useSelector(selectSpecsOfScope);
  const questionnaireName: Questionnaire = useSelector(selectQuestionnaireName);
  const isTableInPomDistributorQuestionnaire = questionnaireName === Questionnaire.PomDistributor;
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );
  const rows = specsOfScopeData?.results?.data;
  const isLoadingFetchSpecs = useSelector(selectSpecsOfScopeLoading);
  const isLoadingRowDelete = useSelector(selectRemoveSpecFromScopeLoading);
  const isLoadingAddSpecToScopeTable = useSelector(selectAddSpecToScopeLoading);
  const [searchValue, setSearchValue] = useState<string>("");
  const formData = useSelector(selectFormData) as SubtiersFormData;
  const isFetchSpecByClickedThread = useSelector(selectIsFetchSpecByClickedThread);
  const columns = getScopeFormTableColumns(
    isTableInPomDistributorQuestionnaire,
    isFormDisplayForValidationManagement,
    isTableDisabled,
    searchValue,
    setSearchValue
  );

  useEffect(() => {
    if (formData) {
      dispatch(vendorManagementActions.fetchSubtierCompaniesRequest(searchValue));
    }
  }, [searchValue]);

  const handleCellClick = (params: GridCellParams) => {
    if (isTableDisabled) {
      return;
    }

    if (params.field === "removeRowButton" && !isTableDisabled) {
      const id = params.row.specification_id;
      dispatch(
        vendorManagementActions.removeSpecFromScopeRequest({
          companyId: companyId,
          specification_id: id,
          onSuccess: () => onRemoveSuccess(params.row?.entity_id),
        })
      );
      return;
    }
  };

  const onRemoveSuccess = (entity_id: number) => {
    checkInputChange();
    dispatch(
      vendorManagementActions.decreaseUnapprovedThreadsCountOfStepIfRemovedLineHasUnresolvedThread({
        stepId: step.step,
        entityIdOfLine: entity_id,
      })
    );
    reFetchStepsToGetUpdatedUnresolvedThreadsCountsOfSteps();
    reFetchTableDataToGetUpdatedCount();
  };

  const reFetchStepsToGetUpdatedUnresolvedThreadsCountsOfSteps = () => {
    dispatch(
      vendorManagementActions.fetchStepsRequestWithoutCausingReRendering({
        companyId: companyId,
        questionnaireType: QuestionnaireType[questionnaireName],
      })
    );
  };

  const reFetchTableDataToGetUpdatedCount = () => {
    dispatch(
      vendorManagementActions.fetchSpecsOfScopeRequest({
        companyId,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
        isInPomDistributor: isTableInPomDistributorQuestionnaire,
        stepId: step.step,
        filter_completed: hideCompletedTableLines,
        isForFetchingToGetCountData: true,
      })
    );
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStep("is_completed", false, activeFormStepIndex);
    } else {
      updateFieldStatusOfStep("is_partially_filled", true, activeFormStepIndex);
    }
  };

  const updateFieldStatusOfStep = (
    key: "is_completed" | "is_partially_filled",
    value: boolean,
    stepIndex: number
  ) => {
    const step = steps[stepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
        onSuccess:
          key === "is_completed" && !value
            ? () => updateApprovalOfStepAfterCompletionChange("changes_requested")
            : undefined,
      })
    );
  };

  const updateApprovalOfStepAfterCompletionChange = (statusParam: "changes_requested") => {
    if (step?.approval_status === "changes_done" && statusParam === "changes_requested") {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: step.step,
          status: statusParam,
        })
      );
    }
  };

  return (
    <>
      <S.StyledDataGridPro
        columns={columns}
        rows={rows || []}
        headerHeight={48}
        rowHeight={!isFormDisplayForValidationManagement ? 32 : 40}
        disableSelectionOnClick
        onCellClick={handleCellClick}
        getRowId={(row) => row.specification_id}
        loading={isLoadingFetchSpecs || isLoadingRowDelete || isLoadingAddSpecToScopeTable}
        pagination
        paginationMode="server"
        pageSize={pageSize}
        page={!isFetchSpecByClickedThread ? page - 1 : 1}
        rowCount={specsOfScopeData?.count ? specsOfScopeData.count : 0}
        rowsPerPageOptions={[pageSize]}
        onPageChange={(newPage) => setPage(newPage + 1)}
        autoHeight
      />
    </>
  );
};
