import React from "react";
import { GridColumnHeaderParams } from "@mui/x-data-grid";
import { styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";

type Props = {
  required?: boolean;
} & GridColumnHeaderParams;

type StyledTypographyProps = {
  required: boolean;
};

const StyledTypography = styled(CustomTypography, {
  shouldForwardProp: (prop) => prop !== "required",
})<StyledTypographyProps>(({ theme, required }) => ({
  fontWeight: 700,
  fontSize: "13px",
  lineHeight: "16px",
  letterSpacing: "0.4px",
  color: required ? theme.palette.primary.main : theme.palette.text.primary,
}));

export const HeaderCell: React.FC<Props> = ({ colDef, required }) => {
  return <StyledTypography required={required}>{colDef.headerName}</StyledTypography>;
};
