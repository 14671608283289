import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { SideDrawerReviewBox } from "../side-drawer-review-box/side-drawer-review-box";
import { vendorManagementActions } from "@next/modules/vendor-management/redux";
import {
  selectIsSideDrawerOpen,
  selectMessageThreadsOfStep,
  selectMessageThreadsOfStepLoading,
  selectMessagesOfThreadLoading,
  selectTriggerForFetchingMessagesOfThread,
} from "@next/modules/vendor-management/redux/selectors";
import * as S from "./side-drawer-unthreadful-review.styled";

export const SideDrawerUnThreadfulReview: React.FC = () => {
  const dispatch = useDispatch();
  const messageThreads = useSelector(selectMessageThreadsOfStep);
  const isLoadingFetchMessageThreadsLoading = useSelector(selectMessageThreadsOfStepLoading);
  const isLoadingMessagesOfThread = useSelector(selectMessagesOfThreadLoading);
  const triggerStateForFetchingMessagesOfThread = useSelector(
    selectTriggerForFetchingMessagesOfThread
  );
  const isDrawerOpen = useSelector(selectIsSideDrawerOpen);

  useEffect(() => {
    if (
      messageThreads &&
      messageThreads?.length !== 0 &&
      isDrawerOpen &&
      !isLoadingMessagesOfThread
    ) {
      dispatch(
        vendorManagementActions.fetchMessagesOfThreadRequest({
          messageThreadId: messageThreads[0].id,
        })
      );
    }
  }, [isLoadingFetchMessageThreadsLoading, triggerStateForFetchingMessagesOfThread]);

  if (isLoadingFetchMessageThreadsLoading || isLoadingMessagesOfThread) {
    return (
      <S.StyledLoadingBox>
        <CircularProgress />
      </S.StyledLoadingBox>
    );
  }

  return (
    <>
      <SideDrawerReviewBox isThreadful={false} />
    </>
  );
};
