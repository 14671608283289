import React, { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";

import { Form, FormikProps } from "formik";
import { Box, Button, Grid, Theme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { selectRfqUpdatePartialLoading } from "services/workspace/workspace.selectors";
import { selectEditRfqDescription } from "@next/modules/workspace/redux/selectors";
import { RichTextEditor } from "@next/components/rich-text-editor";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import CustomIconButton from "@next/components/custom-icon-button/custom-icon-button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export type RfqDescriptionFormValue = {
  additionalDetails?: string | Record<string, any>;
};

type OtherProps = {
  defaultValue?: string;
};

export const RfqDescriptionFormBase: React.FC<
  FormikProps<RfqDescriptionFormValue> & OtherProps
> = ({ handleSubmit, setFieldValue, dirty, defaultValue }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isRfqUpdatePartialLoading = useSelector(selectRfqUpdatePartialLoading);
  const isEditRfqDescription = useSelector(selectEditRfqDescription);
  const [key, forceRemount] = useReducer((x) => x + 1, 0);
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);

  const handleChange = (value) => {
    setFieldValue("additionalDetails", value);
  };

  useEffect(() => {
    forceRemount();
  }, [defaultValue]);

  const handleSetEditRfqDescription = () => {
    dispatch(workspaceNextActions.setEditRfqDescription(true));
  };

  const handleCancelEditRfqDescription = () => {
    setFieldValue("additionalDetails", defaultValue);
    batch(() => {
      dispatch(workspaceNextActions.setEditRfqDescription(false));
    });
    forceRemount();
  };

  return (
    <Box>
      <Form onSubmit={handleSubmit} autoComplete="off">
        <Grid item xs={12}>
          <RichTextEditor
            readOnly={isEditRfqDescription ? false : true}
            onChange={handleChange}
            defaultValue={defaultValue}
            key={key}
          />
        </Grid>

        {isEditRfqDescription ? (
          <Grid item xs={12} className={classes.flexEnd}>
            <Box>
              <Button variant="outlined" onClick={handleCancelEditRfqDescription}>
                {t("common:cancel")}
              </Button>
            </Box>
            <Box ml={1}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={isRfqUpdatePartialLoading || !dirty}
              >
                {t("rfq:saveDescription")}
              </Button>
            </Box>
          </Grid>
        ) : null}
      </Form>
      <Box mt={1} mb={1} display="flex" alignItems="center">
        {!hasViewerRole && !isEditRfqDescription ? (
          <CustomIconButton size="small" onClick={handleSetEditRfqDescription} bordered={true}>
            <EditIcon style={{ width: "18px", height: "18px" }} />
          </CustomIconButton>
        ) : null}
      </Box>
    </Box>
  );
};
