import { GridColDef, GridColumns } from "@mui/x-data-grid-pro-v5";
import React from "react";

import { t } from "assets/configi18n/i18n";
import BuyerCell from "@next/components/table-cells/buyer-cell-v5";
import DateCell from "@next/components/table-cells/date-cell-v5";
import TextCell from "@next/components/table-cells/text-cell-v5";
import PurchaseOrderCell from "@next/components/table-cells/purchase-order-cell-v5";
import LeadTimeCell from "@next/components/table-cells/lead-time-cell-v5";
import { ContractsTableField } from "./contracts-table.types";

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
};

export const getContractsTableColumns = () => {
  const _columns: GridColumns = [
    {
      ...defaultColumnConfig,
      field: ContractsTableField.BUYER,
      sortable: true,
      hideSortIcons: false,
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerName: t("workspaceNext:sellerContracts:table:header:companyName"),
      renderCell: BuyerCell,
    },
    {
      ...defaultColumnConfig,
      field: ContractsTableField.RFQ_DISPLAY_NAME,
      flex: 1,
      headerName: t("workspaceNext:sellerContracts:table:header:name"),
      renderCell: TextCell,
    },
    {
      ...defaultColumnConfig,
      field: ContractsTableField.PARTS,
      width: 100,
      align: "right",
      headerAlign: "right",
      headerName: t("workspaceNext:sellerContracts:table:header:items"),
      renderCell: TextCell,
    },
    {
      ...defaultColumnConfig,
      field: ContractsTableField.OWNER,
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerName: t("workspaceNext:sellerContracts:table:header:createdBy"),
      renderCell: TextCell,
      valueGetter: (params) =>
        typeof params.row?.created_by === "object"
          ? params.row?.created_by?.full_name || ""
          : params.row?.created_by || "",
    },
    {
      ...defaultColumnConfig,
      field: ContractsTableField.AWARDED_ON,
      sortable: true,
      hideSortIcons: false,
      width: 150,
      align: "left",
      headerAlign: "left",
      headerName: t("workspaceNext:sellerContracts:table:header:awardedOn"),
      renderCell: DateCell,
    },

    {
      ...defaultColumnConfig,
      field: ContractsTableField.COST_AND_LEAD_TIME,
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerName: t("workspaceNext:sellerContracts:table:header:costAndLeadTime"),
      renderCell: (params) => <LeadTimeCell {...params} />,
    },
    {
      ...defaultColumnConfig,
      field: ContractsTableField.PURCHASE_ORDER,
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerName: t("workspaceNext:sellerContracts:table:header:purchaseOrder"),
      renderCell: (params) => <PurchaseOrderCell {...params} readOnly={true} />,
    },
    // {
    //   ...defaultColumnConfig,
    //   field: ContractsTableField.OWNER,
    //   width: 80,
    //   align: "center",
    //   headerAlign: "center",
    //   headerName: t("workspaceNext:sellerContracts:table:header:owner"),
    //   renderCell: OwnerCell,
    // },
    // {
    //   ...defaultColumnConfig,
    //   field: ContractsTableField.SUPPLIER_SELECTION_EXPORT,
    //   width: 184,
    //   align: "center",
    //   headerAlign: "center",
    //   headerName: t("workspaceNext:sellerContracts:table:header:quoteData"),
    //   renderCell: BRPExportCell,
    //   hide: !checkIsBRPCompany(getCompany(store.getState()).id),
    // },
    // {
    //   ...defaultColumnConfig,
    //   field: ContractsTableField.RATING,
    //   flex: 0.25,
    //   align: "right",
    //   headerAlign: "right",
    //   headerName: t("workspaceNext:sellerContracts:table:header:rating"),
    //   renderCell: (params) => <RatingCell {...params} size="small" />,
    // },
    // {
    //   ...defaultColumnConfig,
    //   field: ContractsTableField.MARK_AS_COMPLETE,
    //   width: 240,
    //   align: "right",
    //   headerName: " ",
    //   renderCell: MarkAsCompleteCell,
    // },
  ];

  return _columns;
};
