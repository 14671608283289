import React, { ReactNode } from "react";
import { Box, Chip, Theme, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: theme.palette.common.white,
      boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)`,
      padding: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      borderRadius: 24,
      maxWidth: 372,
      boxSizing: "border-box",
      textAlign: "left",
    },
    variantIcon: {
      width: 22,
    },
    primaryColor: {
      color: theme.palette.primary.main,
    },
    successColor: {
      color: theme.palette.success.dark,
      borderColor: theme.palette.success.dark,
      "& .MuiChip-icon": {
        color: "inherit",
      },
    },
    errorColor: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      "& .MuiChip-icon": {
        color: "inherit",
      },
    },
    title: {
      fontSize: 20,
      color: theme.palette.common.black,
      marginLeft: theme.spacing(1.25),
    },
    footerNote: {
      color: theme.palette.text.secondary,
    },
  })
);

type Status = "success" | "error";

interface Props {
  variant: Status;
  title: string;
  status?: Status;
  statusText?: string;
  bodyParas?: ReactNode[];
  footerNote?: string;
  cardClassName?: string;
  children?: ReactNode;
}

export const InfoCard: React.FC<Props> = ({
  variant,
  title,
  status,
  statusText,
  bodyParas,
  footerNote,
  children,
  cardClassName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={`${classes.card} ${cardClassName}`}>
      <Box display="flex" alignItems="center">
        {variant === "success" ? (
          <CheckCircleIcon className={`${classes.primaryColor} ${classes.variantIcon}`} />
        ) : (
          <CancelIcon className={`${classes.errorColor} ${classes.variantIcon}`} />
        )}
        <Typography className={classes.title}>{title}</Typography>
      </Box>

      {status && statusText ? (
        <Box display="flex" alignItems="center">
          <Typography variant="body2">{t("publicItbItnb:status")}:</Typography>
          <Box ml={1}>
            <Chip
              size="medium"
              variant="outlined"
              label={statusText}
              className={status === "success" ? classes.successColor : classes.errorColor}
              icon={status === "success" ? <CheckIcon /> : <CloseIcon />}
            />
          </Box>
        </Box>
      ) : null}

      {bodyParas?.map((para) => <Typography variant="body2">{para}</Typography>) || null}

      {footerNote ? (
        <Typography variant="body2" className={classes.footerNote}>
          {footerNote.split("\n").map((text, i) => (
            <div key={i}>{text}</div>
          ))}
        </Typography>
      ) : null}

      {children}
    </Box>
  );
};

export default InfoCard;
