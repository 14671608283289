import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@next/components/custom-modal";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { PortalModalTypes } from "./types";
import { PortalItbItnbModalProps } from "./portal-itnb-modal";
import InfoCard from "@next/modules/marketplace/pages/public-itb-itnb/info-card";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import CustomButton from "@next/components/custom-button/custom-button";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { selectUpdatePortalQuoteNoQuoteLoading } from "@next/modules/workspace/redux/selectors";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      boxShadow: "none",
      padding: theme.spacing(1),
      maxWidth: "unset",
      paddingBottom: theme.spacing(1.5),
    },
  })
);

export const PortalItbModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(PortalModalTypes.ITB_MODAL));
  const { t } = useTranslation();
  const classes = useStyles();
  const loading = useSelector(selectUpdatePortalQuoteNoQuoteLoading);

  const { portalData, rfqToken, onClose } = (modal?.data || {}) as PortalItbItnbModalProps;

  if (!modal || !portalData || !rfqToken) return null;

  const _onClose = () => {
    dispatch(modalsActions.closeModal(PortalModalTypes.ITB_MODAL));
    if (typeof onClose === "function") onClose();
  };

  const onSave = () => {
    dispatch(
      workspaceNextActions.updatePortalQuoteNoQuoteStatusRequest({
        rfqToken,
        type: "itb",
        onSuccess: _onClose,
      })
    );
  };

  return (
    <Modal
      handleModalClose={_onClose}
      modalState={{
        modalBody: (
          <InfoCard
            variant="success"
            title={t("publicItbItnb:title")}
            status="success"
            statusText={t("publicItbItnb:interestedToQuote")}
            bodyParas={[t("publicItbItnb:itbDescription"), t("publicItbItnb:regards")]}
            footerNote={`${portalData.created_by?.full_name}\n${portalData.company?.name}`}
            cardClassName={classes.card}
          >
            <Box mt={-1} display="flex" justifyContent="flex-end">
              <CustomButton color="primary" variant="contained" onClick={onSave} loading={loading}>
                {t("publicItbItnb:save")}
              </CustomButton>
            </Box>
          </InfoCard>
        ),
      }}
      isModalOpen={true}
    />
  );
};
