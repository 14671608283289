import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { Box, Button, Divider, Menu } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { QuoteStatus } from "components/common/table-list/types";

interface StatusInfoProps {
  status: string;
  cancellationReason: string;
  onHoldReason: string;
}

type StatusParams = {
  status?: string;
  reason?: string;
  buttonTherme?: string;
  title?: string;
};

export const StatusInfo: React.FC<StatusInfoProps> = ({
  status,
  cancellationReason,
  onHoldReason,
}) => {
  const { t } = useTranslation();

  const [statusParams, setStatusParams] = useState<StatusParams>({
    status: "",
    reason: "",
    buttonTherme: "",
    title: "",
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (status === QuoteStatus.CANCELED) {
      setStatusParams({
        ...statusParams,
        title: t("quotation:theRequestHasBeenCancelled"),
        buttonTherme: t("quotation:cancellationReason"),
        reason: cancellationReason,
        status: QuoteStatus.CANCELED,
      });
    } else if (status === QuoteStatus.ON_HOLD) {
      setStatusParams({
        ...statusParams,
        title: t("quotation:theRequestHasBeenOnHold"),
        buttonTherme: t("quotation:onHoldReason"),
        reason: onHoldReason,
        status: QuoteStatus.ON_HOLD,
      });
    } else {
      setStatusParams({});
    }
  }, [status]);

  return (
    <>
      {!isEmpty(statusParams) && (
        <Box
          pt={3}
          pb={3}
          pl={8}
          pr={8}
          mb={4}
          display="flex"
          alignItems="center"
          className="c-cancellation-reason"
        >
          <Box display="flex" alignItems="center" flexGrow={1}>
            <Box>
              <InfoIcon className="info-icon" />
            </Box>
            <Box fontSize={14}> {statusParams?.title}</Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              className="c-cancellation-reason--button"
            >
              {statusParams?.buttonTherme}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              PaperProps={{
                style: {
                  width: "403px",
                },
              }}
              className="c-cancellation-reason--menu"
            >
              <Box pr={3} pt={2} pl={3} pb={2} fontSize={20} fontWeight="fontWeightMedium">
                {statusParams?.buttonTherme}
              </Box>
              <Divider />
              <Box p={3} fontSize={14} className="reason-box">
                {statusParams?.reason}
              </Box>
            </Menu>
          </Box>
        </Box>
      )}
    </>
  );
};
