import { getAccessLevelTitle } from "@next/modals/components/invite-company-user/invite-company-user.utils";
import { GenericUser, User } from "@next/modules/profile/redux";
import i18next from "i18next";

export function getCollaboratorRoleTag(
  user: GenericUser,
  owner: GenericUser,
  t: i18next.TFunction
) {
  return user?.email === owner?.email
    ? t("workspaceNext:collaboratorsModal:owner")
    : getAccessLevelTitle(user?.user_type as User["user_type"]);
}

export function getAddedAndExistingCollaborators({
  collaboratorsEmails,
  companyUsers,
  owner,
  lookup,
  t,
}: {
  collaboratorsEmails: string[];
  companyUsers: GenericUser[];
  owner: GenericUser;
  lookup: Map<string, GenericUser>;
  t: i18next.TFunction;
}) {
  const added: GenericUser[] = [];
  const existing: GenericUser[] = owner
    ? [{ ...owner, tag: getCollaboratorRoleTag(owner, owner, t) }]
    : [];

  for (const item of collaboratorsEmails) {
    const found = lookup.get(item);
    if (found) {
      existing.push({
        ...found,
        tag: getCollaboratorRoleTag(found, owner, t),
      });
    } else {
      const user = companyUsers.find((user) => user.email === item) as GenericUser;
      added.push({
        ...user,
        tag: getCollaboratorRoleTag(user, owner, t),
      });
    }
  }

  return { added, existing };
}
