import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { TopicType } from "@next/modules/workspace/redux";
import TopicSubTitle from "../topic-sub-title";
import BadgeWrapper from "@next/components/badge-wrapper";

type Props = {
  topic: TopicType;
};

const TopicTitle: React.FC<Props> = ({
  topic: { subject, author, company, is_private, messages, unseen_messages_count },
}) => {
  return (
    <Box>
      <Box p={3}>
        <Typography variant="h6">{subject}</Typography>

        <BadgeWrapper color="primary" badgeContent={unseen_messages_count} right="-6%" top="55%">
          <TopicSubTitle
            createdByFullName={author?.full_name}
            companyName={company?.name}
            isPrivate={is_private}
            messagesCount={messages?.length}
          />
        </BadgeWrapper>
      </Box>

      <Box mx={-7}>
        <Divider />
      </Box>
    </Box>
  );
};

export default TopicTitle;
