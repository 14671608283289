// RF-TODO: convert store to type safety store
export const getIsConfirmationModalOpen = (state: any) => state.modals.isConfirmationModalOpen;

export const getIsExtendAndCancelRfqModalOpen = (state: any) =>
  state.modals.isExtendAndCancelRfqModalOpen;

export const getIsPurchaseOrderModalOpen = (state: any) => state.modals.isPurchaseOrderModalOpen;

export const getIsRFQDetailsOpen = (state: any) => state.modals.isRFQDetailsOpen;

export const getIsRFQTourOpen = (state: any) => state.modals.isRfqTourOpen;

export const getIsUserSurveyModalOpen = (state: any) => state.modals.isUserSurveyModalOpen;

export const modalSelectors = {
  getIsConfirmationModalOpen,
  getIsExtendAndCancelRfqModalOpen,
  getIsPurchaseOrderModalOpen,
  getIsRFQDetailsOpen,
  getIsRFQTourOpen,
  getIsUserSurveyModalOpen,
};
