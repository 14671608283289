import * as yup from "yup";

export const emailRegex = /^\w+([!#%&'*+-/=?^_`{|]?\w+)*@([\w-]+\.)+[\w-]{2,10}$/;

export const createSupplierSchema = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .matches(emailRegex, t("userOnboarding:emailAddressIsInvalid"))
      .required(t("common:required")),
    firstName: yup.string().required(t("common:required")),
    lastName: yup.string().required(t("common:required")),
    language: yup.string().required(t("common:required")),
    companyName: yup.string().required(t("common:required")),
    vendorId: yup.string(),
    currency: yup.string(),
  });
