import { Part } from "../../redux";

export enum CreateMultiContractTableField {
  PART = "part",
  SUPPLIER_ID = "supplier_id",
}

export enum CreateMultiContractState {
  CANCEL = -1,
  LEAVE_IN_RFQ = -2,
  ERROR = -3,
}

export type Quotation = {
  company_info: {
    company_name: string;
    company_pk: number;
    company_picture: string;
    company_uuid?: string;
  };
  parts: Part[];
  quoted_parts_count?: number;
};

export type SupplierKeyValue = {
  value: number;
  label: string;
  picture?: string;
  parts?: Part[];
};

export type CreateMultiContractData = {
  id?: number;
  part: Partial<Part>;
  supplier_id?: number;
  supplier_name?: string;
};

export type GroupedCreateMultiContractData = {
  supplier_id?: number;
  supplier_name?: string;
  parts: Partial<Part>[];
};
