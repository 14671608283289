export const PAGINATION_PAGE_SIZE = 30;

export const SHORT_PAGINATION_PAGE_SIZE = 20;

export const SUPPORTED_IMAGE_FILE_TYPES = ["PNG", "JPG", "JPEG"];

export const SUPPORTED_FILE_TYPES = [
  "PDF",
  "ZIP",
  "STL",
  "SLDPRT",
  "STP",
  "STEP",
  "TL",
  "OBJ",
  "FBX",
  "COLLADA",
  "3DS",
  "IGES",
  "IPT",
  "IGE",
  "F3D",
  "DXF",
  "SLDASM",
  "PPT",
  "PPTX",
  "PPSX",
  "POTX",
  ...SUPPORTED_IMAGE_FILE_TYPES,
];

export const SUPPORTED_QNA_FILE_TYPES = SUPPORTED_FILE_TYPES.concat([
  "CATPART",
  "X_T",
  "IGS",
  "DOC",
  "DOCX",
  "JFIF",
  "DWG",
  "CSV",
  "XLSX",
]);

export const SUPPORTED_NDA_FILE_TYPES = ["PDF", "DOC", "DOCX", "ZIP"];

export const SUPPORTED_PO_FILE_TYPES = ["PDF", "ZIP"];

export const EN_DATE_FORMAT = "YYYY/MM/DD";

export const FR_DATE_FORMAT = "DD/MM/YYYY";

export const TIME_FORMAT = "h:mm a";

export const BREAK_POINTS = {
  MOBILE: 768,
  TABLET: 1024,
};

export const ACTION_STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  FAILED: "failed",
};

export const PREMIUM_SUPPLIER_SUBSCRIPTION_COST = 200; //USD

export const PREMIUM_SUPPLIER_SUBSCRIPTION_LINK = "https://buy.stripe.com/aEU2bZdQxg7S3xS4gm";

export const DUMMY_ORDER_STATUS = "Dummy";

export const INTERNAL_NOTES_MODULE_TYPE = {
  PO: "PO",
  RFQ: "RFQ",
};
