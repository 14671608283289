import React from "react";
import { Chip, IconButton, Link, Theme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { FileData } from "./attach-file-list.types";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { CustomTypography } from "../custom-typography";
import { createStyles, makeStyles } from "@mui/styles";
import { formatFileSize, removeExtenstionFromFileName } from "@next/utils/fileUtils";
import { CircularProgressWithLabel } from "../circular-progress";

type StyleProps = {
  disablePaddingBottom?: boolean;
  disableFileItemMarginBottom?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    fileNameExtension: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: ({ disableFileItemMarginBottom }) =>
        theme.spacing(disableFileItemMarginBottom ? 0 : 2),
      paddingBottom: ({ disablePaddingBottom }) => theme.spacing(disablePaddingBottom ? 0 : 1.5),
    },
    fileContainer: {
      display: "flex",
      alignItems: "center",
      "& .MuiChip-icon": {
        marginLeft: "8px",
      },
    },
    fileLink: {
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    fileLinkDisabled: {
      color: theme.palette.text.disabled,
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.text.disabled,
      },
    },
    fileText: {
      color: theme.palette.primary.main,
      "&:hover": { textDecoration: "none", color: theme.palette.primary.main },
    },
    fileName: {
      marginLeft: theme.spacing(1),
      color: theme.palette.primary.main,
    },
    chipIcon: {
      width: 16,
      height: 16,
      marginRight: -2,
      color: theme.palette.text.primary,
    },
    deleteIcon: {
      color: theme.palette.common.black,
      "& svg": {
        fontSize: 18,
        position: "relative",
        top: 1,
      },
    },
    fileSize: {
      color: theme.palette.text.disabled,
      marginTop: 5,
      marginLeft: 8,
    },
    extension_chip: {
      backgroundColor: theme.palette.chip.dark,
    },
  })
);

export const AttachFileItem: React.FC<{
  file: FileData;
  onClickDelete?: (id: string) => void;
  onClickDownload?: (file: FileData) => void;
  disablePaddingBottom?: boolean;
  readOnly?: boolean;
  downloadable?: boolean;
  disableFileItemMarginBottom?: boolean;
}> = ({
  file,
  onClickDelete,
  onClickDownload,
  disablePaddingBottom,
  readOnly,
  downloadable,
  disableFileItemMarginBottom,
}) => {
  const classes = useStyles({
    disablePaddingBottom: !readOnly && (disablePaddingBottom || !!onClickDelete),
    disableFileItemMarginBottom,
  });
  return (
    <div className={classes.fileNameExtension}>
      <div className={classes.fileContainer}>
        {file?.file_extension && (
          <Chip
            label={file?.file_extension}
            size="small"
            icon={<InsertDriveFileOutlinedIcon className={classes.chipIcon} />}
            className={classes.extension_chip}
          />
        )}

        <CustomTypography
          tooltipTitle={removeExtenstionFromFileName(file?.file_name)}
          className={classes.fileName}
          variant="body2"
          maxWidth={250}
        >
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={downloadable && file?.file_url}
            className={
              !downloadable
                ? classes.fileLinkDisabled
                : file?.file_url
                  ? classes.fileLink
                  : classes.fileText
            }
            onClick={downloadable ? () => onClickDownload?.(file) : undefined}
          >
            {removeExtenstionFromFileName(file?.file_name)}
          </Link>
        </CustomTypography>
        <CustomTypography className={classes.fileSize} variant="body2">
          {file?.file_size && formatFileSize(file?.file_size, 2)}
        </CustomTypography>
      </div>
      <div className={classes.fileContainer}>
        {file?.file_uploaded && <CircularProgressWithLabel progress={file.file_uploaded} />}
        {file?.file_name && onClickDelete && !readOnly ? (
          <IconButton
            className={classes.deleteIcon}
            aria-label="delete"
            onClick={() => onClickDelete(file?.file_name)}
          >
            <ClearIcon />
          </IconButton>
        ) : null}
      </div>
    </div>
  );
};
