import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { FieldProps } from "formik";
import React, { useCallback } from "react";

export type FormikAutoCompleteOption = {
  label: string;
  value: number;
  type: string;
};

export type FormikOnChangeParams = {
  event: React.ChangeEvent<{}>;
  newOptions: any;
  reason: AutocompleteChangeReason;
  details: AutocompleteChangeDetails<any> | undefined;
};

type Props = {
  placeholder?: string;
  options: FormikAutoCompleteOption[];
  multiple?: boolean;
  label?: string;
  fieldSize?: "small" | "medium";
  enableCheckboxes?: boolean;
  enableGrouping?: boolean;
  customOnChange?: (params: FormikOnChangeParams) => void;
} & FieldProps &
  React.ComponentProps<typeof Autocomplete>;

const FormikAutoComplete: React.FC<Props> = ({
  form: { setFieldValue },
  field: { value, name },
  placeholder,
  options,
  multiple,
  label,
  fieldSize,
  enableCheckboxes,
  enableGrouping,
  customOnChange,
  ...props
}) => {
  const onChange = (
    event: React.ChangeEvent<{}>,
    newOptions: any,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<any> | undefined
  ) => {
    setFieldValue(name, newOptions);

    if (customOnChange) {
      customOnChange({
        event,
        newOptions,
        reason,
        details,
      });
    }
  };

  const renderOption = useCallback(
    (props, option, state) => (
      <Box display="flex" alignItems="center" {...props}>
        <Checkbox checked={state?.selected} style={{ marginRight: 8 }} color="primary" />

        <Box ml="4px">
          <Typography>{option?.label || option?.name || ""}</Typography>
        </Box>
      </Box>
    ),
    []
  );

  return (
    <Autocomplete
      popupIcon={
        <ExpandMore
          sx={{
            color: "black",
          }}
        />
      }
      {...props}
      options={options.sort((a, b) => {
        if (!a.type) {
          return 1;
        }
        if (!b.type) {
          return -1;
        }
        return -b.type.localeCompare(a.type);
      })}
      multiple={multiple}
      getOptionLabel={(option: any) => option?.label || option?.name || ""}
      {...(enableGrouping ? { groupBy: (option: any) => option?.type } : {})}
      isOptionEqualToValue={(item: any, current: any) => item.value === current.value}
      onChange={onChange}
      renderOption={enableCheckboxes ? renderOption : undefined}
      size="small"
      value={value || []}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          size={fieldSize}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
            "data-lpignore": true,
          }}
        />
      )}
    />
  );
};

export default FormikAutoComplete;
