import { Theme, alpha } from "@mui/material";
import { BREAK_POINTS } from "@next/constants";

import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bgRoot: {
      backgroundColor: theme.palette.grey[50],
      padding: theme.spacing(3),
      margin: theme.spacing(-6, -8),
      borderTop: `1px solid ${theme.palette.divider}`,
      minHeight: "calc(100vh - 80px)",
      maxWidth: "100vw",
    },
    root: {
      maxWidth: "min(100%, 892px)",
      margin: "auto",
    },
    contentBox: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(4),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
    },
    successTag: {
      color: () => theme.palette.success.dark,
      backgroundColor: () => alpha(theme.palette.success.main, 0.1),
      "& .MuiChip-icon": {
        color: () => theme.palette.success.dark,
        fontSize: 25,
        marginRight: 0,
        marginLeft: 16,
      },
    },
    ctaBar: {
      "& .MuiLink-underlineHover:hover": {
        textDecoration: "none",
      },
    },
    rteWrapper: {
      fontSize: theme.typography.body2.fontSize,
    },
    boldText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    bottomContainer: {
      display: "flex",
      marginTop: theme.spacing(4),
      [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
        flexDirection: "column",
      },
    },
    sectionsCotainer: {
      flex: 1,
      marginRight: theme.spacing(6),
      [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
        marginRight: 0,
      },
    },
    certificateLink: {
      color: theme.palette.primary.main,
      display: "inline-flex",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    capabilityContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    capabilityGroup: {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      width: "100%",
    },
    capabilitySubGroup: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      width: "100%",
    },
    capabilitiesContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 12,
    },
  })
);
