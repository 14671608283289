import React from "react";

// MaterialUI specific Import
import { Button, Grid } from "@mui/material";

// Library Components
import { SimpleTextField } from "components/utils/text-field/text-field.component";
import { FormHeader } from "components/utils/form-header/form-header";
import { FormikProps } from "formik";
import { TRecoverPasswordFormState } from "./recover-password-definitions";
import { useTranslation } from "react-i18next";

export const ForgotPasswordFormBase: React.FC<FormikProps<TRecoverPasswordFormState>> = ({
  values,
  errors,
  touched,
  handleSubmit,
  handleChange,
}) => {
  const { t } = useTranslation();
  // Deconstruct the values from formik
  const { email } = values;

  return (
    <form onSubmit={handleSubmit} className="c-recover-password-form">
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid container item xs={6} className="c-recover-password-form--header">
          <FormHeader
            title={t("auth:forgotPasswordForm:forgotPasswordTitle")}
            body={t("auth:forgotPasswordForm:forgotPasswordBody")}
          />
        </Grid>

        <Grid container item xs={6} className="c-recover-password-form--field">
          <SimpleTextField
            name="email"
            value={email}
            label={t("auth:login:email")}
            placeholder={t("auth:login:email")}
            helperText={touched.email ? errors.email : ""}
            error={touched.email && Boolean(errors.email)}
            onChange={handleChange}
          />
        </Grid>

        <Grid
          container
          item
          xs={9}
          className="c-recover-password-form--button"
          display="flex"
          justifyContent="center"
        >
          <Button variant="contained" color={"primary"} onClick={() => handleSubmit()} fullWidth>
            {t("auth:actionsMenu:forgotPasswordButton")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
