import { GridSelectionModel } from "@mui/x-data-grid-pro-v5";

const nullish = ["", undefined, null];
export const getRowIdToPk = (row: any) => (nullish.includes(row.id) ? row.pk : row.id);

export const getRowDataBySelectionModel = (ids: GridSelectionModel, rowsData: any[]) => {
  const selectedIDs = new Set(ids);

  return rowsData?.filter((row) => selectedIDs.has(row?.pk));
};

export const getIdsByData = (rowsData: any[], selectionKey: string = "pk") => {
  return rowsData?.map((item: any) => item?.[selectionKey]);
};
