import { startOfYear, subDays, subMonths, subYears } from "date-fns";
import { formatDate } from "helpers/formatDate";

export const calculateDateRange = (selection: string) => {
  const today = new Date();
  let start: Date;
  let end = today;

  switch (selection) {
    case "lastWeek":
      start = subDays(today, 7);
      break;
    case "lastMonth":
      start = subMonths(today, 1);
      break;
    case "lastYear":
      start = subYears(today, 1);
      break;
    case "yearToDate":
      start = startOfYear(today);
      break;
    case "allTime":
      return {
        start: null,
        end: null,
      };

    default:
      start = today;
  }

  return {
    start: formatDate(start, "YYYY-MM-DD"),
    end: formatDate(end, "YYYY-MM-DD"),
  };
};
