import { GridCellParams, MuiEvent } from "@mui/x-data-grid-pro-v5";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { modalsActions } from "@next/redux/modalsSlices";
import { openProfileInNewTab } from "@next/utils/browserUtils";
import { getRowIdToPk } from "@next/utils/dataGridUtils";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RemoveMySupplierConfirmationModal } from "../../modals/remove-my-supplier-confirmation-modal";
import { WorkspaceModalTypes } from "../../modals/types";
import {
  selectFavoriteSupplierGroupsLoading,
  selectFavoriteSuppliersCount,
  selectFavoriteSuppliersLoading,
} from "../../redux/selectors";
import { SuppliersTable } from "../suppliers-table/suppliers-table-v5";
import { MySupplierTableEditMenu } from "./my-suppliers-table-edit-menu";
import { SuppliersField } from "../suppliers-table/suppliers-table-v5.types";
import { useMySuppliersTableData } from "./my-suppliers-table.hooks";
import { generatePaginationAndSearchQuery } from "@next/utils/paginationUtils";
import { PAGINATION_PAGE_SIZE } from "@next/constants";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";

type Props = {
  selectedGroup: number;
  selectedASL: boolean | null;
  searchTerm?: string;
  NoRowsOverlay?: React.JSXElementConstructor<any>;
};

export const MySuppliersTable: React.FC<Props> = ({
  selectedGroup,
  selectedASL,
  searchTerm,
  NoRowsOverlay,
}) => {
  const axyaTracking = useAxyaTracking();
  const dispatch = useDispatch();
  const favoriteSuppliersLoading = useSelector(selectFavoriteSuppliersLoading);
  const favoriteSuppliersCount = useSelector(selectFavoriteSuppliersCount);
  const favoriteSupplierGroupsLoading = useSelector(selectFavoriteSupplierGroupsLoading);
  const hasAdminRole = useHasCompanyRole(["admin"]);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | Element>(null);
  const [menuActiveData, setMenuActiveData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);

  const [searchKey, searchValue] = useMemo(() => {
    const keys = [];
    const values: (number | string)[] = [];
    if (selectedGroup) {
      keys.push("group");
      values.push(selectedGroup);
    }
    if (selectedASL !== null) {
      keys.push("isASL");
      values.push(selectedASL.toString());
    }
    if (searchTerm) {
      keys.push("name");
      values.push(searchTerm);
    }
    return [keys, values];
  }, [selectedGroup, selectedASL, searchTerm]);

  const { gridData } = useMySuppliersTableData(
    `${generatePaginationAndSearchQuery({
      currentPage,
      searchKey,
      searchValue,
    })}`
  );

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent<Element, MouseEvent>>
  ) => {
    if (params?.field === SuppliersField.EDIT_BUTTON) {
      setMenuAnchorEl(event.currentTarget);
      setMenuActiveData(params.row);
    }
  };

  const onClickRemoveMySupplier = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.REMOVE_MY_SUPPLIER_CONFIRMATION_MODAL,
        data: menuActiveData?.pk,
      })
    );
    setMenuAnchorEl(null);
    setMenuActiveData(null);
  };

  const onClickOpenFullProfile = () => {
    openProfileInNewTab(menuActiveData?.name, menuActiveData?.uuid);
    setMenuAnchorEl(null);
    axyaTracking({
      app: "axya-app",
      scout_category: "axya-supplier-profile",
      scout_feature_name: "supplier-profile-redirection-from-my-supplier-list",
    });
  };

  return (
    <>
      <SuppliersTable
        gridData={gridData}
        getRowId={getRowIdToPk}
        loading={favoriteSuppliersLoading || favoriteSupplierGroupsLoading}
        onCellClick={onCellClick}
        pagination
        paginationMode="server"
        pageSize={PAGINATION_PAGE_SIZE}
        rowCount={favoriteSuppliersCount}
        onPageChange={(newPage) => setCurrentPage(newPage + 1)}
        hideFooter={false}
        components={{ NoRowsOverlay }}
      />

      {/* This menu appears only 
          when SuppliersField.EDIT_BUTTON is in columns. 
      */}
      <MySupplierTableEditMenu
        anchorEl={menuAnchorEl}
        setAnchorEl={setMenuAnchorEl}
        onClickRemoveMySupplier={onClickRemoveMySupplier}
        onClickOpenFullProfile={onClickOpenFullProfile}
        hasAdminRole={hasAdminRole}
      />

      <RemoveMySupplierConfirmationModal />
    </>
  );
};
