import React, { useState } from "react";
import { Box, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createStyles, makeStyles } from "@mui/styles";
import folderIcon from "@next/assets/folderIcon.png";
import MultipleDNDFileUpload from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload";
import JSZip from "jszip";
import { modalsActions } from "@next/redux/modalsSlices";
import { selectOrder, selectOrderAdditionalFiles } from "@next/modules/orders/redux/selectors";
import { ordersActions } from "@next/modules/orders/redux";
import { SharedModalTypes } from "@next/modals/types";
import { fileStateToZip } from "@next/modals/components/bulk-file-import/bulk-file-import-upload-files.utils";
import { BulkFileImportFor } from "@next/modals/shared-bulk-files-import-modal";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { store } from "store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileUploadContainer: {
      width: "100%",
    },
  })
);

export const OrderDetailDraftBulkFilesImport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const orderDetail = useSelector(selectOrder);

  const [bulkFiles, setBulkFiles] = useState<any[]>([]);

  const filesZip = new JSZip();

  const onAttachmentsChange = (files: FileData[], filePkToDelete?: number) => {
    if (files.length === 0 && filePkToDelete <= 0) return;
    const additional_files = selectOrderAdditionalFiles(store.getState());
    if (filePkToDelete > 0) {
      dispatch(
        ordersActions.updateOrderRequest({
          pk: orderDetail?.pk,
          additional_files: additional_files?.filter(
            (attachment) => attachment.pk !== filePkToDelete
          ),
        })
      );
    } else {
      const updatedAttachments = [
        ...additional_files,
        ...files.filter(
          (file) => !additional_files?.some((attachment) => attachment.pk === file.pk)
        ),
      ];
      dispatch(
        ordersActions.updateOrderRequest({
          pk: orderDetail?.pk,
          additional_files: updatedAttachments,
        })
      );
    }
  };

  const handleBulkFilesImport = (bulkFiles: any) => {
    if (!bulkFiles || bulkFiles.length === 0) return;
    const files = bulkFiles.map((file) => file?.file);
    fileStateToZip(filesZip, files, (zippedFile: any) => {
      if (orderDetail?.pk) {
        dispatch(ordersActions.resetBomFilesUploadReport());
        dispatch(
          ordersActions.uploadBulkFilesRequest({
            file: zippedFile,
            orderPk: orderDetail?.pk,
            attach_unmatched_files: true,
          })
        );

        dispatch(
          modalsActions.showModal({
            key: SharedModalTypes.BULK_FILES_IMPORT_MODAL,
            data: {
              filesCount: bulkFiles.length,
              bulkFileImportFor: BulkFileImportFor.ORDER,
              onAttachmentsChange,
            },
          })
        );

        setBulkFiles([]);
      }
    });
  };

  return (
    <Box className={classes.fileUploadContainer}>
      <MultipleDNDFileUpload
        filesState={bulkFiles}
        setFilesState={(files) => handleBulkFilesImport(files)}
        compactMode={true}
        disableDndMargin={true}
        showUploadList={false}
        showSecureInfo={false}
        centerInnnerContent={false}
        customText={t("project:bulkFileImport:bulkFilesImportDescription")}
        customStartIcon={folderIcon}
      />
    </Box>
  );
};
