/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridColumns } from "@mui/x-data-grid-pro-v5";
import { selectContracts, selectContractsLoading } from "../../redux/selectors";
import { getContractsTableColumns } from "./contracts-table.columns";
import { workspaceNextActions } from "../../redux";
import { useGridData } from "@next/hooks/useGridData-v5";
import i18n from "assets/configi18n/i18n";

export type GridStatePartData = {
  rows: Partial<any>[];
  columns: GridColumns;
};

type Props = {
  paginatedQuery: string;
};

export const useContractsTableData = ({ paginatedQuery }: Props) => {
  const dispatch = useDispatch();

  const contracts = useSelector(selectContracts);
  const contractsLoading = useSelector(selectContractsLoading);
  const contractsColumns = useMemo(
    () => getContractsTableColumns(),
    [contractsLoading, i18n?.language]
  );

  const { gridData, setGridData } = useGridData({
    rows: contracts,
    columns: contractsColumns as GridColumns,
    loading: contractsLoading,
  });

  useEffect(() => {
    dispatch(
      workspaceNextActions.fetchContractsRequest({
        query: paginatedQuery,
      })
    );
  }, [paginatedQuery]);

  return {
    gridData,
    setGridData,
  };
};
