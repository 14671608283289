import React from "react";
import { Box, Divider } from "@mui/material";
import { selectLineItemsChangesFromRevisionProposal } from "@next/modules/orders/redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CustomButton from "@next/components/custom-button";
import { LineItemType, ordersActions } from "@next/modules/orders/redux";
import { OrderMarkAsShippedModal } from "@next/modules/orders/modals/order-mark-as-shipped-modal";
import { PreviewLineItemChanges } from "../../preview-line-item-changes/preview-line-item-changes";

type Props = {
  isPortal?: boolean;
};

export const ActionsPanelReviewChanges: React.FC<Props> = ({ isPortal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const lineItemsChanges = useSelector(selectLineItemsChangesFromRevisionProposal);

  const handleMarkAllAs = (decision: "approved" | "rejected") => {
    dispatch(ordersActions.markAllLineItemChangeAs(decision));
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box display={"flex"} alignItems={"center"} gap={"16px"} p={"16px"}>
        <CustomButton
          variant="outlined"
          color="secondary"
          sx={{ borderRadius: "48px", fontWeight: "400" }}
          onClick={() => handleMarkAllAs("approved")}
        >
          {t("orders:orderDetail:actionPanel:review:markAllAsApproved")}
        </CustomButton>
        <CustomButton
          variant="outlined"
          color="secondary"
          sx={{ borderRadius: "48px", fontWeight: "400" }}
          onClick={() => handleMarkAllAs("rejected")}
        >
          {t("orders:orderDetail:actionPanel:review:markAllAsDenied")}
        </CustomButton>
      </Box>
      <Divider />
      <PreviewLineItemChanges
        lineItemsChanges={lineItemsChanges}
        onMakeDecision={(
          id: number,
          type: LineItemType,
          decision: "approved" | "rejected" | null
        ) => {
          dispatch(ordersActions.approveOrRejectLineItemChange({ id, type, decision }));
        }}
      />
      <OrderMarkAsShippedModal />
    </Box>
  );
};
