import { RootState } from "store";

export const selectOrders = (state: RootState) => state.ordersNext.orders;
export const selectOrdersLoading = (state: RootState) => state.ordersNext.ordersLoading;
export const selectOrdersCounts = (state: RootState) => state.ordersNext.ordersCounts;
export const selectOrdersLastQuery = (state: RootState) => state.ordersNext.ordersLastQuery;
export const selectOrdersStatusCounts = (state: RootState) => state.ordersNext.ordersStatusCounts;
export const selectOrdersSuppliers = (state: RootState) => state.ordersNext.ordersSuppliers;

export const selectOrder = (state: RootState) => state.ordersNext.order;
export const selectOrderLoading = (state: RootState) => state.ordersNext.orderLoading;

export const selectSupplierLineItems = (state: RootState) => state.ordersNext.supplierLineItems;
export const selectSupplierLineItemsLoading = (state: RootState) =>
  state.ordersNext.supplierLineItemsLoading;
export const selectSupplierLineItemsCounts = (state: RootState) =>
  state.ordersNext.supplierLineItemsCounts;
export const selectSupplierLineItemsStatusCounts = (state: RootState) =>
  state.ordersNext.supplierLineItemsStatusCounts;

export const selectCreateOrderLoading = (state: RootState) => state.ordersNext.createOrderLoading;

export const selectUpdateOrderLoading = (state: RootState) => state.ordersNext.updateOrderLoading;

export const selectUploadOrderFileLoading = (state: RootState) =>
  state.ordersNext.uploadOrderFileLoading;

export const selectDeleteOrderFileLoading = (state: RootState) =>
  state.ordersNext.deleteOrderFileLoading;

export const selectPortalOrder = (state: RootState) => state.ordersNext.portalOrder;
export const selectPortalOrderLoading = (state: RootState) => state.ordersNext.portalOrderLoading;

export const selectUpdatePortalOrderLoading = (state: RootState) =>
  state.ordersNext.updatePortalOrderLoading;

export const selectOrderByIsPortal = (isPortal: boolean) => (state: RootState) =>
  isPortal ? state.ordersNext.portalOrder : state.ordersNext.order;

export const selectUpdatePortalOrderAcknowledgementLoading = (state: RootState) =>
  state.ordersNext.updatePortalOrderAcknowledgementLoading;

export const selectUpdatePortalOrderDeliveryDateLoading = (state: RootState) =>
  state.ordersNext.updatePortalOrderLineItemsLoading;

export const selectUpdatePortalOrderShippingConfirmationLoading = (state: RootState) =>
  state.ordersNext.updatePortalOrderShippingConfirmationLoading;

export const selectAddOrderPartsLoading = (state: RootState) =>
  state.ordersNext.addOrderPartsLoading;

export const selectUpdateOrderTargetProfilesLoading = (state: RootState) =>
  state.ordersNext.updateOrderTargetedProfilesLoading;

export const selectNotSavedChangesInLineItems = (isPortal?: boolean) => (state: RootState) =>
  isPortal
    ? state.ordersNext.portalOrder?.line_items?.filter((lineItem) =>
        lineItem?.previous_line_item ? Object.keys(lineItem.previous_line_item).length > 0 : false
      )
    : state.ordersNext.order?.line_items?.filter((lineItem) =>
        lineItem?.previous_line_item ? Object.keys(lineItem.previous_line_item).length > 0 : false
      );

export const selectLineItemsTableSelectionModel = (state: RootState) =>
  state.ordersNext.lineItemsTableSelectionModel;

export const selectRevisionProposals = (state: RootState) =>
  state.ordersNext.order?.revision_proposals;

export const selectRevisionProposalsLoading = (state: RootState) =>
  state.ordersNext.revisionProposalLoading;

export const selectLineItemsChangesFromRevisionProposal = (state: RootState) =>
  state.ordersNext.order?.line_items_changes_proposal;

export const selectAllLineItemsChangesHaveDecision = (state: RootState): boolean => {
  return state.ordersNext.order?.line_items_changes_proposal?.every((lineItem) => {
    if (lineItem.decision) {
      return Object.values(lineItem.decision).every((value) => value !== null);
    }
    return false;
  });
};

export const selectReviewRevisionProposalLoading = (state: RootState) =>
  state.ordersNext.reviewRevisionProposalLoading;

export const selectUpdateOrderLineItemsLoading = (state: RootState) =>
  state.ordersNext.updateOrderLineItemsLoading;

export const selectOrderDetailRightTabOpen = (state: RootState) =>
  state.ordersNext.orderDetailRightTabOpen;

export const selectOrderActionPanelType = (state: RootState) =>
  state.ordersNext.orderActionPanelType;

export const selectUploadOrderBulkFilesLoading = (state: RootState) =>
  state.ordersNext.uploadBulkFilesLoading;

export const selectFetchOrderBulkFilesUploadReportLoading = (state: RootState) =>
  state.ordersNext.fetchBulkFilesUploadReportLoading;

export const selectOrderBulkFilesUploadReport = (state: RootState) =>
  state.ordersNext.bulkFilesUploadReport;

export const selectOrderParts = (state: RootState) =>
  state.ordersNext.order?.line_items.map((lineItem) => lineItem.part);

export const selectOrderPartByPk = (pk: number) => (state: RootState) =>
  state.ordersNext.order?.line_items.find((lineItem) => lineItem.part.pk === pk).part;

export const selectOrderAdditionalFiles = (state: RootState) =>
  state.ordersNext.order?.additional_files;

export const ordersSelectors = {
  selectOrders,
  selectOrdersLoading,
  selectOrdersCounts,
  selectOrdersLastQuery,
  selectOrdersSuppliers,
  selectOrder,
  selectOrderLoading,
  selectUpdateOrderLoading,
  selectUploadOrderFileLoading,
  selectDeleteOrderFileLoading,
  selectPortalOrder,
  selectPortalOrderLoading,
  selectUpdatePortalOrderLoading,
  selectCreateOrderLoading,
  selectOrdersStatusCounts,
  selectNotSavedChangesInLineItems,
  selectUpdatePortalOrderAcknowledgementLoading,
  selectUpdatePortalOrderDeliveryDateLoading,
  selectUpdatePortalOrderShippingConfirmationLoading,
  selectAddOrderPartsLoading,
  selectUpdateOrderTargetProfilesLoading,
  selectLineItemsTableSelectionModel,
  selectRevisionProposals,
  selectRevisionProposalsLoading,
  selectLineItemsChangesFromRevisionProposal,
  selectAllLineItemsChangesHaveDecision,
  selectReviewRevisionProposalLoading,
  selectUpdateOrderLineItemsLoading,
  selectOrderDetailRightTabOpen,
  selectOrderActionPanelType,
  selectUploadOrderBulkFilesLoading,
  selectFetchOrderBulkFilesUploadReportLoading,
  selectOrderBulkFilesUploadReport,
  selectOrderAdditionalFiles,
};
