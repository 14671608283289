import { RootState } from "store";

export const selectCompanyCapabilitiesTypes = (state: RootState) =>
  state.companyCapabilitiesNext.companyCapabilitesTypes;

export const selectCompanyCapabilitiesTypesLoading = (state: RootState) =>
  state.companyCapabilitiesNext.companyCapabilitesTypesLoading;

export const selectPatchCompanyCapabilitiesLoading = (state: RootState) =>
  state.companyCapabilitiesNext.patchCompanyCapabilitiesLoading;

export const selectCompanyCapabilities = (state: RootState) =>
  state.companyCapabilitiesNext.companyCapabilities;

export const companyCapabilitiesSelectors = {
  selectCompanyCapabilitiesTypes,
  selectCompanyCapabilitiesTypesLoading,
  selectPatchCompanyCapabilitiesLoading,
  selectCompanyCapabilities,
};
