import { Box, Divider, Typography } from "@mui/material";
import { Form, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "@next/components/custom-button/custom-button";
import { OrderDetailMarkAsShippedFormState } from "./order-detail-mark-as-shipped-form.definitions";
import { MUIDatePicker } from "@next/components/mui-date-picker";
import { GenericTextInput } from "components/utils/input/text";
import { formatDate } from "helpers/formatDate";
import { GridSelectionModel } from "@mui/x-data-grid-pro";
import { PreviewLineItemStatusChange } from "./preview-item-shipment-status";
import { selectOrderByIsPortal } from "@next/modules/orders/redux/selectors";
import { useSelector } from "react-redux";
import { OrderLineItemChanges } from "@next/modules/orders/redux";

export const OrderDetailMarkAsShippedFormBase: React.FC<
  FormikProps<OrderDetailMarkAsShippedFormState> & {
    onClose: () => void;
    partsSelectionModel?: GridSelectionModel;
    isPortal?: boolean;
    submitLoading?: boolean;
  }
> = ({
  handleSubmit,
  values,
  onClose,
  setFieldValue,
  touched,
  errors,
  partsSelectionModel,
  isPortal,
  submitLoading,
}) => {
  const { t } = useTranslation();
  const orderDetail = useSelector(selectOrderByIsPortal(isPortal));
  const lineItemsSelected = orderDetail?.line_items.filter((item) =>
    partsSelectionModel.includes(item.id)
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Box display={"flex"} flexDirection={"column"} gap={"24px"} p={"24px"}>
        <Typography variant="body1">
          {t("orders:orderDetail:confirmShippingModal:description")}
        </Typography>

        <MUIDatePicker
          name="shippedDate"
          label={t("orders:orderDetail:confirmShippingModal:dateInputLabel")}
          value={formatDate(values.shippedDate, "YYYY-MM-DD")}
          onChange={(value) => {
            setFieldValue("shippedDate", value || "");
          }}
          error={touched["shippedDate"] && !!errors["shippedDate"]}
          helperText={
            touched["shippedDate"] && !!errors["shippedDate"]
              ? (errors["shippedDate"] as string)
              : ""
          }
          variant="inline"
          size="medium"
          disablePast={false}
        />

        <GenericTextInput
          name="shippingNote"
          label={t("orders:orderDetail:confirmShippingModal:shippingNoteInputLabel")}
        />
      </Box>
      <Divider />

      <PreviewLineItemStatusChange lineItemChanges={lineItemsSelected as OrderLineItemChanges[]} />
      <Divider />
      <Box display="flex" justifyContent="flex-end" p="24px">
        <Box mr="10px">
          <CustomButton variant="outlined" onClick={onClose}>
            {t("common:cancel")}
          </CustomButton>
        </Box>

        <CustomButton type="submit" variant="contained" color="primary" loading={submitLoading}>
          {t("orders:orderDetail:confirmShippingModal:buttonText")}
        </CustomButton>
      </Box>
    </Form>
  );
};
