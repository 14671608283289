import { Box, CircularProgress, IconButton, Link, Theme, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { SellerDashboardBuyerProfilePastJobsTable } from "./seller-dashboard-buyer-profile-past-jobs-table/seller-dashboard-buyer-profile-past-jobs-table";
import { useSellerDashboardBuyerProfileData } from "./seller-dashboard-buyer-profile.hooks";
import { buildStringWithComma } from "@next/utils/stringUtils";
import { useTranslation } from "react-i18next";
import CompanyAvatar from "@next/modules/profile/components/company-profile-full/company-avatar/company-avatar";
import { CustomTypography } from "@next/components/custom-typography";
import { formatDate } from "helpers/formatDate";
import ProfileConnectButton, {
  ProfileConnectionState,
} from "@next/modules/marketplace/components/profile-connect-button/profile-connect-button";
import { getCompany } from "services/profile/profile.selectors";
import { checkIsSellerCompany } from "@next/utils/checkCompanyUtils";
import { CompanyTypeNext } from "@next/redux/types";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      padding: "32px",
    },
    loader: {
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      display: "grid",
      placeItems: "center",
    },
    logoAvatar: {
      width: "92px",
      height: "92px",
      borderRadius: "16px",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(2),
      color: theme.palette.grey[500],
    },
    itemText: {
      marginLeft: "10px",
    },
    listItem: {
      display: "flex",
      paddingBottom: "8px",
      paddingTop: "8px",
    },
    listItemLeft: {
      display: "flex",
      alignItems: "center",
      flex: 0.5,
    },
    listItemRight: {
      display: "flex",
      alignItems: "center",
      flex: 0.5,
    },
  })
);

type Props = {
  handleClose?: () => void;
  buyerProfileId: number;
};

export const SellerDashboardBuyerProfile: React.FC<Props> = ({ handleClose, buyerProfileId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { buyerProfile, buyerProfileLoading } = useSellerDashboardBuyerProfileData(buyerProfileId);

  const company = useSelector(getCompany);
  const isSupplier = checkIsSellerCompany(company?.type as CompanyTypeNext);

  if (!buyerProfile || buyerProfileLoading) {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <Box display="flex">
        <CompanyAvatar avatarSrc={buyerProfile?.picture} showName={false} />

        {handleClose && (
          <IconButton className={classes.closeButton} onClick={handleClose} size="large">
            <CloseIcon color="secondary" />
          </IconButton>
        )}
      </Box>

      <Box mt="16px">
        <CustomTypography variant="h4">{buyerProfile?.name}</CustomTypography>
      </Box>

      {isSupplier && (
        <Box display="flex" alignItems="center" mt="16px">
          <ProfileConnectButton
            buyerProfile={{
              id: buyerProfile?.id,
              name: buyerProfile?.name,
              picture: buyerProfile?.picture,
            }}
            state={buyerProfile?.connection_status}
            connectionId={buyerProfile?.connection?.id}
          />
          <Box ml="12px">
            <Typography color="textSecondary" variant="body2">
              {buyerProfile.connection_status === ProfileConnectionState.PENDING
                ? t("profile:buyerProfile:lastMessageSentOn", {
                    date: formatDate(buyerProfile.connection?.created_at, "LLL"),
                  })
                : buyerProfile.connection_status === ProfileConnectionState.NOT_CONNECTED
                  ? t("profile:buyerProfile:proposeYourServiceTo", {
                      companyName: buyerProfile?.name,
                    })
                  : null}
            </Typography>
          </Box>
        </Box>
      )}

      <Box display="flex" alignItems="center" flexWrap="wrap" gap="20px" mt="16px">
        <Box display="flex" alignItems="center">
          <LocationOnOutlinedIcon />

          <Typography variant="body2" className={classes.itemText}>
            {buildStringWithComma([
              buyerProfile.address?.street,
              buyerProfile.address?.province,
              buyerProfile.address?.city,
              buyerProfile.address?.country,
              buyerProfile.address?.postal_code,
            ])}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <CallOutlinedIcon />

          <Typography variant="body2" className={classes.itemText}>
            {buyerProfile?.phone_number}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <LanguageOutlinedIcon />

          <Typography variant="body2" className={classes.itemText}>
            <Link href={buyerProfile?.website} target="_blank" data-pid="linkVisitWebsite">
              {t("dashboard:visitWebsite")}
            </Link>
          </Typography>
        </Box>
      </Box>

      {buyerProfile?.rich_description ? (
        <Box mt="48px">
          <Typography variant="h6">Description</Typography>

          <Box mt="16px">
            <div
              dangerouslySetInnerHTML={{
                __html: buyerProfile?.rich_description,
              }}
            />
          </Box>
        </Box>
      ) : null}

      <Box mt="48px">
        <Typography variant="h6">{t("dashboard:activity")}</Typography>

        <Box className={classes.listItem}>
          <Box className={classes.listItemLeft}>
            <Typography variant="body2" color="textSecondary">
              {t("dashboard:openRequestsCreated")}
            </Typography>
          </Box>

          <Box className={classes.listItemRight}>
            <Typography variant="body2">{buyerProfile?.marketplace_rfq_count}</Typography>
          </Box>
        </Box>

        <Box className={classes.listItem}>
          <Box className={classes.listItemLeft}>
            <Typography variant="body2" color="textSecondary">
              {t("dashboard:privateRequestsCreated")}
            </Typography>
          </Box>

          <Box className={classes.listItemRight}>
            <Typography variant="body2">{buyerProfile?.private_rfq_count}</Typography>
          </Box>
        </Box>

        <Box className={classes.listItem}>
          <Box className={classes.listItemLeft}>
            <Typography variant="body2" color="textSecondary">
              {t("dashboard:ordersCreated")}
            </Typography>
          </Box>

          <Box className={classes.listItemRight}>
            <Typography variant="body2">{buyerProfile?.po_count}</Typography>
          </Box>
        </Box>
      </Box>

      <Box mt="48px">
        <Typography variant="h6"> {t("dashboard:pastJobsOnAxya")}</Typography>

        <Box mt="16px">
          <SellerDashboardBuyerProfilePastJobsTable />
        </Box>
      </Box>
    </div>
  );
};
