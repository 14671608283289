import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const NoRowsOverlayBase = () => {
  const { t } = useTranslation();

  return (
    <Box position="absolute" left="50%" top="50%">
      {t("common:noDataFound")}
    </Box>
  );
};
