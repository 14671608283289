import { requestDemoConstants } from "./request-demo.constants";

const initialState = {
  isRequestDemoLoading: false,
  isRequestDemoSuccess: false,
};

export function requestDemo(state = initialState, action) {
  switch (action.type) {
    case requestDemoConstants.BOOK_DEMO_REQUEST:
      return {
        ...state,
        isRequestDemoLoading: true,
        isRequestDemoSuccess: false,
      };

    case requestDemoConstants.BOOK_DEMO_SUCCESS:
      return {
        ...state,
        isRequestDemoLoading: false,
        isRequestDemoSuccess: true,
      };

    case requestDemoConstants.BOOK_DEMO_FAILURE:
      return {
        ...state,
        isRequestDemoLoading: false,
        isRequestDemoSuccess: false,
      };

    default:
      return state;
  }
}
