import { IconButton, Tooltip } from "@mui/material";
import { SharedModalTypes } from "@next/modals/types";
import { modalsActions } from "@next/redux/modalsSlices";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

export const InviteUserActionItem = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClickInviteUser = () => {
    dispatch(
      modalsActions.showModal({
        key: SharedModalTypes.INVITE_COMPANY_USER_FORM,
      })
    );
  };

  return (
    <Tooltip title={t("appTour:inviteUser")}>
      <IconButton
        data-tut="reactour__inviteUser"
        data-pid="btnInviteUser"
        onClick={onClickInviteUser}
        size="large"
      >
        <PersonAddIcon style={{ color: "white" }} />
      </IconButton>
    </Tooltip>
  );
};
