import { Box, Chip, IconButton, Theme } from "@mui/material";
import { CircularAvatarsList } from "@next/components/circular-avatars-list";
import { AvatarSizes } from "@next/components/generic-avatar/generic-avatar";
import { GenericUser } from "@next/modules/profile/redux";
import { modalsActions } from "@next/redux/modalsSlices";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FavoriteSupplier } from "../../redux";
import { RFQSupplierType } from "../rfq-creation-form/rfq-creation.form.types";
import {
  filteredUsersAccordingToDistribution,
  generateImageTooltipText,
} from "./supplier-contacts-list.utils";
import { RootState } from "store";
import { SharedModalTypes } from "@next/modals/types";
import { createStyles, makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { DistributionForSupplierContacts } from "@next/modals/shared-supplier-contact-lists-modal";

type StyleProps = {
  size?: keyof typeof AvatarSizes;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  const defaultSize = "medium";
  const btnSize = ({ size }: StyleProps) => AvatarSizes[size || defaultSize];
  const iconSize = ({ size }: StyleProps) => {
    const ratio = 1.5 / btnSize({ size: defaultSize });
    return `${btnSize({ size }) * ratio}rem`;
  };

  return createStyles({
    addBtn: {
      width: btnSize,
      height: btnSize,
      minWidth: "unset",
      borderRadius: "16px",
      marginLeft: theme.spacing(1),
    },
    addIcon: {
      fontSize: iconSize,
      color: "black",
    },
  });
});

export interface SupplierContactsListProps {
  supplier?: Partial<FavoriteSupplier | RFQSupplierType> & { id?: number };
  users?: GenericUser[];
  editMode?: boolean;
  size?: keyof typeof AvatarSizes;
  includeEmailInTooltip?: boolean;
  views?: { [key: string]: string };
  showVisibilityBadge?: boolean;
  onValidate?: (targeted_profiles: GenericUser[]) => void;
  loadingSelector?: (state: RootState) => boolean;
  overlapping?: boolean;
  distributionForSupplierContacts?: DistributionForSupplierContacts;
}

const SupplierContactsList: React.FC<SupplierContactsListProps> = ({
  supplier,
  users,
  editMode = false,
  size,
  includeEmailInTooltip = false,
  views = {},
  showVisibilityBadge,
  overlapping,
  onValidate,
  loadingSelector,
  distributionForSupplierContacts,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles({ size });
  const { t } = useTranslation();
  const filteredUsers = useMemo(
    () =>
      filteredUsersAccordingToDistribution(
        users || [],
        distributionForSupplierContacts || DistributionForSupplierContacts.ALL
      ),
    [users, distributionForSupplierContacts]
  );

  const onAdd = useCallback(() => {
    dispatch(
      modalsActions.showModal({
        key: SharedModalTypes.SUPPLIER_CONTACTS_LIST_MODAL,
        data: {
          supplier,
          users: filteredUsers,
          onValidate,
          loadingSelector,
          distributionForSupplierContacts,
        },
      })
    );
  }, [supplier, filteredUsers, onValidate, loadingSelector]);
  const images = useMemo(
    () =>
      filteredUsers?.map((user) => ({
        url: user.picture,
        name: user.full_name || user.email,
        tooltip: generateImageTooltipText(
          user,
          includeEmailInTooltip,
          views && views[`${user.email}`]
        ),
        email: user.email,
      })) || [],
    [filteredUsers, views]
  );

  return users?.length ? (
    <Box display="flex" alignItems="center">
      <CircularAvatarsList
        maxToShow={3}
        images={images}
        remainingTooltip
        overlapping={overlapping}
        size={size}
        views={views}
        showVisibilityBadge={showVisibilityBadge}
      />

      {editMode ? (
        <IconButton onClick={onAdd} className={classes.addBtn}>
          <EditIcon className={classes.addIcon} />
        </IconButton>
      ) : null}
    </Box>
  ) : editMode ? (
    <Chip
      icon={<AddIcon className={classes.addIcon} />}
      label={t("workspaceNext:supplierContactsList:addAContact")}
      onClick={onAdd}
      variant="outlined"
      size="medium"
      color="default"
    />
  ) : (
    <Chip label="No point of contact" size="small" />
  );
};

export default SupplierContactsList;
