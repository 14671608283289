import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { getFileExtension } from "../../utils/getFileExtension";
import { formatFileSize } from "@next/utils/fileUtils";
import { EvidenceFileObj, FileObj } from "../../redux";
import { fileDownloadFromUrl } from "@next/utils/downloadUtils";
import * as S from "./form-attached-file-box.styled";

type Props = {
  file: File | FileObj | EvidenceFileObj;
  handleClickRemove: () => void;
  isFormDisabled?: boolean;
};

export const FormAttachedFileBox: React.FC<Props> = ({
  file,
  handleClickRemove,
  isFormDisabled,
}) => {
  const isFileSavedBefore = (file as EvidenceFileObj)?.file_url ? true : false;
  const cursor = isFileSavedBefore ? "pointer" : "default";

  const downloadFile = (file: EvidenceFileObj) => {
    fileDownloadFromUrl(file.file_url, file.file_name, file.file_type);
  };

  const onClickFile = () => {
    if (isFileSavedBefore) {
      downloadFile(file as EvidenceFileObj);
    }
  };
  return (
    <S.RootBox>
      <S.StyledAttachedBox>
        <S.StyledFileBox>
          <S.StyledFileTypography tooltipTitle={null} variant="caption">
            {file instanceof File ? getFileExtension(file.name) : file?.file_type}
          </S.StyledFileTypography>
        </S.StyledFileBox>

        <S.StyledFileNameTypography
          tooltipTitle={null}
          variant="body2"
          sx={{ cursor: cursor }}
          onClick={onClickFile}
        >
          {file instanceof File ? file.name : file.file_name}
        </S.StyledFileNameTypography>

        <S.StyledAttachmentTypography tooltipTitle={null} variant="body2">
          {file instanceof File ? formatFileSize(file.size, 2) : formatFileSize(file.file_size, 2)}
        </S.StyledAttachmentTypography>
      </S.StyledAttachedBox>

      {!isFormDisabled ? (
        <S.StyledIconButton onClick={handleClickRemove}>
          <CloseIcon />
        </S.StyledIconButton>
      ) : null}
    </S.RootBox>
  );
};
