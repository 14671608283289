import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "../redux/modalsSlices";
import { SharedModalTypes } from "./types";
import Modal from "@next/components/custom-modal";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getPremiumSupplierSubscriptionCost } from "@next/utils/constantUtils";
import { PREMIUM_SUPPLIER_SUBSCRIPTION_LINK } from "@next/constants";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: 700,
    },
    dialogContent: {
      overflowX: "hidden",
    },
    premiumCard: {
      display: "flex",
      flexDirection: "column",
      gridGap: "16px",
      padding: "16px",
      backgroundColor: theme.palette.grey[50],
      borderRadius: "12px",
    },
    checkIcon: {
      color: theme.palette.success.main,
    },
    textDisabled: {
      color: theme.palette.text.disabled,
    },
  })
);

export const SharedUpgradeToPremiumModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(SharedModalTypes.UPGRADE_TO_PREMIUM_MODAL));
  const classes = useStyles();

  if (!modal) return null;

  return (
    <Modal
      handleModalClose={() =>
        dispatch(modalsActions.closeModal(SharedModalTypes.UPGRADE_TO_PREMIUM_MODAL))
      }
      modalState={{
        modalHeader: t("upgradeModal:title"),
        modalBody: <ModalBody />,
        dialogContentClassName: classes.dialogContent,
      }}
      dialogProps={{ fullWidth: true, classes: { paper: classes.paper } }}
      isModalOpen={true}
    />
  );
};

const ModalBody = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const features = [
    t("upgradeModal:exclusiveAccessToRequests"),
    t("upgradeModal:getRecommendedBuyers"),
    t("upgradeModal:profileAnalytics"),
    t("upgradeModal:connectOnAxyaNetwork"),
  ];

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box className={classes.premiumCard}>
        <Typography variant="body1" color="textSecondary">
          {t("upgradeModal:premiumMembership")}
        </Typography>
        <Box display="flex">
          <Typography variant="h5">{getPremiumSupplierSubscriptionCost()}/</Typography>
          <Typography variant="h5" className={classes.textDisabled}>
            {t("upgradeModal:month")}
          </Typography>
        </Box>
        {features.map((item) => (
          <Box key={item} display="flex" alignItems="center" gap="8px">
            <CheckCircleIcon className={classes.checkIcon} />
            <Typography variant="body1">{item}</Typography>
          </Box>
        ))}
      </Box>
      <Button
        href={PREMIUM_SUPPLIER_SUBSCRIPTION_LINK}
        variant="contained"
        color="primary"
        fullWidth
        target="_blank"
        rel="noopener noreferrer"
      >
        {t("upgradeModal:cta")}
      </Button>
      <Box display="flex" alignItems="center" justifyContent="center" gap="8px">
        <PhoneIcon className={classes.textDisabled} />
        <Typography variant="body1">{t("upgradeModal:footer")}</Typography>
      </Box>
    </Box>
  );
};
