import { Typography } from "@material-ui/core";
import { Box, styled } from "@mui/material";

export const StyledTypography = styled(Typography)({
  lineHeight: "24px",
  letterSpacing: "0.5px",
  color: "rgba(0, 0, 0, 0.87)",
});

export const StyledBox = styled(Box)({
  width: "50%",
});

export const StyledFullWidthBox = styled(Box)({
  width: "100%",
});
