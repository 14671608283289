import { axiosApi } from "@next/api";
import { backendUrl } from "urls";
import {
  CompanyProfile,
  CompanyProfileFileReferenceType,
  ConnectionInput,
  DeleteCompanyDocumentsInput,
  DiscoveryRequestInput,
  FetchCompanyProfileRequestInput,
  FetchSupplierInvitationsInput,
  InviteCompanyUserInput,
  ResendMemberInviteInput,
  SendBuyerProfileMessageInput,
  Subscription,
  SubscriptionInput,
  Update2FAAuthInput,
  UpdateMemberTeamPermissionInput,
  UpdateProfilePreferencesInput,
  UpdateUserLanguageInput,
  UploadCompanyDocumentsInput,
} from "../redux";
import { CompanyInformationFormValues } from "../components/company-profile-edit/company-profile-information/company-information.form.types";
import { EditProfileFormValues } from "../components/user-profile/user-profile-settings/profile/edit-user-profile-form/edit-user-profile-form.types";
import { ChangePasswordFormValues } from "../components/user-profile/user-profile-settings/authentication/change-password-form/change-password-form.types";

import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { convertObjectToFormData } from "@next/utils/apiUtils";

const fetchSubscriptionList = () => {
  return axiosApi.get<Subscription[]>(`${backendUrl.subscriptions}`);
};

const enableSubscription = (payload: SubscriptionInput) => {
  if (payload.isEnabled) {
    return axiosApi.post(`${backendUrl.userSubscriptions}`, {
      sub_pk: payload.subPk,
    });
  } else {
    return axiosApi.delete(`${backendUrl.userSubscriptions}`, {
      data: { sub_pk: payload.subPk },
    });
  }
};

const fetchCompanyProfile = async (payload: FetchCompanyProfileRequestInput) => {
  if (payload.isPublic) {
    const profile = await axiosApi.get<CompanyProfile>(
      `${backendUrl.companyPublicProfile}/${payload.companyId}`
    );

    const caps = await axiosApi.get(`${backendUrl.companyCapabilities}${profile.data.id}/`);

    return {
      data: {
        ...profile.data,
        capabilities: (caps.data.capabilities || []).filter((item: any) => !!item?.has_capability),
      },
    };
  } else {
    return axiosApi.get<CompanyProfile>(`${backendUrl.companyProfile}/${payload.companyId}`);
  }
};

const updateCompanyProfile = ({ id, ...values }: CompanyInformationFormValues & { id: string }) => {
  // allow lower case postal code in the field and converting it to upper case while sending to API

  let formData = new FormData();

  values.name && formData.append("name", values.name as string);
  typeof values.description === "string" &&
    formData.append("description", values.description || "");
  typeof values.additional_details === "string" &&
    formData.append("additional_details", values.additional_details || "");
  typeof values.additional_details_rich_text === "string" &&
    formData.append("additional_details_rich_text", values.additional_details_rich_text || "");
  values.address && formData.append("address", JSON.stringify(values.address));
  formData.append("public_email", (values.public_email || "") as string);
  values.phone_number && formData.append("phone_number", values.phone_number as string);
  values.website && formData.append("website", values.website as string);
  formData.append("number_employee", (values.number_employee || 0)?.toString() as string);
  values.references && formData.append("references", JSON.stringify(values.references));
  formData.append("shop_floor_area", values.shop_floor_area as string);

  if (typeof values.picture !== "string" && values.picture != null) {
    // converting the blob into a file to send it to the backend
    formData.append("picture", values.picture);
  }

  values.subscribed_to_companies &&
    formData.append("subscribed_to_companies", JSON.stringify(values.subscribed_to_companies));

  values.past_projects_pictures &&
    formData.append("past_projects_pictures", JSON.stringify(values.past_projects_pictures));

  return axiosApi.patch<Partial<any>>(`${backendUrl.company}/${id}`, formData);
};

const updateCompanyFileReferences = ({
  type,
  file,
}: {
  file: File;
  type: CompanyProfileFileReferenceType;
}) => {
  const formData = new FormData();

  formData.append("type", type?.toString());
  formData.append("image_file", file);

  return axiosApi.post<Partial<any>>(`${backendUrl.companyFileReferences}`, formData);
};

const deleteCompanyFileReferences = ({ fileId }: { fileId: string }) => {
  return axiosApi.delete<Partial<any>>(`${backendUrl.companyFileReferences}/${fileId}`);
};

const reorderCompanyFileReferences = (payload: {
  ordering: number[];
  type: CompanyProfileFileReferenceType;
}) => {
  return axiosApi.patch<Partial<any>>(`${backendUrl.companyFileReferences}`, payload);
};

const uploadCompanyFileDocuments = async ({ attachments, pk }: UploadCompanyDocumentsInput) => {
  const response: { data: { files: FileData[]; filesError?: Error } } = {
    data: {
      files: [],
      filesError: undefined,
    },
  };

  if (attachments && attachments.length) {
    try {
      const results: any = await Promise.all(
        attachments?.map((file) => {
          const formData = new FormData();

          formData.append("file", file);

          return axiosApi.post(`${backendUrl.companyFileDocuments}`, formData);
        })
      );

      response.data.files = results?.map((res: any) => res.data);
    } catch (error) {
      response.data.filesError = (error as any)?.response?.data?.error;
    }
  }

  return response;
};

const deleteCompanyFileDocuments = ({ fileId }: DeleteCompanyDocumentsInput) => {
  return axiosApi.delete<Partial<any>>(`${backendUrl.companyFileDocuments}/${fileId}`);
};

const updateUserProfile = ({ first_name, last_name, picture }: EditProfileFormValues) => {
  const formData = new FormData();

  first_name && formData.append("first_name", first_name as string);
  last_name && formData.append("last_name", last_name as string);

  if (typeof picture !== "string" && picture != null) {
    // converting the blob into a file to send it to the backend
    formData.append("picture", picture);
  }

  return axiosApi.put<Partial<any>>(`${backendUrl.profile}`, formData);
};

const updateUserPassword = (values: ChangePasswordFormValues) => {
  return axiosApi.post<Partial<any>>(`${backendUrl.changeUserPassword}`, values);
};

const updateUserLanguage = ({ language, token }: UpdateUserLanguageInput) => {
  const url = token
    ? `${backendUrl.changeUserLanguage}${token}`
    : `${backendUrl.changeUserLanguage}`;
  return axiosApi.post<Partial<any>>(url, { language });
};

const update2FAAuth = (payload: Update2FAAuthInput) => {
  const requestObject = payload?.isConfirm ? { code: payload?.code } : undefined;
  const confirmPostfix = payload?.isConfirm ? "confirm/" : "";

  return axiosApi.post(
    `${backendUrl.authUrl}${payload.authType}/${payload?.actionType}/${confirmPostfix}`,
    requestObject
  );
};

const inviteCompanyUser = (payload: InviteCompanyUserInput) => {
  return axiosApi.post(`${backendUrl.inviteMember}`, payload.users);
};

const fetchUserActive2FAs = () => {
  return axiosApi.get(`${backendUrl.userActiveMethods}`);
};

// Uses a separate endpoint as in the future we might want to add more preferences
const updateProfilePreferences = (payload: UpdateProfilePreferencesInput) => {
  return updateUserLanguage(payload);
};

const sendBuyerProfileMessage = ({ buyerProfileId, ...params }: SendBuyerProfileMessageInput) => {
  const formData = convertObjectToFormData(
    {
      files: params.files,
      message: JSON.stringify(params.message),
    },
    "files"
  );

  return axiosApi.post(`${backendUrl.buyerDiscovery}/${buyerProfileId}/connect/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const sendDiscovery = ({ type, ...params }: DiscoveryRequestInput) => {
  const formData = convertObjectToFormData(
    {
      files: params.files,
      message: JSON.stringify(params.message),
    },
    "files"
  );

  return axiosApi.post(`${backendUrl.discovery}/${type}/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const fetchSupplierInvitations = (input?: FetchSupplierInvitationsInput) => {
  const { isApproved = false, page = 1, pageSize = 30 } = input?.query || {};

  return axiosApi.get(
    `${backendUrl.supplierConnections}/?is_approved=${isApproved}&page=${page}&pageSize=${pageSize}`
  );
};

const fetchInvitationDetails = ({ connectionId }: ConnectionInput) => {
  return axiosApi.get(`${backendUrl.supplierConnections}/${connectionId}/`);
};

const approveInvitation = ({ connectionId }: ConnectionInput) => {
  return axiosApi.post(`${backendUrl.supplierConnections}/${connectionId}/approve/`);
};

const deleteInvitation = ({ connectionId }: ConnectionInput) => {
  return axiosApi.delete(`${backendUrl.supplierConnections}/${connectionId}/`);
};

const updateMemberTeamPermission = (payload: UpdateMemberTeamPermissionInput) => {
  return axiosApi.patch(`${backendUrl.userPermissions}`, payload);
};

const resendMemberInvite = (payload: ResendMemberInviteInput) => {
  return axiosApi.put(`${backendUrl.inviteMember}`, payload);
};

export const profileService = {
  enableSubscription,
  fetchSubscriptionList,
  fetchCompanyProfile,
  updateCompanyProfile,
  updateCompanyFileReferences,
  deleteCompanyFileReferences,
  reorderCompanyFileReferences,
  uploadCompanyFileDocuments,
  deleteCompanyFileDocuments,
  updateUserProfile,
  updateUserPassword,
  updateUserLanguage,
  update2FAAuth,
  fetchUserActive2FAs,
  inviteCompanyUser,
  updateProfilePreferences,
  sendBuyerProfileMessage,
  sendDiscovery,
  fetchSupplierInvitations,
  fetchInvitationDetails,
  approveInvitation,
  deleteInvitation,
  updateMemberTeamPermission,
  resendMemberInvite,
};
