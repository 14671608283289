import React from "react";
import { Drawer } from "@mui/material";
import { batch, useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { DashboardModalTypes } from "./types";
import { SellerDashboardBuyerProfile } from "../components/seller-dashboard-buyer-profile/seller-dashboard-buyer-profile";

export const BuyerProfileDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(DashboardModalTypes.BUYER_PROFILE_DRAWER));
  const data = modal?.data as {
    buyerProfileId: number;
  };

  const onClose = () => {
    batch(() => {
      dispatch(modalsActions.closeModal(DashboardModalTypes.BUYER_PROFILE_DRAWER));
    });
  };

  return (
    <Drawer PaperProps={{ style: { width: 620 } }} anchor="right" open={!!modal} onClose={onClose}>
      {!!modal && data?.buyerProfileId ? (
        <SellerDashboardBuyerProfile buyerProfileId={data?.buyerProfileId} handleClose={onClose} />
      ) : null}
    </Drawer>
  );
};
