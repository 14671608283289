import { Typography, styled } from "@material-ui/core";
import { CustomTypography } from "@next/components/custom-typography";

export const StyledTypography = styled(CustomTypography)({
  color: "rgba(0, 0, 0, 0.87)",
  letterSpacing: "0.3px",
  fontSize: "16px",
});

export const StyledSubHeadingTypography = styled(Typography)({
  fontSize: "14px",
  color: "#0000008A",
  letterSpacing: "0.3px",
});
