import React from "react";
import amqBG from "./amq-bg.jpg";
import PublicRfqCreationOLD from "../../components/public-rfq-creation-OLD";
import PublicRfqCreationHeader from "../../components/public-rfq-creation-OLD/public-rfq-creation-header";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      position: "relative",
      flexDirection: "column",
      background: `url(${amqBG}) no-repeat center center fixed`,
      backgroundSize: "cover",
      width: "100%",
      height: "100%",

      "&::before": {
        content: '""',
        position: "absolute",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
        backgroundColor: "black",
        opacity: 0.7,
      },
    },
  })
);

export const PublicRfqOLD = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PublicRfqCreationHeader />

      <PublicRfqCreationOLD />
    </div>
  );
};
