import React from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";

type StyleProps = {
  disableContentPadding?: boolean;
  customWidth?: string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    dialogPaper: {
      width: ({ customWidth }) => (customWidth ? customWidth : "544px"),
      borderRadius: "24px",
    },
    dialogTitle: {
      margin: 0,
      padding: theme.spacing(3),
      display: "flex",
      alignItems: "center",
    },
    dialogContent: {
      padding: ({ disableContentPadding }) => theme.spacing(disableContentPadding ? 0 : 3),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    loadingContainer: {
      position: "absolute",
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 99,
      backgroundColor: "white",
    },
  })
);

type Props = {
  modalState: {
    modalHeader?: string | React.ReactNode;
    modalBody: React.ReactNode;
    modalBanner?: React.ReactNode;
    className?: string;
    bannerClassName?: string;
    dialogContentClassName?: string;
    dialogTitleClassName?: string;
  };
  handleModalClose: (isModalOpen: boolean) => void;
  isModalOpen: boolean;
  isModalLoading?: boolean;
  dialogProps?: Partial<DialogProps>;
  disableContentPadding?: boolean;
  dividers?: boolean;
  customWidth?: string;
};

const CustomModal: React.FC<Props> = ({
  modalState,
  handleModalClose,
  isModalOpen,
  isModalLoading,
  dialogProps,
  disableContentPadding,
  dividers = true,
  customWidth,
}) => {
  const handleClose = () => {
    handleModalClose(false);
  };
  const classes = useStyles({ disableContentPadding, customWidth });
  const {
    modalHeader,
    modalBody,
    modalBanner,
    className,
    bannerClassName,
    dialogContentClassName,
    dialogTitleClassName,
  } = modalState;

  return (
    <Dialog
      maxWidth="md"
      onClose={handleClose}
      open={isModalOpen}
      classes={{ paper: classes.dialogPaper }}
      {...dialogProps}
    >
      {isModalLoading ? (
        <Box className={classes.loadingContainer}>
          <CircularProgress />
        </Box>
      ) : null}

      {modalHeader && typeof modalHeader === "string" ? (
        <DialogTitle className={`${classes.dialogTitle} ${dialogTitleClassName || ""}`}>
          <Typography variant="h6">{modalHeader}</Typography>

          {handleClose ? (
            <IconButton className={classes.closeButton} onClick={handleClose} size="large">
              <CloseIcon color="secondary" />
            </IconButton>
          ) : null}
        </DialogTitle>
      ) : null}

      {modalHeader && React.isValidElement(modalHeader) ? (
        <DialogTitle className={classes.dialogTitle}>{modalHeader}</DialogTitle>
      ) : null}

      {modalBanner ? (
        <Box className={`${className} ${bannerClassName || ""}`}>{modalBanner}</Box>
      ) : null}

      <DialogContent
        dividers={dividers}
        className={`${classes.dialogContent} ${dialogContentClassName || ""}`}
      >
        {modalBody}
      </DialogContent>
    </Dialog>
  );
};

export default CustomModal;
