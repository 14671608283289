import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Box, Button, Chip, Link, Popover, Theme, Tooltip } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { OrderStateObj, POFileWithRevision } from "@next/modules/orders/redux";
import { removeExtenstionFromFileName } from "@next/utils/fileUtils";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";
import { CustomTypography } from "@next/components/custom-typography";
import { Typography } from "@material-ui/core";
import { OrderStateTag } from "../../order-state-tag";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    fileName: {
      marginLeft: theme.spacing(1),
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    fileLink: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    partFile: {
      display: "flex",
      alignItems: "center",
    },
    revisionHistoryMenu: {
      "& .MuiPopover-paper": {
        minWidth: "500px",
        maxHeight: "400px",
        padding: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      },
    },
    popoverItem: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },
    rowSection: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
  })
);

type Props = {
  poFiles: POFileWithRevision[];
  onRevisePO: () => void;
  isPortal: boolean;
  hasViewerRole: boolean;
  syncSubTotal: boolean;
};

export const OrderPOFiles: React.FC<Props> = ({
  poFiles,
  onRevisePO,
  isPortal,
  hasViewerRole,
  syncSubTotal,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [revHistoryBtnEl, setRevHistoryBtnEl] = React.useState<null | HTMLElement>(null);
  const revHistoryOpened = Boolean(revHistoryBtnEl);

  const sortedPOFiles = useMemo(
    () => [...poFiles].sort((a, b) => b.rev_number - a.rev_number) || [],
    [poFiles]
  );

  //For OrderStateTag component State
  const erpWarning = {
    display_name: t("orders:orderDetail:tag:subTotalEquals0"),
    description: "",
    slug: "at_risk",
  };

  return (
    <>
      {poFiles?.length ? (
        <Box className={classes.partFile}>
          {sortedPOFiles[0].rev_number && (
            <Chip size="small" label={`REV ${sortedPOFiles[0].rev_number}`} />
          )}
          <CustomTypography
            tooltipTitle={sortedPOFiles[0].file_name}
            className={classes.fileName}
            variant="body2"
            maxWidth={250}
            onClick={() => {
              dispatch(
                modalsActions.showModal({
                  key: SharedModalTypes.SUPPLIER_VIEW_PART_MODAL,
                  data: {
                    file_url: sortedPOFiles[0].file_url,
                    pk: sortedPOFiles[0].pk,
                  },
                })
              );
            }}
          >
            <Link rel="noopener noreferrer" className={classes.fileLink}>
              {sortedPOFiles[0].file_name}
            </Link>
          </CustomTypography>
        </Box>
      ) : isPortal || hasViewerRole ? (
        "-"
      ) : (
        ""
      )}

      {!isPortal && !hasViewerRole ? (
        <Box ml={poFiles?.length ? 1 : 0}>
          <Tooltip title={t("orders:orderDetail:tooltip:revisePO")}>
            {poFiles?.length ? null : (
              <Button onClick={onRevisePO} variant="outlined">
                {t("common:attach")}
              </Button>
            )}
          </Tooltip>
        </Box>
      ) : null}
      {poFiles?.length ? (
        <Box ml={poFiles?.length ? 1 : 0} className={classes.rowSection}>
          <Button variant="outlined" onClick={(event) => setRevHistoryBtnEl(event.currentTarget)}>
            {t("orders:orderDetail:versionHistory")}
          </Button>
          <Popover
            anchorEl={revHistoryBtnEl}
            open={revHistoryOpened}
            onClose={() => setRevHistoryBtnEl(null)}
            className={classes.revisionHistoryMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box className={classes.popoverItem}>
              <Typography variant="body2">{t("orders:orderDetail:revision")}</Typography>
              <Typography variant="body2">{t("orders:orderDetail:revisionDate")}</Typography>
            </Box>
            {sortedPOFiles.map((file, i) => (
              <Box key={file.rev_number || i} className={classes.popoverItem}>
                <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                  <Chip
                    size="small"
                    label={`REV ${file.rev_number}`}
                    color={i === 0 ? "primary" : undefined}
                  />
                  <CustomTypography
                    tooltipTitle={file.file_name}
                    className={classes.fileName}
                    variant="body2"
                    onClick={() => {
                      dispatch(
                        modalsActions.showModal({
                          key: SharedModalTypes.SUPPLIER_VIEW_PART_MODAL,
                          data: {
                            file_url: file.file_url,
                            pk: file.pk,
                          },
                        })
                      );
                    }}
                  >
                    <Link rel="noopener noreferrer" className={classes.fileLink}>
                      {removeExtenstionFromFileName(file.file_name)}
                    </Link>
                  </CustomTypography>
                </Box>

                <Typography variant="body2" color="textSecondary">
                  {new Date(file.created_at).toLocaleString("en-US", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                </Typography>
              </Box>
            ))}
          </Popover>
          {!isPortal && syncSubTotal && (
            <Box ml="">
              <OrderStateTag
                state={erpWarning as OrderStateObj}
                tooltipTitle={t("orders:orderDetail:tooltip:erpWarningText")}
              />
            </Box>
          )}
        </Box>
      ) : null}
    </>
  );
};
