import React from "react";

// Helper function to parse the error message (an array) and format it properly
export function renderErrorMessage(message, t) {
  if (message != null) {
    let myArray = null;
    let str = [];

    // Check if we have user generated error or backend errors
    try {
      myArray = JSON.parse(message);
    } catch (e) {
      return t("something_went_wrong_please_contact_support");
    }

    // Parse the array
    for (var key in myArray) {
      if (myArray.hasOwnProperty(key)) {
        str.push(myArray[key]);
      }
    }
    return <p>{str}</p>;
  }
}
