import React, { useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import { Box, Button } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Rfq } from "@next/modules/project/redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { useDispatch } from "react-redux";
import { SharedModalTypes } from "@next/modals/types";
import { MUIDatePicker } from "@next/components/mui-date-picker";
import { formatDate } from "helpers/formatDate";

type SharedExtendRfqProps = {
  rfq: Partial<Rfq>;
  refetchData: boolean;
};

export const SharedExtendRfq: React.FC<SharedExtendRfqProps> = ({
  rfq: { deadline, pk },
  refetchData,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(deadline);

  const handleDateChange = (date: Date | any) => {
    setSelectedDate(formatDate(date, "YYYY-MM-DD"));
  };

  const isDateValid = moment(selectedDate).isValid();

  const handleSubmit = () => {
    dispatch(
      modalsActions.showModal({
        key: SharedModalTypes.EXTEND_RFQ_CONFIRMATION_MODAL,
        data: {
          rfqPk: pk,
          extension_date: formatDate(selectedDate, "YYYY-MM-DD"),
          refetchData,
        },
      })
    );
  };

  return (
    <Box className="c-shared-extend-rfq">
      <MUIDatePicker
        margin="normal"
        id="date-picker-dialog"
        format="MM/dd/yyyy"
        value={selectedDate}
        label={`${t("workspace:endOfRequestForQuotations")} : ${isDateValid ? selectedDate : ""}`}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        disablePast
        invalidDateMessage=""
        minDateMessage=""
        style={{ width: "225px" }}
      />

      <DialogActions className="c-shared-extend-rfq--action c-rfq-action">
        <Button autoFocus onClick={handleSubmit} color="primary" variant="contained">
          {t("workspace:submit")}
        </Button>
      </DialogActions>
    </Box>
  );
};
