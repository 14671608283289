import { Box } from "@mui/material";
import { CompanyPublicProfile } from "@next/modules/profile/redux";
import React from "react";
import CompanyProfileDocCard from "../company-profile-doc-card";

interface Props {
  companyProfile?: CompanyPublicProfile;
}

const CompanyProfileFullCerts: React.FC<Props> = ({ companyProfile }) => {
  if (!companyProfile?.certificates?.length) return null;

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(auto-fill, minmax(min(260px, 100%), 1fr))"
      gap="12px"
    >
      {companyProfile.certificates.map((item, ix) => (
        <CompanyProfileDocCard key={ix} file={{ ...item.file, file_name: item.name, pk: ix + 1 }} />
      ))}
    </Box>
  );
};

export default CompanyProfileFullCerts;
