import { Box, Card, CardActionArea, CardContent, Theme, Typography, alpha } from "@mui/material";
import React from "react";
import { grey } from "@mui/material/colors";
import { CardOrderState } from "./select-order-state.cards.types";
import { getOrderStateTagIcon } from "../../order-detail/order-detail-header/order-detail-header.utils";
import { CustomTypography } from "@next/components/custom-typography";

import { createStyles, makeStyles } from "@mui/styles";

type StyleProps = {
  selected?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: ({ selected }) => (selected ? "#FAFAFA" : "white"),
      borderColor: ({ selected }) =>
        selected ? theme.palette.primary.main : theme.palette.divider,
      borderRadius: "32px",
    },
    content: {
      width: "100%",
      height: "40px",
      display: "flex",
      padding: theme.spacing(2),
    },
    arrowIcon: {
      color: alpha(grey[900], 0.3),
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

type Props = {
  state: CardOrderState;
  selected?: boolean;
  onClick?: (newSelectedFilterState: CardOrderState) => void;
};

export const SelectOrderStateCard: React.FC<Props> = ({ state, selected, onClick }) => {
  const classes = useStyles({ selected });

  return (
    <Card variant="outlined" className={classes.root} onClick={() => onClick && onClick(state)}>
      <CardActionArea>
        <CardContent className={classes.content}>
          <Box display="flex" alignItems="center" gap={"8px"}>
            {getOrderStateTagIcon(state?.slug)}
            <CustomTypography variant="body2" tooltipTitle={null}>
              {state?.name}
            </CustomTypography>
            ·<Typography variant="body2">{state?.count}</Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
