import React, { useEffect } from "react";
import { Box, Dialog, DialogTitle, Divider, IconButton, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CreateMultiContractModalData, ProjectModalTypes } from "./types";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import CloseIcon from "@mui/icons-material/Close";
import GavelOutlinedIcon from "@mui/icons-material/Gavel";
import { CreateMultiContract } from "../components/multi-contract/create-multi-contract";
import { projectActions } from "../redux";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: { display: "flex", alignItems: "center" },
    dialogTitleText: { display: "flex", alignItems: "center", flexGrow: 1 },
    dialogTitleIcon: { marginRight: theme.spacing(1) },
    dialogTitleRightText: {
      marginLeft: "12px",
    },
    paper: {
      minHeight: "264px",
      maxWidth: "800px",
    },
  })
);

export const ProjectCreateMultiContractModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(ProjectModalTypes.CREATE_MULTI_CONTRACT_MODAL));

  const data = modal?.data;

  useEffect(() => {
    dispatch(projectActions.resetImportMultiContractRFQExcelReport());
  }, []);

  if (!modal || !data) return null;

  const { rfq, onSuccess } = data as CreateMultiContractModalData;

  const onClose = () => {
    dispatch(projectActions.resetImportMultiContractRFQExcelReport());

    dispatch(modalsActions.closeModal(ProjectModalTypes.CREATE_MULTI_CONTRACT_MODAL));
  };

  const onCloseAndSuccess = () => {
    onClose();
    onSuccess && onSuccess();
  };

  return (
    <Dialog
      open={!!modal}
      onClose={onClose}
      closeAfterTransition
      fullWidth={true}
      maxWidth="md"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box className={classes.dialogTitleText}>
          <GavelOutlinedIcon className={classes.dialogTitleIcon} />
          <Typography variant="h6">{t("rfq:quotations:multiSupplierSelection")}</Typography>

          <Typography className={classes.dialogTitleRightText} variant="h6" color="textSecondary">
            {t("rfq:quotations:parts", { count: rfq?.parts?.length || 0 })}
          </Typography>
        </Box>

        <IconButton onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Divider />

      <CreateMultiContract rfq={rfq} onSuccess={onSuccess} onClose={onCloseAndSuccess} />
    </Dialog>
  );
};
