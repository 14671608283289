import React, { useEffect } from "react";
import { Tab, Tabs, Theme } from "@mui/material";
import { TabPanel } from "components/utils/tab-panel/tab-panel";
import { useTranslation } from "react-i18next";
import { AuthenticationSettings } from "./authentication/authentication-settings";
import { NotificationSettings } from "./notifications/notifications-settings";
import { ProfileSettings } from "./profile/profile-settings";
import { useDispatch, useSelector } from "react-redux";
import { profileNextActions } from "@next/modules/profile/redux";
import { createStyles, makeStyles } from "@mui/styles";
import { OutlookIntegration } from "./outlook-integration/outlook-integration";
import { getCompany } from "services/profile/profile.selectors";
import { checkIsSellerCompany } from "@next/utils/checkCompanyUtils";
import { CompanyTypeNext } from "@next/redux/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editProfilePanel: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2),
      minHeight: "400px",
    },
    panel: { padding: theme.spacing(3), minHeight: "400px" },
    outlookPanel: {
      padding: theme.spacing(3),
      minHeight: "104px",
    },
  })
);

export const UserProfileSettings = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const company = useSelector(getCompany);
  const isSupplier = checkIsSellerCompany(company?.type as CompanyTypeNext);

  useEffect(() => {
    dispatch(profileNextActions.fetchUserActive2FAsRequest());
  }, []);

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs indicatorColor="primary" value={value} onChange={handleChange} variant="fullWidth">
        <Tab label={t("profile:userProfile:tabProfile")} />
        <Tab label={t("profile:userProfile:tabAuthentication")} />
        {!isSupplier && <Tab label={t("profile:userProfile:tabOutlookIntegration")} />}
        <Tab label={t("profile:userProfile:tabNotifications")} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <div className={classes.editProfilePanel}>
          <ProfileSettings />
        </div>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <div className={classes.panel}>
          <AuthenticationSettings />
        </div>
      </TabPanel>
      {!isSupplier && (
        <TabPanel value={value} index={2}>
          <div className={classes.outlookPanel}>
            <OutlookIntegration />
          </div>
        </TabPanel>
      )}
      <TabPanel value={value} index={isSupplier ? 2 : 3}>
        <div className={classes.panel}>
          <NotificationSettings />
        </div>
      </TabPanel>
    </div>
  );
};
