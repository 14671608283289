import { DistributionForSupplierContacts } from "@next/modals/shared-supplier-contact-lists-modal";
import { GenericUser } from "@next/modules/profile/redux";
import { t } from "assets/configi18n/i18n";
import { formatDate } from "helpers/formatDate";

export const generateImageTooltipText = (
  user: GenericUser,
  includeEmailInTooltip: boolean,
  seenDate: string
) => {
  return `${user.full_name || user.email} ${
    includeEmailInTooltip && user.full_name ? `(${user.email})` : ""
  } ${seenDate ? t("orders:viewedOn", { date: formatDate(seenDate, "DD-MMM-YYYY") }) : ""}`;
};

export const filteredUsersAccordingToDistribution = (
  users: GenericUser[],
  distribution: DistributionForSupplierContacts
) => {
  if (!users || users.length === 0) return [];
  return users.filter((user) => {
    if (distribution === DistributionForSupplierContacts.RFQ && user.is_rfq_distribution === false)
      return false;
    if (distribution === DistributionForSupplierContacts.ORDER && user.is_po_distribution === false)
      return false;
    return true;
  });
};
