import { createStyles, makeStyles } from "@mui/styles";
import React, { ReactNode } from "react";

interface PaginatedAutoCompleteLoaderProps {
  loadingSkeletonsCount: number;
  LoadingSkeleton: ReactNode;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    skeletonContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

const PaginatedAutoCompleteLoader: React.FC<PaginatedAutoCompleteLoaderProps> = ({
  loadingSkeletonsCount,
  LoadingSkeleton,
}) => {
  const classes = useStyles();

  return (
    <div>
      {Array(loadingSkeletonsCount)
        .fill(true)
        .map(() => (
          <div className={classes.skeletonContainer}>{LoadingSkeleton}</div>
        ))}
    </div>
  );
};

export default PaginatedAutoCompleteLoader;
