/**
 * use this component to display head of page
 */

import React from "react";
import { useSelector } from "react-redux";

import { Grid } from "@mui/material";

import CardInfo from "components/common/card-info/card-info.component";
import { StatusInfo } from "../details-summary-header/status-info";
import { CompetitivenessInsights } from "../details-summary-competitiveness-report/details-summary-competitiveness-report.component";

export const DetailsSummaryHeader = (props) => {
  const {
    quotation,
    displayName,
    tag,
    rfqAdditionalDetails,
    rfqAdditionalDetailsRichText,
    contracting,
    nda,
    supplier_signed_nda,
    dateToDisplay,
    subText,
    handleOpenModal,
    getQuotationThreadDetails,
    setActiveQuotationThread,
  } = props;
  const language = useSelector((state) => state.profile.user.language);

  return (
    <div className="c-details-summary-header">
      <StatusInfo
        status={quotation?.rfq_info?.status}
        cancellationReason={quotation?.rfq_info?.cancellation_note}
        onHoldReason={quotation?.rfq_info?.on_hold_note}
      />
      {quotation?.competitiveness_report && (
        <CompetitivenessInsights
          delay={quotation.delay}
          delayUnit={quotation.delay_unit}
          totalPrice={quotation.total_price}
          report={quotation.competitiveness_report}
        />
      )}
      <Grid xs={12}>
        <CardInfo
          quotation={quotation}
          displayName={displayName}
          dateToDisplay={dateToDisplay}
          additionalDetails={rfqAdditionalDetails}
          additionalDetailsRichText={rfqAdditionalDetailsRichText}
          tag={tag}
          language={language}
          supplier_signed_nda={supplier_signed_nda}
          nda={nda}
          contracting={contracting}
          subText={subText}
          getQuotationThreadDetails={getQuotationThreadDetails}
          setActiveQuotationThread={setActiveQuotationThread}
          handleOpenModal={handleOpenModal}
        />
      </Grid>
    </div>
  );
};
