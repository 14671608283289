import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { CustomTypography } from "../custom-typography";

const TextCell: React.FC<GridRenderCellParams<any>> = (params) => {
  return (
    <CustomTypography tooltipTitle={params?.value?.toString() || ""}>
      {params?.value?.toString() || ""}
    </CustomTypography>
  );
};

export default TextCell;
