import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Theme,
  Typography,
} from "@mui/material";
import { GenericPhoneNumberInput } from "components/utils/input/phone-number";
import { GenericTextInput } from "components/utils/input/text";
import { ErrorMessage, Field, Form, FormikProps } from "formik";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { PublicRFQFormValuesType } from "./public-rfq-creation-form.types";
import { FormikAttachFile } from "@next/components/formik-attach-file";
import PublicRfqCreationFormButton from "../public-rfq-creation-form-button";
import { MUIDatePicker } from "@next/components/mui-date-picker";
import LockIcon from "@mui/icons-material/Lock";
import { formatDate } from "helpers/formatDate";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: "calc(100vh - 200px)",
      overflowY: "auto",
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(3),
      },
      // MUI issue: https://github.com/mui/material-ui/issues/7466
      "& .MuiGrid-root": {
        width: "100% !important",
        margin: "0 !important",
      },
    },
  })
);

export const PublicRfqCreationFormBase: React.FC<FormikProps<PublicRFQFormValuesType>> = ({
  handleSubmit,
  errors,
  touched,
  values,
  setFieldValue,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit} className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="textPrimary">
            {t("workspaceNext:publicRfqOLD:form:companyInformation")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <GenericTextInput
            name="company_name"
            label={t("workspaceNext:publicRfqOLD:form:yourCompanyName")}
            size="small"
            error={touched.company_name && !!errors.company_name}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <GenericTextInput
            name="name"
            label={t("workspaceNext:publicRfqOLD:form:yourName")}
            size="small"
            error={touched.name && !!errors.name}
          />
        </Grid>

        <Grid item xs={6}>
          <GenericPhoneNumberInput
            name="phone_number"
            label={t("workspaceNext:publicRfqOLD:form:phone")}
            defaultCountry="ca"
            onlyCountries={["us", "ca"]}
            value={values.phone_number}
            onBlur={(data: any) => setFieldValue("phone_number", data?.target?.value)}
            size="small"
          />
        </Grid>

        <Grid item xs={6}>
          <GenericTextInput
            name="email"
            label={t("workspaceNext:publicRfqOLD:form:yourEmail")}
            size="small"
            type="email"
            error={touched.email && !!errors.email}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2" color="textPrimary">
            {t("workspaceNext:publicRfqOLD:form:requestInformation")}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <GenericTextInput
            name="rfq_name"
            label={t("workspaceNext:publicRfqOLD:form:addATitle")}
            size="small"
            error={touched.rfq_name && !!errors.rfq_name}
          />
        </Grid>

        <Grid item xs={6}>
          <MUIDatePicker
            name="deadline"
            label={t("workspaceNext:rfqDrawer:placeholder:addEndDate")}
            value={values.deadline}
            onChange={(value) => {
              setFieldValue("deadline", formatDate(value, "YYYY-MM-DD") || "");
            }}
            error={touched.deadline && !!errors.deadline}
            helperText={touched.deadline && !!errors.deadline ? errors.deadline : ""}
          />
        </Grid>

        <Grid item xs={12}>
          <GenericTextInput
            name="additional_details"
            label={t("workspaceNext:publicRfqOLD:form:subject")}
            multiline
            rows="5"
            error={touched.additional_details && !!errors.additional_details}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            name="attachments"
            component={FormikAttachFile}
            captionText={
              <Trans
                i18nKey="workspaceNext:publicRfqOLD:form:securedUpload"
                components={[
                  <Link
                    href="https://axya.co/en/how-it-works/axya-security"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Secure Upload, Axya is SOC2 compliant
                  </Link>,
                ]}
              />
            }
            captionIcon={<LockIcon />}
          />
          <Box pt={"5px"} pl={"5px"}>
            <Typography color={"error"} variant="caption">
              <ErrorMessage component="div" name="attachments" />
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Field
            as={FormControlLabel}
            type="checkbox"
            name="is_agreed"
            control={<Checkbox color="primary" />}
            label={
              <Trans
                i18nKey="workspaceNext:publicRfqOLD:form:agreeToTheTerms"
                components={[
                  <Link
                    href="https://axya.co/en/buyers-terms-of-services"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms and conditions
                  </Link>,
                ]}
              />
            }
          />
          <Typography
            color={touched.is_agreed && !!errors.is_agreed ? "error" : "initial"}
            variant="caption"
          >
            <ErrorMessage component="div" name="is_agreed" />
          </Typography>
        </Grid>
      </Grid>

      <PublicRfqCreationFormButton>{t("common:send")}</PublicRfqCreationFormButton>
    </Form>
  );
};
