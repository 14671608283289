import React from "react";
import ValidationPage from "components/ValidationPage";
import { useTranslation } from "react-i18next";
import notFound from "assets/img/oops-not-found.png";

export const ProjectNotFound = () => {
  const { t } = useTranslation();

  return (
    <ValidationPage
      className={"c-project-not-found"}
      title={t("project:error:projectNotFoundTitle")}
      body={t("project:error:projectNotFoundBody")}
      primaryText={t("project:error:projectNotFoundButton")}
      handleSubmitPrimaryUrl={() => {
        window.location.href = `${window.location.origin}`;
      }}
      image={notFound}
    />
  );
};
