import { Box } from "@mui/material";
import React from "react";
import { useSellerDashboardBuyerViewsTableData } from "./seller-dashboard-buyer-views-table.hooks";
import { createStyles, makeStyles } from "@mui/styles";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

const useStyles = makeStyles(() =>
  createStyles({
    dataGrid: {
      "& .MuiDataGrid-columnsContainer": {
        display: "none",
      },
    },
  })
);

export const SellerDashboardBuyerViewsTable = () => {
  const gridData = useSellerDashboardBuyerViewsTableData();
  const classes = useStyles();

  return (
    <Box width={"100%"}>
      <DataGridProV5
        autoHeight
        pageSize={5}
        pagination
        paginationMode="client"
        rows={gridData?.rows || []}
        columns={gridData?.columns || []}
        disableSelectionOnClick
        disableColumnResize
        headerHeight={0}
        rowHeight={60}
        className={classes.dataGrid}
      />
    </Box>
  );
};
