import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    option: {
      maxWidth: 360,
      whiteSpace: "pre-wrap",
    },
  })
);

interface Props {
  title: string;
  helperText: string;
}

const AccessLevelMenuItem: React.FC<Props> = ({ title, helperText }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.option}>
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        {title}
      </Typography>
      <Typography variant="body1" color={"textSecondary"}>
        {helperText}
      </Typography>
    </Box>
  );
};

export default AccessLevelMenuItem;
