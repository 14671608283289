import { DashboardFetchType } from "./dashboard-rfqs-modal.types";

export const getQueryForDashboardFetchType = (fetchType: DashboardFetchType) => {
  let query = "";

  switch (fetchType) {
    case DashboardFetchType.ACTIVE_RFQS:
      query = "status=InProgress";
      break;
    case DashboardFetchType.WAITING_RFQS:
      query = "status=Waiting";
      break;
    case DashboardFetchType.PO_PENDING_CONTRACTS:
      query = "status=Contract&orderStatus=poPending";
      break;
    case DashboardFetchType.WAITING_RATE:
      query = "status=Contract&orderStatus=ratingRequested";
      break;
    default:
      break;
  }

  return query;
};
