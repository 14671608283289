import { connect } from "react-redux";
import { AddPart } from "./add-part.component";
import { workspaceActions } from "../../services/workspace";
import { modalsActions } from "../../services/modals/modals.actions";

const mapDispatchToProps = {
  createPart: workspaceActions.createPart,
  editPart: workspaceActions.editPart,
  handleOpenModal: modalsActions.showModal,
  patchPurchaseOrder: workspaceActions.patchPurchaseOrder,
};

const mapStateToProps = (state) => ({
  token: state.profile.token,
  processes: state.partConfiguration.processes,
  machineTypes: state.partConfiguration.machineTypes,
  materials: state.partConfiguration.materials,
  alloys: state.partConfiguration.alloys,
  treatments: state.partConfiguration.treatment,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPart);
