// URL

import { backendUrl } from "../../urls";

// Helper
import { handleResponse } from "../../helpers/responseHandling";
import { axiosApi } from "@next/api";
import { genericErrorHandler } from "@next/utils/apiUtils";

const MAX_SIZE = 102400; //100mb

export const userOnboardingApi = {
  setUploadedFiles,
  registerUser,
  uploadsCompanyImages,
};

/**
 * first uploaded file
 * @param {object} file uploaded file
 * @param {function} t i18n function
 * @returns  promise object new file
 */

function setUploadedFiles(file, t) {
  const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf|\.pptx|\.jfif)$/i;

  const fileSize = Math.round(file.size / 1024);

  const myPromise = new Promise((resolve, reject) => {
    let companyImage = {
      file: file,
      url: URL.createObjectURL(file),
      name: file.name,
      size: file.size,
      type: file.type,
    };
    resolve(companyImage);
  });

  return myPromise
    .then((data) => {
      if (allowedExtensions.exec(data.name) && fileSize < MAX_SIZE) {
        return Promise.resolve(data);
      } else {
        return Promise.reject(t("userOnboarding:invalidExtension"));
      }
    })
    .catch((error) => {
      genericErrorHandler(JSON.stringify(error?.response?.data));
    });
}

/**
 * Uploads company image
 *
 * @param {String} token - company token for User Authentication
 * @param {File} file - The file to be uploaded
 */
function uploadsCompanyImages(file, email, token) {
  const formData = new FormData();

  formData.append("file", file);
  formData.append("email", email);
  formData.append("token", token);

  return axiosApi
    .post(backendUrl.uploadCompanyImage, formData)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      genericErrorHandler(JSON.stringify(error?.response?.data));
    });
}

/**
 *
 * @param {*} userInformation
 * @returns
 */
function registerUser(userInformation) {
  return axiosApi
    .post(backendUrl.registerUserOnBoarding, userInformation)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}
