import * as yup from "yup";
import { t } from "assets/configi18n/i18n";
import { EditFormValues } from "./part-form.types";

export const editPartFormValidationSchema = yup.object({
  name: yup
    .string()
    .max(255)
    .required(t("project:rfqDrawer:rfqForm:errorMessage:thisFieldIsRequired")),
  processes: yup.array().required(t("project:rfqDrawer:rfqForm:errorMessage:thisFieldIsRequired")),
  treatment: yup.object().required(t("project:rfqDrawer:rfqForm:errorMessage:thisFieldIsRequired")),
  alloy: yup.object().required(t("project:rfqDrawer:rfqForm:errorMessage:thisFieldIsRequired")),
  additional_details: yup
    .string()
    .required(t("project:rfqDrawer:rfqForm:errorMessage:thisFieldIsRequired")),
});

export const getEditPartFormInitialValues = (initialValues: any): EditFormValues => {
  return {
    name: initialValues?.name,
    processes: initialValues?.processes?.map((process: any) => {
      return {
        label: process?.name,
        value: process?.id,
        type: process?.type,
      };
    }),
    treatment: {
      label: initialValues?.treatment,
      value: initialValues?.treatment_pk,
      type: "",
    },
    alloy: {
      label: initialValues?.alloy,
      value: initialValues?.alloy_pk,
      type: "",
    },
    additional_details: initialValues?.additional_details,
    part_uploads_list: initialValues?.part_uploads_list || [],
  };
};
