import React from "react";
import { CustomTypography } from "@next/components/custom-typography";
import * as S from "./analytic-graph-card-legend-list.styled";
import TimelineIcon from "@mui/icons-material/Timeline";

type Props = {
  legendTitles: string[];
  colors: string[];
};

export const LegendList: React.FC<Props> = ({ legendTitles, colors }) => {
  const legendListItems = legendTitles?.map((title, i) => {
    if (title === "Cumulative value" || title === "Valeur cumulée") {
      return (
        <S.StyledDiv key={title}>
          <TimelineIcon style={{ marginLeft: "24px", color: colors[i] }} fontSize="small" />

          <CustomTypography variant="body2" style={{ marginLeft: "8px", marginTop: ".2px" }}>
            {title}
          </CustomTypography>
        </S.StyledDiv>
      );
    } else {
      return (
        <li key={title} style={{ marginLeft: "24px" }}>
          <S.StyledSpan style={{ color: colors[i] }}>•</S.StyledSpan>

          <CustomTypography variant="body2" style={{ color: "#000000" }}>
            {title}
          </CustomTypography>
        </li>
      );
    }
  });

  return <S.StyledUl>{legendListItems}</S.StyledUl>;
};
