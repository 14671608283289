export enum SupplierQuotesField {
  REQUEST = "rfq_info.name",
  PARTS = "parts_count",
  BUYER = "BUYER",
  REQUEST_CREATED_BY = "createdBy",
  REQUEST_CREATED_ON = "createdOn",
  QUOTE_SUBMITTED_ON = "submittedOn",
  REQUEST_ENDS_ON = "requestEndsOn",
  COST_AND_LEAD_TIME = "cost",
  STATUS = "quotation_status",
}
