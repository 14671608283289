import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  AddCompanyProfileViewInput,
  BuyerProfile,
  BuyerProfilePart,
  CompanyProfileAnalytics,
  CompanyProfileViews,
  DashboardState,
  FetchBuyerProfileInput,
  FetchBuyerProfilePartInput,
  FetchBuyerProfilesInput,
  FetchCompanyProfileViewsInput,
  SupplierOnboardingStatus,
} from "./types";
import { ProfileConnectionState } from "@next/modules/marketplace/components/profile-connect-button/profile-connect-button";
import { Connection } from "@next/modules/profile/redux";
import { OnboardingSteps } from "../components/seller-dashboard-onboarding-premium/seller-dashboard-onboarding-premium";

export const initialState: DashboardState = {
  companyProfileAnalyticsLoading: false,
  companyProfileAnalyticsError: undefined,
  companyProfileAnalytics: undefined,
  companyProfileViewsLoading: false,
  companyProfileViewsError: undefined,
  companyProfileViews: undefined,
  companyProfileViewsCount: 0,
  companyProfileViewsLastQuery: undefined,
  addCompanyProfileViewLoading: false,
  addCompanyProfileViewError: undefined,
  buyerProfilesLoading: false,
  buyerProfilesError: undefined,
  buyerProfiles: undefined,
  buyerProfilesCount: 0,
  buyerProfileLoading: false,
  buyerProfileError: undefined,
  buyerProfile: undefined,
  buyerProfilePartLoading: false,
  buyerProfilePartError: undefined,
  buyerProfilePart: undefined,
  supplierOnboardingStatus: undefined,
  supplierOnboardingStatusLoading: false,
};

const dashboardSlice = createSlice({
  name: "dashboardNext",
  initialState,
  reducers: {
    fetchCompanyProfileAnalyticsRequest: (state) => {
      state.companyProfileAnalyticsLoading = true;
      state.companyProfileAnalyticsError = undefined;
      state.companyProfileAnalytics = undefined;
    },
    fetchCompanyProfileAnalyticsFailure: (state, action: PayloadAction<Error>) => {
      state.companyProfileAnalyticsLoading = false;
      state.companyProfileAnalyticsError = action.payload;
    },
    fetchCompanyProfileAnalyticsSuccess: (
      state,
      action: PayloadAction<CompanyProfileAnalytics>
    ) => {
      state.companyProfileAnalyticsLoading = false;
      state.companyProfileAnalyticsError = undefined;
      state.companyProfileAnalytics = action.payload;
    },
    fetchCompanyProfileViewsRequest: (
      state,
      action: PayloadAction<FetchCompanyProfileViewsInput>
    ) => {
      state.companyProfileViewsLoading = true;
      state.companyProfileViewsError = undefined;
      state.companyProfileViews = undefined;
      state.companyProfileViewsLastQuery = action.payload.query;
    },
    fetchCompanyProfileViewsFailure: (state, action: PayloadAction<Error>) => {
      state.companyProfileViewsLoading = false;
      state.companyProfileViewsError = action.payload;
    },
    fetchCompanyProfileViewsSuccess: (
      state,
      action: PayloadAction<{ results: CompanyProfileViews[]; count: number }>
    ) => {
      state.companyProfileViewsLoading = false;
      state.companyProfileViewsError = undefined;
      state.companyProfileViews = action.payload.results;
      state.companyProfileViewsCount = action.payload.count;
    },
    addCompanyProfileViewRequest: (state, _action: PayloadAction<AddCompanyProfileViewInput>) => {
      state.addCompanyProfileViewLoading = true;
      state.addCompanyProfileViewError = undefined;
    },
    addCompanyProfileViewFailure: (state, action: PayloadAction<Error>) => {
      state.addCompanyProfileViewLoading = false;
      state.addCompanyProfileViewError = action.payload;
    },
    addCompanyProfileViewSuccess: (state) => {
      state.addCompanyProfileViewLoading = false;
      state.addCompanyProfileViewError = undefined;
    },
    fetchBuyerProfilesRequest: (state, _action: PayloadAction<FetchBuyerProfilesInput>) => {
      state.buyerProfilesLoading = true;
      state.buyerProfilesError = undefined;
      state.buyerProfiles = undefined;
    },
    fetchBuyerProfilesFailure: (state, action: PayloadAction<Error>) => {
      state.buyerProfilesLoading = false;
      state.buyerProfilesError = action.payload;
    },
    fetchBuyerProfilesSuccess: (
      state,
      action: PayloadAction<{ results: BuyerProfile[]; count: number }>
    ) => {
      state.buyerProfilesLoading = false;
      state.buyerProfilesError = undefined;
      state.buyerProfiles = action.payload.results;
      state.buyerProfilesCount = action.payload.count;
    },
    fetchBuyerProfileRequest: (state, _action: PayloadAction<FetchBuyerProfileInput>) => {
      state.buyerProfileLoading = true;
      state.buyerProfileError = undefined;
      state.buyerProfile = undefined;
    },
    fetchBuyerProfileFailure: (state, action: PayloadAction<Error>) => {
      state.buyerProfileLoading = false;
      state.buyerProfileError = action.payload;
    },
    fetchBuyerProfileSuccess: (state, action: PayloadAction<BuyerProfile>) => {
      state.buyerProfileLoading = false;
      state.buyerProfileError = undefined;
      state.buyerProfile = action.payload;
    },
    fetchBuyerProfilePartRequest: (state, _action: PayloadAction<FetchBuyerProfilePartInput>) => {
      state.buyerProfilePartLoading = true;
      state.buyerProfilePartError = undefined;
      state.buyerProfilePart = undefined;
    },
    fetchBuyerProfilePartFailure: (state, action: PayloadAction<Error>) => {
      state.buyerProfilePartLoading = false;
      state.buyerProfilePartError = action.payload;
    },
    fetchBuyerProfilePartSuccess: (state, action: PayloadAction<BuyerProfilePart[]>) => {
      state.buyerProfilePartLoading = false;
      state.buyerProfilePartError = undefined;
      state.buyerProfilePart = action.payload;
    },
    updateBuyerProfileConnection: (
      state,
      action: PayloadAction<{
        buyerProfileId?: number;
        connection: Connection | null;
      }>
    ) => {
      const newConnectionState =
        action.payload.connection === null
          ? ProfileConnectionState.NOT_CONNECTED
          : action.payload.connection.is_approved
            ? ProfileConnectionState.CONNECTED
            : ProfileConnectionState.PENDING;

      state.buyerProfile = {
        ...state.buyerProfile!,
        connection: action.payload.connection,
        connection_status: newConnectionState,
      };
      if (action.payload.buyerProfileId)
        state.companyProfileViews = state.companyProfileViews?.map((item) => {
          if (item.viewed_by_company?.id === action.payload.buyerProfileId) {
            return {
              ...item,
              connection: action.payload.connection,
              connection_status: newConnectionState,
            };
          }
          return item;
        });
    },

    fetchSupplierOnboardingStatusRequest: (
      state,
      _action: PayloadAction<((data: SupplierOnboardingStatus) => void) | undefined>
    ) => {
      state.supplierOnboardingStatusLoading = true;
      state.supplierOnboardingStatus = undefined;
    },
    fetchSupplierOnboardingStatusFailure: (state, action: PayloadAction<Error>) => {
      state.supplierOnboardingStatusLoading = false;
      state.supplierOnboardingStatusError = action.payload;
    },
    fetchSupplierOnboardingStatusSuccess: (
      state,
      action: PayloadAction<SupplierOnboardingStatus>
    ) => {
      state.supplierOnboardingStatusLoading = false;
      state.supplierOnboardingStatusError = undefined;
      state.supplierOnboardingStatus = action.payload;
    },
    showPopUpOnboardingRequest: (state, _action: PayloadAction<OnboardingSteps>) => {},
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
