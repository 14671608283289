import React from "react";
import Modal from "@next/components/custom-modal";
import { useDispatch, useSelector } from "react-redux";
import { SharedModalTypes } from "./types";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { AddInternalNotesModal } from "@next/components/add-internal-notes/add-internal-notes";
import { t } from "assets/configi18n/i18n";
import { SharedDeleteInternalNotesModal } from "./shared-delete-internal-notes-modal";

type Props = {
  moduleType: string;
  isDetailPage?: boolean;
  paramID?: number;
};
export const SharedInternalNotesModal: React.FC<Props> = ({
  moduleType,
  isDetailPage,
  paramID,
}) => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(SharedModalTypes.INTERNAL_NOTES_MODAL));

  const handleModalClose = () => {
    dispatch(modalsActions.closeModal(SharedModalTypes.INTERNAL_NOTES_MODAL));
  };
  return (
    <Modal
      customWidth="472px"
      isModalOpen={!!modal}
      handleModalClose={handleModalClose}
      modalState={{
        modalHeader:
          moduleType == "PO"
            ? t("project:table:row:internalNotes:managePO")
            : t("project:table:row:internalNotes:manageRFQ"),
        modalBody: (
          <>
            <AddInternalNotesModal
              moduleType={moduleType}
              isDetailPage={isDetailPage}
              paramID={paramID}
            />
            <SharedDeleteInternalNotesModal moduleType={moduleType} />
          </>
        ),
      }}
    />
  );
};
