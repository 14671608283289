import { t } from "assets/configi18n/i18n";
import * as yup from "yup";

export type ProjectCreateRenameFormState = {
  display_name: string;
};

export const projectCreateRenameFormState: ProjectCreateRenameFormState = {
  display_name: "",
};

export const projectCreateRenameFormValidationSchema = yup.object({
  display_name: yup
    .string()
    .required("Name is required")
    .max(256, t("common:validation:maxCharacters", { limit: 256 })),
});
