// General imports
import React from "react";

// Material ui imports
import { Popover } from "@mui/material";

import { SellerRating } from "../seller-info-popover/seller-rating/seller-rating.component";

export const SellerInfoPopover = ({
  handleClose,
  open,
  anchorEl,
  sellerData,
  popoverPosition: { popoverLeft, popoverTop },
  t,
  ratingScores,
}) => {
  return (
    <Popover
      id={"mouse-over-popover"}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: popoverTop,
        left: popoverLeft + 570,
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handleClose}
      disableRestoreFocus
    >
      <SellerRating sellerData={sellerData} ratingScores={ratingScores} t={t} />
    </Popover>
  );
};
