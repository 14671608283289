import React from "react";
import { Avatar, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { getCompany, getUser } from "services/profile/profile.selectors";

import { Instigator } from "@next/modules/project/redux";
import { openProfileInNewTab } from "@next/utils/browserUtils";

export interface SupplierAvatarProps {
  instigator: Instigator;
  disableClick?: boolean;
}

export const SupplierAvatar: React.FC<SupplierAvatarProps> = ({ instigator, disableClick }) => {
  const tracking = useTracking();
  const user = useSelector(getUser);
  const company = useSelector(getCompany);

  const onClickRedirectToSupplierProfilePage = () => {
    if (!disableClick) {
      openProfileInNewTab(instigator?.name, instigator?.uuid);
    }

    tracking.trackEvent({
      scout_category: "axya-supplier-profile",
      scout_feature_name: "supplier-profile-redirection-from-chat-box",
      scout_company: company?.name,
      scout_username: user?.name,
      scout_user_type: user?.type,
      scout_date: new Date(),
    });
  };

  return (
    <Box
      className={`${!disableClick ? "box-cursor-pointer" : ""}`}
      onClick={onClickRedirectToSupplierProfilePage}
    >
      <Avatar src={instigator?.picture} className="c-chat-dialog--avatar"></Avatar>
    </Box>
  );
};
