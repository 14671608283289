import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { PublicRfqCreationFormBase } from "./public-rfq-creation-form.base";
import {
  getPublicRfqFormInitialValues,
  getPublicRfqFormValidationSchema,
} from "./public-rfq-creation-form.definitions";
import { PublicRFQFormValuesType } from "./public-rfq-creation-form.types";

type Props = {
  onSubmit: (values: PublicRFQFormValuesType) => void;
};

const PublicRfqCreationForm: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={getPublicRfqFormInitialValues()}
      onSubmit={onSubmit}
      validationSchema={getPublicRfqFormValidationSchema(t)}
      enableReinitialize
    >
      {(props) => <PublicRfqCreationFormBase {...props} />}
    </Formik>
  );
};

export default PublicRfqCreationForm;
