import { useDispatch, useSelector } from "react-redux";
import {
  selectSupplierQuotes,
  selectSupplierQuotesCounts,
  selectSupplierQuotesLoading,
} from "../../redux/selectors";
import { useEffect, useMemo, useState } from "react";
import { generatePaginationAndSearchQuery } from "@next/utils/paginationUtils-v5";
import { workspaceNextActions } from "../../redux";
import { getSupplierQuotesTableColumns } from "./supplier-quotes-table.columns";
import { SHORT_PAGINATION_PAGE_SIZE } from "@next/constants";

export const useSupplierQuotesTableData = ({ query }: { query: string }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [gridData, setGridData] = useState<any>({
    rows: [],
    columns: [],
  });

  const supplierQuotes = useSelector(selectSupplierQuotes);
  const supplierQuotesCounts = useSelector(selectSupplierQuotesCounts);
  const supplierQuotesLoading = useSelector(selectSupplierQuotesLoading);
  const supplierQuotesColumns = useMemo(
    () => getSupplierQuotesTableColumns(),
    [supplierQuotesLoading]
  );

  useEffect(() => {
    dispatch(
      workspaceNextActions.fetchSupplierQuotesRequest({
        query: `${query}&${generatePaginationAndSearchQuery({
          currentPage,
          pageSize: SHORT_PAGINATION_PAGE_SIZE,
        })}`,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, query]);

  useEffect(() => {
    setGridData({ rows: supplierQuotes, columns: supplierQuotesColumns });
  }, [supplierQuotes, supplierQuotesLoading, supplierQuotesColumns]);

  return {
    currentPage,
    setCurrentPage,
    gridData,
    count: supplierQuotesCounts?.count,
    loading: supplierQuotesLoading,
  };
};
