import React, { useState } from "react";
import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro-v5";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import EditIcon from "@mui/icons-material/Edit";
import { QuotationLineItemsTableField } from "./quotation-line-items-table.types";
import { FormikErrors } from "formik";
import { FormValues } from "../upload-quotation.form.types";

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: true,
  hideSortIcons: false,
  editable: false,
};

export const EditPriceCell: React.FC<
  GridRenderCellParams & {
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean
    ) => Promise<void | FormikErrors<FormValues>>;
    parts: any[];
  }
> = ({ value, setFieldValue, row, parts }) => {
  const [isEditing, setIsEditing] = useState(false);
  const partIndex = parts?.findIndex((part) => part.pk === row.pk);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsEditing(false);
    if (e.target.value === "") {
      return;
    }
    setFieldValue(`parts.[${partIndex}].price`, parseFloat(Number(e.target.value).toFixed(2)));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setIsEditing(false);
      if ((e.target as HTMLInputElement).value === "") {
        return;
      }
      setFieldValue(
        `parts.[${partIndex}].price`,
        parseFloat(Number((e.target as HTMLInputElement).value).toFixed(2))
      );
    }
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleOnChange = (value: string) => {
    setFieldValue(`parts.[${partIndex}].price`, parseFloat(Number(value).toFixed(2)));
  };

  return (
    <Box width={"100%"} display="flex" alignItems="center" onDoubleClick={handleDoubleClick}>
      {isEditing ? (
        <TextField
          value={value || 0}
          onChange={(e) => handleOnChange(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          type="number"
          variant="standard"
          size="small"
          InputProps={{
            disableUnderline: true,
            style: { fontSize: 14, marginTop: "3px", textAlign: "right" },
          }}
          inputProps={{ style: { textAlign: "right" } }}
          autoFocus
          fullWidth
        />
      ) : (
        <>
          {
            <IconButton size="small" onClick={handleEditClick}>
              <EditIcon fontSize="small" />
            </IconButton>
          }
          <Typography variant="body2" style={{ flexGrow: 1, textAlign: "right" }}>
            {value || 0}
          </Typography>
        </>
      )}
    </Box>
  );
};

const renderHeader = (params: GridColumnHeaderParams, extraText?: string) => {
  return (
    <CustomTypography
      variant="body2"
      color={"textSecondary"}
      sx={{ display: "flex", alignItems: "center" }}
    >
      {params?.colDef?.headerName}
      {extraText ? extraText : ""}
    </CustomTypography>
  );
};

export const getQuotationLineItemsTableColumns = ({
  setFieldValue,
  parts,
  currency,
  t,
}): GridColumns => {
  return [
    {
      ...defaultColumnConfig,
      field: QuotationLineItemsTableField.PART_NAME,
      headerName: t("rfq:quotations:quoteAsBuyerModal:item"),
      renderHeader: (params) => renderHeader(params),
      flex: 0.5,
    },
    {
      ...defaultColumnConfig,
      field: QuotationLineItemsTableField.QUANTITY,
      headerName: t("rfq:quotations:quoteAsBuyerModal:quantity"),
      renderHeader: (params) => renderHeader(params),
      flex: 0.5,
    },
    {
      ...defaultColumnConfig,
      field: QuotationLineItemsTableField.DESCRIPTION,
      headerName: t("rfq:quotations:quoteAsBuyerModal:description"),
      renderHeader: (params) => renderHeader(params),
      flex: 0.5,
    },
    {
      ...defaultColumnConfig,
      field: QuotationLineItemsTableField.UNIT_PRICE,
      type: "number",
      headerName: t("rfq:quotations:quoteAsBuyerModal:unitPrice"),
      renderHeader: (params) => renderHeader(params, ` (${currency})`),
      renderCell: (params) => (
        <EditPriceCell {...params} setFieldValue={setFieldValue} parts={parts} />
      ),
      flex: 0.5,
    },
  ];
};
