import React, { useMemo } from "react";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { Box, Typography } from "@mui/material";
import { formatTimeFromNow } from "@next/utils/dateTimeUtils";
import { AppTheme } from "layout/AppTheme";
import { CircularAvatarsList } from "@next/components/circular-avatars-list";
import { getUserDisplayName } from "@next/modules/profile/helpers/profile-helpers";
import { CustomTypography } from "@next/components/custom-typography";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { Notification } from "@next/modules/app/redux";
import { GenericUser } from "@next/modules/profile/redux";

const NotificationCell: React.FC<GridRenderCellParams> = (params) => {
  const notification: Notification = useMemo(() => params?.row as Notification, [params?.row?.id]);

  return (
    <Box p="16px" pl="6px" pr="6px" display="flex" flexDirection="column" height={92} width="100%">
      <Box flex="60%" display="flex" alignItems="center">
        {notification?.is_seen ? null : (
          <Box>
            <Box
              style={{
                width: 8,
                height: 8,
                borderRadius: 4,
                backgroundColor: AppTheme.palette.primary.main,
              }}
              mr="8px"
            />
          </Box>
        )}

        <Box>
          <CircularAvatarsList
            images={[
              {
                name: getUserDisplayName({
                  first_name: notification?.company?.name || "",
                }),
                tooltip: notification?.company?.name,
                url: notification?.company?.picture,
                avatarProps: {
                  variant: "rounded",
                },
              },
              {
                name: getUserDisplayName(notification?.created_by || ({} as GenericUser)),
                tooltip: notification?.created_by?.full_name,
                url: notification?.created_by?.full_name,
              },
            ]}
            overlapping
            combinedTooltip
            size="medium"
          />
        </Box>

        <Box width="100%" display="flex" flexDirection="column" ml="8px">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="caption">
              <b>{notification?.created_by?.full_name}</b>
              {` · ${notification?.company?.name}`}
            </Typography>

            <Typography variant="caption" style={{ color: AppTheme.palette.text.disabled }}>
              {formatTimeFromNow(notification?.created_at)}
            </Typography>
          </Box>

          <Typography variant="caption" color="textSecondary" style={{ lineHeight: 1.26 }}>
            {notification?.notification_type === "po"
              ? notification?.po?.name
              : notification?.rfq?.rfq_display_name}
          </Typography>
        </Box>
      </Box>

      <Box flex="40%" mt="6px" display="flex" justifyContent="space-between">
        <CustomTypography variant="caption" maxWidth={536} display="block">
          {notification?.body}
        </CustomTypography>

        {notification?.attachment_count > 0 ? (
          <Box display="flex" alignItems="center">
            <AttachmentIcon style={{ width: "16px", height: "16px", marginRight: 4 }} />

            <Typography variant="caption">{notification?.attachment_count}</Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export const getNotificationsTableColumns = () => {
  const _columns: GridColumns = [
    {
      disableColumnMenu: true,
      sortable: false,
      hideSortIcons: true,
      field: "notification",
      flex: 1,
      renderCell: NotificationCell,
    },
  ];

  return _columns;
};
