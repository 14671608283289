// General imports
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Material UI
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  InputAdornment,
  List,
  Tooltip,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import { Field } from "formik";
// Components
// this CurrencyField applies a currency format to a numeric expression and returns the result as a string.
import CurrencyField from "../../../../components/common/fields/CurrencyField";
import UploadQuotation from "../upload-quotation/upload-quotation.component";
import { FormikGenericTextFieldBlackBorder } from "../../../../components/css-text-field/css-text-field.component";
import { CurrencyFormatCustom } from "../currency-format-custom/currency-format-custom.component";
import { UploadedItem } from "./uploaded-item/uploaded-item.component";

//this function is triggered when clicking to upload file
import { FileUploadAction } from "../../../../helpers/FileUploadAction";
import { useQuotationViewData } from "../quotation-view-hooks";
import { getIsEditQuotationMode } from "../../../../services/quotation/quotation.selectors";

export const SubTotalFees = (props) => {
  const {
    setUploads,
    uploads,
    onUploadSelectionChange,
    removeFile,
    selectedIndex,
    t,
    onFeesFieldChange,
    totalPrice,
    setFieldValue,
    values,
    nda,
    subTotalPrice,
  } = props;

  const { isDetailsExtraFieldsAvailable } = useQuotationViewData();

  // use this state set uploading progress
  const [isUploadingInProgress, setIsUploadingInProgress] = useState(false);

  const isEditQuotationMode = useSelector(getIsEditQuotationMode);

  // Update the fees state on fees' input field change event.
  const _onFeesFieldChange = (e) => {
    let feesValue = e.target.value ? parseFloat(e.target.value.replace(",", ".")) : 0;
    onFeesFieldChange(feesValue);
    setFieldValue("fixedFees", feesValue ? feesValue : 0);
  };

  useEffect(() => {
    onFeesFieldChange(values["fixedFees"] ? Number(values["fixedFees"]) : 0);
    setFieldValue("fixedFees", values["fixedFees"] ? Number(values["fixedFees"]) : 0);
  }, [values]);

  const textFieldProps = {
    onChange: _onFeesFieldChange,
    className: `c-subtotal-fees--price-field ${nda ? "nda-form-disable" : ""}`,
    variant: "outlined",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyFormatCustom,
      startAdornment: <InputAdornment position="end">$</InputAdornment>,
    },
  };

  return (
    <Grid item xs={12} className="c-subtotal-fees">
      <div className={`c-subtotal-fees--container ${isEditQuotationMode && "flex-direction-left"}`}>
        <Card elevation={0} className="c-subtotal-fees--card-upload">
          <CardContent>
            <Grid container>
              <Grid item>
                <Box display="flex" alignItems="center" className="c-subtotal-fees--title" mt={2}>
                  <Box textAlign="left" fontWeight="fontWeightMedium" fontSize="h5.fontSize">
                    {t("quotationFileOptional")}
                  </Box>
                  <Box className="c-subtotal-fees--box">
                    <Tooltip title={t("uploadYourPdfFilesAndAttachThemToYourQuotation")}>
                      <Avatar className="c-subtotal-fees--avatar">i</Avatar>
                    </Tooltip>
                  </Box>
                </Box>
                <List
                  fullWidth
                  component="nav"
                  aria-label="part name"
                  className="c-subtotal-fees--list"
                >
                  {uploads.map((uploadInfo) => {
                    return (
                      <UploadedItem
                        uploadInfo={uploadInfo}
                        onUploadSelectionChange={onUploadSelectionChange}
                        onFileRemove={removeFile}
                        selectedIndex={selectedIndex}
                      />
                    );
                  })}
                </List>
                {isUploadingInProgress ? (
                  <Box
                    className="c-subtotal-fees--circular-progress"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress color="grey" />
                  </Box>
                ) : (
                  <Box
                    className={`c-subtotal-fees--upload-background ${nda && "nda-form-disable"} `}
                    display="flex"
                    p={1}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Box ml={-1} mb={2}>
                      <Avatar
                        aria-label="upload"
                        onClick={FileUploadAction}
                        className="c-subtotal-fees--upload-avatar"
                      >
                        <ArrowDownwardIcon />
                      </Avatar>
                    </Box>

                    <Box className="c-subtotal-fees--box-margin">
                      <Box ml={1} pt={6} fontSize={20} fontWeight="fontWeightMedium">
                        {t("addASubmissionFile")}
                      </Box>
                      <Box>
                        <UploadQuotation
                          labelIdle={`${t(
                            "dragYourFilesOr"
                          )} <span id="fileInputField" class="filepond--label-action">${t(
                            "browse"
                          )}</span>`}
                          setUploads={setUploads}
                          setIsUploadingInProgress={setIsUploadingInProgress}
                          t={t}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {!isDetailsExtraFieldsAvailable && (
          <Card elevation={0} className="c-subtotal-fees--card-subtotal">
            <CardContent className="c-subtotal-fees--card-subtotal-content">
              <Grid container direction="row" justifyContent="space-" alignItems="center">
                <Grid item xs={6}>
                  <Box textAlign="left" fontWeight="fontWeightRegular" fontSize={16}>
                    {t("subTotal")}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box textAlign="right" fontWeight="fontWeightMedium" fontSize="h6.fontSize">
                    <CurrencyField value={subTotalPrice} />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            <CardContent className="c-subtotal-fees--card-fees-content">
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={4}>
                  <Box display="flex" alignItems="center">
                    <Box textAlign="left" fontWeight="fontWeightRegular" fontSize={16}>
                      {t("fixedFees")}
                    </Box>
                    <Box className="c-subtotal-fees--box-fees">
                      <Tooltip title={t("costsAssociatedWithProgrammingTime")}>
                        <Avatar className="c-subtotal-fees--avatar">i</Avatar>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={FormikGenericTextFieldBlackBorder}
                    name="fixedFees"
                    textFieldProps={
                      isEditQuotationMode
                        ? {
                            ...textFieldProps,
                            value: Number(values["fixedFees"]),
                          }
                        : textFieldProps
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box textAlign="right" fontWeight="fontWeightMedium" fontSize="h6.fontSize">
                    <CurrencyField value={totalPrice} />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </div>
    </Grid>
  );
};
