import React from "react";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DownloadIcon from "@mui/icons-material/GetApp";
import { useTranslation } from "react-i18next";
import { formatTimeFromNow } from "@next/utils/dateTimeUtils";
import { AppTheme } from "layout/AppTheme";
import { Box, Typography } from "@mui/material";
import StatusVariantTag from "../status-variant-tag/status-variant-tag";
import { CustomTooltip } from "@next/modules/marketplace/components/intent-to-bid/intent-to-bid.definitions";
import { truncate } from "lodash";
import { grey } from "@mui/material/colors";
import i18next from "i18next";
import { SupplierStatusInfo } from "../suppliers-table/suppliers-table-v5.types";

type StatusInfoProps = {
  status: string;
  itbDate?: string;
  itnbReason?: string;
  downloadedAt?: string;
  viewedAt?: string;
};

const renderStatusIcon = (statusInfo: StatusInfoProps) => {
  const theme = AppTheme;

  switch (statusInfo?.status) {
    case SupplierStatusInfo.ITB:
      return <WatchLaterIcon style={{ color: theme.palette.primary.main }} fontSize="small" />;

    case SupplierStatusInfo.ITNB:
      return <CancelIcon color="error" fontSize="small" />;

    case SupplierStatusInfo.QUOTED:
      return <CheckIcon style={{ color: theme.palette.success.main }} fontSize="small" />;

    case SupplierStatusInfo.WAITING:
      return statusInfo?.downloadedAt ? (
        <DownloadIcon style={{ color: theme.palette.grey[500] }} fontSize="small" />
      ) : statusInfo?.viewedAt ? (
        <VisibilityIcon style={{ color: theme.palette.grey[500] }} fontSize="small" />
      ) : (
        <VisibilityOffIcon style={{ color: theme.palette.grey[500] }} fontSize="small" />
      );
  }
};

const getStatusText = (statusInfo: StatusInfoProps, t: i18next.TFunction) => {
  switch (statusInfo?.status) {
    case SupplierStatusInfo.ITB:
      return t("workspaceNext:supplierTable:row:intentToQuote", {
        itbDate: statusInfo?.itbDate,
      });

    case SupplierStatusInfo.ITNB:
      return `${t("workspaceNext:supplierTable:row:noQuote")} · ${statusInfo?.itnbReason || ""}`;

    case SupplierStatusInfo.QUOTED:
      return t("workspaceNext:supplierTable:row:quoted");

    case SupplierStatusInfo.WAITING:
      return statusInfo?.viewedAt && !statusInfo?.downloadedAt
        ? t("workspaceNext:supplierTable:row:viewed", {
            viewedAt: formatTimeFromNow(statusInfo?.viewedAt),
          })
        : statusInfo?.downloadedAt
          ? t("workspaceNext:supplierTable:row:viewedAndDownloaded", {
              downloadedAt: formatTimeFromNow(statusInfo?.downloadedAt),
            })
          : t("workspaceNext:supplierTable:row:notViewed");

    default:
      return t("workspaceNext:supplierTable:row:notViewed");
  }
};

interface Props {
  statusInfo: Record<string, any>;
}

const SupplierStatus: React.FC<Props> = ({ statusInfo }) => {
  const { t } = useTranslation();

  const statusInfoObj = {
    ...(statusInfo || {}),
    status: statusInfo?.status,
    itbDate: statusInfo?.itb_date,
    itnbReason: statusInfo?.itnb_reason,
    downloadedAt: statusInfo?.downloaded_at,
    viewedAt: statusInfo?.viewed_at,
    isRevisionRequested: statusInfo?.is_revision_requested,
    isAwarded: statusInfo?.is_awarded,
  };
  const statusIcon = renderStatusIcon(statusInfoObj);
  const statusText = getStatusText(statusInfoObj, t);

  return statusInfoObj.isRevisionRequested || statusInfoObj.isAwarded ? (
    <Box display="flex" alignItems="center" height={22}>
      <StatusVariantTag variant={statusInfoObj.isAwarded ? "awarded" : "revision-requested"} />
    </Box>
  ) : (
    <Box display="flex" alignItems="center">
      {statusIcon ? (
        <Box mr={0.5} lineHeight={1.5}>
          {statusIcon}
        </Box>
      ) : null}
      <CustomTooltip title={statusText.length > 25 ? statusText : ""}>
        <Typography
          variant="caption"
          style={{
            color: SupplierStatusInfo.QUOTED !== statusInfoObj.status ? grey[600] : "inherit",
            marginTop: 2,
          }}
        >
          {truncate(statusText, { length: 50 })}
        </Typography>
      </CustomTooltip>
    </Box>
  );
};

export default SupplierStatus;
