import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { calculateDateRange } from "../components/analytic-filters/analytic-filters.utils";
import {
  AnalyticFilters,
  AnalyticsState,
  FetchOrdersReportInput,
  FetchRequestsReportInput,
  OrdersReport,
  RequestsReport,
  SupplierReport,
} from "./types";

const dateObj = calculateDateRange("yearToDate");

export const initialState: AnalyticsState = {
  requestsReportLoading: false,
  requestsReportError: undefined,
  requestsReport: undefined,

  ordersReportLoading: false,
  ordersReportError: undefined,
  ordersReport: undefined,

  supplierReportLoading: false,
  supplierReportError: undefined,
  supplierReport: undefined,

  analyticFilters: {
    selectedSuppliers: [],
    timeframe: {
      key: "yearToDate",
      start: dateObj?.start,
      end: dateObj?.end,
    },
  },
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    fetchRequestsReportRequest: (state, action: PayloadAction<FetchRequestsReportInput>) => {
      state.requestsReportLoading = true;
      state.requestsReportError = undefined;
      state.requestsReport = undefined;
    },
    fetchRequestsReportFailure: (state, action: PayloadAction<Error>) => {
      state.requestsReportLoading = false;
      state.requestsReportError = action.payload;
    },
    fetchRequestsReportSuccess: (state, action: PayloadAction<RequestsReport>) => {
      state.requestsReportLoading = false;
      state.requestsReportError = undefined;
      state.requestsReport = action.payload;
    },
    fetchOrdersReportRequest: (state, action: PayloadAction<FetchOrdersReportInput>) => {
      state.ordersReportLoading = true;
      state.ordersReportError = undefined;
      state.ordersReport = undefined;
    },
    fetchOrdersReportFailure: (state, action: PayloadAction<Error>) => {
      state.ordersReportLoading = false;
      state.ordersReportError = action.payload;
    },
    fetchOrdersReportSuccess: (state, action: PayloadAction<OrdersReport>) => {
      state.ordersReportLoading = false;
      state.ordersReportError = undefined;
      state.ordersReport = action.payload;
    },
    fetchSupplierReportRequest: (state) => {
      state.supplierReportLoading = true;
      state.supplierReportError = undefined;
      state.supplierReport = undefined;
    },
    fetchSupplierReportFailure: (state, action: PayloadAction<Error>) => {
      state.supplierReportLoading = false;
      state.supplierReportError = action.payload;
    },
    fetchSupplierReportSuccess: (state, action: PayloadAction<SupplierReport>) => {
      state.supplierReportLoading = false;
      state.supplierReportError = undefined;
      state.supplierReport = action.payload;
    },

    setAnalyticFilters: (state, action: PayloadAction<AnalyticFilters>) => {
      state.analyticFilters = action.payload;
    },
  },
});

export const analyticsActions = analyticsSlice.actions;

export default analyticsSlice.reducer;
