import React, { useState } from "react";
import { PublicRfqLandingStepSuccess } from "./public-rfq-landing-step-success";
import PublicRfqLandingStepForm from "./public-rfq-landing-step-form/public-rfq-landing-step-form";
import { useDispatch } from "react-redux";
import { DiscordChannel, workspaceNextActions } from "@next/modules/workspace/redux";
import { PublicRFQFormValuesType } from "./public-rfq-landing-step-form/public-rfq-landing-step-form.types";

export const PublicRfqLandingSteps = () => {
  const [submittedData, setSubmittedData] = useState<PublicRFQFormValuesType | undefined>();

  const dispatch = useDispatch();

  const onSubmit = (values: PublicRFQFormValuesType) => {
    const formattedValues = {
      ...values,
      attachments: values?.attachments?.map((attach: any) => attach?.file) as any,
    };

    dispatch(
      workspaceNextActions.createDiscordNotifyRequest({
        title: "PUBLIC_RFQ_CREATED",
        channel_id: DiscordChannel.BUYER_ACTIVITY,
        ...formattedValues,
      })
    );

    setSubmittedData(values);
  };

  const resetProcess = () => {
    setSubmittedData(undefined);
  };

  return (
    <>
      {!!submittedData ? (
        <PublicRfqLandingStepSuccess resetProcess={resetProcess} submittedData={submittedData} />
      ) : (
        <PublicRfqLandingStepForm onSubmit={onSubmit} />
      )}
    </>
  );
};
