import React from "react";
import { Badge, BadgeProps, styled } from "@mui/material";

type OthersProps = {
  right?: string;
  top?: string;
};

const useStyledBadge = ({ right, top }: OthersProps) => {
  const styledBadge = React.useMemo(() => {
    return styled(Badge)<BadgeProps>(() => ({
      "& .MuiBadge-badge": {
        right: right,
        top: top,
        marginTop: -1,
      },
      "&.MuiBadge-root": {
        alignItems: "center",
      },
    }));
  }, [right, top]);
  return styledBadge;
};

export { useStyledBadge };
