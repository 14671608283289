import { Box } from "@mui/material";
import { GenericTextInput } from "components/utils/input/text";
import { Form, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { CreateOrderFormState } from "./create-order-form.definitions";
import { useSelector } from "react-redux";
import { selectCreateOrderLoading } from "../../redux/selectors";
import CustomButton from "@next/components/custom-button/custom-button";

export const CreateOrderFormBase: React.FC<
  FormikProps<CreateOrderFormState> & {
    onClose: () => void;
    isManualOrder?: boolean;
  }
> = ({ handleSubmit, values, onClose, isManualOrder }) => {
  const { t } = useTranslation();
  const createOrderLoading = useSelector(selectCreateOrderLoading);

  return (
    <Form onSubmit={handleSubmit}>
      <Box mt="24px">
        <GenericTextInput
          name="orderName"
          label={t(
            isManualOrder ? "orders:createManualOrder:name" : "orders:sendOrderDrawer:orderNumber"
          )}
          value={values.orderName}
          autoComplete="off"
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" mt="24px">
        <Box mr="10px">
          <CustomButton variant="outlined" onClick={onClose}>
            {t("common:cancel")}
          </CustomButton>
        </Box>

        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          disabled={values.orderName === ""}
          loading={createOrderLoading}
        >
          {t("common:create")}
        </CustomButton>
      </Box>
    </Form>
  );
};
