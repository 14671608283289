import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { EditProfileFormBase } from "./edit-user-profile-form.base";
import { EditProfileFormValues } from "./edit-user-profile-form.types";
import { validationSchema } from "./edit-user-profile-form.definitions";
import { getUser } from "services/profile/profile.selectors";
import { profileNextActions } from "@next/modules/profile/redux";

const EditUserProfileForm: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const initialValues: EditProfileFormValues = {
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    picture: user?.picture || "",
  };

  const handleSubmitCompanyInformation = (values: EditProfileFormValues) => {
    dispatch(profileNextActions.updateUserProfileRequest(values));
  };

  return (
    <Formik
      render={(props) => <EditProfileFormBase {...props} />}
      initialValues={initialValues}
      onSubmit={handleSubmitCompanyInformation}
      validationSchema={validationSchema}
    />
  );
};

export default EditUserProfileForm;
