import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import FlashOnRoundedIcon from "@mui/icons-material/FlashOnRounded";
import { IBusinessProfileMissingProps } from "services/pay/pay.model";

export const BusinessProfileMissing: React.FC<IBusinessProfileMissingProps> = ({
  onboardMsgBtn,
  onOnboardButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="body1">{t("pay:businessProfileMissing:title")}</Typography>
      <Box m={2} />
      <Box display="flex">
        <Box width={250} whiteSpace="nowrap">
          <Typography variant="body1">
            <CreditCardIcon fontSize="large" />
            {t("pay:businessProfileMissing:noMoreLatePayments")}
          </Typography>
        </Box>
        <Box width={350} whiteSpace="nowrap">
          <Typography variant="body1">
            <FlashOnRoundedIcon fontSize="large" />
            {t("pay:businessProfileMissing:payOutPercentage")}
          </Typography>
        </Box>
      </Box>
      <Box m={2} />
      <Button color="secondary" variant="contained" fullWidth={true} onClick={onOnboardButtonClick}>
        {!onboardMsgBtn ? <CircularProgress size={24} style={{ color: "white" }} /> : onboardMsgBtn}
      </Button>
    </Box>
  );
};
