import { GridCellParams, MuiEvent } from "@mui/x-data-grid-pro-v5";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { SHORT_PAGINATION_PAGE_SIZE } from "@next/constants";
import { makeStyles } from "@mui/styles";
import { useCompanyMembersTableData } from "./company-members-table.hooks";
import { CompanyMembersField } from "./company-members.types";
import { CompanyMembersTableActionMenu } from "./company-members-table-action-menu";
import { getRowIdToPk } from "@next/utils/dataGridUtils";
import { Box } from "@mui/material";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";
import { useTranslation } from "react-i18next";
import { profileNextActions } from "@next/modules/profile/redux";
import CustomButton from "@next/components/custom-button";
import AddIcon from "@mui/icons-material/Add";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

const useStyles = makeStyles(() => ({
  root: {
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: 0,
    },
  },
  columnHeader: {
    background: "none",
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
}));

export const CompanyMembersTable: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | Element>(null);
  const [menuActiveData, setMenuActiveData] = useState<any>();

  const { gridData } = useCompanyMembersTableData();

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent<Element, MouseEvent>>
  ) => {
    if (
      params?.field === CompanyMembersField.ACTION_BUTTON &&
      params?.row?.invitation_status !== "accepted"
    ) {
      setMenuAnchorEl(event.currentTarget);
      setMenuActiveData(params.row);
    }
  };

  const onClickResendInvite = () => {
    setMenuAnchorEl(null);
    dispatch(
      profileNextActions.resendMemberInviteRequest({
        email: menuActiveData?.email || menuActiveData?.username,
        language: menuActiveData?.language || "en",
      })
    );
  };

  const onSendInviteButtonClick = () => {
    dispatch(
      modalsActions.showModal({
        key: SharedModalTypes.INVITE_COMPANY_USER_FORM,
      })
    );
  };

  return (
    <>
      <Box mt={"24px"} mb={"24px"}>
        <CustomButton
          variant="contained"
          startIcon={<AddIcon />}
          onClick={onSendInviteButtonClick}
          className="c-company-members__sendInvite"
        >
          {t("userCompany:invite")}
        </CustomButton>
      </Box>
      <DataGridProV5
        pagination
        autoHeight
        paginationMode="client"
        pageSize={SHORT_PAGINATION_PAGE_SIZE}
        getRowId={getRowIdToPk}
        disableSelectionOnClick
        classes={classes}
        rowHeight={61}
        onCellClick={onCellClick}
        rows={gridData?.rows || []}
        columns={gridData?.columns || []}
      />

      {/* This menu appears only 
          when CompanyMembersField.Action_BUTTON is in columns. 
      */}
      <CompanyMembersTableActionMenu
        anchorEl={menuAnchorEl}
        setAnchorEl={setMenuAnchorEl}
        onClickResendInvite={onClickResendInvite}
      />
    </>
  );
};
