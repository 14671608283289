// URL
import { backendUrl } from "../../urls";
import { handleResponse } from "../../helpers/responseHandling";
import { axiosApi } from "@next/api";

function sendNPS(token, nps_token, has_dismissed, score) {
  const payload = has_dismissed ? { has_dismissed } : { score };

  const url = `${backendUrl.nps}${nps_token}/`;
  return axiosApi.patch(url, payload).then(handleResponse);
}

export const npsApi = {
  sendNPS,
};
