import { GenericObject } from "@next/modules/profile/redux";
import { t } from "assets/configi18n/i18n";

export enum DelayUnitType {
  DAY = "d",
  WEEK = "w",
  MOUTH = "m",
}

export const getLeadTimeDelayUnit = (quoteDelay: number, delayUnit: string) => {
  const leadTime: GenericObject = {
    d: `${t("quotation:workingDay", {
      count: quoteDelay,
    })}`,
    w: `${t("quotation:week", {
      count: quoteDelay,
    })}`,
    m: `${t("quotation:mouth", {
      count: quoteDelay,
    })}`,
  };
  return leadTime[delayUnit];
};
