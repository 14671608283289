import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  SvgIcon,
  Theme,
  Typography,
} from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { Language } from "@mui/icons-material";

import { history } from "helpers/history";
import { frontendUrl } from "urls";
import { modalsActions } from "services/modals/modals.actions";
import { getIsNewUser, getUser } from "services/profile/profile.selectors";
import { CompanyTypeNext } from "@next/redux/types";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";

import { UserProfileSettingsModal } from "@next/modules/profile/modals/user-profile-settings-modal";
import { modalsActions as modalsActionsNext } from "@next/redux/modalsSlices";
import { ProfileModalTypes } from "@next/modules/profile/modals/types";
import SettingsIcon from "@mui/icons-material/Settings";
import { UserProfileTwoFactorManageModal } from "@next/modules/profile/modals/user-profile-two-factor-manage-modal";
import { modalsActions as nextModalActions } from "@next/redux/modalsSlices";
import { AnnouncementModalTypes } from "@next/modules/announcement/modals/types";
import { createStyles, makeStyles } from "@mui/styles";
import { LANGUAGE, useLanguageToggle } from "@next/hooks/useLanguageToggle";
import { SgvPathIcons } from "assets/svg-path-icons";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { width: "344px" },
    menuItem: {
      height: "50px",
    },
    menuItemText: { marginLeft: theme.spacing(1) },
  })
);

export const UserProfileMenu = () => {
  const classes = useStyles();
  const { showModal } = modalsActions;
  const dispatch = useDispatch();
  const axyaTracking = useAxyaTracking();
  const location = useLocation();
  const { t } = useTranslation();
  const axyaTrack = useAxyaTracking();
  const user = useSelector(getUser);
  const isNewUser = useSelector(getIsNewUser);
  const { activeLanguage, handleChange: toggleLanguage } = useLanguageToggle();

  useEffect(() => {
    //check if announcement model is visited and hide the workspace tour
    const isModalOpen = localStorage.getItem("isAxyaAnnouncementVisited");
    if (
      isNewUser &&
      user &&
      user?.type === CompanyTypeNext.DO &&
      location &&
      location.pathname.includes("/my-parts") &&
      !isModalOpen
    ) {
      dispatch(showModal(true, "isAppTourOpen"));
    }
  }, [isNewUser]);

  const onClickSettings = () => {
    dispatch(
      modalsActionsNext.showModal({
        key: ProfileModalTypes.USER_PROFILE_SETTINGS_MODAL,
      })
    );
  };

  const onClickDiscoverThePlatform = () => {
    dispatch(showModal(true, "isAppTourOpen"));
    // tracking open app tour click
    axyaTrack({
      scout_category: "tour_guide",
      scout_feature_name: "open_app_tour",
    });
  };

  const onClickWhatsNew = () => {
    dispatch(
      nextModalActions.showModal({
        key: AnnouncementModalTypes.WHATS_NEW_MODAL,
        data: {
          userOpen: true,
        },
      })
    );
  };

  const onClickOpenGiveYouFeedbackModal = () => {
    dispatch(
      nextModalActions.showModal({
        key: WorkspaceModalTypes.CONTACT_US_MODAL,
        data: {
          modalTitle: t("contactUs:giveYouFeedback"),
          hasFormTitle: true,
          formTitle: t("contactUs:giveYouFeedbackFormTitle"),
          label: t("contactUs:giveYouFeedbackInputLabel"),
        },
      })
    );
    axyaTracking({
      scout_category: "contact-us",
      scout_feature_name: "open-give-you-feedback-modal-from-app-bar",
    });
  };

  const onClickLogout = () => history.push(frontendUrl.logout);

  return (
    <div className={classes.root}>
      <ListItem>
        <ListItemAvatar>
          <Avatar alt="Photo de profile" src={user?.picture} variant="circular" />
        </ListItemAvatar>

        <ListItemText
          primary={<Typography>{`${user?.first_name} ${user?.last_name}`}</Typography>}
          secondary={<Typography>{user?.email || user?.username}</Typography>}
        />
      </ListItem>

      <Divider style={{ margin: "8px" }} />

      <MenuItem className={classes.menuItem} onClick={onClickSettings}>
        <SettingsIcon />
        <Typography className={classes.menuItemText}>
          {t("profile:menu:profileSettings")}
        </Typography>
      </MenuItem>
      {/* <LanguageToggle
        customNode={
          <MenuItem className={classes.menuItem}>
          <Language style={{ color: "black" }} />
            <Typography className={classes.menuItemText}>
              {t("profile:menu:language")}
            </Typography>
          </MenuItem>
        }
      /> */}
      <MenuItem
        className={classes.menuItem}
        onClick={toggleLanguage}
        data-value={activeLanguage === LANGUAGE.FR ? LANGUAGE.EN : LANGUAGE.FR}
      >
        <Language style={{ color: "black" }} />
        <Typography className={classes.menuItemText}>
          {activeLanguage === LANGUAGE.FR
            ? t("profile:menu:switchToEnglish")
            : t("profile:menu:switchToFrench")}
        </Typography>
      </MenuItem>

      <Divider sx={{ margin: 0 }} />

      <MenuItem className={classes.menuItem} onClick={onClickWhatsNew}>
        <WbSunnyIcon />
        <Typography className={classes.menuItemText}>{t("common:whatsNew")}</Typography>
      </MenuItem>

      <MenuItem className={classes.menuItem} onClick={onClickOpenGiveYouFeedbackModal}>
        <SvgIcon>
          <path d={SgvPathIcons.SVG_PATH_CAMPAIGN_ICON} />
        </SvgIcon>
        <Typography className={classes.menuItemText}>
          {t("profile:menu:giveYourFeedback")}
        </Typography>
      </MenuItem>

      <Divider sx={{ margin: 0 }} />

      <MenuItem className={classes.menuItem} onClick={onClickLogout}>
        <PowerSettingsNewIcon />
        <Typography className={classes.menuItemText}>{t("SIDE_BAR:logout")}</Typography>
      </MenuItem>

      <UserProfileSettingsModal />
      <UserProfileTwoFactorManageModal />
    </div>
  );
};
