import { Box, Button, IconButton, SnackbarContent } from "@mui/material";
import { CustomContentProps, useSnackbar } from "notistack";
import React, { forwardRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

export type CustomSnackbarProps = {
  buttonText?: string;
  buttonCallback?: () => void;
  buttonRedirectTo?: string;
  ButtonComponent?: JSX.Element;
  disableCloseButton?: boolean;
} & CustomContentProps;

export const CustomSnackbar: React.FC<CustomSnackbarProps> = forwardRef<
  HTMLDivElement,
  CustomSnackbarProps
>(
  (
    {
      id,
      message,
      buttonText,
      buttonCallback,
      buttonRedirectTo,
      disableCloseButton,
      ButtonComponent,
    },
    ref
  ) => {
    const { closeSnackbar } = useSnackbar();

    const handleClose = () => {
      closeSnackbar(id);
    };

    const delayClose = () => {
      setTimeout(() => {
        closeSnackbar(id);
      }, 100);
    };

    return (
      <SnackbarContent
        ref={ref}
        message={message}
        action={
          <React.Fragment>
            {ButtonComponent ? (
              ButtonComponent
            ) : buttonText ? (
              <Box mr="8px" onClick={delayClose}>
                {buttonCallback ? (
                  <Button color="inherit" onClick={buttonCallback}>
                    {buttonText}
                  </Button>
                ) : (
                  <Link
                    to={buttonRedirectTo || "#"}
                    style={{ textDecoration: "underline", color: "inherit" }}
                    replace
                  >
                    {buttonText}
                  </Link>
                )}
              </Box>
            ) : null}

            {!disableCloseButton ? (
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            ) : null}
          </React.Fragment>
        }
      />
    );
  }
);
