import React from "react";
import { frontendUrl } from "urls";
import { Redirect } from "react-router-dom";

const DummyLayout: React.FC<any> = ({ children }) => {
  return children;
};

const ROOT_ROUTE = [
  {
    // /
    layout: DummyLayout,
    path: frontendUrl.home,
    key: "root",
    exact: true,
    component: (props: any) => {
      const { profile } = props;
      // If authenticated, but user has no company.
      if (profile.token && profile.company && !profile.company.id) {
        return <Redirect to={frontendUrl.joinOrRegisterCompany} />;
      }

      if (profile.token && profile.company && !profile.company.is_active) {
        return <Redirect to="/company" />;
      }

      // If user is connected, just redirect it to the auth
      // route which will determine to where the user
      // should go next.
      if (profile.token && profile.company) {
        return <Redirect to={frontendUrl.auth} />;
      }

      return <Redirect to={frontendUrl.login} />;
    },
  },
];

export default ROOT_ROUTE;
