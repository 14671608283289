import React, { useEffect, useRef, useState } from "react";
import { Menu, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { CustomTypography } from "@next/components/custom-typography";
import { FormFileAttachmentButton } from "../../../form-file-attachment-button/form-file-attachment-button";
import { FormAttachedFileBox } from "../../../form-attached-file-box/form-attached-file-box";
import * as S from "./evidence-menu.styled";

type Props = {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  row: any;
  fieldComment: string;
  fieldFile: string;
  handleSaveCommentAndFile: (comment: string, file: File) => void;
  isTableDisabled?: boolean;
};

export const EvidenceMenu: React.FC<Props> = ({
  anchorEl,
  setAnchorEl,
  row,
  fieldComment,
  fieldFile,
  handleSaveCommentAndFile,
  isTableDisabled,
}) => {
  const openMenu = Boolean(anchorEl);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | "">("");
  const [comment, setComment] = useState<string>("");

  useEffect(() => {
    if (openMenu) {
      setFile(row[fieldFile] || "");
      setComment(row[fieldComment] || "");
    }
  }, [openMenu, row]);

  const handleCloseMenuItem = () => {
    setAnchorEl(null);
  };

  const handleAttachButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleSave = () => {
    handleSaveCommentAndFile(comment, file as any);
  };

  const handleClickRemoveFile = () => {
    handleSaveCommentAndFile(comment, "" as any);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleCloseMenuItem}
    >
      <S.StyledParentBox>
        {!file ? (
          !isTableDisabled ? (
            <S.StyledBox>
              <FormFileAttachmentButton
                handleAttachButtonClick={handleAttachButtonClick}
                handleFileChange={handleFileChange}
                fileInputRef={fileInputRef}
                btnText={"File"}
              />

              <S.StyledInfoTypography variant="body2" tooltipTitle={null}>
                ZIP to attach multiple files.
              </S.StyledInfoTypography>
            </S.StyledBox>
          ) : null
        ) : (
          <S.StyledBox>
            <FormAttachedFileBox
              file={file as File}
              handleClickRemove={handleClickRemoveFile}
              isFormDisabled={isTableDisabled}
            />
          </S.StyledBox>
        )}

        {!isTableDisabled ? (
          <TextField
            placeholder="Comment"
            label={null}
            value={comment}
            onChange={handleCommentChange}
            multiline
            minRows={2}
            maxRows={4}
            onKeyDown={onKeyDown}
          />
        ) : comment ? (
          <S.StyledCommentTypography variant="body2" tooltipTitle={null}>
            {comment}
          </S.StyledCommentTypography>
        ) : null}

        {!isTableDisabled ? (
          <S.StyledFooterBox>
            <S.StyledSaveButton
              startIcon={<CheckIcon sx={{ width: "24px", height: "24px" }} />}
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={comment.length === 0 && !file ? true : false}
              disableElevation
            >
              <CustomTypography variant="body2" tooltipTitle={null}>
                Save
              </CustomTypography>
            </S.StyledSaveButton>
          </S.StyledFooterBox>
        ) : null}
      </S.StyledParentBox>
    </Menu>
  );
};
