import { Box, styled } from "@mui/material";

type Props = {
  isDrawerOpen: boolean;
};

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isDrawerOpen",
})<Props>(({ isDrawerOpen }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: isDrawerOpen ? "70%" : "calc(100% - 72px)",
  transition: "width 0.2s ease",
}));
export default StyledBox;
