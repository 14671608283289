import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { Divider, Drawer } from "@mui/material";
import { WorkspaceModalTypes } from "../types";
import { QuoteComparisonAwardDrawerHeader } from "./quote-comparison-award-drawer-header";
import { QuoteComparisonAwardDrawerContent } from "./quote-comparison-award-drawer-content";
import { QuoteComparisonAwardDrawerFooter } from "./quote-comparison-award-drawer-footer";

export const QuoteComparisonAwardDrawer = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(WorkspaceModalTypes.QUOTE_COMPARISON_AWARD_DRAWER));

  const onClose = () => {
    dispatch(modalsActions.closeModal(WorkspaceModalTypes.QUOTE_COMPARISON_AWARD_DRAWER));
  };

  return (
    <Drawer PaperProps={{ style: { width: 582 } }} anchor="right" open={!!modal} onClose={onClose}>
      <QuoteComparisonAwardDrawerHeader onClose={onClose} />

      <Divider />

      <QuoteComparisonAwardDrawerContent />

      <Divider />

      <QuoteComparisonAwardDrawerFooter />
    </Drawer>
  );
};
