// Helper functions
import { history } from "helpers/history";

// Services
import { profileService } from "./profile.api";

// Constants for Redux
import { profileConstants } from "./profile.constants";

// Urls for redirection
import { frontendUrl } from "urls";

import { modalsActions } from "../modals/modals.actions";

import i18n from "assets/configi18n/i18n";
import { AmplitudeEvent, sendAmplitudeData } from "@next/utils/amplitudeUtils";
import { genericErrorHandler } from "@next/utils/apiUtils";
import snackbarUtils from "@next/utils/snackbarUtils";

const t = i18n.t.bind(i18n);

export const profileActions = {
  createCompany,
  editCompany,
  fetchUserProfile,
  register,
  recover,
  resetPassword,
  refreshToken,
  login,
  loginCode,
  logout,
  fetchCompany,
  resendVerification,
  createProcess,
  createStandard,
  createRating,
  isEmailSubscribed,
  fetchNotifications,
  markNotificationAsRead,
  addFileToCompanyStorage,
  deleteFileFromStorage,
  addCompanyToProfile,
  resetLoginState,
  decrementCompanyStatsNewQaMsgCount,
  updateUserState,
  setTokens,
  addUserInCompany,
  setLoginSuccess,
  decrementCompanyStatsPendingConnectionsCount,
  fetchProfilePreferences,
  editProfilePreferences,
};

// Authentication Actions
function register(user) {
  return (dispatch) => {
    dispatch(request(user));
    profileService
      .register(user)
      .then((responseUser) => {
        dispatch(success(responseUser, true));
        dispatch(profileActions.login(user.email, user.password1));
      })
      .catch((error) => {
        genericErrorHandler(JSON.stringify(error?.response?.data));
        dispatch(failure(error?.toString()));
      });
  };

  function request(user) {
    return { type: profileConstants.REGISTER_REQUEST, user };
  }
  function success(user, keepLoading) {
    return { type: profileConstants.REGISTER_SUCCESS, user, keepLoading };
  }
  function failure(error) {
    return { type: profileConstants.REGISTER_FAILURE, error };
  }
}

function resetLoginState() {
  return (dispatch) => {
    dispatch(reset());
  };

  function reset() {
    return { type: profileConstants.RESET_LOGIN_STATE };
  }
}

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username })); //TODO FIX THIS

    profileService.login(username, password).then(
      (data) => {
        // If the ephemeral token is present, it means that the user is logging in with 2FA enabled
        if (data?.ephemeral_token && data?.method === "email") {
          history.push(`${frontendUrl.login2FA}/${data?.ephemeral_token}`);
        } else {
          sendAmplitudeData(AmplitudeEvent.LOGIN, data);
          dispatch(success(data));
        }
      },
      (error) => {
        dispatch(failure(error?.toString()));

        if (
          error?.response?.status === 400 &&
          error.response.data?.message?.[0] === "Subscription expired"
        ) {
          //This is required to avoid api call at language-toggle.tsx line 76
          dispatch(request(null));

          history.push(frontendUrl.unsubscribed);
        } else if (
          error?.response?.status === 400 &&
          error.response.data?.message?.[0] === "Your email address is not confirmed yet"
        ) {
          history.push(`${frontendUrl.validationEmailSent}?email=${encodeURIComponent(username)}`);
        } else {
          genericErrorHandler(error);
        }
      }
    );
  };

  function request(user) {
    return { type: profileConstants.LOGIN_REQUEST, user };
  }
  function success(data) {
    return { type: profileConstants.LOGIN_SUCCESS, ...data };
  }
  function failure(error) {
    return { type: profileConstants.LOGIN_FAILURE, error };
  }
}

function loginCode(code, ephemeral_token) {
  return (dispatch) => {
    dispatch(request({})); //TODO FIX THIS

    profileService.loginCode(code, ephemeral_token).then(
      (data) => {
        sendAmplitudeData(AmplitudeEvent.LOGIN, data);
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure());
        genericErrorHandler(error);
      }
    );
  };

  function request(user) {
    return { type: profileConstants.LOGIN_REQUEST, user };
  }
  function success(data) {
    return { type: profileConstants.LOGIN_SUCCESS, ...data };
  }
  function failure(error) {
    return { type: profileConstants.LOGIN_FAILURE, error };
  }
}

function fetchUserProfile() {
  return (dispatch) => {
    dispatch(request());
    profileService
      .fetchUserProfile()
      .then((user) => {
        dispatch(success(user));
      })
      .catch((error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      });
  };

  function request() {
    return { type: profileConstants.USER_PROFILE_REQUEST };
  }
  function success(user) {
    return { type: profileConstants.USER_PROFILE_SUCCESS, user };
  }
  function failure(error) {
    return { type: profileConstants.USER_PROFILE_FAILURE, error };
  }
}

/*
 *   Logout:
 *   @param {string} accessToken: The access token used to validate the user
 *   @param {string} refreshToken: The refresh token used to generate new tokens for this session
 */
function logout(accessToken, refreshToken) {
  return (dispatch, getState) => {
    dispatch(request());
    profileService.logout(refreshToken).then(
      () => {
        sendAmplitudeData(AmplitudeEvent.LOGOUT, getState().profile?.user);
        dispatch(success());
      },
      (error) => {
        dispatch(failure("session_has_expired"));
      }
    );
  };

  function request() {
    return { type: profileConstants.LOGOUT_REQUEST };
  }
  function success() {
    return { type: profileConstants.LOGOUT_SUCCESS };
  }
  function failure(error) {
    return { type: profileConstants.LOGOUT_FAILURE, error };
  }
}

function recover(email) {
  return (dispatch) => {
    dispatch(request(email));
    profileService.recover(email).then(
      (email) => {
        dispatch(success(email));
        history.push(`${frontendUrl.login}?fromRecover=true`);
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request(email) {
    return { type: profileConstants.RECOVER_REQUEST, email };
  }
  function success(email) {
    return { type: profileConstants.RECOVER_SUCCESS, email };
  }
  function failure(error) {
    return { type: profileConstants.RECOVER_FAILURE, error };
  }
}

function refreshToken(token) {
  return (dispatch) => {
    dispatch(request());
    profileService.refreshToken(token).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        //  If an error occurs at this point, it means the refresh token has expired.
        dispatch({ type: profileConstants.LOGOUT_SUCCESS });
      }
    );
  };

  function request() {
    return { type: profileConstants.REFRESH_REQUEST };
  }
  function success(data) {
    return { type: profileConstants.REFRESH_SUCCESS, ...data };
  }
}

function addCompanyToProfile(user, token, data, t) {
  return (dispatch) => {
    dispatch(request(user));
    profileService.addCompanyToProfile(token, data).then(
      (user) => {
        dispatch(success(user));
        dispatch(modalsActions.hideModal("isJoinCompanyModalOpen"));
        snackbarUtils.success(t("PROFILE:youHaveSuccessfullyJoinedTheCompany"));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        dispatch(modalsActions.hideModal("isJoinCompanyModalOpen"));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: profileConstants.ADD_COMPANY_TO_PROFILE_REQUEST };
  }
  function success(user) {
    return { type: profileConstants.ADD_COMPANY_TO_PROFILE_SUCCESS, user };
  }
  function failure(error) {
    return { type: profileConstants.ADD_COMPANY_TO_PROFILE_FAILURE, error };
  }
}

function resetPassword(new_password1, new_password2, uid, token) {
  return (dispatch) => {
    dispatch(request(uid));
    profileService
      .resetPassword(new_password1, new_password2, uid, token)
      .then((uid) => {
        dispatch(success(uid));
        history.push(`${frontendUrl.login}?fromReset=true`);
      })
      .catch((error) => {
        dispatch(failure(error));
        genericErrorHandler(error);
      });
  };

  function request(uid) {
    return { type: profileConstants.RECOVER_REQUEST, uid };
  }
  function success(uid) {
    return { type: profileConstants.RECOVER_SUCCESS, uid };
  }
  function failure(error) {
    return { type: profileConstants.RECOVER_FAILURE, error };
  }
}

// Company Management Actions
// TODO: Check all the company in the reducers to use companyData + change the backend to make use of it
function createCompany(user, token, company, t) {
  return (dispatch) => {
    dispatch(request(company));
    profileService.createCompany(user, token, company).then(
      (company) => {
        dispatch(success(company));
        dispatch(modalsActions.hideModal("isCreateCompanyModalOpen"));
        snackbarUtils.success(t("userCompany:theCompanyProfileHasBeenSuccessfullyCreated"));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request(company) {
    return { type: profileConstants.CREATE_COMPANY_REQUEST, company };
  }
  function success(company) {
    return { type: profileConstants.CREATE_COMPANY_SUCCESS, company };
  }
  function failure(error) {
    return { type: profileConstants.CREATE_COMPANY_FAILURE, error };
  }
}

function fetchCompany() {
  return (dispatch) => {
    dispatch(request());
    profileService.fetchCompany().then(
      (companyData) => {
        dispatch(success(companyData.company));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: profileConstants.COMPANY_FETCH_REQUEST };
  }
  function success(company) {
    return { type: profileConstants.COMPANY_FETCH_SUCCESS, company };
  }
  function failure(error) {
    return { type: profileConstants.COMPANY_FETCH_FAILURE, error };
  }
}

function fetchProfilePreferences() {
  return (dispatch) => {
    dispatch(request());
    profileService.fetchProfilePreferences().then(
      (preferenceData) => {
        dispatch(success(preferenceData));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: profileConstants.PROFILE_PREFERENCE_REQUEST };
  }
  function success(preferences) {
    return { type: profileConstants.PROFILE_PREFERENCE_SUCCESS, preferences };
  }
  function failure(error) {
    return { type: profileConstants.PROFILE_PREFERENCE_FAILURE, error };
  }
}

function editProfilePreferences(payload) {
  return (dispatch) => {
    dispatch(request());
    profileService.editProfilePreferences(payload).then(
      (preferenceData) => {
        dispatch(success(preferenceData.data));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: profileConstants.PROFILE_PREFERENCE_REQUEST };
  }
  function success(preferences) {
    return { type: profileConstants.PROFILE_PREFERENCE_SUCCESS, preferences };
  }
  function failure(error) {
    return { type: profileConstants.PROFILE_PREFERENCE_FAILURE, error };
  }
}

function editCompany(user, token, company, t) {
  return (dispatch) => {
    dispatch(request(company));
    profileService.editCompany(user, token, company).then(
      (company) => {
        // Close the dialog and display a success message.
        dispatch(modalsActions.hideModal("isEditCompanyModalOpen"));
        dispatch(success(company));
        snackbarUtils.success(t("CALLING_PLACES:companyDataHasBeenSuccessfullyBackedUp"));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request(company) {
    return { type: profileConstants.EDIT_COMPANY_REQUEST, company };
  }
  function success(company) {
    return { type: profileConstants.EDIT_COMPANY_SUCCESS, company };
  }
  function failure(error) {
    return { type: profileConstants.EDIT_COMPANY_FAILURE, error };
  }
}

function resendVerification(email) {
  return (dispatch) => {
    dispatch(request());
    profileService.resendVerification(email).then(
      (email) => {
        dispatch(success(email));
        history.push(frontendUrl.emailSent);
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: profileConstants.RESEND_VERIFICATION_REQUEST };
  }
  function success(email) {
    return { type: profileConstants.RESEND_VERIFICATION_SUCCESS, email };
  }
  function failure(error) {
    return { type: profileConstants.RECOVER_FAILURE, error };
  }
}

function createProcess(token, data_process) {
  return (dispatch) => {
    dispatch(request());
    profileService.createProcess(token, data_process).then(
      (company) => {
        dispatch(success(company));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: profileConstants.CREATE_PROCESS_REQUEST };
  }
  function success(company) {
    return { type: profileConstants.CREATE_PROCESS_SUCCESS, company };
  }
  function failure(error) {
    return { type: profileConstants.CREATE_PROCESS_FAILURE, error };
  }
}
function createStandard(token, data_standard) {
  return (dispatch) => {
    dispatch(request());
    profileService.createStandard(token, data_standard).then(
      (company) => {
        dispatch(success(company));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: profileConstants.CREATE_STANDARD_REQUEST };
  }
  function success(company) {
    return { type: profileConstants.CREATE_STANDARD_SUCCESS, company };
  }
  function failure(error) {
    return { type: profileConstants.CREATE_STANDARD_FAILURE, error };
  }
}
function createRating(token, data_rating) {
  return (dispatch) => {
    dispatch(request());
    profileService.createRating(token, data_rating).then(
      (company) => {
        dispatch(success(company));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: profileConstants.CREATE_RATING_REQUEST };
  }
  function success(company) {
    return { type: profileConstants.CREATE_RATING_SUCCESS, company };
  }
  function failure(error) {
    return { type: profileConstants.CREATE_RATING_FAILURE, error };
  }
}

function isEmailSubscribed(token) {
  return (dispatch) => {
    dispatch(request());
    profileService.isEmailSubscribed(token).then(
      (company) => {
        dispatch(success(company));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: profileConstants.IS_EMAIL_SUBSCRIBED_REQUEST };
  }
  function success(company) {
    return { type: profileConstants.IS_EMAIL_SUBSCRIBED_SUCCESS, company };
  }
  function failure(error) {
    return { type: profileConstants.IS_EMAIL_SUBSCRIBED_FAILURE, error };
  }
}

function fetchNotifications(token) {
  return (dispatch) => {
    dispatch(request());
    profileService.fetchNotifications(token).then(
      (notificationData) => {
        dispatch(success(notificationData));
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: profileConstants.NOTIFICATION_FETCH_REQUEST };
  }
  function success(notificationData) {
    return {
      type: profileConstants.NOTIFICATION_FETCH_SUCCESS,
      notificationData,
    };
  }
  function failure(error) {
    return { type: profileConstants.NOTIFICATION_FETCH_FAILURE, error };
  }
}

function markNotificationAsRead(token, notification_id) {
  return (dispatch) => {
    dispatch(request());
    profileService.markNotificationAsRead(token, notification_id).then(
      (notification) => {
        dispatch(success(notification));
        window.location.reload();
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: profileConstants.NOTIFICATION_READ_REQUEST };
  }
  function success(notificationData) {
    return {
      type: profileConstants.NOTIFICATION_READ_SUCCESS,
      notificationData,
    };
  }
  function failure(error) {
    return { type: profileConstants.NOTIFICATION_READ_FAILURE, error };
  }
}

function addFileToCompanyStorage(token, data) {
  return (dispatch) => {
    dispatch(request());
    profileService.addFileToCompanyStorage(token, data).then(
      (data) => {
        dispatch(success(data));
        window.location.reload();
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: profileConstants.ADD_FILE_TO_WORKSPACE_REQUEST };
  }
  function success(data) {
    return { type: profileConstants.ADD_FILE_TO_WORKSPACE_SUCCESS, data };
  }
  function failure(error) {
    return { type: profileConstants.ADD_FILE_TO_WORKSPACE_FAILURE, error };
  }
}

function deleteFileFromStorage(token, data) {
  return (dispatch) => {
    dispatch(request());
    profileService.deleteFileFromStorage(token, data).then(
      (data) => {
        dispatch(success(data));
        window.location.reload();
      },
      (error) => {
        dispatch(failure(error?.toString()));
        genericErrorHandler(error);
      }
    );
  };

  function request() {
    return { type: profileConstants.DELETE_FILE_FROM_WORKSPACE_REQUEST };
  }
  function success(data) {
    return { type: profileConstants.DELETE_FILE_FROM_WORKSPACE_SUCCESS, data };
  }
  function failure(error) {
    return { type: profileConstants.DELETE_FILE_FROM_WORKSPACE_FAILURE, error };
  }
}

function decrementCompanyStatsNewQaMsgCount(count) {
  return {
    type: profileConstants.DECREMENT_COMPANY_STATS_NEW_QA_MSG_COUNT,
    count,
  };
}

function updateUserState(user) {
  return { type: profileConstants.UPDATE_USER_STATE, user };
}

function setTokens(authTokens) {
  return {
    type: profileConstants.SET_TOKENS,
    ...authTokens,
  };
}

function setLoginSuccess(authTokens) {
  return {
    type: profileConstants.LOGIN_SUCCESS,
    ...authTokens,
  };
}

function addUserInCompany(users) {
  return { type: profileConstants.ADD_USER_IN_COMPANY, users };
}

function decrementCompanyStatsPendingConnectionsCount(count) {
  return {
    type: profileConstants.DECREMENT_COMPANY_STATS_PENDING_CONNECTIONS_COUNT,
    count,
  };
}
