import { useSelector } from "react-redux";

import { ProjectCompanyPart } from "@next/modules/project/redux";

import { GridColDef } from "@mui/x-data-grid-pro-v5";
import { getSellerDashboardBuyerProfilePastJobsTableColumns } from "./seller-dashboard-buyer-profile-past-jobs-table.columns";
import { selectBuyerProfile } from "@next/modules/dashboard/redux/selectors";

export type GridStatePartData = {
  rows: Partial<ProjectCompanyPart>[];
  columns: GridColDef[];
};

export const useSellerDashboardBuyerProfilePastJobsTableData = (): GridStatePartData => {
  const buyerProfile = useSelector(selectBuyerProfile);

  return {
    rows: buyerProfile?.parts || [],
    columns: getSellerDashboardBuyerProfilePastJobsTableColumns() || [],
  };
};
