import React, { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, FormikProps } from "formik";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { GenericTextInput } from "components/utils/input/text";
import { GenericPhoneNumberInput } from "components/utils/input/phone-number";
import { handlePostalCode } from "helpers/handle-postal-code";
import { CompanyInformationFormValues } from "./company-information.form.types";
import { checkIsSellerCompany } from "@next/utils/checkCompanyUtils";
import {
  selectCompanyProfile,
  selectCompanyProfileLoading,
} from "@next/modules/profile/redux/selectors";
import { useSelector } from "react-redux";
import ChangeAvatar from "../../change-avatar";
import { RichTextEditor } from "@next/components/rich-text-editor";
import { EditorState, RawDraftContentState } from "draft-js";
import { makeStyles } from "@mui/styles";
import { profileService } from "services/profile";
import { debounce } from "lodash";
import { CountryList } from "@next/utils/countryList";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    padding: theme.spacing(2),
  },
  circularProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-12px",
    marginLeft: "-12px",
  },
  loaderContainer: {
    position: "relative",
  },
}));

export const CompanyInformationFormBase: React.FC<FormikProps<CompanyInformationFormValues>> = ({
  values,
  handleSubmit,
  setFieldValue,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const companyProfile = useSelector(selectCompanyProfile);
  const companyProfileLoading = useSelector(selectCompanyProfileLoading);
  const isSeller = checkIsSellerCompany(companyProfile?.type);
  const [key, forceRemount] = useReducer((x) => x + 1, 0);

  const [open, setOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    Country: "",
    ISO: "",
  });

  useEffect(() => {
    if (values?.address?.country) {
      const currentCountry = CountryList.find((item) => item.Country === values?.address?.country);
      setSelectedCountry(currentCountry);
    }
  }, [values?.address?.country]);

  const [postalCode, setPostalCode] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    forceRemount();
  }, [companyProfileLoading]);

  const handleOnBlur = (event: React.ChangeEvent<{}>) => {
    handlePostalCode(event, setFieldValue);
  };

  const handleOnChangeAvatar = (file: File) => {
    setFieldValue("picture", file);
  };

  const handleChangePostalCode = async (_, postalCode) => {
    if (postalCode && selectedCountry.ISO) {
      await profileService
        .fetchAddressByPostalCode(selectedCountry?.ISO, postalCode)
        .then((response) => {
          setFieldValue("address", {
            ...values.address,
            postal_code: postalCode,
            city: response?.city || "",
            province: response?.province || "",
          });
        })
        .catch((error) => {
          setFieldValue("address", {
            ...values.address,
            city: "",
            province: "",
          });
        });
    } else {
      setFieldValue("address", {
        ...values.address,
        city: "",
        province: "",
      });
    }
  };

  const handleCountryChange = (select) => {
    setSelectedCountry(select);
    setFieldValue("address", {
      ...values.address,
      country: select?.Country,
      postal_code: "",
      city: "",
      province: "",
    });
  };

  const onPostalCodeSearch = async (pst) => {
    const value = pst.target.value;
    if (value && selectedCountry.ISO) {
      setFieldValue("address.postal_code", value);
      setLoading(true);
      await profileService
        .fetchPostalCode(selectedCountry?.ISO, value)
        .then((res) => {
          setPostalCode(res?.postal_code_list);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setPostalCode([]);
          const { response } = error;
          if (response.status === 400) {
            setFieldValue("address", {
              ...values.address,
              city: response?.city || "",
              province: response?.province || "",
            });
          }
        });
    }
  };

  const postalCodeSearchChange = debounce(onPostalCodeSearch, 500);

  return (
    <Form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mb={3}>
            <Typography variant="body2" color="textSecondary">
              {t("profile:companyProfile:generalInformationAboutYourCompany")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ChangeAvatar
            value={values?.picture}
            onChange={handleOnChangeAvatar}
            companyName={values.name}
          />
        </Grid>

        <Grid item xs={12}>
          <GenericTextInput
            name="name"
            label={t("profile:inputs:companyName")}
            value={values.name}
          />
        </Grid>
        <Grid item xs={12}>
          <GenericTextInput
            maxLength={115}
            name="description"
            label={t("profile:inputs:companySummary")}
            value={values.description}
          />
        </Grid>
        <Grid item xs={12}>
          <RichTextEditor
            label={t("profile:inputs:companyDescription")}
            minHeight={344}
            maxHeight={344}
            defaultValue={values.additional_details_rich_text || values.additional_details}
            onChange={(state: RawDraftContentState | EditorState) =>
              setFieldValue("additional_details_rich_text", state)
            }
            key={key}
          />
        </Grid>
        <Grid item xs={12}>
          <GenericPhoneNumberInput
            name="phone_number"
            label={t("profile:inputs:phoneNumber")}
            value={values.phone_number}
            onBlur={(data: any) => setFieldValue("phone_number", data?.target?.value)}
            defaultCountry="ca"
            onlyCountries={["us", "ca"]}
          />
        </Grid>
        <Grid item xs={12}>
          <GenericTextInput
            name="public_email"
            label={t("profile:inputs:publicEmail")}
            value={values.public_email}
          />
        </Grid>
        <Grid item xs={12}>
          <GenericTextInput
            name="website"
            label={t("profile:inputs:website")}
            value={values.website}
          />
        </Grid>
        <Grid item xs={12}>
          <GenericTextInput
            name="address.street"
            label={t("profile:inputs:streetAndCivilAddress")}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            size="medium"
            options={CountryList || []}
            value={selectedCountry}
            getOptionLabel={(option) => option.Country}
            renderInput={(params) => (
              <TextField {...params} key={params.id} label={t("userOnboarding:countryName")} />
            )}
            onChange={(_, select) => {
              handleCountryChange(select);
            }}
          />
        </Grid>
        <Grid item xs={6} className={classes.loaderContainer}>
          <Autocomplete
            size="medium"
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            options={postalCode}
            onClose={() => {
              setOpen(false);
            }}
            loading={loading}
            value={values?.address?.postal_code || ""}
            getOptionLabel={(option) => option}
            onChange={handleChangePostalCode}
            defaultValue={values?.address?.postal_code}
            renderInput={(params) => (
              <TextField
                {...params}
                key={params.id}
                label={t("userOnboarding:postalCode")}
                onChange={postalCodeSearchChange}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          {values?.showLoader && (
            <CircularProgress size={24} className={classes.circularProgress} />
          )}
        </Grid>
        <Grid item xs={6}>
          <GenericTextInput
            name="address.city"
            label={t("profile:inputs:city")}
            value={values?.address?.city}
          />
        </Grid>
        <Grid item xs={6}>
          <GenericTextInput
            name="address.province"
            label={t("profile:inputs:province")}
            value={values?.address?.province}
          />
        </Grid>
        {isSeller ? (
          <>
            <Grid item xs={6}>
              <GenericTextInput
                name="number_employee"
                label={t("profile:inputs:numberOfEmployees")}
                value={values.number_employee}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <GenericTextInput
                name="shop_floor_area"
                label={
                  <span
                    style={{ lineHeight: 0 }}
                    dangerouslySetInnerHTML={{
                      __html: t("profile:inputs:shopFloorArea"),
                    }}
                  />
                }
                value={values.shop_floor_area}
                type="number"
              />
            </Grid>
          </>
        ) : null}

        <Grid item xs={2}>
          <Button variant="contained" type="submit" size="medium" color="primary">
            {t("common:save")}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};
