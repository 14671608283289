import React from "react";
import * as S from "./form-add-button.styled";

type Props = {
  btnText: string;
  onClick: () => void;
  disabled?: boolean;
};

export const FormAddButton: React.FC<Props> = ({ btnText, onClick, disabled }) => {
  return (
    <S.StyledButton
      startIcon={<S.StyledAddIcon />}
      variant="contained"
      color="primary"
      disableElevation
      onClick={onClick}
      fullWidth={false}
      disabled={disabled}
    >
      <S.StyledButtonTypography variant="body2" tooltipTitle={null}>
        {btnText}
      </S.StyledButtonTypography>
    </S.StyledButton>
  );
};
