import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useFormikContext } from "formik";
import { Box, Chip, Icon, IconButton, InputAdornment, Typography } from "@mui/material";
import { GenericTextInput } from "components/utils/input/text";
import { Field, FieldArray, Form, FormikProps } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getToken, getUserLanguage, selectCompanyUsers } from "services/profile/profile.selectors";
import { PartsAndFilesCount, RFQFormValuesType } from "./rfq-creation.form.types";
import { PaymentTerms } from "@next/components/payment-terms/payment-terms";
import { TagOptions } from "components/rfq-form/tag-options/tag-options.component";
import { RichTextEditor } from "@next/components/rich-text-editor";
import { RawDraftContentState } from "draft-js";
import InlineFilePickerButton from "@next/components/inline-file-picker-button";
import { workspaceApi } from "services/workspace";
import { getPlainAndRichTextAsString } from "@next/components/rich-text-editor/rich-text-editor-utils";
import { useStyles } from "./rfq-creation.form.styles";
import Footer from "./footer";
import { useFormikScrollToError } from "@next/hooks/useFormilkScrollToError";
import SuppliersAutoCompleteDropdown from "./suppliers-autocomplete-dropdown";
import { projectActions } from "@next/modules/project/redux";
import { workspaceNextActions } from "../../redux";
import { InviteSupplierModal } from "../../modals/invite-supplier-modal";
import SupplierListItem from "./supplier-list-item";
import {
  selectActiveDraftRFQ,
  selectDraftRFQsCount,
  selectFileUploadProgress,
  selectRfqFormAdditionalFiles,
  selectUploadRfqAttachmentsLoading,
} from "../../redux/selectors";
import { debounce, isEqual } from "lodash";
import { RequestType, formDataToRFQData, getInitialValues } from "./rfq-creation.form.definitions";
import { useDeepCompareEffect, useDeepCompareUpdateEffect } from "@next/hooks/useDeepCompareEffect";
import { store } from "store";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { getSupportedNDAFileTypes } from "@next/utils/constantUtils";
import CustomListItem from "./custom-list-item";
import { selectProjectCreateLoading } from "@next/modules/project/redux/selectors";
import { useIsStaffUser } from "@next/modules/profile/hooks/useIsStaffUser";
import UsersPaginatedAutoComplete from "./users-paginated-autocomplete";
import SupplierContactsList from "../supplier-contacts-list/supplier-contacts-list";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { MUIDatePicker } from "@next/components/mui-date-picker";
import { formatDate } from "helpers/formatDate";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";
import { getIsLastRFQPublic } from "services/part-configuration/part-configuration.selectors";
import RfqFormHeader from "./rfq-form-header";
import AccordionWrapper from "@next/components/accordion-wrapper";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CustomButton from "@next/components/custom-button";
import LanguageIcon from "@mui/icons-material/Language";
import CloseIcon from "@mui/icons-material/Close";
import MultipleDNDFileUpload from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload";
import RfqAddFilesAndPartsModal, {
  RfqDataToPartsModal,
} from "../../modals/rfq-add-filesandparts-modal/rfq-add-filesandparts-modal";
import folderIcon from "@next/assets/folderIcon.png";
import { WorkspaceModalTypes } from "../../modals/types";
import { AttachFileList } from "@next/components/attach-file/attach-file-list";
import { convertFilesToObjects, getFileUploadProgress } from "@next/utils/fileUtils";
import { CustomFileObject } from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload.types";
import { slugifyString } from "@next/utils/stringUtils";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { DistributionForSupplierContacts } from "@next/modals/shared-supplier-contact-lists-modal";
import snackbarUtils from "@next/utils/snackbarUtils";

const RFQCreationFormBase: React.FC<
  FormikProps<RFQFormValuesType> & {
    projectCode?: string;
    selectAllParts?: boolean;
    partSearch?: string;
    selectedPartsCount?: number;
    setSelectedPartsCount?: React.Dispatch<React.SetStateAction<number>>;
  }
> = (props) => {
  const {
    values,
    setFieldValue,
    touched,
    errors,
    submitForm,
    projectCode,
    partSearch,
    selectAllParts,
    setSelectedPartsCount,
  } = props;
  const dispatch = useDispatch();
  const formik = useFormikContext<RFQFormValuesType>();

  const { t } = useTranslation();
  const classes = useStyles();
  const language = useSelector(getUserLanguage);
  const additionalDetailsRichText = useRef<string | RawDraftContentState>(
    values.additional_details_rich_text || values.additional_details
  );
  const ts = useRef(Date.now());
  const token = useSelector(getToken);
  const axyaTracking = useAxyaTracking();
  const isStaffUser = useIsStaffUser();
  const companyUsers = useSelector(selectCompanyUsers);
  const isPublic = useSelector(getIsLastRFQPublic);
  const fileUploadProgress = useSelector(selectFileUploadProgress);
  const attachmentsLoading = useSelector(selectUploadRfqAttachmentsLoading);
  const projectCreationLoading = useSelector(selectProjectCreateLoading);
  const isRestrictedViewer = useHasCompanyRole(["restricted_viewer"]);
  const [attachmentsForMultipleDND, setAttachmentsForMultipleDND] = React.useState<any>([]);
  const [allAttachments, setAllAttachments] = React.useState<FileData[]>([]);
  const [showPartsList, setShowPartsList] = React.useState(false);
  const selectedPartsCount =
    values?.parts?.length > 0 ? props.selectedPartsCount : values.partsAndFilesCount?.partsCount;

  useFormikScrollToError();

  const onNdaUpload = (file: File) => {
    return workspaceApi.buyerUploadNDA(token, file).then((nda) => setFieldValue("nda", nda));
  };

  const onNdaDelete = () => {
    setFieldValue("nda", null);
  };

  const handlePartsAdditionalAttachmentsChange = (
    files: Partial<FileData>[],
    filePkToDelete: number = -1
  ) => {
    const prev = selectRfqFormAdditionalFiles(store.getState());
    if (filePkToDelete === -1) {
      setFieldValue(
        "attachments",
        [...files, ...(prev || [])].filter(
          (file, index, self) => index === self.findIndex((t) => t.pk === file.pk)
        )
      );
    } else {
      const allFiles = [...files, ...(prev || [])].filter(
        (file, index, self) => index === self.findIndex((t) => t.pk === file.pk)
      );
      const filteredFiles = allFiles.filter((file) => file.pk !== filePkToDelete);
      setFieldValue("attachments", filteredFiles);
    }
  };

  const handleOnAttachmentDelete = useCallback(
    (fileName: string) => {
      const filePk = allAttachments.find((file) => file.file_name === fileName)?.pk;
      handlePartsAdditionalAttachmentsChange([], filePk);
      setAllAttachments((prev) => prev.filter((file) => file.file_name !== fileName));
      dispatch(
        workspaceNextActions.removeFileUploadProgress({
          filesName: fileName,
        })
      );
    },
    [allAttachments, handlePartsAdditionalAttachmentsChange]
  );

  const handleAttachmentsChange = useCallback(
    (files: CustomFileObject[]) => {
      const allFiles = files.map((file) => file?.file);
      const all_attachments = allAttachments.concat(convertFilesToObjects(allFiles));
      setAllAttachments(all_attachments);
      dispatch(
        workspaceNextActions.uploadRfqAttachmentsRequest({
          files: allFiles as File[],
          rfqPk: null,
          onSuccess: (attachments) => {
            setFieldValue(
              "attachments",
              allAttachments.concat(
                attachments.filter((item) =>
                  all_attachments.some((file) => file.file_name === item.file_name)
                )
              )
            );
          },
        })
      );
      setAttachmentsForMultipleDND([]);
    },
    [allAttachments]
  );

  useDeepCompareEffect(() => {
    dispatch(workspaceNextActions.updateAdditionalFiles(values.attachments));
    setAllAttachments(values.attachments);
  }, [values.attachments]);

  const onSubmit = useCallback(() => {
    const { richText, plainText } = getPlainAndRichTextAsString(
      additionalDetailsRichText.current || ""
    );
    setFieldValue("additional_details_rich_text", richText);
    setFieldValue("additional_details", plainText);
    submitForm();
  }, []);

  const saveDraft = useCallback(
    debounce(
      (values) => {
        const { richText, plainText } = getPlainAndRichTextAsString(
          additionalDetailsRichText.current || ""
        );

        const rfqData: Record<string, any> = {
          ...formDataToRFQData(values),
          targeted_suppliers: values.targeted_suppliers,
          nda: values.nda || null,
          additional_details: plainText,
          additional_details_rich_text: richText,
          all_parts_selected: !!selectAllParts,
          part_search: partSearch,
          selected_parts_count: selectedPartsCount,
          attachments: values.attachments,
        };
        if (projectCode) rfqData.project = projectCode;

        dispatch(workspaceNextActions.saveDraftRFQRequest(rfqData));
      },
      500,
      {
        leading: false,
        trailing: true,
      }
    ),
    [projectCode, selectedPartsCount]
  );

  const onDescriptionChange = useCallback(
    (state) => {
      //ignore initial false triggers where description has not actually changed
      if (
        Date.now() - ts.current > 1000 &&
        JSON.stringify(state || {}) !==
          (typeof additionalDetailsRichText.current === "string"
            ? additionalDetailsRichText.current
            : JSON.stringify(additionalDetailsRichText.current || {}))
      ) {
        saveDraft(values);
      }
      additionalDetailsRichText.current = state;
    },
    [values]
  );

  useDeepCompareUpdateEffect(() => {
    saveDraft(values);
  }, [values]);

  useEffect(() => {
    const state = store.getState();
    const initialDraftsCount = selectDraftRFQsCount(state);

    dispatch(workspaceNextActions.fetchFavoriteSupplierGroupsRequest());
    dispatch(workspaceNextActions.updateAdditionalFiles(values.attachments));
    //if form is open by not loading a draft and form has some initial data then save
    if (
      !selectActiveDraftRFQ(state) &&
      !isEqual(
        getInitialValues({
          is_public: isPublic,
        }),
        values
      )
    ) {
      saveDraft(values);
    }
    if (values.parts?.length === selectedPartsCount) {
      setShowPartsList(true);
    }

    return () => {
      //wait to allow debounce to save the latest draft changes
      setTimeout(() => {
        dispatch(workspaceNextActions.setActiveDraftRFQ(null));
        const draftsCount = selectDraftRFQsCount(store.getState());
        if (draftsCount === initialDraftsCount + 1) {
          axyaTracking({
            scout_category: "axya-buyer-draft-rfqs",
            scout_feature_name: "create-new-draft-rfq",
          });
        }
      }, 1000);
    };
  }, []);

  const handlePartsAndFilesBtnClick = useCallback(() => {
    const handleProjectCreationSuccess = (project) => {
      setFieldValue("project", project.code);
      dispatch(
        modalsActions.showModal({
          key: WorkspaceModalTypes.RFQ_ADD_FILES_AND_PARTS_MODAL,
          data: {
            projectCode: project.code,
            onAttachmentsChange: handlePartsAdditionalAttachmentsChange,
            onPartsCountChange: (partsAndFilesCount: PartsAndFilesCount) =>
              setFieldValue("partsAndFilesCount", partsAndFilesCount),
          } as RfqDataToPartsModal,
        })
      );
    };

    if (!formik.values.project) {
      dispatch(
        projectActions.createProjectRequest({
          code: slugifyString(formik.values.rfq_name),
          display_name: formik.values.rfq_name,
          onSuccess: handleProjectCreationSuccess,
        })
      );
    } else {
      handleProjectCreationSuccess({ code: formik.values.project });
    }
  }, [formik.values.project, formik.values.rfq_name, values.attachments]);

  const partsAdded = useMemo(() => {
    return (
      values.partsAndFilesCount?.partsCount > 0 ||
      values.partsAndFilesCount?.filesCount > 0 ||
      false
    );
  }, [values.partsAndFilesCount?.partsCount, values.partsAndFilesCount?.filesCount]);

  const attachedFiles: FileData[] = useMemo(() => {
    const filesWithUploadProgress = allAttachments.map((item) => ({
      ...item,
      file_uploaded: attachmentsLoading
        ? getFileUploadProgress(fileUploadProgress, item.file_name)
        : undefined,
    }));
    return filesWithUploadProgress;
  }, [allAttachments, fileUploadProgress, attachmentsLoading]);

  return (
    <>
      <Box className={classes.rfqFormContainer}>
        <Form>
          <RfqFormHeader />
          <Box className={classes.form}>
            {isStaffUser ? (
              <UsersPaginatedAutoComplete
                options={companyUsers}
                fieldName="created_by"
                placeholder={t("workspaceNext:rfqDrawer:onBehalfOf")}
                valueKey="email"
                multiple={false}
              />
            ) : null}

            <SuppliersAutoCompleteDropdown
              // AAC-4405: hide invite supplier for now
              // onInvite={onInvite}
              fieldName="targeted_suppliers"
              showGroupsAndMarketplace={true}
              endAdornment={
                <InputAdornment position="end">
                  <Typography variant="body1" color={"secondary"}>
                    {t("workspaceNext:rfqDrawer:supplier", {
                      count: values.targeted_suppliers.length,
                    })}
                  </Typography>
                </InputAdornment>
              }
              formik={formik}
            />
            {(values.targeted_suppliers.length > 0 || values.is_public) && (
              <Box p={1}>
                {values.is_public && (
                  <Box className={classes.publicSupplierContainer}>
                    <Box className={classes.labelContainer}>
                      <Icon className={classes.labelIconContainer}>
                        <LanguageIcon color="inherit" />
                      </Icon>
                      <Typography>{t("rfq:isPublicSwitch:label")}</Typography>
                    </Box>
                    <IconButton
                      onClick={() => setFieldValue("is_public", false)}
                      className={classes.closeIconBtn}
                      size="large"
                      style={{ padding: 0, marginRight: -5 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                )}
                <FieldArray
                  name="targeted_suppliers"
                  render={(arrayHelpers) =>
                    values.targeted_suppliers.map((item, index) => (
                      <Box>
                        <CustomListItem
                          key={item.pk}
                          onRemove={() => arrayHelpers.remove(index)}
                          rootClassName={
                            index === values.targeted_suppliers.length - 1
                              ? classes.lastListItem
                              : classes.listItem
                          }
                        >
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <SupplierListItem
                              name={item.name}
                              uuid={item.uuid}
                              picture={item.picture}
                              openProfileOnClick
                              tooltipTitle={item.name}
                              maxWidth={500}
                              avatarStyle={{ borderRadius: "4px" }}
                            />
                            <SupplierContactsList
                              supplier={item}
                              users={item.target_profiles}
                              editMode
                              overlapping
                              onValidate={(users) => {
                                setFieldValue(
                                  `targeted_suppliers[${index}].target_profiles`,
                                  users
                                );
                                dispatch(
                                  modalsActions.closeModal(
                                    SharedModalTypes.SUPPLIER_CONTACTS_LIST_MODAL
                                  )
                                );
                              }}
                              distributionForSupplierContacts={DistributionForSupplierContacts.RFQ}
                            />
                          </Box>
                        </CustomListItem>
                      </Box>
                    ))
                  }
                />
                <FieldArray
                  name="invited_suppliers"
                  render={(arrayHelpers) =>
                    values.invited_suppliers.map((item, index) => (
                      <CustomListItem key={item.email} onRemove={() => arrayHelpers.remove(index)}>
                        <SupplierListItem name={item.name} />
                      </CustomListItem>
                    ))
                  }
                />
              </Box>
            )}
            <Box className={classes.row}>
              <Box flex={1}>
                <GenericTextInput
                  name="rfq_name"
                  label={t("workspaceNext:rfqDrawer:placeholder:addTitle")}
                  value={values.rfq_name}
                  size="small"
                />
              </Box>
              <Box flex={1}>
                <MUIDatePicker
                  size="small"
                  color="secondary"
                  style={{ width: "100%" }}
                  name="deadline"
                  label={t("workspaceNext:rfqDrawer:placeholder:addEndDate")}
                  value={values.deadline}
                  inputVariant="outlined"
                  format={language === "en" ? "yyyy/MM/dd" : "dd/MM/yyyy"}
                  InputAdornmentProps={{ position: "end" }}
                  onChange={(value) => {
                    setFieldValue("deadline", formatDate(value, "YYYY-MM-DD") || "");
                  }}
                  error={touched.deadline && !!errors.deadline}
                  helperText={touched.deadline && !!errors.deadline ? errors.deadline : ""}
                  disablePast
                  autoOk
                  okLabel=""
                  cancelLabel=""
                />
              </Box>
            </Box>

            <RichTextEditor
              minHeight={120}
              label={t("workspaceNext:rfqDrawer:placeholder:addDescription")}
              value={values.additional_details_rich_text || values.additional_details}
              onChange={onDescriptionChange}
            />

            {formik.values.request_type === RequestType.RFQ && (
              <>
                {values?.parts?.length > 0 ? null : (
                  <CustomButton
                    startIcon={partsAdded ? <EditIcon /> : <AddIcon />}
                    variant={partsAdded ? "outlined" : "contained"}
                    size="large"
                    sx={{ alignSelf: "flex-start" }}
                    loading={projectCreationLoading}
                    onClick={handlePartsAndFilesBtnClick}
                  >
                    {partsAdded
                      ? t("workspaceNext:rfqDrawer:itemWithCount", {
                          count: values.partsAndFilesCount?.partsCount || 0,
                        }) +
                        t("workspaceNext:rfqDrawer:fileWithCount", {
                          count: values.partsAndFilesCount?.filesCount || 0,
                        })
                      : t("workspaceNext:rfqDrawer:partsAndFiles")}
                  </CustomButton>
                )}
                {values?.parts?.length > 0 && (
                  <>
                    <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                      <Trans
                        i18nKey="workspaceNext:rfqDrawer:partsSelected"
                        components={[
                          <Chip
                            label={showPartsList ? values?.parts?.length : selectedPartsCount}
                            size="small"
                          />,
                        ]}
                        values={{
                          count: showPartsList ? values?.parts?.length : selectedPartsCount,
                        }}
                      />
                    </Box>
                    {showPartsList ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <FieldArray
                          name="parts"
                          render={(arrayHelpers) =>
                            values.parts.map((part, index) => (
                              <CustomListItem
                                key={part.pk}
                                onRemove={() => {
                                  if (values.parts.length === 1) {
                                    snackbarUtils.toast(
                                      `${t("workspaceNext:rfqDrawer:error:atLeastOnePartReqd")}`
                                    );
                                  } else {
                                    setSelectedPartsCount((prev: number) => prev - 1);
                                    arrayHelpers.remove(index);
                                  }
                                }}
                              >
                                <Typography variant="body1">{part.name}</Typography>
                              </CustomListItem>
                            ))
                          }
                        />
                      </Box>
                    ) : null}

                    <MultipleDNDFileUpload
                      filesState={attachmentsForMultipleDND}
                      setFilesState={handleAttachmentsChange}
                      compactMode={true}
                      disableDndMargin={true}
                      showSecureInfo={false}
                      showUploadList={false}
                      centerInnnerContent={false}
                      customStartIcon={folderIcon}
                    />
                    {attachedFiles.length > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                        }}
                      >
                        <AttachFileList
                          files={attachedFiles}
                          onClickDelete={handleOnAttachmentDelete}
                        />
                      </Box>
                    )}
                  </>
                )}
                <AccordionWrapper
                  title={
                    <Typography variant="body1">
                      {t("workspaceNext:rfqDrawer:additionalOptions")}
                    </Typography>
                  }
                  expandedKey="additionnal_details"
                  accordionClasses={{
                    root: classes.additionalDetailsAccordionRoot,
                  }}
                >
                  <Box>
                    <Box className={classes.row}>
                      <Box flex={1}>
                        <Field
                          name="tag"
                          component={TagOptions}
                          size="small"
                          label={t("workspaceNext:rfqDrawer:placeholder:addTag")}
                        />
                      </Box>
                      <Box flex={1}>
                        <Field
                          name="net_ds"
                          component={PaymentTerms}
                          showHelperText={false}
                          size="small"
                          label={t("workspaceNext:rfqDrawer:placeholder:selectPaymentTerms")}
                        />
                      </Box>
                    </Box>
                    <Box mt={2}>
                      <InlineFilePickerButton
                        file={values.nda || undefined}
                        onFileAdded={onNdaUpload}
                        onFileRemoved={onNdaDelete}
                        btnProps={{ variant: "outlined", size: "medium" }}
                        rootClassName={classes.rfqAttachmentBtn}
                        pickerButtonText={t("workspaceNext:rfqDrawer:uploadNDA")}
                        accept={`${getSupportedNDAFileTypes()}`}
                      />
                    </Box>
                  </Box>
                </AccordionWrapper>
              </>
            )}
            {(formik.values.request_type === RequestType.RFI ||
              formik.values.request_type === RequestType.SIMPLE_RFQ) && (
              <>
                <MultipleDNDFileUpload
                  filesState={attachmentsForMultipleDND}
                  setFilesState={handleAttachmentsChange}
                  compactMode={true}
                  disableDndMargin={true}
                  showSecureInfo={false}
                  showUploadList={false}
                  centerInnnerContent={false}
                  customStartIcon={folderIcon}
                />
                <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                  <AttachFileList files={attachedFiles} onClickDelete={handleOnAttachmentDelete} />
                </Box>
              </>
            )}
          </Box>
        </Form>
        {!isRestrictedViewer && <Footer onSubmit={onSubmit} />}
      </Box>
      <InviteSupplierModal />
      <RfqAddFilesAndPartsModal />
    </>
  );
};

export default RFQCreationFormBase;
