import { CircularProgress, CircularProgressProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { AppTheme } from "layout/AppTheme";
import React from "react";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      lineHeight: 0,
    },
    bottom: {
      color: theme.palette.grey[300],
    },
  })
);

type ColorBreakPoint = {
  value: number; // Percentage of the circle (0-100)
  color: string;
};

interface Props {
  value: number; // Overall progress value (0-100)
  colors?: ColorBreakPoint[]; // Array of color segments
}

const ProgressCircle: React.FC<Props & CircularProgressProps> = ({
  value,
  colors = [{ value: 100, color: AppTheme.palette.text.primary }],
  ...props
}) => {
  const classes = useStyles();

  const hasMultipleColors = colors.length > 1;

  const overallValue = value !== undefined ? Math.min(value, 100) : 100;

  const sortedColors = colors.sort((a, b) => b.value - a.value);

  let layersToRender: ColorBreakPoint[] = [];

  if (hasMultipleColors) {
    let cumulative = 0;
    for (const colorBreakPoint of sortedColors) {
      if (cumulative >= overallValue) {
        break;
      }
      const remaining = overallValue - cumulative;
      const layerValue = Math.min(colorBreakPoint.value, remaining);
      layersToRender.push({ value: layerValue, color: colorBreakPoint.color });
      cumulative += layerValue;
    }
  } else {
    layersToRender.push({ value: overallValue, color: colors[0].color });
  }

  // Calculate rotation for each segment based on cumulative progress
  let cumulativeRotation = -90; // Start from -90 degrees (top of the circle)
  const layersWithRotation = layersToRender.map((layer) => {
    const rotation = cumulativeRotation;
    cumulativeRotation += layer.value * 3.6; // Convert percentage to degrees
    return { ...layer, rotation };
  });

  return (
    <div className={classes.root}>
      {/* Base Grey Circular Progress */}
      <CircularProgress
        size={24}
        thickness={10}
        {...props}
        variant="determinate"
        className={classes.bottom}
        value={100}
      />

      {/* Render Each Progress Segment */}
      {layersWithRotation.map((layer, index) => (
        <CircularProgress
          key={index}
          size={24}
          thickness={10}
          {...props}
          variant="determinate"
          className={classes.progressLayer}
          style={{
            position: "absolute",
            left: 0,
            color: layer.color,
            transform: `rotate(${layer.rotation}deg) scaleX(1)`,
            transformOrigin: "center",
          }}
          value={layer.value}
        />
      ))}
    </div>
  );
};

export default ProgressCircle;
