export enum OrdersTableField {
  NAME = "name",
  USERS = "users",
  ITEMS = "line_items_count",
  SUPPLIER = "supplier",
  SENT_ON = "send_date",
  REQUEST = "rfq.display_name",
  REQUIRED_RECEPTION = "delivery_date",
  PROMISED_DELIVERY = "updated_delivery_date",
  STATE = "state",
  SHIPMENT = "shipment",
  NOTE = "internal_note",
  SHIPPING_NOTE = "shipping_note",
  EDIT_BUTTON = "editButton",
}

export enum OrdersTableSortField {
  RFQ_NAME = "rfq__rfq_name",
  RFQ_NUMBER = "rfq__rfq_number",
  SUPPLIER_NAME = "rfq__winner__name",
  OWNER_FIRST_NAME = "created_by__first_name",
  OWNER_LAST_NAME = "created_by__last_name",
  USERS = "created_by",
  CREATED_AT = "created_at",
  DEADLINE = "deadline",
}
