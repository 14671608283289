import React from "react";
import { Box, CircularProgress, Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SharedModalTypes } from "./types";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { selectBuyerProfilePartLoading } from "@next/modules/dashboard/redux/selectors";
import { ProjectFile } from "@next/modules/project/components/file/project-file";

export const SharedPartFileModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(SharedModalTypes.PART_FILE_MODAL));
  const buyerProfilePartLoading = useSelector(selectBuyerProfilePartLoading);
  const data = modal?.data as {
    part?: any;
    readOnly?: boolean;
    isBuyerProfilePart?: boolean;
    isRFQPart?: boolean;
    isRFQEditMode?: boolean;
    isOrderPart?: boolean;
    preSelectedFileId?: number;
  };

  if (!modal || !data) return null;

  const onClose = () => {
    dispatch(modalsActions.closeModal(SharedModalTypes.PART_FILE_MODAL));
  };

  return (
    <Dialog
      open={!!modal}
      onClose={onClose}
      closeAfterTransition
      fullWidth={true}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "100%",
        },
      }}
    >
      {data?.isBuyerProfilePart && buyerProfilePartLoading ? (
        <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <ProjectFile
          isRFQPart={data?.isRFQPart}
          isRFQEditMode={data?.isRFQEditMode}
          part={data?.part}
          readOnly={data?.readOnly}
          onClose={onClose}
          isOrderPart={data?.isOrderPart}
          isBuyerProfilePart={data?.isBuyerProfilePart}
          preSelectedFileId={data?.preSelectedFileId}
        />
      )}
    </Dialog>
  );
};
