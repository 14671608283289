import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTracking } from "react-tracking";

import { Field, Form, Formik } from "formik";
import { array, object } from "yup";
import { Box, Button, Chip, Grid, Popover } from "@mui/material";

import { FormikAutocomplete } from "../../../components/formik-autocomplete/formik-autocomplete.component";

export const TagForm = ({
  t,
  createPartTags,
  token,
  partPk,
  onclickCloseTagDialog,
  partTags,
  getAllTagsCreatedByCurrentCompany,
  tagsCreatedByCurrentCompany,
  tagsCreatedByCurrentCompanyLoading,
  getDefaultTagsList,
  tags,
}) => {
  const tracking = useTracking();

  const profile = useSelector((state) => state.profile);
  // use this state to open and close autocomplete dropdown
  const [open, setOpen] = useState(false);

  // use this state to control number of tags enter by the buyer
  const [isMaxTags, setIsMaxTags] = useState(false);

  // use this state to set tags length
  const [tagsLength, setTagsLength] = useState(0);

  // use this state to open popover
  const [anchorEl, setAnchorEl] = useState(null);

  // use this state to set keyword enter by user
  const [keyWord, setKeyWord] = useState("");

  // Use this state to set autocomplete loading
  const [isTagsListLoading, setIsTagsListLoading] = useState(false);

  // Use this useEffect to get all tags created by current company
  useEffect(() => {
    if (keyWord) {
      getAllTagsCreatedByCurrentCompany(token, keyWord);
    }
  }, [keyWord]);

  // get list of last 5 created tags
  useEffect(() => {
    getDefaultTagsList(tags);
  }, []);

  // Use this useEffect control loading on tags list
  useEffect(() => {
    if (tagsCreatedByCurrentCompanyLoading) {
      setIsTagsListLoading(true);
    } else if (tagsCreatedByCurrentCompany) {
      setIsTagsListLoading(false);
    }
  }, [tagsCreatedByCurrentCompanyLoading, tagsCreatedByCurrentCompany]);

  const validationSchema = object().shape({
    tags: array(),
  });

  const initialValues = {
    tags: partTags,
  };

  // use this function to submit tags and close tag dialog
  const onSubmitTags = (values) => {
    // get list of tags
    let tags = values["tags"].map((tag) => {
      let tagName = "";
      if (tag.name) {
        tagName = tag.name;
      }
      if (tag.inputValue) {
        tagName = tag.inputValue;
      }
      return tagName;
    });
    onclickCloseTagDialog();

    createPartTags(token, partPk, tags, t);
  };

  // Use this useEffect control tags length
  useEffect(() => {
    if (tagsLength >= 5) {
      setIsMaxTags(true);
    } else {
      setIsMaxTags(false);
    }
  }, [tagsLength]);

  // When user enter max tags display message
  const handleKeyDown = (event) => {
    if (event.key && isMaxTags) {
      setAnchorEl(event.currentTarget);
    }

    if (event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const addTagsFocusHandle = () => {
    tracking.trackEvent({
      scout_category: "part_tags",
      scout_feature_name: "add_part_tags",
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["username"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date(),
    });
  };

  return (
    <div className="c-tag-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange
        onSubmit={onSubmitTags}
      >
        {() => (
          <Form>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Field
                  tags={tags}
                  getDefaultTagsList={getDefaultTagsList}
                  name="tags"
                  component={FormikAutocomplete}
                  loading={isTagsListLoading}
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  onFocus={() => addTagsFocusHandle()}
                  options={isMaxTags ? [] : tagsCreatedByCurrentCompany}
                  setKeyWord={setKeyWord}
                  handleKeyDown={handleKeyDown}
                  variant="outlined"
                  color="secondary"
                  placeholder={t("selectALabelOrWriteToCreateOne")}
                  noOptionsText={isMaxTags ? t("tagLimitPerPartReached") : t("noTagFound")}
                  t={t}
                  multiple
                  id="selected-tags-demo"
                  renderTags={(value, getTagProps) => {
                    setTagsLength(value.length);
                    return value.map((option, index) => {
                      let label = "";
                      if (option["name"]) {
                        label = option["name"];
                      }
                      if (option["inputValue"]) {
                        label = option["inputValue"];
                      }
                      return <Chip size="small" label={label} {...getTagProps({ index })} />;
                    });
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className="c-tag-form--btn"
            >
              <Grid item>
                <Button color="primary" variant="contained" type="submit">
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Popover
        id={Boolean(anchorEl) ? "c-popover" : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box fontSize={14} className="c-popover--box">
          {t("tagLimitPerPartReached")}
        </Box>
      </Popover>
    </div>
  );
};
