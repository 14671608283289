import { Box, Divider, IconButton, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./rfq-creation-form-lite.styles";
import CloseIcon from "@mui/icons-material/Close";
import { DashboardCardIcon } from "@next/modules/dashboard/components/buyer-dashboard-card/buyer-dashboard-card-icon";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { AppTheme } from "layout/AppTheme";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "../../modals/types";
import { RFQDeleteConfirmationModal } from "../../modals/rfq-delete-confirmation-modal";

const RFQCreationFormLiteHeader: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(modalsActions.closeModal(WorkspaceModalTypes.RFQ_CREATION_LITE_DRAWER));
  };

  return (
    <div className={classes.header}>
      <Box display="flex" alignItems="center" justifyContent="space-between" px={2} py={1.5}>
        <Box display="flex" alignItems="center">
          <Box className={classes.rfqIcon}>
            <DashboardCardIcon iconColor={AppTheme.palette.primary.main}>
              <InsertDriveFileIcon />
            </DashboardCardIcon>
          </Box>
          <Typography variant="h6">{t("workspaceNext:rfqDrawer:title")}</Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <IconButton onClick={onClose} size="large">
            <CloseIcon className={classes.rfqDeleteIcon} />
          </IconButton>
        </Box>
      </Box>
      <Divider light />
      <RFQDeleteConfirmationModal />
    </div>
  );
};

export default RFQCreationFormLiteHeader;
