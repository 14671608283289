import { Box, styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "48px",
});

export const StyledTypography = styled(CustomTypography)({
  letterSpacing: "0.5px",
  lineHeight: "24px",
});
