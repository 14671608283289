import { connect } from "react-redux";
import { Part } from "./part.component";
import { workspaceActions } from "../../../services/workspace";
const mapStateToProps = (state) => ({
  partList: state.workspace.companyPartList,
  company: state.profile.company,
  token: state.profile.token,
});

const mapDispatchToProps = {
  deletePart: workspaceActions.deletePart,
  getPartById: workspaceActions.getPartById,
  clonePart: workspaceActions.clonePart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Part);
