import React, { useCallback, useMemo } from "react";
import { Box, Button, Chip, Divider, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

//icons
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallIcon from "@mui/icons-material/Call";

//profile components
import CompanyAvatar from "@next/modules/profile/components/company-profile-full/company-avatar";

//profile imports
import { useCompanyProfileData } from "./company-profile-full.hooks";
import { useStyles } from "./company-profile-full.styles";
import { CompanyProfileFullSkeleton } from "./company-profile-full.skeleton";

//common
import { AppTheme } from "layout/AppTheme";
import CompanyProfileFullProjects from "./company-profile-full-tabs/company-profile-full-projects";
import CompanyProfileFullShop from "./company-profile-full-tabs/company-profile-full-shop";
import CompanyProfileFullDocs from "./company-profile-full-tabs/company-profile-full-docs";
import CompanyProfileFullCerts from "./company-profile-full-tabs/company-profile-full-certs";
import { useQueryParam } from "@next/hooks/useQuery";
import { useDispatch, useSelector } from "react-redux";
import { getCompany } from "services/profile/profile.selectors";
import { CompanyType } from "components/common/table-list/types";
import { selectFavoriteSuppliers } from "@next/modules/workspace/redux/selectors";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { selectAddFavoriteSupplierLoading } from "@next/modules/workspace/redux/selectors";
import { selectRemoveFavoriteSupplierLoading } from "@next/modules/workspace/redux/selectors";
import CustomButton from "@next/components/custom-button/custom-button";
import CompanyFeatureWithIcon from "@next/modules/profile/components/company-profile-full/company-feature-with-icon/company-feature-with-icon";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { isEmpty } from "lodash";
import { buildStringWithComma } from "@next/utils/stringUtils";
import { RichTextEditor } from "@next/components/rich-text-editor";
import LocationMap from "@next/components/location-map/location-map";
import { copyToClipboard } from "@next/utils/browserUtils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { PremiumStyledBadge } from "@next/modules/dashboard/components/seller-dashboard-premium-status-card/seller-dashboard-premium-status-card";
import snackbarUtils from "@next/utils/snackbarUtils";

const CompanyProfileFull = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(AppTheme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const {
    companyProfile,
    companyProfileLoading,
    companyProfileError,
    manufacturingCapabilities,
    specializedServices,
    specializedSolutionsAndDistribution,
    industryExpertise,
  } = useCompanyProfileData();
  const favoriteSuppliers = useSelector(selectFavoriteSuppliers);
  const company = useSelector(getCompany);
  const addFavoriteLoading = useSelector(selectAddFavoriteSupplierLoading);
  const removeFavoriteLoading = useSelector(selectRemoveFavoriteSupplierLoading);

  const isFavorite = favoriteSuppliers.some((item) => item.pk === companyProfile?.id);
  const isBuyer = company?.type === CompanyType.DO;

  const tabs = useMemo(
    () => [
      {
        key: "projects",
        buttonText: t("profile:companyProfile:tabs:projects", {
          count: companyProfile?.past_projects_pictures?.length,
        }),
        heading: t("profile:companyProfile:projects"),
        component: <CompanyProfileFullProjects companyProfile={companyProfile} />,
        hidden: !companyProfile?.past_projects_pictures?.length,
      },
      {
        key: "shop",
        buttonText: t("profile:companyProfile:tabs:shop", {
          count: companyProfile?.shop_pictures?.length,
        }),
        heading: t("profile:companyProfile:shop"),
        component: <CompanyProfileFullShop companyProfile={companyProfile} />,
        hidden: !companyProfile?.shop_pictures?.length,
      },
      {
        key: "certifications",
        buttonText: t("profile:companyProfile:tabs:certifications", {
          count: companyProfile?.certificates?.length,
        }),
        heading: t("profile:companyProfile:certifications"),
        component: <CompanyProfileFullCerts companyProfile={companyProfile} />,
        hidden: !companyProfile?.certificates?.length,
      },
      {
        key: "documents",
        buttonText: t("profile:companyProfile:tabs:documents", {
          count: companyProfile?.documents?.length,
        }),
        heading: t("profile:companyProfile:documents"),
        component: <CompanyProfileFullDocs companyProfile={companyProfile} />,
        hidden: !companyProfile?.documents?.length,
      },
    ],
    [t, companyProfile]
  );
  const [tab, setTab] = useQueryParam("tab", tabs[0].key);
  const activeTab = useMemo(() => {
    const active = tabs.find((t) => t.key === tab) || tabs[0];
    return active.hidden ? tabs.find((item) => !item.hidden) || tabs[0] : active;
  }, [tab, tabs]);

  const handleFavoriteClick = useCallback(() => {
    if (isFavorite) {
      dispatch(workspaceNextActions.removeFavoriteSupplierRequest(companyProfile?.id as number));
    } else {
      dispatch(
        workspaceNextActions.addFavoriteSupplierRequest({
          pk: companyProfile?.id as number,
        })
      );
    }
  }, [isFavorite, companyProfile?.id]);

  if (companyProfileLoading || companyProfileError || !companyProfile) {
    return <CompanyProfileFullSkeleton />;
  }

  return (
    <div>
      <div className={classes.contentBox}>
        <CompanyAvatar
          avatarSrc={companyProfile.picture}
          companyName={companyProfile.name}
          bordered={false}
          showName={false}
        />
        <Box mt={2} display="flex" alignItems="center" flexWrap="wrap" gap="12px">
          <Typography variant="h5" style={{ fontSize: isMobile ? 24 : undefined }}>
            {companyProfile.name}
          </Typography>
          {companyProfile.is_premium && (
            <Chip
              className={classes.successTag}
              icon={<PremiumStyledBadge variant="dot" />}
              label={t("profile:companyProfile:openForNewOpportunities")}
            />
          )}
        </Box>
        {companyProfile.description ? (
          <Box mt={2}>
            <Typography variant="body1">{companyProfile.description}</Typography>
          </Box>
        ) : null}
        <Box display="flex" flexWrap="wrap" gap="1rem" mt={2}>
          <CompanyFeatureWithIcon
            hidden={
              isNaN(+(companyProfile.shop_floor_area ?? 0)) ||
              +(companyProfile.shop_floor_area ?? 0) <= 0
            }
            Icon={HomeOutlinedIcon}
            featureText={
              <span
                dangerouslySetInnerHTML={{
                  __html: t("profile:companyProfile:shopFloorArea", {
                    count: companyProfile.shop_floor_area,
                  }),
                }}
              />
            }
          />
          <CompanyFeatureWithIcon
            hidden={!companyProfile.number_employee}
            Icon={PersonOutlineOutlinedIcon}
            featureText={t("profile:companyProfile:employeeWithCount", {
              count: companyProfile.number_employee,
            })}
          />
          <CompanyFeatureWithIcon
            hidden={isEmpty(companyProfile.address)}
            Icon={LocationOnOutlinedIcon}
            featureText={buildStringWithComma([
              companyProfile.address?.street,
              companyProfile.address?.province,
              companyProfile.address?.city,
              companyProfile.address?.country,
              companyProfile.address?.postal_code,
            ])}
          />
          <CompanyFeatureWithIcon
            hidden={!companyProfile.website}
            Icon={LanguageOutlinedIcon}
            featureText={
              <Box
                onClick={() => {
                  if (companyProfile.website) {
                    copyToClipboard(companyProfile.website).then(() => {
                      snackbarUtils.toast(t("profile:companyProfile:URLCopiedToClipboard"));
                    });
                  }
                }}
                data-pid="linkCompanyProfileWebsite"
              >
                <Typography style={{ cursor: "pointer" }} variant="body2">
                  {companyProfile.website}
                </Typography>
              </Box>
            }
          />
        </Box>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          gap="12px"
          className={classes.ctaBar}
        >
          {isBuyer && (
            <CustomButton
              loading={addFavoriteLoading || removeFavoriteLoading}
              variant={isFavorite ? "outlined" : "contained"}
              color={isFavorite ? "default" : "primary"}
              startIcon={isFavorite ? <CheckIcon /> : <AddIcon />}
              onClick={handleFavoriteClick}
              data-pid="btnAddToList"
            >
              {isFavorite
                ? t("profile:companyProfile:inMyList")
                : t("profile:companyProfile:addToMyList")}
            </CustomButton>
          )}

          {companyProfile.email && (
            <CopyToClipboard
              text={companyProfile.email}
              onCopy={() => snackbarUtils.toast(t("common:copiedToClipboard"))}
            >
              <Button variant="outlined" startIcon={<MailOutlineIcon />} data-pid="btnProfileEmail">
                {companyProfile.email}
              </Button>
            </CopyToClipboard>
          )}

          {companyProfile.phone_number && (
            <CopyToClipboard
              text={companyProfile.phone_number}
              onCopy={() => snackbarUtils.toast(t("common:copiedToClipboard"))}
            >
              <Button variant="outlined" startIcon={<CallIcon />} data-pid="btnProfilePhoneNumber">
                {companyProfile.phone_number}
              </Button>
            </CopyToClipboard>
          )}
        </Box>
        {companyProfile.additional_details && (
          <Box className={classes.rteWrapper} mt={2}>
            <RichTextEditor
              readOnly
              defaultValue={
                companyProfile.additional_details_rich_text || companyProfile.additional_details
              }
              maxCharsToShow={500}
            />
          </Box>
        )}
      </div>

      {tabs.filter((item) => !item.hidden).length > 0 ? (
        <Box className={classes.contentBox} mt={3}>
          <Typography variant="h6">{t("profile:companyProfile:picturesAndDocuments")}</Typography>
          <Box
            my={2}
            display="flex"
            alignItems="center"
            gap="12px"
            width="100%"
            overflow="auto"
            pb={isMobile ? "12px" : undefined}
            position="relative"
          >
            {tabs
              .filter((item) => !item.hidden)
              .map((tab) => (
                <Button
                  onClick={() => setTab(tab.key)}
                  key={tab.key}
                  color={tab.key === activeTab.key ? "primary" : undefined}
                  variant={tab.key === activeTab.key ? "contained" : "outlined"}
                  style={{
                    borderRadius: 99,
                    whiteSpace: "nowrap",
                    minWidth: "unset",
                  }}
                  data-pid={`btnProfile${activeTab.key}`}
                >
                  {tab.buttonText}
                </Button>
              ))}
          </Box>
          {activeTab.component}
        </Box>
      ) : null}
      {manufacturingCapabilities.count > 0 && (
        <Box className={classes.contentBox} mt={3}>
          <Typography variant="h6">
            {`${t("profile:companyProfile:manufacturingCapabilities")} · ${
              manufacturingCapabilities.count
            }`}
          </Typography>
          <Box className={classes.capabilityContainer}>
            {Object.keys(manufacturingCapabilities.data).map((key) => (
              <Box className={classes.capabilityGroup}>
                <Divider />
                <Box className={classes.capabilitySubGroup}>
                  <Typography variant="subtitle2">{key}</Typography>
                  <Box className={classes.capabilitiesContainer}>
                    {manufacturingCapabilities.data[key].map((item: any) => (
                      <Chip label={item.name} key={item.pk} />
                    ))}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {specializedServices.count > 0 && (
        <Box className={classes.contentBox} mt={3}>
          <Typography variant="h6">
            {`${t("profile:companyProfile:specializedServices")} · ${specializedServices.count}`}
          </Typography>
          <Box className={classes.capabilityContainer}>
            {Object.keys(specializedServices.data).map((key) => (
              <Box className={classes.capabilityGroup}>
                <Divider />
                <Box className={classes.capabilitySubGroup}>
                  <Typography variant="subtitle2">{key}</Typography>
                  <Box className={classes.capabilitiesContainer}>
                    {specializedServices.data[key].map((item: any) => (
                      <Chip label={item.name} key={item.pk} />
                    ))}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {specializedSolutionsAndDistribution.count > 0 && (
        <Box className={classes.contentBox} mt={3}>
          <Typography variant="h6">
            {`${t(
              "profile:companyProfile:specializedSolutionsAndDistribution"
            )} · ${specializedSolutionsAndDistribution.count}`}
          </Typography>
          <Box className={classes.capabilityContainer}>
            {Object.keys(specializedSolutionsAndDistribution.data).map((key) => (
              <Box className={classes.capabilityGroup}>
                <Divider />
                <Box className={classes.capabilitySubGroup}>
                  <Typography variant="subtitle2">{key}</Typography>
                  <Box className={classes.capabilitiesContainer}>
                    {specializedSolutionsAndDistribution.data[key].map((item: any) => (
                      <Chip label={item.name} key={item.pk} />
                    ))}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {industryExpertise.count > 0 && (
        <Box className={classes.contentBox} mt={3}>
          <Typography variant="h6">
            {`${t("profile:companyProfile:industryExpertise")} · ${industryExpertise.count}`}
          </Typography>
          <Box className={classes.capabilityContainer}>
            {Object.keys(industryExpertise.data).map((key) => (
              <Box className={classes.capabilityGroup}>
                <Divider />
                <Box className={classes.capabilitySubGroup}>
                  <Typography variant="subtitle2">{key}</Typography>
                  <Box className={classes.capabilitiesContainer}>
                    {industryExpertise.data[key].map((item: any) => (
                      <Chip label={item.name} key={item.pk} />
                    ))}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {companyProfile.address?.latitude && companyProfile.address.longitude && (
        <Box className={classes.contentBox} mt={3}>
          <Typography variant="h6">{t("profile:companyProfile:map")}</Typography>
          <Box mt={3} borderRadius="8px" overflow="hidden" data-pid={"containerLocationMap"}>
            <LocationMap
              location={{
                lat: +companyProfile.address.latitude,
                lng: +companyProfile.address.longitude,
              }}
            />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default CompanyProfileFull;
