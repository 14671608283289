import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { Box, Chip, styled } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { CustomTypography } from "@next/components/custom-typography";

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  justifyContent: "space-between",
  alignItems: "center",
});

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
}));

const StyledInReviewIcon = styled(ErrorIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
  width: "24px",
  height: "24px",
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  width: "75px",
  height: "24px",
  backgroundColor: "rgba(255, 169, 44, 0.1)",
  "& .MuiChip-label": {
    color: theme.palette.warning.dark,
    fontSize: "12px",
  },
  marginLeft: "10px",
}));

type Props = {
  text: string;
  isCellInEditableView?: boolean;
} & GridRenderCellParams<any>;

const StyledPlaceholderTypography = styled(CustomTypography)(({ theme }) => ({
  color: `${theme.palette.text.secondary}`,
  letterSpacing: "0.4px",
}));

export const SpecCell: React.FC<Props> = (params) => {
  const { text, isCellInEditableView } = params;
  const isApprovalStatusOfSpecChangesRequested =
    params?.row?.approval_status === "changes_requested";

  return (
    <StyledBox>
      <ContentBox>
        {isApprovalStatusOfSpecChangesRequested ? <StyledInReviewIcon /> : null}

        {!isCellInEditableView ? (
          <Box sx={{ width: "117px" }}>{text}</Box>
        ) : text !== "" && text ? (
          <Box sx={{ width: "117px" }}>{text}</Box>
        ) : (
          <StyledPlaceholderTypography tooltipTitle={null}>
            Write in plain text...
          </StyledPlaceholderTypography>
        )}
      </ContentBox>

      {isApprovalStatusOfSpecChangesRequested ? <StyledChip label="To review" /> : null}
    </StyledBox>
  );
};
