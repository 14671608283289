import { frontendUrl } from "../../../urls";
import { history } from "../../../helpers/history";

export const handleCloseCreateDialog = ({ handleCloseModal }) => {
  history.push(frontendUrl.joinOrRegisterCompany);
  handleCloseModal("isCreateCompanyModalOpen");
};

export const handleCloseJoinDialog = ({ handleCloseModal }) => {
  history.push(frontendUrl.joinOrRegisterCompany);
  handleCloseModal("isJoinCompanyModalOpen");
};
