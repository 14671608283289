import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";
import { frontendUrl } from "urls";
import { Box, Typography } from "@mui/material";
import { AppTheme } from "layout/AppTheme";

type Props = {
  rfqPk?: number;
  rfqName?: string;
};

export const CreateMultiContractSummaryItemLink: React.FC<Props> = ({ rfqName, rfqPk }) => {
  return (
    <Box mt="12px" display="flex" alignItems="center">
      <Link
        target="_blank"
        to={`${frontendUrl.bundleView}/${rfqPk}?fromSplitOrInbox=true`}
        style={{
          lineHeight: "initial",
          textDecoration: "underline",
          display: "flex",
          alignItems: "center",
        }}
      >
        <OpenInNewIcon style={{ color: AppTheme.palette.primary.main }} />

        <Box ml="9px">
          <Typography variant="body2">{rfqName}</Typography>
        </Box>
      </Link>
    </Box>
  );
};
