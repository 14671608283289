export enum ProjectsPartField {
  NAME = "name",
  QUANTITY = "quantity",
  PROCESSES = "processes",
  ALLOY = "alloy",
  ADDITIONAL_DETAILS_RICH_TEXT = "additional_details_rich_text",
  ADDITIONAL_DETAILS = "additional_details",
  FILES = "files",
  DISPLAY_NAME = "rfq.display_name",
  PRICE = "price",
  QUOTATIONS_COUNT = "quotations_count",
  EDIT_BUTTON = "editButton",
  SELECTED_CHECKBOX = "selectedPartCheckbox",
}
