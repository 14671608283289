import React, { useEffect } from "react";
import { ErrorMessage, FormikProps } from "formik";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

import { ASLInvitedSupplier } from "./add-asl.types";
import { GenericTextField } from "components/generic-text-field/generic-text-field.component";
import CustomButton from "@next/components/custom-button";
import { useSelector } from "react-redux";
import { selectAddASLLoading } from "../../redux/selectors";
import { CURRENCY_OPTIONS } from "@next/utils/countryList";

const useStyles = makeStyles(() =>
  createStyles({
    fullWidth: {
      width: "100%",
    },
    group: {
      paddingTop: "24px",
      paddingBottom: "24px",
      paddingLeft: "24px",
    },
  })
);

type Props = {
  searchType: "no_user" | "companies" | "user_found" | "non_company_user" | "user_buyer" | "";
};

const AddASLDetailsForm: React.FC<FormikProps<ASLInvitedSupplier> & Props> = ({
  values,
  searchType,
  setFieldValue,
  errors,
  touched,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currencyOptions = [CURRENCY_OPTIONS.CAD, CURRENCY_OPTIONS.USD, CURRENCY_OPTIONS.EUR];
  const languageOptions = ["EN", "FR"];
  const loading = useSelector(selectAddASLLoading);
  const [initialCompanyName] = React.useState(values.companyName);

  useEffect(() => {
    setFieldValue("language", values.language);
  }, []);

  const isSearchUserType = () => searchType === "user_found" || searchType === "non_company_user";

  return (
    <>
      <Grid container spacing={2} className={classes.group}>
        <Grid item xs={12}>
          <GenericTextField
            name="email"
            label={t("workspaceNext:inviteSupplier:labelEmail")}
            value={values.email}
            disabled
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={4}>
          <GenericTextField
            name="firstName"
            label={t("workspaceNext:inviteSupplier:form:firstName")}
            value={values.firstName}
            disabled={isSearchUserType()}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={4}>
          <GenericTextField
            name="lastName"
            label={t("workspaceNext:inviteSupplier:form:lastName")}
            value={values.lastName}
            disabled={isSearchUserType()}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl
            className={classes.fullWidth}
            size="small"
            error={Boolean(touched.language && errors.language)}
          >
            <InputLabel id="invite-supplier-language-label">
              {t("workspaceNext:inviteSupplier:form:language")}
            </InputLabel>
            <Select
              labelId="invite-supplier-language-label"
              label={t("workspaceNext:inviteSupplier:form:language")}
              id="select-release-announcement"
              value={values.language}
              disabled={isSearchUserType()}
              onChange={(e) => setFieldValue("language", e.target.value)}
            >
              {languageOptions.map((lan) => (
                <MenuItem key={lan} value={lan}>
                  {lan}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              <ErrorMessage name="language" />
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.group}>
        <Grid item xs={12}>
          <GenericTextField
            name="companyName"
            label={t("workspaceNext:inviteSupplier:form:companyName")}
            value={values.companyName}
            disabled={!!initialCompanyName}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <GenericTextField
            name="vendorId"
            label={t("workspaceNext:inviteSupplier:form:vendorID")}
            value={values.vendorId}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.fullWidth} size="small">
            <InputLabel id="invite-supplier-currency-label">
              {t("workspaceNext:inviteSupplier:form:currency")}
            </InputLabel>
            <Select
              labelId="invite-supplier-currency-label"
              label={t("workspaceNext:inviteSupplier:form:currency")}
              id="select-release-announcement"
              value={values.currency}
              onChange={(e) => setFieldValue("currency", e.target.value)}
            >
              {currencyOptions.map((curr) => (
                <MenuItem key={curr} value={curr}>
                  {curr}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <CustomButton
          variant="contained"
          color="primary"
          disabled={!values}
          type="submit"
          size="large"
          loading={loading}
        >
          {t("common:add")}
        </CustomButton>
      </Grid>
    </>
  );
};

export default AddASLDetailsForm;
