import { Theme, Typography } from "@mui/material";
import React from "react";
import { CreateMultiContractSummaryItemLink } from "./create-multi-contract-summary-item-link";
import {
  getSummaryItemTitleIcons,
  getSummaryItemTitleText,
} from "./create-multi-contract-summary-item.utils";
import { SupplierKeyValue } from "./create-multi-contract-table.types";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(3),
      borderBottom: `solid 1px ${theme.palette.divider}`,
      paddingBottom: theme.spacing(3),
    },
    title: {
      display: "flex",
    },
    titleText: { paddingLeft: theme.spacing(1) },
  })
);
type Props = {
  supplier?: SupplierKeyValue;
  partsCount?: number;
  rfq?: any;
};

export const CreateMultiContractSummaryItem: React.FC<Props> = ({ supplier, partsCount, rfq }) => {
  const classes = useStyles();

  return (
    <div className={classes?.root}>
      <div className={classes.title}>
        {getSummaryItemTitleIcons(supplier)}

        <Typography variant="body1" className={classes.titleText}>
          {getSummaryItemTitleText(supplier as SupplierKeyValue, partsCount as number)}
        </Typography>
      </div>

      {rfq?.rfq_display_name && (
        <CreateMultiContractSummaryItemLink rfqPk={rfq?.pk} rfqName={rfq?.rfq_display_name} />
      )}
    </div>
  );
};
