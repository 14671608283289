import React from "react";

import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { fieldToTextField } from "formik-material-ui";
import { createFilterOptions } from "@mui/material/useAutocomplete";

export const FormikAutocomplete = ({
  textFieldProps,
  placeholder,
  setKeyWord,
  variant,
  color,
  loading,
  handleKeyDown,
  t,
  tags,
  getDefaultTagsList,
  ...props
}) => {
  const {
    form: { setTouched, setFieldValue },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;
  // material ui function create filter option
  const filter = createFilterOptions();

  return (
    <Autocomplete
      {...props}
      {...field}
      // get list of last 5 created tags
      onInputChange={(event, newInputValue) => {
        if (!newInputValue) getDefaultTagsList(tags);
      }}
      onChange={(_, newValue) => {
        if (typeof newValue === "string") {
          setFieldValue(name, newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setFieldValue(name, newValue.inputValue);
        } else {
          setFieldValue(name, newValue);
        }
      }}
      filterOptions={(options, params) => {
        setKeyWord(params.inputValue);

        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          //find index of existing tag
          let tagIndex = filtered.findIndex((tag) => tag.name === params.inputValue);

          filtered.push({
            inputValue: params.inputValue,
            name: tagIndex === -1 && `${t("add")} "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option || "";
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue || "";
        }
        // Regular option
        return option.name || "";
      }}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      autoHighlight
      onBlur={() => setTouched({ [name]: true })}
      isOptionEqualToValue={(option, current) => option.name === current.name}
      size="small"
      renderInput={(params) => {
        params.inputProps.onKeyDown = handleKeyDown;
        return (
          <TextField
            variant={variant}
            {...params}
            {...textFieldProps}
            helperText={helperText}
            error={error}
            placeholder={placeholder}
            color={color}
            InputProps={{
              ...params.InputProps,

              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
};
