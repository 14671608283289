import { Announcement, AnnouncementsState } from "./types";

export const selectAnnouncements = (state: {
  announcementNext: AnnouncementsState;
}): Announcement[] => state.announcementNext.announcements;

export const selectAnnouncementsLoading = (state: {
  announcementNext: AnnouncementsState;
}): boolean => state.announcementNext.loading;

export const selectAnnouncementsError = (state: {
  announcementNext: AnnouncementsState;
}): string | null => state.announcementNext.error;

export const selectSelectedAnnouncement = (state: {
  announcementNext: AnnouncementsState;
}): Announcement | null => state.announcementNext.selectedAnnouncement;

export const selectSelectedAnnouncementLoading = (state: {
  announcementNext: AnnouncementsState;
}): boolean => state.announcementNext.announcementLoading;

export const selectSelectedAnnouncementError = (state: {
  announcementNext: AnnouncementsState;
}): string | null => state.announcementNext.announcementError;
