import React from "react";
import { useTranslation } from "react-i18next";

import { Box, SvgIcon, Theme, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DescriptionIcon from "@mui/icons-material/Description";

import { SgvPathIcons } from "assets/svg-path-icons";
import { createStyles, makeStyles } from "@mui/styles";

interface SuppliersStatsProps {
  stats: { quotations_count: number; itbs_count: number; views_count: number };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: { color: theme.palette.text.disabled },
  })
);

export const SuppliersStats: React.FC<SuppliersStatsProps> = ({ stats }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Box>
        <VisibilityIcon className={classes.icon} />
      </Box>
      <Box ml={0.5}>
        <Typography variant="body2">
          {`${t("quotation:viewWithCount", {
            count: stats?.views_count,
          })}`}
        </Typography>
      </Box>
      <Box ml={2} mt={0.5}>
        <SvgIcon className={classes.icon} fontSize="medium">
          <path d={SgvPathIcons.SVG_PATH_VECTOR_ICON} />
        </SvgIcon>
      </Box>
      <Box ml={0.5}>
        <Typography variant="body2">
          {`${t("quotation:supplierInterestedToQuote", {
            count: stats?.itbs_count,
          })}`}
        </Typography>
      </Box>
      <Box ml={2}>
        <DescriptionIcon className={classes.icon} />
      </Box>
      <Box ml={0.5}>
        <Typography variant="body2">
          {` ${t("quotation:quoteWithCount", {
            count: stats?.quotations_count,
          })}`}
        </Typography>
      </Box>
    </Box>
  );
};
