import { Grid, GridProps, Typography } from "@mui/material";
import FormikAutoComplete from "@next/components/formik-autocomplete/formik-autocomplete";
import { GenericTextInput } from "components/utils/input/text";
import { Field, Form, FormikProps } from "formik";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getMaterialsOptions,
  getProcessesOptions,
  getTreatmentOptions,
} from "services/part-configuration/part-configuration.selectors";
import {
  selectUploadedMyPartAttachments,
  selectUploadedMyPartAttachmentsLoading,
} from "../../redux/selectors";
import { workspaceNextActions } from "../../redux";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import useUpdateEffect from "@next/hooks/useUpdateEffect";
import { AttachFileControlled } from "@next/components/attach-file-controlled";

const PartFormBase: React.FC<FormikProps<any> & { createNew?: boolean }> = ({
  handleSubmit,
  values,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const materialsOptions = useSelector(getMaterialsOptions);
  const processesOptions = useSelector(getProcessesOptions);
  const treatmentOptions = useSelector(getTreatmentOptions);
  const uploadedMyPartAttachments = useSelector(selectUploadedMyPartAttachments);
  const uploadedMyPartAttachmentsLoading = useSelector(selectUploadedMyPartAttachmentsLoading);

  useUpdateEffect(() => {
    if (uploadedMyPartAttachments && uploadedMyPartAttachments?.length > 0) {
      setFieldValue("part_uploads_list", [
        ...(values.part_uploads_list || []),
        ...(uploadedMyPartAttachments as FileData[]),
      ]);
    }
  }, [uploadedMyPartAttachments]);

  const onAddFiles = (newFiles: File[]) => {
    dispatch(workspaceNextActions.uploadMyPartsAttachmentsRequest({ files: newFiles }));
  };

  const onDeleteFiles = (fileName: string) => {
    setFieldValue(
      "part_uploads_list",
      values.part_uploads_list.filter((f: any) => f.file_name !== fileName)
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <GridFormItem label={t("workspaceNext:myParts:table:header:name")}>
          <GenericTextInput
            name="name"
            value={values.name}
            fullWidth
            size="small"
            placeholder={t("workspaceNext:myParts:inputs:typeName")}
            hideHelperText={true}
            maxLength={49}
          />
        </GridFormItem>

        <GridFormItem label={t("workspaceNext:myParts:table:header:process")}>
          <Field
            name="processes"
            component={FormikAutoComplete}
            options={processesOptions}
            placeholder={
              values.processes?.length ? "" : t("workspaceNext:myParts:inputs:selectProcess")
            }
            multiple
            enableGrouping
          />
        </GridFormItem>

        <GridFormItem label={t("workspaceNext:myParts:table:header:material")}>
          <Field
            name="alloy"
            component={FormikAutoComplete}
            options={materialsOptions}
            placeholder={t("workspaceNext:myParts:inputs:selectMaterial")}
            enableGrouping
          />
        </GridFormItem>

        <GridFormItem label={t("workspaceNext:myParts:table:header:treatment")}>
          <Field
            name="treatment"
            component={FormikAutoComplete}
            options={treatmentOptions}
            placeholder={t("workspaceNext:myParts:inputs:selectTreatment")}
            enableGrouping
          />
        </GridFormItem>

        <GridFormItem label={t("workspaceNext:myParts:table:header:description")}>
          <GenericTextInput
            name="additional_details"
            value={values.additional_details}
            fullWidth
            size="small"
            placeholder={t("workspaceNext:myParts:inputs:typeDescription")}
          />
        </GridFormItem>

        <GridFormItem
          label={t("workspaceNext:myParts:table:header:attachments")}
          alignItems="flex-start"
          labelPadding
        >
          <AttachFileControlled
            files={values.part_uploads_list}
            onAddFiles={onAddFiles}
            onDeleteFile={onDeleteFiles}
            loading={uploadedMyPartAttachmentsLoading}
          />
        </GridFormItem>
      </Grid>
    </Form>
  );
};

export default PartFormBase;

const GridFormItem: React.FC<
  PropsWithChildren<GridProps & { label: string; labelPadding?: boolean }>
> = ({ children, label, labelPadding, ...props }) => (
  <Grid
    container
    item
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    {...props}
  >
    <Grid item xs={4} style={{ paddingTop: labelPadding ? "8px" : "0px" }}>
      <Typography variant="subtitle2">{label}</Typography>
    </Grid>
    <Grid item xs={8}>
      {children}
    </Grid>
  </Grid>
);
