import { Box, FormControlLabel, Radio, RadioGroup, styled } from "@mui/material";

export const StyledInnerBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  width: "100%",
});

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

export const StyledRadioGroup = styled(RadioGroup)({
  width: "59px",
  marginTop: "-16px",
});

export const StyledFormFieldBox = styled(Box)({
  width: "48%",
  display: "flex",
});

export const AttachmentBox = styled(Box)({
  width: "250px",
});

export const StyledRadio = styled(Radio)(({ theme }) => ({
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.38)",
  },
  "&.Mui-checked.Mui-disabled": {
    color: theme.palette.primary.main,
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.87)",
  },
});

export const StyledTableWrapper = styled(Box)(() => ({
  margin: "0",
  overflowX: "auto",
}));
