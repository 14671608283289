import { useEffect } from "react";

export const useRemoveRootOverflow = ({ skip }: { skip: boolean }) => {
  useEffect(() => {
    const rootEl = document.getElementById("root");

    if (rootEl && !skip) {
      rootEl!.style!.overflow = "hidden";
    } else {
      rootEl!.style!.overflow = "auto";
    }

    return () => {
      if (rootEl) {
        rootEl!.style!.overflow = "auto";
      }
    };
  }, [skip]);
};
