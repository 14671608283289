import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { batch, useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "./types";
import { PartUpload, projectActions } from "../redux";
import { selectProjectCompanyPart, selectSelectedPartPk } from "../redux/selectors";
import { workspaceActions } from "services/workspace";
import { getToken } from "services/profile/profile.selectors";
import { selectRFQPart } from "services/workspace/workspace.selectors";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import CustomButton from "@next/components/custom-button";
import Modal from "@next/components/custom-modal";
import { selectOrderPartByPk } from "@next/modules/orders/redux/selectors";
import snackbarUtils from "@next/utils/snackbarUtils";

export const ProjectFileDeleteConfirmationModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(ProjectModalTypes.FILE_DELETE_CONFIRMATION_MODAL));
  const data = modal?.data as {
    deleteFilePk: number;
    isRFQPart?: boolean;
    isRFQEditMode?: boolean;
    isOrderPart?: boolean;
    customDeleteHandler?: () => void;
    customUpdateHandler?: (filePK: number) => void;
  };
  const token = useSelector(getToken);

  const selectedPartPk = useSelector(selectSelectedPartPk) as number;
  let selectedPart = useSelector(
    data?.isRFQPart
      ? selectRFQPart(selectedPartPk)
      : data?.isOrderPart
        ? selectOrderPartByPk(selectedPartPk)
        : selectProjectCompanyPart(selectedPartPk)
  );
  const closeModal = () => {
    dispatch(modalsActions.closeModal(ProjectModalTypes.FILE_DELETE_CONFIRMATION_MODAL));
  };

  const onClickDeletePart = () => {
    if (data.customDeleteHandler instanceof Function && data?.deleteFilePk === -1) {
      data.customDeleteHandler();
      closeModal();
      return;
    }

    if (data.customUpdateHandler instanceof Function && data?.isRFQEditMode) {
      data.customUpdateHandler(data?.deleteFilePk);
      closeModal();
      return;
    }

    const newFileIds =
      selectedPart?.part_uploads_list
        .map((item: PartUpload) => item.pk)
        .filter((item: number) => item !== data?.deleteFilePk) || [];

    const newData = {
      files: newFileIds,
    };

    if (data?.isRFQPart) {
      batch(() => {
        dispatch(workspaceActions.editPart(token, newData, selectedPartPk, t, true));
        dispatch(workspaceNextActions.setShowRevisionStatusBar(true));
      });
    } else {
      dispatch(
        projectActions.updateProjectPartRequest({
          pk: selectedPartPk as number,
          part: newData,
          skipSuccessToast: data?.isOrderPart,
          onSuccess: () => {
            if (data?.isOrderPart) {
              snackbarUtils.success(t("orders:orderDetail:fileRemoved"));
            }
          },
        })
      );
    }

    closeModal();
  };

  const ModalBody = () => {
    return (
      <div>
        <Typography variant="body1" marginBottom="16px">
          {t("workspaceNext:thisActionIsIrreversible")}
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="flex-end" gap="16px">
          <CustomButton variant="outlined" color="secondary" onClick={closeModal} autoFocus>
            {t("common:cancel")}
          </CustomButton>
          <CustomButton variant="contained" color="error" onClick={onClickDeletePart}>
            {t("common:delete")}
          </CustomButton>
        </Box>
      </div>
    );
  };

  return (
    <Modal
      isModalOpen={!!modal}
      handleModalClose={closeModal}
      modalState={{
        modalHeader: t("workspace:deleteThisFile"),
        modalBody: ModalBody(),
      }}
    />
  );
};
