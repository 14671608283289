import * as yup from "yup";
import i18n from "assets/configi18n/i18n";

const t = i18n.t.bind(i18n);

export type TRecoverPasswordFormState = {
  email: string;
};

export const recoverPasswordValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required(t("auth:error:errorEmailMissing"))
    .email(t("auth:error:errorEmailInvalid")),
});
export const recoverPasswordFormState: TRecoverPasswordFormState = {
  email: "",
};
