import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from "@mui/material";

import { getCompany, getUser } from "services/profile/profile.selectors";
import { Subscription, SubscriptionProps, profileNextActions } from "@next/modules/profile/redux";

import { getInitialSubscriptionState } from "./switch-subscription.utils";

const SwitchSubscription: React.FC<SubscriptionProps> = ({ subscriptions, title }) => {
  const tracking = useTracking();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const company = useSelector(getCompany);
  const user = useSelector(getUser);

  const [subscriptionState, setSubscriptionState] = useState(
    getInitialSubscriptionState(subscriptions)
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, subscription: Subscription) => {
    setSubscriptionState({
      ...subscriptionState,
      [event.target.name]: event.target.checked,
    });
    dispatch(
      profileNextActions.enableSubscriptionRequest({
        name: subscription.name,
        subPk: subscription.pk,
        isEnabled: event.target.checked,
      })
    );

    /**
     * Feature tracking
     */

    tracking.trackEvent({
      scout_category: "feature",
      scout_feature_name: "user_subscription",
      scout_company: company?.name,
      scout_username: user.username,
      scout_user_type: user.type,
      scout_user_subscription_name: subscription.name,
      scout_user_subscription_value: event.target.checked,
      scout_date: new Date(),
    });
  };

  return (
    <FormControl component="fieldset">
      <Box mb="8px">
        <FormLabel component="legend" focused={false}>
          {`${t(`profile:notifications:${title}`)} : `}
        </FormLabel>
      </Box>

      <FormGroup>
        {subscriptions && subscriptions.length > 0 ? (
          subscriptions.map((subscription: Subscription) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={subscriptionState[subscription.name]}
                    onChange={(e) => handleChange(e, subscription)}
                    name={subscription.name}
                  />
                }
                label={<Typography variant="body1">{subscription.name}</Typography>}
              />
            );
          })
        ) : (
          <Typography variant="body1">{t("profile:notifications:noNotificationsYet")}</Typography>
        )}
      </FormGroup>
    </FormControl>
  );
};

export default SwitchSubscription;
