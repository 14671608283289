import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { SideDrawerThreadCard } from "./side-drawer-thread-card/side-drawer-thread-card";
import { SideDrawerReviewBox } from "../side-drawer-review-box/side-drawer-review-box";
import {
  A2psThreadLabel,
  MessageThread,
  PosEndUsersFormKeyMapping,
  Step,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectCurrentMessageThread,
  selectIsShowReviewBoxForThreadfulReview,
  selectMessageThreadsOfStep,
  selectTriggerForFetchingMessagesOfThread,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./side-drawer-threadful-review.styled";

export const SideDrawerThreadfulReview: React.FC = () => {
  const dispatch = useDispatch();
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const activeFormStep: Step = steps[activeFormStepIndex];
  const triggerStateForFetchingMessagesOfThread = useSelector(
    selectTriggerForFetchingMessagesOfThread
  );
  const isShowReviewBoxForThreadfulReview = useSelector(selectIsShowReviewBoxForThreadfulReview);
  const messageThreads: MessageThread[] = useSelector(selectMessageThreadsOfStep);
  const currentMessageThread: MessageThread = useSelector(selectCurrentMessageThread);

  useEffect(() => {
    if (
      currentMessageThread &&
      currentMessageThread?.id !== null &&
      isShowReviewBoxForThreadfulReview
    ) {
      dispatch(
        vendorManagementActions.fetchMessagesOfThreadRequest({
          messageThreadId: currentMessageThread.id,
        })
      );
    }
  }, [isShowReviewBoxForThreadfulReview, triggerStateForFetchingMessagesOfThread]);

  const onClickThreadCard = (thread: MessageThread) => {
    dispatch(vendorManagementActions.setCurrentMessageThread(thread));
    dispatch(vendorManagementActions.setIsShowReviewBoxForThreadfulReview(true));
    dispatch(vendorManagementActions.setIsFetchSpecByClickedThread(true));

    if (activeFormStep.step_name === PosEndUsersFormKeyMapping.A2psProcessControl) {
      dispatch(
        vendorManagementActions.setNameOfTableToFetchSpecByClickedThread(
          A2psThreadLabel[thread.entity_name]
        )
      );
    } else if (activeFormStep.step_name === PosEndUsersFormKeyMapping.Subtiers) {
      dispatch(
        vendorManagementActions.setNameOfTableToFetchSpecByClickedThread(thread?.supplier_title)
      );
    }
  };

  const ThreadCards = messageThreads?.map((thread) => (
    <SideDrawerThreadCard
      key={thread.id}
      id={thread.id}
      is_resolved={thread.is_resolved}
      title={thread.title}
      entity_id={thread.entity_id}
      entity_name={thread.entity_name}
      line_deleted={thread?.line_deleted}
      supplier_title={thread?.supplier_title}
      onClick={() => onClickThreadCard(thread)}
    />
  ));

  const NoThreadBox = () => (
    <S.NoCommmentBox>
      <Typography variant="body2">
        {'No thread yet, click on "review" on a line to start a review.'}
      </Typography>
    </S.NoCommmentBox>
  );

  return isShowReviewBoxForThreadfulReview ? (
    <SideDrawerReviewBox isThreadful={true} />
  ) : messageThreads && messageThreads?.length > 0 ? (
    ThreadCards
  ) : (
    <NoThreadBox />
  );
};
