import { Box, SwipeableDrawer, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const StyledBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  right: 0,
  top: 72,
  bottom: 72,
  width: "72px",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  borderLeft: `1px solid ${theme.palette.divider}`,
}));

export const StyledIconBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "72px",
  cursor: "pointer",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const StyledDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  ".MuiDrawer-paper": {
    width: "30%",
    top: 72,
    height: `calc(100vh - 144px)`,
    boxShadow: "none",
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
}));

export const StyledHeaderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "24px",
  borderBottom: `1px solid ${theme.palette.divider}`,
  height: "72px",
}));

export const StyledCloseIcon = styled(CloseIcon)({
  color: "black",
});
