import { axiosApi } from "@next/api";
import { backendUrl } from "urls";
import {
  CustomQuotationExportInput,
  CustomQuotationImportInput,
  IntentToNoQuoteInput,
  PublicItbItnbRequestInput,
} from "../redux";

const customQuotationExport = ({ rfqId }: CustomQuotationExportInput) =>
  axiosApi.get(`${backendUrl.rfqs}/${rfqId}/customQuotation/excelExport`, {
    responseType: "blob",
  });

const customQuotationImport = ({ rfqId, file }: CustomQuotationImportInput) => {
  const formData = new FormData();

  formData.append("file", file);

  return axiosApi.post(`${backendUrl.rfqs}/${rfqId}/customQuotation/excelProbe`, formData);
};
const intentToNoQuote = ({ noQuoteReason, rfqId, reasonDescription }: IntentToNoQuoteInput) => {
  if (noQuoteReason && rfqId) {
    const body: Record<string, any> = {
      reason: noQuoteReason,
    };
    if (reasonDescription) body.description = reasonDescription;
    return axiosApi.post(`${backendUrl.market}${rfqId}/intentToNotBids`, body);
  } else {
    return axiosApi.delete(`${backendUrl.market}${rfqId}/intentToNotBids`);
  }
};

const savePublicItbItnb = ({ token, ...payload }: PublicItbItnbRequestInput) => {
  return axiosApi.post(`${backendUrl.marketplaceQuoteNoQuote}${token}/`, payload);
};

export const marketplaceService = {
  customQuotationExport,
  customQuotationImport,
  intentToNoQuote,
  savePublicItbItnb,
};
