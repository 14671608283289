import snackbarUtils from "@next/utils/snackbarUtils";

export const showIncompleteStepsMessageForScopeForm = (
  stepNames: string[] // max length 2
) => {
  let message = "";

  if (stepNames.length > 1) {
    message = `Due to this change, '${stepNames[0]}' and '${stepNames[1]}' steps are now incomplete.`;
  } else if (stepNames.length === 1) {
    message = `Due to this change, '${stepNames[0]}' step is now incomplete.`;
  }

  return snackbarUtils.warning(message);
};
