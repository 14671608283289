import React from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { Button, Grid } from "@mui/material";
import { SimpleTextField } from "components/utils/text-field/text-field.component";
import { TLoginFormState } from "./login-form-definitions";
import { useDispatch } from "react-redux";
import { appActions } from "@next/modules/app/redux";
import { GenericPasswordField } from "components/generic-password-field/generic-password-field.component";

export const LoginFormBase: React.FC<FormikProps<TLoginFormState>> = ({
  values,
  errors,
  touched,
  handleSubmit,
  handleChange,
  handleBlur,
  isValidating,
}) => {
  const { email, password } = values;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const checkUserEmail = (email: string) => {
    if (email?.length >= 6 && !errors.email && !isValidating) {
      // Check if it is a valid e-mail.
      dispatch(appActions.checkUserEmailRequest({ email }));
    }
  };

  return (
    <form onSubmit={handleSubmit} className="c-login" autoComplete="off">
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid container item xs={8} className="c-login--field">
          <SimpleTextField
            name="email"
            type="email"
            value={email}
            label={t("auth:login:email")}
            placeholder={t("auth:login:email")}
            helperText={touched.email ? errors.email : ""}
            error={touched.email && Boolean(errors.email)}
            onChange={handleChange}
            onBlur={() => handleBlur("email") && checkUserEmail(email)}
            autoComplete
            id="email"
          />
        </Grid>

        <Grid container item xs={8} className="c-login--field">
          <GenericPasswordField
            name="password"
            value={password}
            label={t("auth:login:password")}
            placeholder={t("auth:login:password")}
            helperText={touched.password ? errors.password : ""}
            error={touched.password && Boolean(errors.password)}
            handleChange={handleChange}
            id="password"
          />
        </Grid>

        <Grid container item xs={8} className="c-login--button">
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSubmit()}
            fullWidth={true}
            data-pid={"btnLogin"}
          >
            {t("auth:login:login")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
