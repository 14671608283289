import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid } from "@mui/material";

import Modal from "@next/components/custom-modal";
import { modalsActions } from "../../../services/modals/modals.actions";

import inboxFr from "../../../assets/img/inbox-fr.gif";
import inboxEn from "../../../assets/img/inbox-en.gif";

export const AboutFeatureModal = () => {
  const dispatch = useDispatch();
  const { hideModal } = modalsActions;
  const { t } = useTranslation("aboutMessenger");
  const isModalOpen = useSelector((state) => state.modals.isAboutFeatureModalOpen);
  const handleModalClose = () => {
    dispatch(hideModal("isAboutFeatureModalOpen"));
  };

  return (
    <section className="c-about-feature" align="center">
      <Modal
        handleModalClose={() => handleModalClose()}
        modalState={{
          modalHeader: t("modalTitle"),
          modalBody: <ModalBody t={t} handleModalClose={handleModalClose} />,
        }}
        isModalOpen={isModalOpen}
        isSplashModal={true}
      />
    </section>
  );
};

const ModalBody = ({ t, handleModalClose }) => {
  const language = useSelector((state) => state.profile.user.language);

  return (
    <Grid
      container
      item
      xs={9}
      direction="row"
      justifyContent="center"
      style={{ margin: "20px auto" }}
    >
      <Box color="#000000DE" mb={2} fontSize={16} letterSpacing={0.5}>
        {t("modalDescription")}
      </Box>
      <img src={language === "en" ? inboxEn : inboxFr} width="100%" />
      <Box color="#000000DE" mt={2} mb={3} fontSize={16} letterSpacing={0.5}>
        <ul>
          <li>{t("descriptionItem1")}</li>
          <li>{t("descriptionItem2")}</li>
          <li>{t("descriptionItem3")}</li>
        </ul>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          className="c-about-feature--btn"
          variant="contained"
          onClick={() => handleModalClose()}
        >
          {t("gotIt")}
        </Button>
      </Box>
    </Grid>
  );
};
