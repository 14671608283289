import { Box } from "@mui/material";
import { formatDate } from "helpers/formatDate";
import React from "react";
import Chart from "react-apexcharts";

type Props = {
  viewsData: {
    series: number[];
    xaxis: string[];
  };
};

export const SellerDashboardViewsGraph: React.FC<Props> = ({ viewsData }) => {
  return (
    <Box width="100%">
      <Chart
        height={"300px"}
        options={{
          chart: {
            id: "basic-bar",
            width: "100%",
            type: "line",
            toolbar: {
              show: false,
            },
          },
          xaxis: {
            categories: viewsData?.xaxis,
            labels: {
              formatter: function (value) {
                return formatDate(value, "DD MMM");
              },
            },
          },
          dataLabels: {
            enabled: true,
          },
        }}
        series={[
          {
            name: "Views",
            data: viewsData?.series,
          },
        ]}
      />
    </Box>
  );
};
