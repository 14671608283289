import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { convertObjectToFiles } from "@next/utils/fileUtils";
import { t } from "assets/configi18n/i18n";
import * as yup from "yup";

export const getRevisePoFormInitialValues = (values: { file?: FileData }) => {
  return {
    file: values.file ? convertObjectToFiles([values.file])[0] : undefined,
  };
};

export const validationSchema = () => {
  return yup.object({
    file: yup.mixed().required(t("common:error:thisFieldIsRequired")),
  });
};
