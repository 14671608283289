import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  getPublicRfqFormInitialValues,
  getPublicRfqFormValidationSchema,
} from "./public-rfq-landing-step-form.definitions";
import { PublicRFQFormValuesType } from "./public-rfq-landing-step-form.types";
import { PublicRfqLandingStepFormBase } from "./public-rfq-landing-step-form.base";

type Props = {
  onSubmit: (values: PublicRFQFormValuesType) => void;
};

const PublicRfqLandingStepForm: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      render={(props) => <PublicRfqLandingStepFormBase {...props} />}
      initialValues={getPublicRfqFormInitialValues()}
      onSubmit={onSubmit}
      validationSchema={getPublicRfqFormValidationSchema(t)}
      enableReinitialize
      validateOnMount
    />
  );
};

export default PublicRfqLandingStepForm;
