import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@next/components/custom-modal";
import { ThankYouModalBody } from "./thank-you-modal-body";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { VendorManagementModalTypes } from "@next/modules/vendor-management/redux";
import { selectIdentificationData } from "@next/modules/vendor-management/redux/selectors";

export const ThankYouModal: React.FC = () => {
  const dispatch = useDispatch();
  const identificationData = useSelector(selectIdentificationData);
  const isFormValidated = identificationData?.is_form_validated;
  const modal = useSelector(getModal(VendorManagementModalTypes.THANK_YOU_MODAL));

  const onClose = () => {
    if (isFormValidated) {
      dispatch(modalsActions.closeModal(VendorManagementModalTypes.THANK_YOU_MODAL));
    }
  };

  return (
    <Modal
      handleModalClose={onClose}
      modalState={{
        modalBody: <ThankYouModalBody />,
      }}
      isModalOpen={!!modal}
      customWidth="100%"
      dialogProps={{ fullWidth: true, maxWidth: "md" }}
    />
  );
};
