/**
 *  Create or edit a company information.
 */

// General Imports
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Avatar from "@mui/material/Avatar";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MuiPhoneNumber from "material-ui-phone-number";

import { Form, Formik, useField } from "formik";
import * as yup from "yup";
import { phoneRegExp, postalCodeRegex, urlRegex } from "../../../helpers/formRegex";
import { profileService } from "../../../services/profile";
import { handlePostalCode } from "../../../helpers/handle-postal-code";

const GenericTextField = ({ placeholder, rows, label, handleOnBlur, disabled, ...props }) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <TextField
      placeholder={placeholder}
      label={label}
      rows={rows}
      multiline={!!rows}
      helperText={errorText}
      error={!!errorText}
      id="outlined-basic"
      variant="outlined"
      className="text"
      color="secondary" /** The focus color */
      {...field}
      disabled={disabled}
      onBlur={handleOnBlur ? handleOnBlur : null}
    />
  );
};

export default function CompanyForm(props) {
  const { onClose, open } = props;
  const [companyPicture, setCompanyPicture] = useState("");
  const { t } = useTranslation("userCompany");
  const [showLoader, setShowLoader] = useState(false);

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("theNameOfTheCompanyIsRequired")),
    address: yup.object().shape({
      street: yup.string().required(t("theStreetIsRequired")),
      city: yup.string().required(t("theCityIsRequired")),
      province: yup.string().required(t("provinceIsRequired")),
      postal_code: yup
        .string()
        .required(t("postalCodeIsRequired"))
        .matches(postalCodeRegex, t("pleaseEnterAValidPostalCode")),
      country: yup.string().required(t("countryIsRequired")),
    }),
    phone_number: yup
      .string()
      .required(t("thePhoneNumberIsRequired"))
      .matches(phoneRegExp, t("pleaseEnterAValidPhoneNumber")),
    website: yup
      .string()
      .matches(urlRegex, t("invalidWebsiteTheFormatShouldBeSimilarTo"))
      .required(t("theWebsiteIsRequired")),
    description: yup.string(),
  });

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="false"
      className="c-company-form"
    >
      <DialogTitle id="simple-dialog-title">
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid item xs={11}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item>
                      <Typography variant="h6">{t("companySettings")}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className="close-icon" onClick={onClose}>
                  <CloseIcon />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DialogTitle>
      <Divider className="divider" />
      <Formik
        initialValues={{
          name: props.company ? props.company.name : "",
          address: {
            street: props.company.address ? props.company.address.street : "",
            city: props.company.address ? props.company.address.city : "",
            province: props.company.address ? props.company.address.province : "",
            postal_code: props.company.address ? props.company.address.postal_code : "",
            country: props.company.address ? props.company.address.country : "",
          },
          phone_number: props.company ? props.company.phone_number : "",
          website: props.company ? props.company.website : "",
          description: props.company ? props.company.description : "",
          picture: null,
        }}
        validationSchema={validationSchema}
        onSubmit={(data) => {
          const { user, token } = props.profile;
          const { postal_code, ...restProps } = data.address;
          // allow lower case postal code in the field and converting it to upper case while sendnig to API
          const formattedAddress = Object.assign(
            {},
            {
              postal_code: postal_code.toUpperCase(),
            },
            { ...restProps }
          );
          let formData = new FormData();
          formData.append("username", user.username);
          formData.append("name", data.name);
          formData.append("address", JSON.stringify(formattedAddress));
          formData.append("phone_number", data.phone_number);
          formData.append("website", data.website);
          formData.append("description", data.description);

          if (typeof data.picture !== "string" && data.picture != null) {
            // converting the blob into a file to send it to the backend
            formData.append("picture", data.picture);
          }

          if (props.isEditForm) {
            props.updateCompany(props.profile.company.id, token, formData, t);
          } else {
            formData.append("type", user.type);
            props.createCompany(token, formData, t);
          }
        }}
      >
        {({
          setFieldValue,
          handleSubmit,
          touched,
          errors,
          handleChange,
          /* and other goodies */
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <DialogContent>
                {/** Profile/Avatar */}
                <Grid container xs={12} direction="row" spacing={3}>
                  <Grid item>
                    <IconButton
                      variant="contained"
                      component="span"
                      aria-controls="Company"
                      aria-haspopup="true"
                      size="large"
                    >
                      <Avatar
                        alt={t("companyPictureProfile")}
                        src={companyPicture}
                        variant="circular"
                        className="avatar"
                      />
                    </IconButton>
                  </Grid>
                  <Grid item className="upload-picture-container">
                    <Typography className="upload-picture-typography">
                      <input
                        className="upload-picture-input"
                        id="picture"
                        name="picture"
                        type="file"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          if (FileReader) {
                            const reader = new FileReader();
                            reader.onload = function () {
                              setCompanyPicture(reader.result);
                            };
                            reader.readAsDataURL(file);
                          }
                          setFieldValue("picture", event.currentTarget.files[0]);
                        }}
                      />
                      <label htmlFor="picture">
                        <Link
                          className="upload-picture-link"
                          variant="text"
                          color="Default"
                          component="span"
                        >
                          {t("userCompany:uploadLogo")}
                        </Link>
                      </label>
                    </Typography>
                  </Grid>
                </Grid>
                {/** First column [Name, phone, website, description] */}
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Grid container spacing={3} direction="column">
                      <Grid item>
                        <GenericTextField name="name" label={t("companyName")} />
                      </Grid>
                      <Grid item>
                        <MuiPhoneNumber
                          name="phone_number"
                          label={t("phoneNumber")}
                          fullWidth
                          data-cy="user-phone"
                          defaultCountry={"ca"}
                          onlyCountries={["ca", "us"]}
                          variant="outlined"
                          className="phone-number"
                          color="secondary" /** The focus color */
                          value={props.company.phone_number}
                          helperText={touched.phone_number ? errors.phone_number : ""}
                          error={touched.phone_number && Boolean(errors.phone_number)}
                          onChange={(value) => {
                            setFieldValue("phone_number", value);
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <GenericTextField name="website" label={t("COMPANY:website")} />
                      </Grid>
                      <Grid item>
                        <GenericTextField rows="5" name="description" label={t("description")} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={3} direction="column">
                      <Grid item>
                        <GenericTextField
                          name="address.street"
                          label={t("streetAndCivicAddress")}
                        />
                      </Grid>
                      <Grid item className="c-company-form--postal-code">
                        <GenericTextField
                          name="address.postal_code"
                          label={t("postalCode")}
                          handleOnBlur={(event) =>
                            handlePostalCode(
                              event,
                              setFieldValue,
                              profileService.fetchAddressByPostalCode,
                              setShowLoader
                            )
                          }
                        />
                        {showLoader && (
                          <Grid item className="c-company-form--loader">
                            {/* overrding loader default height and width */}
                            <CircularProgress style={{ width: "30px", height: "30px" }} />
                          </Grid>
                        )}
                      </Grid>
                      <Grid item>
                        <GenericTextField
                          name="address.city"
                          label={t("city")}
                          disabled={showLoader}
                        />
                      </Grid>
                      <Grid item>
                        <GenericTextField
                          name="address.province"
                          label={t("province")}
                          disabled={showLoader}
                        />
                      </Grid>
                      <Grid item>
                        <GenericTextField
                          name="address.country"
                          label={t("country")}
                          disabled={showLoader}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" className="submit-button-container">
                  <Button
                    color={"primary"}
                    variant="contained"
                    type="submit"
                    disabled={props.isCompanySavingInProgress}
                    className="submit-button"
                  >
                    {t("save")}
                  </Button>
                </Grid>
              </DialogContent>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
