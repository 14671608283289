import { useEffect } from "react";

export const useEnterKeyListener = (
  onEnterKeyPress: ((values?: any) => void) | undefined,
  options?: {
    element?: Element | null;
    isCtrlEnter?: boolean;
  }
) => {
  const { element, isCtrlEnter } = options || {};

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      const conditions = [
        event?.keyCode === 13 || event?.code === "Enter" || event?.code === "NumpadEnter",
      ];
      if (isCtrlEnter) conditions.push(event?.ctrlKey);
      if (element) conditions.push(document.activeElement === element);
      conditions.push(!!onEnterKeyPress);

      if (conditions.every(Boolean)) {
        event.preventDefault();
        onEnterKeyPress && onEnterKeyPress();
      }
    };

    const elem = element || document;
    elem.addEventListener("keydown", listener as () => void);

    return () => {
      elem.removeEventListener("keydown", listener as () => void);
    };
  }, [onEnterKeyPress, element]);
};
