import React from "react";
import { useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";

export const CurrencyCell = (props) => {
  const language = useSelector((state) => state.profile.user.language);
  const currency = props?.currency || "$";
  const toFixedValue = props?.toFixedValue !== undefined ? props.toFixedValue : 2;

  return (
    <CurrencyFormat
      value={`${Number.parseFloat(props.value).toFixed(toFixedValue).replace(/\.00$/, "")} CAD`}
      displayType={"text"}
      thousandSeparator={language === "fr" ? " " : ","}
      decimalSeparator={language === "fr" ? "," : "."}
      isNumericString={true}
      prefix={props.hideCurrency ? "" : `${currency} `}
    />
  );
};
