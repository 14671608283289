import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import findIndex from "lodash/findIndex";
import { Modal, ModalsState } from "./modalsTypes";
import { RootState } from "store";

const initialState: ModalsState = {
  modals: [],
  modalsOk: [],
};

const modalsSlice = createSlice({
  name: "modalsNext",
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<Modal>) => {
      const foundIndex = findIndex(state.modals, { key: action.payload.key });

      if (foundIndex === -1) {
        // Add modal
        state.modals.push(action.payload);
      } else {
        // If the modal is already open, update the data
        state.modals = state.modals.map((modal) => {
          if (modal.key === action.payload.key) {
            return { ...modal, data: action.payload.data };
          }

          return modal;
        });
      }
    },
    closeModal: (state, { payload: key }: PayloadAction<Modal["key"]>) => {
      const foundIndex = findIndex(state.modals, { key });
      if (foundIndex !== -1) {
        // For now, hiding a modal will remove it
        state.modals.splice(foundIndex, 1);
      }
    },
    onModalOk: (state, action: PayloadAction<Modal>) => {
      const foundIndex = findIndex(state.modalsOk, { key: action.payload.key });

      if (foundIndex === -1) {
        // Add modalOk
        state.modalsOk.push(action.payload);
      } else {
        // If the modalOk is already open, update the data
        state.modalsOk = state.modalsOk.map((modal) => {
          if (modal.key === action.payload.key) {
            return { ...modal, data: action.payload.data };
          }

          return modal;
        });
      }
    },
  },
});

export const getModals = (state: RootState) => state.modalsNext.modals;
export const getModal = (key: string) => (state: RootState) =>
  state.modalsNext.modals.find((item) => item.key === key);
export const getModalOk = (key: string) => (state: RootState) =>
  state.modalsNext.modalsOk.find((item) => item.key === key);
export const getIsModelKeyExists = (key: string) => (state: RootState) =>
  state.modalsNext.modals.some((modal) => modal.key === key);

export const modalsActions = modalsSlice.actions;

export default modalsSlice.reducer;
