import { connect } from "react-redux";
import WorkspaceTabComponent from "./workspace-tab.component";
import { workspaceActions } from "../../services/workspace";
import { modalsActions } from "../../services/modals/modals.actions";

const mapDispatchToProps = {
  fetchCompanyPartList: workspaceActions.fetchCompanyParts,
  fetchCompanyPartsNextPage: workspaceActions.fetchCompanyPartsNextPage,
  handleOpenModal: modalsActions.showModal,
  handleCloseModal: modalsActions.hideModal,
  setSelectedParts: workspaceActions.setSelectedParts,
};

const mapStateToProps = (state) => ({
  token: state.profile.token,
  selectedTab: state.workspace.selectedTab,
  partList: state.workspace.companyPartList,
  partListHasNext: !!state.workspace.companyPartNextPage,
  company: state.profile.company,
  modalState: state.modals ? state.modals.isAddPartModalOpen : false,
  selectedParts: state.workspace.selectedParts,
  selectedTags: state.workspace.selectedTags,
  partNextPageLoading: state.workspace.companyPartNextPageLoading,
  isWorkspaceLoading: state.workspace.isWorkspaceLoading,
  isWorkspaceInitialLoading: state.workspace.isWorkspaceInitialLoading,
});

export const WorkspaceTabContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkspaceTabComponent);
