import React from "react";
import { Box, Grid, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SearchTextField } from "@next/components/search-text-field";
import { useSelector } from "react-redux";
import { selectMyPartsLoading } from "../../redux/selectors";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1.5, 0, 1.5),
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

type Props = {
  clearSearch?: () => void;
  setSearchValue?: (searchValue: string) => void;
  searchValue?: string;
  onClickNewPart?: () => void;
  selectedPartsLength?: number;
};

export const MyPartsTableToolbar: React.FC<Props> = ({
  clearSearch,
  setSearchValue,
  searchValue,
  selectedPartsLength,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const myPartsLoading = useSelector(selectMyPartsLoading);

  return (
    <Box color="inherit" className={classes.root}>
      <Grid container spacing={1} xs={8}>
        <Grid item>
          <SearchTextField
            clearSearch={clearSearch}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            disabled={myPartsLoading}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} justifyContent="flex-end" xs={4}>
        <Grid item alignItems="center">
          <Box mt="8px">
            <Typography variant="body2" color="textSecondary">
              {selectedPartsLength
                ? `${t("project:navbar:partSelectedWithCount", {
                    count: selectedPartsLength,
                  })} `
                : " "}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
