import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "../types";
import { SendOrderForm } from "../../components/send-order-form/send-order-form";
import { Drawer } from "@mui/material";
import SendOrderDrawerHeader from "./send-order-drawer-header";

export const SendOrderDrawer = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(OrdersModalTypes.SEND_ORDER_DRAWER));

  const onClose = () => {
    dispatch(modalsActions.closeModal(OrdersModalTypes.SEND_ORDER_DRAWER));
  };

  return (
    <Drawer PaperProps={{ style: { width: 840 } }} anchor="right" open={!!modal} onClose={onClose}>
      <SendOrderDrawerHeader />

      <SendOrderForm />
    </Drawer>
  );
};
