import { GenericUser } from "@next/modules/profile/redux";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCompanyUsers } from "services/profile/profile.selectors";

export const useRFQCollaborators = (collaborators?: GenericUser[], createdBy?: GenericUser) => {
  const companyUsers = useSelector(selectCompanyUsers);
  const owner: GenericUser = createdBy || companyUsers?.[0];

  const _collaborators = collaborators || [];
  const [arr, lookup] = useMemo(() => {
    const arr = [];
    const lookup = new Map<string, GenericUser>();

    for (let i = 0; i < (_collaborators.length || 0); i++) {
      const collaborator = _collaborators[i];
      const companyUser = companyUsers.find((item) => item.email === collaborator.email);

      const res = {
        ...collaborator,
        user_type: companyUser?.user_type || "viewer" || "restricted_viewer",
      };

      arr.push(res);
      lookup.set(collaborator.email as string, res);
    }

    return [arr, lookup];
  }, [_collaborators, companyUsers]);

  return { owner, collaborators: arr, lookup };
};
