import React from "react";
import { Chip, Theme, alpha } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EditIcon from "@mui/icons-material/Edit";
import { NewsfeedPost } from "../redux/types";
import { newsfeedPostTypeConstants } from "../redux/consts";
import { t } from "assets/configi18n/i18n";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  newCompanyChip: {
    color: theme.palette.success.dark,
    backgroundColor: alpha(theme.palette.success.light, 0.15),
    "& .MuiChip-avatar": {
      color: theme.palette.success.dark,
      width: 20,
      height: 20,
    },
  },
  announcementChip: {
    color: theme.palette.warning.dark,
    backgroundColor: alpha(theme.palette.warning.light, 0.15),
    "& .MuiChip-avatar": {
      color: theme.palette.warning.dark,
      width: 18,
      height: 18,
    },
  },
  industryNewsChip: {
    color: "black",
    backgroundColor: theme.palette.customBackground.light,
    "& .MuiChip-avatar": {
      color: "black",
      width: 18,
      height: 18,
    },
  },
}));

type Props = {
  post: NewsfeedPost;
};

const NewsfeedPostChip: React.FC<Props> = ({ post }) => {
  const classes = useStyles();
  let chipProps;

  switch (post.type) {
    case newsfeedPostTypeConstants.NEW_BUYER:
      chipProps = {
        avatar: <PersonAddIcon />,
        label: t("newsfeed:chips:newBuyer"),
        className: classes.newCompanyChip,
      };
      break;
    case newsfeedPostTypeConstants.NEW_SUPPLIER:
      chipProps = {
        avatar: <PersonAddIcon />,
        label: t("newsfeed:chips:newManufacturer"),
        className: classes.newCompanyChip,
      };
      break;
    case newsfeedPostTypeConstants.ANNOUNCEMENT:
      chipProps = {
        avatar: <CalendarTodayIcon />,
        label: t("newsfeed:chips:announcement"),
        className: classes.announcementChip,
      };
      break;
    case newsfeedPostTypeConstants.INDUSTRY_NEWS:
      chipProps = {
        avatar: <EditIcon />,
        label: t("newsfeed:chips:industryNews"),
        className: classes.industryNewsChip,
      };
      break;
    default:
      chipProps = {};
  }

  return <Chip {...chipProps} />;
};

export default NewsfeedPostChip;
