import React from "react";
import { Box, Button } from "@mui/material";
import create from "zustand";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "@next/components/confirmation-dialog/confirmation-dialog";
import { t } from "assets/configi18n/i18n";
import { AppTheme } from "layout/AppTheme";

type ConfirmDialogStore = {
  title: string;
  content: string;
  onSubmit?: () => void;
  close: () => void;
  yesText?: string;
  yesVariant?: "default" | "delete";
  cancelText?: string;
};

const useConfirmDialogStore = create<ConfirmDialogStore>((set: any) => ({
  title: "",
  content: "",
  onSubmit: undefined,
  close: () => set({ onSubmit: undefined }),
  yesText: t("RFQ:yes"),
  yesVariant: "default",
  cancelText: t("common:cancel"),
}));

export const confirmDialog = (
  title: string,
  content: string,
  onSubmit: () => void,
  yesText?: string,
  yesVariant?: "default" | "delete",
  cancelText?: string
) => {
  useConfirmDialogStore.setState({
    title,
    content,
    onSubmit,
    yesText,
    yesVariant,
    cancelText,
  });
};

const ConfirmDialog = () => {
  const { t } = useTranslation();
  const { title, content, onSubmit, close, yesText, yesVariant, cancelText } =
    useConfirmDialogStore();

  return (
    <ConfirmationDialog
      title={title}
      content={content}
      isOpen={Boolean(onSubmit)}
      handleClose={close}
      dialogActions={
        <Box padding="8px 16px 12px 16px" display={"flex"}>
          <Box mr="12px">
            <Button autoFocus onClick={close} color="secondary" variant="outlined">
              {cancelText || t("workspace:cancel")}
            </Button>
          </Box>

          <Button
            onClick={() => {
              if (onSubmit) {
                onSubmit();
              }
              close();
            }}
            style={
              yesVariant === "delete"
                ? {
                    backgroundColor: AppTheme.palette.error.main,
                    color: "white",
                  }
                : {}
            }
            color="primary"
            variant="contained"
          >
            {yesText || t("RFQ:yes")}
          </Button>
        </Box>
      }
    />
  );
};

export default ConfirmDialog;
