export const marketplaceConstants = {
  CREATE_QUOTATION_REQUEST: "CREATE_QUOTATION_REQUEST",
  CREATE_QUOTATION_SUCCESS: "CREATE_QUOTATION_SUCCESS",
  CREATE_QUOTATION_FAILURE: "CREATE_QUOTATION_FAILURE",

  FETCH_ACTIVE_RFQLIST_REQUEST: "FETCH_ACTIVE_RFQLIST_REQUEST",
  FETCH_ACTIVE_RFQLIST_SUCCESS: "FETCH_ACTIVE_RFQLIST_SUCCESS",
  FETCH_ACTIVE_RFQLIST_FAILURE: "FETCH_ACTIVE_RFQLIST_FAILURE",

  FETCH_RECOMMENDED_RFQLIST_REQUEST: "FETCH_RECOMMENDED_RFQLIST_REQUEST",
  FETCH_RECOMMENDED_RFQLIST_SUCCESS: "FETCH_RECOMMENDED_RFQLIST_SUCCESS",
  FETCH_RECOMMENDED_RFQLIST_FAILURE: "FETCH_RECOMMENDED_RFQLIST_FAILURE",

  FETCH_ACTIVE_RFQ_DETAILS_REQUEST: "FETCH_ACTIVE_RFQ_DETAILS_REQUEST",
  FETCH_ACTIVE_RFQ_DETAILS_SUCCESS: "FETCH_ACTIVE_RFQ_DETAILS_SUCCESS",
  FETCH_ACTIVE_RFQ_DETAILS_FAILURE: "FETCH_ACTIVE_RFQ_DETAILS_FAILURE",

  FETCH_HIDDEN_RFQLIST_REQUEST: "FETCH_HIDDEN_RFQLIST_REQUEST",
  FETCH_HIDDEN_RFQLIST_SUCCESS: "FETCH_HIDDEN_RFQLIST_SUCCESS",
  FETCH_HIDDEN_RFQLIST_FAILURE: "FETCH_HIDDEN_RFQLIST_FAILURE",

  FETCH_COMPANY_QUOTATIONS_REQUEST: "FETCH_COMPANY_QUOTATIONS_REQUEST",
  FETCH_COMPANY_QUOTATIONS_SUCCESS: "FETCH_COMPANY_QUOTATIONS_SUCCESS",
  FETCH_COMPANY_QUOTATIONS_FAILURE: "FETCH_COMPANY_QUOTATIONS_FAILURE",

  FETCH_COMPANY_CONTRACTS_REQUEST: "FETCH_COMPANY_CONTRACTS_REQUEST",
  FETCH_COMPANY_CONTRACTS_SUCCESS: "FETCH_COMPANY_CONTRACTS_SUCCESS",
  FETCH_COMPANY_CONTRACTS_FAILURE: "FETCH_COMPANY_CONTRACTS_SUCCESS",

  MARKETPLACE_HIDE_SHOW_PREFERENCES_REQUEST: "MARKETPLACE_HIDE_SHOW_PREFERENCES_REQUEST",
  MARKETPLACE_HIDE_SHOW_PREFERENCES_SUCCESS: "MARKETPLACE_HIDE_SHOW_PREFERENCES_SUCCESS",
  MARKETPLACE_HIDE_SHOW_PREFERENCES_FAILURE: "MARKETPLACE_HIDE_SHOW_PREFERENCES_FAILURE",

  MARKETPLACE_LIKE_DISLIKE_RECOMMENDED_REQUEST: "MARKETPLACE_LIKE_DISLIKE_RECOMMENDED_REQUEST",
  MARKETPLACE_LIKE_DISLIKE_RECOMMENDED_SUCCESS: "MARKETPLACE_LIKE_DISLIKE_RECOMMENDED_SUCCESS",
  MARKETPLACE_LIKE_DISLIKE_RECOMMENDED_FAILURE: "MARKETPLACE_LIKE_DISLIKE_RECOMMENDED_FAILURE",

  FETCH_STATS_REQUEST: "FETCH_STATS_REQUEST",
  FETCH_STATS_SUCCESS: "FETCH_STATS_SUCCESS",
  FETCH_STATS_FAILURE: "FETCH_STATS_FAILURE",

  SET_SELECTED_TAB_IN_SUBMISSIONS: "SET_SELECTED_TAB_IN_SUBMISSIONS",
  SET_SELLER_NDA_ATTACHMENTS: "SET_SELLER_NDA_ATTACHMENTS",
  CLEAR_ACTIVE_RFQ_DETAILS: "CLEAR_ACTIVE_RFQ_DETAILS",
  SET_RFQ_HAS_NDA: "SET_RFQ_HAS_NDA",
  SET_RFQ_DETAILS_LOADING: "SET_RFQ_DETAILS_LOADING",
  SET_NDA_STEPPER_INDEX: "SET_NDA_STEPPER_INDEX",

  GET_BUYER_STATS_BY_RFQ_ID_REQUEST: "GET_BUYER_STATS_BY_RFQ_ID_REQUEST",
  GET_BUYER_STATS_BY_RFQ_ID_SUCCESS: "GET_BUYER_STATS_BY_RFQ_ID_SUCCESS",
  GET_BUYER_STATS_BY_RFQ_ID_FAILURE: "GET_BUYER_STATS_BY_RFQ_ID_FAILURE",

  FETCH_INTENT_TO_BID_RFQLIST_REQUEST: "FETCH_INTENT_TO_BID_RFQLIST_REQUEST",
  FETCH_INTENT_TO_BID_RFQLIST_SUCCESS: "FETCH_INTENT_TO_BID_RFQLIST_SUCCESS",
  FETCH_INTENT_TO_BID_RFQLIST_FAILURE: "FETCH_INTENT_TO_BID_RFQLIST_FAILURE",

  SET_INTENT_TO_BID_DUE_DATE_SUCCESS: "SET_INTENT_TO_BID_DUE_DATE_SUCCESS",
  SET_ITNB_REASON_DATA: "SET_ITNB_REASON_DATA",

  INCREMENT_RFQ_QUESTIONS_COUNT: "INCREMENT_RFQ_QUESTIONS_COUNT",

  DECREMENT_RFQ_NEW_QA_MSG_COUNT: "DECREMENT_RFQ_NEW_QA_MSG_COUNT",

  ITN_ITNB_SUCCESS: "ITN_ITNB_SUCCESS",
};
