import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectMarketplaceActiveRFQsListCount } from "../../redux/selectors";
import { QuotationDialogContainer } from "components/quotation-dialog/quotation-dialog.container";
import { useTranslation } from "react-i18next";
import { Box, Button, Chip } from "@mui/material";
import { frontendUrl } from "urls";
import { useQueryParam } from "@next/hooks/useQuery";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RFQsStats } from "components/marketplace/marketplace-active-rfqs/rfqs-stats/rfqs-stats.component";
import MarketplaceStatsCard from "../marketplace-stats-card/marketplace-stats-card";
import { matchPath, useHistory } from "react-router-dom";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { quotationActions } from "services/quotation";
import { MarketplaceRequestsTable } from "../marketplace-requests-table/marketplace-requests-table";
import JoinPrivateB2BNetworkModal from "../../modals/join-private-b2b-network-modal";
import { RequestStatus, RequestType } from "../../redux";
import { marketplaceActions } from "services/marketplace";
import { NotificationsDialog } from "components/notifications-dialog/notifications-dialog.component";
import {
  selectMarketplaceCounts,
  selectMarketplaceCountsLoading,
} from "@next/modules/workspace/redux/selectors";
import { dashboardActions } from "@next/modules/dashboard/redux";
import { OnboardingSteps } from "@next/modules/dashboard/components/seller-dashboard-onboarding-premium/seller-dashboard-onboarding-premium";
import { CustomCircularProgress } from "@next/components/custom-circular-progress";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      position: "absolute",
      top: "60px",
      left: 0,
      width: "100%",
      height: "100%",
    },
    section: {
      display: "flex",
      flexDirection: "column",
      gridGap: "16px",
      height: "100%",
      padding: theme.spacing(3),
    },
    left: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    activeChip: {
      borderColor: theme.palette.primary.main,
    },
    colorSuccess: {
      color: theme.palette.success.main,
    },
    colorError: {
      color: theme.palette.error.main,
    },
  })
);

interface Props {}

const MarketplaceRequestsPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { location } = useHistory();
  const dispatch = useDispatch();

  const [notificationOpen, setNotificationOpen] = useState(false);
  const [type, setType] = useQueryParam("type", RequestType.DIRECT);
  const [status, setStatus] = useQueryParam("status", RequestStatus.ALL);
  const counts = useSelector(selectMarketplaceCounts);
  const counstLoading = useSelector(selectMarketplaceCountsLoading);
  const chipCounts = useSelector(selectMarketplaceActiveRFQsListCount);
  const activeRfqId = useMemo(
    () =>
      (
        matchPath(location.pathname, {
          path: `${frontendUrl.requestForQuotations}/:id`,
        })?.params as any
      )?.id,
    [location.pathname]
  );
  const firstTime = useRef(true);

  useEffect(() => {
    dispatch(quotationActions.setIsEditQuotationMode(false));
    if (activeRfqId) {
      dispatch(marketplaceActions.getActiveRfqDetails(activeRfqId));
      dispatch(
        dashboardActions.showPopUpOnboardingRequest(OnboardingSteps.VIEWING_REQUEST_MARKETPLACE)
      );
    }
  }, [activeRfqId]);

  useEffect(() => {
    if (!activeRfqId) {
      dispatch(workspaceNextActions.fetchMarketplaceCountsRequest());
    }
  }, [activeRfqId, type]);

  useEffect(() => {
    if (type === RequestType.DIRECT && counts.private_total === 0 && !counstLoading) {
      if (firstTime.current) firstTime.current = false;
      else setType(RequestType.PREMIUM);
    }
  }, [type, counts.private_total, counstLoading]);

  return (
    <Box className={classes.root}>
      {activeRfqId ? null : (
        <>
          <Box className={`${classes.left} ${classes.section}`}>
            {counts.private_total > 0 && (
              <MarketplaceStatsCard
                title={t("marketplaceRequests:directRequests:title")}
                description={t("marketplaceRequests:directRequests:desc")}
                count={counts.private_total}
                unreadCount={counts.private_unseen}
                isActive={type === RequestType.DIRECT}
                onClick={() => setType(RequestType.DIRECT)}
              />
            )}
            <MarketplaceStatsCard
              title={t("marketplaceRequests:premiumRequests:title")}
              description={t("marketplaceRequests:premiumRequests:desc")}
              count={counts.public_total}
              unreadCount={counts.public_unseen}
              isPro
              isActive={type === RequestType.PREMIUM}
              onClick={() => setType(RequestType.PREMIUM)}
            />
            <MarketplaceStatsCard
              title={t("marketplaceRequests:premiumTenders:title")}
              description={t("marketplaceRequests:premiumTenders:desc")}
              count={counts.tenders_total}
              unreadCount={counts.tenders_unseen}
              isPro
              isActive={type === RequestType.TENDERS}
              onClick={() => setType(RequestType.TENDERS)}
            />
            <Button
              size="large"
              startIcon={<NotificationsNoneIcon />}
              variant="outlined"
              fullWidth
              onClick={() => setNotificationOpen(true)}
            >
              {t("marketplaceRequests:preference")}
            </Button>
          </Box>

          <Box className={classes.section} flex={1}>
            {type === RequestType.DIRECT ? null : <RFQsStats type={type} />}
            {type === RequestType.TENDERS ? null : (
              <Box display="flex" alignItems="center" gap="12px">
                <Chip
                  variant="outlined"
                  label={t("marketplaceRequests:all", {
                    count: type === RequestType.DIRECT ? counts.private_total : counts.public_total,
                  })}
                  icon={<FilterListOutlinedIcon />}
                  className={status === RequestStatus.ALL ? classes.activeChip : ""}
                  onClick={() => setStatus(RequestStatus.ALL)}
                />
                <Chip
                  variant="outlined"
                  label={t("marketplaceRequests:interested", {
                    count: chipCounts.itb,
                  })}
                  icon={<CheckOutlinedIcon className={classes.colorSuccess} />}
                  className={status === RequestStatus.INTERESTED ? classes.activeChip : ""}
                  onClick={() => setStatus(RequestStatus.INTERESTED)}
                />
                <Chip
                  variant="outlined"
                  label={t("marketplaceRequests:notInterested", {
                    count: chipCounts.itnb,
                  })}
                  icon={<CloseOutlinedIcon className={classes.colorError} />}
                  className={status === RequestStatus.NOT_INTERESTED ? classes.activeChip : ""}
                  onClick={() => setStatus(RequestStatus.NOT_INTERESTED)}
                />
              </Box>
            )}
            {counstLoading ? (
              <CustomCircularProgress />
            ) : (
              <MarketplaceRequestsTable
                type={type as RequestType}
                status={status as RequestStatus}
              />
            )}
          </Box>
        </>
      )}

      {activeRfqId && <QuotationDialogContainer open={activeRfqId} t={t} />}
      <JoinPrivateB2BNetworkModal />
      <NotificationsDialog open={notificationOpen} handleClose={() => setNotificationOpen(false)} />
    </Box>
  );
};

export default MarketplaceRequestsPage;
