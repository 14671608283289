import { Box } from "@mui/material";
import { ProjectModalTypes } from "@next/modules/project/modals/types";
import { projectActions } from "@next/modules/project/redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { slugifyString } from "@next/utils/stringUtils";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ProjectCreateRenameFormBase } from "./project-create-rename-form.base";
import {
  ProjectCreateRenameFormState,
  projectCreateRenameFormValidationSchema,
} from "./project-create-rename-form.definitions";

export enum ProjectCreateRenameFormVariant {
  CREATE = "CREATE",
  RENAME = "RENAME",
}

type Props = {
  projectCode: string;
  projectName: string;
  variant: ProjectCreateRenameFormVariant;
};

export const ProjectCreateRenameForm: React.FC<Props> = ({ projectCode, projectName, variant }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = (values: ProjectCreateRenameFormState) => {
    if (variant === ProjectCreateRenameFormVariant.CREATE) {
      dispatch(
        projectActions.createProjectRequest({
          display_name: values.display_name as string,
          code: slugifyString(values.display_name as string),
        })
      );
    } else if (variant === ProjectCreateRenameFormVariant.RENAME) {
      dispatch(
        projectActions.updateProjectRequest({
          code: projectCode as string,
          project: {
            display_name: values?.display_name,
          },
        })
      );
    }
  };

  const onClickCancel = () => {
    dispatch(modalsActions.closeModal(ProjectModalTypes.CREATE_RENAME_MODAL));
  };

  return (
    <Box p="8px">
      <Formik
        initialValues={{ display_name: projectName || "" }}
        validationSchema={projectCreateRenameFormValidationSchema}
        onSubmit={onSubmit}
        validateOnMount
        render={(props) => (
          <ProjectCreateRenameFormBase onClickCancel={onClickCancel} variant={variant} {...props} />
        )}
      />
    </Box>
  );
};
