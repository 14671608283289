import React from "react";

import { useNDATableData } from "./nda-table.hooks";
import { useSelector } from "react-redux";
import { selectNDAListLoading } from "../../redux/selectors";
import { getRowIdToPk } from "@next/utils/dataGridUtils";
import { DataGridProV4 } from "@next/components/data-grid-pro-v4";

export const NDATable: React.FC = () => {
  const { gridData } = useNDATableData();
  const ndaListLoading = useSelector(selectNDAListLoading);

  return (
    <div>
      <DataGridProV4
        autoHeight
        autoPageSize
        rows={gridData?.rows || []}
        columns={gridData?.columns || []}
        hideFooter
        disableSelectionOnClick
        disableColumnResize
        headerHeight={40}
        rowHeight={60}
        getRowId={getRowIdToPk}
        loading={ndaListLoading}
      />
    </div>
  );
};
