export const DND_SUPPORTED_FILE_FORMATS = [
  "TL",
  "OBJ",
  "FBX",
  "COLLADA",
  "3DS",
  "IGES",
  "STEP",
  "STP",
  "CATPART",
  "X_T",
  "IGS",
  "STL",
  "SLDPRT",
  "IPT",
  "IGE",
  "F3D",
  "DXF",
  "SLDASM",
  "ZIP",
  "PDF",
  "DOC",
  "DOCX",
  "PPTX",
  "JPEG",
  "JPG",
  "JFIF",
  "DWG",
  "CSV",
  "XLSX",
  "XLSM",
];

export const DND_RESTRICTED_FILE_FORMATS = [
  "EXE",
  "BAT",
  "CMD",
  "SH",
  "PHP",
  "JS",
  "VBS",
  "PS1",
  "PSM1",
  "PSD1",
  "APP",
  "DMG",
  "JAR",
  "VB",
  "VBE",
  "TAR",
  "GZ",
  "TGZ",
  "BZ2",
  "DLL",
  "SYS",
  "REG",
  "LNK",
  "INF",
  "MSI",
  "MSP",
  "COM",
  "HTM",
  "HTML",
  "SVG",
];

export const MAX_FILE_SIZE = 50000;
