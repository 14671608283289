import { useLocation } from "react-router-dom";
import { frontendUrl } from "urls";
import { useIsLocationPath } from "./useIsPath";

export function useProjectCodeFromLocation() {
  const location = useLocation();
  const isProjectsPath = useIsLocationPath(frontendUrl.projects);
  if (!isProjectsPath) return null;

  const split = location.pathname.split("/").filter((x) => x);
  if (split.length <= 1) return null; //has project code in the path

  return split[split.length - 1];
}
