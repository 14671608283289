import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppTheme } from "layout/AppTheme";
import CustomButton from "@next/components/custom-button";
import { ContentCopyOutlined } from "@mui/icons-material";
import { copyToClipboard } from "@next/utils/browserUtils";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { useDispatch, useSelector } from "react-redux";
import { selectOttLoading, selectfetchOTT } from "@next/modules/workspace/redux/selectors";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@mui/styles";
import snackbarUtils from "@next/utils/snackbarUtils";

const useStyles = makeStyles(() =>
  createStyles({
    outlookTokenButton: {
      marginTop: "16px",
      padding: "8px 16px",
    },
  })
);

export const OutlookIntegration = () => {
  const classes = useStyles();
  const token = useSelector(selectfetchOTT);
  const ottloading = useSelector(selectOttLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fetchOneTimeToken = () => {
    if (token) {
      copyToClipboard(token);
      snackbarUtils.toast(t("common:copied"));
      return;
    }
    dispatch(workspaceNextActions.fetchOTTRequest());
  };
  return (
    <Box display="flex" flexDirection="column">
      <Typography
        maxWidth="530px"
        style={{
          color: AppTheme.palette.text.secondary,
        }}
      >
        {t("profile:userProfile:outlookIntegrationLabel")}
      </Typography>
      <CustomButton
        className={classes.outlookTokenButton}
        sx={{ fontWeight: token ? "700" : "400" }}
        loading={token ? false : ottloading}
        endIcon={token && <ContentCopyOutlined sx={{ transform: "scaleY(-1)" }} />}
        onClick={fetchOneTimeToken}
        variant={token ? "outlined" : "contained"}
        fullWidth
        color="primary"
      >
        {token ? token : t("profile:userProfile:generateCode")}
      </CustomButton>
    </Box>
  );
};
