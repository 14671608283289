import React, { FC } from "react";
import { OrderDetailHeader } from "../../components/order-detail/order-detail-header/order-detail-header";
import { OrderDetailLineItemsTable } from "../../components/order-detail/order-detail-line-items-table/order-detail-line-items-table";
import { OrderDetailRightTabs } from "../../components/order-detail/order-detail-right-tabs/order-detail-right-tabs";
import {
  LeftSection,
  RightSection,
  SectionContainer,
  TopDivider,
} from "./order-detail-base-page.styled";
import { useElementSize } from "@next/hooks/useElementSize";
import { useDispatch, useSelector } from "react-redux";
import { selectOrderDetailRightTabOpen } from "../../redux/selectors";
import { ordersActions } from "../../redux";
import { Box } from "@mui/material";

type Props = {
  isPortal?: boolean;
  portalOrderToken?: string;
};

export const OrderDetailBasePage: FC<Props> = ({ isPortal }) => {
  const rightTabOpen = useSelector(selectOrderDetailRightTabOpen);
  const dispatch = useDispatch();
  const [headerRef, { height: headerHeight }] = useElementSize();
  // calculate the header height and adjust the line items table height
  const memoizedHeaderHeight = React.useMemo(() => headerHeight, [headerHeight]);

  React.useEffect(() => {
    dispatch(ordersActions.setOrderDetailRightTabOpen(true));
  }, []);

  return (
    <Box>
      <TopDivider />

      <SectionContainer style={!isPortal ? { height: "calc(100vh - 140px)" } : undefined}>
        <LeftSection>
          <OrderDetailHeader isPortal={isPortal} ref={headerRef} />
          <OrderDetailLineItemsTable isPortal={isPortal} headerHeight={memoizedHeaderHeight} />
        </LeftSection>

        <RightSection rightTabOpen={rightTabOpen}>
          <OrderDetailRightTabs
            isPortal={isPortal}
            rightTabOpen={rightTabOpen}
            setRightTabOpen={(isOpen) => {
              dispatch(ordersActions.setOrderDetailRightTabOpen(isOpen));
            }}
          />
        </RightSection>
      </SectionContainer>
    </Box>
  );
};
