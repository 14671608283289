import { Box, SvgIconProps, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { CustomTypography } from "@next/components/custom-typography";
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: grey[50],
      borderRadius: "16px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      height: "90%",
      textAlign: "center",
      marginTop: theme.spacing(3),
    },
    icon: {
      color: theme.palette.primary.main,
      width: "38px",
      height: "38px",
    },
    subtitle: {
      whiteSpace: "break-spaces",
    },
  })
);

type Props = {
  title?: string;
  subTitle?: string;
  Icon?: React.ComponentType<SvgIconProps>;
};

export const SellerDashboardCardPlaceholder: React.FC<Props> = ({
  title = "Please add title",
  subTitle = "Please add subtitle",
  Icon,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box>{Icon && <Icon className={classes.icon} />}</Box>

      <Box mt="12px">
        <CustomTypography variant="h6">{title}</CustomTypography>

        <Box mt="12px">
          <CustomTypography variant="body1" className={classes.subtitle}>
            {subTitle}
          </CustomTypography>
        </Box>
      </Box>
    </Box>
  );
};
