import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import PartDialog from "components/part-dialog/part-dialog.component";
import { SharedModalTypes } from "./types";

export const SharedOrderPurchaseDialogModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(SharedModalTypes.ORDER_PURCHASE_MODAL));
  const orderPurchaseParams = modal?.data as {
    contractPk: number;
    isProject: boolean;
    refetchData?: boolean;
  };

  if (!modal || !orderPurchaseParams) return null;

  const partDialogAttrs = {
    isOpen: !!modal,
    contractPk: orderPurchaseParams?.contractPk,
    isPurchaseOrder: !!modal,
    handleCloseModal: () =>
      dispatch(modalsActions.closeModal(SharedModalTypes.ORDER_PURCHASE_MODAL)),
    isProject: orderPurchaseParams?.isProject,
  };

  // @ts-ignore
  return <PartDialog {...partDialogAttrs} />;
};
