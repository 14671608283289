import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { getNormalizedCapabilitiesTypes } from "../company-capabilities/company-capabilities.utils";
import { CompanyCertificationsForm } from "./company-certifications-form";
import { selectCompanyProfile } from "@next/modules/profile/redux/selectors";
import {
  CapabilityTypes,
  companyCapabilitiesActions,
} from "@next/modules/company-capabilities/redux";
import {
  selectCompanyCapabilitiesTypes,
  selectPatchCompanyCapabilitiesLoading,
} from "@next/modules/company-capabilities/redux/selectors";
import CustomButton from "@next/components/custom-button";
import { EnhancedCertificaionList } from "../company-capabilities/company-capabilities.types";
import { profileNextActions } from "@next/modules/profile/redux";

export const CompanyCertifications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyProfile = useSelector(selectCompanyProfile);
  const companyCapabilitiesTypes = useSelector(selectCompanyCapabilitiesTypes);
  const certificates = getNormalizedCapabilitiesTypes(
    companyCapabilitiesTypes?.[CapabilityTypes.CERTIFICATIONS] || []
  );
  const submitLoading = useSelector(selectPatchCompanyCapabilitiesLoading);

  useEffect(() => {
    dispatch(
      companyCapabilitiesActions.fetchCompanyCapabilitesTypesRequest({
        type: CapabilityTypes.CERTIFICATIONS,
      })
    );
  }, []);

  const enhancedCertificationList: EnhancedCertificaionList[] = Object.keys(certificates).flatMap(
    (parentKey) => {
      const items = certificates[parentKey];
      return items.flatMap((item) =>
        item.capabilities.map((capability) => ({
          ...capability,
          has_capability:
            !!companyProfile?.certificates?.find(
              (certificate) => certificate.pk === capability.pk
            ) || false,
          file:
            companyProfile?.certificates?.find((certificate) => certificate.pk === capability.pk)
              ?.file || null,
        }))
      );
    }
  );

  const initialValues = {
    certificationsPks: companyProfile?.certificates?.map((item: any) => item.pk) as number[],
  };

  const handleSubmitCertifications = (values: any) => {
    dispatch(
      companyCapabilitiesActions.patchCompanyCapabilitiesRequest({
        capabilities: values.certificationsPks,
        onSuccess: () => {
          dispatch(
            profileNextActions.fetchCompanyProfileRequest({
              companyId: companyProfile?.id as number,
              isPublic: false,
            })
          );
        },
      })
    );
  };

  return (
    <section className="c-company-capabilities">
      <Formik initialValues={initialValues} onSubmit={handleSubmitCertifications}>
        {({ handleSubmit, setFieldValue, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Box className="c-company-capabilities--subtitle">
                {t("profile:companyProfile:certificationsSubtitle")}
              </Box>
              <React.Fragment>
                <CompanyCertificationsForm
                  certificationList={enhancedCertificationList}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </React.Fragment>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                className="c-company-capabilities--btn"
              >
                <CustomButton
                  variant="contained"
                  type="submit"
                  size="medium"
                  color="primary"
                  loading={submitLoading}
                >
                  {t("common:save")}
                </CustomButton>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};
