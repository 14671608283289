import React from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { CustomTypography } from "@next/components/custom-typography";
import * as S from "./form-file-attachment-button.styled";

type Props = {
  handleAttachButtonClick: () => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fileInputRef: React.MutableRefObject<HTMLInputElement>;
  btnText: string;
  disabled?: boolean;
};

export const FormFileAttachmentButton: React.FC<Props> = ({
  handleAttachButtonClick,
  handleFileChange,
  fileInputRef,
  btnText,
  disabled,
}) => {
  return (
    <S.StyledAttachmentButton
      startIcon={<AttachmentIcon sx={{ width: "24px", height: "24px" }} />}
      variant="outlined"
      onClick={handleAttachButtonClick}
      disabled={disabled}
    >
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <CustomTypography variant="body2" tooltipTitle={null}>
        {btnText}
      </CustomTypography>
    </S.StyledAttachmentButton>
  );
};
