import { Box, alpha } from "@mui/material";
import { GridCellEditCommitParams, GridCellParams, GridRowModel } from "@mui/x-data-grid-pro-v5";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useRFQPartsTableData } from "./project-part-table.hooks";
import { projectActions } from "@next/modules/project/redux/slices";
import { ProjectCompanyPart } from "@next/modules/project/redux";
import { NoRowsOverlayWithApiRef } from "@next/components/no-rows-overlay-with-api";
import { TableLoadingOverlay } from "@next/components/table-loading-overlay";
import { workspaceActions } from "services/workspace";
import { getToken } from "services/profile/profile.selectors";
import { getIsLoading, selectRfqPartsLoading } from "services/workspace/workspace.selectors";

import { createStyles, makeStyles } from "@mui/styles";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";
import useLocalStorageGridState from "@next/hooks/useLocalStorageGridState-v5";
import { BUYER_PARTS_GRID_COLUMN_STATE } from "@next/constants/data-grid-state-constants";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";

const PAGE_SIZE = 10;

const useStyles = makeStyles((theme) =>
  createStyles({
    gridContainer: {
      "& .MuiDataGrid-cell:hover": {
        backgroundColor: alpha(theme.palette.common.black, 0.05),
        cursor: "pointer",
      },
    },
  })
);

type Props = {
  readOnly?: boolean;
  loading?: boolean;
  disableSuccessMessage?: boolean;
  parts?: ProjectCompanyPart[];
  onEditStart?: () => void;
  hidePriceColumn?: boolean;
};

export const RFQPartsTable: React.FC<Props> = ({
  readOnly,
  parts,
  loading,
  disableSuccessMessage,
  onEditStart,
  hidePriceColumn,
}) => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const { t } = useTranslation();
  const partUpdateLoading = useSelector(getIsLoading);
  const partsListLoading = useSelector(selectRfqPartsLoading);
  const { gridData } = useRFQPartsTableData(readOnly, false, hidePriceColumn);
  const classes = useStyles();
  const hasViewerOrRestrictedViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);

  const { apiRef } = useLocalStorageGridState(BUYER_PARTS_GRID_COLUMN_STATE);

  const isCellEditable = (params: GridCellParams) =>
    !readOnly && !(params.colDef.editable && params.field === "quantity" && params.row.winner);

  const onCellEditCommit = useCallback(
    (params: GridCellEditCommitParams & { api?: any }) => {
      let field = params.field;
      let value = params.value;

      if (field === "processes") {
        if (value instanceof Object || !value) {
          return;
        }

        value = [value];
      }

      if (apiRef.current) {
        const row = apiRef.current.getRow(params.id);

        if (row) {
          // Here, we perform operations to save the extraFields in the appropriate format for the API.
          const field_parts = field.split(".");
          if (field_parts.length > 1) {
            field = "extra_fields";
            const extraFields = row[field];
            const oldFieldValue = extraFields?.[field_parts[1]];
            if (oldFieldValue === params.value) {
              return;
            }
            value = {
              [field_parts[1]]: params.value,
            };
          } else {
            const oldFieldValue = row.field;
            if (params.value === oldFieldValue) {
              return;
            }
          }
        }
      }

      const updates = {
        [field]: value,
      };
      dispatch(
        workspaceActions.editPart(
          token,
          updates,
          params.id,
          t,
          true,
          undefined,
          disableSuccessMessage
        )
      );
    },
    [apiRef.current, token, t, disableSuccessMessage]
  );

  const onCellClick = (params: GridCellParams) => {
    dispatch(projectActions.setSelectedPart(params.row as ProjectCompanyPart));
  };

  return (
    <Box className={`c-project-part-table ${readOnly ? "" : "c-parts-table-column-borders"}`}>
      <Box className={`c-project-part-table__container ${classes.gridContainer}`}>
        <DataGridProV5
          apiRef={apiRef}
          autoHeight
          disableSelectionOnClick
          rowHeight={32}
          headerHeight={39}
          pagination
          autoPageSize
          loading={loading || partUpdateLoading || partsListLoading}
          pageSize={PAGE_SIZE}
          onCellClick={onCellClick}
          rows={parts || gridData.rows || []}
          columns={gridData.columns || []}
          disableColumnReorder={hasViewerOrRestrictedViewerRole}
          isCellEditable={isCellEditable}
          getRowId={(row: GridRowModel) => row.pk}
          onCellEditCommit={onCellEditCommit}
          onCellEditStart={onEditStart}
          components={{
            LoadingOverlay: () => <TableLoadingOverlay />,
            NoRowsOverlay: () => (
              <NoRowsOverlayWithApiRef
                setApiRef={(baseApiRef) => (apiRef.current = baseApiRef?.current)}
              />
            ),
          }}
        />
      </Box>
    </Box>
  );
};
