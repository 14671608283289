import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  CapabilityTypes,
  CompanyCapabilitiesState,
  FetchCompanyCapabilitiesTypesInput,
  FetchCompanyCapabilitiesTypesResponse,
  PatchCompanyCapabilitiesInput,
} from "./types";

export const initialState: CompanyCapabilitiesState = {
  companyCapabilities: undefined,

  companyCapabilitesTypesLoading: false,
  companyCapabilitesTypesError: undefined,
  companyCapabilitesTypes: undefined,

  patchCompanyCapabilitiesLoading: false,
  patchCompanyCapabilitiesError: undefined,
};

const companyCapabilitesSlice = createSlice({
  name: "companyCapabilitesNext",
  initialState,
  reducers: {
    fetchCompanyCapabilitesTypesRequest(
      state,
      _action: PayloadAction<FetchCompanyCapabilitiesTypesInput>
    ) {
      state.companyCapabilitesTypesLoading = true;
      state.companyCapabilitesTypesError = undefined;
      state.companyCapabilitesTypes = state.companyCapabilitesTypes || undefined;
    },
    fetchCompanyCapabilitesTypesSuccess(
      state,
      action: PayloadAction<FetchCompanyCapabilitiesTypesResponse>
    ) {
      state.companyCapabilitesTypesLoading = false;
      state.companyCapabilitesTypes = {
        ...state.companyCapabilitesTypes,
        [action.payload.type || CapabilityTypes.CAPABILITIES]: action.payload.data,
      };
    },
    fetchCompanyCapabilitesTypesFailure(state, action: PayloadAction<Error>) {
      state.companyCapabilitesTypesLoading = false;
      state.companyCapabilitesTypesError = action.payload;
    },

    patchCompanyCapabilitiesRequest(state, _action: PayloadAction<PatchCompanyCapabilitiesInput>) {
      state.patchCompanyCapabilitiesLoading = true;
      state.patchCompanyCapabilitiesError = undefined;
    },
    patchCompanyCapabilitiesSuccess(state, _action: PayloadAction<number[]>) {
      state.patchCompanyCapabilitiesLoading = false;
    },
    patchCompanyCapabilitiesFailure(state, _action: PayloadAction<Error>) {
      state.patchCompanyCapabilitiesLoading = false;
      state.patchCompanyCapabilitiesError = _action.payload;
    },

    setCompanyCapabilities(state, action: PayloadAction<number[]>) {
      state.companyCapabilities = action.payload;
    },

    toggleCompanyCapability(state, action: PayloadAction<number>) {
      if (state.companyCapabilities?.includes(action.payload)) {
        state.companyCapabilities = state.companyCapabilities?.filter(
          (item) => item !== action.payload
        );
      } else {
        state.companyCapabilities = [...(state.companyCapabilities || []), action.payload];
      }
    },
  },
});

export const companyCapabilitiesActions = companyCapabilitesSlice.actions;

export default companyCapabilitesSlice.reducer;
