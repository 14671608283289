import { t } from "assets/configi18n/i18n";
import { AppTheme } from "layout/AppTheme";

export type quotesHeaderProps = {
  headerName: string;
  variant?: string;
  showTab: boolean;
};

export const textColor = "#000000DE";
export const textSize = "14px";
export const borderStyle = `1px solid ${AppTheme.palette.divider}`;
export const ellipsisText = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

const QuotesHeaderTabList = (columnView: boolean) => {
  const tabsList: Array<quotesHeaderProps> = [
    {
      headerName: t("workspaceNext:quoteComparison:autoSelectLowestPrice"),
      showTab: columnView ? true : false,
    },
    {
      headerName: t("workspaceNext:quoteComparison:customizeView"),
      showTab: columnView ? true : false,
    },
    {
      headerName: t("workspaceNext:quoteComparison:export"),
      showTab: true,
    },
  ];
  return {
    tabsList,
  };
};
export default QuotesHeaderTabList;
