import { Typography } from "@material-ui/core";
import { Box, styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";

export const RootBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "96px",
});

export const StyledBox = styled(Box)(({ theme }) => ({
  width: "95%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "16px",
  padding: "24px",
  paddingTop: "120px",
  paddingBottom: "120px",
  borderRadius: "16px",
  backgroundColor: theme.palette.customBackground.dark,
}));

export const StyledTitleTypography = styled(CustomTypography)({
  fontWeight: 500,
  lineHeight: "24px",
  letterSpacing: "0.15px",
  color: "white",
});

export const StyledTypography = styled(Typography)({
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0.25px",
  color: "white",
  fontFamily: "Roboto",
  textAlign: "center",
});
