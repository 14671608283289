// send postal code to API to get city, province and country information
// using setFieldValue to set field with backend response

import { profileService } from "services/profile";

export const handlePostalCode = async (event, setFieldValue) => {
  const postalCode = event.target.value;
  if (postalCode && postalCode.length > 2) {
    // show loader on postal code field on blur while making API call
    setFieldValue("showLoader", true);
    await profileService
      .fetchAddressByPostalCode(event.target.value)
      .then((response) => {
        setFieldValue("address.city", response && response.city ? response.city : "");
        setFieldValue("address.province", response && response.province ? response.province : "");
        setFieldValue("address.country", response && response.country ? response.country : "");
        // hide loader once response received from API and after setting to the address fields

        setFieldValue("showLoader", false);
      })
      .catch((error) => {
        setFieldValue("showLoader", false);
      });
  }
};
