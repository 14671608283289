import React, { useMemo } from "react";
import { SelectOrderStateCard } from "./select-order-state-card";
import { Grid } from "@mui/material";
import { OrderState } from "@next/modules/orders/redux";
import { useSelector } from "react-redux";
import { selectOrdersStatusCounts } from "@next/modules/orders/redux/selectors";
import { CardOrderState } from "./select-order-state.cards.types";
import { useTranslation } from "react-i18next";
import { t } from "assets/configi18n/i18n";
import i18n from "assets/configi18n/i18n";

type Props = {
  selectedFilterState: CardOrderState;
  setSelectedFilterState: (newSelectedState: CardOrderState) => void;
};

export const initialCardStateOrder = (total_count: number) => {
  return {
    slug: OrderState.ALL,
    query_slugs: [OrderState.ALL],
    name: t("orders:cardStates:allOrders"),
    count: total_count || 0,
  };
};

export const SelectOrderStateCards: React.FC<Props> = ({
  selectedFilterState,
  setSelectedFilterState,
}) => {
  const { t } = useTranslation();
  const ordersStatusCounts = useSelector(selectOrdersStatusCounts);

  const cardStates: CardOrderState[] = useMemo(
    () => [
      initialCardStateOrder(ordersStatusCounts?.total_count),
      {
        slug: OrderState.UNPUBLISHED,
        query_slugs: [OrderState.UNPUBLISHED],
        name: t("orders:cardStates:draft"),
        count: ordersStatusCounts?.draft_count || 0,
        hide: !ordersStatusCounts?.draft_count || ordersStatusCounts?.draft_count === 0,
      },
      {
        slug: OrderState.UPDATED,
        query_slugs: [OrderState.UPDATED],
        name: t("orders:cardStates:waitingReview"),
        count: ordersStatusCounts?.updated_count || 0,
      },
      {
        slug: OrderState.ON_TRACK,
        query_slugs: [
          OrderState.ON_TRACK,
          OrderState.WAITING_FOR_SHIPPING_CONFIRMATION,
          OrderState.WAITING_FOR_ACKNOWLEDGMENT,
        ],
        name: t("orders:cardStates:onTrack"),
        count: ordersStatusCounts?.on_track_count || 0,
      },

      {
        slug: OrderState.AT_RISK,
        query_slugs: [OrderState.AT_RISK],
        name: t("orders:cardStates:atRisk"),
        count: ordersStatusCounts?.at_risk_count || 0,
      },
      {
        slug: OrderState.LATE,
        query_slugs: [OrderState.LATE],
        name: t("orders:cardStates:late"),
        count: ordersStatusCounts?.late_count || 0,
      },

      {
        slug: OrderState.SHIPPED,
        query_slugs: [OrderState.SHIPPED],
        name: t("orders:cardStates:shipped"),
        count: ordersStatusCounts?.shipped_count || 0,
      },
    ],
    [ordersStatusCounts, i18n?.language]
  );

  return (
    <>
      {cardStates?.map((cardState) => {
        if (cardState?.hide) return null;

        return (
          <Grid item>
            <SelectOrderStateCard
              state={cardState}
              selected={selectedFilterState?.name === cardState?.name}
              onClick={(newSelectedFilterState) => {
                if (selectedFilterState?.name !== newSelectedFilterState?.name) {
                  setSelectedFilterState(newSelectedFilterState);
                }
              }}
            />
          </Grid>
        );
      })}
    </>
  );
};
