import { Box, styled } from "@mui/material";

export const StyledFormFieldBox = styled(Box)({
  width: "48%",
  display: "flex",
});

export const StyledTableWrapper = styled(Box)(() => ({
  margin: "0",
  overflowX: "auto",
}));
