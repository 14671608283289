import { Box, TextField, styled } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { CustomTypography } from "@next/components/custom-typography";

export const StyledContentBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
});

export const StyledInnerBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
});

export const StyledThreadBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "16px",
  flexGrow: 1,
  overflowY: "auto",
  marginBottom: 0,
});

export const StyledInputBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  padding: "16px",
  gap: "16px",
  borderTop: `1px solid ${theme.palette.divider}`,
  alignItems: "center",
  backgroundColor: theme.palette.background.paper,
  position: "sticky",
  bottom: 0,
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    minHeight: "40px",
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    transition: "height 0.3s ease",
  },
  "& .MuiInput-underline:before": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  color: theme.palette.text.secondary,
  "& .MuiInputBase-input": {
    width: "95vw",
    height: "auto",
    lineHeight: "24px",
    minHeight: "25px",
  },
  "& .MuiInputBase-input::placeholder": {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
  },
}));

export const StyledSendIcon = styled(SendIcon)({
  color: "black",
});

export const NoCommmentBox = styled(Box)(({ theme }) => ({
  padding: "16px",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const StyledTypography = styled(CustomTypography)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0.25px",
}));
