import { Box, Button, Card, Theme, Typography } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    maxWidth: "736px",
    height: "160px",
    padding: "24px",
    borderRadius: "16px",
    border: `1px solid ${theme.palette.divider}`,
  },
  addBtn: {
    width: 40,
    height: 40,
    minWidth: "unset",
    borderRadius: "50%",
    border: `1px solid ${theme.palette.primary.main}`,
    marginLeft: 5,
  },
  addIcon: {
    fontSize: `24px`,
    color: theme.palette.primary.main,
  },
}));

type Props = {
  title: string;
  subTitle: string;
  leftComponent: React.ReactNode;
  onClickAdd?: () => void;
};

export const FreemiumDashboardCardMini: React.FC<Props> = ({
  title,
  subTitle,
  leftComponent,
  onClickAdd,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Box>
        <Typography variant="h6">{title}</Typography>
      </Box>

      <Box mt="12px">
        <Typography variant="body1" color="textSecondary">
          {subTitle}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between" mt="12px">
        <Box>{leftComponent}</Box>

        <Box>
          <Button onClick={onClickAdd} className={classes.addBtn}>
            <AddIcon className={classes.addIcon} />
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
