import React from "react";
import { useTranslation } from "react-i18next";
import { FieldArray, Form, FormikProps } from "formik";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { GenericTextInput } from "components/utils/input/text";
import { CompanyReferencesValues } from "./company-references.form.types";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";

const CompanyReferencesBase: React.FC<FormikProps<CompanyReferencesValues>> = ({
  values,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const onRemoveReference = (index: number, removeCallback: (index: number) => void) => {
    confirmDialog(
      t("workspace:confirmation"),
      t("profile:companyProfile:areYouSureWantToDelete"),
      () => {
        removeCallback(index);
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mb={3}>
            <Typography variant="body2" color="textSecondary">
              {t("profile:companyProfile:referenceTitle")}
            </Typography>
          </Box>
        </Grid>
        <FieldArray
          name="references"
          render={(arrayHelpers) => (
            <Grid item xs={12}>
              {Array.isArray(values?.references) &&
                values?.references?.map((reference, index) => (
                  <div key={index}>
                    <Grid item xs={12}>
                      <Box display="flex" mb={"10px"}>
                        <GenericTextInput
                          name={`references[${index}].name`}
                          label={t("profile:companyProfile:reference", {
                            count: index + 1,
                          })}
                          value={reference?.name}
                        />

                        <IconButton
                          onClick={() => onRemoveReference(index, arrayHelpers.remove)}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </div>
                ))}
              <Button
                startIcon={<AddCircleIcon />}
                onClick={(event) => {
                  event.preventDefault();
                  arrayHelpers.push("");
                }}
              >
                {t("profile:companyProfile:addNewReference")}
              </Button>
            </Grid>
          )}
        />

        <Grid item>
          <Button variant="contained" type="submit" size="medium" color="primary">
            {t("common:save")}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default CompanyReferencesBase;
