import React from "react";
import { Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SharedModalTypes } from "./types";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { BulkFileImport } from "./components/bulk-file-import/bulk-file-import";
import { projectActions } from "@next/modules/project/redux";
import { ordersActions } from "@next/modules/orders/redux";

export enum BulkFileImportFor {
  PROJECT = "PROJECT",
  ORDER = "ORDER",
}

type SharedBulkFileImportModalProps = {
  filesCount: number;
  onAttachmentsChange?: (files: any[], filePkToDelete?: number) => void;
  bulkFileImportFor: BulkFileImportFor;
};

export const SharedBulkFileImportModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(SharedModalTypes.BULK_FILES_IMPORT_MODAL));
  const data = modal?.data as SharedBulkFileImportModalProps;

  if (!modal || !data) return null;

  const resetAction =
    data.bulkFileImportFor === BulkFileImportFor.PROJECT
      ? projectActions.resetBomFilesUploadReport
      : ordersActions.resetBomFilesUploadReport;

  const onClose = () => {
    dispatch(resetAction);
    dispatch(modalsActions.closeModal(SharedModalTypes.BULK_FILES_IMPORT_MODAL));
  };

  return (
    <Dialog open={!!modal} closeAfterTransition maxWidth="md">
      <BulkFileImport
        onCloseModal={onClose}
        filesCount={data.filesCount}
        onAttachmentsChange={data?.onAttachmentsChange}
        bulkFileImportFor={data.bulkFileImportFor}
      />
    </Dialog>
  );
};
