import { userOnboardingConstants } from "./user-onboarding.constants";

// Initial state of the app + architecture of what we want to have in our store inside user onboarding
const initialState = {
  isRegisterUserLoading: false,
  activeStep: 0,
  user: {},
  company: {},
  references: {},
  uploadedFiles: [],
  isUploadingInProgress: false,
  isUploadingDone: false,
  isChooseCompanyType: false,
};

export function userOnboarding(state = initialState, action) {
  switch (action.type) {
    //set onboarding active step
    case userOnboardingConstants.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.activeStep,
      };

    //set onboarding  user information
    case userOnboardingConstants.SET_USER_INFORMATION:
      let newUser = {
        ...state.userInformation,
        ...action.userInfos,
      };
      return {
        ...state,
        user: newUser,
      };

    // set onboarding company type
    case userOnboardingConstants.SET_COMPANY_TYPE:
      let newUserType = {
        ...state.user,
        type: action.companyType,
      };
      return {
        ...state,
        user: newUserType,
      };

    // set onboarding company information
    case userOnboardingConstants.SET_COMPANY_INFORMATION:
      let newCompany = {
        ...state.company,
        ...action.companyInfos,
      };

      return {
        ...state,
        company: newCompany,
      };

    // set onboarding company information
    case userOnboardingConstants.SET_COMPANY_REFERENCES:
      let newCompanyReferences = {
        ...action.references,
      };

      return {
        ...state,
        references: newCompanyReferences,
      };
    //SET_UPLOADED_FILES_SUCCESS
    case userOnboardingConstants.SET_UPLOADED_FILES_SUCCESS:
      let newUploadedFiles = [...state.uploadedFiles, ...action.uploadedFiles];

      return {
        ...state,

        uploadedFiles: newUploadedFiles,
      };
    //SET_REMOVE_UPLOADED_FILES_SUCCESS
    case userOnboardingConstants.SET_REMOVE_UPLOADED_FILES_SUCCESS:
      return {
        ...state,
        uploadedFiles: state.uploadedFiles.filter((file) => file != action.fileToRemove),
      };

    //REGISTER_USER_REQUEST
    case userOnboardingConstants.REGISTER_USER_REQUEST:
      return { ...state, isRegisterUserLoading: true };

    case userOnboardingConstants.REGISTER_USER_SUCCESS:
      let newCompanyAuthenticate = {
        ...state.company,
        ...action.authenticationsInfos,
      };
      return {
        ...state,
        isRegisterUserLoading: false,
        company: newCompanyAuthenticate,
      };

    case userOnboardingConstants.REGISTER_USER_FAILURE:
      return { ...state, isRegisterUserLoading: false };

    //SET_UPLOAD_DONE_SUCCESS
    case userOnboardingConstants.IS_UPLOAD_DONE:
      return {
        ...state,
        isUploadingDone: action.isUploadingDone,
      };
    //SET_UPLOAD_DONE_SUCCESS
    case userOnboardingConstants.SET_IS_CHOOSE_COMPANY_TYPE:
      return {
        ...state,
        isChooseCompanyType: action.isChooseCompanyType,
      };
    //SET_UPLOAD_DONE_SUCCESS
    case userOnboardingConstants.RESET_TO_INITIAL_STATE:
      return {
        ...state,
        activeStep: 0,
        isChooseCompanyType: false,
        user: {},
        company: {},
        references: {},
        uploadedFiles: [],
      };

    default:
      return state;
  }
}
