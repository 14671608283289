import { getModal, modalsActions } from "@next/redux/modalsSlices";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SharedModalTypes } from "./types";
import Modal from "@next/components/custom-modal";
import { GenericUser } from "@next/modules/profile/redux";
import InviteCompanyUserForm from "./components/invite-company-user/invite-company-user.form";
import { CompanyTypeNext } from "@next/redux/types";
import { getUser } from "services/profile/profile.selectors";

export interface SharedInviteCompanyUserModalData {
  onSuccess?: (user: GenericUser) => void;
  email?: string;
}

const SharedInviteCompanyUserModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const isSeller = user?.type !== CompanyTypeNext.DO;
  const modal = useSelector(getModal(SharedModalTypes.INVITE_COMPANY_USER_FORM));
  const data = modal?.data as SharedInviteCompanyUserModalData;

  if (!modal) return null;

  return (
    <Modal
      customWidth={!isSeller ? "md" : ""}
      dialogProps={{ maxWidth: "md", fullWidth: true }}
      handleModalClose={() =>
        dispatch(modalsActions.closeModal(SharedModalTypes.INVITE_COMPANY_USER_FORM))
      }
      modalState={{
        modalHeader: t("profile:inviteCompanyUserModal:title"),
        modalBody: <InviteCompanyUserForm {...data} />,
      }}
      isModalOpen={true}
    />
  );
};

export default SharedInviteCompanyUserModal;
