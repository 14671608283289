import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import ContractsTable from "@next/modules/workspace/components/contracts-table/contracts-table";

export function SupplierContracts({ setIsEditQuotationMode }) {
  const { t } = useTranslation(["workspace", "ratingInContract"]);
  useEffect(() => {
    setIsEditQuotationMode(false);
  }, []);

  return (
    <div className="c-supplier-contracts">
      {/* <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className="c-supplier-contracts--title"
      >
        <Grid item xs={12}>
          <Typography variant="h4">
            {t("mainNavigationSidebar:awardedRequests")}
          </Typography>
        </Grid>
      </Grid> */}

      <ContractsTable />
    </div>
  );
}
