// General IMport
import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import querystring from "query-string";

import { Box, Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Components
import { TabsView } from "components/utils/tabs-view/tabs-view.component";
import { TabPanel } from "components/utils/tab-panel/tab-panel";
import { TabName } from "components/common/table-list/types";

// Helpers functions
import { history } from "helpers/history";
import { frontendUrl } from "urls";
import CompanyProfileEdit from "@next/modules/profile/components/company-profile-edit/company-profile-information";
import { ValidationCompanyAccepted } from "./company-validation/validation-company-accepted/validation-company-accepted.component";
import { ValidationCompanyWaiting } from "./company-validation/validation-company-waiting/validation-company-waiting.component";
import { CompanyMembersTable } from "./company-members/company-members-table";

export default function CompanyProfile(props) {
  const useStyles = makeStyles(() => ({
    divider: {
      width: "auto",
      marginLeft: "-64px",
      marginRight: "-64px",
    },
  }));
  const { company } = props;
  const location = useLocation();

  const { t } = useTranslation("userCompany");
  const classes = useStyles();

  let tabItemsWithCapabilities = [];
  // tab names
  let tabItems = [
    { tabName: TabName.COMPANY_INFORMATION, length: false },
    {
      tabName: TabName.MEMBERS,
      length: false,
    },
  ];
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  tabItems = [...tabItems, ...tabItemsWithCapabilities];

  const tabHandleChange = (_, index) => {
    setActiveTabIndex(index);
    if (index === 0) {
      history.push(frontendUrl.companyProfile);
    } else if (index === 1) {
      history.push(`${frontendUrl.companyProfile}?members=true`);
    }
  };

  useEffect(() => {
    const queryParams = location.search.replace("?", ""); // removing "?" from params
    const parsed = querystring.parse(queryParams);

    const parsedHasMembers =
      Object.hasOwnProperty.bind(parsed)(TabName.MEMBERS) && parsed[TabName.MEMBERS] === "true";

    if (parsed && parsedHasMembers) {
      setActiveTabIndex(1);
      history.push(`${frontendUrl.companyProfile}?members=true`);
    } else {
      history.push(frontendUrl.companyProfile);
    }
  }, [location.search]);

  return (
    <Grid container direction="column" className="c-user-company">
      <Grid item>
        <Box display="flex" alignItems="center">
          <Typography variant="h4">{company.name}</Typography>

          {company.is_active ? (
            <ValidationCompanyAccepted t={t} />
          ) : (
            <ValidationCompanyWaiting t={t} />
          )}
        </Box>
      </Grid>

      <Grid style={{ marginTop: "24px" }}>
        <TabsView tabItems={tabItems} tabHandleChange={tabHandleChange} tabValue={activeTabIndex} />
      </Grid>

      <Grid item xs={12}>
        <Divider classes={{ root: classes.divider }} />
      </Grid>

      <TabPanel
        value={activeTabIndex}
        index={0}
        // style={{ border: "1px solid blue" }}
        className="c-company-edit-container"
      >
        <CompanyProfileEdit />
      </TabPanel>

      <TabPanel value={activeTabIndex} index={1}>
        <CompanyMembersTable />
      </TabPanel>
    </Grid>
  );
}
