import { Box, Typography, styled } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import TimelapseRoundedIcon from "@mui/icons-material/TimelapseRounded";
import RecommendIcon from "@mui/icons-material/Recommend";
import ErrorIcon from "@mui/icons-material/Error";

interface Props {
  active: boolean;
}

export const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})<Props>(({ theme, active }) => ({
  height: "40px",
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
  paddingLeft: "12px",
  paddingRight: "16px",
  margin: 0,
  borderRadius: "8px",
  boxSizing: "border-box",
  backgroundColor: "#ffffff",
  zIndex: 1,
  opacity: 1,
  cursor: "pointer",
  width: "auto",
  ...(active && {
    border: `6px solid ${theme.palette.primary.main}`,
    height: "50px",
    borderRadius: "12px",
  }),
}));

export const StyledApprovedIcon = styled(RecommendIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  width: "24px",
  height: "24px",
}));

export const StyledInReviewIcon = styled(ErrorIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
  width: "24px",
  height: "24px",
}));

export const StyledCompleteIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: "24px",
  height: "24px",
}));

export const StyledPartialCompleteIcon = styled(TimelapseRoundedIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: "24px",
  height: "24px",
}));

export const StyledIncompleteIcon = styled(PanoramaFishEyeIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
  width: "24px",
  height: "24px",
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const StyledBadgeWrapper = styled(Box)({
  marginLeft: "8px",
  width: "20px",
  height: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
