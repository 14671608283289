import { Box, Theme, Typography } from "@mui/material";
import CustomButton from "@next/components/custom-button";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { workspaceNextActions } from "../../redux";
import { quoteComparisonContext } from "../../components/quote-comparison/quote-comparison.context";
import { groupAndMergeBySupplierId } from "./quote-comparison-award-drawer-footer.utils";
import { useParams } from "react-router-dom";
import { selectProceedQuoteComparisonSelectionLoading } from "../../redux/selectors";
import { createStyles, makeStyles } from "@mui/styles";
import { getRfqDetails } from "services/workspace/workspace.selectors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      position: "sticky",
      bottom: 0,
      backgroundColor: theme.palette.common.white,
      display: "flex",
      flexDirection: "column",
      zIndex: 10,
    },
    button: {
      width: "100%",
      height: 40,
    },
  })
);

export const QuoteComparisonAwardDrawerFooter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const proceedQuoteComparisonSelectionLoading = useSelector(
    selectProceedQuoteComparisonSelectionLoading
  );
  const rfqDetails = useSelector(getRfqDetails);
  const { selectedData } = useContext(quoteComparisonContext);
  const classes = useStyles();
  const params = useParams<{ id?: string }>();

  const onClickProceed = () => {
    const rfqId = params?.id || rfqDetails?.pk;
    if (!rfqId) return null;

    dispatch(
      workspaceNextActions.proceedQuoteComparisonSelectionRequest({
        rfq_pk: parseInt(rfqId),
        contractData: groupAndMergeBySupplierId(selectedData?.selectedSuppliersParts),
      })
    );
  };

  return (
    <div className={classes.footer}>
      <Box m={2} display="flex" justifyContent="center" alignItems="center">
        <CustomButton
          onClick={onClickProceed}
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          fullWidth
          loading={proceedQuoteComparisonSelectionLoading}
          data-pid="btnQCProceed"
        >
          <Typography variant="body2">{t("workspaceNext:quoteComparison:proceed")}</Typography>
        </CustomButton>
      </Box>
    </div>
  );
};
