import i18next from "i18next";
import * as yup from "yup";
import { PublicRFQFormValuesType } from "./public-rfq-creation-form.types";

export const getPublicRfqFormInitialValues = (): PublicRFQFormValuesType => ({
  first_name: "",
  last_name: "",
  company_name: "",
  email: "",
  additional_details: "",
  attachments: [],
});

export const getPublicRfqFormValidationSchema = (t: i18next.TFunction) =>
  yup.object().shape({
    first_name: yup.string().required(t("common:error:thisFieldIsRequired")),
    last_name: yup.string().required(t("common:error:thisFieldIsRequired")),
    company_name: yup.string().required(t("common:error:thisFieldIsRequired")),
    email: yup
      .string()
      .email(t("auth:error:errorEmailInvalid"))
      .required(t("common:error:thisFieldIsRequired")),
    additional_details: yup.string().required(t("common:error:thisFieldIsRequired")),
    attachments: yup.string().required(t("workspaceNext:publicRfq:form:thisFileIsRequired")),
  });
