export enum UserRatingActionTypes {
  // get user rating information
  FETCH_USER_RATING_INFO_REQUEST = "FETCH_USER_RATING_INFO_REQUEST",
  FETCH_USER_RATING_INFO_SUCCESS = "FETCH_USER_RATING_INFO_SUCCESS",
  FETCH_USER_RATING_INFO_FAILURE = "FETCH_USER_RATING_INFO_FAILURE",
  // submit rating response
  SEND_USER_RATING_REQUEST = "SEND_USER_RATING_REQUEST",
  SEND_USER_RATING_SUCCESS = "SEND_USER_RATING_SUCCESS",
  SEND_USER_RATING_FAILURE = "SEND_USER_RATING_FAILURE",
}
