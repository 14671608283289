// General Import
import React from "react";
import { useTranslation } from "react-i18next";
// Material UI Import
import { Grid, Typography } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import { fieldToTextField } from "formik-material-ui";
import { GenericAutoCompleteTextField } from "components/generic-autocomplete-text-field/generic-autocomplete-text-field.component";

export const RequestTypeDropdown = (props: any) => {
  const { label, textFieldProps } = props;
  const { t } = useTranslation("workspace");

  const { ...field } = fieldToTextField(props);
  const { name } = field;

  const {
    form: { setFieldValue },
  } = props;

  const options = [
    {
      slug: "rfq",
      name: t("workspaceNext:rfqDrawer:requestTypeRFQTitle"),
      description: t("workspaceNext:rfqDrawer:requestTypeRFQDescription"),
    },
    {
      slug: "simple_rfq",
      name: t("workspaceNext:rfqDrawer:requestTypeSimpleRFQTitle"),
      description: t("workspaceNext:rfqDrawer:requestTypeSimpleRFQDescription"),
    },
    {
      slug: "rfi",
      name: t("workspaceNext:rfqDrawer:requestTypeRFITitle"),
      description: t("workspaceNext:rfqDrawer:requestTypeRFIDescription"),
    },
  ];

  // if you want to change the name of the RFx when the request type changes
  // const changeRFxName = useCallback((rfxName: string, v: string): string => {
  //   if (v === "rfi") {
  //     return rfxName.replace(/RFQ/i, "RFI");
  //   } else {
  //     return rfxName.replace(/RFI/i, "RFQ");
  //   }
  // }, []);

  return (
    <Autocomplete
      id={name}
      disableClearable
      renderOption={(props, option: any) => (
        <li {...props}>
          <Grid container direction="column">
            <Grid>
              <Typography variant="subtitle2">{option.name}</Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" className="c-tag-options-description">
                {option.description}
              </Typography>
            </Grid>
          </Grid>
        </li>
      )}
      options={options}
      getOptionLabel={(slug) => {
        if (slug === "simple_rfq") {
          return t("workspaceNext:rfqDrawer:requestTypeSimpleRFQTitle");
        }
        return options.find((option) => option.slug === slug)?.slug.toLocaleUpperCase() || "";
      }}
      {...props}
      {...field}
      disabled={props.form.values.parts?.length > 0}
      onChange={(_: any, v: any) => {
        if (!v) {
          setFieldValue(name, "rfq");
        } else {
          setFieldValue(name, v.slug);
        }
        // setFieldValue("rfq_name", changeRFxName(values.rfq_name, v.slug));
        if (v.slug === "rfi") {
          setFieldValue("parts", []);
          // all RFIs are private
          setFieldValue("is_public", false);
        }
      }}
      style={{ width: "14rem" }}
      onKeyPress={(e) => {
        e.preventDefault();
      }}
      renderInput={(params) => (
        <GenericAutoCompleteTextField
          {...params}
          {...textFieldProps}
          name={name}
          label={label}
          readonly={true}
          autoComplete={"off"}
          disabled={props.form.values.parts?.length > 0}
          size="small"
        />
      )}
    />
  );
};
