import React from "react";
import { Box, Chip, Link, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import CurrencyField from "components/common/fields/CurrencyField";

import { formatDate } from "helpers/formatDate";
import { useDispatch } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { MarketplaceModalTypes } from "../../modals/types";
import { useTranslation } from "react-i18next";
import { useCompanyPlan } from "@next/hooks/useIsPremiumCompany";
import TextCell from "@next/components/table-cells/text-cell-v5";

const defaultColumnConfig: Omit<GridColDef, "field"> = {
  flex: 1,
  editable: false,
  sortable: false,
  minWidth: 100,
  hideSortIcons: true,
  disableColumnMenu: true,
};

const useOnClickPremiumRestriction = () => {
  const isPremiumCompany = useCompanyPlan("premium");
  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!isPremiumCompany) {
      event.preventDefault();
      event.stopPropagation();
      dispatch(
        modalsActions.showModal({
          key: MarketplaceModalTypes.JOIN_PRIVATE_B2B_NETWORK_MODAL,
        })
      );
    }
  };
  return { handleClick, isPremiumCompany };
};

const NameCell: React.FC<GridRenderCellParams> = (params: any) => {
  const cellVal = params?.row?.name;
  const { handleClick, isPremiumCompany } = useOnClickPremiumRestriction();
  if (!cellVal) return null;

  return (
    <div onClick={handleClick}>
      <Tooltip title={cellVal}>
        <Typography noWrap variant="caption">
          <Link
            href={!isPremiumCompany ? "#" : params?.row?.url}
            target="_blank"
            rel="noopener"
            data-pid="linkTender"
          >
            {cellVal}
          </Link>
        </Typography>
      </Tooltip>
    </div>
  );
};

const BuyerCell: React.FC<GridRenderCellParams> = (params: any) => {
  const cellVal = params?.row?.buyer;
  if (!cellVal) return null;

  return (
    <Tooltip title={cellVal}>
      <Typography noWrap variant="caption">
        {cellVal}
      </Typography>
    </Tooltip>
  );
};

const DescriptionCell: React.FC<GridRenderCellParams> = (params: any) => {
  const cellVal = params?.row?.description;
  const { handleClick, isPremiumCompany } = useOnClickPremiumRestriction();
  const { t } = useTranslation();
  if (!cellVal) return null;

  return (
    <Tooltip
      title={
        <div>
          <Typography color="inherit" variant="inherit">
            {cellVal}
          </Typography>
          <Box mt="4px" onClick={handleClick}>
            <Link
              href={!isPremiumCompany ? "#" : params?.row?.url}
              onClick={handleClick}
              target="_blank"
              rel="noopener"
            >
              <Typography
                noWrap
                variant="inherit"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {t("tender:table:seeMore")}
              </Typography>
            </Link>
          </Box>
        </div>
      }
    >
      <Typography noWrap variant="caption">
        <VisibilityOutlinedIcon style={{ color: "gray", marginRight: "6px", fontSize: "16px" }} />
        {cellVal}
      </Typography>
    </Tooltip>
  );
};

const StartDateCell: React.FC<GridRenderCellParams> = (params: any) => {
  const cellVal = params?.row?.start_date;
  if (!cellVal) return null;

  return (
    <>
      <EventAvailableOutlinedIcon style={{ color: "gray", marginRight: "6px", fontSize: "16px" }} />
      {formatDate(cellVal, "DD-MMM-YYYY")}
    </>
  );
};

const EndDateCell: React.FC<GridRenderCellParams> = (params: any) => {
  const cellVal = params?.row?.end_date;
  if (!cellVal) return null;

  return (
    <>
      <FlagOutlinedIcon style={{ color: "gray", marginRight: "6px", fontSize: "16px" }} />
      {formatDate(cellVal, "DD-MMM-YYYY")}
    </>
  );
};

const CategoriesCell: React.FC<GridRenderCellParams> = (params: any) => {
  return params?.row?.categories.map((category: string) => {
    return <Chip label={category} key={category} style={{ fontSize: "12px" }} />;
  });
};

const AmountCell: React.FC<GridRenderCellParams> = (params: any) => {
  const cellVal = params?.row?.amount;
  if (!cellVal) return null;

  return <CurrencyField value={cellVal} currency={params?.row?.amount_currency} />;
};

export const getTendersTableColumns = (t: (...args: any) => string) => {
  const _columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "buyer",
      headerName: t("tender:table:buyer"),
      renderCell: BuyerCell,
    },
    {
      ...defaultColumnConfig,
      flex: 2,
      field: "name",
      headerName: t("tender:table:name"),
      renderCell: (params) => NameCell(params),
    },
    {
      ...defaultColumnConfig,
      field: "description",
      headerName: t("tender:table:description"),
      renderCell: (params) => DescriptionCell(params),
    },
    {
      ...defaultColumnConfig,
      field: "area",
      headerName: t("tender:table:location"),
      renderCell: TextCell,
    },
    {
      ...defaultColumnConfig,
      field: "start_date",
      headerName: t("tender:table:createdOn"),
      renderCell: StartDateCell,
    },
    {
      ...defaultColumnConfig,
      field: "end_date",
      headerName: t("tender:table:endsOn"),
      renderCell: EndDateCell,
    },
    {
      ...defaultColumnConfig,
      field: "categories",
      headerName: t("tender:table:category"),
      renderCell: CategoriesCell,
    },
    {
      ...defaultColumnConfig,
      field: "price",
      headerName: t("tender:table:price"),
      renderCell: AmountCell,
    },
  ];

  return _columns;
};
