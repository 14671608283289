import React from "react";

import { useTranslation } from "react-i18next";

// Image Assets

import notFound from "../../assets/img/404.svg";

import { InformationPage } from "../utils/information-page/information-page.component";

export const QuotationPageNotFound = ({ onClick }) => {
  const { t } = useTranslation("ERRORS");

  return (
    <div className="c-quotation-page-not-found">
      <InformationPage
        src={notFound}
        variant="h2"
        text={t("error404Title")}
        subtext={t("error404quotationPageBody")}
        classNameSubText={t("c-quotation-page-not-found--sub-text")}
        buttonTheme={t("error404quotationPageRedirection")}
        onClick={onClick}
      />
    </div>
  );
};
