import { eventChannel } from "redux-saga";

export function createEventChannel() {
  let emit: any;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  return [emit, chan];
}
