import { npsConstants } from "./nps.constants";

const initialState = {
  isSubmitRatingLoading: false,
  isNPSDismissed: false,
};

export function nps(state = initialState, action) {
  switch (action.type) {
    case npsConstants.SEND_NPS_REQUEST:
      return { ...state, isSubmitRatingLoading: true };

    case npsConstants.SEND_NPS_SUCCESS:
      return {
        ...state,
        isSubmitRatingLoading: false,
        isNPSDismissed: action.has_dismissed,
      };

    case npsConstants.SEND_NPS_FAILURE:
      return { ...state, isSubmitRatingLoading: false };

    default:
      return state;
  }
}
