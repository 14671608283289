import {
  TableRowMenu,
  TableRowMenuItem,
  TableRowMenuProps,
} from "@next/components/table-row-menu/table-row-menu";
import React, { useMemo } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "../../modals/types";
import { useDispatch } from "react-redux";
import { ProjectCreateRenameFormVariant } from "./project-create-rename-form/project-create-rename-form";
import { ProjectCreateRenameModalProps } from "../../modals/project-create-rename-modal";
import { useTranslation } from "react-i18next";
import i18n from "assets/configi18n/i18n";
import { ProjectType } from "../../redux";

type Props = {
  projectDetails: ProjectType;
} & TableRowMenuProps;

export const ProjectsTableRowMenu: React.FC<Props> = ({ projectDetails, ...restProps }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const menuItems: TableRowMenuItem[] = useMemo(
    () => [
      {
        text: t("common:rename"),
        onClick: () => {
          dispatch(
            modalsActions.showModal({
              key: ProjectModalTypes.CREATE_RENAME_MODAL,
              data: {
                projectCode: projectDetails.code,
                projectName: projectDetails.display_name,
                variant: ProjectCreateRenameFormVariant.RENAME,
              } as ProjectCreateRenameModalProps,
            })
          );

          restProps.setAnchorEl(null);
        },
        Icon: EditOutlinedIcon,
      },
      {
        text: t("common:delete"),
        onClick: () => {
          dispatch(
            modalsActions.showModal({
              key: ProjectModalTypes.PROJECT_DELETE_MODAL,
              data: {
                projectCode: projectDetails.code,
                display_name: projectDetails.display_name,
                total_parts_count: projectDetails.total_parts_count,
                attached_files_count: projectDetails.attached_files_count,
              },
            })
          );

          restProps.setAnchorEl(null);
        },
        Icon: DeleteForeverOutlinedIcon,
        disabled: !projectDetails?.can_safe_delete,
      },
    ],
    [projectDetails, i18n.language]
  );

  return <TableRowMenu {...restProps} menuItems={menuItems} />;
};
