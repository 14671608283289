import { Box, Typography, styled } from "@mui/material";

export const StyledRootBox = styled(Box)({
  overflowY: "visible",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "64px",
});

export const StyledInnerBox = styled(Box)({
  height: "72px",
  padding: "24px",
  borderBottom: "1px solid rgba(224, 224, 224, 1)",
});

export const StyledContentBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  padding: "24px",
});

export const StyledTypography = styled(Typography)({
  color: "rgba(0, 0, 0, 0.87)",
  letterSpacing: "0.5px",
});
