/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsTableColumns } from "./notifications-table.columns";
import { appActions } from "@next/modules/app/redux";
import {
  selectNotificationIsNextPageAvailable,
  selectNotifications,
  selectNotificationsLoading,
  selectNotificationsPage,
} from "@next/modules/app/redux/selectors";

export const useNotificationsTableData = () => {
  const dispatch = useDispatch();

  const notifications = useSelector(selectNotifications);
  const notificationsColumns = getNotificationsTableColumns();
  const notificationsLoading = useSelector(selectNotificationsLoading);
  const notificationsPage = useSelector(selectNotificationsPage);
  const notificationIsNextPageAvailable = useSelector(selectNotificationIsNextPageAvailable);

  const fetchNotifications = ({ page, reset }: { page: number; reset?: boolean }) => {
    dispatch(
      appActions.fetchNotificationsRequest({
        page,
        reset,
      })
    );
  };

  useEffect(() => {
    fetchNotifications({ page: 1, reset: true });
  }, []);

  return {
    gridData: { rows: notifications || [], columns: notificationsColumns },
    notificationsLoading,
    notificationsPage,
    fetchNotifications,
    notificationIsNextPageAvailable,
  };
};
