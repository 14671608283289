import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  BulkFileImportSummaryItem,
  BulkFileImportSummaryItemType,
} from "./bulk-file-import-summary-item/bulk-file-import-summary-item";
import { getSummaryItemText } from "./bulk-file-import-summary-item/bulk-file-import-summary-item.utils";
import { createStyles, makeStyles } from "@mui/styles";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { selectBomFilesUploadReport } from "@next/modules/project/redux/selectors";
import { BomFilesUploadReport } from "@next/redux/types";
import { BulkFileImportFor } from "@next/modals/shared-bulk-files-import-modal";
import { selectOrderBulkFilesUploadReport } from "@next/modules/orders/redux/selectors";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: "24px",
      paddingTop: 0,
      position: "relative",
      height: "83%",
    },
    content: { overflowY: "auto", height: "92%" },
    rightButton: {
      position: "absolute",
      right: "24px",
      bottom: "4px",
    },
  })
);

type Props = {
  onClickCloseReport: () => void;
  onAttachmentsChange?: (files: FileData[], filePkToDelete?: number) => void;
  bulkFileImportFor: BulkFileImportFor;
};

export const BulkFileImportSummary: React.FC<Props> = ({
  onClickCloseReport,
  onAttachmentsChange,
  bulkFileImportFor,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const filesReportSelector =
    bulkFileImportFor === BulkFileImportFor.PROJECT
      ? selectBomFilesUploadReport
      : selectOrderBulkFilesUploadReport;
  const bomFilesUploadReport: BomFilesUploadReport = useSelector(filesReportSelector);

  if (!bomFilesUploadReport) return null;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <BulkFileImportSummaryItem
          type={BulkFileImportSummaryItemType.SUCCESS}
          summaryItemText={getSummaryItemText(
            BulkFileImportSummaryItemType.SUCCESS,
            bomFilesUploadReport
          )}
          bulkFileImportFor={bulkFileImportFor}
          hide={bomFilesUploadReport.files_success_count === 0}
        />

        <BulkFileImportSummaryItem
          type={BulkFileImportSummaryItemType.WARNING}
          summaryItemText={getSummaryItemText(
            BulkFileImportSummaryItemType.WARNING,
            bomFilesUploadReport
          )}
          fileStatusReport={bomFilesUploadReport.warning_report}
          bulkFileImportFor={bulkFileImportFor}
          hide={
            bomFilesUploadReport.files_warning_count === 0 ||
            bomFilesUploadReport?.additional_attachments?.length > 0
          }
        />

        <BulkFileImportSummaryItem
          type={BulkFileImportSummaryItemType.ERROR}
          summaryItemText={getSummaryItemText(
            BulkFileImportSummaryItemType.ERROR,
            bomFilesUploadReport
          )}
          fileStatusReport={bomFilesUploadReport.error_report}
          bulkFileImportFor={bulkFileImportFor}
          hide={bomFilesUploadReport.files_error_count === 0}
        />

        <BulkFileImportSummaryItem
          type={BulkFileImportSummaryItemType.ATTACHMENT}
          summaryItemText={getSummaryItemText(
            BulkFileImportSummaryItemType.ATTACHMENT,
            bomFilesUploadReport
          )}
          fileStatusReport={bomFilesUploadReport.warning_report}
          additionalAttachments={bomFilesUploadReport?.additional_attachments}
          bulkFileImportFor={bulkFileImportFor}
          hide={
            !bomFilesUploadReport?.additional_attachments ||
            bomFilesUploadReport?.additional_attachments.length === 0
          }
          onAttachmentsChange={onAttachmentsChange}
        />
      </div>

      <div className={classes.rightButton}>
        <Button variant="outlined" onClick={onClickCloseReport}>
          {t("project:bulkFileImport:closeReport")}
        </Button>
      </div>
    </div>
  );
};
