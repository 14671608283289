import { Box, Grid, Typography } from "@mui/material";
import { SellerDashboardCard } from "@next/modules/dashboard/components/seller-dashboard-card/seller-dashboard-card";
import React, { useMemo } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import { useSellerDashboardData } from "./seller-dashboard.hooks";
import { SellerDashboardViewsGraph } from "../../components/seller-dashboard-views-graph/seller-dashboard-views-graph";
import { SellerDashboardUserViewsTable } from "../../components/seller-dashboard-user-views-table/seller-dashboard-user-views-table";
import { SellerDashboardPremiumStatusCard } from "../../components/seller-dashboard-premium-status-card/seller-dashboard-premium-status-card";
import { openProfileInNewTab } from "@next/utils/browserUtils";
import { DashboardInformation } from "../../components/dashboard-information/dashboard-information";
import { useTranslation } from "react-i18next";
import { CustomCircularProgress } from "@next/components/custom-circular-progress";
import { selectCompanyProfileViewsCount } from "../../redux/selectors";
import { batch, useDispatch, useSelector } from "react-redux";
import { getCompany } from "services/profile/profile.selectors";
import { getBuyersNetwork } from "services/part-configuration/part-configuration.selectors";
import { useSellerDashboardUserViewsTableData } from "../../components/seller-dashboard-user-views-table/seller-dashboard-user-views-table.hooks";
import { BuyerProfileDrawer } from "../../modals/buyer-profile-drawer";
import { SellerDashboardActiveBuyers } from "../../components/seller-dashboard-active-buyers/seller-dashboard-active-buyers";
import ProfileViewsLocked from "../../components/profile-views-locked/profile-views-locked";
import { ActionCard } from "@next/components/action-card/action-card";
import FlashOnRoundedIcon from "@mui/icons-material/FlashOnRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProfileModalTypes } from "@next/modules/profile/modals/types";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import { getSelectedLanguage } from "@next/utils/browserUtils";
import { DiscoveryModal } from "@next/modules/profile/modals/user-profile-discovery-modal";
import { profileNextActions } from "@next/modules/profile/redux";
import { SellerDashboardMyNetworkTabs } from "../../components/seller-dashboard-my-network-tabs/seller-dashboard-my-network-tabs";
import SellerDashboardProfileCompletenessCard from "../../components/seller-dashboard-profile-completeness-card/seller-dashboard-profile-completeness-card";
import { SellerDashboardGreetings } from "../../components/seller-dashboard-greetings/seller-dashboard-greetings";
import { SellerDashboardOnboardingPremium } from "../../components/seller-dashboard-onboarding-premium/seller-dashboard-onboarding-premium";
import { useCompanyPlan } from "@next/hooks/useIsPremiumCompany";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
  })
);

export const SellerDashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const companyProfileViewsCount = useSelector(selectCompanyProfileViewsCount);

  const company = useSelector(getCompany);
  const buyersNetwork = useSelector(getBuyersNetwork);

  const { companyProfileAnalytics, companyProfileAnalyticsLoading, supplierOnboardingStatus } =
    useSellerDashboardData();

  const isPremiumCompany = useCompanyPlan("premium");

  const dashboardUserViewsTableData = useSellerDashboardUserViewsTableData();

  const onOpenMyProfilePageClick = () => {
    company?.uuid && openProfileInNewTab(company?.name, company?.uuid);
  };

  const onGetRecommendationClick = () => {
    const onClose = () => {
      batch(() => {
        dispatch(modalsActions.closeModal(ProfileModalTypes.USER_PROFILE_SEND_DISCOVERY_DRAWER));
      });
    };

    const onSubmit = (values: any) => {
      dispatch(
        profileNextActions.sendDiscoveryRequest({
          type: "connection",
          files: values.files?.map((item: any) => item?.file),
          message: values.message as any,
        })
      );
    };

    dispatch(
      modalsActions.showModal({
        key: ProfileModalTypes.USER_PROFILE_SEND_DISCOVERY_DRAWER,
        data: {
          onSubmit: onSubmit,
          onClose: onClose,
          dialogTitle: t("discovery:recommendation:dialogHeader"),
          descriptionTitle: t("discovery:recommendation:dialogTitle"),
          descriptionText: t("discovery:recommendation:dialogDescription"),
          messageInputLabel: t("discovery:recommendation:dialogInputLabel"),
          descriptionIcon: FlashOnRoundedIcon,
          sendButtonText: t("common:send"),
        },
      })
    );
  };

  const onGetQuotationClick = () => {
    const onClose = () => {
      batch(() => {
        dispatch(modalsActions.closeModal(ProfileModalTypes.USER_PROFILE_SEND_DISCOVERY_DRAWER));
      });
    };

    const onSubmit = (values: any) => {
      dispatch(
        profileNextActions.sendDiscoveryRequest({
          type: "quotation",
          files: values.files?.map((item: any) => item?.file),
          message: values.message as any,
        })
      );
    };

    dispatch(
      modalsActions.showModal({
        key: ProfileModalTypes.USER_PROFILE_SEND_DISCOVERY_DRAWER,
        data: {
          onSubmit: onSubmit,
          onClose: onClose,
          dialogTitle: t("discovery:quotation:dialogHeader"),
          descriptionTitle: t("discovery:quotation:dialogTitle"),
          descriptionText: t("discovery:quotation:dialogDescription"),
          messageInputLabel: t("discovery:quotation:dialogInputLabel"),
          descriptionIcon: SearchRoundedIcon,
          sendButtonText: t("common:send"),
        },
      })
    );
  };

  const onAxyaNetworkClick = () => {
    const selectedLanguage = getSelectedLanguage();
    window.location.href = `/${selectedLanguage}/search`;
  };

  const isAnyStepIncomplete = useMemo(
    () =>
      supplierOnboardingStatus &&
      Object.values(supplierOnboardingStatus).some((item) => item === false),
    [supplierOnboardingStatus]
  );

  return (
    <div className={classes.root}>
      {/* <Box display="flex" alignItems="center" position="absolute" top="24px">
        <Typography variant="h5">{t("sellerDashboard:title")}</Typography>
      </Box>

      <Box mt="47px" ml="-40px" mr="-32px" position="relative" zIndex="1">
        <Divider />
      </Box> */}

      {/* <DashboardInformation subTitle={t("sellerDashboard:subTitle")} /> */}

      <SellerDashboardGreetings />

      {companyProfileAnalyticsLoading ? (
        <CustomCircularProgress />
      ) : (
        <Box mt="24px">
          <Grid container spacing={3}>
            {isPremiumCompany && isAnyStepIncomplete && (
              <Grid item xs={12}>
                <SellerDashboardOnboardingPremium onboardingStatus={supplierOnboardingStatus} />
              </Grid>
            )}
            <Grid item xs>
              <SellerDashboardPremiumStatusCard />
            </Grid>

            {/* <Grid item xs>
            <SellerDashboardCard
              title="Showcase demo data"
              subTitle={
                <>
                  <Switch defaultChecked color="primary" />
                </>
              }
            />
          </Grid> */}

            <Grid item xs>
              <SellerDashboardProfileCompletenessCard
                allowEditAction
                completenessPercentage={company?.profile_completeness?.total}
              />
            </Grid>

            <Grid item xs>
              <SellerDashboardCard
                title={t("sellerDashboard:profileVisits")}
                content={
                  <Typography variant="h6">{companyProfileAnalytics?.views_count}</Typography>
                }
              />
            </Grid>
            <Grid item xs>
              <SellerDashboardCard
                title={t("sellerDashboard:buyerConnections")}
                content={<Typography variant="h6">{buyersNetwork?.length}</Typography>}
              />
            </Grid>

            <Grid item xs>
              <SellerDashboardCard
                title={t("sellerDashboard:openProfile")}
                content={<OpenInNewIcon />}
                onClick={onOpenMyProfilePageClick}
              />
            </Grid>

            <Grid item xs={12}>
              <SellerDashboardActiveBuyers />
            </Grid>

            <Grid item xs={12}>
              <Box mb={2}>
                <DashboardInformation subTitle={t("discovery:supplierTitle")} />
              </Box>
              <Box display="flex" justifyContent="center">
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignItems="stretch"
                  justifyContent="center"
                >
                  <Grid item xs={12} sm={12} md={4}>
                    <ActionCard
                      Icon={FlashOnRoundedIcon}
                      title={t("discovery:recommendation:cardTitle")}
                      btnText={t("discovery:recommendation:cardButton")}
                      onClick={onGetRecommendationClick}
                      btnTestId="btnGetMyRecommendation"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <ActionCard
                      Icon={SearchRoundedIcon}
                      title={t("discovery:network:cardTitle")}
                      btnText={t("discovery:network:cardButton")}
                      onClick={onAxyaNetworkClick}
                      btnTestId="btnSearchTheDirectory"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <ActionCard
                      Icon={AttachMoneyOutlinedIcon}
                      title={t("discovery:quotation:cardTitle")}
                      btnText={t("discovery:quotation:cardButton")}
                      onClick={onGetQuotationClick}
                      btnTestId="btnSubmitARequestQuotes"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <SellerDashboardCard
                title={t("sellerDashboard:profileVisitsOverTime")}
                titleVariant="h6"
                content={
                  <SellerDashboardViewsGraph viewsData={companyProfileAnalytics?.views_data} />
                }
                height="100%"
                disableFocusHighlight
                cardPlaceholderProps={{
                  title: t("sellerDashboard:noDataYet"),
                  subTitle: t("sellerDashboard:noDataYetDescriptionGraph"),
                  Icon: BarChartRoundedIcon,
                }}
              />
            </Grid>

            <Grid item lg={6} md={12} xs={12}>
              <SellerDashboardCard
                title={t("sellerDashboard:usersHaveVisitedYourProfile")}
                titleVariant="h6"
                content={
                  isPremiumCompany ? (
                    <SellerDashboardUserViewsTable {...dashboardUserViewsTableData} />
                  ) : (
                    <ProfileViewsLocked />
                  )
                }
                height="min-content"
                disableFocusHighlight
                showCardPlaceholder={companyProfileViewsCount === 0}
                cardPlaceholderProps={{
                  title: t("sellerDashboard:noDataYet"),
                  subTitle: t("sellerDashboard:noDataYetDescriptionUser"),
                  Icon: VisibilityOutlinedIcon,
                }}
              />
            </Grid>

            <Grid item lg={6} md={12} xs={12} marginBottom={"8px"}>
              <SellerDashboardCard
                title={t("sellerDashboard:myNetwork")}
                titleVariant="h6"
                content={<SellerDashboardMyNetworkTabs />}
                minHeight="360px"
                disableFocusHighlight
                showCardPlaceholder={buyersNetwork?.length === 0}
                cardPlaceholderProps={{
                  title: t("sellerDashboard:noDataYet"),
                  subTitle: t("sellerDashboard:noDataYetDescriptionBuyer"),
                  Icon: AccountBoxOutlinedIcon,
                }}
                height="100%"
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <BuyerProfileDrawer />
      <DiscoveryModal />
    </div>
  );
};
