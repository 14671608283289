import React from "react";
import { GridCloseIcon, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { IconButton } from "@mui/material";

type Props = {
  disabled?: boolean;
} & GridRenderCellParams;

export const RemoveBtnCell: React.FC<Props> = ({ disabled }) => (
  <IconButton style={{ opacity: "1" }} size="large" disabled={disabled}>
    <GridCloseIcon style={{ color: "black", opacity: "1" }} />
  </IconButton>
);
