import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    marginBottom: 30,
  },
});

export default function StatePriceTable({ data, t }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("ranking")}</TableCell>
            {data && data.headers.map((part, i) => <TableCell align="center">{part}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.rows.map((row, i) => (
              <TableRow key={row.company_name}>
                <TableCell component="th" scope="row">
                  #{i + 1}
                </TableCell>
                {row.map((part) => (
                  <TableCell
                    align="center"
                    className={`${part.mine ? "c-my-company" : ""} ${
                      part.company_name.includes("Winner") || part.company_name.includes("Gagnant")
                        ? "c-winner-company"
                        : ""
                    }`}
                  >
                    {part.company_name}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
