import React from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { Box, Button, Grid, Typography } from "@mui/material";
import { TLogin2FAFormState } from "./login-2fa-form-definitions";
import { GenericTextInput } from "components/utils/input/text";
import CheckIcon from "@mui/icons-material/Check";
import LinkButton from "@next/components/link-button/link-button";
import { frontendUrl } from "urls";
import { history } from "helpers/history";

export const Login2FAFormBase: React.FC<FormikProps<TLogin2FAFormState>> = ({
  values,
  errors,
  touched,
  handleSubmit,
}) => {
  const { code } = values;
  const { t } = useTranslation();

  const onClickBack = () => {
    history.replace(frontendUrl.login, null);
  };

  return (
    <form onSubmit={handleSubmit} className="c-login">
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid container item xs={10} className="c-login--field">
          <GenericTextInput
            name="code"
            value={code}
            placeholder={t("auth:login:code")}
            helperText={touched.code ? errors.code : ""}
            error={touched.code && Boolean(errors.code)}
          />

          <Box m="4px">
            <Typography variant="caption" color="textSecondary">
              {t("auth:login:verifyCodeTip")}
            </Typography>
          </Box>
        </Grid>

        <Grid container item xs={10} className="c-login--button">
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSubmit()}
            fullWidth={true}
            size="large"
            startIcon={<CheckIcon />}
          >
            {t("auth:login:verifyCode")}
          </Button>
        </Grid>

        <Grid container item xs={10} justifyContent="center" direction="column">
          <Box display={"flex"} justifyContent="center" mt="16px">
            <Typography variant="body1">{t("profile:userProfile:dontSeeTheEmail")}</Typography>
            <Box ml="8px">
              <LinkButton onClick={onClickBack}>
                <Typography variant="body1">{t("auth:login:goBackToLogin")}</Typography>
              </LinkButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
