export const fileStateToZip = (zip: any, files: File[] = [], callback: any) => {
  try {
    if (!zip) return null;

    for (let file = 0; file < files.length; file++) {
      // Zip file with the file name.
      zip.file(files[file].name, files[file]);
    }

    zip.generateAsync({ type: "blob" }).then((content: any) => {
      callback && callback(content);
    });
  } catch (error) {
    console.error(error);
  }
};
