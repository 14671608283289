import { Box, Chip, Theme, Typography, alpha } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { createStyles, makeStyles } from "@mui/styles";

type StyleProps = {
  isActive?: boolean;
  onClick?: () => void;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      gridGap: 16,
      padding: theme.spacing(2),
      border: ({ isActive }) =>
        `1px solid ${isActive ? theme.palette.primary.main : theme.palette.divider}`,
      borderRadius: 16,
      width: 312,
      backgroundColor: ({ isActive }) =>
        isActive ? theme.palette.action.hover : theme.palette.common.white,
      cursor: ({ onClick }) => (typeof onClick === "function" ? "pointer" : undefined),
      transition: theme.transitions.create("background-color"),
      "&:hover": {
        backgroundColor: ({ onClick }: StyleProps) =>
          typeof onClick === "function" ? theme.palette.action.hover : undefined,
      },
    },
    badge: {
      height: 20,
      minWidth: 20,
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    badgeText: {
      color: theme.palette.common.white,
    },
    chip: {
      color: theme.palette.warning.dark,
      backgroundColor: alpha(theme.palette.warning.main, 0.1),
      alignSelf: "flex-start",
    },
  })
);

type Props = {
  title: string;
  description: string;
  count: number;
  unreadCount?: number;
  isPro?: boolean;
} & StyleProps;

const MarketplaceStatsCard: React.FC<Props> = ({
  title,
  description,
  count,
  unreadCount = 0,
  isPro = false,
  isActive = false,
  onClick,
}) => {
  const classes = useStyles({ isActive, onClick });
  const { t } = useTranslation();

  return (
    <Box className={classes.root} onClick={onClick}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">{title}</Typography>
        <Box display="flex" alignItems="center" gap="8px">
          <Typography variant="body2">{count}</Typography>
          {unreadCount > 0 && (
            <Box className={classes.badge}>
              <Typography variant="caption" className={classes.badgeText}>
                {unreadCount}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Typography variant="caption" color="textSecondary">
        {description}
      </Typography>
      {isPro && (
        <Chip size="small" className={classes.chip} label={t("marketplaceRequests:premiumPlan")} />
      )}
    </Box>
  );
};

export default MarketplaceStatsCard;
