import React from "react";

// Hooks
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Box, Grid } from "@mui/material";

PartTags.propTypes = {
  /**
   * Part info object
   */
  part: PropTypes.object.isRequired,
};

function PartTags({ part }) {
  const { t } = useTranslation("workspace");

  return (
    <Grid container>
      <Grid item xs={12} className="c-part-tags">
        {part.processes && (
          <Box className="c-part-field-box">
            <Box className="c-part-field-name">
              {`${part.processes.length > 1 ? t("processes") : t("process")}`}
            </Box>
            <Box className="c-part-field-value">
              {part.processes.map((process) => ` ${process.name}`).join()}
            </Box>
          </Box>
        )}

        {part.alloy && (
          <Box className="c-part-field-box">
            <Box className="c-part-field-name">{`${t("material")}`}</Box>
            <Box className="c-part-field-value">{`${part.alloy}`}</Box>
          </Box>
        )}

        <Box className="c-part-field-box">
          <Box className="c-part-field-name">{`${t("treatment")}`}</Box>
          <Box className="c-part-field-value capitalize">
            {" "}
            {part.treatment ? part.treatment : t("QUOTE_LIST:noTreatment")}
          </Box>
        </Box>

        <Box className="c-part-field-box">
          <Box className="c-part-field-name">{`${t("quantity")}`}</Box>
          <Box className="c-part-field-value">{part.quantity}</Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default PartTags;
