import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Dialog, DialogContent, DialogProps, Theme } from "@mui/material";
import { batch, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "./types";
import UploadQuotationForm from "../components/upload-quotation/upload-quotation.form";
import { FileData } from "@next/components/attach-file/attach-file-list.types";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    dialogPaper: {
      width: "100%",
      borderRadius: "24px",
      maxWidth: "100%",
      height: "100%",
      padding: theme.spacing(0),
    },
    dialogTitle: {
      padding: theme.spacing(3),
      margin: 0,
      display: "flex",
      alignItems: "center",
    },
    heading: {
      display: "flex",
      alignItems: "baseline",
      gap: theme.spacing(1.5),
    },
    dialogContent: {
      display: "flex",
      width: "100%",
      height: "100%",
      padding: theme.spacing(0),
    },
    headerRightContainer: {
      display: "flex",
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
);

export type UploadQuotationAsBuyerModal = {
  supplier_id: number;
  supplier_picture: string;
  parts: any[];
  currency: string;
  delay: number;
  delay_unit: string;
  files: FileData[];
  fixed_charge: number;
  total_price: number;
  is_edit?: boolean;
  quotation_id?: number;
  rfq_id?: number;
};

type Props = {
  dialogProps?: Partial<DialogProps>;

  modalHeader?: string | React.ReactNode;
  modalBanner?: React.ReactNode;
  className?: string;
  bannerClassName?: string;
  dialogContentClassName?: string;
  dialogTitleClassName?: string;
};

const UploadQuotationAsBuyerModal: React.FC<Props> = ({ dialogProps }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const modal = useSelector(getModal(WorkspaceModalTypes.UPLOAD_QUOTATION_AS_BUYER_MODAL));

  const data = modal?.data as UploadQuotationAsBuyerModal;

  const onClose = () => {
    batch(() => {
      dispatch(modalsActions.closeModal(WorkspaceModalTypes.UPLOAD_QUOTATION_AS_BUYER_MODAL));
    });
  };

  return (
    <Dialog
      maxWidth="md"
      onClose={onClose}
      open={!!modal}
      classes={{ paper: classes.dialogPaper }}
      {...dialogProps}
    >
      <DialogContent className={classes.dialogContent}>
        <UploadQuotationForm onClose={onClose} quotationData={data} />
      </DialogContent>
    </Dialog>
  );
};

export default UploadQuotationAsBuyerModal;
