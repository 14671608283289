import { axiosApi } from "@next/api";
import { backendUrl } from "urls";
import {
  CheckUserEmailInput,
  FetchNotificationsInput,
  MarkAsSeenNotificationInput,
  Notification,
} from "../redux";
import { generatePaginationAndSearchQuery } from "@next/utils/paginationUtils";

const checkUserEmail = ({ email }: CheckUserEmailInput) =>
  axiosApi.post(`${backendUrl.checkUserEmail}`, {
    email,
  });

const fetchNotifications = ({ page }: FetchNotificationsInput) => {
  return axiosApi.get<Notification[]>(
    `${backendUrl.messageNotifications}?${generatePaginationAndSearchQuery({
      currentPage: page,
      pageSize: 20,
    })}`
  );
};

const markAsSeenNotification = ({ notificationId }: MarkAsSeenNotificationInput) => {
  return axiosApi.patch<Partial<Notification>>(
    `${backendUrl.messageNotifications}${notificationId}/`
  );
};

const markAllAsSeenNotification = () => {
  return axiosApi.post(`${backendUrl.messageNotifications}`);
};

export const appService = {
  checkUserEmail,
  fetchNotifications,
  markAsSeenNotification,
  markAllAsSeenNotification,
};
