import { Box, SvgIconProps, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

export const DashboardInformation: React.FC<{
  subTitle: string;
  subTitle2?: string;
  Icon?: React.ComponentType<SvgIconProps>;
  showDate?: boolean;
}> = ({ subTitle, Icon, showDate, subTitle2 }) => {
  return (
    <Box display="flex" justifyContent={"center"} alignItems="center" flexDirection={"column"}>
      {showDate ? (
        <Box display="flex" mt="28px">
          <Typography variant="body2" color="textSecondary" style={{ textTransform: "capitalize" }}>
            {moment().format("LL")}
          </Typography>
        </Box>
      ) : null}

      <Box mt="24px" display="flex" alignItems="center">
        <Typography variant="h5">{subTitle}</Typography>

        <Box ml={"8px"} mt="4px">
          {Icon && <Icon />}
        </Box>
      </Box>

      <Box mt="16px" display="flex" alignItems="center">
        <Typography variant="body1" color="textSecondary">
          {subTitle2}
        </Typography>
      </Box>
    </Box>
  );
};
