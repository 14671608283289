import React from "react";
import { Box, Typography } from "@mui/material";
import { batch, useDispatch, useSelector } from "react-redux";
import { selectSelectedPartPk } from "@next/modules/project/redux/selectors";

import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import snackbarUtils from "@next/utils/snackbarUtils";

type Props = {
  isRFQPart?: boolean;
  isRFQEditMode?: boolean;
  isOrderPart?: boolean;
};

const FileDropzone: React.FC<Props> = ({ isRFQPart, isRFQEditMode, isOrderPart }) => {
  const dispatch = useDispatch();
  const selectedPartPk = useSelector(selectSelectedPartPk);
  const { t } = useTranslation();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: (file) => {
      batch(() => {
        dispatch(
          workspaceNextActions.uploadMultiplePartFileRequest({
            files: file,
            partPk: selectedPartPk,
            isRFQPart,
            isOrderPart,
            isRFQEditMode,
          })
        );
        dispatch(workspaceNextActions.setShowRevisionStatusBar(true));
      });
    },
    onDropRejected: (fileRejections) => {
      fileRejections.map(({ file, errors }) =>
        errors.map((error) => snackbarUtils.error(`${file?.name} - ${error?.message}`))
      );

      dispatch(workspaceNextActions.uploadMultiplePartFileRequestFailure());
    },
  });

  return (
    <Box {...getRootProps()}>
      <input {...getInputProps()} />

      <Box
        className={`c-file-dropzone__container ${
          isDragActive ? "c-file-dropzone__container--active" : ""
        }`}
      >
        {isDragActive ? (
          <Typography variant="h6">{t("project:table:row:dragAndDropInThisZone")}</Typography>
        ) : (
          <Typography variant="subtitle1" color="textSecondary">
            {t("project:table:row:dragAndDropFiles")}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default FileDropzone;
