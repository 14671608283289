import { Box } from "@mui/material";
import { Skeleton } from "@mui/material";
import { debounce } from "lodash";
import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import PaginatedAutoCompleteLoader from "../paginated-autocomplete/paginated-autocomplete-loader";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      listStyleType: "none",
      overflow: "auto",
      position: "relative",
      marginLeft: 0,

      "&> li": {
        marginBottom: 0,
      },
    },
  })
);

interface PaginatedListProps {
  maxHeight?: number | string;
  data: any[];
  renderItem?: (option: any, ix: number) => ReactNode;
  keyExtractor?: (option: any, ix: number) => string | number;
  endReachedPercent?: number;
  endReachedDebouncing?: number;
  loadingSkeletonsCount?: number;
  LoadingSkeleton?: ReactNode;
  onEndReached?: () => void;
  onInitialFetch?: () => void;
  loading?: boolean;
  EmptyContent?: ReactNode;
}

const PaginatedList: React.FC<PaginatedListProps & React.HTMLAttributes<HTMLUListElement>> = ({
  maxHeight = 300,
  data,
  renderItem,
  keyExtractor,
  endReachedPercent = 80,
  endReachedDebouncing = 500,
  loadingSkeletonsCount = 3,
  LoadingSkeleton = <Skeleton width="100%" height={24} variant="rectangular" />,
  onEndReached,
  onInitialFetch,
  loading,
  EmptyContent,
  ...props
}) => {
  const classes = useStyles();
  const listRef = useRef<HTMLUListElement | null>(null);

  const _onEndReached = useCallback(
    debounce(
      () => {
        if (typeof onEndReached === "function") setTimeout(onEndReached, 200);
      },
      endReachedDebouncing,
      {
        leading: true,
        trailing: false,
      }
    ),
    [onEndReached, endReachedDebouncing]
  );

  useEffect(() => {
    if (typeof onInitialFetch === "function") onInitialFetch();
  }, [onInitialFetch]);

  useEffect(() => {
    let lastScrollTop = 0;
    const onScroll = (e: Record<string, any>) => {
      const { clientHeight, scrollTop, scrollHeight } = e.target as HTMLElement;
      //if scrolldown
      if (scrollTop > lastScrollTop && !loading) {
        const maxScroll = scrollHeight - clientHeight;
        const currentPercent = (scrollTop / maxScroll) * 100;
        if (currentPercent > endReachedPercent) {
          _onEndReached();
        }
      }
      lastScrollTop = Math.max(0, scrollTop);
    };

    if (listRef.current) listRef.current.onscroll = onScroll;
  }, [listRef.current, loading, _onEndReached, endReachedPercent]);

  return (
    <ul
      {...props}
      style={{ maxHeight, ...(props.style || {}) }}
      className={`${classes.root} ${props.className || ""}`}
      ref={listRef}
    >
      {data.map((item, ix) => (
        <li key={typeof keyExtractor === "function" ? keyExtractor(item, ix) : ix}>
          {typeof renderItem === "function" ? renderItem(item, ix) : item}
        </li>
      ))}
      {loading ? (
        <Box mt={2}>
          <PaginatedAutoCompleteLoader
            loadingSkeletonsCount={loadingSkeletonsCount}
            LoadingSkeleton={LoadingSkeleton}
          />
        </Box>
      ) : null}
      {!data.length && EmptyContent ? EmptyContent : null}
    </ul>
  );
};

export default PaginatedList;
