import { Box, Button, CircularProgress } from "@mui/material";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { SharedModalTypes } from "@next/modals/types";
import { RFQStatus, RfqDetails, projectActions } from "@next/modules/project/redux";
import { selectUpdateRFQLoading } from "@next/modules/project/redux/selectors";
import { modalsActions } from "@next/redux/modalsSlices";
import { GenericTextInput } from "components/utils/input/text";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  AddOnHoldNoteFormState,
  addOnHoldNoteFormValidationSchema,
} from "./add-on-hold-note.form.definitions";

const SharedAddOnHoldNoteFormBase: React.FC<
  FormikProps<AddOnHoldNoteFormState> & { onClickSkip: () => void }
> = ({ handleSubmit, values, onClickSkip }) => {
  const { t } = useTranslation();
  const updateRfqLoading = useSelector(selectUpdateRFQLoading);

  return (
    <Form onSubmit={handleSubmit}>
      <Box mt="24px">
        <GenericTextInput name="on_hold_note" label={"Note"} value={values.on_hold_note} />
      </Box>

      <Box display="flex" justifyContent="flex-end" mt="24px">
        <Box mr="16px" display="flex" alignItems="center">
          {updateRfqLoading ? <CircularProgress size={16} /> : null}
        </Box>

        <Box mr="10px">
          <Button variant="outlined" onClick={onClickSkip} disabled={updateRfqLoading}>
            {t("common:skip")}
          </Button>
        </Box>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={values.on_hold_note === "" || updateRfqLoading}
        >
          {t("workspaceNext:buyerRfqs:addNote")}
        </Button>
      </Box>
    </Form>
  );
};

type Props = {
  rfqPk: number;
  isDetailPage?: boolean;
};

export const SharedAddOnHoldNoteForm: React.FC<Props> = ({ rfqPk, isDetailPage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const axyaTracking = useAxyaTracking();

  const updateRfqOnHold = (partialRfq?: Partial<RfqDetails>) => {
    dispatch(
      projectActions.updateRFQRequest({
        pk: rfqPk,
        status: RFQStatus.ON_HOLD,
        ...partialRfq,
        refetchData: true,
        isDetailPage,
        onSuccess: () => {
          axyaTracking({
            scout_category: "usage_metric",
            scout_rfq_pk: rfqPk,
            scout_action: "put-rfq-on-hold",
          });
          dispatch(modalsActions.closeModal(SharedModalTypes.ADD_ON_HOLD_NOTE_MODAL));
        },
      })
    );
  };

  const onSubmit = (values: AddOnHoldNoteFormState) => {
    updateRfqOnHold({ on_hold_note: values.on_hold_note });
  };

  const onClickSkip = () => {
    updateRfqOnHold();
  };

  return (
    <Box p="8px">
      {t("project:table:row:addANoteSubtext")}

      <Formik
        initialValues={{ on_hold_note: "" }}
        validationSchema={addOnHoldNoteFormValidationSchema}
        onSubmit={onSubmit}
        validateOnMount
        render={(props) => <SharedAddOnHoldNoteFormBase onClickSkip={onClickSkip} {...props} />}
      />
    </Box>
  );
};
