import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ChangePasswordFormValues } from "../components/user-profile/user-profile-settings/authentication/change-password-form/change-password-form.types";
import {
  CompanyImage,
  CompanyProfile,
  CompanyProfileFileReferenceType,
  CompanyPublicProfile,
  ConnectionDetails,
  ConnectionInput,
  ConnectionRequests,
  DeleteCompanyDocumentsInput,
  DeleteCompanyFileReferencesInput,
  DiscoveryRequestInput,
  FetchCompanyProfileRequestInput,
  FetchSupplierInvitationsInput,
  GenericUser,
  InviteCompanyUserInput,
  ProfileState,
  ReorderCompanyFileReferencesInput,
  ResendMemberInviteInput,
  SendBuyerProfileMessageInput,
  Subscription,
  SubscriptionInput,
  Update2FAAuthInput,
  UpdateCompanyFileReferencesInput,
  UpdateMemberTeamPermissionInput,
  UpdateProfilePreferencesInput,
  UpdateUserLanguageInput,
  UploadCompanyDocumentsInput,
  User,
} from "./types";
import { RfqDetails } from "@next/modules/project/redux";

export const initialState: ProfileState = {
  subscriptionListLoading: false,
  subscriptionListError: undefined,
  subscriptionList: undefined,
  enableSubscriptionLoading: false,
  enableSubscriptionError: undefined,
  companyProfileLoading: false,
  companyProfileError: undefined,
  companyProfile: undefined,
  updateCompanyProfileLoading: false,
  updateCompanyProfileError: undefined,
  updateCompanyFileReferencesLoading: false,
  updateCompanyFileReferencesError: undefined,
  deleteCompanyFileReferencesLoading: false,
  deleteCompanyFileReferencesError: undefined,
  reorderCompanyFileReferencesLoading: false,
  reorderCompanyFileReferencesError: undefined,
  updateUserProfileLoading: false,
  updateUserProfileError: undefined,
  updateUserPasswordLoading: false,
  updateUserPasswordError: undefined,
  updateUserLanguageLoading: false,
  updateUserLanguageError: undefined,
  update2FAAuthLoading: false,
  update2FAAuthError: undefined,
  update2FAAuthResult: undefined,
  fetchUserActive2FAsLoading: false,
  fetchUserActive2FAsError: undefined,
  fetchUserActive2FAsResult: [],

  inviteCompanyUserLoading: false,
  inviteCompanyUserError: undefined,

  updateProfilePreferencesLoading: false,
  updateProfilePreferencesError: undefined,

  uploadCompanyFileDocumentsLoading: false,
  uploadCompanyFileDocumentsError: undefined,

  deleteCompanyFileDocumentsLoading: false,
  deleteCompanyFileDocumentsError: undefined,

  fetchPublicRequestLoading: false,
  fetchPublicRequestError: undefined,
  fetchPublicRequestResult: undefined,

  sendDiscoveryLoading: false,
  sendDiscoveryError: undefined,

  sendBuyerProfileMessageLoading: false,
  sendBuyerProfileMessageError: undefined,
  companyProfileExternal: undefined,

  fetchSupplierInvitationsLoading: false,
  fetchSupplierInvitationsError: undefined,
  fetchSupplierInvitationsResult: undefined,

  fetchInvitationDetailsLoading: false,
  fetchInvitationDetailsError: undefined,
  fetchInvitationDetailsResult: undefined,

  approveInvitationLoading: false,
  approveInvitationError: undefined,

  deleteInvitationLoading: false,
  deleteInvitationError: undefined,

  updateMemberTeamPermissionLoading: [],
  updateMemberTeamPermissionError: undefined,
};

const profileSlice = createSlice({
  name: "profileNext",
  initialState,
  reducers: {
    fetchSubscriptionListRequest: (state, _action: PayloadAction<Subscription[]>) => {
      state.subscriptionListLoading = true;
      state.subscriptionListError = undefined;
      state.subscriptionList = undefined;
    },
    fetchSubscriptionListFailure: (state, action: PayloadAction<Error>) => {
      state.subscriptionListLoading = false;
      state.subscriptionListError = action.payload;
    },
    fetchSubscriptionListSuccess: (state, action: PayloadAction<Subscription[]>) => {
      state.subscriptionListLoading = false;
      state.subscriptionListError = undefined;
      state.subscriptionList = action.payload;
    },
    enableSubscriptionRequest: (state, _action: PayloadAction<SubscriptionInput>) => {
      state.enableSubscriptionLoading = true;
      state.enableSubscriptionError = undefined;
    },
    enableSubscriptionFailure: (state, action: PayloadAction<Error>) => {
      state.enableSubscriptionLoading = false;
      state.enableSubscriptionError = action.payload;
    },
    enableSubscriptionSuccess: (state, action: PayloadAction<SubscriptionInput>) => {
      state.enableSubscriptionLoading = false;
      state.enableSubscriptionError = undefined;
      state.subscriptionList = state.subscriptionList?.map((subscription: Subscription) => {
        if (subscription.pk === action.payload.subPk) {
          return {
            ...subscription,
            is_user_subscribed: action.payload.isEnabled,
          };
        }
        return subscription;
      });
    },
    fetchCompanyProfileRequest: (state, action: PayloadAction<FetchCompanyProfileRequestInput>) => {
      state.companyProfileLoading = true;
      state.companyProfileError = undefined;
      if (action.payload.resetState) {
        state.companyProfile = action.payload.isPublic ? state.companyProfile : undefined;
        state.companyProfileExternal = action.payload.isPublic
          ? undefined
          : state.companyProfileExternal;
      }
    },
    fetchCompanyProfileFailure: (state, action: PayloadAction<Error>) => {
      state.companyProfileLoading = false;
      state.companyProfileError = action.payload;
    },
    fetchCompanyProfileSuccess: (
      state,
      action: PayloadAction<{
        isPublic?: boolean;
        data: CompanyProfile | CompanyPublicProfile;
      }>
    ) => {
      state.companyProfileLoading = false;
      state.companyProfileError = undefined;
      state.companyProfile = action.payload.isPublic
        ? state.companyProfile
        : (action.payload.data as CompanyProfile);
      state.companyProfileExternal = action.payload.isPublic
        ? (action.payload.data as CompanyPublicProfile)
        : state.companyProfileExternal;
    },
    updateCompanyProfileRequest: (state, _action: PayloadAction<Partial<CompanyProfile>>) => {
      state.updateCompanyProfileLoading = true;
      state.updateCompanyProfileError = undefined;
    },
    updateCompanyProfileFailure: (state, action: PayloadAction<Error>) => {
      state.updateCompanyProfileLoading = false;
      state.updateCompanyProfileError = action.payload;
    },
    updateCompanyProfileSuccess: (state, action: PayloadAction<CompanyProfile>) => {
      state.updateCompanyProfileLoading = false;
      state.updateCompanyProfileError = undefined;
      state.companyProfile = { ...state.companyProfile, ...action.payload };
    },
    updateCompanyFileReferencesRequest: (
      state,
      _action: PayloadAction<UpdateCompanyFileReferencesInput>
    ) => {
      state.updateCompanyFileReferencesLoading = true;
      state.updateCompanyFileReferencesError = undefined;
    },
    updateCompanyFileReferencesFailure: (state, action: PayloadAction<Error>) => {
      state.updateCompanyFileReferencesLoading = false;
      state.updateCompanyFileReferencesError = action.payload;
    },
    updateCompanyFileReferencesSuccess: (
      state,
      action: PayloadAction<{
        data: CompanyImage[];
        type: CompanyProfileFileReferenceType;
      }>
    ) => {
      state.updateCompanyFileReferencesLoading = false;
      state.updateCompanyFileReferencesError = undefined;

      if (
        action.payload.type === CompanyProfileFileReferenceType.SHOP &&
        state.companyProfile?.shop_pictures
      ) {
        state.companyProfile.shop_pictures = [
          ...state.companyProfile.shop_pictures,
          ...action.payload.data,
        ];
      } else if (
        action.payload.type === CompanyProfileFileReferenceType.PAST_PROJECT &&
        state.companyProfile?.past_projects_pictures
      ) {
        state.companyProfile.past_projects_pictures = [
          ...state.companyProfile.past_projects_pictures,
          ...action.payload.data,
        ];
      }
    },
    deleteCompanyFileReferencesRequest: (
      state,
      _action: PayloadAction<DeleteCompanyFileReferencesInput>
    ) => {
      state.deleteCompanyFileReferencesLoading = true;
      state.deleteCompanyFileReferencesError = undefined;
    },
    deleteCompanyFileReferencesFailure: (state, action: PayloadAction<Error>) => {
      state.deleteCompanyFileReferencesLoading = false;
      state.deleteCompanyFileReferencesError = action.payload;
    },
    deleteCompanyFileReferencesSuccess: (
      state,
      action: PayloadAction<{
        fileId: string;
        type: CompanyProfileFileReferenceType;
      }>
    ) => {
      state.deleteCompanyFileReferencesLoading = false;
      state.deleteCompanyFileReferencesError = undefined;

      if (action.payload.type === CompanyProfileFileReferenceType.SHOP) {
        state.companyProfile!.shop_pictures = state.companyProfile!.shop_pictures?.filter(
          (item) => item.id !== action.payload.fileId
        );
      } else if (action.payload.type === CompanyProfileFileReferenceType.PAST_PROJECT) {
        state.companyProfile!.past_projects_pictures =
          state.companyProfile!.past_projects_pictures?.filter(
            (item) => item.id !== action.payload.fileId
          );
      }
    },

    reorderCompanyFileReferencesRequest: (
      state,
      _action: PayloadAction<ReorderCompanyFileReferencesInput>
    ) => {
      state.reorderCompanyFileReferencesLoading = true;
      state.reorderCompanyFileReferencesError = undefined;
    },
    reorderCompanyFileReferencesFailure: (state, action: PayloadAction<Error>) => {
      state.reorderCompanyFileReferencesLoading = false;
      state.reorderCompanyFileReferencesError = action.payload;
    },
    reorderCompanyFileReferencesSuccess: (state) => {
      state.reorderCompanyFileReferencesLoading = false;
      state.reorderCompanyFileReferencesError = undefined;
    },

    updateUserProfileRequest: (state, _action: PayloadAction<Partial<User>>) => {
      state.updateUserProfileLoading = true;
      state.updateUserProfileError = undefined;
    },
    updateUserProfileFailure: (state, action: PayloadAction<Error>) => {
      state.updateUserProfileLoading = false;
      state.updateUserProfileError = action.payload;
    },
    updateUserProfileSuccess: (state, action: PayloadAction<CompanyProfile>) => {
      state.updateUserProfileLoading = false;
      state.updateUserProfileError = undefined;
      // state.companyProfile = { ...state.companyProfile, ...action.payload };
    },
    updateUserPasswordRequest: (
      state,
      _action: PayloadAction<Partial<ChangePasswordFormValues>>
    ) => {
      state.updateUserPasswordLoading = true;
      state.updateUserPasswordError = undefined;
    },
    updateUserPasswordFailure: (state, action: PayloadAction<Error>) => {
      state.updateUserPasswordLoading = false;
      state.updateUserPasswordError = action.payload;
    },
    updateUserPasswordSuccess: (state) => {
      state.updateUserPasswordLoading = false;
      state.updateUserPasswordError = undefined;
    },
    updateUserLanguageRequest: (state, _action: PayloadAction<UpdateUserLanguageInput>) => {
      state.updateUserLanguageLoading = true;
      state.updateUserLanguageError = undefined;
    },
    updateUserLanguageFailure: (state, action: PayloadAction<Error>) => {
      state.updateUserLanguageLoading = false;
      state.updateUserLanguageError = action.payload;
    },
    updateUserLanguageSuccess: (state) => {
      state.updateUserLanguageLoading = false;
      state.updateUserLanguageError = undefined;
    },
    update2FAAuthRequest: (state, _action: PayloadAction<Update2FAAuthInput>) => {
      state.update2FAAuthLoading = true;
      state.update2FAAuthError = undefined;
    },
    update2FAAuthFailure: (state, action: PayloadAction<Error>) => {
      state.update2FAAuthLoading = false;
      state.update2FAAuthError = action.payload;
    },
    update2FAAuthSuccess: (state, action: PayloadAction<any>) => {
      state.update2FAAuthLoading = false;
      state.update2FAAuthError = undefined;
      state.update2FAAuthResult = action.payload;
    },
    fetchUserActive2FAsRequest: (state) => {
      state.fetchUserActive2FAsLoading = true;
      state.fetchUserActive2FAsError = undefined;
    },
    fetchUserActive2FAsFailure: (state, action: PayloadAction<Error>) => {
      state.fetchUserActive2FAsLoading = false;
      state.fetchUserActive2FAsError = action.payload;
    },
    fetchUserActive2FAsSuccess: (state, action: PayloadAction<any>) => {
      state.fetchUserActive2FAsLoading = false;
      state.fetchUserActive2FAsError = undefined;
      state.fetchUserActive2FAsResult = action.payload;
    },
    inviteCompanyUserRequest: (state, _action: PayloadAction<InviteCompanyUserInput>) => {
      state.inviteCompanyUserLoading = true;
      state.inviteCompanyUserError = undefined;
    },
    inviteCompanyUserFailure: (state, action: PayloadAction<Error>) => {
      state.inviteCompanyUserLoading = false;
      state.inviteCompanyUserError = action.payload;
    },
    inviteCompanyUserSuccess: (state, action: PayloadAction<GenericUser[]>) => {
      state.inviteCompanyUserLoading = false;
      state.inviteCompanyUserError = undefined;
      state.companyProfile = {
        ...state.companyProfile!,
        users: [...action.payload, ...(state.companyProfile?.users || [])],
      };
    },
    updateProfilePreferencesRequest: (
      state,
      _action: PayloadAction<UpdateProfilePreferencesInput>
    ) => {
      state.updateProfilePreferencesLoading = true;
      state.updateProfilePreferencesError = undefined;
    },
    updateProfilePreferencesFailure: (state, action: PayloadAction<Error>) => {
      state.updateProfilePreferencesLoading = false;
      state.updateProfilePreferencesError = action.payload;
    },
    updateProfilePreferencesSuccess: (state) => {
      state.updateProfilePreferencesLoading = false;
      state.updateProfilePreferencesError = undefined;
    },
    uploadCompanyDocumentsRequest: (state, _action: PayloadAction<UploadCompanyDocumentsInput>) => {
      state.uploadCompanyFileDocumentsLoading = true;
      state.uploadCompanyFileDocumentsError = undefined;
    },
    uploadCompanyDocumentsFailure: (state, action: PayloadAction<Error>) => {
      state.uploadCompanyFileDocumentsLoading = false;
      state.uploadCompanyFileDocumentsError = action.payload;
    },
    uploadCompanyDocumentsSuccess: (state, action: PayloadAction<any>) => {
      state.uploadCompanyFileDocumentsLoading = false;
      state.uploadCompanyFileDocumentsError = undefined;
      state.companyProfile!.documents = state.companyProfile!.documents!.concat(action.payload);
    },
    deleteCompanyDocumentsRequest: (state, _action: PayloadAction<DeleteCompanyDocumentsInput>) => {
      state.deleteCompanyFileDocumentsLoading = true;
      state.deleteCompanyFileDocumentsError = undefined;
    },
    deleteCompanyDocumentsFailure: (state, action: PayloadAction<Error>) => {
      state.deleteCompanyFileDocumentsLoading = false;
      state.deleteCompanyFileDocumentsError = action.payload;
    },
    deleteCompanyDocumentsSuccess: (state, action: PayloadAction<number>) => {
      state.deleteCompanyFileDocumentsLoading = false;
      state.deleteCompanyFileDocumentsError = undefined;
      state.companyProfile!.documents = state.companyProfile!.documents?.filter(
        (document) => document?.pk !== action.payload
      );
    },
    fetchPublicRequestRequest: (state, _action: PayloadAction<number>) => {
      state.fetchPublicRequestLoading = true;
      state.fetchPublicRequestError = undefined;
    },
    fetchPublicRequestFailure: (state, action: PayloadAction<Error>) => {
      state.fetchPublicRequestLoading = false;
      state.fetchPublicRequestError = action.payload;
    },
    fetchPublicRequestSuccess: (state, action: PayloadAction<RfqDetails>) => {
      state.publicRequest = action.payload;
      state.fetchPublicRequestLoading = false;
      state.fetchPublicRequestError = undefined;
    },
    sendDiscoveryRequest: (state, _action: PayloadAction<DiscoveryRequestInput>) => {
      state.sendDiscoveryLoading = true;
      state.sendDiscoveryError = undefined;
    },
    sendDiscoveryFailure: (state, action: PayloadAction<Error>) => {
      state.sendDiscoveryLoading = false;
      state.sendDiscoveryError = action.payload;
    },
    sendDiscoverySuccess: (state, _action) => {
      state.sendDiscoveryLoading = false;
      state.sendDiscoveryError = undefined;
    },
    sendBuyerProfileMessageRequest: (
      state,
      _action: PayloadAction<SendBuyerProfileMessageInput>
    ) => {
      state.sendBuyerProfileMessageLoading = true;
      state.sendBuyerProfileMessageError = undefined;
    },
    sendBuyerProfileMessageFailure: (state, action: PayloadAction<Error>) => {
      state.sendBuyerProfileMessageLoading = false;
      state.sendBuyerProfileMessageError = action.payload;
    },
    sendBuyerProfileMessageSuccess: (state) => {
      state.sendBuyerProfileMessageLoading = false;
      state.sendBuyerProfileMessageError = undefined;
    },

    fetchSupplierInvitationsRequest: (
      state,
      _action: PayloadAction<FetchSupplierInvitationsInput>
    ) => {
      state.fetchSupplierInvitationsLoading = true;
      state.fetchSupplierInvitationsError = undefined;
    },
    fetchSupplierInvitationsFailure: (state, action: PayloadAction<Error>) => {
      state.fetchSupplierInvitationsLoading = false;
      state.fetchSupplierInvitationsError = action.payload;
    },
    fetchSupplierInvitationsSuccess: (state, action: PayloadAction<ConnectionRequests>) => {
      state.fetchSupplierInvitationsLoading = false;
      state.fetchSupplierInvitationsError = undefined;
      state.fetchSupplierInvitationsResult = action.payload;
    },

    fetchInvitationDetailsRequest: (state, _action: PayloadAction<ConnectionInput>) => {
      state.fetchInvitationDetailsLoading = true;
      state.fetchInvitationDetailsError = undefined;
    },
    fetchInvitationDetailsFailure: (state, action: PayloadAction<Error>) => {
      state.fetchInvitationDetailsLoading = false;
      state.fetchInvitationDetailsError = action.payload;
    },
    fetchInvitationDetailsSuccess: (state, action: PayloadAction<ConnectionDetails>) => {
      state.fetchInvitationDetailsLoading = false;
      state.fetchInvitationDetailsError = undefined;
      state.fetchInvitationDetailsResult = action.payload;
    },

    approveInvitationRequest: (
      state,
      _action: PayloadAction<ConnectionInput & { onSuccess?: () => void }>
    ) => {
      state.approveInvitationLoading = true;
      state.approveInvitationError = undefined;
    },
    approveInvitationFailure: (state, action: PayloadAction<Error>) => {
      state.approveInvitationLoading = false;
      state.approveInvitationError = action.payload;
    },
    approveInvitationSuccess: (state, action: PayloadAction<ConnectionInput>) => {
      state.approveInvitationLoading = false;
      state.approveInvitationError = undefined;
      state.fetchSupplierInvitationsResult = state.fetchSupplierInvitationsResult
        ? {
            count: state.fetchSupplierInvitationsResult.count - 1,
            results: state.fetchSupplierInvitationsResult.results.filter(
              (connection) => connection.id !== action.payload.connectionId
            ),
          }
        : initialState.fetchSupplierInvitationsResult;
    },

    deleteInvitationRequest: (state, _action: PayloadAction<ConnectionInput>) => {
      state.deleteInvitationLoading = true;
      state.deleteInvitationError = undefined;
    },
    deleteInvitationFailure: (state, action: PayloadAction<Error>) => {
      state.deleteInvitationLoading = false;
      state.deleteInvitationError = action.payload;
    },
    deleteInvitationSuccess: (state, action: PayloadAction<ConnectionInput>) => {
      state.deleteInvitationLoading = false;
      state.deleteInvitationError = undefined;
      state.fetchSupplierInvitationsResult = state.fetchSupplierInvitationsResult
        ? {
            count: state.fetchSupplierInvitationsResult.count - 1,
            results: state.fetchSupplierInvitationsResult.results.filter(
              (connection) => connection.id !== action.payload.connectionId
            ),
          }
        : initialState.fetchSupplierInvitationsResult;
    },

    updateMemberTeamPermissionRequest: (
      state,
      action: PayloadAction<UpdateMemberTeamPermissionInput>
    ) => {
      state.updateMemberTeamPermissionLoading = [
        ...(state.updateMemberTeamPermissionLoading || []),
        action.payload.userId,
      ];
      state.updateMemberTeamPermissionError = undefined;
    },
    updateMemberTeamPermissionFailure: (state, action: PayloadAction<Error>) => {
      state.updateMemberTeamPermissionLoading = [];
      state.updateMemberTeamPermissionError = action.payload;
    },
    updateMemberTeamPermissionSuccess: (
      state,
      action: PayloadAction<UpdateMemberTeamPermissionInput>
    ) => {
      state.updateMemberTeamPermissionLoading = state.updateMemberTeamPermissionLoading?.filter(
        (userId) => userId !== action.payload.userId
      );
      state.updateMemberTeamPermissionError = undefined;
    },

    resendMemberInviteRequest: (state, _action: PayloadAction<ResendMemberInviteInput>) => {
      state.inviteCompanyUserLoading = true;
      state.inviteCompanyUserError = undefined;
    },
    resendMemberInviteFailure: (state, action: PayloadAction<Error>) => {
      state.inviteCompanyUserLoading = false;
      state.inviteCompanyUserError = action.payload;
    },
    resendMemberInviteSuccess: (state) => {
      state.inviteCompanyUserLoading = false;
      state.inviteCompanyUserError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state) => {
      state.updateCompanyProfileError = undefined;
    });
  },
});

export const profileNextActions = profileSlice.actions;

export default profileSlice.reducer;
