import React from "react";
import { frontendUrl } from "urls";
import { CustomRoute } from "./types";
import { AirbusProcurementConfirmation } from "@next/modules/workspace/components/custom-routes-for-companies/airbus-procurement-confirmation";
import { PublicLayout } from "layout/PublicLayout";

const CUSTOM_ROUTES_FOR_COMPANIES: CustomRoute[] = [
  {
    layout: (props) => <PublicLayout {...props} showAppBar={false} />,
    path: `${frontendUrl.airbusProcurement}/confirmation/:token/`,
    key: "airbusProcurementConfirmation",
    exact: true,
    component: (props: any) => <AirbusProcurementConfirmation {...props} />,
  },
];

export default CUSTOM_ROUTES_FOR_COMPANIES;
