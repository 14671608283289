import React, { useCallback } from "react";
import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro-v5";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  selectIsFetchSpecByClickedThread,
  selectIsFormDisplayForValidationManagement,
  selectIsFormStepCompleted,
  selectIsLoadingSpecsOfLeadTimeForProduction,
  selectSpecsOfLeadTimeForProduction,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  PartialSpecOfLeadTimeForProduction,
  PartialStep,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { getLeadTimeForProductionFormTableColumns } from "./lead-time-for-production-form-table.columns";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./lead-time-for-production-form-table.styled";

type Props = {
  hideCompletedTableLines: boolean;
  page: number;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  isTableDisabled: boolean;
};

export const LeadTimeForProductionFormTable: React.FC<Props> = ({
  hideCompletedTableLines,
  page,
  pageSize,
  setPage,
  isTableDisabled,
}) => {
  const dispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();
  const apiRef = useGridApiRef();
  const isFormCompleted = useSelector(selectIsFormStepCompleted);
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const activeFormStep = steps[activeFormStepIndex];
  const isFetchSpecByClickedThread = useSelector(selectIsFetchSpecByClickedThread);
  const columns = getLeadTimeForProductionFormTableColumns(
    isFormDisplayForValidationManagement,
    isTableDisabled
  );
  const tableData = useSelector(selectSpecsOfLeadTimeForProduction);
  const rows = tableData?.results?.data;
  const loading = useSelector(selectIsLoadingSpecsOfLeadTimeForProduction);
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const handleCellClick = useCallback(
    (params: GridCellParams, event: React.MouseEvent<HTMLElement>) => {
      if (!params.colDef.editable) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    [isFormCompleted, rows]
  );

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    const specificationId = newRow?.specification_id;
    let updatedField = null;
    Object.keys(newRow).forEach((key) => {
      if (newRow[key] !== oldRow[key]) {
        updatedField = { [key]: newRow[key] };
      }
    });

    if (updatedField) {
      const spec: PartialSpecOfLeadTimeForProduction = updatedField;
      dispatch(
        vendorManagementActions.updateSpecOfLeadTimeForProductionRequest({
          companyId: companyId,
          specificationId: specificationId,
          data: spec,
          onSuccess: onUpdateSuccess,
        })
      );
    }

    return updatedRow;
  };

  const onUpdateSuccess = () => {
    reFetchTableDataToGetUpdatedCount();
    checkInputChange();
  };

  const reFetchTableDataToGetUpdatedCount = () => {
    dispatch(
      vendorManagementActions.fetchSpecsOfLeadTimeForProductionRequest({
        companyId: companyId,
        page: page,
        pageSize: pageSize,
        filter_completed: hideCompletedTableLines,
        isForFetchingToGetCountData: true,
      })
    );
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStep("is_completed", false);
    } else {
      updateFieldStatusOfStep("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStep = (key: "is_completed" | "is_partially_filled", value: boolean) => {
    const step = steps[activeFormStepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
        onSuccess:
          key === "is_completed" && !value
            ? () => updateApprovalOfStepAfterCompletionChange("changes_requested")
            : undefined,
      })
    );
  };

  const updateApprovalOfStepAfterCompletionChange = (statusParam: "changes_requested") => {
    if (activeFormStep?.approval_status === "changes_done" && statusParam === "changes_requested") {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: activeFormStep.step,
          status: statusParam,
        })
      );
    }
  };

  return (
    <S.StyledDataGridPro
      apiRef={apiRef}
      autoHeight
      autoPageSize
      headerHeight={48}
      rowHeight={!isFormDisplayForValidationManagement ? 32 : 40}
      rows={rows || []}
      columns={columns}
      pagination
      paginationMode="server"
      pageSize={pageSize}
      page={!isFetchSpecByClickedThread ? page - 1 : 1}
      rowCount={tableData?.count ? tableData.count : 0}
      rowsPerPageOptions={[pageSize]}
      onPageChange={(newPage) => setPage(newPage + 1)}
      disableSelectionOnClick
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={handleCellClick}
      experimentalFeatures={{ newEditingApi: true }}
      processRowUpdate={processRowUpdate}
      getRowId={(row) => row.specification_id}
      loading={loading}
      getRowClassName={(params) => {
        const isDisabledStatus = params?.row?.approval_status === "approved";
        return isDisabledStatus ? "disabled-row" : "";
      }}
    />
  );
};
