import { modalsConstants } from "./modals.constants";

const showModal = (isModalOpen = false, modalID = "") => {
  return (dispatch) => {
    dispatch({
      type: modalsConstants.SHOW_MODAL,
      isModalOpen,
      modalID,
    });
  };
};

const hideModal = (modalID) => {
  return (dispatch) => {
    dispatch({
      type: modalsConstants.HIDE_MODAL,
      modalID,
    });
  };
};

export const modalsActions = {
  showModal,
  hideModal,
};
