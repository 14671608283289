import { Formik } from "formik";
import React, { useContext } from "react";
import QuoteComparisonCustomizeViewFormBase from "./quote-comparison-customize-view-form.base";
import { quoteComparisonContext } from "@next/modules/workspace/components/quote-comparison/quote-comparison.context";
import { useDispatch } from "react-redux";
import { workspaceNextActions } from "@next/modules/workspace/redux";

export const QuoteComparisonCustomizeViewForm = () => {
  const dispatch = useDispatch();
  const {
    gridData: { config },
  } = useContext(quoteComparisonContext);

  return (
    <Formik
      initialValues={config?.selected || {}}
      onSubmit={(values) => {
        dispatch(
          workspaceNextActions.updateQuoteComparisonConfigRequest({
            config_id: config?.id,
            updated_data: values,
          })
        );
      }}
    >
      {(props) => <QuoteComparisonCustomizeViewFormBase {...props} />}
    </Formik>
  );
};
