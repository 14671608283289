import { quotationConstants } from "./index";

const initialState = {
  isLoading: false,
  isQuotationDetailsLoading: false,
  quotationDetails: {},
  isEditQuotationMode: false,
  isEditQuotationLoading: false,
  leadTimeOptions: [],
};

export function quotation(state = initialState, action) {
  switch (action.type) {
    /**FETCH_ACTIVE_RFQ_DETAILS_REQUEST */
    case quotationConstants.FETCH_QUOTATION_DETAILS_REQUEST:
      return { ...state, isQuotationDetailsLoading: true };

    case quotationConstants.FETCH_QUOTATION_DETAILS_SUCCESS:
      return {
        ...state,
        isQuotationDetailsLoading: false,
        quotationDetails: action.quotationDetails,
      };

    case quotationConstants.FETCH_QUOTATION_DETAILS_FAILURE:
      return { ...state, isQuotationDetailsLoading: false };

    // Open buyer quotation tabs
    case quotationConstants.SET_IS_EDIT_QUOTATION_MODE:
      return {
        ...state,
        isEditQuotationMode: action.isEditQuotationMode,
      };

    /** EDIT_QUOTATION_REQUEST*/
    case quotationConstants.EDIT_QUOTATION_REQUEST:
      return { ...state, isEditQuotationLoading: true };

    case quotationConstants.EDIT_QUOTATION_SUCCESS:
      const updatedQuotation = { ...state.quotationDetails, ...action.quote };

      return {
        ...state,
        isEditQuotationLoading: false,
        quotationDetails: updatedQuotation,
      };

    case quotationConstants.EDIT_QUOTATION_FAILURE:
      return { ...state, isEditQuotationLoading: false };

    /** SET_LEAD_TIME_OPTIONS_REQUEST*/

    case quotationConstants.SET_LEAD_TIME_OPTIONS_SUCCESS:
      const newLeadTimeOptions = [...action.leadTimeOptions];
      return {
        ...state,
        leadTimeOptions: newLeadTimeOptions,
      };

    default:
      return state;
  }
}
