import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Theme,
  TypographyProps,
  alpha,
} from "@mui/material";
import React, { ReactNode } from "react";
import { SellerDashboardCardPlaceholder } from "./seller-dashboard-card-placeholder";
import { CustomTypography } from "@next/components/custom-typography";

import { createStyles, makeStyles } from "@mui/styles";

type SellerDashboardCardVariant = "success" | "primary" | "default";

type StyleProps = {
  variant?: SellerDashboardCardVariant;
  height?: string;
  disableFocusHighlight?: boolean;
  minHeight?: string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: "none",
      border: "1px solid",
      borderColor: ({ variant }) =>
        variant === "success"
          ? theme.palette.success.main
          : variant === "primary"
            ? theme.palette.primary.main
            : theme.palette.divider,
      backgroundColor: ({ variant }) =>
        variant === "success" ? alpha(theme.palette.success.main, 0.1) : "#fff",
      height: ({ height }) => (height ? height : "default"),
      width: "100%",
      borderRadius: "16px",
      whiteSpace: "nowrap",
      minHeight: ({ minHeight }) => (minHeight ? minHeight : "default"),
    },
    actionArea: { height: "100%" },
    cardContent: { height: "100%", padding: "24px" },
    focusHighlight: {
      opacity: ({ disableFocusHighlight }) => (disableFocusHighlight ? "0 !important" : "default"),
    },
  })
);

type Props = {
  variant?: SellerDashboardCardVariant;
  title: string | React.ReactNode;
  action?: ReactNode;
  titleVariant?: TypographyProps["variant"];
  content?: string | React.ReactNode;
  onClick?: () => void;
  height?: string;
  disableFocusHighlight?: boolean;
  showCardPlaceholder?: boolean;
  cardPlaceholderProps?: React.ComponentProps<typeof SellerDashboardCardPlaceholder>;
  minHeight?: string;
};

export const SellerDashboardCard: React.FC<Props> = ({
  variant = "default",
  title,
  action,
  titleVariant = "body1",
  content,
  onClick,
  height = "112px",
  disableFocusHighlight,
  showCardPlaceholder,
  cardPlaceholderProps,
  minHeight,
}) => {
  const classes = useStyles({
    variant,
    height,
    disableFocusHighlight,
    minHeight,
  });

  return (
    <Card variant="outlined" className={classes.root}>
      <CardActionArea
        classes={{ focusHighlight: classes.focusHighlight }}
        disableRipple={!onClick}
        disableTouchRipple={!onClick}
        onClick={onClick}
        className={classes.actionArea}
        style={{ cursor: onClick ? "pointer" : "unset" }}
      >
        <CardContent className={classes.cardContent}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <CustomTypography tooltipTitle={null} variant={titleVariant}>
              {title}
            </CustomTypography>
            {action && action}
          </Box>

          {showCardPlaceholder ? (
            <SellerDashboardCardPlaceholder {...cardPlaceholderProps} />
          ) : (
            <Box display="flex" alignItems="center" mt="12px" width="100%">
              {content}
            </Box>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
