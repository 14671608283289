import {
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";

export const StyledRadioGroup = styled(RadioGroup)({
  width: "59px",
  marginTop: "-16px",
});

export const StyledFormHelperText = styled(FormHelperText)({
  marginTop: "-14px",
});

export const StyledMenuItem = styled(MenuItem)({
  "& .Mui-selected": {
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "transparent",
  },
  "& .Mui-disabled": {
    color: "rgba(0, 0, 0, 0.87)",
    opacity: 1,
  },
});

export const StyledRadio = styled(Radio)(({ theme }) => ({
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.38)",
  },
  "&.Mui-checked.Mui-disabled": {
    color: theme.palette.primary.main,
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.87)",
  },
});
