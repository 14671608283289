import React from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, FormikProps } from "formik";
import { Button, Grid, Typography } from "@mui/material";
import { GenericTextInput } from "components/utils/input/text";

import { SelectSuppliers } from "@next/components/select-suppliers/select-suppliers";

import { ContactSuppliersFormValues, OthersProps } from "./contact-suppliers.form.types";

const ContactSuppliersFormBase: React.FC<
  FormikProps<ContactSuppliersFormValues> & OthersProps & { disabled?: boolean }
> = ({ values, handleSubmit, suppliers, disabled }) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            {t("workspaceNext:contactSuppliersModal:subtitle")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="suppliers"
            hideEntireNetworkOption={true}
            component={SelectSuppliers}
            options={suppliers}
            placeholder={t("recipients:chooseSuppliersPlaceholder")}
            disabled={disabled}
            showStatus={true}
          />
        </Grid>
        <Grid item xs={12}>
          <GenericTextInput
            multiline={true}
            name="body"
            label={t("workspaceNext:contactSuppliersModal:description")}
            value={values.body}
            rows="4"
          />
        </Grid>

        <Grid item container justifyContent="flex-end">
          <Button variant="contained" color="primary" type="submit">
            {t("common:send")}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ContactSuppliersFormBase;
