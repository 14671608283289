import React, { useCallback, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Theme,
} from "@mui/material";
import { batch, useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "./types";
import CloseIcon from "@mui/icons-material/Close";
import { CustomTypography } from "@next/components/custom-typography";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import { RichTextEditor } from "@next/components/rich-text-editor";
import { AttachFileList } from "@next/components/attach-file/attach-file-list";
import { profileNextActions } from "@next/modules/profile/redux";
import {
  selectApproveInvitationLoading,
  selectInvitationDetails,
  selectInvitationDetailsLoading,
} from "@next/modules/profile/redux/selectors";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import CustomButton from "@next/components/custom-button/custom-button";
import { openProfileInNewTab } from "@next/utils/browserUtils";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    header: {
      position: "sticky",
      top: 0,
      backgroundColor: theme.palette.common.white,
      zIndex: 10,
      display: "flex",
      justifyContent: "space-between",
    },
    container: {
      display: "flex",
      alignItems: "center",
      gap: 12,
    },
    loader: {
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      display: "grid",
      placeItems: "center",
    },
    messageContainer: {
      padding: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    rteWrapper: {
      fontSize: theme.typography.body2.fontSize,
    },
    profileLink: {
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  })
);

export const ConnectionRequestsModal: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const modal = useSelector(getModal(WorkspaceModalTypes.CONNECTION_REQUESTS_MODAL));
  const data = useSelector(selectInvitationDetails);
  const detailsFetchLoading = useSelector(selectInvitationDetailsLoading);
  const approveConnectionLoading = useSelector(selectApproveInvitationLoading);
  const connectionId = modal?.data?.connectionId;

  useEffect(() => {
    if (!connectionId) return;
    dispatch(
      profileNextActions.fetchInvitationDetailsRequest({
        connectionId,
      })
    );
  }, [connectionId]);

  const onClickName = useCallback(() => {
    openProfileInNewTab(data?.from_company.name, data?.from_company.uuid as string);
  }, [data?.from_company]);

  const onClose = () => {
    batch(() => {
      dispatch(modalsActions.closeModal(WorkspaceModalTypes.CONNECTION_REQUESTS_MODAL));
    });
  };

  const handleOnIgnore = () => {
    confirmDialog(
      t("workspaceNext:ignoreInvitationModal:title"),
      t("workspaceNext:ignoreInvitationModal:content"),
      () => {
        dispatch(
          profileNextActions.deleteInvitationRequest({
            connectionId,
            buyerProfileId: data?.to_company.id,
          })
        );
      },
      t("workspaceNext:ignoreInvitationModal:ignoreText")
    );
  };

  const handleOnAccept = () => {
    dispatch(
      profileNextActions.approveInvitationRequest({
        connectionId,
        onSuccess: () => {
          dispatch(
            modalsActions.showModal({
              key: WorkspaceModalTypes.GROUPING_AFTER_CONNECTION_MODAL,
              data,
            })
          );
        },
      })
    );
  };

  return (
    <Drawer PaperProps={{ style: { width: 620 } }} anchor="right" open={!!modal} onClose={onClose}>
      {detailsFetchLoading ? (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      ) : (
        data && (
          <div>
            <Box className={classes.header} display="flex" alignItems="center" px={2} py={1.5}>
              <Box className={classes.container}>
                <Avatar src={data.from_company.picture} />
                <CustomTypography
                  onClick={data?.from_company.uuid ? onClickName : undefined}
                  className={classes.profileLink}
                  variant="body1"
                  color="primary"
                >
                  {data.from_company.name}
                </CustomTypography>
              </Box>
              <Box display={"flex"} gap={"12px"} alignItems={"center"}>
                <Button onClick={handleOnIgnore} variant="outlined">
                  {t("workspaceNext:connectionRequestsModal:ignore")}
                </Button>
                <CustomButton
                  variant="contained"
                  color="primary"
                  onClick={handleOnAccept}
                  startIcon={<CheckIcon />}
                  loading={approveConnectionLoading}
                >
                  {t("workspaceNext:connectionRequestsModal:accept")}
                </CustomButton>
                <IconButton className={classes.closeButton} onClick={onClose} size="large">
                  <CloseIcon color="secondary" />
                </IconButton>
              </Box>
            </Box>
            <Divider light />
            <Box className={classes.messageContainer}>
              <Box className={classes.container}>
                <Avatar src={data.created_by.picture} />
                <Box display="flex" gap={"4px"}>
                  <CustomTypography variant="subtitle2" style={{ fontWeight: "bold" }}>
                    {data.created_by.full_name}
                  </CustomTypography>
                  <CustomTypography variant="body2">({data.created_by.email})</CustomTypography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" gap={"12px"}>
                <Box className={classes.rteWrapper}>
                  <RichTextEditor value={data.additional_details_rich_text} readOnly />
                </Box>
                {data.files && <AttachFileList files={data.files} />}
              </Box>
            </Box>
          </div>
        )
      )}
    </Drawer>
  );
};
