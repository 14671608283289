import { Quotation } from "../components/multi-contract/create-multi-contract-table.types";
import { Rfq } from "../redux";

export enum ProjectModalTypes {
  FILE_DELETE_CONFIRMATION_MODAL = "project-file-delete-confirmation-modal",
  RFQ_DETAILS_MODAL = "project-rfq-details-modal",
  CANCEL_RFQ_MODAL = "project-cancel-rfq-modal",
  EXTEND_RFQ_MODAL = "project-extend-rfq-modal",
  CREATE_MULTI_CONTRACT_MODAL = "project-create-multi-contract-modal",
  CREATE_RENAME_MODAL = "project-create-rename-modal",
  BULK_PART_IMPORT_LOADER_MODAL = "project-bulk-part-import-loader-modal",
  SHORTLIST_SUPPLIER_MODAL = "project-shortlist-supplier-model",
  PROJECT_DELETE_MODAL = "project-delete-modal",
}

export type CreateMultiContractModalData = {
  rfq: Rfq & {
    quotations: Quotation[];
  };
  onSuccess?: () => void;
  onClose?: () => void;
};

export type ShortlistSuppliersModalData = {
  rfq: Rfq & {
    quotations: Quotation[];
  };
  winnerIDs: Number[];
  onSuccess?: (winnerIDs: Number[], winnersNote: string, losersNote: string) => void;
  onClose?: () => void;
};
