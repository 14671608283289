import { call } from "redux-saga/effects";
import { isArray } from "lodash";
import { t } from "assets/configi18n/i18n";
import { genericErrorHandler } from "@next/utils/apiUtils";

/**
 * Instead of writing a try catch block in each saga, a service error handling wrapper is used to catch a possible error here.
 * The call() method we use in safeCall() should return a yield using the fn and …args parameters that we have given to safeCall() so in the generator where safeCall() is called,
 * we will be able to read the data returned from the services.
 * @param fn
 * @param args
 * @returns response, error
 */
export function* safeCall(fn: any, ...args: any[]): Generator {
  try {
    return { response: yield call(fn, ...args) };
  } catch (error) {
    console.error("SAFE CALL ERROR", error);

    if ((error as any)?.response?.data?.error?.code) {
      // New error handling
      genericErrorHandler(error);
    }

    const response = (error as any)?.response;

    // TODO: improve error handling with backend
    let errorString = t("common:unexpectedError");

    if (response?.data && isArray(response?.data)) {
      errorString = response?.data[0];
    } else if (response) {
      errorString =
        response?.data?.detail || JSON.stringify(response?.data) || t("common:unexpectedError");
    } else if ((error as any)?.message === "Network Error") {
      errorString = t("common:timeoutError");
    }

    return {
      error: errorString,
    };
  }
}
