import React from "react";
import { Button, CircularProgress, Theme, capitalize } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export type ColorTypes =
  | "primary"
  | "secondary"
  | "error"
  | "success"
  | "warning"
  | "default"
  | "inherit"
  | "info";

type ButtonProps = {
  color?: ColorTypes;
  loading?: boolean;
  component?: string;
  loadingIndicatorPosition?: "start" | "end";
  children: React.ReactNode;
} & Omit<React.ComponentProps<typeof Button>, "color">;

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      whiteSpace: "nowrap",
    },
    outlinedSuccess: {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
    },
    outlinedError: {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
    outlinedWarning: {
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
    },
    outlinedInfo: {
      borderColor: theme.palette.info.main,
      color: theme.palette.info.main,
    },
    containedSuccess: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.success.dark,
      },
    },
    containedError: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },
    containedWarning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.warning.dark,
      },
    },
    containedInfo: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.info.dark,
      },
    },
  })
);

const CustomButton: React.FC<ButtonProps> = (props) => {
  const { loading, children, color = "default", loadingIndicatorPosition = "end", ...rest } = props;
  const classes = useStyles();
  const className = classes?.[`${props.variant}${capitalize(color)}`];
  const colorProp =
    ["inherit", "primary", "secondary", "error", "success", "warning", "info"].indexOf(color) > -1
      ? (color as "inherit" | "primary" | "secondary" | "error" | "success" | "warning" | "info")
      : undefined;

  return (
    <Button
      {...rest}
      disabled={loading || rest.disabled}
      endIcon={
        loading && loadingIndicatorPosition === "end" ? (
          <CircularProgress color="inherit" size={16} />
        ) : (
          rest.endIcon
        )
      }
      startIcon={
        loading && loadingIndicatorPosition === "start" ? (
          <CircularProgress color="inherit" size={16} />
        ) : (
          rest.startIcon
        )
      }
      color={colorProp}
      className={`${rest.className} ${className} ${classes.root}`}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
