import { Divider, Theme } from "@mui/material";
import LanguageToggle from "@next/components/language-toggle";
import i18n from "assets/configi18n/i18n";
import React from "react";
import amqLogoFr from "./amq-logo-fr.png";
import amqLogoEn from "./amq-logo-en.png";
import logoWhite from "./logo-white-base.svg";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(2.5),
      paddingTop: theme.spacing(2.5),
    },
    left: { display: "flex" },
    right: {
      paddingRight: theme.spacing(2),
    },
    logo: { width: "190px", height: "48px" },
    axyaLogo: { width: "87px", height: "48px" },
    headerDivider: {
      background: "white",
      height: theme.spacing(3.6),
      marginTop: theme.spacing(1.25),
      marginRight: theme.spacing(2.5),
      marginLeft: theme.spacing(2.5),
    },
  })
);

const PublicRfqCreationHeader = () => {
  const classes = useStyles();
  const language = i18n.language;

  return (
    <header className={classes.header}>
      <div className={classes.left}>
        <img
          src={language === "fr" ? amqLogoFr : amqLogoEn}
          alt="amqLogo"
          className={classes.logo}
        />

        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className={classes.headerDivider}
        />

        <img src={logoWhite} alt="logoWhite" className={classes.axyaLogo} />
      </div>

      <div className={classes.right}>
        <LanguageToggle disablePadding />
      </div>
    </header>
  );
};

export default PublicRfqCreationHeader;
