/**
 * Use this component to summarize all the information's of quotations and request for quotation
 */

import React from "react";
import { useSelector } from "react-redux";

import { Box, Grid } from "@mui/material";

import { LeadTime } from "@next/components/lead-time/lead-time";
import { getCompany } from "services/profile/profile.selectors";
import CurrencyField from "components/common/fields/CurrencyField";
import { RichTextEditor } from "@next/components/rich-text-editor";
import { checkIsShowCommissionFeeCompany } from "@next/utils/checkCompanyUtils";

export const DetailsSummaryFooter = (props) => {
  const {
    additionalDetails,
    additionalDetailsRichText,
    totalPrice,
    leadTime,
    t,
    commissionFee,
    isQuotationExtraFieldsAvailable,
  } = props;
  const company = useSelector(getCompany);
  const isShowCommissionFeeCompany = checkIsShowCommissionFeeCompany(company?.id);

  return (
    <Box className="c-details-summary-footer">
      <Box
        textAlign="left"
        fontWeight="fontWeightRegular"
        fontSize="h5.fontSize"
        className="c-details-summary-footer--title"
      >
        {t("summary")}
      </Box>

      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={!isQuotationExtraFieldsAvailable ? 5 : 12}>
          <Box
            textAlign="left"
            fontWeight="fontWeightRegular"
            fontSize={16}
            className="c-details-summary-footer--additional-details"
          >
            <RichTextEditor
              readOnly
              defaultValue={additionalDetailsRichText || additionalDetails}
            />
          </Box>
        </Grid>
        {!isQuotationExtraFieldsAvailable ? (
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    <Box textAlign="left" fontWeight="fontWeightRegular" fontSize={16}>
                      {t("inbox:productionDelay")}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box textAlign="right" fontWeight="fontWeightMedium" fontSize="h6.fontSize">
                      <LeadTime value={leadTime} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item xs={6}>
                    <Box textAlign="left" fontWeight="fontWeightRegular" fontSize={16}>
                      {t("price")}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box textAlign="right" fontWeight="fontWeightMedium" fontSize="h6.fontSize">
                      <CurrencyField
                        value={
                          isShowCommissionFeeCompany && commissionFee
                            ? commissionFee?.final_total_price
                            : totalPrice
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};
