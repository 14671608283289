import { t } from "assets/configi18n/i18n";
import { Part } from "@next/modules/project/redux";

export const matchKeysAndParseData = (results: any, quotationExtraFields: any, parts: any) => {
  try {
    const data = results?.data
      ?.map((result: any) => {
        if (result) {
          const foundData = Object.keys(result).reduce((acc: any, key) => {
            if (key === t("project:table:header:partNumber")) {
              // We need to find part pk from the part name, because csv does not include part pk
              const part = parts.find((item: Part) => item.name === result[key]);

              if (!part) {
                return acc;
              }

              return {
                ...acc,
                name: result[key],
                pk: part?.pk,
              };
            }

            const findField = quotationExtraFields.find((quotationExtraField: any) =>
              Object.values(quotationExtraField.display_name).includes(key)
            );

            if (findField) {
              return {
                ...acc,
                ...{
                  [`${findField?.slug}`]: result[key],
                },
              };
            }

            return acc;
          }, {});

          return foundData;
        }

        return [];
      })
      .filter((item: any) => item?.pk);

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const normalizePartExtraFieldsForPart = (partExtraFields: any) => {
  return Object.entries(partExtraFields).reduce((result: any, obj: any) => {
    const [key, value]: any = obj;
    result[key] = value;
    return result;
  }, {});
};
