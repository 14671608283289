import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { profileNextActions } from "../../redux";
import {
  selectCompanyProfileError,
  selectCompanyProfileExternal,
  selectCompanyProfileLoading,
} from "../../redux/selectors";
import { dashboardActions } from "@next/modules/dashboard/redux";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { selectFavoriteSuppliersLoading } from "@next/modules/workspace/redux/selectors";
import { getCompany } from "services/profile/profile.selectors";
import { CompanyType } from "components/common/table-list/types";
import { useTranslation } from "react-i18next";

export const useCompanyProfileData = () => {
  const dispatch = useDispatch();
  const { id: companyId = undefined } = useParams<{ id: string }>();
  const company = useSelector(getCompany);
  const isBuyer = company?.type === CompanyType.DO;
  const { t } = useTranslation();

  const companyProfile = useSelector(selectCompanyProfileExternal);
  const companyProfileLoading = useSelector(selectCompanyProfileLoading);
  const favoriteSuppliersLoading = useSelector(selectFavoriteSuppliersLoading);
  const companyProfileError = useSelector(selectCompanyProfileError);

  const manufacturingCapabilities = useMemo(() => {
    let capabilitiesData: any = {};
    let totalCapabilities = 0;

    const data = companyProfile?.expertise?.forEach((item) => {
      if (item.type.parent === t("profile:companyProfile:manufacturingCapabilities")) {
        const typeName = item.type.name;

        if (!capabilitiesData[typeName]) {
          capabilitiesData[typeName] = [];
        }

        capabilitiesData[typeName].push({
          name: item.name,
          pk: item.pk,
          slug: item.slug,
        });

        totalCapabilities += 1;
      }
    });

    return {
      data: capabilitiesData,
      count: totalCapabilities,
    };
  }, [companyProfile, t]);

  const specializedServices = useMemo(() => {
    let capabilitiesData: any = {};
    let totalCapabilities = 0;

    const data = companyProfile?.expertise?.forEach((item) => {
      if (item.type.parent === t("profile:companyProfile:specializedServices")) {
        const typeName = item.type.name;

        if (!capabilitiesData[typeName]) {
          capabilitiesData[typeName] = [];
        }

        capabilitiesData[typeName].push({
          name: item.name,
          pk: item.pk,
          slug: item.slug,
        });

        totalCapabilities += 1;
      }
    });

    return {
      data: capabilitiesData,
      count: totalCapabilities,
    };
  }, [companyProfile]);

  const specializedSolutionsAndDistribution = useMemo(() => {
    let capabilitiesData: any = {};
    let totalCapabilities = 0;

    const data = companyProfile?.expertise?.forEach((item) => {
      if (item.type.parent === t("profile:companyProfile:specializedSolutionsAndDistribution")) {
        const typeName = item.type.name;

        if (!capabilitiesData[typeName]) {
          capabilitiesData[typeName] = [];
        }

        capabilitiesData[typeName].push({
          name: item.name,
          pk: item.pk,
          slug: item.slug,
        });

        totalCapabilities += 1;
      }
    });

    return {
      data: capabilitiesData,
      count: totalCapabilities,
    };
  }, [companyProfile]);

  const industryExpertise = useMemo(() => {
    let capabilitiesData: any = {};
    let totalCapabilities = 0;

    const data = companyProfile?.industry_expertise?.forEach((item) => {
      const typeName = item.type.name;

      if (!capabilitiesData[typeName]) {
        capabilitiesData[typeName] = [];
      }

      capabilitiesData[typeName].push({
        name: item.name,
        pk: item.pk,
        slug: item.slug,
      });

      totalCapabilities += 1;
    });

    return {
      data: capabilitiesData,
      count: totalCapabilities,
    };
  }, [companyProfile]);

  useEffect(() => {
    if (companyId) {
      dispatch(
        profileNextActions.fetchCompanyProfileRequest({
          companyId,
          isPublic: true,
          resetState: true,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (isBuyer) dispatch(workspaceNextActions.fetchFavoriteSuppliersRequest({ query: "" }));
  }, [isBuyer]);

  useEffect(() => {
    if (companyProfile?.id) {
      dispatch(
        dashboardActions.addCompanyProfileViewRequest({
          pk: companyProfile?.id as number,
        })
      );
    }
  }, [companyProfile?.id]);

  return {
    companyProfile,
    companyProfileLoading: companyProfileLoading || favoriteSuppliersLoading,
    companyProfileError,
    manufacturingCapabilities,
    specializedServices,
    specializedSolutionsAndDistribution,
    industryExpertise,
  };
};
