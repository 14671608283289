import { Box, Chip, SvgIconProps, Switch, Theme } from "@mui/material";
import React from "react";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

import { CustomTypography } from "@next/components/custom-typography";
import { AppTheme } from "layout/AppTheme";
import { OrderFollowUp, OrderFollowupStateSlug } from "@next/modules/orders/redux";
import { getFollowupStateTagStyle } from "./order-detail-followups-timeline.utils";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { createStyles, makeStyles } from "@mui/styles";

type StyleProps = Partial<OrderFollowUp & { lastItem?: boolean }>;

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(1),
    },
    oppositeContent: {
      flex: 0,
      padding: 0,
    },
    content: {
      paddingTop: 0,
      paddingLeft: "10px",
      display: "flex",
      justifyContent: "space-between",
    },

    icon: {
      opacity: ({ is_muted }) => (is_muted ? 0.3 : 1),
      width: "18px",
      height: "18px",
      color: ({ is_sent, is_next, state }) =>
        is_sent
          ? AppTheme.palette.success.main
          : is_next && state?.slug !== OrderFollowupStateSlug.PENDING
            ? AppTheme.palette.primary.main
            : AppTheme.palette.text.disabled,
      marginTop: "12px",
    },
    "& .MuiTimelineItem-root:before": {
      flex: 0,
    },
    message: {
      whiteSpace: "break-spaces",
      opacity: ({ is_muted }) => (is_muted ? 0.6 : 1),
    },
    date: {
      opacity: ({ is_muted }) => (is_muted ? 0.6 : 1),
    },
    connector: {
      marginTop: "15px",
      flexGrow: 0.5,
      width: "1px",
      backgroundColor: ({ is_sent, is_next }) =>
        is_sent && !is_next ? theme.palette.primary.main : "default",
    },
    editIcon: {
      width: "24px",
      height: "24px",
      opacity: ({ is_muted }) => (is_muted ? 0.3 : 1),
    },
    timeLineItem: {
      minHeight: ({ lastItem }) => (lastItem ? "40px" : "default"),
    },
    hiddenTextField: {
      visibility: "hidden",
      width: "0px",
    },
  })
);

type Props = {
  followUp: Partial<OrderFollowUp>;
  lastItem?: boolean;
  Icon?: React.ComponentType<SvgIconProps>;
  onChangeEditDate?: (
    orderFollowupPk?: number,
    event?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onClickMute?: (orderFollowupPk?: number, nextIsMuted?: boolean) => void;
};

export const OrderDetailFollowupsTimelineItem: React.FC<Props> = ({
  followUp,
  lastItem,
  Icon,
  onClickMute,
}) => {
  const { msg, is_sent, is_next, is_muted, state, display_name } = followUp;
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);

  const classes = useStyles({ is_muted, is_sent, is_next, lastItem, state });

  return (
    <TimelineItem className={classes.timeLineItem}>
      <TimelineOppositeContent className={classes.oppositeContent} />
      <TimelineSeparator>
        {Icon && <Icon className={classes.icon} />}

        {!lastItem ? <TimelineConnector classes={{ root: classes.connector }} /> : null}
      </TimelineSeparator>

      <TimelineContent className={classes.content}>
        <Box display="flex" mt="4px" flexDirection="column">
          <CustomTypography variant="body2" color="textPrimary" className={classes.message}>
            <b>{display_name}</b>
          </CustomTypography>

          <CustomTypography variant="body2" color="textSecondary" className={classes.message}>
            {msg}
          </CustomTypography>
        </Box>

        {is_sent ? (
          <>
            <Box mr="6px">
              <Chip
                label={followUp?.state?.display_name}
                style={getFollowupStateTagStyle(state?.slug)}
              />
            </Box>
          </>
        ) : (
          <Box display={"flex"}>
            {/* <Box mr="12px">
              <Chip
                icon={<VisibilityIcon />}
                label={t("orders:views", { count: followUp?.views_count })}
              />
            </Box> */}

            <Box mr="6px">
              <Chip
                label={followUp?.state?.display_name}
                style={getFollowupStateTagStyle(state?.slug)}
              />
            </Box>

            <Box>
              <Switch
                checked={!is_muted}
                disabled={
                  (is_muted && followUp?.state?.slug !== OrderFollowupStateSlug.IS_USER_MUTED) ||
                  hasViewerRole
                }
                onChange={() => onClickMute && onClickMute(followUp?.pk, !is_muted)}
                color="primary"
              />
            </Box>
          </Box>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};
