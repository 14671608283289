import axios from "axios";
import { getToken } from "services/profile/profile.selectors";
import { store } from "store";
import { downloadFileNext } from "./fileUtils";

export const fileDownloadFromUrl = (
  url: string,
  fileName?: string,
  fileExtension?: string,
  requiresToken: boolean = false,
  propagateError: boolean = false,
  onFinish?: () => void
) => {
  return axios
    .get(url, {
      responseType: "blob",
      headers: requiresToken
        ? {
            Authorization: `JWT ${getToken(store.getState())}`,
          }
        : {},
    })
    .then((res) => {
      const contentDisposition = res.headers["content-disposition"];
      let extractedFileName = fileName || `download_${Date.now()}`;

      if (contentDisposition && contentDisposition.includes("filename=")) {
        extractedFileName = contentDisposition
          .split("filename=")[1]
          .split(";")[0]
          .trim()
          .replace(/"/g, "");
        extractedFileName = decodeURIComponent(extractedFileName);
      }

      downloadFileNext(res.data, extractedFileName, fileExtension, onFinish);
    })
    .catch((error) => {
      if (propagateError) throw error;
      else console.log("File download error", error);
    });
};
