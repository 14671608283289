import { createContext } from "react";
import { Config, QCPartData, QCPartSupplier } from "./types";

type GridDataState = {
  rows: QCPartData[];
  columns: any;
  config?: Config;
};

export type QuoteComparisonSelectedData = {
  selectedSuppliersParts: QCPartSupplier[];
  convertedTotalPriceSum: number;
};

type QuoteComparisonContext = {
  gridData: GridDataState;
  setGridData: (gridData: GridDataState) => void;
  selectedData: QuoteComparisonSelectedData;
  getSelectedTotalSumBySupplier: (supplierId: number) => number;
  onClickAwardSelection: () => void;
  onClickCustomizeView: () => void;
  autoSelectLowestPrice: () => void;
  selectAllPartsBySupplier: (supplierId: number) => void;
  isAwardedRfq?: boolean;
};

const defaultQuoteComparisonContext: QuoteComparisonContext = {
  gridData: {
    rows: [],
    columns: [],
    config: undefined,
  },
  setGridData: () => {},
  selectedData: {
    selectedSuppliersParts: [],
    convertedTotalPriceSum: 0,
  },
  getSelectedTotalSumBySupplier: () => {
    return 0;
  },
  onClickAwardSelection: () => null,
  onClickCustomizeView: () => null,
  autoSelectLowestPrice: () => null,
  selectAllPartsBySupplier: () => null,
  isAwardedRfq: false,
};

export const quoteComparisonContext = createContext<QuoteComparisonContext>(
  defaultQuoteComparisonContext
);

export const QuoteComparisonContextProvider = quoteComparisonContext.Provider;
