import React, { useCallback } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { t } from "assets/configi18n/i18n";

import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "./types";
import ContactSuppliersForm from "../components/contact-suppliers";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
}));

export const ContactSuppliersModal = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const modal = useSelector(getModal(WorkspaceModalTypes.CONTACT_SUPPLIERS_MODAL));

  const onClickCloseModal = useCallback((_, reason?: string) => {
    if (reason === "backdropClick") return;
    dispatch(modalsActions.closeModal(WorkspaceModalTypes.CONTACT_SUPPLIERS_MODAL));
  }, []);

  if (!modal) return null;
  const { supplierId } = (modal.data || {}) as { supplierId?: number };

  return (
    <>
      <Dialog
        open={!!modal}
        onClose={onClickCloseModal}
        closeAfterTransition
        fullWidth={true}
        className={classes.root}
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography variant="h6">{t("workspaceNext:contactSuppliersModal:title")}</Typography>
            </Box>
            <Box>
              <IconButton onClick={onClickCloseModal} size="large">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <ContactSuppliersForm supplierId={supplierId} />
        </DialogContent>
      </Dialog>
    </>
  );
};
