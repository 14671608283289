import { Box, Chip, styled } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { CustomTypography } from "@next/components/custom-typography";

type CardProps = {
  isShowReviewBoxForThreadfulReview: boolean;
};

export const StyledCardBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isShowReviewBoxForThreadfulReview",
})<CardProps>(({ theme, isShowReviewBoxForThreadfulReview }) => ({
  width: "100%",
  height: "56px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "16px",
  gap: "8px",
  borderBottom: `1px solid ${theme.palette.divider}`,
  cursor: !isShowReviewBoxForThreadfulReview ? "pointer" : "default",
  ":hover": {
    backgroundColor: !isShowReviewBoxForThreadfulReview
      ? theme.palette.action.hover
      : "transparent",
  },
}));

export const StyledInnerBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "12px",
});

export const StyledCircle = styled(Box)(({ theme }) => ({
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  border: `2px solid ${theme.palette.text.disabled}`,
}));

export const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
  color: "white",
  backgroundColor: theme.palette.success.main,
  width: "20px",
  height: "20px",
  borderRadius: "50%",
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: "rgba(255, 62, 50, 0.1)",
  color: theme.palette.error.main,
  height: "24px",
}));

export const StyledRightIcon = styled(ChevronRightIcon)({
  width: "24px",
  height: "24px",
});

export const StyledLeftIcon = styled(ChevronLeftIcon)({
  width: "24px",
  height: "24px",
});

export const StyledTypography = styled(CustomTypography)(({ theme }) => ({
  color: theme.palette.primary.main,
  letterSpacing: "0.5px",
}));

export const StyledLabelTypography = styled(CustomTypography)(({ theme }) => ({
  color: theme.palette.text.primary,
  letterSpacing: "0.5px",
}));
