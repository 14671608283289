import { RootState } from "store";

export const selectCompanyProfileAnalytics = (state: RootState) =>
  state.dashboardNext.companyProfileAnalytics;
export const selectCompanyProfileAnalyticsError = (state: RootState) =>
  state.dashboardNext.companyProfileAnalyticsError;
export const selectCompanyProfileAnalyticsLoading = (state: RootState) =>
  state.dashboardNext.companyProfileAnalyticsLoading;

export const selectCompanyProfileViews = (state: RootState) =>
  state.dashboardNext.companyProfileViews;
export const selectCompanyProfileViewsError = (state: RootState) =>
  state.dashboardNext.companyProfileViewsError;
export const selectCompanyProfileViewsLoading = (state: RootState) =>
  state.dashboardNext.companyProfileViewsLoading;
export const selectCompanyProfileViewsCount = (state: RootState) =>
  state.dashboardNext.companyProfileViewsCount;
export const selectCompanyProfileViewsLastQuery = (state: RootState) =>
  state.dashboardNext.companyProfileViewsLastQuery;

export const selectBuyerProfiles = (state: RootState) => state.dashboardNext.buyerProfiles;
export const selectBuyerProfilesError = (state: RootState) =>
  state.dashboardNext.buyerProfilesError;
export const selectBuyerProfilesLoading = (state: RootState) =>
  state.dashboardNext.buyerProfilesLoading;
export const selectBuyerProfilesCount = (state: RootState) =>
  state.dashboardNext.buyerProfilesCount;

export const selectBuyerProfile = (state: RootState) => state.dashboardNext.buyerProfile;
export const selectBuyerProfileError = (state: RootState) => state.dashboardNext.buyerProfileError;
export const selectBuyerProfileLoading = (state: RootState) =>
  state.dashboardNext.buyerProfileLoading;

export const selectBuyerProfilePart = (state: RootState) => state.dashboardNext.buyerProfilePart;
export const selectBuyerProfilePartError = (state: RootState) =>
  state.dashboardNext.buyerProfilePartError;
export const selectBuyerProfilePartLoading = (state: RootState) =>
  state.dashboardNext.buyerProfilePartLoading;

export const selectSupplierOnboardingStatus = (state: RootState) =>
  state.dashboardNext.supplierOnboardingStatus;
export const selectSupplierOnboardingStatusLoading = (state: RootState) =>
  state.dashboardNext.supplierOnboardingStatusLoading;

export const dashboardtSelectors = {
  selectCompanyProfileAnalytics,
  selectCompanyProfileAnalyticsError,
  selectCompanyProfileAnalyticsLoading,
  selectCompanyProfileViews,
  selectCompanyProfileViewsError,
  selectCompanyProfileViewsLoading,
  selectCompanyProfileViewsCount,
  selectCompanyProfileViewsLastQuery,
  selectBuyerProfiles,
  selectBuyerProfilesError,
  selectBuyerProfilesLoading,
  selectBuyerProfilesCount,
  selectBuyerProfile,
  selectBuyerProfileError,
  selectBuyerProfileLoading,
  selectBuyerProfilePart,
  selectBuyerProfilePartError,
  selectBuyerProfilePartLoading,
  selectSupplierOnboardingStatusLoading,
  selectSupplierOnboardingStatus,
};
