/*
 *   SimpleTextField:
 *   Documentation: https://grad4.atlassian.net/wiki/spaces/FDS/pages/229146714/Text+fields
 *   This component is used whenever we want a user to enter some text in a form
 */

// General Imports
import React from "react";

// MaterialUI Imports
import { withStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
// MaterialUI styling
const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },

    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  textField: {
    color: "white",
  },
}));

export function SimpleTextField(props) {
  const {
    name,
    value,
    onChange,
    placeholder,
    label,
    error,
    helperText,
    rows,
    type,
    multiline,
    id,
    autoComplete,
    className,
    handlePaste,
  } = props;
  const classes = useStyles();
  return (
    <CustomTextField
      {...props}
      name={name}
      value={value}
      label={label}
      rows={rows}
      className={`${classes.textField} ${className || ""}`}
      onChange={onChange}
      fullWidth
      id={id || name || "outlined-full-width"}
      placeholder={placeholder}
      helperText={helperText}
      error={error}
      type={type}
      multiline={multiline}
      variant="outlined"
      autoComplete={!!autoComplete ? "on" : "new-password"}
      inputProps={{ autoComplete: !!autoComplete ? "on" : "new-password" }}
      onPaste={handlePaste}
    />
  );
}
