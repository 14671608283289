import React from "react";

import { Button, Typography } from "@mui/material";

export const NoTag = ({ onClickOpenTagDialog, t }) => {
  return (
    <div className="c-no-tag">
      <Button variant="outlined" size="large" onClick={onClickOpenTagDialog}>
        <Typography variant="body2" className="c-no-tag--lowerCase">
          {t("addTag")}
        </Typography>
      </Button>
    </div>
  );
};
