import { Avatar, Box, Paper, Typography, alpha } from "@mui/material";
import React from "react";
import { RichTextEditor } from "@next/components/rich-text-editor";
import MultipleDNDFileUpload from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload";
import { CustomFileObject } from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload.types";
import { useTranslation } from "react-i18next";
import { getInitials } from "@next/utils/stringUtils";
import { EditorState, RawDraftContentState } from "draft-js";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { getPlainText } from "@next/components/rich-text-editor/rich-text-editor-utils";
import CustomButton from "@next/components/custom-button/custom-button";
import { selectSendBuyerProfileMessageLoading } from "../../redux/selectors";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    padding: theme.spacing(2),
  },
  circularProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-12px",
    marginLeft: "-12px",
  },
  suggestionTextPaper: {
    height: "100%",
    padding: "16px 24px 16px 24px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    color: theme.palette.primary.main,
  },
}));

type Props = {
  buyerData?: {
    buyerProfileId: number;
    buyerName: string;
    buyerPicture: string;
  };
  suggestionTitle?: string;
  suggenstionText?: string;
  messageInputLabel?: string;
  buttonText?: string;
  submitHandler: (values: any) => void;
};

export const BuyerProfileSendMessage: React.FC<Props> = ({
  buyerData,
  suggestionTitle,
  suggenstionText,
  messageInputLabel,
  buttonText,
  submitHandler,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const sendBuyerProfileMessageLoading = useSelector(selectSendBuyerProfileMessageLoading);

  const formik = useFormik({
    initialValues: {
      message: null,
      files: [],
    },
    validationSchema: yup.object().shape({
      message: yup.object().required("common:error:thisFieldIsRequired"),
      files: yup.array(),
    }),
    onSubmit: (values) => {
      submitHandler(values);
    },
  });

  const onMessageChange = (state: RawDraftContentState | EditorState) => {
    formik.setFieldValue("message", state);
  };

  const onFilesChange = (files: CustomFileObject[]) => {
    formik.setFieldValue("files", files);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box width="962px" p="36px">
        {suggestionTitle && (
          <Paper className={classes.suggestionTextPaper}>
            <Typography variant="body1">
              <b> {suggestionTitle}</b>
            </Typography>
            <Typography variant="body1" style={{ whiteSpace: "unset" }}>
              {suggenstionText}
            </Typography>
          </Paper>
        )}

        {buyerData && (
          <Box display="flex" alignItems="center" mt="24px" mb="24px">
            <Typography>{t("profile:buyerProfile:to")} </Typography>

            <Box ml="12px">
              <Avatar alt="Photo de profile" src={buyerData.buyerPicture} variant="circular">
                {getInitials(buyerData.buyerName)}
              </Avatar>
            </Box>

            <Box ml="12px">
              <Typography>{buyerData.buyerName} </Typography>
            </Box>
          </Box>
        )}

        <form onSubmit={formik.handleSubmit}>
          <Box>
            <RichTextEditor label={messageInputLabel} minHeight={720} onChange={onMessageChange} />
          </Box>

          <Box mt="24px">
            <MultipleDNDFileUpload
              filesState={formik.values.files}
              setFilesState={onFilesChange}
              compactMode={true}
              disableDndMargin={true}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" mt="24px">
            <CustomButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                !formik.isValid ||
                getPlainText(formik.values.message as any) === "" ||
                sendBuyerProfileMessageLoading
              }
              loading={sendBuyerProfileMessageLoading}
              data-pid="btnSendConnectionRequest"
            >
              {buttonText || t("common:send")}
            </CustomButton>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
