import React from "react";

import { Box, Link, Popover, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
  })
);

interface MouseOverPopoverProps {
  items: string[];
  itemName: string;
}

export const MouseOverPopover: React.FC<MouseOverPopoverProps> = ({ items, itemName }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (items && items.length > 1) setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="c-mousse-popover">
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        variant="caption"
        component={"span"}
      >
        {items && items.length ? (
          items.length > 1 ? (
            <Box fontSize={12} className={`${items.length > 1 && "c-mousse-popover__name"}`}>
              <Link href="#" onClick={(e: React.SyntheticEvent) => e.preventDefault()}>
                {t(`project:table:row:${itemName}`, {
                  count: items.length,
                })}
              </Link>
            </Box>
          ) : (
            <Box fontSize={12} color="#000000">
              {items[0]}
            </Box>
          )
        ) : (
          <Box fontSize={12} color="#000000">
            {t("project:table:row:axyaNetwork")}
          </Box>
        )}
      </Typography>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: 150,
            height: "auto",
            position: "absolute",
            overflowY: "auto",
            overflowX: "hidden",
            minWidth: 16,
            minHeight: 16,
            maxWidth: "calc(100vw - 32px)",
            maxHeight: "calc(100vh - 32px)",
          },
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {Array.isArray(items) &&
          items.map((name: string, index: number) => {
            return (
              <Typography variant="body2" key={`item-${index}`}>
                {name}
              </Typography>
            );
          })}
      </Popover>
    </div>
  );
};
