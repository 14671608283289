import { useFormikContext } from "formik";
import { useEffect } from "react";

type Props = {
  clearFormAfterSubmission?: boolean;
};

export const FormikAutoSaver = ({ clearFormAfterSubmission }: Props) => {
  const formik = useFormikContext();

  useEffect(() => {
    // use your own equality test or react-fast-compare because they are probably different objects
    if (formik.values !== formik.initialValues) {
      formik.submitForm(); // or onSubmit if you want to do validations before submitting
      if (clearFormAfterSubmission) {
        setTimeout(() => {
          formik.resetForm();
        }, 50);
      }
    }
  }, [formik.values]);
  // not listening for initialValues, because even if they are updated you probably don't want to autosave.
  return null;
};
