import React from "react";
import { Button, Grid } from "@mui/material";
import { SimpleTextField } from "../../../components/utils/text-field/text-field.component";
import { Formik } from "formik";
import * as Yup from "yup";

const JoinForm = (props) => {
  const {
    values: { validation_code },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    t,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <SimpleTextField
          name="validation_code"
          value={validation_code}
          onChange={handleChange}
          placeholder={t("PROFILE:validation_code")}
          helperText={touched.validation_code ? errors.validation_code : ""}
          error={Boolean(errors.validation_code)}
        />
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Button
            type="submit"
            onClick={() => handleSubmit()}
            color="primary"
            variant="contained"
            disabled={!isValid}
          >
            {t("PROFILE:validate")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const JoinCompanyForm = (props) => {
  const { joinCompany, t } = props;
  const validationSchema = Yup.object({
    validation_code: Yup.string(t("PROFILE:enter_validation_code ")).required(
      t("PROFILE:validation_code_is_required")
    ),
  });
  return (
    <div>
      <Formik
        initialValues={{ validation_code: "" }}
        validationSchema={validationSchema}
        onSubmit={joinCompany}
        render={(props) => {
          return <JoinForm {...props} t={t} />;
        }}
      />
    </div>
  );
};

export default JoinCompanyForm;
