import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { WorkspaceModalTypes } from "./types";
import { useTranslation } from "react-i18next";
import Modal from "@next/components/custom-modal";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import TopicPortalSendQuotationForm from "../components/questions-answers/topic/topic-portal/topic-portal-send-quotation/topic-portal-send-quotation-form";

export const TopicPortalSendQuotationModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(WorkspaceModalTypes.TOPIC_PORTAL_SEND_QUOTATION_MODAL));
  const data = modal?.data as {
    quote?: any;
    isRFI?: Boolean;
  };
  const isQuoteAvailable = !!data?.quote;
  const isRFI = data?.isRFI;

  if (!modal) return null;

  const onClose = () => {
    dispatch(modalsActions.closeModal(WorkspaceModalTypes.TOPIC_PORTAL_SEND_QUOTATION_MODAL));
  };

  return (
    <Modal
      handleModalClose={onClose}
      modalState={{
        modalHeader: isQuoteAvailable
          ? t(
              isRFI
                ? "workspaceNext:QA:topicPortal:editYourSubmission"
                : "workspaceNext:QA:topicPortal:editYourQuotation"
            )
          : t(
              isRFI
                ? "workspaceNext:QA:topicPortal:sendYourSubmission"
                : "workspaceNext:QA:topicPortal:sendYourQuotation"
            ),
        modalBody: <TopicPortalSendQuotationForm />,
      }}
      isModalOpen={true}
    />
  );
};
