import React, { useEffect, useMemo } from "react";
import { Drawer } from "@mui/material";
import { batch, useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "./types";
import RfqCreationForm from "../components/rfq-creation-form";
import { workspaceNextActions } from "../redux";
import { workspaceActions } from "services/workspace";
import { partConfigurationActions } from "services/part-configuration";
import { selectPartConfigurationLoading } from "services/part-configuration/part-configuration.selectors";
import { projectActions } from "@next/modules/project/redux";

export const RFQCreationDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(WorkspaceModalTypes.RFQ_CREATION_DRAWER));
  const configurationLoading = useSelector(selectPartConfigurationLoading);
  const data = modal?.data || {};
  const [initialProjectLoading, setInitialProjectLoading] = React.useState(false);

  const onClose = () => {
    batch(() => {
      dispatch(modalsActions.closeModal(WorkspaceModalTypes.RFQ_CREATION_DRAWER));
      dispatch(workspaceActions.setSelectedParts([]));
      dispatch(workspaceActions.setBuyerNdaAttachments([]));
      dispatch(workspaceNextActions.resetFileUploadProgress());
    });
  };

  useEffect(() => {
    dispatch(workspaceNextActions.setActiveDraftRFQ(null));
  }, []);

  useEffect(() => {
    if (!!modal) dispatch(partConfigurationActions.fetchPartConfigurationData());
  }, [!!modal]);

  useEffect(() => {
    if (data.projectCode) {
      setInitialProjectLoading(true);
      dispatch(
        projectActions.fetchProjectStatsRequest({
          projectCode: data.projectCode,
          onSuccess: (data) => {
            setInitialProjectLoading(false);
          },
        })
      );
    }
  }, [data.projectCode]);

  const loading = useMemo(() => {
    return data.loading || configurationLoading || initialProjectLoading;
  }, [data.loading, configurationLoading, initialProjectLoading]);

  return (
    <Drawer PaperProps={{ style: { width: 840 } }} anchor="right" open={!!modal} onClose={onClose}>
      {!!modal ? (
        <RfqCreationForm
          initialValues={data.form || {}}
          projectCode={data.projectCode || data.form?.project || null}
          loading={loading}
          selectAllParts={
            data.form?.all_parts_selected === undefined
              ? data.selectAllParts
              : data.form.all_parts_selected
          }
          partSearch={data.form?.part_search || data.partSearch}
          selectedPartsCount={
            data.form?.selected_parts_count ??
            data.selectedPartsCount ??
            data.form?.parts?.length ??
            0
          }
        />
      ) : null}
    </Drawer>
  );
};
