import React from "react";
import { Breadcrumbs, Link } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { toTitleCase } from "./custom-breadrcumbs.utils";
import { CustomTypography } from "../custom-typography";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    li: {
      marginBottom: 0,
    },
    separator: {
      marginBottom: 0,
    },
  })
);
type Props = {
  pathNames?: string[];
  linkRootTo: string;
};
const CustomBreadcrumbs: React.FC<Props> = ({ pathNames, linkRootTo }) => {
  const location = useLocation();
  const pathnames = pathNames || location.pathname.split("/").filter((x) => x);

  const classes = useStyles();

  return (
    <Breadcrumbs classes={classes} aria-label="breadcrumb">
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        return last ? (
          <CustomTypography
            maxWidth="calc(100vw - 970px)"
            color="textPrimary"
            key={to}
            variant="body1"
          >
            {toTitleCase(`${value}`)}
          </CustomTypography>
        ) : (
          <Link color="inherit" component={RouterLink} to={linkRootTo} key={to}>
            {toTitleCase(`${value}`)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
