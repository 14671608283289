// General function to handle a response object
export function handleResponse(response) {
  return new Promise((resolve) => {
    response.text().then((text) => {
      let data;
      try {
        data = text && JSON.parse(text);
      } catch (e) {
        data = response?.data;
      }

      resolve(data);
    });
  }).catch(() => {
    return Promise.resolve(response?.data);
  });
}
