import { useDispatch, useSelector } from "react-redux";
import {
  selectBuyerProfiles,
  selectBuyerProfilesCount,
  selectBuyerProfilesLoading,
} from "../../redux/selectors";
import { useEffect, useState } from "react";
import { dashboardActions } from "../../redux";
import { generatePaginationAndSearchQuery } from "@next/utils/paginationUtils";

export const useSellerDashboardActiveBuyersData = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const buyerProfiles = useSelector(selectBuyerProfiles);
  const buyerProfilesCount = useSelector(selectBuyerProfilesCount);
  const buyerProfilesLoading = useSelector(selectBuyerProfilesLoading);

  useEffect(() => {
    dispatch(
      dashboardActions.fetchBuyerProfilesRequest({
        query: generatePaginationAndSearchQuery({
          currentPage,
          pageSize: 6,
        }),
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return {
    currentPage,
    setCurrentPage,
    buyerProfiles,
    count: buyerProfilesCount,
    loading: buyerProfilesLoading,
  };
};
