import React from "react";
import EditUserProfileForm from "./edit-user-profile-form/edit-user-profile-form";

export const ProfileSettings = () => {
  return (
    <div>
      <EditUserProfileForm />
    </div>
  );
};
