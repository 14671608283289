import PaginatedGridGallery from "@next/modules/profile/components/company-profile-full/paginated-grid-gallery/paginated-grid-gallery";
import { CompanyPublicProfile } from "@next/modules/profile/redux";
import React from "react";

interface Props {
  companyProfile?: CompanyPublicProfile;
}

const CompanyProfileFullShop: React.FC<Props> = ({ companyProfile }) => {
  return companyProfile?.shop_pictures?.length ? (
    <PaginatedGridGallery images={companyProfile.shop_pictures?.map((item) => item.url) || []} />
  ) : null;
};

export default CompanyProfileFullShop;
