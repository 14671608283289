import { InternationalStandardTableRow } from "../redux";

export const validateInternationalStandardTableRows = (
  rows: InternationalStandardTableRow[]
): boolean => {
  if (!rows || rows.length === 0) return true;

  return rows.every(
    (row: InternationalStandardTableRow) =>
      row.name !== undefined &&
      row.scope_limitation !== "" &&
      row.scope_limitation !== undefined &&
      row.dash_ref !== "" &&
      row.dash_ref !== undefined &&
      row.revision !== "" &&
      row.revision !== undefined &&
      row.controlled_process !== "" &&
      row.controlled_process !== undefined &&
      row.description !== "" &&
      row.description !== undefined
  );
};
