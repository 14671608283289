/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { workspaceNextActions } from "../../redux";
import {
  selectQAThreadDetails,
  selectQAThreadDetailsLoading,
  selectQATopics,
  selectQATopicsLoading,
  selectRfqQuestionsStats,
  selectRfqQuestionsStatsLoading,
} from "../../redux/selectors";

export const useQuestionAnswersData = (rfqId: string) => {
  const questions = useSelector(selectQATopics);
  const questionsLoading = useSelector(selectQATopicsLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (rfqId) {
      dispatch(
        workspaceNextActions.fetchQATopicsRequest({
          rfqId,
        })
      );
    }
  }, [rfqId]);

  return {
    questions,
    questionsLoading,
  };
};

export const useRfqQAStats = (rfqId: string) => {
  const rfqQAStats = useSelector(selectRfqQuestionsStats);
  const rfqQAStatsLoading = useSelector(selectRfqQuestionsStatsLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (rfqId) {
      dispatch(
        workspaceNextActions.fetchRFQQuestionsStatsRequest({
          rfqId,
        })
      );
    }
  }, [rfqId]);

  return {
    rfqQAStats,
    rfqQAStatsLoading,
  };
};

export const useQAThreadDetails = (qaId: string) => {
  const dispatch = useDispatch();
  const question = useSelector(selectQAThreadDetails);
  const questionLoading = useSelector(selectQAThreadDetailsLoading);

  useEffect(() => {
    if (qaId) {
      dispatch(
        workspaceNextActions.fetchQAThreadDetailsRequest({
          qaId,
        })
      );
    }
  }, [qaId]);

  return {
    question,
    questionLoading,
  };
};
