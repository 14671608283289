// General Import
import React from "react";
import { ConnectedRouter } from "connected-react-router";

import { connect, useSelector } from "react-redux";
import { history } from "helpers/history";
import { withTranslation } from "react-i18next";

// Routes renderer
import { RenderRoutes } from ".";
// Routes configuration to render
import routes from "./config";

import { profileActions } from "services/profile/profile.actions";
import { partConfigurationActions } from "services/part-configuration";
import { modalsActions } from "services/modals/modals.actions";
import { inboxActions } from "services/inbox/inbox.actions";
import {
  connect as initSocketConnection,
  listenForUpdates,
  onReceiveUpdates,
} from "services/socket/socket.actions";

import ErrorBoundary from "./ErrorBoundary";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, Theme } from "@mui/material/styles";
import { ThemeProvider as ThemeProviderV5 } from "@mui/material/styles";
import { StylesProvider, ThemeProvider as ThemeProviderV4 } from "@material-ui/core/styles";
import { AppTheme, AppThemeV5, generateClassName } from "layout/AppTheme";
import { SnackbarProvider as SnackbarProviderNext } from "notistack";
import { CustomSnackbar } from "@next/components/custom-snackbar/custom-snackbar";
import { SnackbarNextVariant, SnackbarUtilsConfigurator } from "@next/utils/snackbarUtils";
import { initPendo } from "@next/utils/browserUtils";
import { APP_ENVIRONMENT, checkAppEnvironmentIs } from "@next/utils/constantUtils";
import { getProfile } from "services/profile/profile.selectors";

declare module "@mui/styles" {
  interface DefaultTheme extends Theme {}
}

const AppRouter: React.FC = (props) => {
  const isLocalHost = checkAppEnvironmentIs([APP_ENVIRONMENT.LOCALHOST]);
  const profile = useSelector(getProfile);

  if (!isLocalHost) {
    //For registration flow tracking where pendo will be init even if there is no profile information
    initPendo(profile);
  }

  return (
    <ErrorBoundary {...props}>
      <StyledEngineProvider injectFirst>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProviderV4 theme={AppTheme}>
            <ThemeProviderV5 theme={AppThemeV5}>
              <CssBaseline />
              <ConnectedRouter history={history}>
                <SnackbarProviderNext
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  Components={{
                    [SnackbarNextVariant.CUSTOM_SNACKBAR]: CustomSnackbar,
                  }}
                  variant={SnackbarNextVariant.CUSTOM_SNACKBAR}
                >
                  <SnackbarUtilsConfigurator />
                  <RenderRoutes routes={routes} props={props} />

                  {/* Global file input elem to pick file programmatically from anywhere */}
                  <input id="c-global-file-input" type="file" hidden />
                </SnackbarProviderNext>
              </ConnectedRouter>
            </ThemeProviderV5>
          </ThemeProviderV4>
        </StylesProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  );
};

/*
 *   Map Dispatch to Props:
 *   In this Application we need to have a few functionalities to fetch the data from
 *   the backend and to refresh the token if the user is re-rendering the application.
 */
const mapDispatchToProps = {
  refreshToken: profileActions.refreshToken,
  fetchUserProfile: profileActions.fetchUserProfile,
  fetchCompany: profileActions.fetchCompany,
  fetchProfilePreferences: profileActions.fetchProfilePreferences,
  fetchPartConfigurationData: partConfigurationActions.fetchPartConfigurationData,
  handleOpenModal: modalsActions.showModal,
  initSocketConnection: initSocketConnection,
  handleInboxUpdates: inboxActions.handleInboxUpdates,
  getQuotationThreadDetails: inboxActions.getQuotationThreadDetails,
  setActiveQuotationThread: inboxActions.setActiveQuotationThread,
  listenForUpdates,
  onReceiveUpdates,
};

// TODO: Double check here if we need to have all the state
function mapStateToProps(state: any) {
  return state;
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppRouter));
