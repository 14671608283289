import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Paper } from "@mui/material";
import { Field, Form, Formik } from "formik";
import {
  getInitialCapabilities,
  getNormalizedCapabilitiesTypes,
  getPKsFromFormikValues,
} from "../company-capabilities/company-capabilities.utils";
import { selectCompanyProfile } from "@next/modules/profile/redux/selectors";
import {
  selectCompanyCapabilitiesTypes,
  selectPatchCompanyCapabilitiesLoading,
} from "@next/modules/company-capabilities/redux/selectors";
import {
  CapabilityTypes,
  companyCapabilitiesActions,
} from "@next/modules/company-capabilities/redux";
import FormikAutoComplete, {
  FormikOnChangeParams,
} from "@next/components/formik-autocomplete/formik-autocomplete";
import CustomButton from "@next/components/custom-button";
import { profileNextActions } from "@next/modules/profile/redux";

export const CompanyIndustryExpertise = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyProfile = useSelector(selectCompanyProfile);
  const companyCapabilitiesTypes = useSelector(selectCompanyCapabilitiesTypes);
  const submitIndustryExpertiseLoading = useSelector(selectPatchCompanyCapabilitiesLoading);
  const industryExpertise = getNormalizedCapabilitiesTypes(
    companyCapabilitiesTypes?.[CapabilityTypes.INDUSTRY_EXPERTISE] || []
  );

  useEffect(() => {
    dispatch(
      companyCapabilitiesActions.fetchCompanyCapabilitesTypesRequest({
        type: CapabilityTypes.INDUSTRY_EXPERTISE,
      })
    );
  }, []);

  const initialValues = {
    ...getInitialCapabilities(companyProfile?.industry_expertise),
  };
  const handleSubmitIndustryExpertise = (values: any) => {
    dispatch(
      companyCapabilitiesActions.patchCompanyCapabilitiesRequest({
        capabilities: getPKsFromFormikValues(values),
        onSuccess: () => {
          dispatch(
            profileNextActions.fetchCompanyProfileRequest({
              companyId: companyProfile?.id as number,
              isPublic: false,
            })
          );
        },
      })
    );
  };

  return (
    <section className="c-company-capabilities">
      <Formik initialValues={initialValues} onSubmit={handleSubmitIndustryExpertise}>
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Box className="c-company-capabilities--subtitle">
                {t("profile:companyProfile:industryExpertiseSubtitle")}
              </Box>
              {Object.keys(industryExpertise).map((parentKey) => {
                const items = industryExpertise[parentKey];
                return (
                  <>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      {items.map((item) => (
                        <React.Fragment key={item.pk}>
                          <Field
                            name={`${parentKey}-${item.pk}`}
                            component={FormikAutoComplete}
                            options={item.capabilities.map((item) => ({
                              name: item.name,
                              value: item.pk,
                            }))}
                            multiple
                            customOnChange={({ details }: FormikOnChangeParams) => {
                              dispatch(
                                companyCapabilitiesActions.toggleCompanyCapability(
                                  details?.option?.value
                                )
                              );
                            }}
                            PaperComponent={(props: any) => {
                              return <Paper elevation={8} {...props} />;
                            }}
                            label={item.name}
                            fieldSize="medium"
                            enableCheckboxes
                            disableClearable
                            autoHighlight
                            selectOnFocus
                            disableCloseOnSelect
                            freeSolo
                            enableGrouping
                          />
                        </React.Fragment>
                      ))}
                    </Box>
                  </>
                );
              })}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                className="c-company-capabilities--btn"
              >
                <CustomButton
                  variant="contained"
                  type="submit"
                  size="medium"
                  color="primary"
                  loading={submitIndustryExpertiseLoading}
                >
                  {t("common:save")}
                </CustomButton>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};
