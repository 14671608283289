import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { Checkbox, styled } from "@mui/material";

type Props = {
  disabled: boolean;
} & GridRenderCellParams;

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.Mui-checked.Mui-disabled": {
    color: theme.palette.primary.main,
    opacity: 0.5,
  },
  "&.Mui-disabled": {
    color: theme.palette.text.disabled,
  },
}));

export const CheckboxCell: React.FC<Props> = (params) => {
  return <StyledCheckbox checked={!!params.value} disabled={params.disabled} />;
};
