import React from "react";
import { SharedCancelRfqModal } from "./shared-cancel-rfq-modal";
import { SharedCancelRfqConfirmationModal } from "./shared-cancel-rfq-confirmation-modal";
import { SharedExtendRfqModal } from "./shared-extend-rfq-modal";
import { SharedExtendRfqConfirmationModal } from "./shared-extend-rfq-confirmation-modal";
import { SharedProjectIntroductionModal } from "./shared-project-introduction-modal";
import { SharedAttachFileDeleteConfirmationModal } from "./shared-attach-file-delete-confirmation-modal";
import { RFQCreationDrawer } from "@next/modules/workspace/modals/rfq-creation-drawer";
import { SharedOrderPurchaseDialogModal } from "./shared-order-purchase-modal";
import { SharedUserRatingModal } from "./shared-user-rating-modal";
import { SharedAddOnHoldNoteModal } from "./shared-add-on-hold-note-modal";
import { SharedAddCollaboratorModal } from "./shared-add-collaborator-modal";
import SharedInviteCompanyUserModal from "./shared-invite-company-user-modal";
import SharedGlobalSearchDialog from "./shared-global-search-dialog";
import { ContactSuppliersModal } from "@next/modules/workspace/modals/contact-suppliers-modal";
import ReleaseAnnouncement from "@next/modules/announcement/pages/release-announcement";
import { SharedUpgradeToPremiumModal } from "./shared-upgrade-to-premium-modal";
import { useSelector } from "react-redux";
import { getProfile } from "services/profile/profile.selectors";
import { BuyerProfileSendMessageDrawer } from "@next/modules/profile/modals/buyer-profile-send-message-drawer";
import { checkIsPublicPage } from "@next/utils/apiUtils";
import { RFQCreationLiteDrawer } from "@next/modules/workspace/modals/rfq-creation-lite-drawer";
import { SharedSupplierContactsListModal } from "./shared-supplier-contact-lists-modal";
import { SharedInviteSupplierContactModal } from "./shared-invite-supplier-contact-modal";
import { SharedPartFileModal } from "./shared-part-file-modal";
import { SharedBulkFileImportModal } from "./shared-bulk-files-import-modal";

export const SharedModals = () => {
  const isPublicPage = checkIsPublicPage();
  const profile = useSelector(getProfile);

  return (
    <>
      <SharedCancelRfqModal />
      <SharedCancelRfqConfirmationModal />

      <SharedExtendRfqModal />
      <SharedExtendRfqConfirmationModal />

      <SharedAddCollaboratorModal />

      <SharedProjectIntroductionModal />
      <SharedAttachFileDeleteConfirmationModal />

      <RFQCreationDrawer />

      <RFQCreationLiteDrawer />

      <SharedOrderPurchaseDialogModal />

      <SharedUserRatingModal />

      <SharedAddOnHoldNoteModal />

      <SharedInviteCompanyUserModal />

      <SharedGlobalSearchDialog />

      <SharedUpgradeToPremiumModal />

      <ContactSuppliersModal />

      <BuyerProfileSendMessageDrawer />

      <SharedSupplierContactsListModal />

      <SharedInviteSupplierContactModal />

      <SharedPartFileModal />

      <SharedBulkFileImportModal />

      {profile?.token && !isPublicPage ? <ReleaseAnnouncement /> : null}
    </>
  );
};
