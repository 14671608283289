import React from "react";
import { List } from "@mui/material";
import { FileListItem } from "./file-list-item";
import { PartUpload } from "@next/modules/project/redux";
import { FileUploadProgress } from "@next/modules/workspace/redux";
import { convertFileUploadProgresstoPartUpload } from "@next/utils/fileUtils";

type Props = {
  files: PartUpload[];
  filesUploading?: FileUploadProgress[];
  selectedFile?: PartUpload;
  setSelectedFile: (file: PartUpload) => void;
  onDelete: (file: PartUpload) => void;
  readOnly?: boolean;
};

export const FileList: React.FC<Props> = ({
  files = [],
  selectedFile,
  filesUploading,
  setSelectedFile,
  onDelete,
  readOnly,
}) => {
  return (
    <List disablePadding={true}>
      {filesUploading?.length === 0
        ? null
        : filesUploading?.map((file) => (
            <FileListItem
              file={convertFileUploadProgresstoPartUpload(file)}
              onClick={() => {}}
              onDelete={onDelete}
              selected={false}
              readOnly={readOnly}
            />
          ))}
      {files.length === 0
        ? null
        : files.map((file) => (
            <FileListItem
              file={file}
              key={file.pk}
              onClick={setSelectedFile}
              onDelete={onDelete}
              selected={selectedFile?.pk === file.pk}
              readOnly={readOnly}
            />
          ))}
    </List>
  );
};
