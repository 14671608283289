import { Box, styled } from "@mui/material";

export const ActionPanelBox = styled(Box)<{ centerItems?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  align-items: ${(props) => (props.centerItems ? "center" : "flex-start")};
`;

export const LoadingContainer = styled(Box)`
  display: grid;
  place-items: center;
  position: absolute;
  top: 73px;
  left: 0;
  right: 0;
  bottom: 0;
`;
