import * as yup from "yup";
import { t } from "assets/configi18n/i18n";
import { RFQAddAddendaFormValues } from "./rfq-add-addenda-form.types";

export const getInitialValues = (): RFQAddAddendaFormValues => {
  return {
    suppliers: [],
    note: "",
    files: [],
  };
};

export const validationSchema = yup.object().shape({
  suppliers: yup.array().required(t("userOnboarding:thisFieldIsRequired")),
  note: yup.string().when("files", {
    is: (files: Array<any>) => !files || files.length === 0,
    then: yup.string().required(t("userOnboarding:thisFieldIsRequired")),
    otherwise: yup.string().notRequired(),
  }),
});
