import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Avatar, Divider, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { history } from "helpers/history";
import { frontendUrl } from "urls";
import { modalsActions } from "services/modals/modals.actions";
import { getIsNewUser, getUser } from "services/profile/profile.selectors";
import { CompanyTypeNext } from "@next/redux/types";
import { UserProfileSettingsModal } from "@next/modules/profile/modals/user-profile-settings-modal";
import { UserProfileTwoFactorManageModal } from "@next/modules/profile/modals/user-profile-two-factor-manage-modal";
import * as S from "./validation-management-user-profile-menu.styled";

export const ValidationManagementUserProfileMenu = () => {
  const { showModal } = modalsActions;
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const isNewUser = useSelector(getIsNewUser);

  useEffect(() => {
    //check if announcement model is visited and hide the workspace tour
    const isModalOpen = localStorage.getItem("isAxyaAnnouncementVisited");
    if (
      isNewUser &&
      user &&
      user?.type === CompanyTypeNext.DO &&
      location &&
      location.pathname.includes("/my-parts") &&
      !isModalOpen
    ) {
      dispatch(showModal(true, "isAppTourOpen"));
    }
  }, [isNewUser]);

  const onClickLogout = () => history.push(frontendUrl.logout);

  return (
    <S.StyledBox>
      <ListItem>
        <ListItemAvatar>
          <Avatar alt="Photo de profile" src={user?.picture} variant="circular" />
        </ListItemAvatar>

        <ListItemText
          primary={<Typography>{`${user?.first_name} ${user?.last_name}`}</Typography>}
          secondary={<Typography>{user?.email || user?.username}</Typography>}
        />
      </ListItem>

      <Divider style={{ margin: "8px" }} />

      <S.StyledMenuItem onClick={onClickLogout}>
        <PowerSettingsNewIcon />
        <S.StyledTypography>{t("SIDE_BAR:logout")}</S.StyledTypography>
      </S.StyledMenuItem>

      <UserProfileSettingsModal />
      <UserProfileTwoFactorManageModal />
    </S.StyledBox>
  );
};
