import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { FavoriteSupplierGroup } from "../../redux";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  select: { height: 42.25 },
  filterFormControl: {
    minWidth: 200,
  },
  paper: { maxHeight: "300px" },
}));

type Props = {
  placeholder: string;
  value?: number;
  onChange: (event: SelectChangeEvent<any>) => void;
  items: FavoriteSupplierGroup[];
};

export const SelectFilterSuppliersDropdown: React.FC<Props> = ({
  placeholder,
  onChange,
  value,
  items = [],
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.filterFormControl}>
      <Select
        labelId="select-filter-suppliers-dropdown-label"
        id="select-filter-suppliers-dropdown"
        value={value}
        onChange={onChange}
        variant="outlined"
        displayEmpty
        className={classes.select}
        renderValue={(selected) => {
          if (!selected) {
            return <span style={{ color: "gray" }}>{placeholder}</span>;
          }
          const selectedItem = items.find((item) => item.pk === selected);
          return selectedItem ? selectedItem.name : "";
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },

          classes: {
            paper: classes.paper,
          },
        }}
      >
        {items?.map((item) => <MenuItem value={item?.pk}>{item?.name}</MenuItem>)}
      </Select>
    </FormControl>
  );
};
