import React, { useCallback } from "react";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { useDispatch, useSelector } from "react-redux";
import { MarketplaceModalTypes } from "./types";
import Modal from "@next/components/custom-modal";
import {
  ITNQReasonFormProps,
  IntentToNoQuoteReasonForm,
} from "../components/intent-to-bid/intent-to-no-quote-reason-form";

const IntentToNoQuoteReasonModal: React.FC = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(MarketplaceModalTypes.INTENT_TO_NO_QUOTE_MODAL));

  const data = modal?.data as ITNQReasonFormProps;

  const onClose = useCallback(() => {
    dispatch(modalsActions.closeModal(MarketplaceModalTypes.INTENT_TO_NO_QUOTE_MODAL));
  }, []);

  const onSkip = useCallback(() => {
    if (typeof data?.onSkip === "function") data.onSkip();
    onClose();
  }, [data?.onSkip]);

  const onSubmit = useCallback(
    (reason) => {
      if (typeof data?.onSubmit === "function") data.onSubmit(reason);
      onClose();
    },
    [data?.onSubmit]
  );

  if (!modal || !data) return null;

  return (
    <Modal
      handleModalClose={onSkip}
      modalState={{
        modalBody: <IntentToNoQuoteReasonForm onSubmit={onSubmit} onSkip={onSkip} />,
      }}
      isModalOpen={!!modal}
    />
  );
};

export default IntentToNoQuoteReasonModal;
