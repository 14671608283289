import { Box, Button, Typography } from "@mui/material";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { selectShowRevisionStatusBar } from "@next/modules/workspace/redux/selectors";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import RFQPartsStatusBar from "./rfq-parts-status-bar";
import { RFQPartsTable } from "@next/modules/project/components/part/rfq-part-table";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { PartsAndFilesCount } from "@next/modules/workspace/components/rfq-creation-form/rfq-creation.form.types";
import RfqAddFilesAndPartsModal, {
  RfqDataToPartsModal,
} from "@next/modules/workspace/modals/rfq-add-filesandparts-modal/rfq-add-filesandparts-modal";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import { workspaceActions } from "services/workspace";

export const BuyerRfqsInformationPartList = () => {
  const { t } = useTranslation();
  const showRevisionStatusBar = useSelector(selectShowRevisionStatusBar);
  const dispatch = useDispatch();
  const rfqDetails = useSelector(getRfqDetails);

  const setShowRevisionStatusBar = (val: any) => {
    dispatch(workspaceNextActions.setShowRevisionStatusBar(val));
  };
  const openPartDialog = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.RFQ_ADD_FILES_AND_PARTS_MODAL,
        data: {
          projectCode: rfqDetails?.project?.code,
          onAttachmentsChange: (files: FileData[], filestoDelete?: number) => {},
          onPartsCountChange: (partsAndFilesCount: PartsAndFilesCount) => {},
          isRFQEditMode: true,
        } as RfqDataToPartsModal,
      })
    );
    dispatch(workspaceActions.setRFQPartForEdit());
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Box mr={2}>
          <Typography variant="h6">{t("rfq:rfqInfosPartTitle")}</Typography>
        </Box>
        <Button variant="outlined" startIcon={<EditIcon />} onClick={openPartDialog}>
          {t("common:edit")}
        </Button>
      </Box>
      <RFQPartsTable
        readOnly={true}
        hidePriceColumn={true}
        onEditStart={() => setShowRevisionStatusBar(true)}
      />
      <RfqAddFilesAndPartsModal isRFQEditMode={true} />
    </Box>
  );
};
