import { connect } from "react-redux";

// Action Import
import { modalsActions } from "../../services/modals/modals.actions";
import { inboxActions } from "../../services/inbox/inbox.actions";
import { workspaceActions } from "../../services/workspace";

import {
  emitMessage,
  listenToNewMessages,
  onReceiveRFQRoomMsg,
} from "../../services/socket/socket.actions";

import { Inbox } from "./inbox-drawer.component";

const mapDispatchToProps = {
  handleOpenModal: modalsActions.showModal,
  getQuotationMessages: inboxActions.getQuotationMessages,
  publishMessage: inboxActions.publishMessage,
  sendQuotationMessage: inboxActions.sendQuotationMessage,
  setActiveQuotationThread: inboxActions.setActiveQuotationThread,
  logUserActivity: inboxActions.logUserActivity,
  getQuotationThreadDetails: inboxActions.getQuotationThreadDetails,
  fetchCompanyRFQList: workspaceActions.fetchCompanyRFQList,

  listenToNewMessages,
  emitMessage,
  onReceiveRFQRoomMsg,
};

const mapStateToProps = (state) => {
  return {
    token: state.profile.token,
    company: state.profile.company,
    user: state.profile.user,
    userType: state.profile.user.type,
    modalState: !!state.modals.isInboxOpen,
    quotationMessages: state.inbox.quotationMessages,
    isLoading: state.inbox.isLoading,
    messages: state.inbox.messages,
    RFQList: state.workspace.RFQListOfCompany,
    isMessagesLoading: state.inbox.isMessagesLoading,
    isAboutFeatureModalOpen: state.modals.isAboutFeatureModalOpen,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
