import React, { useState } from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { GenericTextField } from "components/generic-text-field/generic-text-field.component";
import { useTranslation } from "react-i18next";
import { CustomTypography } from "@next/components/custom-typography";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  passwordContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  formControlLabel: {
    display: "flex",
    width: "max-content",
    "& .MuiButtonBase-root": {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
}));

export const GenericPasswordField = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div style={{ width: "100%" }}>
      <Box className={classes.passwordContainer}>
        <GenericTextField {...props} type={showPassword ? "text" : "password"} />
        <FormControlLabel
          className={classes.formControlLabel}
          control={
            <Checkbox checked={showPassword} onChange={handleTogglePassword} color="primary" />
          }
          label={
            <CustomTypography variant="body2" color="textPrimary" tooltipTitle={null}>
              {t("common:showPassword")}
            </CustomTypography>
          }
        />
      </Box>
    </div>
  );
};
