import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { CompanyInformationFormBase } from "./company-information.form.base";
import { validationSchema } from "./company-information.form.definitions";
import { CompanyInformationFormValues } from "./company-information.form.types";
import { selectCompanyProfile } from "@next/modules/profile/redux/selectors";
import { profileNextActions } from "@next/modules/profile/redux";
import { getPlainAndRichTextAsString } from "@next/components/rich-text-editor/rich-text-editor-utils";

export const CompanyInformationForm: React.FC = () => {
  const dispatch = useDispatch();

  const companyProfile = useSelector(selectCompanyProfile);

  const initialValues: CompanyInformationFormValues = {
    name: companyProfile?.name || "",
    description: companyProfile?.description || "",
    additional_details: companyProfile?.additional_details || "",
    additional_details_rich_text:
      companyProfile?.additional_details_rich_text || companyProfile?.additional_details || "",
    address: {
      street: companyProfile?.address?.street || "",
      city: companyProfile?.address?.city || "",
      province: companyProfile?.address?.province || "",
      postal_code: companyProfile?.address?.postal_code || "",
      country: companyProfile?.address?.country || "",
    },
    public_email: companyProfile?.public_email || "",
    phone_number: companyProfile?.phone_number || "",
    website: companyProfile?.website || "",
    shop_floor_area: companyProfile?.shop_floor_area || "",
    number_employee: companyProfile?.number_employee || 1,
    picture: companyProfile?.picture || "",
  };

  const handleSubmitCompanyInformation = (values: CompanyInformationFormValues) => {
    const { plainText, richText } = getPlainAndRichTextAsString(
      values.additional_details_rich_text || ""
    );
    dispatch(
      profileNextActions.updateCompanyProfileRequest({
        ...values,
        additional_details: plainText,
        additional_details_rich_text: richText,
        id: companyProfile?.id,
      })
    );
  };

  return (
    <>
      <Formik
        render={(props) => <CompanyInformationFormBase {...props} />}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmitCompanyInformation}
        validationSchema={validationSchema}
      />
    </>
  );
};
