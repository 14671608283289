//this component is used to show application tour

// general imports
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";

import { useTranslation } from "react-i18next";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { modalsActions } from "../../../services/modals/modals.actions";
import { AppTheme } from "layout/AppTheme";

const WorkSpaceTour = () => {
  const { t } = useTranslation("workspaceTour");
  const isModalOpen = localStorage.getItem("isAxyaAnnouncementVisited");

  const tourConfig = [
    {
      selector: '[data-tut="reactour__newPart"]',
      content: () => (
        <div>
          <h3>{t("newPart")}</h3>
          <p>{t("newPartDescription")}</p>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__createRfq"]',
      content: () => (
        <div>
          <h3>{t("createRfq")}</h3>
          <p>{t("createRfqDescription")}</p>
          <CloseBtn />
        </div>
      ),
    },
  ];

  const CloseBtn = () => {
    const dispatch = useDispatch();
    const tracking = useTracking();
    const profile = useSelector((state) => state.profile);

    const tourDoneClick = () => {
      dispatch(modalsActions.hideModal("isWorkSpaceTourOpen"));
      !isModalOpen && dispatch(modalsActions.showModal(true, "isAxyaProjectMngmntModalOpen"));
      // tracking close workspace tour click
      tracking.trackEvent({
        scout_category: "tour_guide",
        scout_feature_name: "tour_done",
        scout_company: profile["company"]["name"],
        scout_username: profile["user"]["name"],
        scout_user_type: profile["user"]["type"],
        scout_date: new Date(),
      });
    };

    return (
      <button class="c-tour--done-btn" onClick={() => tourDoneClick()}>
        {t("gotItButton")}
      </button>
    );
  };

  const dispatch = useDispatch();
  const tracking = useTracking();
  const isWorkSpaceTourOpen = useSelector((state) => state.modals.isWorkSpaceTourOpen);
  const profile = useSelector((state) => state.profile);
  const closeTour = () => {
    dispatch(modalsActions.hideModal("isWorkSpaceTourOpen"));
    !isModalOpen && dispatch(modalsActions.showModal(true, "isAxyaProjectMngmntModalOpen"));

    localStorage.setItem("isAxyaProjectMngmntVisited", true);
    // tracking close workspace tour click
    tracking.trackEvent({
      scout_category: "tour_guide",
      scout_feature_name: "close_workspace_tour",
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["name"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date(),
    });
  };

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const accentColor = AppTheme.palette.primary.main;
  const trackingApi = (step) => {
    tracking.trackEvent({
      scout_category: "tour_guide",
      scout_feature_name: "ongoing_workspace_tour",
      scout_company: profile["company"]["name"],
      scout_username: profile["user"]["name"],
      scout_user_type: profile["user"]["type"],
      scout_date: new Date(),
    });
  };
  return (
    <section className="c-app-tour">
      <Tour
        onRequestClose={closeTour}
        steps={tourConfig}
        isOpen={isWorkSpaceTourOpen}
        maskClassName="mask"
        className="helper"
        rounded={5}
        accentColor={accentColor}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        getCurrentStep={(step) => trackingApi(step)}
      />
    </section>
  );
};

export default WorkSpaceTour;
