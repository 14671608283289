import React from "react";
import { Grid, Theme, Typography } from "@mui/material";
import NextAxyaLogo from "@next/assets/nextLogo.png";
import { NewsfeedPost } from "../redux/types";
import { formatDate } from "helpers/formatDate";
import NewsfeedPostChip from "./newsfeed-post-chip";
import NewsfeedPostButton from "./newsfeed-post-button";
import { newsfeedPostTypeConstants } from "../redux/consts";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  imgNewBuyers: {
    width: "100%",
    margin: "auto",
  },
  imgGrid: {
    maxHeight: "220px",
    maxWidth: "220px",
    marginRight: "24px",
  },
  imgBox: {
    border: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.customBackground.light,
    borderRadius: "15px",
    overflow: "hidden",
    width: "220px",
    height: "220px",
    display: "flex",
    alightItems: "center",
  },
  title: {
    margin: "8px 0px",
    fontWeight: "bold",
  },
  mainGrid: {
    paddingRight: "24px",
    paddingLeft: "24px",
  },
  flexColumnSpaceBetween: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  flexSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  fadedTextColor: {
    color: "#00000061",
  },
  truncate: {
    display: "-webkit-box",
    "-webkit-line-clamp": 20,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: "100%",
  },
}));

type Props = {
  post: NewsfeedPost;
};

export const NewsfeedPostComponent: React.FC<Props> = ({ post }) => {
  const classes = useStyles();
  const { created_at, type, title, description, picture } = post;

  return (
    <Grid
      container
      alignItems="stretch"
      justifyContent="space-between"
      className={classes.mainGrid}
    >
      <Grid item xs={12} className={classes.imgGrid}>
        <div className={classes.imgBox}>
          <img
            alt={title}
            src={picture || NextAxyaLogo}
            className={
              type === newsfeedPostTypeConstants.NEW_BUYER ? classes.imgNewBuyers : classes.img
            }
          />
        </div>
      </Grid>
      <Grid item xs={12} className={classes.flexColumnSpaceBetween} style={{ flex: 1 }}>
        <div>
          <div className={classes.flexSpaceBetween}>
            <NewsfeedPostChip post={post} />
            <Typography className={classes.fadedTextColor}>
              {created_at && formatDate(created_at, "LLL")}
            </Typography>
          </div>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Typography paragraph className={classes.truncate}>
            {description}
          </Typography>
        </div>
        <div className={classes.flexEnd}>
          <NewsfeedPostButton post={post} />
        </div>
      </Grid>
    </Grid>
  );
};
