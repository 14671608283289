import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useStyles } from "./company-profile-full.styles";
import { Skeleton } from "@mui/material";
import { AppTheme } from "layout/AppTheme";

export const CompanyProfileFullSkeleton = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(AppTheme.breakpoints.down("lg"));

  return (
    <div>
      <div className={classes.contentBox}>
        {/* avatar */}
        <Skeleton variant="rectangular" height={92} width={92} style={{ borderRadius: 8 }} />

        {/* name */}
        <Box mt={2} display="flex" alignItems="center" flexWrap="wrap" gap="12px">
          <Skeleton variant="rectangular" height={32} width={140} />
        </Box>

        {/* summary */}
        <Box mt={2}>
          <Skeleton variant="rectangular" height={22} width="90%" />
        </Box>

        {/* features */}
        <Box mt={2} display="flex" alignItems="center" flexWrap="wrap" gap="2rem">
          {Array(3)
            .fill(true)
            .map((_, i) => (
              <Box display="flex" alignItems="center" gap="12px">
                <Skeleton variant="circular" width={20} height={22} />
                <Skeleton width={90} height={28} />
              </Box>
            ))}
        </Box>

        {/* buttons */}
        <Box
          mt={2}
          display="flex"
          alignItems="center"
          gap="12px"
          flexWrap="wrap"
          className={classes.ctaBar}
        >
          <Skeleton variant="rectangular" height={36.5} width={165} style={{ borderRadius: 8 }} />
          <Skeleton variant="rectangular" height={36.5} width={200} style={{ borderRadius: 8 }} />
          <Skeleton variant="rectangular" height={36.5} width={180} style={{ borderRadius: 8 }} />
        </Box>
      </div>

      <Box mt={3}>
        <div className={classes.contentBox}>
          {/* tab buttons */}
          <Box
            mb={2}
            display="flex"
            alignItems="center"
            gap="12px"
            width="100%"
            overflow="auto"
            pb={isMobile ? "12px" : undefined}
            position="relative"
          >
            {Array(4)
              .fill(true)
              .map(() => (
                <Skeleton
                  variant="rectangular"
                  height={36.5}
                  width="110px"
                  style={{ borderRadius: 199 }}
                />
              ))}
          </Box>

          {/* Gallery */}
          <Box
            mt={2}
            display="grid"
            gridTemplateColumns="repeat(auto-fill, minmax(min(260px, 100%), 1fr))"
            gap="12px"
          >
            {Array(3)
              .fill(true)
              .map(() => (
                <Skeleton style={{ borderRadius: 8 }} height={207} variant="rectangular" />
              ))}
          </Box>
        </div>
      </Box>
    </div>
  );
};
