import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { FreemiumDashboardCardMini } from "../../components/freemium-dashboard-card/freemium-dashboard-card-mini";
import { DashboardInformation } from "../../components/dashboard-information/dashboard-information";
import FreeBreakfastOutlinedIcon from "@mui/icons-material/FreeBreakfastOutlined";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getUser, selectCompanyUsers } from "services/profile/profile.selectors";
import { selectFavoriteSuppliers } from "@next/modules/workspace/redux/selectors";
import { CircularAvatarsList } from "@next/components/circular-avatars-list";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { InviteSupplierModal } from "@next/modules/workspace/modals/invite-supplier-modal";
import { getSupplierDirectoryLink } from "@next/utils/constantUtils";
import { FreemiumDashboardCard } from "../../components/freemium-dashboard-card/freemium-dashboard-card";
import quoteImage from "./quote.png";
import mapImage from "./map.png";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SearchIcon from "@mui/icons-material/Search";
import { AppTheme } from "layout/AppTheme";

export const FreemiumBuyerDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const favoriteSuppliers = useSelector(selectFavoriteSuppliers) || [];
  const companyUsers = useSelector(selectCompanyUsers);

  const supplierDirectoryLink = getSupplierDirectoryLink();

  useEffect(() => {
    dispatch(workspaceNextActions.fetchFavoriteSuppliersRequest({ query: "" }));
  }, []);

  const onClickInviteUser = () => {
    dispatch(
      modalsActions.showModal({
        key: SharedModalTypes.INVITE_COMPANY_USER_FORM,
      })
    );
  };

  const onClickInviteSupplier = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.INVITE_SUPPLIER_MODAL,
      })
    );
  };

  const onClickSearchForSuppliers = () => {
    window.open(supplierDirectoryLink, "_blank");
  };

  const onClickGetQuotes = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.RFQ_CREATION_LITE_DRAWER,
        data: {
          fromDashboard: true,
        },
      })
    );
  };

  return (
    <Grid container spacing={3} alignItems="center" justifyContent="center">
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DashboardInformation
          subTitle={t("freemiumDashboard:subTitle", {
            userName: user?.first_name,
          })}
          subTitle2={t("freemiumDashboard:subTitle2")}
          Icon={FreeBreakfastOutlinedIcon}
          showDate={false}
        />
      </Grid>

      <Grid item xs={12} sm={6} style={{ display: "flex", justifyContent: "flex-end" }}>
        <FreemiumDashboardCard
          Icon={<SearchIcon style={{ color: AppTheme.palette.primary.main }} />}
          title={t("freemiumDashboard:searchForSuppliersCard:title")}
          subTitle={t("freemiumDashboard:searchForSuppliersCard:subTitle")}
          onClick={onClickSearchForSuppliers}
          bgImage={mapImage}
          buttonText={t("freemiumDashboard:searchForSuppliersCard:btnText")}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FreemiumDashboardCard
          Icon={<NoteAddIcon />}
          title={t("freemiumDashboard:getQuotesCard:title")}
          subTitle={t("freemiumDashboard:getQuotesCard:subTitle")}
          onClick={onClickGetQuotes}
          bgImage={quoteImage}
          buttonText={t("freemiumDashboard:getQuotesCard:btnText")}
        />
      </Grid>

      <Grid item xs={12} sm={6} style={{ display: "flex", justifyContent: "flex-end" }}>
        <FreemiumDashboardCardMini
          title={t("freemiumDashboard:membersOnTeamMiniCard:title")}
          subTitle={t("freemiumDashboard:membersOnTeamMiniCard:subTitle")}
          leftComponent={
            <CircularAvatarsList
              size="large"
              images={companyUsers?.map((item: any) => ({
                name: item.first_name || item.email,
                url: item.picture,
                tooltip: item.first_name || item.email,
              }))}
              maxToShow={10}
            />
          }
          onClickAdd={onClickInviteUser}
        />
      </Grid>
      <Grid item xs={12} sm={6} style={{ display: "flex", justifyContent: "flex-start" }}>
        <FreemiumDashboardCardMini
          title={t("freemiumDashboard:suppliersInYourlistMiniCard:title")}
          subTitle={t("freemiumDashboard:suppliersInYourlistMiniCard:subTitle")}
          leftComponent={
            <CircularAvatarsList
              size="large"
              images={favoriteSuppliers?.map((item: any) => ({
                name: item.name,
                url: item.picture,
                tooltip: item.name,
              }))}
              maxToShow={10}
            />
          }
          onClickAdd={onClickInviteSupplier}
        />
      </Grid>

      <InviteSupplierModal />
    </Grid>
  );
};
