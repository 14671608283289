import React from "react";
import { makeStyles } from "@mui/styles";
import { Drawer } from "@mui/material";
import Inbox from "../../components/inbox/inbox-drawer.container";
import SupplierRating from "../../components/user-rating/user-rating.component";
import { getModal } from "@next/redux/modalsSlices";
import { useSelector } from "react-redux";
import { ProjectModalTypes } from "@next/modules/project/modals/types";
import { ProjectRfqDetailsModal } from "@next/modules/project/modals/project-rfq-details-modal";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { WorkspaceAskQuestionModal } from "@next/modules/workspace/modals/workspace-ask-question-modal";

interface Props {
  children: React.ReactNode;
  modals: { [key: string]: boolean };
  isDrawerOpen: boolean;
  handleOpenModal: (isOpen: boolean, modalType: string) => void;
}

enum DrawerWidth {
  Inbox = 450,
  RFQForm = 700,
  RFQDetail = 1280,
  SupplierRating = 700,
}

const AppDrawer: React.FC<Props> = ({ isDrawerOpen, modals }) => {
  const isProjectRfqDetailsModal = useSelector(getModal(ProjectModalTypes.RFQ_DETAILS_MODAL));
  const isWorkspaceAskQuestionModal = useSelector(getModal(WorkspaceModalTypes.ASK_QUESTION_MODAL));

  let drawerWidth = 0;
  if (modals.isInboxOpen) {
    drawerWidth = DrawerWidth.Inbox;
  } else if (!!isProjectRfqDetailsModal) {
    drawerWidth = DrawerWidth.RFQDetail;
  } else if (modals.isSupplierRatingDrawerOpen) {
    drawerWidth = DrawerWidth.SupplierRating;
  }

  const useStyles = makeStyles(() => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      boxShadow: "0px 6px 21px #00000033",
    },
  }));

  const classes = useStyles();

  return (
    <Drawer
      hideBackdrop
      style={{ pointerEvents: "none" }}
      PaperProps={{ style: { pointerEvents: "auto" } }}
      anchor={"right"}
      open={isDrawerOpen}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="persistent"
    >
      {modals.isInboxOpen && <Inbox />}
      {!!isProjectRfqDetailsModal && <ProjectRfqDetailsModal />}
      {!!isWorkspaceAskQuestionModal && <WorkspaceAskQuestionModal />}
      {modals.isSupplierRatingDrawerOpen && <SupplierRating />}
    </Drawer>
  );
};

export default AppDrawer;
