import { Box, alpha } from "@mui/material";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import GlobalSearch from "./components/global-search/global-search";
import { SharedModalTypes } from "./types";

import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 10000,
      display: "flex",
      justifyContent: "center",
    },
    inner: {
      backgroundColor: alpha(theme.palette.common.black, 0.8),
      boxShadow:
        "0px 109px 44px rgba(0, 0, 0, 0.01), 0px 62px 37px rgba(0, 0, 0, 0.05), 0px 27px 27px rgba(0, 0, 0, 0.09), 0px 7px 15px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
      borderRadius: 8,
      marginTop: 88,
      width: "70rem",
      height: "min-content",
    },
  })
);

const SharedGlobalSearchDialog: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const modal = useSelector(getModal(SharedModalTypes.GLOBAL_SEARCH));

  const handleClose = useCallback(() => {
    dispatch(modalsActions.closeModal(SharedModalTypes.GLOBAL_SEARCH));
  }, []);

  if (!modal) return null;

  return (
    <Box className={classes.root} onClick={handleClose}>
      <Box className={classes.inner} onClick={(e) => e.stopPropagation()}>
        <GlobalSearch handleClose={handleClose} />
      </Box>
    </Box>
  );
};

export default SharedGlobalSearchDialog;
