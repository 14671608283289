import React from "react";
import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { OrderStatus } from "@next/modules/project/components/order/types";
import CurrencyField from "components/common/fields/CurrencyField";
import { LeadTime } from "../lead-time/lead-time";
import PurchaseOrderCell from "./purchase-order-cell-v5";
import { CustomTypography } from "../custom-typography";
import { checkIsSellerCompany } from "@next/utils/checkCompanyUtils";
import { useSelector } from "react-redux";
import { frontendUrl } from "urls";
import { t } from "assets/configi18n/i18n";
import { Link } from "react-router-dom";
import { getCompany } from "services/profile/profile.selectors";
import { CompanyTypeNext } from "@next/redux/types";
import { RequestType } from "@next/modules/workspace/components/rfq-creation-form/rfq-creation.form.definitions";

const LeadTimeCell: React.FC<
  GridRenderCellParams & {
    showPOCell?: boolean;
  }
> = ({ showPOCell, ...params }) => {
  const company = useSelector(getCompany);
  const isSeller = checkIsSellerCompany(company?.type as CompanyTypeNext);

  if (params.row.status === OrderStatus.CANCELED) {
    return <CustomTypography color="secondary">n/a</CustomTypography>;
  }

  if (params.row?.request_type === RequestType.RFI) {
    return <CustomTypography color="secondary">-</CustomTypography>;
  }

  const CostAndLead = () => (
    <CustomTypography>
      <CurrencyField value={params.row.total_price} /> - <LeadTime value={params.row?.lead_time} />
    </CustomTypography>
  );

  return (
    <Box display={"flex"} flexDirection={"column"}>
      {params.row.total_price > 0 && <CostAndLead />}

      {showPOCell ? <PurchaseOrderCell {...params} /> : null}

      {!isSeller && (
        <Link
          target="_blank"
          to={`${frontendUrl.bundleView}/${params.row?.pk}`}
          style={{ lineHeight: "initial", textDecoration: "underline" }}
        >
          <Typography variant="caption">{t("workspace:seeAllQuotations")}</Typography>
        </Link>
      )}
    </Box>
  );
};

export default LeadTimeCell;
