import React from "react";
import { Drawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "./types";
import RFQCreationFormLite from "../components/rfq-creation-form-lite/rfq-creation-form-lite";

export const RFQCreationLiteDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(WorkspaceModalTypes.RFQ_CREATION_LITE_DRAWER));
  const data =
    (modal?.data as {
      fromDashboard?: boolean;
    }) || {};

  const onClose = () => {
    dispatch(modalsActions.closeModal(WorkspaceModalTypes.RFQ_CREATION_LITE_DRAWER));
  };

  return (
    <Drawer PaperProps={{ style: { width: 696 } }} anchor="right" open={!!modal} onClose={onClose}>
      {!!modal ? <RFQCreationFormLite fromDashboard={data.fromDashboard} /> : null}
    </Drawer>
  );
};
