import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import Modal from "@next/components/custom-modal";
import { WorkspaceModalTypes } from "./types";
import { Box, Button, Theme } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import { SelectSuppliersGroupAutocomplete } from "../components/select-suppliers-group-autocomplete/select-suppliers-group-autocomplete";
import { ConnectionDetails } from "@next/modules/profile/redux";
import { createStyles, makeStyles } from "@mui/styles";

export const GroupingAfterConnectionModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(WorkspaceModalTypes.GROUPING_AFTER_CONNECTION_MODAL));

  const handleModalClose = useCallback(() => {
    dispatch(modalsActions.closeModal(WorkspaceModalTypes.GROUPING_AFTER_CONNECTION_MODAL));
  }, [dispatch]);

  if (!modal) return null;
  return (
    <Modal
      handleModalClose={handleModalClose}
      modalState={{
        modalHeader: t("workspaceNext:groupingAfterConnectionModal:title"),
        modalBody: <AddSupplierToGroup onClose={handleModalClose} data={modal.data} />,
      }}
      isModalOpen={true}
    />
  );
};

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    modalBody: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: theme.spacing(2),
    },
    supplierInfo: {
      display: "flex",
      alignItems: "center",
      gap: 12,
    },
    border: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 8,
    },
    actionButtons: {
      display: "flex",
      justifyContent: "flex-end",
      gap: 12,
    },
  })
);
interface AddSupplierToGroupProps {
  onClose: () => void;
  data: ConnectionDetails;
}

const AddSupplierToGroup = ({ onClose, data }: AddSupplierToGroupProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.modalBody}>
      <Box className={classes.supplierInfo}>
        <img src={data.from_company.picture} width={40} className={classes.border} />
        <CustomTypography variant="body1">{data.from_company.name}</CustomTypography>
      </Box>
      <CustomTypography variant="body2">
        {t("workspaceNext:groupingAfterConnectionModal:content")}
      </CustomTypography>
      <Box width="100%" className={classes.border} padding={"8px"}>
        <SelectSuppliersGroupAutocomplete
          favoriteSupplierPk={data.from_company.id!}
          initialValue={[]}
        />
      </Box>
      <Box className={classes.actionButtons}>
        {/* <Button onClick={onClose}>
          {t("workspaceNext:groupingAfterConnectionModal:skip")}
        </Button> */}
        <Button onClick={onClose} variant="contained" color="primary">
          {t("workspaceNext:groupingAfterConnectionModal:done")}
        </Button>
      </Box>
    </Box>
  );
};
