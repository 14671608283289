import React from "react";
// Urls
import { frontendUrl } from "urls";
// Layout
import AppLayout from "layout/AppLayout";
// Components
import { PayMain } from "@next/modules/pay/pages/pay-main";
// Types
import { CustomRoute } from "./types";

const PAY_ROUTES: CustomRoute[] = [
  {
    layout: (props) => <AppLayout isHideNav {...props} />,
    path: `${frontendUrl.axyapay}`,
    key: "axya-pay-onboarding",
    exact: true,
    component: () => <PayMain />,
  },
];

export default PAY_ROUTES;
