import { GenericObject, Subscription } from "../../redux";

export const getInitialSubscriptionState = (subscriptionsList: Subscription[]) => {
  const initialSubscriptionState: Subscription | GenericObject = {};

  subscriptionsList &&
    subscriptionsList.length > 0 &&
    subscriptionsList.forEach((subscription: Subscription) => {
      initialSubscriptionState[subscription.name] = subscription.is_user_subscribed;
    });

  return initialSubscriptionState;
};
