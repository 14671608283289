export const socketConstants = {
  RECEIVE_REQUEST: "RECEIVE_REQUEST",
  RECEIVE_SUCCESS: "RECEIVE_SUCCESS",
  RECEIVE_FAILURE: "RECEIVE_FAILURE",

  EXCHANGE_REQUEST: "EXCHANGE_REQUEST",
  EXCHANGE_SUCCESS: "EXCHANGE_SUCCESS",
  EXCHANGE_FAILURE: "EXCHANGE_FAILURE",

  CONNECT_REQUEST: "CONNECT_REQUEST",
  CONNECT_SUCCESS: "CONNECT_SUCCESS",
  CONNECT_FAILURE: "CONNECT_FAILURE",

  JOIN_REQUEST: "JOIN_REQUEST",
  JON_SUCCESS: "JON_SUCCESS",
  JOIN_FAILURE: "JOIN_FAILURE",

  LISTEN_FOR_UPDATES_REQUEST: "LISTEN_FOR_UPDATES_REQUEST",
  LISTEN_FOR_UPDATES_SUCCESS: "LISTEN_FOR_UPDATES_SUCCESS",
  LISTEN_FOR_UPDATES_FAILURE: "LISTEN_FOR_UPDATES_FAILURE",

  RECEIVE_UPDATES_REQUEST: "RECEIVE_UPDATES_REQUEST",
  RECEIVE_UPDATES_SUCCESS: "RECEIVE_UPDATES_SUCCESS",
  RECEIVE_UPDATES_FAILURE: "RECEIVE_UPDATES_FAILURE",
};
