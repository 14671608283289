import React from "react";

import {
  GridCellParams,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro-v5";
import { formatDate } from "helpers/formatDate";
import { t } from "assets/configi18n/i18n";
import { MyPartsTableField } from "./my-parts-table.types";
import LinkCell from "@next/components/table-cells/link-cell-v5";
import IconButtonCell from "@next/components/table-cells/icon-button-cell-v5";
import TextCell from "@next/components/table-cells/text-cell-v5";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { formatProcessesValue } from "./my-parts-table.utils";
import { Box, Checkbox, Chip, Typography, alpha, styled } from "@mui/material";
import { AppTheme } from "layout/AppTheme";
import { frontendUrl } from "urls";
import { ProjectCompanyPart } from "@next/modules/project/redux";
import { getIdsByData } from "@next/utils/dataGridUtils-v5";
import { getSelectedLanguage } from "@next/utils/browserUtils";
import { checkIsColumnHidden } from "@next/modules/project/components/part/project.part.table.utils";
import { CustomTypography } from "@next/components/custom-typography";
import CurrencyField from "components/common/fields/CurrencyField";

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
};

const AttachmentChip = styled(Chip)({
  color: AppTheme.palette.primary.main,
  backgroundColor: alpha(AppTheme.palette.primary.main, 0.1),
});

const QuantityCell: React.FC<GridCellParams> = ({ value }) => {
  return <CustomTypography variant="caption">{value}</CustomTypography>;
};

const UnitPriceCell: React.FC<GridCellParams> = (params) => {
  return params.value ? (
    <CustomTypography variant="caption">
      <CurrencyField value={params.value} />
    </CustomTypography>
  ) : (
    <CustomTypography variant="caption" className="not-available">
      n/a
    </CustomTypography>
  );
};

const AttachmentChipsCell: React.FC<GridRenderCellParams> = ({ row }) => {
  return (
    <Box width="100%">
      {row?.part_uploads_list?.length > 0 ? (
        <AttachmentChip
          label={t("workspaceNext:myParts:table:row:attachmentsWithCount", {
            count: row?.part_uploads_list?.length,
          })}
          size="small"
        />
      ) : null}
    </Box>
  );
};

export const getMyPartsTableColumns = (
  partExtraFields: any = [],
  quotationExtraFields: any = [],
  pmPartsHiddenColumns: string[],
  isReadOnly: boolean
) => {
  if (isReadOnly) {
    defaultColumnConfig.editable = !isReadOnly;
  }

  const _columns: GridColumns = [
    {
      ...defaultColumnConfig,
      field: MyPartsTableField.NAME,
      hide: checkIsColumnHidden(pmPartsHiddenColumns, MyPartsTableField.NAME),
      width: 150,
      minWidth: 110,
      headerName: t("project:table:header:partNumber"),
      renderCell: TextCell,
    },
    {
      ...defaultColumnConfig,
      field: MyPartsTableField.FILES,
      hide: checkIsColumnHidden(pmPartsHiddenColumns, MyPartsTableField.FILES),
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerName: t("project:table:header:files"),
      renderCell: AttachmentChipsCell,
    },
    {
      ...defaultColumnConfig,
      field: MyPartsTableField.QUANTITY,
      hide: checkIsColumnHidden(pmPartsHiddenColumns, MyPartsTableField.QUANTITY),
      minWidth: 100,
      flex: 1,
      align: "right",
      headerName: t("project:table:header:quantity"),
      renderCell: QuantityCell,
    },
    {
      ...defaultColumnConfig,
      field: MyPartsTableField.ADDITIONAL_DETAILS,
      hide: checkIsColumnHidden(pmPartsHiddenColumns, MyPartsTableField.ADDITIONAL_DETAILS),
      minWidth: 100,
      width: 150,
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerName: t("project:table:header:description"),
      renderCell: TextCell,
    },
    {
      ...defaultColumnConfig,
      field: MyPartsTableField.DISPLAY_NAME,
      minWidth: 80,
      width: 200,
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerName: t("project:table:header:rfq"),
      renderCell: (params) => (
        <LinkCell
          {...params}
          to={`${frontendUrl.bundleView}/${params.row?.rfq?.pk}`}
          label={params.row?.rfq?.display_name}
        />
      ),
    },
    {
      ...defaultColumnConfig,
      field: MyPartsTableField.MATERIAL,
      hide: checkIsColumnHidden(pmPartsHiddenColumns, MyPartsTableField.MATERIAL),
      minWidth: 80,
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerName: t("project:table:header:material"),
      renderCell: TextCell,
    },
    {
      ...defaultColumnConfig,
      field: MyPartsTableField.PROCESSES,
      hide: checkIsColumnHidden(pmPartsHiddenColumns, MyPartsTableField.PROCESSES),
      minWidth: 80,
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerName: t("project:table:header:process"),
      renderCell: (params) => <TextCell {...params} value={formatProcessesValue(params.value)} />,
    },
  ];

  if (partExtraFields?.length > 0) {
    partExtraFields.forEach((field: any) => {
      const selectedLanguage = getSelectedLanguage();

      _columns.push({
        ...defaultColumnConfig,
        disableExport: true,

        editable: !isReadOnly,
        minWidth: field.width | 150,
        flex: 1,
        field: `extra_fields.${field.slug}`,
        hide: checkIsColumnHidden(pmPartsHiddenColumns, field.slug),
        headerName: field.display_name[selectedLanguage],
        type: field.type,
        valueOptions: field.valueOptions,
        valueGetter: (_params: GridValueGetterParams) => {
          const field_parts = _params.field.split(".");
          return _params.row.extra_fields?.[field_parts[1]];
        },
        renderCell: TextCell,
      });
    });
  }

  _columns.push(
    {
      ...defaultColumnConfig,
      field: MyPartsTableField.PRICE,
      hide: checkIsColumnHidden(pmPartsHiddenColumns, MyPartsTableField.PRICE),
      minWidth: 150,
      flex: 1,
      align: "right",
      headerAlign: "right",
      headerName: t("project:table:header:priceUnit"),
      renderCell: UnitPriceCell,
    },
    {
      ...defaultColumnConfig,
      field: MyPartsTableField.AWARDED_SUPPLIER_NAME,
      flex: 1,
      minWidth: 140,
      align: "left",
      headerAlign: "left",
      headerName: t("workspaceNext:myParts:table:header:winner"),
      renderCell: TextCell,
    },
    {
      ...defaultColumnConfig,
      field: MyPartsTableField.QUOTATION_SUBMISSION_DATE,
      flex: 1,
      minWidth: 130,
      align: "left",
      headerAlign: "left",
      headerName: t("workspaceNext:myParts:table:header:submissionDate"),
      renderCell: (params) => <Typography variant="body2">{params.value}</Typography>,
      valueGetter: (params: GridValueGetterParams) => {
        return formatDate(params.value);
      },
    }
  );
  if (quotationExtraFields?.length > 0) {
    quotationExtraFields.forEach((field: any) => {
      const selectedLanguage = getSelectedLanguage();

      _columns.push({
        ...defaultColumnConfig,
        disableExport: true,
        editable: !isReadOnly,
        minWidth: field.width | 150,
        flex: 1,
        field: `quotation_extra_fields.${field.slug}`,
        hide: checkIsColumnHidden(pmPartsHiddenColumns, field.slug),
        headerName: field.display_name[selectedLanguage],
        type: field.type,
        valueOptions: field.valueOptions,
        valueGetter: (_params: GridValueGetterParams) => {
          const field_parts = _params.field.split(".");
          return _params.row.quotation_extra_fields?.[field_parts[1]];
        },
        renderCell: TextCell,
      });
    });
  }

  _columns.push(
    {
      ...defaultColumnConfig,
      hide: isReadOnly,
      field: MyPartsTableField.EDIT,
      headerName: " ",
      width: 6,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <IconButtonCell {...params} Icon={EditOutlinedIcon} />,
      resizable: false,
      headerClassName: "c-hide-column-separator",
    },
    {
      ...defaultColumnConfig,
      field: MyPartsTableField.CONTEXT_MENU,
      hide: isReadOnly,
      headerName: " ",
      width: 6,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <IconButtonCell {...params} />,
      resizable: false,
      headerClassName: "c-hide-column-separator",
    }
  );

  return _columns;
};

export const getMyPartCheckboxColumn = (
  onCheckboxChange: (params: {
    row?: GridRenderCellParams["row"];
    selectAll?: boolean;
  }) => () => void,
  selectedPartRows: ProjectCompanyPart[],
  loading?: boolean
) => {
  const selectedPartRowsIds = getIdsByData(selectedPartRows, "pk");

  const checkboxColumn: GridColumns = [
    {
      field: MyPartsTableField.SELECTED_CHECKBOX,
      headerName: " ",
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      sortable: false,
      hideSortIcons: true,
      width: 40,
      renderHeader: () => {
        return (
          <Checkbox
            onChange={onCheckboxChange({ selectAll: true })}
            checked={false}
            color="primary"
            indeterminate={selectedPartRows.length > 0}
            disabled={loading}
          />
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Checkbox
            onChange={onCheckboxChange({ row: params?.row })}
            checked={selectedPartRowsIds.includes(params?.row?.pk)}
            color="primary"
            disabled={loading}
          />
        );
      },
    },
  ];

  return checkboxColumn;
};
