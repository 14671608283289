import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Announcement, AnnouncementsState } from "./types";

const initialState: AnnouncementsState = {
  announcements: [],
  selectedAnnouncement: null,
  loading: false,
  error: null,
  announcementLoading: false,
  announcementError: null,
};

const announcementsSlice = createSlice({
  name: "announcements",
  initialState,
  reducers: {
    fetchAnnouncementsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchAnnouncementsSuccess: (state, action: PayloadAction<Announcement[]>) => {
      state.announcements = action.payload;
      state.loading = false;
    },
    fetchAnnouncementsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getAnnouncementBySlugRequest: (state, action: PayloadAction<string>) => {
      state.announcementLoading = true;
      state.announcementError = null;
    },
    getAnnouncementBySlugSuccess: (state, action: PayloadAction<Announcement>) => {
      state.selectedAnnouncement = action.payload;
      state.announcementLoading = false;
    },
    getAnnouncementBySlugFailure: (state, action: PayloadAction<string>) => {
      state.announcementLoading = false;
      state.announcementError = action.payload;
    },
  },
});

export const {
  fetchAnnouncementsRequest,
  fetchAnnouncementsSuccess,
  fetchAnnouncementsFailure,
  getAnnouncementBySlugRequest,
  getAnnouncementBySlugSuccess,
  getAnnouncementBySlugFailure,
} = announcementsSlice.actions;

export default announcementsSlice.reducer;
