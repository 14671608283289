import React from "react";
import { ForwardToInboxRounded } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { AppTheme } from "layout/AppTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { TopicMessageFooterType } from "@next/modules/workspace/redux";

type Props = {
  footerMessage: string;
  footerType: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    outlookSyncStyle: {
      color: "#9e9e9e",
      height: "16px",
      width: "16px",
    },
  })
);

export const TopicMessageFooter: React.FC<Props> = ({ footerMessage, footerType }) => {
  const classes = useStyles();
  switch (footerType) {
    case TopicMessageFooterType.OUTLOOK_SYNC:
      return (
        <>
          <ForwardToInboxRounded className={classes.outlookSyncStyle} />
          <Typography
            variant="caption"
            style={{
              color: AppTheme.palette.text.secondary,
              fontStyle: "italic",
            }}
          >
            {footerMessage}
          </Typography>
        </>
      );
    default:
      return null;
  }
};
