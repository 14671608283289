import { t } from "assets/configi18n/i18n";
import * as Yup from "yup";
import { oneLetterOneNumber } from "helpers/formRegex";
import { SignupFormValues } from "./sign-up";

export const signUpFormValidations = (fromPortal?: boolean) =>
  Yup.object().shape({
    first_name: Yup.string().required(t("userOnboarding:firstNameRequired")),
    last_name: Yup.string().required(t("userOnboarding:lastNameRequired")),
    email: Yup.string()
      .required(t("userOnboarding:anEmailAddressIsRequired"))
      .email(t("userOnboarding:emailAddressIsInvalid")),

    password1: Yup.string()
      .required(t("userOnboarding:aPasswordIsRequired"))
      .min(8, t("userOnboarding:passwordLonger"))
      .matches(oneLetterOneNumber, {
        message: t("userOnboarding:passwordRequiresValue"),
        excludeEmptyString: true,
      }),
    how: !fromPortal
      ? Yup.string()
          .required(t("common:error:thisFieldIsRequired"))
          .max(250, t("common:validation:maxCharacters", { limit: 250 }))
      : Yup.string(),
  });

export const signUpFormInitialValues = ({
  first_name,
  last_name,
  email,
}: Partial<SignupFormValues>): SignupFormValues => ({
  first_name: first_name || "",
  last_name: last_name || "",
  email: email || "",
  password1: "",
  how: "",
});
