import React, { useState } from "react";
import { IStarRatingProps } from "../../../services/rating";

// star rating component
const Star = ({ marked, starId }: any) => {
  return (
    <span className={marked ? "c-star-filled star" : "star"} data-star-id={starId} role="button">
      {marked ? "\u2605" : "\u2606"}
    </span>
  );
};

const StarRating: React.FC<IStarRatingProps> = ({
  value,
  handleOnChange,
  id,
}: IStarRatingProps): React.ReactElement => {
  // selected rating value on star hover for each rating criteria
  const [ratingSelection, setRatingSelection] = useState<any>({});

  // select star when user hover over star and set back to null on mouseout
  const hoverOver = (event: any, id: number) => {
    let val = 0;
    if (event && event.target && event.target.getAttribute("data-star-id"))
      val = event.target.getAttribute("data-star-id");
    setRatingSelection({
      ...ratingSelection,
      [id]: val,
    });
  };

  return (
    <div
      className="c-star-rating"
      onMouseOut={() => hoverOver(null, id)}
      onClick={(e: any) => {
        const ratingValues = e?.target?.getAttribute("data-star-id") || value;
        handleOnChange(ratingValues, id);
      }}
      onMouseOver={(e) => hoverOver(e, id)}
    >
      {Array.from(
        {
          length: 5,
        },
        (_v, i) => (
          <Star
            starId={i + 1}
            key={`star_${i + 1}`}
            marked={ratingSelection[id] ? ratingSelection[id] >= i + 1 : value >= i + 1}
          />
        )
      )}
    </div>
  );
};

export default StarRating;
