import { Box } from "@mui/material";

import {
  GridCellParams,
  GridRowModel,
  GridRowParams,
  GridSelectionModel,
  MuiEvent,
} from "@mui/x-data-grid-pro-v5";

import React, { useCallback, useEffect, useState } from "react";

import { useProjectsTableData } from "./projects-table.hooks";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { selectProjectsCount, selectProjectsLoading } from "@next/modules/project/redux/selectors";
import { ProjectsField } from "./types";
import { history } from "helpers/history";
import { ProjectType } from "@next/modules/project/redux";
import { PAGINATION_PAGE_SIZE } from "@next/constants";
import { getCompany, getUser } from "services/profile/profile.selectors";
import { ProjectsTableToolbar } from "./projects-table.toolbar";
import { debounce } from "lodash";
import { ProjectsTableRowMenu } from "./projects-table-row-menu";
import {
  ProjectCreateRenameModal,
  ProjectCreateRenameModalProps,
} from "../../modals/project-create-rename-modal";
import { modalsActions } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "../../modals/types";
import { ProjectCreateRenameFormVariant } from "./project-create-rename-form/project-create-rename-form";
import { useTableSavedQueryPagination } from "@next/hooks/useTableSavedQueryPagination";
import { makeStyles } from "@mui/styles";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";
import { ProjectDeleteModal } from "../../modals/project-delete-modal";

const useStyles = makeStyles({
  root: {
    borderLeft: 0,
    borderRight: 0,
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: 0,
    },
    "& .MuiDataGrid-main": {
      borderTop: "solid 1px rgba(224, 224, 224, 1)",
    },
  },
  row: {
    cursor: "pointer",
  },
  columnHeader: {
    background: "none",
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
});

export const PROJECT_TABLE_NAVIGATION_KEY = "PROJECT_TABLE_NAVIGATION";

export const ProjectsTable = () => {
  const classes = useStyles();
  const tracking = useTracking();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [contextMenuActiveProject, setContextMenuActiveProject] = useState<ProjectType>();

  const projectsCount = useSelector(selectProjectsCount);
  const projectsLoading = useSelector(selectProjectsLoading);
  const user = useSelector(getUser);
  const company = useSelector(getCompany);

  const { savedTableQueryPagination, setSavedTableQueryPagination, onPageChangeTable } =
    useTableSavedQueryPagination(PROJECT_TABLE_NAVIGATION_KEY);
  const currentPage = savedTableQueryPagination?.p || 1;

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [projectsDataParams, setProjectsDataParams] = useState<{
    currentPage: number;
    debouncedSearchValue: string;
  }>({ currentPage, debouncedSearchValue: "" });

  useEffect(() => {
    setProjectsDataParams((p) => ({ ...p, currentPage }));
  }, [currentPage]);

  const { gridData, setGridData } = useProjectsTableData(projectsDataParams);

  const onClickCreateNewProject = () => {
    dispatch(
      modalsActions.showModal({
        key: ProjectModalTypes.CREATE_RENAME_MODAL,
        data: {
          projectCode: contextMenuActiveProject?.code,
          variant: ProjectCreateRenameFormVariant.CREATE,
        } as ProjectCreateRenameModalProps,
      })
    );

    tracking.trackEvent({
      scout_category: "project_management_analytics",
      scout_feature_name: "create_new_project",
      scout_company: company?.name,
      scout_username: user?.username,
      scout_user_type: user?.type,
      scout_date: new Date(),
    });
  };

  const onCellKeyDown = (params: GridCellParams, event: MuiEvent<React.KeyboardEvent<Element>>) => {
    if (event.key === "Escape") {
      setGridData({
        ...gridData,
        rows: gridData.rows.filter((item) => item.code !== params?.id),
      });
    }
  };

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent<Element, MouseEvent>>
  ) => {
    if (params.field === "__check__") {
      event.stopPropagation();
      return;
    }
    setContextMenuActiveProject(params?.row as ProjectType);

    switch (params.field) {
      case ProjectsField.EDIT_BUTTON:
        setAnchorEl(event?.currentTarget as HTMLElement);
        break;
      default:
        setSavedTableQueryPagination({
          ...savedTableQueryPagination,
          lsr: params?.row?.code,
        });
        history.push(`/projects/${params?.row.code}`);
    }
  };

  const getRowClassName = (params: GridRowParams) =>
    `c-cursor-pointer c-highlighted-row--${params.id === savedTableQueryPagination?.lsr}`;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchValue = useCallback(
    debounce(
      (value) =>
        setProjectsDataParams({
          currentPage: 1,
          debouncedSearchValue: value,
        }),
      1000
    ),
    []
  );

  return (
    <Box className="c-projects-table">
      <Box className="c-projects-table__container">
        <DataGridProV5
          autoHeight
          pagination
          headerHeight={40}
          disableSelectionOnClick={true}
          autoPageSize
          checkboxSelection={true}
          hideFooterSelectedRowCount
          loading={projectsLoading}
          pageSize={PAGINATION_PAGE_SIZE}
          rowCount={projectsCount}
          paginationMode="server"
          page={currentPage - 1}
          onPageChange={onPageChangeTable}
          selectionModel={selectionModel}
          onSelectionModelChange={setSelectionModel}
          rows={gridData?.rows || []}
          getRowClassName={getRowClassName}
          columns={gridData?.columns || []}
          getRowId={(row: GridRowModel) => row.code}
          onCellKeyDown={onCellKeyDown}
          onCellClick={onCellClick}
          classes={classes}
          components={{
            // According to meeting at 18.01.2022 we disabled for now.
            Toolbar: ProjectsTableToolbar,
            //
          }}
          componentsProps={{
            toolbar: {
              selectionModel: selectionModel,
              setSelectionModel: setSelectionModel,
              searchValue: searchValue,
              setSearchValue: (value: any) => {
                setSearchValue(value);
                debounceSetSearchValue(value);
              },
              clearSearch: () => {
                setSearchValue("");
                debounceSetSearchValue("");
              },
              onClickCreateNewProject,
            },
          }}
        />
      </Box>

      <ProjectsTableRowMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        projectDetails={contextMenuActiveProject}
      />

      <ProjectCreateRenameModal />
      <ProjectDeleteModal />
    </Box>
  );
};
