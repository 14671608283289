import React from "react";
import { Box, Button, DialogActions, TextField } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import {
  TCancelRfqFormState,
  cancelRfqFormState,
  cancelRfqFormValidationSchema,
} from "./shared-cancel-rfq-form.definitions";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { GenericTextField } from "components/generic-text-field/generic-text-field.component";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";

const SharedCancelRfqFormBase: React.FC<FormikProps<TCancelRfqFormState>> = ({
  handleSubmit,
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const { rfqCancellationReason } = values;
  const cancelOptions = useSelector(
    (state: RootState) => state.partConfiguration.rfqCancellationRelasons
  );

  const isNoQuoteSelected = rfqCancellationReason?.reason !== t("quotation:noQuote");

  return (
    <Form onSubmit={handleSubmit}>
      <Autocomplete
        value={rfqCancellationReason?.pk}
        onChange={(_: any, newValue: any) => {
          setFieldValue("rfqCancellationReason", newValue);

          setFieldValue(
            "cancellationNote",
            newValue?.reason === t("quotation:noQuote") ? t("quotation:noQuote") : ""
          );
        }}
        options={cancelOptions}
        getOptionLabel={(option: any) => (option.reason ? option.reason || "" : option || "")}
        disableClearable
        renderInput={(params: any) => (
          <TextField {...params} label={t("workspace:cancellationReason")} variant="outlined" />
        )}
      />
      {isNoQuoteSelected && (
        <div className="c-shared-cancel-rfq--text-field">
          <GenericTextField
            label={t("workspace:cancelationFeedbackForSupplier")}
            name="cancellationNote"
            placeholder={""}
            onChange={handleChange}
            handleOnBlur={handleBlur}
            color="primary"
            rows="5"
            disabled={false}
            InputProps={null}
            error={touched.cancellationNote && Boolean(errors.cancellationNote)}
          />
        </div>
      )}
      <DialogActions className="c-shared-cancel-rfq--action c-rfq-action">
        <Button
          autoFocus
          type="submit"
          size="large"
          color="primary"
          variant="contained"
          className="c-shared-cancel-rfq--submit c-rfq-submit"
          disabled={!values}
        >
          {t("workspace:submit")}
        </Button>
      </DialogActions>
    </Form>
  );
};

type SharedCancelRfqFormProps = {
  activeRfqPk: number;
  refetchData: boolean;
};

const SharedCancelRfqForm: React.FC<SharedCancelRfqFormProps> = ({ activeRfqPk, refetchData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = (values: TCancelRfqFormState) => {
    dispatch(
      modalsActions.showModal({
        key: SharedModalTypes.CANCEL_RFQ_CONFIRMATION_MODAL,
        data: { ...values, rfqPk: activeRfqPk, refetchData },
      })
    );
  };

  return (
    <Box className="c-shared-cancel-rfq">
      <Box className="c-shared-cancel-rfq--helper-text">{t("workspace:cancelRFQHelperText")}</Box>
      <Formik
        initialValues={cancelRfqFormState}
        validationSchema={cancelRfqFormValidationSchema}
        onSubmit={handleSubmit}
        render={(props) => <SharedCancelRfqFormBase {...props} />}
      />
    </Box>
  );
};

export default SharedCancelRfqForm;
