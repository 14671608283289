import React from "react";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import * as S from "./screen-banner.styled";

export const ScreenBanner: React.FC = () => {
  return (
    <S.RootBox>
      <S.StyledBox>
        <DesktopWindowsOutlinedIcon sx={{ color: "white" }} />

        <S.StyledTitleTypography variant="h6">Best Viewed on Desktop</S.StyledTitleTypography>

        <S.StyledTypography variant="body2">
          For the best experience, please access this feature on a desktop. Some functionalities may
          not be available on mobile
        </S.StyledTypography>
      </S.StyledBox>
    </S.RootBox>
  );
};
