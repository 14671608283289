import { IconButton, Theme } from "@mui/material";
import React from "react";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { ContactSupportPopover } from "../contact-support-popover/contact-support-popover";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      backgroundColor: theme.palette.grey[300],
      borderRadius: "100%",
      padding: "8px",
      cursor: "pointer",
    },
  })
);

type Props = {
  noBg?: boolean;
};

export const HelpToggle: React.FC<Props> = ({ noBg }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonJSX = (
    <IconButton onClick={noBg ? handleClick : undefined} size="large">
      <HelpOutlineOutlinedIcon />
    </IconButton>
  );

  return (
    <div>
      {noBg ? (
        buttonJSX
      ) : (
        <div className={classes.iconButton} onClick={handleClick}>
          {buttonJSX}
        </div>
      )}
      <ContactSupportPopover anchorEl={anchorEl} onClose={handleClose} />
    </div>
  );
};
