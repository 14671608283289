import { RootState } from "store";

export const selectSubscriptionList = (state: RootState) => state.profileNext.subscriptionList;

export const selectCompanyProfile = (state: RootState) => state.profileNext.companyProfile;
export const selectCompanyProfileExternal = (state: RootState) =>
  state.profileNext.companyProfileExternal;
export const selectCompanyProfileLoading = (state: RootState) =>
  state.profileNext.companyProfileLoading;
export const selectCompanyProfileError = (state: RootState) =>
  state.profileNext.companyProfileError;

export const selectDeleteCompanyFileReferencesLoading = (state: RootState) =>
  state.profileNext.deleteCompanyFileReferencesLoading;
export const selectUpdateCompanyFileReferencesLoading = (state: RootState) =>
  state.profileNext.updateCompanyFileReferencesLoading;

export const selectUpdateUserProfileLoading = (state: RootState) =>
  state.profileNext.updateUserProfileLoading;
export const selectUpdateUserPasswordLoading = (state: RootState) =>
  state.profileNext.updateUserPasswordLoading;

export const selectUpdateCompanyProfileError = (state: RootState) =>
  state.profileNext.updateCompanyProfileError;

export const selectUpdate2FAAuthResult = (state: RootState) =>
  state.profileNext.update2FAAuthResult;
export const selectUpdate2FAAuthLoading = (state: RootState) =>
  state.profileNext.update2FAAuthLoading;

export const selectFetchUserActive2FAsResult = (state: RootState) =>
  state.profileNext.fetchUserActive2FAsResult;

export const selectInviteCompanyUserLoading = (state: RootState) =>
  state.profileNext.inviteCompanyUserLoading;

export const selectUploadCompanyFileDocumentsLoading = (state: RootState) =>
  state.profileNext.uploadCompanyFileDocumentsLoading;
export const selectDeleteCompanyFileDocumentsLoading = (state: RootState) =>
  state.profileNext.deleteCompanyFileDocumentsLoading;

export const selectFetchPublicRequestLoading = (state: RootState) =>
  state.profileNext.fetchPublicRequestLoading;
export const selectPublicRequest = (state: RootState) => state.profileNext.publicRequest;

export const selectSendBuyerProfileMessageLoading = (state: RootState) =>
  state.profileNext.sendBuyerProfileMessageLoading;

export const selectSendDiscoveryLoading = (state: RootState) =>
  state.profileNext.sendDiscoveryLoading;

export const selectSupplierInvitationsLoading = (state: RootState) =>
  state.profileNext.fetchSupplierInvitationsLoading;
export const selectSupplierInvitations = (state: RootState) =>
  state.profileNext.fetchSupplierInvitationsResult;
export const selectSupplierInvitationsError = (state: RootState) =>
  state.profileNext.fetchSupplierInvitationsError;

export const selectInvitationDetailsLoading = (state: RootState) =>
  state.profileNext.fetchInvitationDetailsLoading;
export const selectInvitationDetails = (state: RootState) =>
  state.profileNext.fetchInvitationDetailsResult;
export const selectInvitationDetailsError = (state: RootState) =>
  state.profileNext.fetchInvitationDetailsError;

export const selectApproveInvitationLoading = (state: RootState) =>
  state.profileNext.approveInvitationLoading;

export const selectDeleteInvitationLoading = (state: RootState) =>
  state.profileNext.deleteInvitationLoading;

export const selectUpdateMemberTeamPermissionLoading = (state: RootState) =>
  state.profileNext.updateMemberTeamPermissionLoading;

export const projectSelectors = {
  selectSubscriptionList,
  selectCompanyProfile,
  selectCompanyProfileLoading,
  selectCompanyProfileError,
  selectUpdateCompanyFileReferencesLoading,
  selectDeleteCompanyFileReferencesLoading,
  selectUpdateUserProfileLoading,
  selectUpdateUserPasswordLoading,
  selectUpdateCompanyProfileError,
  selectUpdate2FAAuthResult,
  selectUpdate2FAAuthLoading,
  selectFetchUserActive2FAsResult,
  selectUploadCompanyFileDocumentsLoading,
  selectDeleteCompanyFileDocumentsLoading,
  selectSendBuyerProfileMessageLoading,
  selectSendDiscoveryLoading,
  selectSupplierInvitationsLoading,
  selectSupplierInvitations,
  selectSupplierInvitationsError,
  selectInvitationDetailsLoading,
  selectInvitationDetails,
  selectInvitationDetailsError,
};
