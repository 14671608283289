import React from "react";

import { Avatar, Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { CustomTypography } from "@next/components/custom-typography";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import defaultPartImg from "assets/img/default-part-img.png";

const defaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
};

const Cell: React.FC<GridRenderCellParams> = ({ value }) => {
  return (
    <Box display="flex" alignItems="center">
      <Avatar
        variant="square"
        style={{ width: "64px", height: "64px", borderRadius: "8px" }}
        src={defaultPartImg}
      />

      <Box ml="12px">
        <CustomTypography variant="body2">{value}</CustomTypography>
      </Box>

      <Box position="absolute" right="12px">
        <ArrowForwardOutlinedIcon />
      </Box>
    </Box>
  );
};

export const getSellerDashboardBuyerProfilePastJobsTableColumns = () => {
  const _columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "name",
      flex: 1,
      renderCell: Cell,
    },
  ];

  return _columns;
};
