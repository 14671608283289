import React from "react";
import { Box, Theme, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { AttachFileList } from "@next/components/attach-file/attach-file-list";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      marginTop: theme.spacing(1.5),
      whiteSpace: "pre-line",
    },
    discrepancies: {
      marginTop: theme.spacing(1.5),
    },
    translatedText: {
      color: theme.palette.text.secondary,
    },
    errorText: {
      color: theme.palette.error.main,
    },
    headingText: {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.dark,
      borderRadius: "12px",
      display: "inline-block",
      padding: "2px 8px",
    },
    list: {
      listStyleType: "none",
      paddingLeft: 0,
    },
    listItem: {
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(1),
    },
    dataGrid: {
      marginTop: 0,
      color: theme.palette.text.secondary,
      border: "none",
      "& .MuiDataGrid-root": {
        border: "none",
      },
      "& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders": {
        borderBottom: "none",
      },
      "& .error": {
        color: theme.palette.error.main,
      },
    },
  })
);

type Props = {
  files: any[];
  data: {
    missing: Array<{
      item_name: string;
    }>;
    discrepancies: Array<{
      id: string;
      absolute_difference: number;
      erp_item_id: string;
      is_price_different: boolean;
      is_quantity_different: boolean;
      item_name: string;
      price_pdf: number;
      price_po: number;
      quantity_pdf: number;
      quantity_po: number;
      relative_difference: number;
      supplier_item_id: string;
    }>;
  };
};

const PoAcknowledgementFailureEvent: React.FC<Props> = ({ files, data }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { missing, discrepancies } = data;
  const gridHeight = discrepancies.length * 52 + 56;

  const columns: GridColDef[] = [
    {
      field: "item_name",
      headerName: t("orders:autoPoAcknowledgement:partNumber"),
      flex: 1,
    },
    {
      field: "quantity_po",
      headerName: t("orders:autoPoAcknowledgement:quantity"),
      flex: 1,
    },
    {
      field: "quantity_pdf",
      headerName: t("orders:autoPoAcknowledgement:confirmedQty"),
      flex: 1,
      cellClassName: (params) => (params.row.is_quantity_different ? "error" : ""),
    },
    {
      field: "price_po",
      headerName: t("orders:autoPoAcknowledgement:unitPrice"),
      flex: 1,
    },
    {
      field: "price_pdf",
      headerName: t("orders:autoPoAcknowledgement:confirmedPrice"),
      flex: 1,
      cellClassName: (params) => (params.row.is_price_different ? "error" : ""),
    },
  ];

  return (
    <Box>
      <div className={classes.message}>
        <AttachFileList files={files} />
      </div>
      <div className={classes.message}>
        <Typography variant="body2" className={classes.translatedText}>
          {t("inbox:autoPoAcknowledgementFailureMessage")}
        </Typography>
      </div>
      {missing.length > 0 && (
        <div className={classes.message}>
          <Typography variant="body2" className={classes.headingText}>
            {t("inbox:missingLines", { value: missing.length })}
          </Typography>
          <ul className={classes.list}>
            {missing.map((line, index) => (
              <li key={index} className={classes.listItem}>
                <Typography variant="body2">{line.item_name}</Typography>
              </li>
            ))}
          </ul>
        </div>
      )}
      {discrepancies.length > 0 && (
        <div className={classes.discrepancies}>
          <Typography variant="body2" className={classes.headingText}>
            {t("inbox:discrepancies", { value: discrepancies.length })}
          </Typography>
          <div style={{ height: gridHeight, width: "100%" }} className={classes.dataGrid}>
            <DataGrid
              rows={discrepancies.map((d, index) => ({ ...d, id: index }))}
              columns={columns}
              pageSize={discrepancies.length}
              hideFooter={true}
              disableSelectionOnClick
              sx={{
                "& .MuiDataGrid-row": {
                  color: (theme) => theme.palette.text.secondary,
                },
                "& .MuiDataGrid-row.error": {
                  color: (theme) => theme.palette.error.main,
                },
                "& .MuiDataGrid-cell.error": {
                  color: (theme) => theme.palette.error.main,
                },
              }}
            />
          </div>
        </div>
      )}
    </Box>
  );
};

export default PoAcknowledgementFailureEvent;
