import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "./types";
import { useTranslation } from "react-i18next";
import UserRating from "components/user-rating/user-rating.component";
import { Box, Dialog, DialogTitle, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const SharedUserRatingModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal(SharedModalTypes.USER_RATING_MODAL));
  const { t } = useTranslation();
  const ratingToken = modal?.data;

  if (!modal || !ratingToken) return null;

  const onClose = () => {
    dispatch(modalsActions.closeModal(SharedModalTypes.USER_RATING_MODAL));
  };

  return (
    <Dialog open={!!modal} onClose={onClose} closeAfterTransition fullWidth={true}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}> {t("project:introductionDialog:title")}</Box>
          <Box pl="5px">
            <IconButton onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />

      <UserRating ratingToken={ratingToken} />
    </Dialog>
  );
};
