import React from "react";
import { useHistory } from "react-router-dom";
import { Avatar } from "@mui/material";
import AirbusLogo from "../../../../../assets/airbus-logo.png";
import NextAxyaLogo from "assets/img/axya-logo-topbar.svg";
import { ValidationManagementUserProfile } from "./validation-management-user-profile/validation-management-user-profile";
import * as S from "./validation-management-app-bar.styled";

export const ValidationManagementAppBar = () => {
  const history = useHistory();

  return (
    <S.StyledAppBar>
      <img src={NextAxyaLogo} alt="Axya" />

      <Avatar sx={{ width: "100px", height: "40px" }} src={AirbusLogo} />

      <S.ProfileWrapper>
        <ValidationManagementUserProfile />
      </S.ProfileWrapper>
    </S.StyledAppBar>
  );
};
