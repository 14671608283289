import { Formik, FormikProps } from "formik";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import PartFormBase from "./part-form.base";
import {
  editPartFormValidationSchema,
  getEditPartFormInitialValues,
} from "./part-form.definitions";
import { EditFormValues } from "./part-form.types";

type Props = {
  initialValues: EditFormValues;
  onSubmit: (values: EditFormValues) => void;
  ref: any;
};

const PartForm: React.FC<Props> = forwardRef(({ initialValues, onSubmit }, ref) => {
  const formRef = useRef<FormikProps<EditFormValues>>(null);

  useImperativeHandle(ref, () => ({
    submitForm() {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      formRef?.current?.submitForm();
    },
  }));

  return (
    <Formik
      initialValues={getEditPartFormInitialValues(initialValues)}
      onSubmit={onSubmit}
      validationSchema={editPartFormValidationSchema}
      innerRef={formRef}
    >
      {(props) => <PartFormBase {...props} />}
    </Formik>
  );
});

export default PartForm;
