// URL

import { backendUrl } from "../../urls";
// Helper
import { handleResponse } from "../../helpers/responseHandling";
import { store } from "store";
import { axiosApi } from "@next/api";
import { genericErrorHandler } from "@next/utils/apiUtils";

// Services Exported
export const marketplaceApi = {
  fetchActiveRFQList,
  fetchRecommendedRFQList,
  createQuotation,
  fetchCompanyQuotations,
  fetchCompanyContractList,
  marketplacePreferences,
  marketplaceLikeDislikeRecommend,
  uploadQuotes,
  getActiveRfqDetails,
  getPublicRfqDetails,
  fetchHiddenRFQList,
  fetchRFQStats,
  supplierUploadNDA,
  getBuyerStatsByRfqId,
  fetchIntentToBidRFQList,
};

/**
 * Backend Query for the fetching of a list of all the RFQ's created by a company
 *
 * @param {*} token - Token for User Authentication
 * @returns {Array} - Array of Objects representing RFQ's
 */
function fetchActiveRFQList(token, queryParams) {
  let url = backendUrl.marketplace;
  if (queryParams) {
    url += "?" + queryParams;
  }

  return axiosApi.get(url).then(handleResponse);
}

function fetchRecommendedRFQList() {
  const token = store.getState().profile.token;

  let url = backendUrl.marketplace + "?recommended=true";

  return axiosApi.get(url).then(handleResponse);
}

// Backend Query to fetch hidden rfqs

function fetchHiddenRFQList(token) {
  const url = backendUrl.marketplace + "?hidden=true";
  return axiosApi.get(url).then(handleResponse);
}

// Backend Query to fetch intent to bid rfqs

function fetchIntentToBidRFQList(token) {
  const url = backendUrl.marketplace + "?intentToBid=true";
  return axiosApi.get(url).then(handleResponse);
}

// Backend Query for the fetching rfq details on each rfq click

function getActiveRfqDetails(token, rfqId) {
  let url = backendUrl.marketplace + rfqId;

  return axiosApi
    .get(url)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function getPublicRfqDetails(rfqId) {
  let url = `${backendUrl.marketplacePublic}${rfqId}/`;

  return axiosApi
    .get(url)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
/**
 * Backend Query for create Quotation
 * @param {*} token - Token for User Authentication
 * @returns {Objects} -  Objects representing quotes
 */
function createQuotation(token, quote) {
  return axiosApi.post(backendUrl.quotation, quote).then(handleResponse);
}

/**
 * Backend Query for create fetch Company Quotations
 * @param {*} token - Token for User Authentication
 * @returns {Array} -   Array of Objects representing quotes
 */
function fetchCompanyQuotations(token, status) {
  return axiosApi
    .get(`${backendUrl.quotations}${status}`)
    .then(handleResponse)
    .then((data) => {
      return { data, status };
    });
}

/**
 * Backend Query for create fetch Company Quotations
 * @param {*} token - Token for User Authentication
 * @returns {Array} -   Array of Objects representing contracts
 */

function fetchCompanyContractList(token, winnerId) {
  return axiosApi.get(backendUrl.contracts).then(handleResponse);
}

function marketplacePreferences(token, prefs, isIntentToNoQuote) {
  const { rfq_pk, ...restPrefs } = prefs;

  const requestData = { item: rfq_pk, ...restPrefs };

  return axiosApi[!isIntentToNoQuote ? "post" : "delete"](
    `${backendUrl.marketplacePreferences}`,
    !isIntentToNoQuote ? requestData : { data: requestData }
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function marketplaceLikeDislikeRecommend(rfqId, liked_recommendation) {
  return axiosApi
    .post(`${backendUrl.marketplaceLikeDislikeRecommend}`, {
      rfq: rfqId,
      liked_recommendation,
    })
    .then(handleResponse);
}

/**
 * Backend Query for upload Quotation
 * @param {*} token - Token for User Authentication
 * @param {array} file - file upload
 * @returns {array} - array of Objects representing quote upload file
 */
function uploadQuotes(token, file, t) {
  const formData = new FormData();
  formData.append("file", file.file);

  return axiosApi.post(backendUrl.uploadQuotes, formData).then(handleResponse);
}

// Backend query to fetch RFQ stats
function fetchRFQStats(id, token, page) {
  return axiosApi.get(`${backendUrl.rfqs}/${id}/stats?p=${page}`).then(handleResponse);
}

// Backend query to upload supplier signed NDA
function supplierUploadNDA(id, token, file) {
  const formData = new FormData();
  formData.append("nda_file", file);

  return axiosApi
    .post(`${backendUrl.rfq}${id}/signed-nda/`, formData)
    .then(handleResponse)
    .then(
      (data) => {
        return data;
      },
      function (error) {
        genericErrorHandler(JSON.stringify(error?.response?.data));
        return error;
      }
    );
}

// Backend query to buyer  stats
function getBuyerStatsByRfqId(token, rfqPk) {
  return axiosApi
    .get(`${backendUrl.buyerStats}/${rfqPk}/buyer-stats/`)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
