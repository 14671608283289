import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GridCellParams } from "@mui/x-data-grid-pro-v5";
import { SelectDateMenu } from "../table-menu/select-date-menu/select-date-menu";
import { EvidenceMenu } from "../table-menu/evidence-menu/evidence-menu";
import { SelectOptionMenu } from "../table-menu/select-option-menu/select-option-menu";
import {
  selectDesignAndBuiltDrawings,
  selectDesignAndBuiltDrawingsLoading,
  selectIsFetchSpecByClickedThread,
  selectIsFormDisplayForValidationManagement,
  selectIsFormStepCompleted,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  EvidenceFileObj,
  PartialStep,
  UpdateDesignAndBuildDrawing,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  getDesignAndBuiltFormTableColumns,
  selectConformMenuItems,
} from "./design-and-built-form-table.columns";
import { formatDate } from "helpers/formatDate";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./design-and-built-form-table.styled";

type Props = {
  hideCompletedTableLines: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  isTableDisabled: boolean;
};

export const DesignAndBuiltFormTable: React.FC<Props> = ({
  hideCompletedTableLines,
  page,
  setPage,
  pageSize,
  isTableDisabled,
}) => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormStepCompleted);
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const activeFormStep = steps[activeFormStepIndex];
  const isFetchSpecByClickedThread = useSelector(selectIsFetchSpecByClickedThread);
  const columns = getDesignAndBuiltFormTableColumns(
    isFormDisplayForValidationManagement,
    isTableDisabled
  );
  const tableData = useSelector(selectDesignAndBuiltDrawings);
  const rows = tableData?.results?.data;
  const loading = useSelector(selectDesignAndBuiltDrawingsLoading);
  const [anchorElForDateMenu, setAnchorElForDateMenu] = useState<null | HTMLElement>(null);
  const [anchorElForEvidenceMenu, setAnchorElForEvidenceMenu] = useState<null | HTMLElement>(null);
  const [anchorElForOptionMenu, setAnchorElForOptionMenu] = useState<null | HTMLElement>(null);
  const [row, setRow] = useState<any>({});
  const [field, setField] = useState<string>("");
  const [currentSelectionDate, setCurrentSelectionDate] = useState<string>();
  const [specOfScopeData, setSpecOfScopeData] = useState<UpdateDesignAndBuildDrawing>();
  const [isClickedRowApproved, setIsClickedRowApproved] = useState<boolean>(false);

  const handleCellClick = (params: GridCellParams, event: React.MouseEvent<HTMLElement>) => {
    if (params.field === "poe" && params.row.conform_to_a2x && !isTableDisabled) {
      setRow(params.row);
      setField(params.field);
      setAnchorElForDateMenu(event.currentTarget);
    } else if (
      params.field === "actionPlan" &&
      params.row.conform_to_a2x &&
      params.row.conform_to_a2x !== "conform_to_a2x"
    ) {
      setIsClickedRowApproved(params.row.approval_status === "approved");
      setRow(params.row);
      setAnchorElForEvidenceMenu(event.currentTarget);
    } else if (params.field === "conform_to_a2x" && !isTableDisabled) {
      setRow(params.row);
      setField(params.field);
      setAnchorElForOptionMenu(event.currentTarget);
    }
  };

  const handleClickConformOption = (menuItem: string) => {
    const drawing_id = row?.drawing_id;
    const formData = new FormData();
    formData.append("conform_to_a2x", menuItem);

    dispatch(
      vendorManagementActions.updateDesignAndBuiltDrawingRequest({
        companyId: companyId,
        drawingId: drawing_id,
        data: formData as any,
        onSuccess: onUpdateSuccess,
      })
    );
    setAnchorElForOptionMenu(null);
  };

  const handleClickDateSelection = (date: Date | null) => {
    const drawing_id = row?.drawing_id;
    const formattedDate = formatDate(date, "YYYY-MM-DD");
    setCurrentSelectionDate(formattedDate);
    const body = { poe: formattedDate };

    setSpecOfScopeData({
      companyId: companyId,
      drawingId: drawing_id,
      data: body,
    });
  };
  const handleOnClose = () => {
    if (currentSelectionDate !== row?.poe) {
      dispatch(
        vendorManagementActions.updateDesignAndBuiltDrawingRequest({
          ...specOfScopeData,
          onSuccess: onUpdateSuccess,
        })
      );
    }
    setAnchorElForDateMenu(null);
  };

  const handleSaveCommentAndFile = (comment: string, file: File | "" | EvidenceFileObj) => {
    const drawing_id = row?.drawing_id;
    const formData = new FormData();
    formData.append("action_plan_evidence_comment", comment);
    if (!(file as EvidenceFileObj)?.file_name) {
      // if file is saved before, file is an evidence file object, not a binary file, no need to save again
      formData.append("action_plan_evidence_file", file as File);
    }

    dispatch(
      vendorManagementActions.updateDesignAndBuiltDrawingRequest({
        companyId: companyId,
        drawingId: drawing_id,
        data: formData as any,
        onSuccess: onUpdateSuccess,
      })
    );
    setAnchorElForEvidenceMenu(null);
  };

  const onUpdateSuccess = () => {
    reFetchTableDataToGetUpdatedCount();
    checkInputChange();
  };

  const reFetchTableDataToGetUpdatedCount = () => {
    dispatch(
      vendorManagementActions.fetchDesignAndBuiltDrawingsRequest({
        companyId: companyId,
        pagination: { page: page, pageSize: pageSize },
        filter_completed: hideCompletedTableLines,
        isForFetchingToGetCountData: true,
      })
    );
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else {
      updateFieldStatusOfStepForSaveAndCompletion("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const step = steps[activeFormStepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
        onSuccess:
          key === "is_completed" && !value
            ? () => updateApprovalOfStepAfterCompletionChange("changes_requested")
            : undefined,
      })
    );
  };

  const updateApprovalOfStepAfterCompletionChange = (statusParam: "changes_requested") => {
    if (activeFormStep?.approval_status === "changes_done" && statusParam === "changes_requested") {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: activeFormStep.step,
          status: statusParam,
        })
      );
    }
  };

  return (
    <>
      <S.StyledDataGridPro
        autoHeight
        autoPageSize
        headerHeight={48}
        rowHeight={!isFormDisplayForValidationManagement ? 32 : 40}
        rows={rows || []}
        columns={columns}
        disableSelectionOnClick
        onCellClick={handleCellClick}
        pagination
        paginationMode="server"
        pageSize={pageSize}
        page={!isFetchSpecByClickedThread ? page - 1 : 1}
        rowCount={tableData?.count ? tableData.count : 0}
        rowsPerPageOptions={[pageSize]}
        onPageChange={(newPage) => setPage(newPage + 1)}
        getRowId={(row) => row?.drawing_id}
        loading={loading}
        getRowClassName={(params) => {
          const isDisabledStatus = params?.row?.approval_status === "approved";
          return isDisabledStatus ? "disabled-row" : "";
        }}
      />

      {!isFormDisplayForValidationManagement ? (
        <>
          <SelectOptionMenu
            anchorEl={anchorElForOptionMenu}
            setAnchorEl={setAnchorElForOptionMenu}
            row={row}
            field={field}
            menuItems={selectConformMenuItems}
            handleClick={handleClickConformOption}
          />

          <SelectDateMenu
            anchorEl={anchorElForDateMenu}
            row={row}
            currentSelectionDate={currentSelectionDate}
            setCurrentSelectionDate={setCurrentSelectionDate}
            handleChange={handleClickDateSelection}
            handleOnClose={handleOnClose}
          />
        </>
      ) : null}

      <EvidenceMenu
        anchorEl={anchorElForEvidenceMenu}
        row={row}
        fieldComment={"action_plan_evidence_comment"}
        fieldFile={"action_plan_evidence_file"}
        setAnchorEl={setAnchorElForEvidenceMenu}
        handleSaveCommentAndFile={handleSaveCommentAndFile}
        isTableDisabled={isTableDisabled || isClickedRowApproved}
      />
    </>
  );
};
