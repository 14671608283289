import React from "react";
import * as S from "./form-title.styled";

type Props = {
  title: string;
  isCompleted?: boolean;
  isInReview?: boolean;
  isApproved?: boolean;
};

export const FormTitle: React.FC<Props> = ({ title, isCompleted, isInReview, isApproved }) => {
  const label = isApproved ? "Approved" : isInReview ? "To review" : isCompleted ? "Completed" : "";
  return (
    <S.StyledBox>
      <S.StyledTitle>{title}</S.StyledTitle>

      {isCompleted || isApproved || isInReview ? (
        <S.StyledChip
          isApproved={isApproved}
          isCompleted={isCompleted}
          isInReview={isInReview}
          label={label}
        />
      ) : null}
    </S.StyledBox>
  );
};
