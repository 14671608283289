export const CountryList = [
  {
    Country: "Afghanistan",
    ISO: "AF",
  },
  {
    Country: "Åland Islands",
    ISO: "AX",
  },
  {
    Country: "Albania",
    ISO: "AL",
  },
  {
    Country: "Algeria",
    ISO: "DZ",
  },
  {
    Country: "American Samoa",
    ISO: "AS",
  },
  {
    Country: "Andorra",
    ISO: "AD",
  },
  {
    Country: "Angola",
    ISO: "AO",
  },
  {
    Country: "Anguilla",
    ISO: "AI",
  },
  {
    Country: "Antigua and Barbuda",
    ISO: "AG",
  },
  {
    Country: "Argentina",
    ISO: "AR",
  },
  {
    Country: "Armenia",
    ISO: "AM",
  },
  {
    Country: "Aruba",
    ISO: "AW",
  },
  {
    Country: "Ascension island",
    ISO: "AC",
  },
  {
    Country: "Australia",
    ISO: "AU",
  },
  {
    Country: "Austria",
    ISO: "AT",
  },
  {
    Country: "Azerbaijan",
    ISO: "AZ",
  },
  {
    Country: "Bahamas",
    ISO: "BS",
  },
  {
    Country: "Bahrain",
    ISO: "BH",
  },
  {
    Country: "Bangladesh",
    ISO: "BD",
  },
  {
    Country: "Barbados",
    ISO: "BB",
  },
  {
    Country: "Belarus",
    ISO: "BY",
  },
  {
    Country: "Belgium",
    ISO: "BE",
  },
  {
    Country: "Belize",
    ISO: "BZ",
  },
  {
    Country: "Benin",
    ISO: "BJ",
  },
  {
    Country: "Bermuda",
    ISO: "BM",
  },
  {
    Country: "Bhutan",
    ISO: "BT",
  },
  {
    Country: "Bolivia",
    ISO: "BO",
  },
  {
    Country: "Bonaire, Sint Eustatius and Saba",
    ISO: "BQ",
  },
  {
    Country: "Bosnia and Herzegovina",
    ISO: "BA",
  },
  {
    Country: "Botswana",
    ISO: "BW",
  },
  {
    Country: "Brazil",
    ISO: "BR",
  },
  {
    Country: "British Antarctic Territory",
    ISO: "",
  },
  {
    Country: "British Indian Ocean Territory",
    ISO: "IO",
  },
  {
    Country: "British Virgin Islands",
    ISO: "VG",
  },
  {
    Country: "Brunei",
    ISO: "BN",
  },
  {
    Country: "Bulgaria",
    ISO: "BG",
  },
  {
    Country: "Burkina Faso",
    ISO: "BF",
  },
  {
    Country: "Burundi",
    ISO: "BI",
  },
  {
    Country: "Cambodia",
    ISO: "KH",
  },
  {
    Country: "Cameroon",
    ISO: "CM",
  },
  {
    Country: "Canada",
    ISO: "CA",
  },
  {
    Country: "Cape Verde",
    ISO: "CV",
  },
  {
    Country: "Cayman Islands",
    ISO: "KY",
  },
  {
    Country: "Central African Republic",
    ISO: "CF",
  },
  {
    Country: "Chad",
    ISO: "TD",
  },
  {
    Country: "Chile",
    ISO: "CL",
  },
  {
    Country: "China",
    ISO: "CN",
  },
  {
    Country: "Christmas Island",
    ISO: "CX",
  },
  {
    Country: "Cocos (Keeling) Island",
    ISO: "CC",
  },
  {
    Country: "Colombia",
    ISO: "CO",
  },
  {
    Country: "Comoros",
    ISO: "KM",
  },
  {
    Country: "Congo (Brazzaville)",
    ISO: "CG",
  },
  {
    Country: "Congo, Democratic Republic",
    ISO: "CD",
  },
  {
    Country: "Cook Islands",
    ISO: "CK",
  },
  {
    Country: "Costa Rica",
    ISO: "CR",
  },
  {
    Country: "Côte d'Ivoire (Ivory Coast)",
    ISO: "CI",
  },
  {
    Country: "Croatia",
    ISO: "HR",
  },
  {
    Country: "Cuba",
    ISO: "CU",
  },
  {
    Country: "Curaçao",
    ISO: "CW",
  },
  {
    Country: "Cyprus",
    ISO: "CY",
  },
  {
    Country: "Czech Republic",
    ISO: "CZ",
  },
  {
    Country: "Denmark",
    ISO: "DK",
  },
  {
    Country: "Djibouti",
    ISO: "DJ",
  },
  {
    Country: "Dominica",
    ISO: "DM",
  },
  {
    Country: "Dominican Republic",
    ISO: "DO",
  },
  {
    Country: "East Timor",
    ISO: "TL",
  },
  {
    Country: "Ecuador",
    ISO: "EC",
  },
  {
    Country: "El Salvador",
    ISO: "SV",
  },
  {
    Country: "Egypt",
    ISO: "EG",
  },
  {
    Country: "Equatorial Guinea",
    ISO: "GQ",
  },
  {
    Country: "Eritrea",
    ISO: "ER",
  },
  {
    Country: "Estonia",
    ISO: "EE",
  },
  {
    Country: "Ethiopia",
    ISO: "ET",
  },
  {
    Country: "Falkland Islands",
    ISO: "FK",
  },
  {
    Country: "Faroe Islands",
    ISO: "FO",
  },
  {
    Country: "Fiji",
    ISO: "FJ",
  },
  {
    Country: "Finland",
    ISO: "FI",
  },
  {
    Country: "France",
    ISO: "FR",
  },
  {
    Country: "French Guiana",
    ISO: "GF",
  },
  {
    Country: "French Polynesia",
    ISO: "PF",
  },
  {
    Country: "French Southern and Antarctic Territories",
    ISO: "TF",
  },
  {
    Country: "Gabon",
    ISO: "GA",
  },
  {
    Country: "Gambia",
    ISO: "GM",
  },
  {
    Country: "Georgia",
    ISO: "GE",
  },
  {
    Country: "Germany",
    ISO: "DE",
  },
  {
    Country: "Ghana",
    ISO: "GH",
  },
  {
    Country: "Gibraltar",
    ISO: "GI",
  },
  {
    Country: "Greece",
    ISO: "GR",
  },
  {
    Country: "Greenland",
    ISO: "GL",
  },
  {
    Country: "Grenada",
    ISO: "GD",
  },
  {
    Country: "Guadeloupe",
    ISO: "GP",
  },
  {
    Country: "Guam",
    ISO: "GU",
  },
  {
    Country: "Guatemala",
    ISO: "GT",
  },
  {
    Country: "Guernsey",
    ISO: "GG",
  },
  {
    Country: "Guinea",
    ISO: "GN",
  },
  {
    Country: "Guinea Bissau",
    ISO: "GW",
  },
  {
    Country: "Guyana",
    ISO: "GY",
  },
  {
    Country: "Haiti",
    ISO: "HT",
  },
  {
    Country: "Heard and McDonald Islands",
    ISO: "HM",
  },
  {
    Country: "Honduras",
    ISO: "HN",
  },
  {
    Country: "Hong Kong",
    ISO: "HK",
  },
  {
    Country: "Hungary",
    ISO: "HU",
  },
  {
    Country: "Iceland",
    ISO: "IS",
  },
  {
    Country: "India",
    ISO: "IN",
  },
  {
    Country: "Indonesia",
    ISO: "ID",
  },
  {
    Country: "Iran",
    ISO: "IR",
  },
  {
    Country: "Iraq",
    ISO: "IQ",
  },
  {
    Country: "Ireland",
    ISO: "IE",
  },
  {
    Country: "Isle of Man",
    ISO: "IM",
  },
  {
    Country: "Israel",
    ISO: "IL",
  },
  {
    Country: "Italy",
    ISO: "IT",
  },
  {
    Country: "Jamaica",
    ISO: "JM",
  },
  {
    Country: "Japan",
    ISO: "JP",
  },
  {
    Country: "Jersey",
    ISO: "JE",
  },
  {
    Country: "Jordan",
    ISO: "JO",
  },
  {
    Country: "Kazakhstan",
    ISO: "KZ",
  },
  {
    Country: "Kenya",
    ISO: "KE",
  },
  {
    Country: "Kiribati",
    ISO: "KI",
  },
  {
    Country: "Korea, North",
    ISO: "KP",
  },
  {
    Country: "Korea, South",
    ISO: "KR",
  },
  {
    Country: "Kosovo",
    ISO: "XK",
  },
  {
    Country: "Kuwait",
    ISO: "KW",
  },
  {
    Country: "Kyrgyzstan",
    ISO: "KG",
  },
  {
    Country: "Latvia",
    ISO: "LV",
  },
  {
    Country: "Laos",
    ISO: "LA",
  },
  {
    Country: "Lebanon",
    ISO: "LB",
  },
  {
    Country: "Lesotho",
    ISO: "LS",
  },
  {
    Country: "Liberia",
    ISO: "LR",
  },
  {
    Country: "Libya",
    ISO: "LY",
  },
  {
    Country: "Liechtenstein",
    ISO: "LI",
  },
  {
    Country: "Lithuania",
    ISO: "LT",
  },
  {
    Country: "Luxembourg",
    ISO: "LU",
  },
  {
    Country: "Macau",
    ISO: "MO",
  },
  {
    Country: "Macedonia",
    ISO: "MK",
  },
  {
    Country: "Madagascar",
    ISO: "MG",
  },
  {
    Country: "Malawi",
    ISO: "MW",
  },
  {
    Country: "Maldives",
    ISO: "MV",
  },
  {
    Country: "Malaysia",
    ISO: "MY",
  },
  {
    Country: "Mali",
    ISO: "ML",
  },
  {
    Country: "Malta",
    ISO: "MT",
  },
  {
    Country: "Marshall Islands",
    ISO: "MH",
  },
  {
    Country: "Mauritania",
    ISO: "MR",
  },
  {
    Country: "Mauritius",
    ISO: "MU",
  },
  {
    Country: "Martinique",
    ISO: "MQ",
  },
  {
    Country: "Mayotte",
    ISO: "YT",
  },
  {
    Country: "Mexico",
    ISO: "MX",
  },
  {
    Country: "Micronesia",
    ISO: "FM",
  },
  {
    Country: "Moldova",
    ISO: "MD",
  },
  {
    Country: "Monaco",
    ISO: "MC",
  },
  {
    Country: "Mongolia",
    ISO: "MN",
  },
  {
    Country: "Montenegro",
    ISO: "ME",
  },
  {
    Country: "Montserrat",
    ISO: "MS",
  },
  {
    Country: "Morocco",
    ISO: "MA",
  },
  {
    Country: "Mozambique",
    ISO: "MZ",
  },
  {
    Country: "Myanmar",
    ISO: "MM",
  },
  {
    Country: "Namibia",
    ISO: "NA",
  },
  {
    Country: "Nauru",
    ISO: "NR",
  },
  {
    Country: "Nepal",
    ISO: "NP",
  },
  {
    Country: "Netherlands",
    ISO: "NL",
  },
  {
    Country: "New Caledonia",
    ISO: "NC",
  },
  {
    Country: "New Zealand",
    ISO: "NZ",
  },
  {
    Country: "Nicaragua",
    ISO: "NI",
  },
  {
    Country: "Niger",
    ISO: "NE",
  },
  {
    Country: "Nigeria",
    ISO: "NG",
  },
  {
    Country: "Niue",
    ISO: "NU",
  },
  {
    Country: "Norfolk Island",
    ISO: "NF",
  },
  {
    Country: "Northern Mariana Islands",
    ISO: "MP",
  },
  {
    Country: "Norway",
    ISO: "NO",
  },
  {
    Country: "Oman",
    ISO: "OM",
  },
  {
    Country: "Pakistan",
    ISO: "PK",
  },
  {
    Country: "Palau",
    ISO: "PW",
  },
  {
    Country: "Panama",
    ISO: "PA",
  },
  {
    Country: "Papua New Guinea",
    ISO: "PG",
  },
  {
    Country: "Paraguay",
    ISO: "PY",
  },
  {
    Country: "Peru",
    ISO: "PE",
  },
  {
    Country: "Philippines",
    ISO: "PH",
  },
  {
    Country: "Pitcairn Islands",
    ISO: "PN",
  },
  {
    Country: "Poland",
    ISO: "PL",
  },
  {
    Country: "Portugal",
    ISO: "PT",
  },
  {
    Country: "Puerto Rico",
    ISO: "PR",
  },
  {
    Country: "Qatar",
    ISO: "QA",
  },
  {
    Country: "Réunion",
    ISO: "RE",
  },
  {
    Country: "Romania",
    ISO: "RO",
  },
  {
    Country: "Russia",
    ISO: "RU",
  },
  {
    Country: "Saint Barthélemy",
    ISO: "BL",
  },
  {
    Country: "Saint Helena",
    ISO: "SH",
  },
  {
    Country: "Saint Kitts and Nevis",
    ISO: "KN",
  },
  {
    Country: "Saint Lucia",
    ISO: "LC",
  },
  {
    Country: "Saint Martin",
    ISO: "MF",
  },
  {
    Country: "Saint Pierre and Miquelon",
    ISO: "PM",
  },
  {
    Country: "Saint Vincent and the Grenadines",
    ISO: "VC",
  },
  {
    Country: "San Marino",
    ISO: "SM",
  },
  {
    Country: "Sao Tome and Principe",
    ISO: "ST",
  },
  {
    Country: "Saudi Arabia",
    ISO: "SA",
  },
  {
    Country: "Senegal",
    ISO: "SN",
  },
  {
    Country: "Serbia",
    ISO: "RS",
  },
  {
    Country: "Seychelles",
    ISO: "SC",
  },
  {
    Country: "Sint Maarten",
    ISO: "SX",
  },
  {
    Country: "Sierra Leone",
    ISO: "SL",
  },
  {
    Country: "Singapore",
    ISO: "SG",
  },
  {
    Country: "Slovakia",
    ISO: "SK",
  },
  {
    Country: "Slovenia",
    ISO: "SI",
  },
  {
    Country: "Solomon Islands",
    ISO: "SB",
  },
  {
    Country: "Somalia",
    ISO: "SO",
  },
  {
    Country: "South Africa",
    ISO: "ZA",
  },
  {
    Country: "South Georgia and the South Sandwich Islands",
    ISO: "GS",
  },
  {
    Country: "South Korea",
    ISO: "KR",
  },
  {
    Country: "Spain",
    ISO: "ES",
  },
  {
    Country: "Sri Lanka",
    ISO: "LK",
  },
  {
    Country: "Sudan",
    ISO: "SD",
  },
  {
    Country: "Suriname",
    ISO: "SR",
  },
  {
    Country: "Swaziland",
    ISO: "SZ",
  },
  {
    Country: "Sweden",
    ISO: "SE",
  },
  {
    Country: "Switzerland",
    ISO: "CH",
  },
  {
    Country: "Svalbard and Jan Mayen",
    ISO: "SJ",
  },
  {
    Country: "Syria",
    ISO: "SY",
  },
  {
    Country: "Taiwan",
    ISO: "TW",
  },
  {
    Country: "Tajikistan",
    ISO: "TJ",
  },
  {
    Country: "Tanzania",
    ISO: "TZ",
  },
  {
    Country: "Thailand",
    ISO: "TH",
  },
  {
    Country: "Togo",
    ISO: "TG",
  },
  {
    Country: "Tokelau",
    ISO: "TK",
  },
  {
    Country: "Tonga",
    ISO: "TO",
  },
  {
    Country: "Trinidad and Tobago",
    ISO: "TT",
  },
  {
    Country: "Tristan da Cunha",
    ISO: "SH",
  },
  {
    Country: "Tunisia",
    ISO: "TN",
  },
  {
    Country: "Turkey",
    ISO: "TR",
  },
  {
    Country: "Turkmenistan",
    ISO: "TM",
  },
  {
    Country: "Turks and Caicos Islands",
    ISO: "TC",
  },
  {
    Country: "Tuvalu",
    ISO: "TV",
  },
  {
    Country: "Uganda",
    ISO: "UG",
  },
  {
    Country: "Ukraine",
    ISO: "UA",
  },
  {
    Country: "United Arab Emirates",
    ISO: "AE",
  },
  {
    Country: "United Kingdom",
    ISO: "GB",
  },
  {
    Country: "United States",
    ISO: "US",
  },
  {
    Country: "Uruguay",
    ISO: "UY",
  },
  {
    Country: "U.S. Virgin Islands",
    ISO: "VI",
  },
  {
    Country: "Uzbekistan",
    ISO: "UZ",
  },
  {
    Country: "Vanuatu",
    ISO: "VU",
  },
  {
    Country: "Vatican",
    ISO: "VA",
  },
  {
    Country: "Venezuela",
    ISO: "VE",
  },
  {
    Country: "Vietnam",
    ISO: "VN",
  },
  {
    Country: "Wallis and Futuna",
    ISO: "WF",
  },
  {
    Country: "Yemen",
    ISO: "YE",
  },
  {
    Country: "Zambia",
    ISO: "ZM",
  },
  {
    Country: "Zimbabwe",
    ISO: "ZW",
  },
];

export enum CURRENCY_OPTIONS {
  USD = "USD",
  EUR = "EUR",
  CAD = "CAD",
}
