import React from "react";
import { OrderDetailDraft } from "../../components/order-detail/order-detail-draft/order-detail-draft";
import { OrderState } from "../../redux";

import { OrderDetailBasePage } from "../order-detail-base-page/order-detail-base-page";
import { useOrderDetailData } from "./order-detail-page.hooks";
import PageLoader from "@next/components/page-loader/page-loader";
import { useRemoveRootOverflow } from "@next/hooks/useRemoveRootOverflow";
import { useMediaQuery, useTheme } from "@mui/material";

export const OrderDetailPage = () => {
  const { orderDetail, orderDetailLoading } = useOrderDetailData();
  const isDraftOrder = orderDetail?.state?.slug === OrderState.UNPUBLISHED;
  const theme = useTheme();
  const onlyForMobile = useMediaQuery(theme.breakpoints.down(937));

  useRemoveRootOverflow({
    skip: isDraftOrder || onlyForMobile,
  });

  if (orderDetailLoading || !orderDetail) {
    return <PageLoader />;
  }

  if (isDraftOrder) {
    return <OrderDetailDraft />;
  }

  return <OrderDetailBasePage isPortal={false} />;
};
