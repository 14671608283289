import { Box, Button, styled } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { CustomTypography } from "@next/components/custom-typography";

export const StyledCheckIcon = styled(CheckIcon)({
  width: "24px",
  height: "24px",
});

export const StyledBtnBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "72px",
  padding: "16px",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "color",
})<{ color: "primary" | "secondary" }>(({ theme, color }) => ({
  width: "100%",
  height: "40px",
  backgroundColor:
    color === "primary" ? theme.palette.primary.main : theme.palette.background.paper,
  color: color === "primary" ? theme.palette.common.white : theme.palette.text.primary,
  "&:hover": {
    backgroundColor:
      color === "primary" ? theme.palette.primary.dark : theme.palette.background.paper,
  },
  "&:disabled": {
    opacity: 0.5,
    backgroundColor:
      color === "primary" ? theme.palette.primary.main : theme.palette.background.paper,
    color: color === "primary" ? theme.palette.common.white : theme.palette.text.primary,
  },
  border: color === "primary" ? "none" : `1px solid ${theme.palette.divider}`,
  // remove click effect from secondary
  /*  "&:active": color === "primary" ? {} : { backgroundColor: "unset" }, */
}));

export const StyledTypography = styled(CustomTypography)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0.25px",
}));
