import { ProjectsField } from "./types";
import { v4 as uuid } from "uuid";

export const NEW_PROJECT_ROW_ID_KEY = "new-project-row";

export const generateNewProjectRowData = () => {
  return {
    // only for catching new row, new code sending seperately when doing create project api request
    code: `${NEW_PROJECT_ROW_ID_KEY}-${uuid()}`,
    display_name: "New Project",
  };
};
export const isNewProjectRow = (id: string | number, field: string) => {
  return id?.toString().startsWith(NEW_PROJECT_ROW_ID_KEY) && field === ProjectsField.DISPLAY_NAME;
};
