import * as yup from "yup";
import { t } from "assets/configi18n/i18n";
import { PortalItnbFormValues } from "./portal-itnb-form.types";

export const getInitialValues = (): PortalItnbFormValues => {
  return {
    reason: "other",
    description: "",
  };
};

export const validationSchema = yup.object().shape({
  reason: yup.string().required(t("userOnboarding:thisFieldIsRequired")),
  description: yup.string(),
});
