import { Box, Typography, styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import CheckIcon from "@mui/icons-material/Check";

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "48px",
  padding: "48px",
  height: "430px",
  alignItems: "center",
  transform: "translateY(-10px)",
}));

export const StyledCheckIcon = styled(CheckIcon)({
  width: "24px",
  height: "24px",
  color: "white",
});

export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: "32px",
  letterSpacing: "0.18px",
  color: theme.palette.text.primary,
  textAlign: "center",
  width: "100%",
}));

export const BodyTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "24px",
  color: theme.palette.text.primary,
  textAlign: "center",
  letterSpacing: "0.5px",
  width: "100%",
}));

export const FooterTypography = styled(CustomTypography)(({ theme }) => ({
  lineHeight: "24px",
  color: theme.palette.text.secondary,
  textAlign: "center",
  letterSpacing: "0.5px",
  width: "100%",
}));

export const StyledCircleBox = styled(Box)(({ theme }) => ({
  width: "48px",
  height: "48px",
  borderRadius: "40px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.success.main,
  padding: "12px",
}));
