import { Box } from "@mui/material";
import { CircularAvatarsList } from "@next/components/circular-avatars-list";
import CompanyAvatar from "@next/modules/profile/components/company-profile-full/company-avatar/company-avatar";
import { getUserDisplayName } from "@next/modules/profile/helpers/profile-helpers";
import { GenericUser } from "@next/modules/profile/redux";
import { Company } from "@next/modules/workspace/redux";
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  })
);

interface Props {
  company?: Company;
  users?: GenericUser[];
  height?: number;
}

const TopicTitle: React.FC<Props> = ({ company, users, height }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <CompanyAvatar
        avatarSrc={company?.picture}
        companyName={company?.name}
        horizontal
        height={height}
      />

      {users?.length ? (
        <CircularAvatarsList
          images={users.map((item) => ({
            name: getUserDisplayName(item) || item?.email,
            tooltip: getUserDisplayName(item) || item?.email,
            url: item?.picture,
          }))}
          overlapping
          combinedTooltip
          size="medium"
        />
      ) : null}
    </Box>
  );
};

export default TopicTitle;
