import React, { useCallback } from "react";
import { Box, Dialog, DialogTitle, IconButton, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "./types";
import AddSuppliersForm from "../components/add-suppliers/add-suppliers.form";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dialogTitle: {
    padding: theme.spacing(1, 2),
  },
}));

export const AddSuppliersModal = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const modal = useSelector(getModal(WorkspaceModalTypes.ADD_SUPPLIERS_MODAL));
  const { t } = useTranslation();

  const onClickCloseModal = useCallback((_, reason?: string) => {
    if (reason === "backdropClick") return;
    dispatch(modalsActions.closeModal(WorkspaceModalTypes.ADD_SUPPLIERS_MODAL));
  }, []);

  if (!modal) return null;

  return (
    <Dialog
      open={!!modal}
      onClose={onClickCloseModal}
      closeAfterTransition
      fullWidth={true}
      className={classes.root}
      disableEscapeKeyDown
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h6">{t("workspaceNext:addSuppliersModal:title")}</Typography>
          </Box>
          <Box>
            <IconButton onClick={onClickCloseModal} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <AddSuppliersForm />
    </Dialog>
  );
};
