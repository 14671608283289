import React from "react";

// MaterialUI specific Import
import { Typography } from "@mui/material";

export const FormHeader = (props) => {
  const { title, body } = props;
  return (
    <>
      <Typography>
        <h5>{title}</h5>
      </Typography>
      <p>{body}</p>
    </>
  );
};
