import React from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Grid, Grow, Typography } from "@mui/material";
import logo from "../../../../assets/img/logo-manssenger.jpeg";

import { InstigatorName } from "./instigator-name";

const QuotationDropMessage = ({ id, quotationCreatedAt, activeQuotationThread }) => {
  const { t } = useTranslation();
  return (
    <Grow in>
      <Box
        id={id}
        flex="0 0 auto"
        my={1}
        display="flex"
        flexDirection="column"
        className="c-g4-communiaction-msg"
      >
        <Box flexGrow={1} display="flex" p={2}>
          {activeQuotationThread && (
            <Box>
              <Avatar style={{ width: 32, height: 32 }} src={logo}></Avatar>
            </Box>
          )}
          {activeQuotationThread && (
            <Grid className="c-chat-dialog--instigator-name" container direction="column">
              <InstigatorName
                name={"Axya Communications"}
                time={quotationCreatedAt}
                companyUUID={activeQuotationThread?.instigator?.uuid}
              />
              <Typography variant="body2" className="c-chat-dialog--instigator-status">
                Axya
              </Typography>
              <Typography
                variant="body1"
                style={{
                  whiteSpace: "pre-line",
                  fontSize: 13,
                  fontWeight: 400,
                  color: "#00000099",
                }}
              >
                {t("inbox:canceledRFQMessage", {
                  buyer_name: `${activeQuotationThread.rfq.author.first_name} ${activeQuotationThread.rfq.author.last_name}`,
                  buyer_company_name: activeQuotationThread.rfq.company.name,
                  supplier_name: `${activeQuotationThread.user_instigator.first_name} ${activeQuotationThread.user_instigator.last_name}`,
                  supplier_company_name: activeQuotationThread.instigator.name,
                  rfq_name: activeQuotationThread.rfq.display_name,
                })}
              </Typography>
            </Grid>
          )}
        </Box>
      </Box>
    </Grow>
  );
};

export default QuotationDropMessage;
