import React from "react";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";
import { NAVIGATION_FOR, useDataForNavigation } from "@next/hooks/useDataForNavigation";

type Props = {
  navigationFor: NAVIGATION_FOR;
  countBeforeArrows?: boolean;
};

export const DataNavigationWithArrows: React.FC<Props> = ({
  navigationFor,
  countBeforeArrows = false,
}) => {
  const { t } = useTranslation();
  const { currentCount, totalCount, loading, handleNextClick, handlePreviousClick } =
    useDataForNavigation(navigationFor);

  return (
    <Box display={"flex"} alignItems={"center"} gap={"2px"}>
      {!countBeforeArrows && (
        <IconButton
          aria-label="previous"
          size="small"
          onClick={handlePreviousClick}
          disabled={currentCount === 1 || loading}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      <Typography variant="caption">
        {loading || currentCount === 0 ? (
          <Skeleton animation="wave" height={40} width={120} />
        ) : (
          t(
            navigationFor === NAVIGATION_FOR.RFQ
              ? "workspaceNext:buyerRfqs:requestsNavigation"
              : "orders:orderDetail:ordersNavigation",
            {
              current: currentCount,
              total: totalCount,
            }
          )
        )}
      </Typography>
      {countBeforeArrows && (
        <IconButton
          aria-label="previous"
          size="small"
          onClick={handlePreviousClick}
          disabled={currentCount === 1 || loading}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      <IconButton
        aria-label="next"
        size="small"
        onClick={handleNextClick}
        disabled={currentCount === totalCount || loading}
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
};
