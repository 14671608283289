import { Box, Theme, Typography } from "@mui/material";
import GenericAvatar from "@next/components/generic-avatar";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUser } from "services/profile/profile.selectors";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
  })
);

export const SellerDashboardGreetings: React.FC = () => {
  const user = useSelector(getUser);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <GenericAvatar url={user?.picture} size="large" />
      <Box>
        <Typography variant="h6" color="textPrimary">
          {t("sellerDashboard:greeting", { name: user?.first_name })}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("sellerDashboard:subtitle")}
        </Typography>
      </Box>
    </Box>
  );
};
