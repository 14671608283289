import { put, select, takeLatest } from "redux-saga/effects";
import { dashboardService } from "../services/dashboard-services";
import { safeCall } from "@next/redux/safeCall";
import { dashboardActions } from "./slices";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  AddCompanyProfileViewInput,
  FetchBuyerProfileInput,
  FetchBuyerProfilePartInput,
  FetchBuyerProfilesInput,
  FetchCompanyProfileViewsInput,
  SupplierOnboardingStatus,
} from "./types";
import { OnboardingSteps } from "../components/seller-dashboard-onboarding-premium/seller-dashboard-onboarding-premium";
import { selectSupplierOnboardingStatus } from "./selectors";
import { getCompany } from "services/profile/profile.selectors";
import { Company } from "@next/modules/profile/redux";
import { store } from "store";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";

function* handleFetchCompanyProfileAnalyticsRequest() {
  const { response, error } = yield safeCall(dashboardService.fetchCompanyProfileAnalytics);

  if (error) {
    yield put(dashboardActions.fetchCompanyProfileAnalyticsFailure(error));
  } else {
    yield put(dashboardActions.fetchCompanyProfileAnalyticsSuccess(response.data));
  }
}

function* handleFetchCompanyProfileViewsRequest({
  payload,
}: PayloadAction<FetchCompanyProfileViewsInput>) {
  const { response, error } = yield safeCall(dashboardService.fetchCompanyProfileViews, payload);

  if (error) {
    yield put(dashboardActions.fetchCompanyProfileViewsFailure(error));
  } else {
    yield put(dashboardActions.fetchCompanyProfileViewsSuccess(response.data));
  }
}

function* handleAddCompanyProfileViewRequest({
  payload,
}: PayloadAction<AddCompanyProfileViewInput>) {
  const { error } = yield safeCall(dashboardService.addCompanyProfileView, payload);

  if (error) {
    yield put(dashboardActions.addCompanyProfileViewFailure(error));
  } else {
    yield put(dashboardActions.addCompanyProfileViewSuccess());
  }
}

function* handleFetchBuyerProfilesRequest({ payload }: PayloadAction<FetchBuyerProfilesInput>) {
  const { response, error } = yield safeCall(dashboardService.fetchBuyerProfiles, payload);

  if (error) {
    yield put(dashboardActions.fetchBuyerProfilesFailure(error));
  } else {
    yield put(dashboardActions.fetchBuyerProfilesSuccess(response.data));
  }
}

function* handleFetchBuyerProfileRequest({ payload }: PayloadAction<FetchBuyerProfileInput>) {
  const { response, error } = yield safeCall(dashboardService.fetchBuyerProfile, payload);

  if (error) {
    yield put(dashboardActions.fetchBuyerProfileFailure(error));
  } else {
    yield put(dashboardActions.fetchBuyerProfileSuccess(response.data));
  }
}

function* handleFetchBuyerProfilePartRequest({
  payload,
}: PayloadAction<FetchBuyerProfilePartInput>) {
  const { response, error } = yield safeCall(dashboardService.fetchBuyerProfilePart, payload);

  if (error) {
    yield put(dashboardActions.fetchBuyerProfilePartFailure(error));
  } else {
    yield put(dashboardActions.fetchBuyerProfilePartSuccess(response.data));
  }
}

function* handleFetchSupplierOnboardingStatusRequest({
  payload,
}: PayloadAction<((data: SupplierOnboardingStatus) => void) | undefined>) {
  const { response, error } = yield safeCall(dashboardService.fetchSupplierOnboardingStatus);

  if (error) {
    yield put(dashboardActions.fetchSupplierOnboardingStatusFailure(error));
  } else {
    yield put(dashboardActions.fetchSupplierOnboardingStatusSuccess(response.data));
    if (payload) payload(response.data);
  }
}

function* handleShowPopUpOnboardingRequest({ payload }: PayloadAction<OnboardingSteps>) {
  const company: Company = yield select(getCompany);
  if (company?.plan_name !== "premium") return;

  const showPopup = (status: SupplierOnboardingStatus) => {
    let stateKey: string;
    switch (payload) {
      case OnboardingSteps.SENDING_CONNECTION_REQUEST:
        stateKey = "company_connection_done";
        break;
      case OnboardingSteps.VISITING_NEWSFEED:
        stateKey = "newsfeed_viewed";
        break;
      case OnboardingSteps.VIEWING_REQUEST_MARKETPLACE:
        stateKey = "marketplace_viewed";
        break;
      case OnboardingSteps.UPDATING_COMPANY_PROFILE:
        stateKey = "company_profile_complete";
        break;
    }

    if (status?.[stateKey as keyof typeof status] === false) {
      const onboardingStatus = {
        ...status,
        [stateKey]: true,
      };
      store.dispatch(dashboardActions.fetchSupplierOnboardingStatusSuccess(onboardingStatus));
      store.dispatch(
        modalsActions.showModal({
          key: SharedModalTypes.SUPPLIER_ONBOARDING_MODAL,
        })
      );
    }
  };

  const prev: SupplierOnboardingStatus | undefined = yield select(selectSupplierOnboardingStatus);

  if (!prev) {
    yield put(dashboardActions.fetchSupplierOnboardingStatusRequest(showPopup));
  } else showPopup(prev);
}

export function* dashboardSaga() {
  yield takeLatest(
    dashboardActions.fetchCompanyProfileAnalyticsRequest,
    handleFetchCompanyProfileAnalyticsRequest
  );

  yield takeLatest(
    dashboardActions.fetchCompanyProfileViewsRequest,
    handleFetchCompanyProfileViewsRequest
  );

  yield takeLatest(
    dashboardActions.addCompanyProfileViewRequest,
    handleAddCompanyProfileViewRequest
  );

  yield takeLatest(dashboardActions.fetchBuyerProfilesRequest, handleFetchBuyerProfilesRequest);

  yield takeLatest(dashboardActions.fetchBuyerProfileRequest, handleFetchBuyerProfileRequest);

  yield takeLatest(
    dashboardActions.fetchBuyerProfilePartRequest,
    handleFetchBuyerProfilePartRequest
  );

  yield takeLatest(
    dashboardActions.fetchSupplierOnboardingStatusRequest,
    handleFetchSupplierOnboardingStatusRequest
  );

  yield takeLatest(dashboardActions.showPopUpOnboardingRequest, handleShowPopUpOnboardingRequest);
}
