import { axiosApi } from "@next/api";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { backendUrl } from "urls";
import {
  AddOrderPartsInput,
  CreateOrderInput,
  DeleteOrderFileInput,
  DeleteOrderInput,
  DeleteShipmentPartInput,
  FetchBulkFilesUploadReportInput,
  FetchOrderInput,
  FetchOrdersInput,
  FetchPortalOrderInput,
  Order,
  ReviewRevisionProposalInput,
  SupplierLineItem,
  UpdateBuyerPOAcknowledgeInput,
  UpdateDraftOrderLineItemsInput,
  UpdateOrderDeliveryDatesInput,
  UpdateOrderFollowupInput,
  UpdateOrderInput,
  UpdateOrderLineItemsInput,
  UpdateOrderShipmentAsBuyerInput,
  UpdateOrderTargetedProfilesInput,
  UpdatePortalOrderAcknowledgeInput,
  UpdatePortalOrderInput,
  UpdatePortalOrderLineItemsInput,
  UpdateSupplierPOAcknowledgeInput,
  UploadBulkFilesInput,
  UploadOrderFileInput,
} from "../redux";

const fetchOrders = ({ query }: FetchOrdersInput) => {
  return axiosApi.get<Order[]>(`${backendUrl.orders}/?${query}`);
};

const fetchSupplierLineItems = ({ query }: FetchOrdersInput) => {
  return axiosApi.get<SupplierLineItem[]>(`${backendUrl.supplierLineItems}/?${query}`);
};

const fetchOrder = ({ pk }: FetchOrderInput) => {
  return axiosApi.get<Order>(`${backendUrl.orders}/${pk}/`);
};

const createOrder = (createOrder: CreateOrderInput) => {
  return axiosApi.post(`${backendUrl.orders}/`, createOrder);
};

const updateOrder = ({ pk, refetchOrders, ...restOrder }: UpdateOrderInput) => {
  return axiosApi.patch(`${backendUrl.orders}/${pk}/`, restOrder);
};

const updateOrderDeliveryDates = ({ orderPk, delivery_dates }: UpdateOrderDeliveryDatesInput) => {
  return axiosApi.post(`${backendUrl.orders}/${orderPk}/deliveryDateUpdate/`, {
    delivery_dates,
  });
};

const updateOrderLineItems = ({ orderPk, ...rest }: UpdateOrderLineItemsInput) => {
  return axiosApi.post(`${backendUrl.orders}/${orderPk}/updateLineItems/`, rest);
};

const deleteOrder = ({ pk }: DeleteOrderInput) => {
  return axiosApi.delete(`${backendUrl.orders}/${pk}/`);
};

const updateOrderFollowup = ({
  orderPk,
  followupPk,
  send_date,
  is_muted,
}: UpdateOrderFollowupInput) => {
  return axiosApi.patch(`${backendUrl.orders}/${orderPk}/followups/${followupPk}/`, {
    send_date,
    is_user_muted: is_muted,
  });
};

const uploadOrderFile = async ({ attachments, pk }: UploadOrderFileInput) => {
  const response: { data: { files: FileData[]; filesError?: Error } } = {
    data: {
      files: [],
      filesError: undefined,
    },
  };

  if (attachments && attachments.length) {
    try {
      const results: any = await Promise.all(
        attachments?.map((file) => {
          const formData = new FormData();

          formData.append("file", file);
          return axiosApi.post(`${backendUrl.orders}/${pk}/files/`, formData);
        })
      );

      response.data.files = results?.map((res: any) => res.data);
    } catch (error) {
      response.data.filesError = (error as any)?.response?.data?.error;
    }
  }

  return response;
};

const deleteOrderFile = ({ orderPk, filePk }: DeleteOrderFileInput) => {
  return axiosApi.delete(`${backendUrl.orders}/${orderPk}/files/${filePk}/`);
};

const fetchPortalOrder = ({ orderToken }: FetchPortalOrderInput) => {
  return axiosApi.get<Order>(`${backendUrl.supplierPortal}orders/${orderToken}/`);
};

const updatePortalOrder = ({ orderToken, ...rest }: UpdatePortalOrderInput) => {
  return axiosApi.patch(`${backendUrl.supplierPortal}orders/${orderToken}/`, rest);
};

const updatePortalOrderAcknowledgement = ({
  orderToken,
  confirmation_file,
  update_note,
}: UpdatePortalOrderAcknowledgeInput) => {
  const payload = new FormData();
  if (confirmation_file) payload.append("confirmation_file", confirmation_file);
  if (update_note) payload.append("update_note", update_note);
  return axiosApi.post(
    `${backendUrl.supplierPortal}orders/${orderToken}/orderAcknowledgment`,
    payload
  );
};

const updateSupplierPOAcknowledgement = ({
  orderToken,
  confirmation_file,
}: UpdateSupplierPOAcknowledgeInput) => {
  const payload = new FormData();
  payload.append("confirmation_file", confirmation_file);

  return axiosApi.post(
    `${backendUrl.supplierPortal}orders/${orderToken}/updateOrderConfirmation`,
    payload
  );
};

const updateBuyerPOAcknowledgement = ({
  orderPk,
  confirmation_file,
}: UpdateBuyerPOAcknowledgeInput) => {
  const payload = new FormData();
  payload.append("confirmation_file", confirmation_file);

  return axiosApi.post(`${backendUrl.orders}/${orderPk}/orderAcknowledgment`, payload);
};

const updatePortalOrderLineItems = ({
  orderToken,
  updated_line_items,
  update_note,
  confirmation_file,
}: UpdatePortalOrderLineItemsInput) => {
  // const payload = convertObjectToFormData(rest, "confirmation_file");
  const payload = new FormData();
  if (confirmation_file) payload.append("confirmation_file", confirmation_file);
  if (update_note) payload.append("update_note", update_note);
  payload.append("updated_line_items", JSON.stringify(updated_line_items));

  return axiosApi.post(
    `${backendUrl.supplierPortal}orders/${orderToken}/proposeLineItemsUpdate`,
    payload
  );
};

const updatePortalOrderShippingConfirmation = ({
  orderToken,
  ...rest
}: UpdatePortalOrderLineItemsInput) => {
  return axiosApi.post(
    `${backendUrl.supplierPortal}orders/${orderToken}/shipmentConfirmation`,
    rest
  );
};

const updateOrderShippingConfirmation = ({ orderPk, ...rest }: UpdateOrderShipmentAsBuyerInput) => {
  return axiosApi.post(`${backendUrl.orders}/${orderPk}/shipmentConfirmation`, rest);
};

const addOrderParts = ({ parts, order_pk }: AddOrderPartsInput) => {
  return axiosApi.post(`${backendUrl.orders}/${order_pk}/lineItemsBulkImport/`, parts);
};

const deleteShipmentPart = ({ order_pk, line_item_id }: DeleteShipmentPartInput) => {
  return axiosApi.delete(`${backendUrl.orders}/${order_pk}/lineItems/${line_item_id}/`);
};

const fetchRevisionProposal = ({ pk: orderPk }: FetchOrderInput) => {
  return axiosApi.get<Order>(`${backendUrl.orders}/${orderPk}/revisionProposal`);
};

const reviewRevisionProposal = ({ orderPk, ...rest }: ReviewRevisionProposalInput) => {
  return axiosApi.post(`${backendUrl.orders}/${orderPk}/revisionProposal`, rest);
};

const bulkFilesUpload = ({ file, orderPk, attach_unmatched_files }: UploadBulkFilesInput) => {
  const formData = new FormData();
  formData.append("file", file);
  if (attach_unmatched_files) formData.append("attach_unmatched_files", "true");
  return axiosApi.post(`${backendUrl.orders}/${orderPk}/FilesUpload`, formData);
};

const fetchBulkFilesUploadReport = ({ uuid, orderPk }: FetchBulkFilesUploadReportInput) => {
  return axiosApi.get(`${backendUrl.orders}/${orderPk}/lineItemFilesUploadReports/${uuid}`);
};

const updateDraftOrderLineItems = ({ orderPk, ...rest }: UpdateDraftOrderLineItemsInput) => {
  return axiosApi.patch(`${backendUrl.orders}/${orderPk}/updateLineItems/`, rest);
};

const updatePOTargetProfiles = ({
  poId,
  supplierId,
  target_profiles,
}: UpdateOrderTargetedProfilesInput) => {
  return axiosApi.post(
    `${backendUrl.purchaseOrder}/${poId}/suppliers/${supplierId}/targetProfiles/`,
    target_profiles
  );
};

export const ordersService = {
  fetchOrders,
  fetchOrder,
  fetchSupplierLineItems,
  createOrder,
  updateOrder,
  deleteOrder,
  updateOrderFollowup,
  uploadOrderFile,
  deleteOrderFile,
  fetchPortalOrder,
  updatePortalOrder,
  updateOrderDeliveryDates,
  updateOrderLineItems,
  updatePortalOrderAcknowledgement,
  updateSupplierPOAcknowledgement,
  updateBuyerPOAcknowledgement,
  updatePortalOrderLineItems,
  updatePortalOrderShippingConfirmation,
  updateOrderShippingConfirmation,
  addOrderParts,
  deleteShipmentPart,
  fetchRevisionProposal,
  reviewRevisionProposal,
  bulkFilesUpload,
  fetchBulkFilesUploadReport,
  updateDraftOrderLineItems,
  updatePOTargetProfiles,
};
