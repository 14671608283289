import { connect } from "react-redux";
import CompanyRegistration from "./registration.component";
import { profileActions } from "../../../services/profile/profile.actions";
import { modalsActions } from "../../../services/modals/modals.actions";

const mapDispatchToProps = {
  createCompany: profileActions.createCompany,
  addCompanyToProfile: profileActions.addCompanyToProfile,
  handleOpenModal: modalsActions.showModal,
  handleCloseModal: modalsActions.hideModal,
};

function mapStateToProps(state) {
  return {
    user: state.profile.user,
    token: state.profile.token,
    company: state.profile.company,
    isCompanySavingInProgress: state.profile.company.isSavingInProgress,
    isCreateCompanyModalOpen: state.modals ? state.modals.isCreateCompanyModalOpen : false,
    isJoinCompanyModalOpen: state.modals ? state.modals.isJoinCompanyModalOpen : false,
  };
}

export const CompanyRegistrationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyRegistration);
