import { Reducer } from "redux";
import { UserRatingActionTypes } from "./rating.constants";
import { IRatingActionTypes, IRatingInitialState } from "./rating.model";

export const initialState: IRatingInitialState = {
  isRatingInfoLoading: false,
  ratingInfo: {},
  isSubmitRatingLoading: false,
  showRatingSuccess: false,
};

export const userRating: Reducer<IRatingInitialState, IRatingActionTypes> = (
  state = initialState,
  action: IRatingActionTypes
) => {
  switch (action.type) {
    case UserRatingActionTypes.FETCH_USER_RATING_INFO_REQUEST:
      return { ...state, isRatingInfoLoading: true, showRatingSuccess: false };

    case UserRatingActionTypes.FETCH_USER_RATING_INFO_SUCCESS:
      return {
        ...state,
        isRatingInfoLoading: false,
        ratingInfo: action.ratingInfo,
      };

    case UserRatingActionTypes.FETCH_USER_RATING_INFO_FAILURE:
      return { ...state, isRatingInfoLoading: false };

    case UserRatingActionTypes.SEND_USER_RATING_REQUEST:
      return {
        ...state,
        isSubmitRatingLoading: true,
        showRatingSuccess: false,
      };

    case UserRatingActionTypes.SEND_USER_RATING_SUCCESS:
      return {
        ...state,
        isSubmitRatingLoading: false,
        showRatingSuccess: true,
      };

    case UserRatingActionTypes.SEND_USER_RATING_FAILURE:
      return {
        ...state,
        isSubmitRatingLoading: false,
        showRatingSuccess: false,
      };

    default: {
      return state;
    }
  }
};
