export enum MyPartsTableField {
  SELECTED_CHECKBOX = "selectedPartCheckbox",
  NAME = "name",
  PROCESSES = "processes",
  MATERIAL = "alloy",
  TREATMENT = "treatment",
  ATTACHMENTS = "part_uploads_list",
  ADDITIONAL_DETAILS = "additional_details",
  ADDITIONAL_DETAILS_RICH_TEXT = "additional_details_rich_text",
  RFQ = "rfq",
  EDIT = "edit",
  CONTEXT_MENU = "contextMenu",
  QUANTITY = "quantity",
  ALLOY = "alloy",
  FILES = "files",
  DISPLAY_NAME = "rfq.display_name",
  PRICE = "price",
  QUOTATIONS_COUNT = "quotations_count",
  QUOTATION_SUBMISSION_DATE = "quotation_submission_at",
  AWARDED_SUPPLIER_NAME = "winner",
}

export type Process = {
  id: number;
  label: string;
  name: string;
  type: string;
};
