import React from "react";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { EditableTemplateCell } from "../table-cells/editable-template-cell";
import { EditableDateTemplateCell } from "../table-cells/editable-date-template-cell";
import { EditableEvidenceTemplateCell } from "../table-cells/editable-evidence-template-cell";
import { HeaderCell } from "../table-cells/header-cell";
import { Questionnaire } from "@next/modules/vendor-management/redux";
import { ValidationCell } from "../table-cells/validation-cell";
import { SpecCell } from "../table-cells/spec-cell";

export const selectComplianceMenuItems = [
  { key: "fully_compliant", value: "Fully compliant" },
  { key: "compliant_with_rfd", value: "Compliant with RFD" },
  { key: "partially_compliant", value: "Partially compliant" },
  { key: "not_compliant", value: "Not compliant" },
];
const sinceOptionsForDateTemplateCell = ["fully_compliant", "compliant_with_rfd"];
const byOptionsForDateTemplateCell = ["partially_compliant", "not_compliant"];

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  editable: false,
};

export const getLeadTimeForImplementationFormTableColumns = (
  questionnaireName: string,
  isFormDisplayForValidationManagement: boolean,
  isTableDisabled: boolean
) => {
  const baseColumns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "name",
      headerName: "Specification",
      renderCell: (params) =>
        !isFormDisplayForValidationManagement ? (
          <SpecCell {...params} text={params?.row?.name} />
        ) : undefined,
    },
    {
      ...defaultColumnConfig,
      field: "dash_ref",
      headerName: "Dash Ref",
      renderCell: (params) => {
        const value = params.value;
        return value === "nan" ? "n/a" : value;
      },
    },
    {
      ...defaultColumnConfig,
      field: "revision",
      headerName: "Revision",
    },
    {
      ...defaultColumnConfig,
      field: "controlled_process",
      headerName: "Controlled process",
    },
    {
      ...defaultColumnConfig,
      field: "description",
      headerName: "Description",
    },
  ];

  const columnsToAdd: GridColDef[] = [];

  const cocColumn = {
    ...defaultColumnConfig,
    field: "coc",
    headerName: "Coc or equivalent to demonstrate ACLP Material integration within your ERP",
    renderHeader: (params: GridColumnHeaderParams) => <HeaderCell {...params} required={true} />,
    renderCell: (params: GridRenderCellParams) => (
      <EditableEvidenceTemplateCell
        fieldForCheckSelected={"compliance_status"}
        isTableDisabled={isTableDisabled || params.row?.approval_status === "approved"}
        {...params}
      />
    ),
  };

  const sourceOfSupplyColumn = {
    ...defaultColumnConfig,
    field: "source_of_supply",
    headerName: "Source of supply",
    renderHeader: (params: GridColumnHeaderParams) => <HeaderCell {...params} />,
    valueFormatter: (params) => {
      return params?.value?.map((item) => item?.title)?.join(",");
    },
  };

  const manufacturingSiteColumn = {
    ...defaultColumnConfig,
    field: "manufacturing_site",
    headerName: "Manufacturing site",
    renderHeader: (params: GridColumnHeaderParams) => <HeaderCell {...params} />,
  };

  const complianceColumn = {
    ...defaultColumnConfig,
    field: "compliance_status",
    headerName: "Compliance",
    renderHeader: (params: GridColumnHeaderParams) => <HeaderCell {...params} required={true} />,
    renderCell: (params: GridRenderCellParams) => {
      const complianceText =
        selectComplianceMenuItems.find((item) => item.key === params.value)?.value || params.value;

      return (
        <EditableTemplateCell
          {...params}
          icon={
            !(isTableDisabled || params.row?.approval_status === "approved") ? (
              <KeyboardArrowDownIcon />
            ) : null
          }
          placeholderText="Select an option"
          value={complianceText}
        />
      );
    },
  };

  const poeColumn = {
    ...defaultColumnConfig,
    field: "poe_per_line",
    headerName: "POE (Point of Embodiment)",
    renderHeader: (params: GridColumnHeaderParams) => <HeaderCell {...params} required={true} />,
    renderCell: (params: GridRenderCellParams) => (
      <EditableDateTemplateCell
        checkField={"compliance_status"}
        sinceOptions={sinceOptionsForDateTemplateCell}
        byOptions={byOptionsForDateTemplateCell}
        isTableDisabled={isTableDisabled || params.row?.approval_status === "approved"}
        {...params}
      />
    ),
  };

  const actionPlanColumn = {
    ...defaultColumnConfig,
    field: "evidence",
    headerName:
      questionnaireName !== Questionnaire.PomManufacturer
        ? "Add action plan / RFD"
        : "Action plan (file)",
    renderHeader: (params: GridColumnHeaderParams) => <HeaderCell {...params} required={true} />,
    renderCell: (params: GridRenderCellParams) => (
      <EditableEvidenceTemplateCell
        fieldForCheckSelected={"compliance_status"}
        isTableDisabled={isTableDisabled || params.row?.approval_status === "approved"}
        {...params}
      />
    ),
  };

  const a0xStockColumn = {
    ...defaultColumnConfig,
    field: "ao_x_stock",
    headerName: "A0x/A2Ms stock",
    headerAlign: "left" as const,
    type: "number",
    editable: !isTableDisabled,
    renderHeader: (params: GridColumnHeaderParams) => <HeaderCell {...params} required={true} />,
    renderCell: (params: GridRenderCellParams) => (
      <EditableTemplateCell {...params} placeholderText="Type a number..." />
    ),
  };

  const b0xStockColumn = {
    ...defaultColumnConfig,
    field: "bo_x_stock",
    headerName: "B0x/BAMs stock",
    headerAlign: "left" as const,
    type: "number",
    editable: !isTableDisabled,
    renderHeader: (params: GridColumnHeaderParams) => <HeaderCell {...params} required={true} />,
    renderCell: (params: GridRenderCellParams) => (
      <EditableTemplateCell {...params} placeholderText="Type a number..." />
    ),
  };

  if (questionnaireName === Questionnaire.PomDistributor) {
    columnsToAdd.push(sourceOfSupplyColumn);
    columnsToAdd.push(manufacturingSiteColumn);
    columnsToAdd.push(complianceColumn);
    columnsToAdd.push(poeColumn);
    columnsToAdd.push(cocColumn);
  } else {
    columnsToAdd.push(complianceColumn);
    columnsToAdd.push(poeColumn);
    columnsToAdd.push(actionPlanColumn);
    columnsToAdd.push(a0xStockColumn);
    columnsToAdd.push(b0xStockColumn);
  }

  if (isFormDisplayForValidationManagement) {
    const validationColumn = {
      ...defaultColumnConfig,
      field: "action",
      headerName: "Action",
      renderCell: (params: GridRenderCellParams) => {
        return <ValidationCell params={params} />;
      },
      minWidth: 180,
    };

    columnsToAdd.push(validationColumn);
  }

  return [...baseColumns, ...columnsToAdd];
};
