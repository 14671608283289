import React, { useMemo } from "react";

import { Box, Divider } from "@mui/material";
import { TabsView } from "components/utils/tabs-view/tabs-view.component";
import { ProjectPartTable } from "../part/project-part-table";
import useLocalStorage from "hooks/useLocalStorage";
import { ProjectOrderTable } from "../order/project-order-table";
import { getOrderColumns } from "../order/project-order-table.columns";
import { useSelector } from "react-redux";
import { selectProjectCompanyOrdersLoading } from "../../redux/selectors";
import { useParams } from "react-router-dom";
import { TabName } from "components/common/table-list/types";
import { TabPanel } from "@next/components/tab-panel";
import i18n from "assets/configi18n/i18n";

enum ETabType {
  PARTS = 0,
  ORDERS = 1,
}

const tabItems = [
  { tabName: TabName.PARTS, length: false },
  { tabName: TabName.ORDERS, length: false },
];

const ProjectTabPanel: React.FC = () => {
  const [tabIndex, setTabIndex] = useLocalStorage<ETabType>("lastProjectTabIndex", ETabType.PARTS);
  const { code: projectCode = "" } = useParams<{ code: string }>();
  const companyOrdersLoading = useSelector(selectProjectCompanyOrdersLoading);

  const tabHandleChange = (_: any, index: ETabType) => setTabIndex(index);

  const orderTableColumns = useMemo(
    () => getOrderColumns(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyOrdersLoading, i18n?.language]
  );

  return (
    <>
      <TabsView
        tabItems={tabItems}
        tabHandleChange={tabHandleChange}
        tabValue={tabIndex}
        className="c-project__tabs"
      />
      <Box width={"100%"}>
        <Divider light />

        <TabPanel
          value={tabIndex}
          index={ETabType.PARTS}
          innerProps={{
            sx: { px: "24px" },
          }}
        >
          <ProjectPartTable />
        </TabPanel>

        <TabPanel
          value={tabIndex}
          index={ETabType.ORDERS}
          innerProps={{
            sx: { px: "24px" },
          }}
        >
          <ProjectOrderTable
            rfqDetailOpenVariant="modal"
            dataGridProProps={{
              columns: orderTableColumns,
            }}
            query={`status=InProgress&status=Waiting&status=Contract&project=${projectCode}`}
          />
        </TabPanel>
      </Box>
    </>
  );
};

export default ProjectTabPanel;
