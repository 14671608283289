import React from "react";
import { CircularProgress, CircularProgressProps, Grid } from "@mui/material";

type Props = {
  circularProgressProps?: CircularProgressProps;
};

const CustomCircularProgress: React.FC<Props> = ({ circularProgressProps }) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: "100%", marginTop: "16px" }}
    >
      <Grid item>
        <CircularProgress {...circularProgressProps} />
      </Grid>
    </Grid>
  );
};

export default CustomCircularProgress;
