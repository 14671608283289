import React from "react";
import { Box, Button, Typography } from "@mui/material";
import bgImg from "assets/img/profile-views-blur-bg.png";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useDispatch } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: `url(${bgImg}) no-repeat left top`,
      backgroundSize: "cover",
      width: "100%",
      height: "215px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "24px",
    },
  })
);

const ProfileViewsLocked: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClick = () => {
    dispatch(
      modalsActions.showModal({
        key: SharedModalTypes.UPGRADE_TO_PREMIUM_MODAL,
      })
    );
  };

  return (
    <Box className={classes.root}>
      <Typography variant="body1">{t("sellerDashboard:profileViewsLockedMsg")}</Typography>
      <Button
        onClick={onClick}
        variant="contained"
        color="primary"
        startIcon={<LockOpenIcon />}
        data-pid="btnSellerUpgradeToPremium"
      >
        {t("sellerDashboard:upgradeToPremium")}
      </Button>
    </Box>
  );
};

export default ProfileViewsLocked;
