// General imports
import React from "react";
import { Card, CardContent, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

export const WorkspaceContentLoader = ({ showHeader = false }) => {
  const partsLoader = () =>
    new Array(8).fill("").map((_, i) => {
      return (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Card>
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={180}
              style={{ marginTop: 6 }}
            />
            <CardContent>
              <React.Fragment>
                <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} width="40%" />
                <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} width="80%" />
                <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} width="80%" />
                <Skeleton animation="wave" height={10} width="80%" />
              </React.Fragment>
            </CardContent>
          </Card>
        </Grid>
      );
    });

  return (
    <>
      {showHeader && (
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            style={{ margin: 8, marginBottom: 0 }}
          >
            <Grid item xs={6} container direction="row">
              <Skeleton animation="wave" height={80} style={{ marginRight: 6 }} width="200px" />
              <Skeleton animation="wave" height={80} width="280px" />
            </Grid>

            <Grid
              item
              xs={6}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Skeleton animation="wave" height={40} style={{ marginRight: 6 }} width="200px" />
              <Skeleton animation="wave" height={80} width="200px" />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ padding: "0 12px" }}>
            <Skeleton animation="wave" height={40} width="200px" />
          </Grid>
        </>
      )}
      {partsLoader()}
    </>
  );
};
