import { connect } from "react-redux";
import CompanyProfile from "./user-company.component";
import { profileActions } from "../../services/profile/profile.actions";
import { modalsActions } from "../../services/modals/modals.actions";

const mapDispatchToProps = {
  updateCompany: profileActions.editCompany,
  handleOpenModal: modalsActions.showModal,
  handleCloseModal: modalsActions.hideModal,
};

function mapStateToProps(state) {
  return {
    company: state.profile.company,
    user: state.profile.user,
    isEditCompanyModalOpen: state.modals ? state.modals.isEditCompanyModalOpen : false,
    companyCapabilities: state.companyCapabilities,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);
