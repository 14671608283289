import React, { useState } from "react";
import { IconButton, SvgIconProps } from "@mui/material";
import { ProjectsTableRowMenu } from "../projects-table-row-menu";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { useSelector } from "react-redux";
import { selectProjectStats } from "@next/modules/project/redux/selectors";
import { ProjectCreateRenameModal } from "@next/modules/project/modals/project-create-rename-modal";
import { ProjectDeleteModal } from "@next/modules/project/modals/project-delete-modal";

type Props = {
  Icon?: React.ComponentType<SvgIconProps>;
};

export const ProjectOptionsMenu: React.FC<Props> = ({ Icon = MoreHorizOutlinedIcon }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const projectStats = useSelector(selectProjectStats);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="large"
        aria-haspopup="true"
        aria-controls="project-options-menu"
      >
        <Icon style={{ color: "black" }} />
      </IconButton>
      <ProjectsTableRowMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        projectDetails={projectStats}
      />

      <ProjectCreateRenameModal />
      <ProjectDeleteModal gotoProjectsListAfterDelete />
    </>
  );
};
