import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";
import { GridColumns } from "@mui/x-data-grid-pro-v5";

interface CSViewerDataGridProps {
  file: FileData;
}

const CSVViewerDataGrid: React.FC<CSViewerDataGridProps> = ({ file }) => {
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<GridColumns>([]);

  useEffect(() => {
    const makeColumns = (rawColumns) => {
      return rawColumns.map((column) => {
        return { field: column, headerName: column, width: 150 };
      });
    };
    const handleFileChange = (file) => {
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        complete: handleDataChange,
      });
    };
    const handleDataChange = (file) => {
      const uniqueDataWithIds = file.data.map((row: any, index: number) => ({
        id: index + 1,
        ...row,
      }));
      setData(uniqueDataWithIds);
      setColumns(makeColumns(file.meta.fields));
    };
    handleFileChange(file);
  }, [file]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        float: "left",
        position: "absolute",
        left: 0,
        top: 0,
        padding: 5,
        margin: 0,
        background: "white",
        overflowY: "auto",
      }}
    >
      <DataGridProV5
        columns={columns}
        rows={data}
        rowsPerPageOptions={[100]}
        pagination
        pageSize={100}
      />
    </div>
  );
};

export default CSVViewerDataGrid;
