import React from "react";

import { Button, Chip, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const OneTag = ({ onClickOpenTagDialog, tags, t }) => {
  return (
    <div className="c-one-tag">
      <Chip label={tags[0]["name"]} variant="outlined" className="c-one-tag--chip" />

      <Tooltip title={t("addTag")}>
        <Button variant="contained" onClick={onClickOpenTagDialog} className="c-one-tag--avatar">
          <AddIcon className="c-one-tag--add-icon" />
        </Button>
      </Tooltip>
    </div>
  );
};
