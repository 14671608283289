// Backend Urls
import { backendUrl } from "../../urls";

// Helper
import { handleResponse } from "../../helpers/responseHandling";
import { axiosApi } from "@next/api";

export const partConfigurationApi = {
  fetchPartConfigurationData,
};

// Will fetch the static data that are admin entered
function fetchPartConfigurationData() {
  return axiosApi.post(backendUrl.partConfiguration).then(handleResponse);
}
