import { modalsConstants } from "./modals.constants";

export const modals = (state = {}, action) => {
  switch (action.type) {
    case modalsConstants.SHOW_MODAL:
      let { modalID, isModalOpen } = action;
      if (modalID) {
        let modalData = {
          [modalID]: isModalOpen,
        };
        return Object.assign({}, state, modalData);
      }
      break;

    case modalsConstants.HIDE_MODAL:
      let modalId = action.modalID;
      if (modalId) {
        let modalData = {
          [modalId]: false,
        };
        return Object.assign({}, state, modalData);
      }
      break;

    default:
      return state;
  }
};
