import React from "react";
import { ActionPanelBox } from "./order-detail-actions-panel.styled";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReviewOrderForm } from "../../review-order-form/review-order-form";
import { batch, useDispatch, useSelector } from "react-redux";
import { ReviewOrderFormState } from "../../review-order-form/review-order-form.definitions";
import { convertLineItemChangesToUpdatedLineItems } from "@next/modules/orders/redux/utils";
import {
  selectLineItemsTableSelectionModel,
  selectNotSavedChangesInLineItems,
  selectUpdatePortalOrderDeliveryDateLoading,
} from "@next/modules/orders/redux/selectors";
import {
  ActionPanelType,
  LineItemType,
  OrderLineItem,
  OrderLineItemChanges,
  ordersActions,
} from "@next/modules/orders/redux";
import { useQueryParam } from "@next/hooks/useQuery";
import { RightPanelTabs } from "../order-detail-right-tabs/order-detail-right-tabs";
import { formatDate } from "helpers/formatDate";
import { MUIDatePicker } from "@next/components/mui-date-picker";
import { useParams } from "react-router-dom";
import { OrderDetailFinalizeReviewForm } from "../order-detail-finalize-review-form/order-detail-finalize-review-form";

type Props = {
  isPortal?: boolean;
  selectedItemsToUpdate: OrderLineItem[];
};

export const ActionsPanelModifyDeliveryDate: React.FC<Props> = ({
  isPortal,
  selectedItemsToUpdate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const updateUpdatePortalOrderDeliveryDateLoading = useSelector(
    selectUpdatePortalOrderDeliveryDateLoading
  );
  const params = useParams<{ orderToken?: string; qaToken?: string }>();
  const orderToken = params?.orderToken;
  const [_rightPanelTab, setRightPanelTab] = useQueryParam("panelTab");
  const notSavedChangesInLineItems = useSelector(selectNotSavedChangesInLineItems(isPortal));
  const lineItemsTableSelectionModel = useSelector(selectLineItemsTableSelectionModel);

  const onClose = () => {
    batch(() => {
      dispatch(ordersActions.resetUpdatedLineItems({ isPortal }));
      dispatch(ordersActions.setOrderActionPanelType(ActionPanelType.Default));
    });
  };

  const onClickSave = (values: ReviewOrderFormState) => {
    const updatedLineItems = convertLineItemChangesToUpdatedLineItems(notSavedChangesInLineItems);
    if (isPortal) {
      dispatch(
        ordersActions.updatePortalOrderLineItemsRequest({
          orderToken: orderToken,
          updated_line_items: updatedLineItems,
          update_note: values?.update_note,
          confirmation_file: values?.confirmation_file,
          onSuccess: () => {
            dispatch(ordersActions.setOrderActionPanelType(ActionPanelType.Default));
            setRightPanelTab(RightPanelTabs.SUPPLIER_CHAT);
          },
        })
      );
    }
  };

  const handleChange = (value: string, type: LineItemType) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    lineItemsTableSelectionModel?.forEach((id) => {
      dispatch(
        ordersActions.setLineItemNewValue({
          id: id as number,
          newValue: value,
          field: type,
          isPortal,
        })
      );
    });
  };

  return (
    <div>
      <ActionPanelBox>
        <Box display="flex" alignItems="center" gap={2}>
          <CalendarTodayOutlinedIcon />

          <Typography variant="h6">
            {t("orders:orderDetail:actionPanel:modifyDeliveryDate:title")}
          </Typography>
        </Box>
      </ActionPanelBox>
      <Divider />
      <ActionPanelBox>
        <Typography variant="body1">
          {t("orders:orderDetail:actionPanel:modifyDeliveryDate:description")}
        </Typography>
        <MUIDatePicker
          placeholder={t("orders:orderDetail:actionPanel:modifyDeliveryDate:bulkUpdateLabel", {
            count: selectedItemsToUpdate.length,
          })}
          name="shippedDate"
          disabled={selectedItemsToUpdate.length === 0}
          value={""}
          helperText=""
          error={false}
          onChange={(date) => {
            handleChange(formatDate(date, "YYYY-MM-DD"), LineItemType.UPDATED_DELIVERY_DATE);
          }}
          variant="inline"
          size="small"
          disablePast={false}
        />
        <Box width={"100%"}>
          {isPortal ? (
            <ReviewOrderForm
              buttonText={t("common:submit")}
              onClose={onClose}
              onSubmit={onClickSave}
              previewLineItemChanges={true}
              loading={updateUpdatePortalOrderDeliveryDateLoading}
              isPortal={isPortal}
              notePlaceholder={t("orders:orderDetail:actionPanel:modifyDeliveryDate:noteLabel")}
            />
          ) : (
            <OrderDetailFinalizeReviewForm
              onClose={onClose}
              lineItemsChanges={notSavedChangesInLineItems as OrderLineItemChanges[]}
              makeChangesFromBuyer={true}
              onSubmitted={() =>
                dispatch(ordersActions.setOrderActionPanelType(ActionPanelType.Default))
              }
            />
          )}
        </Box>
      </ActionPanelBox>
    </div>
  );
};
