import React from "react";
import ConfirmationDialog from "@next/components/confirmation-dialog/confirmation-dialog";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { batch, useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "./types";

export const SharedAttachFileDeleteConfirmationModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(SharedModalTypes.ATTACH_FILE_DELETE_CONFIRMATION_MODAL));

  const onDeleteOk = modal?.data;

  const closeModal = () => {
    dispatch(modalsActions.closeModal(SharedModalTypes.ATTACH_FILE_DELETE_CONFIRMATION_MODAL));
  };

  const onOk = () => {
    batch(() => {
      dispatch(
        modalsActions.onModalOk({
          key: SharedModalTypes.ATTACH_FILE_DELETE_CONFIRMATION_MODAL,
        })
      );

      if (typeof onDeleteOk === "function") onDeleteOk();

      closeModal();
    });
  };

  return (
    <ConfirmationDialog
      title={t("workspace:deleteThisFile")}
      content={t("workspaceNext:thisActionIsIrreversible")}
      isOpen={!!modal}
      handleClose={closeModal}
      dialogActions={
        <div>
          <Button autoFocus onClick={closeModal}>
            {t("workspace:cancel")}
          </Button>
          <Button onClick={onOk}>{t("RFQ:yes")}</Button>
        </div>
      }
    />
  );
};
