import { modalsActions } from "@next/redux/modalsSlices";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, Form, Formik, FormikErrors } from "formik";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { GenericTextInput } from "components/utils/input/text";
import CustomButton from "@next/components/custom-button";
import * as yup from "yup";
import { User, profileNextActions } from "@next/modules/profile/redux";
import { selectInviteCompanyUserLoading } from "@next/modules/profile/redux/selectors";
import AccessLevelMenuItem from "@next/modules/profile/components/access-level-menu-item/access-level-menu-item";
import { SharedInviteCompanyUserModalData } from "@next/modals/shared-invite-company-user-modal";
import { SharedModalTypes } from "@next/modals/types";
import { getAccessLevelHelperText, getAccessLevelTitle } from "./invite-company-user.utils";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddIcon from "@mui/icons-material/Add";
import { CompanyTypeNext } from "@next/redux/types";
import { getUser } from "services/profile/profile.selectors";
import { LANGUAGE } from "@next/hooks/useLanguageToggle";

type FormikInviteError = FormikErrors<{
  email: string;
  user_type: string;
  language: string;
}>[];

const InviteCompanyUserForm: React.FC<SharedInviteCompanyUserModalData> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(selectInviteCompanyUserLoading);
  const user = useSelector(getUser);
  const isSeller = user?.type !== CompanyTypeNext.DO;

  const onSubmit = useCallback((values) => {
    dispatch(
      profileNextActions.inviteCompanyUserRequest({
        users: values.users,
        onSuccess: (users) => {
          dispatch(modalsActions.closeModal(SharedModalTypes.INVITE_COMPANY_USER_FORM));
          if (typeof onSuccess === "function") onSuccess(users);
        },
      })
    );
  }, []);

  return (
    <Formik
      validationSchema={yup.object().shape({
        users: yup.array().of(
          yup.object().shape({
            email: yup
              .string()
              .email(t("workspaceNext:rfqDrawer:error:invalidEmail"))
              .required(t("common:error:thisFieldIsRequired")),
            ...(isSeller
              ? {}
              : {
                  user_type: yup.string().required(t("common:error:thisFieldIsRequired")),
                }),
            language: yup.string().required(t("common:error:thisFieldIsRequired")),
          })
        ),
      })}
      initialValues={{
        users: [{ email: "", ...(isSeller ? {} : { user_type: "" }), language: "" }],
      }}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, errors, touched }) => {
        const getLanguageError = (index: number) =>
          touched?.users?.[index]?.language &&
          (errors.users as FormikInviteError)?.[index]?.language;

        const getTeamPermissionError = (index: number) =>
          touched?.users?.[index]?.user_type &&
          (errors.users as FormikInviteError)?.[index]?.user_type;

        return (
          <Form>
            <Box>
              <FieldArray name="users">
                {({ push, remove }) => (
                  <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
                    {values.users.map((user, index) => (
                      <Grid container spacing={2} key={index} alignItems={"flex-start"}>
                        <Grid item xs={isSeller ? 6 : 4}>
                          <GenericTextInput
                            name={`users[${index}].email`}
                            label={t("profile:inviteCompanyUserModal:email")}
                            size="small"
                          />
                        </Grid>

                        {!isSeller && (
                          <Grid item xs={4}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                id={`user_type_${index}`}
                                error={!!getTeamPermissionError(index)}
                                size="small"
                              >
                                {t("profile:inviteCompanyUserModal:teamPermission")}
                              </InputLabel>
                              <Select
                                labelId={`user_type_${index}`}
                                label={t("profile:inviteCompanyUserModal:teamPermission")}
                                size="small"
                                value={user.user_type}
                                onChange={(e) =>
                                  setFieldValue(`users[${index}].user_type`, e.target.value)
                                }
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "center",
                                  },
                                }}
                                renderValue={(value) =>
                                  getAccessLevelTitle(value as User["user_type"])
                                }
                                error={!!getTeamPermissionError(index)}
                              >
                                <MenuItem value="normal">
                                  <AccessLevelMenuItem
                                    title={getAccessLevelTitle("normal")}
                                    helperText={getAccessLevelHelperText("normal")}
                                  />
                                </MenuItem>
                                <MenuItem value="viewer">
                                  <AccessLevelMenuItem
                                    title={getAccessLevelTitle("viewer")}
                                    helperText={getAccessLevelHelperText("viewer")}
                                  />
                                </MenuItem>
                                <MenuItem value="restricted_viewer">
                                  <AccessLevelMenuItem
                                    title={getAccessLevelTitle("restricted_viewer")}
                                    helperText={getAccessLevelHelperText("restricted_viewer")}
                                  />
                                </MenuItem>
                              </Select>
                              {!!getTeamPermissionError && (
                                <FormHelperText error>
                                  {getTeamPermissionError(index)}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        )}

                        <Grid
                          item
                          xs={
                            values.users.length > 1 && isSeller
                              ? 5
                              : isSeller
                                ? 6
                                : values.users.length > 1
                                  ? 3
                                  : 4
                          }
                        >
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel
                              id={`language_${index}`}
                              error={!!getLanguageError(index)}
                              size="small"
                            >
                              {t("profile:inviteCompanyUserModal:language")}
                            </InputLabel>
                            <Select
                              labelId={`language_${index}`}
                              label={t("profile:inviteCompanyUserModal:language")}
                              size="small"
                              value={user.language}
                              onChange={(e) =>
                                setFieldValue(`users[${index}].language`, e.target.value)
                              }
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "center",
                                },
                              }}
                              renderValue={(value) =>
                                value === LANGUAGE.FR
                                  ? t("profile:inviteCompanyUserModal:french")
                                  : value === LANGUAGE.EN
                                    ? t("profile:inviteCompanyUserModal:english")
                                    : ""
                              }
                              error={!!getLanguageError(index)}
                            >
                              <MenuItem value={LANGUAGE.FR}>
                                {t("profile:inviteCompanyUserModal:french")}
                              </MenuItem>
                              <MenuItem value={LANGUAGE.EN}>
                                {t("profile:inviteCompanyUserModal:english")}
                              </MenuItem>
                            </Select>
                            {!!getLanguageError && (
                              <FormHelperText error>{getLanguageError(index)}</FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        {values.users.length > 1 && (
                          <Grid item xs={1}>
                            <IconButton onClick={() => remove(index)}>
                              <HighlightOffIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    ))}

                    <Grid item xs={12}>
                      <CustomButton
                        variant="outlined"
                        color="secondary"
                        size="large"
                        startIcon={<AddIcon />}
                        onClick={() =>
                          push({
                            email: "",
                            ...(isSeller ? {} : { user_type: "" }),
                            language: "",
                          })
                        }
                      >
                        {t("profile:inviteCompanyUserModal:addAnInvite")}
                      </CustomButton>
                    </Grid>
                  </Box>
                )}
              </FieldArray>
            </Box>

            <Box display={"flex"} justifyContent={"flex-end"}>
              <CustomButton
                loading={loading}
                type="submit"
                size="large"
                color="primary"
                variant="contained"
              >
                {t("profile:inviteCompanyUserModal:submit")}
              </CustomButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InviteCompanyUserForm;
