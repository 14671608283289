import React from "react";
import { connect } from "react-redux";

// Assets
import logoWhite from "../../../assets/img/logo.svg";

//withTranslation i18n
import { withTranslation } from "react-i18next";

// MaterialUI Specific Imports
import { makeStyles } from "@mui/styles";
import {
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import OutlinedInput from "@mui/material/OutlinedInput";

//Form-Control
import { Formik } from "formik";
import * as yup from "yup";

// Helper functions
import { renderErrorMessage } from "../../../helpers/renderErrorMessage";

import { profileActions } from "../../../services/profile/profile.actions";

function Invitemember(props) {
  // Styling from MaterialUI
  const useStyles = makeStyles((theme) => ({
    logo: {
      width: "150px",
      height: "150px",
      marginLeft: "90px",
    },

    form: {
      marginTop: "25px",
      maxWidth: "335px",
    },

    title: {
      marginLeft: "45px",
    },

    subtitle: {
      marginLeft: "45px",
      marginBottom: "15px",
    },

    divider: {
      marginTop: "5px",
      marginBottom: "20px",
      width: "335px",
    },

    button: {
      width: "335px",
    },
  }));

  const classes = useStyles();

  return (
    <Grid container justifyContent="center" alignItems="center">
      <form onSubmit={props.handleSubmit} className={classes.form}>
        <Grid item xs={12} justifyContent="center">
          <img src={logoWhite} alt="Logo" className={classes.logo} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" alignItems="center" className={classes.title}>
            {props.t("invite_a_member")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid>
          <Typography className={classes.subtitle}>
            {props.t("email_address_of_the_new_member")}
          </Typography>
        </Grid>
        {/*Email Form Group */}
        <Grid item xs={12}>
          <FormGroup controlId=" emailGroup">
            <FormControl error={props.errorEmail} variant="outlined">
              <InputLabel htmlFor="my-input">{props.t("Email_address")}</InputLabel>
              <OutlinedInput
                id={props.id}
                type="text"
                label={props.t("enter_the_email_address_of_the_member_you_wish_to_invite")}
                placeholder={props.t("enter_the_email_address_of_the_member_you_wish_to_invite")}
                name={props.Email}
                value={props.valuesEmail}
                onChange={props.onChangeEmail}
              />
              <FormHelperText id="email-helper-text" error={props.errorEmail}>
                {props.errorEmail}
              </FormHelperText>
            </FormControl>
          </FormGroup>
        </Grid>

        {/* Backend Error Showing */}
        <Grid container>
          <Grid item>
            <FormControl type="hidden" error={props.AlertType} />
            <TextField type="hidden">{props.renderErrorMessage}</TextField>
          </Grid>
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            className={classes.button}
          >
            {props.t("invite")}
          </Button>
        </Grid>
      </form>
    </Grid>
  );
}

class InviteMemberForm extends React.Component {
  constructor(props) {
    super(props);

    // function binding of 'this'
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  render() {
    const { t } = this.props;
    const schema = yup.object({
      email: yup
        .string()
        .required(t("a_recovery_email_address_is_required"))
        .email(t("a_valid_email_address_is_required")),
    });
    return (
      <React.Fragment>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          initialValues={{
            email: "",
          }}
        >
          {({ handleSubmit, handleChange, touched, values, errors }) => (
            <Invitemember
              handleSubmit={handleSubmit}
              Email="email"
              // valuesEmail = {email}
              onChangeEmail={handleChange}
              errorEmail={touched.email && errors.email}
              t={t}
              renderErrorMessage={renderErrorMessage(this.props.alert.message, t)}
              AlertType={this.props.alert.type}
            />
          )}
        </Formik>
      </React.Fragment>
    );
  }

  handleSubmit(values) {
    const email = values;
    const { dispatch, token } = this.props;

    dispatch(profileActions.inviteMember(token, email));
  }
}

function mapStateToProps(state) {
  return {
    dispatch: state.dispatch,
    alert: state.alert,
    token: state.profile.token,
  };
}

export default connect(mapStateToProps)(withTranslation("INVITE_MEMBER_FORM")(InviteMemberForm));
