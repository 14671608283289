import { Avatar, Badge, Theme, Tooltip, Typography } from "@mui/material";
import React, { ComponentProps, ReactNode, useMemo } from "react";
import { getInitials } from "@next/utils/stringUtils";
import { createStyles, makeStyles } from "@mui/styles";

export enum AvatarSizes {
  small = 24,
  medium = 32,
  large = 40,
}

type StyleProps = {
  size?: keyof typeof AvatarSizes;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => {
  const defaultSize = "medium";
  const avatarSize = ({ size }: StyleProps) => AvatarSizes[size || defaultSize];
  const iconSize = ({ size }: StyleProps) => avatarSize({ size }) / 2;

  return createStyles({
    avatar: {
      width: avatarSize,
      height: avatarSize,
      backgroundColor: theme.palette.grey[300],
    },
    name: {
      lineHeight: "unset",
      color: theme.palette.text.primary,
      fontSize: ({ size }) => 6 + avatarSize({ size }) / 4,
    },
    badge: {
      backgroundColor: theme.palette.common.white,
      width: iconSize,
      height: iconSize,
      borderRadius: "50%",
    },
  });
});

export interface GenericAvatarProps {
  size?: keyof typeof AvatarSizes;
  url?: string;
  name?: string;
  email?: string;
  exactName?: string;
  tooltip?: ReactNode;
  badge?: boolean;
  avatarProps?: ComponentProps<typeof Avatar>;
  badgeProps?: ComponentProps<typeof Badge>;
  typographyProps?: ComponentProps<typeof Typography>;
}

const GenericAvatar: React.FC<GenericAvatarProps> = ({
  size,
  url,
  name,
  exactName,
  tooltip,
  badge,
  avatarProps,
  badgeProps,
  typographyProps,
  email,
}) => {
  const classes = useStyles({ size });
  const initials = useMemo(() => (name || email ? getInitials(name || email) : ""), [name, email]);
  const finalUrl = url?.includes("Profile-image-supplier.jpg") ? "" : url;

  let node = (
    <Avatar
      alt={exactName || name}
      src={finalUrl}
      {...avatarProps}
      className={`${classes.avatar} ${avatarProps?.className || ""}`}
    >
      <Typography
        variant="caption"
        {...typographyProps}
        className={`${classes.name} ${typographyProps?.className || ""}`}
      >
        {exactName || initials}
      </Typography>
    </Avatar>
  );

  if (badge)
    node = (
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        {...badgeProps}
      >
        {node}
      </Badge>
    );

  return tooltip ? <Tooltip title={tooltip}>{node}</Tooltip> : node;
};

export default GenericAvatar;
