import { OrderFollowUp, OrderFollowupStateSlug } from "@next/modules/orders/redux";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import { AppTheme } from "layout/AppTheme";
import { alpha } from "@mui/material";

export const getFollowupTimelineIcon = (followUp: OrderFollowUp["type"]) => {
  switch (followUp) {
    case "acknowledgement_followup":
      return ThumbUpOutlinedIcon;
    case "production_followup":
      return EventAvailableIcon;
    case "shipping_followup":
      return LocalShippingOutlinedIcon;
  }
};

const baseStyle: React.CSSProperties = {
  cursor: "unset",
  textOverflow: "ellipsis",
  overflow: "hidden",
};

export const getFollowupStateTagStyle = (
  stateSlug?: OrderFollowupStateSlug
): React.CSSProperties | undefined => {
  switch (stateSlug) {
    case OrderFollowupStateSlug.SCHEDULED:
      return {
        ...baseStyle,
        color: AppTheme.palette.primary.dark,
        backgroundColor: alpha(AppTheme.palette.primary.main, 0.1),
      };
    case OrderFollowupStateSlug.PENDING:
      return {
        ...baseStyle,
        color: AppTheme.palette.warning.dark,
        backgroundColor: alpha(AppTheme.palette.warning.main, 0.1),
      };
    case OrderFollowupStateSlug.SENT:
      return {
        ...baseStyle,
        color: AppTheme.palette.success.dark,
        backgroundColor: alpha(AppTheme.palette.success.main, 0.1),
      };
    case OrderFollowupStateSlug.MUTED:
    default:
      return undefined;
  }
};
