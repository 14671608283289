import React from "react";
import { Button } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import * as S from "./analytic-filters.styled";

import { AnalyticFiltersTimeframeDropdown } from "./analytic-filters-timeframe-dropdown";
import AnaltyticFiltersSuppliersAutoCompleteDropdown from "./analtyic-filters-suppliers-autocomplete-dropdown";

type Props = {
  isInSupplierReport: boolean;
};

export const AnalyticFilters: React.FC<Props> = ({ isInSupplierReport }) => {
  return (
    <S.RootDiv>
      <S.InnerDiv>
        {!isInSupplierReport ? <AnaltyticFiltersSuppliersAutoCompleteDropdown /> : null}

        <AnalyticFiltersTimeframeDropdown />

        {isInSupplierReport ? (
          <Button variant="outlined" style={{ marginLeft: "12px" }}>
            <CustomTypography variant="body2">Export</CustomTypography>
          </Button>
        ) : null}
      </S.InnerDiv>
    </S.RootDiv>
  );
};
