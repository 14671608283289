import React from "react";
import { useTranslation } from "react-i18next";

import { Form, FormikProps } from "formik";
import { Button, Grid } from "@mui/material";
import { GenericTextInput } from "components/utils/input/text";
import { EditProfileFormValues } from "./edit-user-profile-form.types";
import ChangeAvatar from "../../../../change-avatar";
import { useSelector } from "react-redux";
import { selectUpdateUserProfileLoading } from "@next/modules/profile/redux/selectors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export const EditProfileFormBase: React.FC<FormikProps<EditProfileFormValues>> = ({
  values,
  handleSubmit,
  setFieldValue,
  dirty,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const updateUserProfileLoading = useSelector(selectUpdateUserProfileLoading);

  const handleOnChangeAvatar = (file: File) => {
    setFieldValue("picture", file);
  };

  return (
    <Form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ChangeAvatar value={values?.picture} onChange={handleOnChangeAvatar} />
        </Grid>

        <Grid item xs={12}>
          <GenericTextInput
            name="first_name"
            label={t("profile:inputs:firstName")}
            value={values.first_name}
          />
        </Grid>

        <Grid item xs={12}>
          <GenericTextInput
            name="last_name"
            label={t("profile:inputs:lastName")}
            value={values.last_name}
          />
        </Grid>

        <Grid item xs={12} className={classes.flexEnd}>
          <Button variant="outlined" type="submit" disabled={updateUserProfileLoading || !dirty}>
            {t("common:save")}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};
