type Capability = {
  pk: number;
  slug: string;
  name: string;
};

export enum CapabilityTypes {
  CAPABILITIES = "capabilities",
  CERTIFICATIONS = "certifications",
  INDUSTRY_EXPERTISE = "industry_expertise",
}

export type CompanyCapabilityType = {
  pk: number;
  slug: string;
  name: string;
  parent: string;
  capabilities: Capability[];
};

export type CompanyCapabilitiesState = {
  companyCapabilities: number[] | undefined;

  companyCapabilitesTypesLoading: boolean;
  companyCapabilitesTypesError: Error | undefined;
  companyCapabilitesTypes:
    | {
        [key: string]: CompanyCapabilityType[];
      }
    | undefined;

  patchCompanyCapabilitiesLoading: boolean;
  patchCompanyCapabilitiesError: Error | undefined;
};

export type FetchCompanyCapabilitiesTypesInput = {
  type?: string;
};

export type FetchCompanyCapabilitiesTypesResponse = {
  data: CompanyCapabilityType[];
  type?: string;
};

export type PatchCompanyCapabilitiesInput = {
  capabilities: number[];
  capabilities_comment?: string;
  onSuccess?: () => void;
};

export type NormalizedCapabilitiesType = {
  [parent: string]: Omit<CompanyCapabilityType, "parent">[];
};
