import { Theme } from "@mui/material";
import React from "react";
import EmojiObjectsRoundedIcon from "@mui/icons-material/EmojiObjectsRounded";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useDispatch, useSelector } from "react-redux";
import { projectActions } from "../../redux";
import {
  selectExportMultiContractRFQExcelLoading,
  selectImportMultiContractRFQExcelLoading,
} from "../../redux/selectors";
import CustomButton from "@next/components/custom-button/custom-button";
import { pickFiles } from "@next/utils/fileUtils";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: { padding: 24 },
    text: {
      display: "flex",
    },
    buttons: {
      display: "flex",
      marginTop: "24px",
    },
    leftIcon: {
      color: theme.palette.warning.main,
      marginRight: "20px",
    },
    downloadButton: {
      marginRight: "20px",
      flex: 1,
    },
  })
);

type Props = {
  rfqPk: number;
  rfqName?: string;
  onlyExcelImport?: boolean;
};

export const CreateMultiContractExcelImport: React.FC<Props> = ({
  rfqPk,
  rfqName,
  onlyExcelImport,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const exportMultiContractRFQExcelLoading = useSelector(selectExportMultiContractRFQExcelLoading);
  const importMultiContractRFQExcelLoading = useSelector(selectImportMultiContractRFQExcelLoading);

  const onClickDownloadExcel = () => {
    dispatch(
      projectActions.exportMultiContractRFQExcelRequest({
        rfq_pk: rfqPk,
        rfq_name: rfqName,
      })
    );
  };

  const onClickImportExcel = () => {
    pickFiles({
      accept: ".xlsx",
      onChange: (files) => {
        if (!files.length) return;

        const pickedFile = files[0];

        dispatch(
          projectActions.importMultiContractRFQExcelRequest({
            rfq_pk: rfqPk,
            file: pickedFile,
          })
        );
      },
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.text}>
        <EmojiObjectsRoundedIcon className={classes.leftIcon} />

        <div>
          {t(
            onlyExcelImport
              ? "project:multiContract:excelImportDescriptionOnly"
              : "project:multiContract:excelImportDescription"
          )}
        </div>
      </div>
      <div className={classes.buttons}>
        <CustomButton
          variant="outlined"
          color="primary"
          className={classes.downloadButton}
          size="large"
          startIcon={<ArrowDownwardIcon />}
          onClick={onClickDownloadExcel}
          loading={exportMultiContractRFQExcelLoading}
        >
          {t("project:multiContract:excelExportButton")}
        </CustomButton>

        <CustomButton
          variant="outlined"
          color="primary"
          size="large"
          className={classes.importButton}
          startIcon={<ArrowUpwardIcon />}
          onClick={onClickImportExcel}
          loading={importMultiContractRFQExcelLoading}
        >
          {t("project:multiContract:excelImportButton")}
        </CustomButton>
      </div>
    </div>
  );
};
