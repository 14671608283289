import React from "react";

import { Box, Theme } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid-pro-v5";
import { useSellerDashboardBuyerProfilePastJobsTableData } from "./seller-dashboard-buyer-profile-past-jobs-table.hooks";
import { batch, useDispatch } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { getRowIdToPk } from "@next/utils/dataGridUtils-v5";
import { BuyerProfile, dashboardActions } from "@next/modules/dashboard/redux";
import { createStyles, makeStyles } from "@mui/styles";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";
import { SharedModalTypes } from "@next/modals/types";

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      border: "none",
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  })
);

type Props = {
  parts?: BuyerProfile["parts"];
};

export const SellerDashboardBuyerProfilePastJobsTable: React.FC<Props> = ({ parts }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const gridData = useSellerDashboardBuyerProfilePastJobsTableData();

  const getRowClassName = () => "c-cursor-pointer";

  const onRowClick = (params: GridRowParams) => {
    batch(() => {
      // First start fetch buyer profile part data
      dispatch(
        dashboardActions.fetchBuyerProfilePartRequest({
          partId: params?.row?.pk,
        })
      );

      dispatch(
        modalsActions.showModal({
          key: SharedModalTypes.PART_FILE_MODAL,
          data: {
            readOnly: true,
            isBuyerProfilePart: true,
          },
        })
      );
    });
  };

  return (
    <Box width={"100%"} data-pid="tablePastJobs">
      <DataGridProV5
        autoHeight
        autoPageSize
        rows={parts || gridData?.rows || []}
        columns={gridData?.columns || []}
        hideFooter
        disableSelectionOnClick
        disableColumnResize
        headerHeight={0}
        rowHeight={88}
        getRowClassName={getRowClassName}
        classes={{ root: classes.root }}
        onRowClick={onRowClick}
        getRowId={getRowIdToPk}
      />
    </Box>
  );
};
