import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { Box, styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";

const StyledBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

const StyledTypography = styled(CustomTypography)(({ theme }) => ({
  color: `${theme.palette.text.secondary}`,
  letterSpacing: "0.4px",
}));

type EditableTemplateCellProps = GridRenderCellParams<any> & {
  placeholderText: string;
  icon?: React.ReactNode;
};

export const EditableTemplateCell: React.FC<EditableTemplateCellProps> = (params) => {
  const { value, placeholderText, icon } = params;

  if (value === "" || value === null || value === undefined) {
    return (
      <StyledBox>
        <StyledTypography tooltipTitle={null}>{placeholderText}</StyledTypography>
        {icon ? icon : null}
      </StyledBox>
    );
  }

  return (
    <StyledBox>
      <CustomTypography variant="caption" tooltipTitle={null}>
        {value}
      </CustomTypography>
      {icon ? icon : null}
    </StyledBox>
  );
};
