import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "services/profile/profile.selectors";
import { getNetDs } from "services/part-configuration/part-configuration.selectors";

import { getActiveRfqDetails } from "services/marketplace/marketplace.selectors";
import { NetDsData } from "services/part-configuration/part-configuration.model";
import { isEmpty } from "lodash";

export const usePaymentTerms = () => {
  const user = useSelector(getUser);
  const netDs = useSelector(getNetDs);
  const activeRfqDetails = useSelector(getActiveRfqDetails);

  const [paymentTerms, setPaymentTerms] = useState<NetDsData[]>([]);

  useEffect(() => {
    setPaymentTerms(
      user["type"] === "DO" && isEmpty(activeRfqDetails)
        ? [...netDs]
        : Array.isArray(activeRfqDetails?.net_ds)
          ? [...activeRfqDetails.net_ds]
          : []
    );
  }, [user, activeRfqDetails, netDs]);

  return { paymentTerms };
};
