import React from "react";
import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@material-ui/core";
import * as S from "./loading-screen.styled";

export const LoadingScreen = () => {
  return (
    <S.StyledDiv>
      <CircularProgress color="primary" />
      <Box mt="8px">
        <Typography variant="body1">Loading...</Typography>
      </Box>
    </S.StyledDiv>
  );
};
