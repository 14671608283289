import { useSelector } from "react-redux";

import { ProjectCompanyPart } from "@next/modules/project/redux";

import { getBuyersNetwork } from "services/part-configuration/part-configuration.selectors";
import { getSellerDashboardBuyerViewsTableColumns } from "./seller-dashboard-buyer-views-table.columns";
import { GridColDef } from "@mui/x-data-grid-pro-v5";

export type GridStatePartData = {
  rows: Partial<ProjectCompanyPart>[];
  columns: GridColDef[];
};

export const useSellerDashboardBuyerViewsTableData = (): GridStatePartData => {
  const buyersNetwork = useSelector(getBuyersNetwork);

  return {
    rows: buyersNetwork || [],
    columns: getSellerDashboardBuyerViewsTableColumns() || [],
  };
};
