import React from "react";
import { Box, Dialog, DialogTitle, IconButton, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { WorkspaceModalTypes } from "./types";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import CloseIcon from "@mui/icons-material/Close";
import AskQuestionForm from "../components/questions-answers/ask-question-form";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: { borderBottom: `solid 1px ${theme.palette.divider}` },
  })
);

export const WorkspaceAskQuestionModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(WorkspaceModalTypes.ASK_QUESTION_MODAL));
  const data = modal?.data as {
    rfqId: string;
    buyerName: string;
  };

  if (!modal || !data) return null;

  const onClose = () => {
    dispatch(modalsActions.closeModal(WorkspaceModalTypes.ASK_QUESTION_MODAL));
  };

  return (
    <Dialog open={!!modal} onClose={onClose} closeAfterTransition fullWidth={true}>
      <DialogTitle className={classes.dialogTitle}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}> {t("workspaceNext:askQuestion:contactBuyer")}</Box>
          <Box>
            <IconButton onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <AskQuestionForm rfqId={data?.rfqId} buyerName={data?.buyerName} />
    </Dialog>
  );
};
