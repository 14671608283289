/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridColumns } from "@mui/x-data-grid-pro-v5";
import { useGridData } from "@next/hooks/useGridData-v5";
import { getProjectsColumns } from "./projects-table.columns";
import { projectActions } from "../../redux";
import { selectProjects, selectProjectsLoading } from "../../redux/selectors";
import { generatePaginationAndSearchQuery } from "@next/utils/paginationUtils";
import i18n from "assets/configi18n/i18n";
import { useTableSavedQueryPagination } from "@next/hooks/useTableSavedQueryPagination";
import { PROJECT_TABLE_NAVIGATION_KEY } from "./projects-table";

export type GridStatePartData = {
  rows: Partial<any>[];
  columns: GridColumns;
};

type Props = {
  currentPage: number;
  debouncedSearchValue: string;
};

export const useProjectsTableData = ({ currentPage, debouncedSearchValue }: Props) => {
  const { savedTableQueryPagination } = useTableSavedQueryPagination(PROJECT_TABLE_NAVIGATION_KEY);

  const dispatch = useDispatch();

  const projects = useSelector(selectProjects);
  const projectsLoading = useSelector(selectProjectsLoading);

  const projectsColumns = useMemo(
    () => getProjectsColumns(savedTableQueryPagination?.lsr),
    [projectsLoading, savedTableQueryPagination?.lsr, i18n?.language]
  );

  const { gridData, setGridData } = useGridData({
    rows: projects,
    columns: projectsColumns as GridColumns,
    loading: projectsLoading,
  });

  useEffect(() => {
    dispatch(
      projectActions.fetchProjectsRequest({
        query: generatePaginationAndSearchQuery({
          currentPage,
          searchKey: "search",
          searchValue: debouncedSearchValue,
        }),
      })
    );
  }, [currentPage, debouncedSearchValue]);

  return {
    gridData,
    setGridData,
  };
};
