import { Box, Divider, Theme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { GenericTextInput } from "components/utils/input/text";
import { Form, FormikProps } from "formik";
import MultipleDndFileUpload from "@next/components/multiple-dnd-file-upload";
import { CustomFileObject } from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload.types";
import { useSelector } from "react-redux";
import {
  selectCreatePortalQuoteLoading,
  selectUpdatePortalQuoteLoading,
} from "@next/modules/workspace/redux/selectors";
import CustomButton from "@next/components/custom-button/custom-button";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    title: { color: theme.palette.text.primary },
    note: { marginTop: "24px" },
    divider: { marginTop: "16px", marginLeft: "-24px", marginRight: "-24px" },
  })
);

export const TopicPortalSendQuotationFormBase: React.FC<
  FormikProps<any> & { quote?: any; isRFI: boolean }
> = ({ handleSubmit, values, setFieldValue, quote, isRFI }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const createLoading = useSelector(selectCreatePortalQuoteLoading);
  const updateLoading = useSelector(selectUpdatePortalQuoteLoading);
  const isQuoteAvailable = !!quote;

  const setFilesState = (files: CustomFileObject[]) => {
    setFieldValue("files", files);
  };

  return (
    <Form onSubmit={handleSubmit} className={classes.root}>
      <MultipleDndFileUpload
        filesState={values?.files || []}
        setFilesState={setFilesState}
        disableDndMargin
      />

      <div className={classes.note}>
        <GenericTextInput
          name="additional_details"
          label={t("workspaceNext:QA:topicPortal:addANote")}
          multiline
          rows="4"
        />
      </div>

      <Divider className={classes.divider} />

      <Box display={"flex"} justifyContent="flex-end" mt="16px">
        <CustomButton
          loading={createLoading || updateLoading}
          variant="contained"
          color="primary"
          type="submit"
        >
          {t(
            isQuoteAvailable
              ? isRFI
                ? "workspaceNext:QA:topicPortal:editSubmission"
                : "workspaceNext:QA:topicPortal:editQuote"
              : isRFI
                ? "workspaceNext:QA:topicPortal:sendSubmission"
                : "workspaceNext:QA:topicPortal:sendQuote"
          )}
        </CustomButton>
      </Box>
    </Form>
  );
};
