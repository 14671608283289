import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormAddButton } from "../../../form-add-button/form-add-button";
import { InternationalStandardTable } from "../../../table/international-standard-table/international-standard-table";
import {
  selectCurrentMessageThread,
  selectIsFetchSpecByClickedThread,
  selectIsFormDisplayForValidationManagement,
  selectIsFormStepCompleted,
  selectNameOfTableToFetchSpecByClickedThread,
  selectProcessControlStandardsLoading,
  selectTriggerForFetchingSpecsOfStep,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  A2psThreadLabel,
  InternationalStandardTableRow,
  PartialStep,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./add-international-standard-field.styled";

const addStandardBtnLabel =
  "If you are using international specs for process control, please add them one by one.";

const templateRow: InternationalStandardTableRow = {
  name: "",
  dash_ref: "",
  revision: "",
  controlled_process: "",
  description: "",
  scope_limitation: "",
};

type Props = {
  isTableDisabled: boolean;
};

export const AddInternationalStandardField: React.FC<Props> = ({ isTableDisabled }) => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const activeFormStep = steps[activeFormStepIndex];
  const isFormCompleted = useSelector(selectIsFormStepCompleted);
  const isLoading = useSelector(selectProcessControlStandardsLoading);
  const triggerStateForFetchingSpecs = useSelector(selectTriggerForFetchingSpecsOfStep);
  const currentMessageThread = useSelector(selectCurrentMessageThread);
  const isFetchSpecByClickedThread = useSelector(selectIsFetchSpecByClickedThread);
  const nameOfTableToFetchSpecByClickedThread = useSelector(
    selectNameOfTableToFetchSpecByClickedThread
  );

  useEffect(() => {
    fetchStandards();

    dispatch(
      vendorManagementActions.fetchNumberOfUnapprovedLinesOfStepRequest({
        companyId: companyId,
        stepId: activeFormStep.step,
      })
    );
  }, [triggerStateForFetchingSpecs, isFetchSpecByClickedThread]);

  const fetchStandards = () => {
    if (
      isFetchSpecByClickedThread &&
      nameOfTableToFetchSpecByClickedThread === A2psThreadLabel.InternationalStandard
    ) {
      dispatch(
        vendorManagementActions.fetchProcessControlStandardsRequest({
          companyId: companyId,
          entityId: currentMessageThread?.entity_id,
        })
      );
    } else {
      dispatch(
        vendorManagementActions.fetchProcessControlStandardsRequest({
          companyId: companyId,
        })
      );
    }
  };

  const handleClickAddStandardBtn = () => {
    dispatch(
      vendorManagementActions.addProcessControlStandardRequest({
        companyId: companyId,
        standard: templateRow,
      })
    );
    checkInputChange();
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else {
      updateFieldStatusOfStepForSaveAndCompletion("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const step = steps[activeFormStepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
        onSuccess:
          key === "is_completed" && !value
            ? () => updateApprovalOfStepAfterCompletionChange("changes_requested")
            : undefined,
      })
    );
  };

  const updateApprovalOfStepAfterCompletionChange = (statusParam: "changes_requested") => {
    if (activeFormStep?.approval_status === "changes_done" && statusParam === "changes_requested") {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: activeFormStep.step,
          status: statusParam,
        })
      );
    }
  };

  return (
    <S.StyledBox>
      <S.StyledTableWrapper>
        <InternationalStandardTable isTableDisabled={isTableDisabled} />
      </S.StyledTableWrapper>

      {!isFormDisplayForValidationManagement ? (
        <S.StyledInnerBox>
          <S.StyledBtnBox>
            <FormAddButton
              btnText={"Add international standard"}
              onClick={handleClickAddStandardBtn}
              disabled={
                isLoading ||
                isTableDisabled ||
                (isFetchSpecByClickedThread &&
                  nameOfTableToFetchSpecByClickedThread === A2psThreadLabel.InternationalStandard)
              }
            />
          </S.StyledBtnBox>

          <S.StyledTypography variant="body2">{addStandardBtnLabel}</S.StyledTypography>
        </S.StyledInnerBox>
      ) : null}
    </S.StyledBox>
  );
};
