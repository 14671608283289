import { t } from "assets/configi18n/i18n";
import { formatDate } from "helpers/formatDate";

export type CurrencyConversion = {
  key?: string;
  conversion_rate?: number;
  converted_at?: string;
  calculated_price?: number;
};

export const generateExchangeRateTooltipText = (
  currency: string,
  currencyConversion: CurrencyConversion
) => {
  return t("workspaceNext:exchangeTooltip", {
    currency,
    rate: currencyConversion?.conversion_rate,
    rateDate: formatDate(currencyConversion?.converted_at, "DD MMMM, hh:mm a"),
    key: currencyConversion?.key,
  });
};
