import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";
import { t } from "assets/configi18n/i18n";

type Props = {
  when?: boolean;
  message?: string;
};

const LeavePageBlocker: React.FC<Props> = ({
  when = true,
  message = t("common:pageHasUnsavedChanges") || "",
}) => {
  useEffect(() => {
    if (!when) return () => {};

    const beforeUnloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", beforeUnloadCallback);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadCallback);
    };
  }, [when, message]);

  return <Prompt when={when} message={message} />;
};

export default LeavePageBlocker;
