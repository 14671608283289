/* eslint-disable no-use-before-define */
import _ from "lodash";
import React, { FC, useEffect } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Box,
  Checkbox,
  Theme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomTypography } from "@next/components/custom-typography";
import { createStyles, makeStyles } from "@mui/styles";
import { profileActions } from "services/profile";
import { useDispatch, useSelector } from "react-redux";
import { getProfilePreferences } from "services/profile/profile.selectors";
import { PO_GRID_INTERNAL_NOTES_FILTER } from "@next/constants/data-grid-state-constants";
import { NoteType } from "@next/modules/workspace/redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)`,
    },
    title: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "105px",
      marginLeft: "8px",
    },
    options: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    notesSection: {
      maxWidth: "90px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  })
);

const Container = styled(Box)({
  width: "268px",
});

type Props = {
  internalNotes: NoteType[];
  selectedNote: NoteType[];
  setSelectedNote: (value: NoteType[]) => void;
  language?: string;
};

const OrdersNotesAutocompleteDropdown: FC<Props> = ({
  internalNotes,
  selectedNote,
  setSelectedNote,
  language,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const profilePreferences = useSelector(getProfilePreferences);
  const { editProfilePreferences } = profileActions;
  useEffect(() => {
    const storedSupplierFilter = profilePreferences[PO_GRID_INTERNAL_NOTES_FILTER];
    if (storedSupplierFilter && !_.isEqual(storedSupplierFilter?.data, selectedNote)) {
      setSelectedNote(storedSupplierFilter?.data);
    }
  }, []);

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: NoteType,
    { selected }: AutocompleteRenderOptionState
  ) => {
    return (
      <li {...props}>
        <Box className={classes.options}>
          <Box display="flex" alignItems="center">
            <div
              style={{
                backgroundColor: option?.color,
                height: "16px",
                width: "16px",
                borderRadius: 30,
              }}
            ></div>
            <CustomTypography className={classes.title}>{option?.title}</CustomTypography>
          </Box>
          <Checkbox checked={selected} />
        </Box>
      </li>
    );
  };

  const renderNotes = (tags: NoteType[]) => {
    const updatedNote = internalNotes?.find((item) => item.id === tags[0].id);
    return tags.length > 1 ? (
      <CustomTypography variant="body2" pl={1} fontSize={"16px"}>
        {t("orders:filterNotes", { count: tags.length })}
      </CustomTypography>
    ) : (
      <CustomTypography variant="body2" maxWidth="100px" fontSize={"16px"}>
        {" "}
        {updatedNote?.title || ""}
      </CustomTypography>
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        variant="outlined"
        label={null}
        placeholder={
          selectedNote?.length
            ? t("project:table:row:internalNotes:selectTag")
            : t("project:table:row:internalNotes:allTags")
        }
      />
    );
  };

  const onChange = (_event: any, newNoteSelection: NoteType[]) => {
    setSelectedNote(newNoteSelection);
    dispatch(
      editProfilePreferences({
        type: PO_GRID_INTERNAL_NOTES_FILTER,
        data: newNoteSelection,
      })
    );
  };

  return (
    <Container>
      <Autocomplete
        multiple
        size="small"
        options={internalNotes}
        disableCloseOnSelect
        sx={{ width: language == "en" ? "253px" : "341px" }}
        getOptionLabel={(option: any) => option.title || ""}
        isOptionEqualToValue={(option, values) => option.id === values.id}
        renderOption={renderOption}
        renderTags={(tags: any) => renderNotes(tags)}
        renderInput={renderInput}
        value={selectedNote}
        onChange={onChange}
        classes={{ paper: classes.paper }}
      />
    </Container>
  );
};

export default OrdersNotesAutocompleteDropdown;
