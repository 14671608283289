import {
  GridColTypeDef,
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro-v5";

export const getDefaultStringFilterOperators = () => {
  return getGridStringOperators().filter(
    // only allow contains and equals operators
    (operator) => operator.value === "contains" || operator.value === "equals"
  );
};

export const getDefaultNumberFilterOperators = () => {
  return getGridNumericOperators().filter(
    // only allow equals operator
    (operator) => operator.value === "="
  );
};

export const getDefaultNumberFilterOperatorsV2 = () => {
  return getGridNumericOperators().filter(
    (operator) => operator.value !== "isEmpty" && operator.value !== "isNotEmpty"
  );
};

export const getDefaultDateFilterOperators = () => {
  return getGridDateOperators(false).filter(
    (operator) =>
      operator.value === "is" || operator.value === "onOrAfter" || operator.value === "onOrBefore"
  );
};

export const CustomStringColumnType: GridColTypeDef = {
  type: "string",
  filterOperators: getDefaultStringFilterOperators(),
};

export const CustomNumberColumnType: GridColTypeDef = {
  type: "number",
  filterOperators: getDefaultNumberFilterOperatorsV2(),
};

export const CustomNumberColumnTypeV2: GridColTypeDef = {
  type: "number",
  filterOperators: getDefaultNumberFilterOperatorsV2(),
};

export const CustomDateColumnType: GridColTypeDef = {
  type: "date",
  filterOperators: getDefaultDateFilterOperators(),
};
