import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  transitNumber: Yup.string().required("Required"),
  institutionNumber: Yup.string().required("Required"),
  accountNumber: Yup.string().required("Required"),
});

export const initialValues = {
  transitNumber: "11000",
  institutionNumber: "000",
  accountNumber: "000123456789",
};
