import React from "react";
import NotificationsSwitches from "./notifications-switches/notifications-switches";

export const NotificationSettings = () => {
  return (
    <div>
      <NotificationsSwitches />
    </div>
  );
};
