import { Box, IconButton } from "@mui/material";
import LinkButton from "@next/components/link-button";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel, { Modal, ModalGateway } from "react-images";
import CloseIcon from "@mui/icons-material/Close";
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove, rectSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragIndicator } from "@mui/icons-material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textAlign: "center",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      columnGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
    gridItem: {
      objectFit: "cover",
      borderRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.grey[300]}`,
      width: "100%",
      height: 162,
    },
    closeIcon: {
      backgroundColor: "rgba(0, 0, 0, .7)",
      color: "white",
      "&:hover, &.Mui-focusVisible": { backgroundColor: "rgba(0, 0, 0, .9)" },
    },
  })
);

export enum PaginatedGridGalleryChangeType {
  REORDER = "reorder",
  DELETE = "delete",
}

type Props = {
  images: string[];
  batchSize?: number;
  sortable?: boolean;
  onClickDelete?: (index: number) => void;
  onChange?: (images: string[], type: PaginatedGridGalleryChangeType) => void;
  gridClassName?: string;
  imgClassName?: string;
};

const PaginatedGridGallery: React.FC<Props> = ({
  images,
  batchSize = 3,
  sortable,
  onClickDelete,
  onChange,
  gridClassName,
  imgClassName,
}) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [list, setList] = useState(images.slice(0, batchSize));
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const { t } = useTranslation();

  const sortedImages = useMemo(() => [...list, ...images.slice(list.length)], [list, images]);

  const handleLoadMore = useCallback(() => {
    setList((list) => images.slice(0, list.length + batchSize));
  }, [batchSize]);

  const openLightbox = useCallback((index) => {
    setCurrentImage(index);
    setIsModalOpen(true);
  }, []);

  const closeLightbox = useCallback(() => {
    setCurrentImage(0);
    setIsModalOpen(false);
  }, []);

  const handleDelete = (index: number) => {
    if (onClickDelete) {
      onClickDelete(index);
    }
    if (onChange) {
      onChange(
        sortedImages.filter((_, ix) => ix !== index),
        PaginatedGridGalleryChangeType.DELETE
      );
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const newList = arrayMove(
        list,
        list.indexOf(active.id as string),
        list.indexOf(over?.id as string)
      );
      setList(newList);
      if (onChange)
        onChange(
          [...newList, ...images.slice(newList.length)],
          PaginatedGridGalleryChangeType.REORDER
        );
    } else if (activeId) {
      openLightbox(list.findIndex((img) => img === activeId));
    }

    setActiveId(null);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  useEffect(() => {
    setList(images.slice(0, batchSize));
  }, [images.length]);

  return (
    <div className={classes.root}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext items={list} strategy={rectSortingStrategy} disabled={!sortable}>
          <div className={`${classes.grid} ${gridClassName || ""}`}>
            {list.map((img, ix) => (
              <SortableImg
                key={img}
                src={img}
                imgClassName={imgClassName}
                onClick={() => openLightbox(ix)}
                cursor={sortable ? (activeId ? "grabbing" : "grab") : "pointer"}
                onClickDelete={activeId || !onClickDelete ? undefined : () => handleDelete(ix)}
                activeId={activeId || ""}
                sortable={sortable}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
      {list.length < images.length ? (
        <Box mt={2}>
          <LinkButton onClick={handleLoadMore}>
            {t("paginatedGridGallery:loadMorePhotos")}
          </LinkButton>
        </Box>
      ) : null}
      {/* @ts-ignore */}
      <ModalGateway>
        {isModalOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              views={sortedImages.map((source) => ({ source }))}
              currentIndex={currentImage}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default PaginatedGridGallery;

const SortableImg: React.FC<{
  src: string;
  onClickDelete?: () => void;
  onClick?: () => void;
  imgClassName?: string;
  cursor?: React.CSSProperties["cursor"];
  activeId?: string;
  sortable?: boolean;
}> = ({ src, onClick, onClickDelete, imgClassName, cursor = "pointer", activeId, sortable }) => {
  const classes = useStyles();
  const sortRef = useSortable({ id: src });
  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = sortRef;

  return (
    <Box position="relative">
      <div
        style={{
          transform: CSS.Transform.toString(transform),
          transition,
          opacity: isDragging ? 0.5 : 1,
          position: "relative",
          cursor,
        }}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
      >
        <img
          loading="lazy"
          className={`${classes.gridItem} ${imgClassName || ""}`}
          src={src}
          onClick={onClick}
        />
        {sortable && !activeId && (
          <Box zIndex={1} position="absolute" left="16px" top="16px">
            <DragIndicator />
          </Box>
        )}
      </div>
      {onClickDelete && (
        <Box zIndex={1} position="absolute" right="4px" top="4px">
          <IconButton onClick={onClickDelete} className={classes.closeIcon} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
