import React from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "@mui/material";
import { withStyles } from "@mui/styles";

import { TabName } from "components/common/table-list/types";
import BadgeWrapper from "@next/components/badge-wrapper";

const StyledTabs = withStyles(({ palette }) => ({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: palette.primary.main,
    },
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export const TabsView = ({ tabValue, tabHandleChange, tabItems, className = "" }) => {
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    tabHandleChange && tabHandleChange(event, newValue);
  };

  return (
    <div className={`c-tabs ${className}`}>
      <StyledTabs
        value={tabValue}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="icon tabs example"
      >
        {tabItems &&
          tabItems.length > 0 &&
          tabItems.map((item) => {
            if (item?.hide) return null;

            return (
              // if length = false then count will not display after tab name
              // if length = number - if 0 count will be 0, more than 0 count will display

              <StyledTab
                {...item.props}
                key={item.key || item.tabName}
                label={
                  <>
                    <BadgeWrapper
                      badgeContent={item?.badgeCount}
                      invisible={!item?.badgeCount || item?.badgeCount < 0}
                      color="primary"
                      top="50%"
                      right="-8%"
                    >
                      {`${t(
                        `tabView:title:${item.tabName.replace(
                          TabName.IN_PROGRESS_STATUS_WAITING,
                          TabName.IN_PROGRESS
                        )}`
                      )} ${
                        Number.isInteger(item.length)
                          ? `· ${item.length}`
                          : item.length === 0
                            ? `· ${0}`
                            : ""
                      }`}
                    </BadgeWrapper>
                  </>
                }
                className="c-tabs--tab-item"
              />
            );
          })}
      </StyledTabs>
    </div>
  );
};
