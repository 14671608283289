import { Box, Button, Paper, Theme, Typography } from "@mui/material";
import React from "react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { AppTheme } from "layout/AppTheme";
import { PublicRFQFormValuesType } from "./public-rfq-landing-step-form/public-rfq-landing-step-form.types";
import { MultipleDNDFileUploadList } from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload-list";
import { IconCircle } from "@next/components/icon-circle/icon-circle";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    headline: {
      fontFamily: "Space Grotesk",
    },
  })
);

type Props = {
  resetProcess: () => void;
  submittedData: PublicRFQFormValuesType;
};

export const PublicRfqLandingStepSuccess: React.FC<Props> = ({ resetProcess, submittedData }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <IconCircle>
        <CheckOutlinedIcon />
      </IconCircle>

      <Box mt="16px">
        <Typography variant="h6" className={classes.headline}>
          {t("workspaceNext:publicRfqLanding:successTitle")}
        </Typography>
      </Box>

      <Box mt="16px">
        <Typography variant="body1">
          {t("workspaceNext:publicRfqLanding:successSubtitle")}
        </Typography>
      </Box>

      <Paper elevation={0} style={{ padding: 24, marginTop: 48 }}>
        <Box display="flex">
          <Box flex="0.5">
            <Box style={{ color: AppTheme.palette.text.secondary }}>
              {t("workspaceNext:publicRfqLanding:yourEmail")}
            </Box>
            <Box>{submittedData?.email}</Box>
          </Box>

          <Box>
            <Box style={{ color: AppTheme.palette.text.secondary }}>
              {t("workspaceNext:publicRfqLanding:yourName")}
            </Box>
            <Box>{submittedData?.name}</Box>
          </Box>
        </Box>

        <Box mt="24px" style={{ color: AppTheme.palette.text.secondary }}>
          {t("workspaceNext:publicRfqLanding:files", {
            count: submittedData?.attachments?.length,
          })}
        </Box>
        <Box>
          <MultipleDNDFileUploadList files={submittedData?.attachments as any} />
        </Box>

        <Box mt="24px" style={{ color: AppTheme.palette.text.secondary }}>
          Note
        </Box>
        <Box>{submittedData?.additional_details}</Box>
      </Paper>

      <Box mt="48px">
        <Button variant="contained" color="primary" onClick={resetProcess}>
          {t("workspaceNext:publicRfqLanding:sendAnotherRequest")}
        </Button>
      </Box>
    </div>
  );
};
