import { RootState } from "store";

export const selectCustomQuotationImportedData = (state: RootState) =>
  state.marketplaceNext.customQuotationImportedData;
export const selectIsIntentToNoQuote = (state: RootState) =>
  state.marketplaceNext.isIntentToNoQuote;
export const selectIntentToNoQuoteData = (state: RootState) =>
  state.marketplaceNext.intentToNoQuoteData;

export const selectSavePublicItbItnbLoading = (state: RootState) =>
  state.marketplaceNext.publicItbItnbLoading;
export const selectSavePublicItbItnbError = (state: RootState) =>
  state.marketplaceNext.publicItbItnbError;

export const selectIsQuotationModalOpen = (state: RootState) =>
  !!(state.modals as Record<string, any>)?.isQuotationModalOpen;

export const selectMarketplaceHiddenRFQsList = (state: RootState) =>
  (state.marketplace as any).hiddenRFQList;

export const selectMarketplaceIntentToBidRFQsList = (state: RootState) =>
  (state.marketplace as any).intentToBidRFQList;

export const selectMarketplaceActiveRFQsList = (state: RootState) =>
  (state.marketplace as any).activeRFQList;

export const selectMarketplaceActiveRFQsListCount = (state: RootState) =>
  (state.marketplace as any).activeRFQListCount;

export const selectMarketplaceActiveRFQsListHasNext = (state: RootState) =>
  (state.marketplace as any).activeRFQListHasNext;

export const projectSelectors = {
  selectCustomQuotationImportedData,
  selectIsIntentToNoQuote,
  selectIntentToNoQuoteData,
};
