import React, { useState } from "react";
import { Box, Chip, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TabsView } from "@next/components/tabs-view";
import { TabPanel } from "components/utils/tab-panel/tab-panel";
import { MySuppliers } from "./my-suppliers";
import { SupplierInvitationsTable } from "./supplier-invitations-table/supplier-invitations-table";
import { store } from "store";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: -theme.spacing(4),
    },
    header: {
      display: "flex",
      flexDirection: "column",
    },

    titleContainer: { display: "flex", alignItems: "center" },
    content: {},
    tabPanels: {
      marginTop: theme.spacing(6),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(4),
    },
  })
);

export const SuppliersTabs = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const state = store.getState();
  const user = state?.profile?.user;
  const pendingConnections = user?.company_stats?.pending_connections;

  const tabItems = [
    {
      tabName: t("workspaceNext:supplierTable:tabTitle"),
    },
    {
      tabName: (
        <Box display="flex" gap={"8px"} alignItems="center">
          <Typography>{t("workspaceNext:invitationsTab:title")}</Typography>
          {pendingConnections > 0 && (
            <Chip label={pendingConnections} color="primary" size="small" />
          )}
        </Box>
      ),
    },
  ];

  const tabHandleChange = (_: React.ChangeEvent<{}>, value: any) => setSelectedTab(value);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <TabsView value={selectedTab} tabItems={tabItems} handleChange={tabHandleChange} />

        <div className={classes.tabPanels}>
          <TabPanel value={selectedTab} index={0}>
            <MySuppliers />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <SupplierInvitationsTable />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};
