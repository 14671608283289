import React from "react";

import { batch, useDispatch } from "react-redux";
import { Formik } from "formik";

import { modalsActions } from "@next/redux/modalsSlices";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { WorkspaceModalTypes } from "../../modals/types";

import InviteSupplierFormBase from "./invite-supplier.form.base";
import { workspaceNextActions } from "../../redux";
import { InviteSupplierFormValues, InvitedSupplier } from "./invite-supplier.types";
import { validationSchema } from "./invite-supplier.definitions";

const InviteSupplierForm = ({
  onSubmit,
}: {
  onSubmit?: (suppliers: InvitedSupplier[]) => void;
}) => {
  const dispatch = useDispatch();
  const axyaTracking = useAxyaTracking();

  const initialValues: InviteSupplierFormValues = {
    suppliers: [{ name: "", email: "", contact_name: "" }],
  };

  const handleInviteSupplier = ({ suppliers }: InviteSupplierFormValues) => {
    if (typeof onSubmit === "function") onSubmit(suppliers);
    batch(() => {
      if (typeof onSubmit !== "function")
        dispatch(workspaceNextActions.inviteSupplierRequest({ suppliers }));
      dispatch(modalsActions.closeModal(WorkspaceModalTypes.INVITE_SUPPLIER_MODAL));
    });
    axyaTracking({
      app: "axya-app",
      scout_category: "invite-supplier",
      scout_feature_name: "send-invitation",
    });
  };

  return (
    <>
      <Formik
        render={(props) => <InviteSupplierFormBase {...props} />}
        initialValues={initialValues}
        onSubmit={handleInviteSupplier}
        validationSchema={validationSchema}
      />
    </>
  );
};

export default InviteSupplierForm;
