// General Imports
import React from "react";

// MaterialUI Specific Imports

import { Grid, Typography } from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

export function ValidationCompanyWaiting({ t }) {
  return (
    <div className="c-validation-company-waiting">
      <Grid item>
        <Grid container>
          <Grid item className="watch-later-icon">
            <WatchLaterIcon color="primary" />
          </Grid>
          <Grid item className="typography-container">
            <Typography variant="body2">{t("yourCompanyIsAwaitingValidation")}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
