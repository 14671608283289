import { Box, Typography, styled } from "@mui/material";

export const StyledTypography = styled(Typography)({
  color: "rgba(0, 0, 0, 0.87)",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  fontFamily: "Roboto",
});

export const AttachmentBox = styled(Box)({
  maxWidth: "300px",
});

export const StyledTextBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "16px",
});
