import React from "react";
import ConfirmationDialog from "@next/components/confirmation-dialog/confirmation-dialog";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import { batch, useDispatch, useSelector } from "react-redux";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { workspaceNextActions } from "../redux";

export const RemoveMySupplierConfirmationModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector(getModal(WorkspaceModalTypes.REMOVE_MY_SUPPLIER_CONFIRMATION_MODAL));

  const pk = modal?.data;

  if (!pk) return null;

  const closeModal = () => {
    dispatch(modalsActions.closeModal(WorkspaceModalTypes.REMOVE_MY_SUPPLIER_CONFIRMATION_MODAL));
  };

  const onDelete = () => {
    batch(() => {
      dispatch(workspaceNextActions.removeFavoriteSupplierRequest(pk));
      closeModal();
    });
  };

  return (
    <ConfirmationDialog
      title={t("workspaceNext:supplierTable:removeMySupplierConfirmationModal:title")}
      content={t("workspaceNext:supplierTable:removeMySupplierConfirmationModal:content")}
      isOpen={!!modal}
      handleClose={closeModal}
      dialogActions={
        <Box display="flex" mb={2} mr={2}>
          <Button variant="outlined" autoFocus onClick={closeModal}>
            {t("workspaceNext:supplierTable:removeMySupplierConfirmationModal:cancel")}
          </Button>
          <Box ml={1.25}>
            <Button onClick={onDelete} variant="contained" color="primary">
              {t("workspaceNext:supplierTable:removeMySupplierConfirmationModal:delete")}
            </Button>
          </Box>
        </Box>
      }
    />
  );
};
