// General imports
import React, { useEffect, useState } from "react";

// Hooks
import { connect } from "react-redux";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Actions
import { marketplaceApi } from "../../../../services/marketplace/marketplace.api";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const UploadQuotation = (props) => {
  const { token, t } = props;

  const [uploads, setUploads] = useState([]);

  const filePond = React.createRef();

  //If no error, file has been successfully loaded
  const onFileAdd = () => {
    if (!filePond.current) {
      return;
    }
    const files = filePond.current.getFiles();
    if (files.length === 0) {
      return;
    }
    // Make sure to clean up the filepond files before
    // uploading new files.
    filePond.current.removeFiles().then(() => {
      setUploads(files);
    });
  };

  // loading upload progress
  useEffect(() => {
    if (uploads.length === 0) {
      return;
    }

    const fetch = async function () {
      props.setIsUploadingInProgress(true);
      const promises = [];
      uploads.forEach((file) => {
        promises.push(marketplaceApi.uploadQuotes(token, file, t));
      });
      try {
        const data = await Promise.all(promises);
        props.setIsUploadingInProgress(false);
        data.forEach((upload, index) => {
          if (!upload) {
            // Remove failed fetches
            delete data[index];
          } else {
            upload.file = uploads[index];
          }
        });

        props.setUploads(
          data.filter(Boolean) // Removes empty values from the  array
        );
      } catch (e) {
        console.log(e);
      }
    };

    fetch();
  }, [uploads]);

  return (
    <FilePond
      name="file"
      labelIdle={props.labelIdle}
      allowMultiple={true}
      ref={filePond}
      onaddfile={onFileAdd}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.profile.token,
  };
};

export default connect(mapStateToProps)(UploadQuotation);
