import React from "react";
import { NoteType } from "@next/modules/workspace/redux";
import { InternalNoteDropDown } from "../internal-note-dropdown/internal-note-dropdown";

type Props = {
  notes: NoteType[];
  internal_note_description?: string | undefined;
  paramID: string | number;
  isDetailPage?: boolean;
  moduleType?: string;
};

export const InternalNoteCell: React.FC<Props> = ({
  notes,
  internal_note_description,
  paramID,
  isDetailPage = false,
  moduleType,
}) => {
  return (
    <InternalNoteDropDown
      notes={notes}
      internal_note_description={internal_note_description}
      paramID={paramID}
      isDetailPage={isDetailPage}
      moduleType={moduleType}
    />
  );
};
