import { GridColDef, GridSelectionModel } from "@mui/x-data-grid-pro";
import { FileData } from "@next/components/attach-file/attach-file-list.types";
import { Company, GenericUser } from "@next/modules/profile/redux";
import { BomFilesUploadReport } from "@next/redux/types";

export type OrdersState = {
  ordersLoading: boolean;
  ordersError: Error | undefined;
  orders: Order[];
  ordersCounts: OrderCounts;
  ordersStatusCounts: OrderStateCounts;
  ordersLastQuery?: string | undefined;
  ordersSuppliers?: OrderSupplier[];

  order: Order | undefined;
  orderLoading: boolean;
  orderError: Error | undefined;

  supplierLineItemsLoading: boolean;
  supplierLineItemsError: Error | undefined;
  supplierLineItems: SupplierLineItem[];
  supplierLineItemsCounts: SupplierLineItemCounts;
  supplierLineItemsStatusCounts: SupplierLineItemStateCounts;

  createOrderLoading: boolean;
  createOrderError: Error | undefined;

  updateOrderLoading: boolean;
  updateOrderError: Error | undefined;

  deleteOrderLoading: boolean;
  deleteOrderError: Error | undefined;

  updateOrderFollowupLoading: boolean;
  updateOrderFollowupError: Error | undefined;

  uploadOrderFileLoading: boolean;
  uploadOrderFileError: Error | undefined;

  deleteOrderFileLoading: boolean;
  deleteOrderFileError: Error | undefined;

  portalOrder: Order | undefined;
  portalOrderLoading: boolean;
  portalOrderError: Error | undefined;

  updatePortalOrderLoading: boolean;
  updatePortalOrderError: Error | undefined;

  updateOrderLineItemsLoading: boolean;
  updateOrderLineItemsError: Error | undefined;

  updateOrderTargetedProfilesLoading: boolean;
  updateOrderTargetedProfilesError: Error | undefined;

  updateOrderUpdatedDeliveryDatesLoading: boolean;
  updateOrderUpdatedDeliveryDatesError: Error | undefined;

  updatePortalOrderAcknowledgementLoading: boolean;
  updatePortalOrderAcknowledgementError: Error | undefined;

  updateSupplierPOAcknowledgementLoading: boolean;
  updateSupplierPOAcknowledgementError: Error | undefined;

  updateBuyerPOAcknowledgementLoading: boolean;
  updateBuyerPOAcknowledgementError: Error | undefined;

  updatePortalOrderLineItemsLoading: boolean;
  updatePortalOrderLineItemsError: Error | undefined;

  updatePortalOrderShippingConfirmationLoading: boolean;
  updatePortalOrderShippingConfirmationError: Error | undefined;

  addOrderPartsLoading: boolean;
  addOrderPartsError: Error | undefined;

  deleteShipmentPartLoading: boolean;
  deleteShipmentPartError: Error | undefined;

  revisionProposalLoading: boolean;
  revisionProposalError: Error | undefined;

  reviewRevisionProposalLoading: boolean;
  reviewRevisionProposalError: Error | undefined;

  lineItemsTableSelectionModel: GridSelectionModel;

  orderDetailRightTabOpen: boolean;

  orderActionPanelType: ActionPanelType;

  uploadBulkFilesLoading: boolean;
  uploadBulkFilesError: Error | undefined;

  fetchBulkFilesUploadReportLoading: boolean;
  fetchBulkFilesUploadReportError: Error | undefined;
  bulkFilesUploadReport: BomFilesUploadReport | undefined;
};

export type FetchOrdersInput = {
  query: string;
  onSuccess?: (
    res: { results: Order[]; supplier_list?: OrderSupplier[] } & OrderCounts & {
        status_counts: OrderStateCounts;
      }
  ) => void;
};

export type FetchOrderInput = {
  pk: number;
  refetchOrder?: boolean;
};

export type FetchPortalOrderInput = {
  orderToken: string;
};

export type UpdatePortalOrderInput = {
  orderToken?: string;
  acknowledgment_date?: Date;
  updated_delivery_date?: string;
  update_note?: string;
  qaToken?: string;
  on_track?: boolean;
};

export type UpdatePortalOrderAcknowledgeInput = {
  orderToken?: string;
  update_note?: string;
  confirmation_file?: File;
  onSuccess?: () => void;
};

export type UpdateSupplierPOAcknowledgeInput = {
  orderToken?: string;
  confirmation_file?: File;
  onSuccess?: () => void;
};

export type UpdateBuyerPOAcknowledgeInput = {
  orderPk: number;
  confirmation_file: File;
  onSuccess?: () => void;
};

export type UpdatePortalOrderLineItemsInput = {
  orderToken?: string;
  update_note?: string;
  confirmation_file?: File;
  updated_line_items: UpdatedLineItems[];
  onSuccess?: () => void;
};

export type UpdateOrderLineItemsInput = {
  orderPk: number;
  update_note?: string;
  po_file_pk?: number;
  updated_line_items: UpdatedLineItems[];
  onSuccess?: () => void;
};

export type UpdateOrderShipmentAsBuyerInput = {
  orderPk: number;
  shipment_note?: string;
  line_item_ids: number[];
  shipment_confirmation_date?: string;
};

export type UpdateOrderShippingConfirmationInput = {
  orderToken?: string;
  isPortal?: boolean;
  orderPk?: number;
  shipment_note?: string;
  shipment_confirmation_date?: string;
  line_item_ids: number[];
  onSuccess?: () => void;
};

export type UpdatedLineItems = {
  id: number;
  type: LineItemTypeFromBE;
  value: string;
};

export type CreateOrderInput = {
  name: string;
  rfq_pk?: number;
};

export type UpdateOrderInput = Partial<Order> & {
  pk: number;
  name: Order["name"];
  delivery_date: Order["delivery_date"];
  note?: Order["note"];
  is_sent?: Order["is_sent"];
  is_completed?: boolean;
  is_cancelled?: boolean;
  files?: File[];
  refetchOrders?: boolean;
  is_updated_delivery_date_checked?: boolean;
  updated_delivery_date?: string;
  change_state_to?: "acknowledged" | "shipped";
  onSuccess?: () => void;
  qa_token?: string;
  created_by?: string;
  supplier?: number;
};

export type UpdateOrderDeliveryDatesInput = {
  orderPk: number;
  delivery_dates: Pick<OrderLineItem, "id" | "delivery_date">[];
  onSuccess?: () => void;
};

export type DeleteOrderInput = {
  pk: number;
  refetchOrders?: boolean;
};

export type UpdateOrderFollowupInput = {
  orderPk: number;
  followupPk: number;
  is_muted?: boolean;
  send_date?: Date;
};

export type UploadOrderFileInput = {
  attachments: File[];
  pk: number;
  onSuccess?: (newFileData: FileData[]) => void;
};

export type DeleteOrderFileInput = {
  orderPk: number;
  filePk: number;
  onSuccess?: () => void;
};

export type Order = {
  pk: number;
  slug: string;
  name: string;
  supplier: OrderSupplier;
  status: OrderStatusObj;
  state: OrderStateObj;
  line_items_count: number;
  followups: OrderFollowUp[];
  files: POFileWithRevision[];
  created_at: string;
  send_date: string;
  po_acknowledged_date: string;
  delivery_date: string;
  updated_delivery_date: string;
  note: string;
  is_sent: boolean;
  rfq: {
    pk: number;
    display_name: string;
    bundle_url?: string;
    status?: string;
  };
  updated_at: string;
  qa_token: string;
  is_completed?: boolean;
  quotation: {
    total_price: number;
  };
  created_by: GenericUser;
  collaborators?: GenericUser[];
  show_cta: OrderShowCTAState | null;
  is_cancelled: boolean;
  company: Company;
  buyer: Company;
  is_updated_delivery_date_review_required: boolean;
  acknowledgment_date: string;
  shipping_confirmation_date: string;
  views: { [key: string]: string };
  shipping_note?: string;
  // shipments?: OrderShipment[];
  part_extra_fields?: OrderShipmentPartExtraField[];
  hidden_fields?: string[];
  step: null | "sent" | "acknowledged" | "shipped";
  shipments_count: number;
  shipped_shipments_count: number;
  supplier_line_item_counts?: {
    active_count: number;
    shipped_count: number;
    cancelled_count: number;
    status_count: {
      on_track_count: number;
      updated_count: number;
      at_risk_count: number;
      late_count: number;
    };
  };
  supplier_list?: OrderSupplier[];
  line_items?: OrderLineItem[];
  line_item_fields?: OrderLineItemField[];
  shipped_line_items_count?: number;
  po_confirmation_file?: FileData;
  revision_proposals?: RevisionProposal[];
  line_items_changes_proposal?: OrderLineItemChanges[];
  additional_files?: FileData[];
};

export type OrderLineItem = {
  delivery_date: string;
  description: string;
  extra_fields: null | any;
  id: number;
  name: string;
  quantity: number;
  shipment_confirmation_date: string | null;
  shipment_note: string | null;
  status: string;
  updated_delivery_date: string;
  quotation_price: number;
  updated_fields?: LineItemType[];

  // For tracking on the fly save
  previous_line_item?: {
    updated_delivery_date?: string;
    quantity?: number;
    quotation_price?: number;
  };
};

export type OrderLineItemField = {
  title: string;
  slug: string;
  type: string;
};

export type SupplierLineItem = {
  pk: string;
  name: string;
  quantity: number;
  updated_delivery_date: string;
  buyer: Company;
  rfq: { id: number; name: string };
  purchase_order: { id: number; name: string; url: string };
};

export type OrderShipmentStatus =
  | "on_track"
  | "late"
  | "shipped"
  | "updated"
  | "at_risk"
  | "cancelled"
  | "waiting_for_acknowledgment";

export type OrderShipmentPartExtraField = {
  display_name: { en: string; fr: string };
  slug: string;
  type: GridColDef["type"];
  width?: number;
  valueOptions?: GridColDef["valueOptions"];
};

export enum OrderShowCTAState {
  IS_SHOW_ACKNOWLEDGMENT_CTA = "is_show_acknowledgment_cta",
  IS_HIDE_ACKNOWLEDGMENT_CTA = "is_hide_acknowledgment_cta",
  IS_SHOW_PRODUCTION_CTA = "is_show_production_cta",
  IS_SHOW_SHIPPING_CTA = "is_show_shipping_cta",
}

export type OrderCounts = {
  count: number;
  active_count: number;
  completed_count: number;
};

export type SupplierLineItemCounts = {
  count: number;
  active_count: number;
  shipped_count: number;
  cancelled_count: number;
};

export type SupplierLineItemStateCounts = {
  active_count: number;
  updated_count: number;
  at_risk_count: number;
  on_track_count: number;
  late_count: number;
};

export type OrderStateCounts = {
  updated_count: number;
  completed_count: number;
  at_risk_count: number;
  on_track_count: number;
  shipped_count: number;
  late_count: number;
  total_count: number;
  draft_count: number;
};

export type OrderFollowUp = {
  pk: number;
  created_at: Date;
  is_muted: boolean;
  is_next: boolean;
  is_sent: boolean;
  msg: string;
  send_date: Date;
  task_id: string;
  type: OrderFollowUpType;
  updated_at: Date;
  views_count: number;
  state: OrderFollowUpState;
  display_name: string;
};

export type OrderFollowUpState = {
  display_name: string;
  slug: OrderFollowupStateSlug;
};

export enum OrderFollowupStateSlug {
  SCHEDULED = "is_scheduled",
  PENDING = "is_pending",
  MUTED = "is_muted",
  SENT = "is_sent",
  IS_USER_MUTED = "is_user_muted",
}

export enum OrderFollowUpType {
  ACKNOWLEDGMENT = "acknowledgement_followup",
  PRODUCTION = "production_followup",
  SHIPPING = "shipping_followup",
}

export enum OrderStatus {
  DRAFT = "draft",
  SENT = "sent",
  ACKNOWLEDGED = "acknowledged",
  IN_PRODUCTION = "in_production",
  SHIPPING_CONFIRMED = "shipping_confirmed",
}

export enum OrderState {
  UNPUBLISHED = "unpublished",
  WAITING_FOR_ACKNOWLEDGMENT = "waiting_for_acknowledgment",
  WAITING_FOR_SHIPPING_CONFIRMATION = "waiting_for_shipping_confirmation",
  LATE = "late",
  ON_TRACK = "on_track",
  AT_RISK = "at_risk",
  UPDATED = "updated",
  SHIPPED = "shipped",
  ALL = "all",
}

export type OrderStatusObj = {
  slug: OrderStatus;
  display_name: string;
};

export type OrderStateObj = {
  slug: OrderState;
  display_name: string;
  description?: string;
};

export type OrderFile = {
  pk: number;
  file_name: string;
  file_url: string;
};

export type OrderSupplier = {
  id: number;
  name: string;
  picture: string;
  contact_name: string;
  contact_email: string;
  target_profiles: OrderSupplierTargetProfile[];
};

export type OrderSupplierTargetProfile = {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  picture: string;
  username: string;
};

export enum OrderPortalModalActionVariant {
  UPDATE_DATE = "updateDate",
  CTA = "CTA",
}

type AddOrderPartData = {
  name: string;
  description: string;
  quantity: number;
  delivery_date: string;
};

export type AddOrderPartsInput = {
  order_pk: number;
  parts: AddOrderPartData[];
};

export type DeleteShipmentPartInput = {
  order_pk: number;
  line_item_id: number;
};

export type UpdateOrderTargetedProfilesInput = {
  target_profiles: number[];
  supplierId: number;
  poId: number;
  onSuccess?: () => void;
};

export type POFileWithRevision = FileData & {
  rev_number: number;
  created_at: string;
};

export type RevisionProposal = {
  id: number;
  revision_type: LineItemTypeFromBE;
  previous_value: string;
  new_value: string;
  part: {
    name: string;
    id: number;
    extra_fields: any | null;
  };
};

export type OrderLineItemChanges = {
  id: number;
  name: string;
  previous_line_item: {
    [key: string]: any;
  };
  [key: string]: any;
  decision?: {
    [key: string]: "approved" | "rejected" | null;
  };
};

export type ProposalDecision = {
  id: number; // id of proposal from revision_proposals GET endpoint
  decision: "approved" | "rejected";
};

export type ReviewRevisionProposalInput = {
  orderPk: number;
  proposal_decisions: ProposalDecision[];
  note?: string;
  po_file_pk?: number;
  onSuccess?: () => void;
};

export type UploadBulkFilesInput = {
  orderPk: number;
  file: File;
  attach_unmatched_files?: boolean;
};

export type FetchBulkFilesUploadReportInput = {
  orderPk: number;
  uuid: string;
};

export type UpdateDraftOrderLineItemsInput = {
  orderPk: number;
  line_items: Partial<OrderLineItem>[];
};

export type QAMessageData = {
  id: number;
  line_id?: string;
  type: LineItemTypeFromBE;
  part_name: string;
  previous_value: string;
  new_value: string;
  decision?: "approved" | "rejected";
};

export enum LineItemType {
  QUANTITY = "quantity",
  PRICE = "quotation_price",
  UPDATED_DELIVERY_DATE = "updated_delivery_date",
  STATUS = "status",
}

export type LineItemTypeFromBE = "quantity" | "price" | "shipping" | "state_change";

export enum ActionPanelType {
  Default,
  ProposeQtyAndPriceChange,
  ModifyDeliveryDate,
  Review,
}
