import React, { useCallback } from "react";
import { ProjectCompanyPart } from "@next/modules/project/redux";
import { useDispatch, useSelector } from "react-redux";
import PaginatedAutoComplete from "@next/components/paginated-autocomplete";
import { Box } from "@mui/material";
import { useField } from "formik";
import { ordersActions } from "../../redux";
import { selectOrder, selectOrderParts } from "../../redux/selectors";

export const OrderPartsAutocompleteDropdown = () => {
  const partsList = useSelector(selectOrderParts);
  const order = useSelector(selectOrder);
  const dispatch = useDispatch();
  const [field, _, fieldHelpers] = useField("parts");

  const onInitialFetch = useCallback(() => {
    dispatch(
      ordersActions.fetchOrderRequest({
        pk: order.pk,
        refetchOrder: true,
      })
    );
  }, [order.pk]);

  return (
    <Box display="flex">
      <Box flex={1}>
        <PaginatedAutoComplete
          name={field.name}
          value={field.value}
          onSelectionChange={fieldHelpers.setValue}
          options={partsList}
          onInitialFetch={onInitialFetch}
          renderOption={(option) => option.name}
          getOptionSelected={(option: ProjectCompanyPart, value: ProjectCompanyPart) =>
            option.pk === value.pk
          }
          getInputFieldValue={(value: ProjectCompanyPart) => value.name}
        />
      </Box>
    </Box>
  );
};
