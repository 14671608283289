import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { WorkspaceParts } from "components/workspace/parts/workspace-parts.component";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "-32px",
  },
  divider: {
    width: "auto",
    marginLeft: "-64px",
    marginRight: "-64px",
  },
}));

const WorkspaceTabView = ({
  token,
  company,
  fetchCompanyPartList,
  handleOpenModal,
  handleCloseModal,
  partList,
  modalState,
  selectedParts,
  setSelectedParts,
  selectedTags,
  isWorkspaceLoading,
  isWorkspaceInitialLoading,
  ...rest
}) => {
  const classes = useStyles();

  // close tour on page reload
  useEffect(() => {
    handleCloseModal("isCreatePartTourOpen");
  }, []);

  return (
    <Grid container xs={12} className={classes.root}>
      <WorkspaceParts
        company={company}
        token={token}
        fetchCompanyPartList={fetchCompanyPartList}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        partList={partList}
        modalState={modalState}
        setSelectedParts={setSelectedParts}
        selectedParts={selectedParts}
        selectedTags={selectedTags}
        isWorkspaceLoading={isWorkspaceLoading}
        isWorkspaceInitialLoading={isWorkspaceInitialLoading}
        {...rest}
      />
    </Grid>
  );
};

export default WorkspaceTabView;
