import React from "react";
import { Tooltip, styled } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { useTranslation } from "react-i18next";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { ContactUsModal } from "@next/modules/workspace/modals/contact-us-modal";
import CustomButton from "@next/components/custom-button/custom-button";
import {
  selectQuestionnaireName,
  selectedCompanyDetails,
} from "@next/modules/vendor-management/redux/selectors";

const StyledHelperButton = styled(CustomButton)`
  background-color: #00205b;
  color: white;
  padding: 7px 30px;
  &:hover {
    background-color: #00205b;
  }
`;

export const VendorContactSupportItem = () => {
  const { t } = useTranslation();
  const axyaTracking = useAxyaTracking();
  const dispatch = useDispatch();
  let questionnaireName = useSelector(selectQuestionnaireName);
  const supplier = useSelector(selectedCompanyDetails);

  let supplierDetails: string = questionnaireName;
  if (supplier) {
    supplierDetails = supplier?.title + `-${supplierDetails}`;
  }

  const onClickOpenContactSupportModal = () => {
    dispatch(
      modalsActions.showModal({
        key: WorkspaceModalTypes.CONTACT_US_MODAL,
        data: {
          modalTitle: t("contactUs:contactSupport"),
          hasSubjectAndMsg: true,
          hasFormTitle: true,
          formTitle:
            "Send us a message via the following form and a customer support rep. will reply to your request as soon as possible.",
          label: "Write here...",
          footerText: "You can also send your message to",
          footerLinkText: "support@axya.co",
          iconName: "EmailIcon",
          isAirbusSupportModal: true,
          supplierDetails,
        },
      })
    );
    axyaTracking({
      scout_category: "contact-us",
      scout_feature_name: "open-contact-support-modal-from-vendor-management",
    });
  };

  return (
    <>
      <Tooltip title={t("appTour:contactSupport")}>
        <StyledHelperButton
          onClick={onClickOpenContactSupportModal}
          startIcon={<HelpIcon style={{ color: "white", width: "24px", height: "24px" }} />}
        >
          Need help?
        </StyledHelperButton>
      </Tooltip>
      <ContactUsModal />
    </>
  );
};
