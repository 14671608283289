/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";

import { getNDATableColumns } from "./nda-table.columns";
import { useDispatch, useSelector } from "react-redux";
import { GridDataState, workspaceNextActions } from "../../redux";
import { selectNDAList } from "../../redux/selectors";
import i18n from "assets/configi18n/i18n";

export const useNDATableData = () => {
  const dispatch = useDispatch();
  const ndaList = useSelector(selectNDAList);
  const [gridData, setGridData] = useState<GridDataState>({
    rows: [],
    columns: [],
  });

  const ndaTableColumns = useMemo(() => getNDATableColumns(), [i18n?.language]);

  useEffect(() => {
    dispatch(workspaceNextActions.fetchNDAListRequest());
  }, []);

  useEffect(() => {
    setGridData({ rows: ndaList, columns: ndaTableColumns });
  }, [ndaList]);

  return {
    gridData,
    setGridData,
  };
};
