import { ListItemIcon, ListItemText, Menu, MenuItem, SvgIconProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useCallback } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemIcon: {
      minWidth: "32px",
    },
    icon: {
      width: "20px",
      height: "20px",
      color: theme.palette.text.primary,
    },
  })
);

export type TableRowMenuItem = {
  text: string;
  onClick: () => void;
  Icon: React.ComponentType<SvgIconProps>;
  disabled?: boolean;
};

export type TableRowMenuProps = {
  anchorEl: Element | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement>>;
  menuItems?: TableRowMenuItem[];
};

export const TableRowMenu: React.FC<TableRowMenuProps> = ({
  menuItems = [],
  anchorEl,
  setAnchorEl,
}) => {
  const classes = useStyles();

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {menuItems?.map(({ text, onClick, Icon, disabled }) => (
        <MenuItem onClick={onClick} disabled={disabled}>
          <ListItemIcon className={classes.listItemIcon}>
            <Icon className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary={text} />
        </MenuItem>
      ))}
    </Menu>
  );
};
