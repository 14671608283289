import * as yup from "yup";

export type TLogin2FAFormState = {
  code: string;
};

export const login2faFormValidationSchema = (t: (str: string) => string) =>
  yup.object().shape({
    code: yup.string().required(t("auth:error:errorCodeMissing")),
  });

export const setLogin2FAFormState = (): TLogin2FAFormState => {
  return {
    code: "",
  };
};
