import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchAnnouncementsRequest, getAnnouncementBySlugRequest } from "../redux/slices";

import {
  selectAnnouncements,
  selectAnnouncementsError,
  selectAnnouncementsLoading,
  selectSelectedAnnouncement,
  selectSelectedAnnouncementError,
  selectSelectedAnnouncementLoading,
} from "../redux/selectors";

export const useAnnouncementsData = () => {
  const dispatch = useDispatch();

  const announcements = useSelector(selectAnnouncements);
  const announcementsLoading = useSelector(selectAnnouncementsLoading);
  const announcementsError = useSelector(selectAnnouncementsError);

  useEffect(() => {
    dispatch(fetchAnnouncementsRequest());
  }, [dispatch]);

  return {
    announcements,
    announcementsLoading,
    announcementsError,
  };
};

export const useSelectedAnnouncementData = (slug: string) => {
  const dispatch = useDispatch();

  const selectedAnnouncement = useSelector(selectSelectedAnnouncement);
  const selectedAnnouncementLoading = useSelector(selectSelectedAnnouncementLoading);
  const selectedAnnouncementError = useSelector(selectSelectedAnnouncementError);

  useMemo(() => {
    if (!slug) return;

    dispatch(getAnnouncementBySlugRequest(slug));
  }, [slug, dispatch]);

  return {
    selectedAnnouncement,
    selectedAnnouncementLoading,
    selectedAnnouncementError,
  };
};
