import * as yup from "yup";

export type TLoginFormState = {
  email: string;
  password: string;
};

export const loginFormValidationSchema = (t: (str: string) => string) =>
  yup.object().shape({
    email: yup
      .string()
      .required(t("auth:error:errorEmailMissing"))
      .email(t("auth:error:errorEmailInvalid")),
    password: yup.string().required(t("auth:error:errorPasswordMissing")),
  });

export const setLoginFormState = (
  initialized: boolean,
  username: string,
  email?: string
): TLoginFormState => {
  return {
    email: email ? email : initialized ? username : "",
    password: "",
  };
};
