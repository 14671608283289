import { EditFormValues } from "./part-form.types";

export const normalizePartData = (formData: EditFormValues) => {
  return {
    name: formData?.name,
    additional_details: formData?.additional_details,
    alloy: formData?.alloy?.value?.toString(),
    processes: (formData?.processes?.map((item) => item.value) as any[]) || [],
    treatment: formData?.treatment?.value?.toString(),
    files:
      formData?.part_uploads_list
        ?.map((item) => item?.pk as number)
        .filter((x) => x !== undefined) || [],
  };
};
