import { Formik } from "formik";
import React, { useCallback } from "react";
import PortalItnbFormBase from "./portal-itnb-form.base";
import { getInitialValues, validationSchema } from "./portal-itnb-form.definitions";
import { useDispatch } from "react-redux";
import { UpdateQuoteNoQuoteStatusInput, workspaceNextActions } from "@next/modules/workspace/redux";
import { PortalItbItnbModalProps } from "../../modals/portal-itnb-modal";
import { PortalItnbFormValues } from "./portal-itnb-form.types";
import { OTHER_REASON_SLUG } from "@next/modules/marketplace/components/intent-to-bid/intent-to-bid.helpers";

const PortalItnbForm: React.FC<PortalItbItnbModalProps> = ({ portalData, rfqToken, onClose }) => {
  const dispatch = useDispatch();
  const handleSubmit = useCallback((values: PortalItnbFormValues) => {
    const payload: UpdateQuoteNoQuoteStatusInput = {
      rfqToken,
      type: "itnb",
      reason: values.reason,
      onSuccess: onClose,
    };
    if (values.reason === OTHER_REASON_SLUG && values.description)
      payload.description = values.description;

    dispatch(workspaceNextActions.updatePortalQuoteNoQuoteStatusRequest(payload));
  }, []);

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(props) => <PortalItnbFormBase {...props} portalData={portalData} />}
    </Formik>
  );
};

export default PortalItnbForm;
