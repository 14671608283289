import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

const useZammadChat = (uuid) => {
  const zammadChatRef = useRef<any>(null);
  const isModalOpen = useSelector(
    (state: RootState) => state.modals[WorkspaceModalTypes.CONTACT_US_MODAL]
  );

  useEffect(() => {
    const hostname: string = window.location.hostname;

    // Check if the current hostname matches the allowed environments
    const allowedHosts: string[] = [
      "staging.app.axya.co",
      "airbus.app.axya.co",
      "localhost:3000",
      "localhost",
    ];

    if (allowedHosts.includes(hostname)) {
      // Create script element for chat-no-jquery.min.js
      const script: HTMLScriptElement = document.createElement("script");
      script.src = "https://support.app.axya.co/assets/chat/chat-no-jquery.min.js";
      script.async = true;

      // Append the script to the document
      document.body.appendChild(script);

      // Initialize ZammadChat after the script is loaded
      script.onload = () => {
        zammadChatRef.current = new (window as any).ZammadChat({
          host: "wss://support.app.axya.co/ws",
          fontSize: "12px",
          chatId: 1,
          show: true,
          title: "Need help? Chat with us live!",
        });
        (window as any).zammadInstance = zammadChatRef.current;

        const style = document.createElement("style");
        style.innerHTML = `
            .zammad-chat {
                position: fixed !important;
                bottom: 72px !important;
                right: 0 !important;
            }
        `;
        document.head.appendChild(style);
      };

      // Cleanup script when component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  const openChat = () => {
    if (zammadChatRef.current) {
      zammadChatRef.current.show();
    }
  };

  const closeChat = () => {
    if (zammadChatRef.current) {
      zammadChatRef.current.hide();
    }
  };

  return { openChat, closeChat };
};

export default useZammadChat;
