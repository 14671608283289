import React from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { confirmDialog } from "@next/modals/shared-confirm-dialog";
import {
  selectProjectCompanyPartsCount,
  selectProjectCompanyPartsSelectAll,
  selectSelectedPartRows,
} from "../../redux/selectors";
import { projectActions } from "../../redux";
import { AppThemeV5 } from "layout/AppTheme";
import snackbarUtils from "@next/utils/snackbarUtils";

type Props = {
  selectedPartCount: number;
};

export const ProjectPartSelectedOptions: React.FC<Props> = ({ selectedPartCount }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const params = useParams<{ code: string }>();
  const selectedPartsSelectionModel = useSelector(selectSelectedPartRows);
  const projectCompanyPartsSelectAll = useSelector(selectProjectCompanyPartsSelectAll);
  const projectCompanyPartsCount = useSelector(selectProjectCompanyPartsCount);

  const onClickCreateRfq = () => {
    dispatch(
      projectActions.openRFQDrawer({
        projectCode: params.code,
        selectedParts: selectedPartCount,
      })
    );
  };

  const onClickSelectAll = (newSelection: boolean) => () => {
    if (!newSelection) {
      dispatch(projectActions.setSelectedPartRows([]));
      snackbarUtils.toast(`${t("project:selectionCleared")}`);
    }

    dispatch(projectActions.setProjectCompanyPartsSelectAll(newSelection));
  };

  const onClickDelete = () => {
    confirmDialog(
      t("common:areYouSureWantToDelete"),
      t("project:deletePartWarningText", { count: selectedPartCount }),
      () => {
        if (selectedPartsSelectionModel.length > 0) {
          const ids = selectedPartsSelectionModel?.map((item) => item.pk);

          dispatch(
            projectActions.bulkDeleteProjectPartRequest({
              partIds: projectCompanyPartsSelectAll ? [] : (ids as number[]),
              selectAll: projectCompanyPartsSelectAll,
              projectCode: params.code,
            })
          );
        }
      },
      t("common:delete"),
      "delete"
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        p: "16px",
        alignItems: "center",
        width: "100%",
        borderBottom: `1px solid ${AppThemeV5.palette.divider}`,
      }}
    >
      <Box display="flex" flexGrow={1}>
        <Typography color="inherit" variant="body2">
          {`${t("project:navbar:partSelectedWithCount", {
            count: selectedPartCount,
          })} `}
        </Typography>

        <Box ml="8px" style={{ cursor: "pointer" }}>
          {projectCompanyPartsCount > 0 ? (
            <Typography color="inherit" variant="body2">
              {projectCompanyPartsSelectAll ? (
                <Link onClick={onClickSelectAll(false)}>
                  {`${t("project:navbar:clearSelection", {
                    count: projectCompanyPartsCount,
                  })} `}
                </Link>
              ) : (
                <Link onClick={onClickSelectAll(true)}>
                  {`${t("project:navbar:selectAllParts", {
                    count: projectCompanyPartsCount,
                  })} `}
                </Link>
              )}
            </Typography>
          ) : null}
        </Box>
      </Box>
      <Box display="flex">
        <Box marginRight="12px">
          <Button variant="outlined" color="error" onClick={onClickDelete} size="medium">
            {t("project:navbar:button:deleteParts", {
              count: selectedPartCount,
            })}
          </Button>
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={onClickCreateRfq}
          startIcon={<NoteAddOutlinedIcon />}
        >
          {t("project:navbar:button:createARequestForQuotation")}
        </Button>
      </Box>
    </Box>
  );
};
