import { Box, styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";

export const RootBox = styled(Box)`
  font-family: "Space Grotesk";
  width: 100%;
  height: 124px;
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 24px;
  border-radius: 24px;
  background-color: white;
`;

export const StyledTypography = styled(CustomTypography)`
  margin-left: 5px;
  margin-top: 15px;
`;
