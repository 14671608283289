import React from "react";

import { Button, Chip, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const ListOfTags = ({ tags, onClickOpenTagDialog, t }) => {
  return (
    <div className="c-list-of-tags">
      {tags.map((tag) => (
        <Chip key={tag.id} label={tag.name} variant="outlined" className="c-list-of-tags--chip" />
      ))}
      <Tooltip title={t("addTag")}>
        <Button
          variant="contained"
          onClick={onClickOpenTagDialog}
          className="c-list-of-tags--avatar"
        >
          <AddIcon className="c-list-of-tags--add-icon" />
        </Button>
      </Tooltip>
    </div>
  );
};
