// General imports
import React from "react";

// Material UI
import { ListItem, ListItemSecondaryAction, ListItemText } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

// Use this component to display extension file name
export const UploadedItem = ({
  uploadInfo,
  selectedIndex,
  onUploadSelectionChange,
  onFileRemove,
}) => {
  return (
    <ListItem
      button
      divider
      selected={selectedIndex === uploadInfo.pk}
      fullWidth
      key={uploadInfo.key}
      onClick={(e) => onUploadSelectionChange(uploadInfo, e)}
    >
      <ListItemText inset={true} primary={`${uploadInfo.file_name}`} />
      <ListItemSecondaryAction>
        <ClearIcon onClick={() => onFileRemove(uploadInfo)} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};
