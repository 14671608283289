// This component's purpose is to display all the necessary info about a contract
// This component is displayed on it's own page on the /workspace/market-place/contract-view/:id route it is not used anywhere else

// General imports
import React, { useCallback, useEffect, useState } from "react";

// Hooks
import { useTranslation } from "react-i18next";

// Material UI
import { AppBar, Box, CircularProgress, Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Helpers functions
import { history } from "helpers/history";
import { frontendUrl } from "urls";

// Components
import RfqsDetailsSummary from "components/rfqs-details-summary/rfqs-details-summary.component";
import { TabsView } from "components/utils/tabs-view/tabs-view.component";
import BackButton from "components/back-button/back-button.component";
import { TabName } from "components/common/table-list/types";
import { QuestionsAnswers } from "@next/modules/workspace/components/questions-answers/questions-answers";
import { useQuery } from "@next/hooks/useQuery";

function TabPanel({ children, value, index, ...other }) {
  return (
    <Grid
      container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
    </Grid>
  );
}
const useStyles = makeStyles(() => ({
  divider: {
    width: "auto",
    marginLeft: "-64px",
    marginRight: "-64px",
    marginBottom: "24px",
  },
}));
// Component containing all the info on contract
export const SupplierContractView = ({
  getQuotationThreadDetails,
  setActiveQuotationThread,
  handleOpenModal,
  params,
  contractDetails,
  isContractDetailsLoading,
  ...props
}) => {
  const token = props.token;

  const { t } = useTranslation("workspace");

  const [query, setQuery] = useQuery();
  const selectedTab = query?.tab || 0;
  const initiallyOpenQAId = query?.qa;

  //Get po date date when po file as added
  const poFile = contractDetails?.po_file;

  const poDate =
    poFile && poFile?.created_at
      ? contractDetails?.quotations[0]?.estimated_lead_time
      : contractDetails?.quotations[0].lead_time;

  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);

  const classes = useStyles();
  // tab names
  let tabItems = [
    { tabName: TabName.INFORMATION, length: false },
    {
      tabName: TabName.QUESTION_AND_ANSWER,
      length: contractDetails?.questions_count,
      badgeCount: contractDetails?.unseen_questions_count,
    },
  ];
  const setSelectedTab = useCallback((_event, index) => {
    setQuery({ tab: index });
  }, []);

  useEffect(() => {
    props.fetchContractDetails(token, params.id);
    setIsInitialLoadDone(true);
  }, [params.id]);

  useEffect(() => {
    if (!isInitialLoadDone) {
      setIsLoading(true);
    } else if (isContractDetailsLoading) {
      setIsLoading(true);
    } else if (contractDetails !== null) {
      setIsLoading(false);
    }
  }, [contractDetails, isContractDetailsLoading]);

  // Function to redirect to the contract index
  const redirect = () => {
    return history.push(frontendUrl.supplierContracts);
  };

  return (
    <>
      {isLoading ? (
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <section>
          <AppBar elevation={0} position="static" className="c-app-bar">
            <Box mb={0}>
              <BackButton onClick={redirect}>
                {t("mainNavigationSidebar:awardedRequests")}
              </BackButton>
            </Box>
          </AppBar>
          <Grid container>
            <Grid>
              <TabsView
                tabItems={tabItems}
                tabHandleChange={setSelectedTab}
                tabValue={selectedTab}
                t={t}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider classes={{ root: classes.divider }} light />
            </Grid>
            <TabPanel value={selectedTab} index={0}>
              <RfqsDetailsSummary
                title={t("mainNavigationSidebar:awardedRequests")}
                startMessage={t("expectedDeliveryOn")}
                endMessage={t("remainingBeforeTheEndOfRequestForQuotations")}
                quotation={contractDetails.quotations[0]}
                handleCloseView={redirect}
                showChart={false}
                dateToDisplay={poDate}
                subText={poFile && poFile?.created_at ? t("expectedDeliveryOn") : t("leadTime")}
                getQuotationThreadDetails={getQuotationThreadDetails}
                setActiveQuotationThread={setActiveQuotationThread}
                handleOpenModal={handleOpenModal}
                isSeller={true}
                isContract={true}
                attachments={contractDetails?.attachments}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <QuestionsAnswers rfqId={params.id} initiallyOpenQAId={initiallyOpenQAId} />
            </TabPanel>
          </Grid>
        </section>
      )}
    </>
  );
};
