import { Box, Theme, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Form, FormikProps } from "formik";
import MultipleDndFileUpload from "@next/components/multiple-dnd-file-upload";
import { CustomFileObject } from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload.types";
import { useSelector } from "react-redux";
import CustomButton from "@next/components/custom-button/custom-button";
import { Order } from "../../redux";
import { selectUpdateOrderLoading, selectUploadOrderFileLoading } from "../../redux/selectors";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    footer: {
      position: "sticky",
      bottom: theme.spacing(-3),
      borderTop: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(2, 3),
      margin: theme.spacing(3, -3, -3, -3),
      backgroundColor: theme.palette.common.white,
      display: "flex",
      justifyContent: "flex-end",
    },
  })
);

type Props = {
  order: Order;
};

export const RevisePoFormBase: React.FC<FormikProps<any> & Props> = ({
  handleSubmit,
  values,
  setFieldValue,
  order,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const fileLoading = useSelector(selectUploadOrderFileLoading);
  const updateLoading = useSelector(selectUpdateOrderLoading);

  const setFilesState = (files: CustomFileObject[]) => {
    setFieldValue("file", files[0]?.file);
  };

  return (
    <Form onSubmit={handleSubmit} className={classes.root}>
      <Box mb={values.file ? 0 : "24px"}>
        <Typography variant="body1">
          {t("orders:revisePoModal:helpText", {
            supplierName: order.supplier?.name,
          })}
        </Typography>
      </Box>

      <MultipleDndFileUpload
        filesState={values?.file ? [{ file: values.file, pk: -1 }] : []}
        setFilesState={setFilesState}
        disableDndMargin
        multiple={false}
        fileFormats={["PDF"]}
      />

      <Box className={classes.footer}>
        <CustomButton
          loading={fileLoading || updateLoading}
          variant="contained"
          color="primary"
          type="submit"
        >
          {t("common:send")}
        </CustomButton>
      </Box>
    </Form>
  );
};
