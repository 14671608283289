import { useEffect, useMemo, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getColumns } from "./project-part-table.columns";
import {
  selectProjectBulkDeletePartLoading,
  selectProjectCompanyParts,
  selectProjectCompanyPartsLoading,
  selectProjectStats,
} from "@next/modules/project/redux/selectors";
import { useGridData } from "@next/hooks/useGridData-v5";
import { GridColDef, GridFilterModel } from "@mui/x-data-grid-pro-v5";

import i18n from "assets/configi18n/i18n";
import { ProjectCompanyPart, projectActions } from "@next/modules/project/redux";
import {
  fetchPublicRequestLoading,
  getRfqDetails,
  getRfqPartsForEdit,
} from "services/workspace/workspace.selectors";
import { ProjectsPartField } from "./types";

import { generatePaginationAndSearchQuery } from "@next/utils/paginationUtils-v5";
import {
  getPMPartsHiddenColumns,
  getPartExtraFields,
} from "services/part-configuration/part-configuration.selectors";
import { useParams } from "react-router-dom";
import useLocalStorageGridState from "@next/hooks/useLocalStorageGridState-v5";
import { BUYER_PARTS_GRID_COLUMN_STATE } from "@next/constants/data-grid-state-constants";

export type GridStatePartData = {
  rows: Partial<ProjectCompanyPart>[];
  columns: GridColDef[];
};

export type PartTableDataProps = {
  currentPage: number;
  debouncedSearchValue: string;
  filterModel?: GridFilterModel;
  fromRfq?: boolean;
  isRFQEditMode?: boolean;
  isEditable?: boolean;
  hidePriceColumn?: boolean;
};

export const usePartTableData = ({
  currentPage,
  debouncedSearchValue,
  filterModel,
  fromRfq,
  isRFQEditMode,
  isEditable,
  hidePriceColumn,
}: PartTableDataProps) => {
  const dispatch = useDispatch();
  const { code: projectCodeFromParam = "" } = useParams<{ code: string }>();
  const companyPartList = useSelector(selectProjectCompanyParts, shallowEqual);
  const rfqParts = useSelector(getRfqPartsForEdit);
  const fetchRFQLoading = useSelector(fetchPublicRequestLoading);
  const companyPartLoading = useSelector(selectProjectCompanyPartsLoading);
  const projectStats = useSelector(selectProjectStats);
  const companyPartExtraFields = useSelector(getPartExtraFields);
  const companyPartsHiddenColumns = useSelector(getPMPartsHiddenColumns);
  const projectCode = useRef(projectCodeFromParam || projectStats?.code);

  useEffect(() => {
    if (projectStats?.code) projectCode.current = projectStats?.code;
  }, [projectStats?.code]);

  const projectBulkDeletePartLoading = useSelector(selectProjectBulkDeletePartLoading);

  const partExtraFields = projectStats?.template?.part_template || companyPartExtraFields;
  const pmPartsHiddenColumns =
    projectStats?.template?.part_hidden_columns || companyPartsHiddenColumns;

  const filterValues = useMemo(
    () => filterModel?.items?.filter((item) => item.value),
    [filterModel?.items]
  );
  const partTableColumns = useMemo(
    () =>
      getColumns(
        partExtraFields || [],
        pmPartsHiddenColumns || [],
        undefined,
        fromRfq,
        isRFQEditMode,
        isEditable,
        hidePriceColumn
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyPartLoading, partExtraFields, pmPartsHiddenColumns, i18n?.language]
  );

  const { gridData, setGridData } = useGridData({
    rows: isRFQEditMode ? rfqParts : companyPartList,
    columns: partTableColumns,
    loading: isRFQEditMode ? fetchRFQLoading : companyPartLoading || projectBulkDeletePartLoading,
  });

  const fetchProjectCompanyParts = () => {
    dispatch(
      projectActions.fetchProjectCompanyPartsRequest({
        projectCode: projectCode.current,
        query: `${generatePaginationAndSearchQuery({
          currentPage,
          searchKey: "search",
          searchValue: debouncedSearchValue,
          filterValues,
        })}`,
      })
    );
  };

  useEffect(() => {
    if (projectCode.current) {
      fetchProjectCompanyParts();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectCode.current, debouncedSearchValue, currentPage, filterValues]);

  return {
    gridData,
    setGridData,
  };
};

export const useRFQPartsTableData = (
  readOnly?: boolean,
  isEditable?: boolean,
  hidePriceColumn?: boolean
) => {
  const rfqDetails = useSelector(getRfqDetails);
  const companyPartExtraFields = useSelector(getPartExtraFields);
  const companyPartsHiddenColumns = useSelector(getPMPartsHiddenColumns);

  const { restoreState } = useLocalStorageGridState(BUYER_PARTS_GRID_COLUMN_STATE);
  const partsList = rfqDetails?.parts;
  const partExtraFields = rfqDetails?.part_extra_fields || companyPartExtraFields;
  const pmPartsHiddenColumns = rfqDetails?.pm_parts_hidden_columns || companyPartsHiddenColumns;
  const _columns = useMemo(
    () =>
      restoreState(
        getColumns(
          partExtraFields || [],
          [
            ...(pmPartsHiddenColumns || []),
            ProjectsPartField.DISPLAY_NAME,
            ProjectsPartField.EDIT_BUTTON,
          ],
          readOnly,
          true,
          isEditable,
          false,
          hidePriceColumn
        )
      ),
    [partExtraFields, pmPartsHiddenColumns, readOnly]
  );

  return {
    gridData: { rows: partsList || [], columns: _columns },
  };
};
