import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";

import { getRfqDetails } from "services/workspace/workspace.selectors";
import { getInitialValues, validationSchema } from "./rfq-add-addenda-form.definations";
import { RFQAddAddendaFormValues } from "./rfq-add-addenda-form.types";
import RFQAddAddendaFormBase from "./rfq-add-addenda-form.base";
import { getTargetedSupplierIds } from "../../components/contact-suppliers/contact-suppliers.definitions";
import { workspaceNextActions } from "../../redux";
import { WorkspaceModalTypes } from "../types";
import { modalsActions } from "@next/redux/modalsSlices";
import { convertFilesToObjects } from "@next/utils/fileUtils";

type Props = {};

const RFQAddAddendaForm: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const rfqDetails = useSelector(getRfqDetails);

  const handleSubmit = useCallback(
    (values: RFQAddAddendaFormValues) => {
      const suppliersIds = getTargetedSupplierIds({
        targetedSuppliersName: values.suppliers,
        targetedSuppliers: (rfqDetails?.targeted_suppliers || []).concat(
          rfqDetails?.marketplace_suppliers || []
        ),
      });

      dispatch(
        workspaceNextActions.addAddendaRequest({
          rfq: rfqDetails.pk,
          companies: suppliersIds,
          note: values.note,
          files: convertFilesToObjects(values.files),
          onSuccess: () => {
            dispatch(workspaceNextActions.resetFileUploadProgress());
            dispatch(modalsActions.closeModal(WorkspaceModalTypes.RFQ_ADD_ADDENDA_MODAL));
          },
        })
      );
    },
    [rfqDetails]
  );

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(props) => (
        <RFQAddAddendaFormBase
          {...props}
          suppliers={[
            ...(rfqDetails?.targeted_suppliers || []),
            ...(rfqDetails?.marketplace_suppliers || []),
          ]}
        />
      )}
    </Formik>
  );
};

export default RFQAddAddendaForm;
