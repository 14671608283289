import React, { useEffect } from "react";

import { useTracking } from "react-tracking";
// Hooks
import { useTranslation } from "react-i18next";

import Moment from "react-moment";

import { CircularProgress } from "@mui/material";

import { Box, Dialog, DialogTitle, Grid, IconButton, Slide, Typography } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Chat from "./discuss/discussion.component";

import CurrencyField from "components/common/fields/CurrencyField";
import { history } from "helpers/history";
import { SupplierAvatar } from "./supplier-avatar";

// Frontend Url imports
import { frontendUrl } from "urls";
import { openProfileInNewTab } from "@next/utils/browserUtils";
import i18n from "assets/configi18n/i18n";

export const Inbox = ({
  modalState,
  activeQuotation,
  isInboxOpen,
  chatWindowState,
  setChatWindowState,
  setActiveQuotationThread,
  isQuotationMessagesLoading,
  ...rest
}) => {
  const { t } = useTranslation("workspace");
  const tracking = useTracking();
  const isSupplier = rest?.user?.type === "DO";

  const handleRedirection = () => {
    setChatWindowState(true);
    rest.handleOpenModal(false, "isInboxOpen");
    // link redirection based on RFQ and quotation status
    history.push(
      rest?.user?.type === "DO"
        ? `${frontendUrl.buyerQuotes}/view/${rest?.activeQuotationThread.rfq?.pk}`
        : `${frontendUrl.supplierQuoteView}/${rest?.activeQuotationThread?.quotation?.pk}`
    );
    tracking.trackEvent({
      scout_category: "manufacturer_messenger",
      scout_feature_name: `${
        rest["user"]["type"] === "DO" ? "locate-rfq-link" : "locate-quotation-link"
      }`,
      scout_company: rest["company"]["name"],
      scout_username: rest["user"]["name"],
      scout_user_type: rest["user"]["type"],
      scout_date: new Date(),
    });
  };

  useEffect(() => {
    if (modalState) document.body.classList.add("c-page-scroll");
    else document.body.classList.remove("c-page-scroll");
  }, [modalState]);

  const onClickSupplierName = () => {
    if (isSupplier && rest?.activeQuotationThread?.instigator) {
      openProfileInNewTab(
        rest?.activeQuotationThread?.instigator?.name,
        rest?.activeQuotationThread?.instigator?.uuid
      );
    }
  };

  return (
    <Dialog
      hideBackdrop
      transition={(props) => <Slide direction="up" {...props} />}
      style={{ pointerEvents: "none" }}
      PaperProps={{ style: { pointerEvents: "auto" } }}
      open={modalState}
      maxWidth={"md"}
      autoDetectWindowHeight={true}
      autoScrollBodyContent={true}
      aria-labelledby="max-width-dialog-title"
      className={`c-chat-dialog ${
        chatWindowState ? "c-chat-dialog-minimized" : "c-chat-dialog-maximized"
      } ${isInboxOpen ? "c-inbox-open" : "c-inbox-close"}`}
    >
      <DialogTitle id="max-width-dialog-title" className="c-chat-dialog--title">
        <Box className="c-chat-dialog--box">
          <Box display="flex" alignItems="center" className="c-chat-dialog--box-margin">
            <Box flexGrow={1} display="flex" alignItems="center">
              {!rest?.activeQuotationThread && (
                <>
                  <CircularProgress color="inherit" size={22} />
                  <Box ml={2}>
                    <Typography variant="body2" className="c-chat-dialog--instigator-status">
                      {t("viewer:loading")}
                    </Typography>
                  </Box>
                </>
              )}
              {rest?.activeQuotationThread && (
                <>
                  <SupplierAvatar
                    instigator={rest?.activeQuotationThread?.instigator}
                    disableClick={!isSupplier}
                  />
                  <Grid className="c-chat-dialog--instigator-name" container direction="column">
                    <Typography
                      variant="body1"
                      className={isSupplier ? "c-cursor-pointer c-text-underline" : ""}
                      onClick={onClickSupplierName}
                    >
                      {rest?.activeQuotationThread?.instigator?.name}
                    </Typography>
                    <Typography variant="body2" className="c-chat-dialog--instigator-status">
                      <FiberManualRecordIcon
                        style={{
                          color: rest?.activeQuotationThread?.instigator?.is_online
                            ? "#4caf50"
                            : "#C7C7C7",
                        }}
                      />
                      {rest?.activeQuotationThread?.instigator?.is_online ? (
                        t("online")
                      ) : (
                        <>
                          {`${t("online")}`}{" "}
                          <Moment locale={i18n.language} fromNow>
                            {rest?.activeQuotationThread?.instigator?.last_seen}
                          </Moment>
                        </>
                      )}
                    </Typography>
                  </Grid>
                </>
              )}
            </Box>
            <Box display="flex" alignItems="center" className="c-cursor-pointer c-text-underline">
              {chatWindowState ? (
                <Box>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="header"
                    onClick={() => {
                      setChatWindowState();
                      tracking.trackEvent({
                        scout_category: "manufacturer_messenger",
                        scout_feature_name: "maximize_chat_window",
                        scout_company: rest["company"]["name"],
                        scout_username: rest["user"]["name"],
                        scout_user_type: rest["user"]["type"],
                        scout_date: new Date(),
                      });
                    }}
                    size="large"
                  >
                    <ExpandLessIcon />
                  </IconButton>
                </Box>
              ) : (
                <Box>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="header"
                    onClick={() => {
                      setChatWindowState(true);
                      tracking.trackEvent({
                        scout_category: "manufacturer_messenger",
                        scout_feature_name: "minimize_chat_window",
                        scout_company: rest["company"]["name"],
                        scout_username: rest["user"]["name"],
                        scout_user_type: rest["user"]["type"],
                        scout_date: new Date(),
                      });
                    }}
                    size="large"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </Box>
              )}

              <Box ml={1}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="header"
                  onClick={() => {
                    rest.handleOpenModal(false, "isChatOpen");
                    setChatWindowState();
                    // Remove the active quotation when the chat box is closed.
                    // This is important, so we dont open the chat box when the page
                    // is refreshed and the chat box was previously closed.
                    setActiveQuotationThread(null, null);
                    tracking.trackEvent({
                      scout_category: "manufacturer_messenger",
                      scout_feature_name: "close_chat_window",
                      scout_company: rest["company"]["name"],
                      scout_username: rest["user"]["name"],
                      scout_user_type: rest["user"]["type"],
                      scout_date: new Date(),
                    });
                  }}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
        {rest["activeQuotationThread"] && (
          <Box alignItems="center">
            <Box p={1} className="c-chat-dialog--box-rfq-name" fontSize={14}>
              <span onClick={() => handleRedirection()}>
                {rest?.activeQuotationThread?.rfq?.display_name}
              </span>
              {" · "}
              {rest?.activeQuotationThread?.quotation?.total_price ? (
                <CurrencyField value={rest?.activeQuotationThread?.quotation?.total_price} />
              ) : (
                <>
                  <span className="blurred-content"></span>
                </>
              )}
              {" · "}
              <span>{rest["activeQuotationThread"]["status"]}</span>
            </Box>
          </Box>
        )}
      </DialogTitle>
      <Chat
        activeQuotation={activeQuotation}
        isQuotationMessagesLoading={isQuotationMessagesLoading}
        {...rest}
        t={t}
      />
    </Dialog>
  );
};
