import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid, Typography, alpha } from "@mui/material";

import { getUser } from "services/profile/profile.selectors";

import { CompanyInformationForm } from "../../components/company-profile-edit/company-profile-information/company-information.form";
import { openProfileInNewTab } from "@next/utils/browserUtils";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTranslation } from "react-i18next";
import { useAxyaTracking } from "@next/hooks/useAxyaTracking";
import ImageCardsEdit from "../../components/company-profile-edit/image-cards-edit";
import { CompanyCapabilities } from "components/user-company/company-capabilities/company-capabilities.component";
import { useCompanyProfileEditData } from "./company-profile-edit.hooks";
import { CompanyProfileFileReferenceType } from "../../redux";
import CompanyReferences from "../../components/company-profile-edit/company-references";
import { checkIsSellerCompany } from "@next/utils/checkCompanyUtils";
import CompanyProfileAccordionWrapper from "../../components/company-profile-edit/company-profile-accordion-wrapper";
import { SellerDashboardCard } from "@next/modules/dashboard/components/seller-dashboard-card/seller-dashboard-card";
import { SellerDashboardPremiumStatusCard } from "@next/modules/dashboard/components/seller-dashboard-premium-status-card/seller-dashboard-premium-status-card";
import CompanyDocuments from "../../components/company-profile-edit/company-documents/company-documents";
import SellerDashboardProfileCompletenessCard from "@next/modules/dashboard/components/seller-dashboard-profile-completeness-card/seller-dashboard-profile-completeness-card";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { AppTheme } from "layout/AppTheme";
import { CompanyCertifications } from "components/user-company/company-certifications/company-certifications";
import { CompanyIndustryExpertise } from "components/user-company/company-industry-expertise/company-industry-expertise";
import { dashboardActions } from "@next/modules/dashboard/redux";
import { OnboardingSteps } from "@next/modules/dashboard/components/seller-dashboard-onboarding-premium/seller-dashboard-onboarding-premium";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    gridGap: theme.spacing(2),
    maxWidth: "984px",
    margin: "0 auto",
    minHeight: "calc(100vh - 240px)",
  },
  buttonContainer: {
    marginBottom: theme.spacing(2),
  },
  premiumGreenIcon: {
    color: theme.palette.success.main,
    width: "21px",
    height: "21px",
  },
  premiumText: {
    marginLeft: theme.spacing(1.5),
  },
  accordionContainer: {
    display: "flex",
    flexDirection: "column",
    gridGap: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

const CompanyProfileEdit: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const axyaTracking = useAxyaTracking();
  const user = useSelector(getUser);
  const { companyProfile } = useCompanyProfileEditData(user?.company?.id);
  const isSeller = checkIsSellerCompany(companyProfile?.type);

  const onOpenMyProfilePageClick = () => {
    companyProfile?.uuid && openProfileInNewTab(companyProfile?.name, companyProfile?.uuid);

    axyaTracking({
      scout_category: "axya-supplier-profile",
      scout_feature_name: "supplier-profile-redirection-from-open-my-profile-page-button",
    });
  };

  const completeness = companyProfile?.profile_completeness;

  useEffect(() => {
    if (
      completeness?.company_info?.is_complete &&
      completeness?.past_project_pictures?.is_complete
    ) {
      dispatch(
        dashboardActions.showPopUpOnboardingRequest(OnboardingSteps.UPDATING_COMPANY_PROFILE)
      );
    }
  }, [completeness]);

  return (
    <Grid container className={classes.root}>
      {isSeller ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs>
              <SellerDashboardPremiumStatusCard />
            </Grid>

            <Grid item xs>
              <SellerDashboardProfileCompletenessCard
                completenessPercentage={completeness?.total}
              />
            </Grid>

            <Grid item xs>
              <SellerDashboardCard
                title={t("sellerDashboard:openProfile")}
                content={<OpenInNewIcon />}
                onClick={onOpenMyProfilePageClick}
              />
            </Grid>
          </Grid>

          {completeness?.total === 100 ? null : (
            <Box
              borderRadius="8px"
              p={2}
              display="flex"
              alignItems="center"
              gap="16px"
              bgcolor={alpha(AppTheme.palette.primary.main, 0.1)}
              flex={1}
            >
              <EditOutlinedIcon style={{ color: AppTheme.palette.primary.main, fontSize: 20 }} />
              <Typography color="primary" variant="body2">
                {t("profile:companyProfile:profileCompletionTooltip")}
              </Typography>
            </Box>
          )}
        </>
      ) : null}

      <Box className={classes.accordionContainer}>
        <CompanyProfileAccordionWrapper
          title={t("profile:companyProfile:companyInformation")}
          expandedKey="companyInformation"
          initiallyOpen={false}
          titleTag={t("profile:companyProfile:completeFields", { count: 13 })}
          completenessPercentage={isSeller ? completeness?.company_info?.weightage : undefined}
          isCompleted={completeness?.company_info?.is_complete}
        >
          <CompanyInformationForm />
        </CompanyProfileAccordionWrapper>

        {isSeller ? (
          <>
            <CompanyProfileAccordionWrapper
              title={t("profile:companyProfile:pictureOfYourProjects")}
              expandedKey="pictureOfYourProjects"
              titleTag={t("profile:companyProfile:uploadMinimumProjectPictures", {
                count: 3,
              })}
              isCompleted={completeness?.past_project_pictures?.is_complete}
              completenessPercentage={completeness?.past_project_pictures?.weightage}
            >
              <ImageCardsEdit
                subTitle={t("profile:companyProfile:pictureOfYourProjectsSubtitle")}
                images={companyProfile?.past_projects_pictures}
                type={CompanyProfileFileReferenceType.PAST_PROJECT}
              />
            </CompanyProfileAccordionWrapper>

            <CompanyProfileAccordionWrapper
              title={t("profile:companyProfile:pictureOfYourMachineShop")}
              expandedKey="pictureOfYourMachineShop"
              titleTag={t("profile:companyProfile:uploadMinimumMachineShopPictures", {
                count: 3,
              })}
              completenessPercentage={completeness?.shop_pictures?.weightage}
              isCompleted={completeness?.shop_pictures?.is_complete}
            >
              <ImageCardsEdit
                subTitle={t("profile:companyProfile:pictureOfYourMachineShopSubtitle")}
                images={companyProfile?.shop_pictures}
                type={CompanyProfileFileReferenceType.SHOP}
              />
            </CompanyProfileAccordionWrapper>

            <CompanyProfileAccordionWrapper
              title={t("profile:companyProfile:certifications")}
              expandedKey="certifications"
              titleTag={t("profile:companyProfile:provideMinimumCertifications", {
                count: 1,
              })}
              completenessPercentage={completeness?.certifications?.weightage}
              isCompleted={completeness?.certifications?.is_complete}
            >
              <CompanyCertifications />
            </CompanyProfileAccordionWrapper>

            <CompanyProfileAccordionWrapper
              title={t("profile:companyProfile:documents")}
              expandedKey="documents"
              titleTag={t("profile:companyProfile:uploadMinimumDocuments", {
                count: 1,
              })}
              completenessPercentage={completeness?.documents?.weightage}
              isCompleted={completeness?.documents?.is_complete}
            >
              <CompanyDocuments />
            </CompanyProfileAccordionWrapper>

            <CompanyProfileAccordionWrapper
              title={t("profile:companyProfile:capabilities")}
              expandedKey="capabilities"
              titleTag={t("profile:companyProfile:selectMinimumCapabilities", {
                count: 3,
              })}
              completenessPercentage={completeness?.manufacturing_capabilities?.weightage}
              isCompleted={completeness?.manufacturing_capabilities?.is_complete}
            >
              <CompanyCapabilities />
            </CompanyProfileAccordionWrapper>

            <CompanyProfileAccordionWrapper
              title={t("profile:companyProfile:industriesExpertise")}
              expandedKey="industry_expertise"
              titleTag={t("profile:companyProfile:provideMinimumIndustriesExpertise", {
                count: 1,
              })}
              completenessPercentage={completeness?.industry_expertise?.weightage}
              isCompleted={completeness?.industry_expertise?.is_complete}
            >
              <CompanyIndustryExpertise />
            </CompanyProfileAccordionWrapper>

            <CompanyProfileAccordionWrapper
              title={t("profile:companyProfile:references")}
              expandedKey="references"
              isOptional
            >
              <CompanyReferences />
            </CompanyProfileAccordionWrapper>
          </>
        ) : null}
      </Box>
    </Grid>
  );
};

export default CompanyProfileEdit;
