import { SpecOfSubtierSupplier } from "../redux";

export const validateSubtiersData = (subtiersData: SpecOfSubtierSupplier[]): boolean => {
  return subtiersData.every((subtier) => {
    if (!Array.isArray(subtier.rows) || subtier.rows.length === 0) {
      return false;
    }

    return subtier.rows.every((row) => row.compliance != null && row.poe != null);
  });
};
