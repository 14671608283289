import React, { useMemo, useRef } from "react";
import { v4 as uuid } from "uuid";

import Skeleton from "@mui/material/Skeleton";
import { GridColDef } from "@mui/x-data-grid-pro";
import { GridColumns } from "@mui/x-data-grid-pro";
import { isArray } from "lodash";
import { PAGINATION_PAGE_SIZE } from "@next/constants";

export const getLoadingRows = (
  columns: GridColDef[] | GridColumns,
  itemSize: number = PAGINATION_PAGE_SIZE
) => {
  if (!columns || !isArray(columns)) {
    return { rows: [], columns: [] };
  }

  const loadingRows = [];
  for (let i = 0; i < itemSize; i++) {
    const obj: any = {};

    columns.forEach((c: any) => {
      obj.pk = uuid();
      obj.code = uuid();
      obj[c.field] = uuid();
    });

    loadingRows.push(obj);
  }

  return {
    rows: loadingRows || [],
    columns: (columns as GridColDef[]).map((c) =>
      Object.assign({}, c, {
        renderCell: () => <Skeleton animation="wave" height={40} width="80%" />,
      })
    ),
  };
};

export const getLoadingRowsOLD = (columns: any) => {
  if (!columns) {
    return [];
  }
  columns.forEach((c: any) => {
    c.renderCell = () => <Skeleton animation="wave" height={40} width="80%" />;
  });

  const _rows = [];
  for (let i = 0; i < 10; i++) {
    const obj: any = {};
    columns.forEach((c: any) => {
      obj.pk = uuid();
      obj[c.field] = uuid();
    });
    _rows.push(obj);
  }

  return _rows;
};

export const useApiRef = (columns: any, callback?: any) => {
  const apiRef = useRef<any>(null);
  const _columns = useMemo(() => {
    columns[0].renderCell = (params: any) => {
      apiRef.current = params.api;
      callback && callback(apiRef);
      return null;
    };
    return columns;
  }, [columns]);

  return { apiRef, _columns };
};
