import moment from "moment";

/**
 * A function that makes use of momentJS "".add" functionality in order
 * to sum the RFQ Contracted date with the expected lead time
 * in order to determine the expected delivery date
 *
 * further documentation on moment.add():
 * https://momentjscom.readthedocs.io/en/latest/moment/03-manipulating/01-add/
 *
 * @param {string || undefined} createdAt : RFQ createdAt
 * @param {Timestamp} delay : timestamp of the required lead time
 * @param {Timestamp} delayUnit : key of the required days, weeks or months
 * @returns {Date} : Sum of created date + Lead Time
 */
export function expectedDeliveryDate(createdAt, delay, delayUnit) {
  if (!createdAt || !delay || !delayUnit) {
    return;
  }

  const momentDuration = {
    m: { value: Math.round(moment.duration(delay).asMonths()), key: "months" },
    w: { value: Math.round(moment.duration(delay).asWeeks()), key: "weeks" },
    d: { value: Math.round(moment.duration(delay).asDays()), key: "days" },
  };

  return moment(createdAt).add(
    momentDuration[delayUnit]["value"],
    momentDuration[delayUnit]["key"]
  );
}
